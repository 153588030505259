import { Checkbox } from "../../../../../components/common/Checkbox/Checkbox";
import Filter from "../../../../../components/Filter/Filter";
import React, { useEffect, useState } from "react";
import { VPHealthCheck } from "../../../../../services/service-constants";
import { mainAxiosInstance } from "../../../../../services/service";
import { useSelector } from "react-redux";
import Feedback from "../../../../../components/common/ErrorComponents/Feedback";
import NotAuthorized from "../../../../../components/common/ErrorComponents/NotAuthorized";
import ChartLoader from "../../../../../components/common/ErrorComponents/ChartLoader";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../../../components/common/Pagination";
import { DualLineGraph } from "../../../components/graphs/HealthCheckGraph";
import { useLocation } from "react-router-dom";
import { Difference } from "@mui/icons-material";

const VPCompareWithFilter = () => {
  const [chartData, setChartData] = useState([]);
  const [isChecked, setIsCheckbox] = useState(true);
  const [diagnosesState, setDiagnosesState] = useState(null);
  const [noDataFound, setNoDataFound] = useState(true);
  const [showChartLoader, setShowChartLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState(true);
  const [selectAll, setSelectAll] = useState(true);
  const [showFilter, setShowFilter] = useState(true);
  const [authenticationMsg, setAuthenticationMsg] = useState(false);
  const firstfilter = useSelector((state) => state.mainReducer.first_filter);
  const secondfilter = useSelector((state) => state.mainReducer.second_filter);
  const location = useLocation();
  const [data, setData] = useState(null);
  const [p1Checkbox, setP1Checkbox] = useState(true);
  const [p2Checkbox, setP2Checkbox] = useState(true);
  const [difference, setDifference] = useState(true);

  const dataStore = [];
  const filterOptions = [
    {
      displayname: "Time To Start Engine",
      id: "timeToStartEngineResponseDto",
      isChecked: true,
    },
    {
      displayname: "Engine Oil Consumption",
      id: "engineOilConsumptionDto",
      isChecked: true,
    },
    {
      displayname: "Average Electric Vehicle Battery Consumption",
      id: "averageElectricVehicleBatteryResponseDTO",
      isChecked: true,
    },
    {
      displayname: "Average Fuel Consumption",
      id: "averageFuelResponseDto",
      isChecked: true,
    },
    {
      displayname: "Distance Between Particulate Filter Regeneration",
      id: "distanceBetweenParticulateFilterRegenerationResponseDTO",
      isChecked: true,
    },
    {
      displayname: "key Off Battery Drain",
      id: "keyOffBatteryDrainResponseDto",
      isChecked: true,
    },
    {
      displayname: "Oil Change Interval",
      id: "oilChangeIntervalResponseDto",
      isChecked: true,
    },
    {
      displayname: "Urea Consumption",
      id: "ureaConsumptionResponseDTO",
      isChecked: true,
    },
  ];

  const [filterData, setFilterData] = useState([...filterOptions]);

  useEffect(() => {
    if (location?.state) {
      setDiagnosesState(location?.state?.diagnosesState);
    }
  }, [location]);

  useEffect(() => {
    if (diagnosesState !== null) getPageData();
  }, [diagnosesState]);

  const getPageData = (val) => {
    setShowChartLoader(true);
    const url = `${VPHealthCheck.digital_twin_comparision
      }/${diagnosesState}?rangeSize=${100}`;
    let params = {};

    let firstParam = { ...firstfilter, ...params };
    let secondParam = { ...secondfilter, ...params };
    //   let paramData = [firstParam, secondParam];

    let paramData = {
      filterDto1: firstParam,
      filterDto2: secondParam,
    };

    // let paramData = {
    //   filterDto1: {
    //     make: "Ford",
    //     organizationId: 1,
    //   },
    //   filterDto2: {
    //     make: "Ford",
    //     organizationId: 1,
    //   },
    // };

    mainAxiosInstance
      .post(url, paramData)
      .then((response) => {
        if (response.status === 200) {
          setNoDataFound(false);
          setShowChartLoader(false);
          setData(response.data);
          prepareChartData(response.data);
        }
        if (response.status === 401) {
          setErrorMsg(response.data?.details || "You are not authorized.");
          setAuthenticationMsg(true);
        }
      })
      .catch((err) => {
        setNoDataFound(true);
        setShowChartLoader(true);
        setErrorMsg(false);
      });
  };

  useEffect(() => {
    if (data) prepareChartData(data);
  }, [data, p1Checkbox, p2Checkbox, difference]);

  const prepareChartData = (response) => {
    let mainData = [];
    Object.keys(response).map((key) => {
      let firstResData = [];
      let secondResData = [];
      let rulPopulation1 = [];
      let rulPopulation2 = [];
      let graphLabels = [];
      let categories = [];
      let secondCategories = [];
      let rulfirstCategories = [];
      let rulSecondCategories = [];
      let firstColorValue = [];
      let SecondColorValue = [];
      let firstRulColorValue = [];
      let SecondRulColorValue = [];
      let differenceResData = [];
      let differenceCategories = [];
      let rulDifferenceResData = [];
      let secondDifferenceCategories = [];

      if (response[key] != null) {
        response[key].population1?.map((data) => {

          firstResData.push({
            y: data?.yaxisAvgValue,
            firstMean: data?.yaxisAvgValue,
            firstMin: data?.yaxisMinValue,
            firstMax: data?.yaxisMaxValue,
            y1AxisLabel: response[key].thresholdDTO?.yAxisName,
          });
          categories?.push(data?.odometer);
          firstColorValue.push([data?.yaxisMinValue, data?.yaxisMaxValue]);
        });
      } else {
        // firstResData?.push({
        //   y: 0,
        // });
        firstResData?.push([]);
        firstColorValue.push([]);
      }
      if (response[key] != null) {

        const matchingIndexes = [];

        response[key].population2?.map((data) => {
          secondCategories?.push(data?.odometer);
          SecondColorValue.push([data?.yaxisMinValue, data?.yaxisMaxValue]);

          secondResData?.push({
            y: data?.yaxisAvgValue,
            secondMean: data?.yaxisAvgValue,
            secondMin: data?.yaxisMinValue,
            secondMax: data?.yaxisMaxValue,
            y1AxisLabel: response[key].thresholdDTO?.yAxisName,

          });

        });

        categories.forEach((item1, index) => {
          if (secondCategories.includes(item1)) {
            matchingIndexes.push(index);
          }
        });

        secondResData = secondResData.map((item, index) => ({
          ...item,
          x: matchingIndexes[index],
        }));

        SecondColorValue = matchingIndexes.map((value, index) => [value, ...SecondColorValue[index]]);

      } else {
        // secondResData?.push({
        //   y: 0,
        // });
        secondResData?.push([]);
        SecondColorValue.push([]);
      }

      if (response[key] !== null) {
        const matchingIndexes = [];
        response[key].rulPopulation1?.map((data) => {
          rulfirstCategories?.push(data?.odometer);
          rulPopulation1?.push({
            y: data?.avgRul,
            firstMean: data?.avgRul,
            firstMin: data?.minRul,
            firstMax: data?.maxRul,
          });
          firstRulColorValue.push([data?.minRul, data?.maxRul]);
        });

        categories.forEach((item1, index) => {
          if (rulfirstCategories.includes(item1)) {
            matchingIndexes.push(index);
          }
        });

        rulPopulation1 = rulPopulation1.map((item, index) => ({
          ...item,
          x: matchingIndexes[index],
        }));

        firstRulColorValue = matchingIndexes.map((value, index) => [value, ...firstRulColorValue[index]]);
      } else {
        // rulPopulation1?.push({ y: 0 });
        rulPopulation1?.push([]);
        firstRulColorValue.push([]);
      }
      if (response[key] !== null) {
        const matchingIndexes = [];

        response[key].rulPopulation2?.map((data) => {
          rulSecondCategories?.push(data?.odometer);
          rulPopulation2?.push({
            y: data?.avgRul,
            secondMean: data?.avgRul,
            secondMin: data?.minRul,
            secondMax: data?.maxRul,
          });
          SecondRulColorValue.push([data?.minRul, data?.maxRul]);
        });

        categories.forEach((item1, index) => {
          if (rulSecondCategories.includes(item1)) {
            matchingIndexes.push(index);
          }
        });

        rulPopulation2 = rulPopulation2.map((item, index) => ({
          ...item,
          x: matchingIndexes[index],
        }));

        SecondRulColorValue = matchingIndexes.map((value, index) => [value, ...SecondRulColorValue[index]]);
      } else {
        // rulPopulation2?.push({ y: 0 });
        rulPopulation2?.push([]);
        SecondRulColorValue.push([]);
      }

      if (response[key] !== null) {
        response[key].overlappingIntervals?.map((data) => {
          differenceCategories?.push(data?.odometer);
          differenceResData.push({
            low: data?.start,
            high: data?.end,
            mean: data?.mean,
            min: data?.min,
            max: data?.max,
            y1AxisLabel: response[key].thresholdDTO?.yAxisName,
          });
        });
      } else {
        differenceResData.push([]);
      }

      if (response[key] !== null) {
        response[key].rulOverlappingIntervals?.map((data) => {
          // differenceCategories?.push(data?.odometer);
          rulDifferenceResData.push({
            low: data?.start,
            high: data?.end,
            mean: data?.mean,
            min: data?.min,
            max: data?.max,
            y1AxisLabel: response[key].thresholdDTO?.yAxisName,
          });
          secondDifferenceCategories?.push(data?.odometer);
        });
      } else {
        rulDifferenceResData.push([]);
      }

      if (response[key] !== null) {
        graphLabels.push({
          xAxisLabel: response[key].thresholdDTO?.xAxisName,
          y1AxisLabel: response[key].thresholdDTO?.yAxisName,
          y2AxisLabel: "RUL(Miles)",
          graphTitle: response[key].thresholdDTO?.graphLabel,
          thresholdValue: response[key].thresholdDTO?.threshold,
        });
      } else {
        graphLabels.push({
          xAxisLabel: "",
          y1AxisLabel: "",
          y2AxisLabel: "",
          graphTitle: "",
          thresholdValue: "",

        });
      }

      if (p1Checkbox && p2Checkbox && difference) {
        mainData.push({
          name: key,
          data: [
            {
              categories:
                categories.length != 0 ? categories : secondCategories,
              graphLabels: graphLabels,
              data: [
                {
                  type: "line",
                  data: firstResData,
                  color: "#0574a6",
                  pointWidth: 15,
                  name: "P1",
                  marker: false,
                },
                {
                  type: "line",
                  data: secondResData,
                  color: "#000000",
                  pointWidth: 15,
                  name: "P2",
                  marker: false,
                },
                {
                  type: "line",
                  data: rulPopulation1,
                  color: "#6FAC4E",
                  pointWidth: 15,
                  name: "P1",
                  marker: false,
                  yAxis: 1,
                },
                {
                  type: "line",
                  data: rulPopulation2,
                  color: "#165C0B",
                  pointWidth: 15,
                  name: "P2",
                  marker: false,
                  yAxis: 1,
                },
                {
                  type: "arearange",
                  data: firstColorValue,
                  color: "#BFDBE8",
                  //pointWidth: 15,
                  name: "",
                  marker: false,
                  fillOpacity: 0.2,
                },
                {
                  type: "arearange",
                  data: SecondColorValue,
                  color: "#BFDBE8",
                  // pointWidth: 15,
                  name: "",
                  marker: false,
                  fillOpacity: 0.2,
                },
                {
                  type: "arearange",
                  data: firstRulColorValue,
                  color: "#B9D6AA",
                  // pointWidth: 15,
                  name: "",
                  marker: false,
                  fillOpacity: 0.2,
                  yAxis: 1,
                },
                {
                  type: "arearange",
                  data: SecondRulColorValue,
                  color: "#B9D6AA",
                  // pointWidth: 15,
                  name: "",
                  marker: false,
                  fillOpacity: 0.2,
                  yAxis: 1,
                },
                {
                  type: "arearange",
                  data: differenceResData,
                  color: "#BFDBE8",
                  name: "",
                  marker: false,
                  fillOpacity: 0.2,
                },
                {
                  type: "arearange",
                  data: rulDifferenceResData,
                  color: "#B9D6AA",
                  // pointWidth: 15,
                  name: "",
                  marker: false,
                  fillOpacity: 0.2,
                  yAxis: 1,
                },
              ],
            },
          ],
        });
      } else if (!p1Checkbox && !p2Checkbox && difference) {
        mainData.push({
          name: key,
          data: [
            {
              categories:
                differenceCategories.length != 0
                  ? differenceCategories
                  : secondDifferenceCategories,
              graphLabels: graphLabels,
              data: [
                {
                  type: "arearange",
                  data: differenceResData,
                  color: "#BFDBE8",
                  name: "",
                  marker: false,
                  fillOpacity: 0.2,
                },
                {
                  type: "arearange",
                  data: rulDifferenceResData,
                  color: "#B9D6AA",
                  // pointWidth: 15,
                  name: "",
                  marker: false,
                  fillOpacity: 0.2,
                  yAxis: 1,
                },
                {
                  type: "line",
                  data: [],
                  color: "#000000",
                  pointWidth: 15,
                  name: "P2",
                  marker: false,
                },
                {
                  type: "line",
                  data: [],
                  color: "#000000",
                  pointWidth: 15,
                  name: "P1",
                  marker: false,
                },
                {
                  type: "line",
                  data: [],
                  color: "#000000",
                  pointWidth: 15,
                  name: "P2",
                  marker: false,
                },
                {
                  type: "arearange",
                  data: [],
                  color: "#BFDBE8",
                  //pointWidth: 15,
                  name: "",
                  marker: false,
                  fillOpacity: 0.2,
                },
                {
                  type: "arearange",
                  data: [],
                  color: "#BFDBE8",
                  // pointWidth: 15,
                  name: "",
                  marker: false,
                  fillOpacity: 0.2,
                },
                {
                  type: "arearange",
                  data: [],
                  color: "#B9D6AA",
                  // pointWidth: 15,
                  name: "",
                  marker: false,
                  fillOpacity: 0.2,
                },
              ],
            },
          ],
        });
      } else if (!p1Checkbox && p2Checkbox && !difference) {
        mainData.push({
          name: key,
          data: [
            {
              categories:
                categories.length != 0 ? categories : secondCategories,
              graphLabels: graphLabels,
              data: [
                {
                  type: "line",
                  data: [],
                  color: "#6FAC4E",
                  pointWidth: 15,
                  name: "P1",
                  marker: false,
                },

                {
                  type: "line",
                  data: secondResData,
                  color: "#000000",
                  pointWidth: 15,
                  name: "P2",
                  marker: false,
                },
                {
                  type: "line",
                  data: [],
                  color: "#000000",
                  pointWidth: 15,
                  name: "P1",
                  marker: false,
                },
                {
                  type: "line",
                  data: rulPopulation2,
                  color: "#165C0B",
                  pointWidth: 15,
                  name: "P2",
                  marker: false,
                  yAxis: 1,
                },
                {
                  type: "arearange",
                  data: [],
                  color: "#BFDBE8",
                  //pointWidth: 15,
                  name: "",
                  marker: false,
                  fillOpacity: 0.2,
                },
                {
                  type: "arearange",
                  data: SecondColorValue,
                  color: "#BFDBE8",
                  // pointWidth: 15,
                  name: "",
                  marker: false,
                  fillOpacity: 0.2,
                },
                {
                  type: "arearange",
                  data: [],
                  color: "#B9D6AA",
                  // pointWidth: 15,
                  name: "",
                  marker: false,
                  fillOpacity: 0.2,
                  yAxis: 1,
                },
                {
                  type: "arearange",
                  data: SecondRulColorValue,
                  color: "#B9D6AA",
                  // pointWidth: 15,
                  name: "",
                  marker: false,
                  fillOpacity: 0.2,
                  yAxis: 1,
                },
              ],
            },
          ],
        });
      } else if (p1Checkbox && !p2Checkbox && !difference) {
        mainData.push({
          name: key,
          data: [
            {
              categories:
                categories.length != 0 ? categories : secondCategories,
              graphLabels: graphLabels,
              data: [
                {
                  type: "line",
                  data: firstResData,
                  color: "#0574a6",
                  pointWidth: 15,
                  name: "P1",
                  marker: false,
                },
                {
                  type: "line",
                  data: [],
                  color: "#000000",
                  pointWidth: 15,
                  name: "P2",
                  marker: false,
                },
                {
                  type: "line",
                  data: rulPopulation1,
                  color: "#6FAC4E",
                  pointWidth: 15,
                  name: "P1",
                  marker: false,
                  yAxis: 1,
                },
                {
                  type: "line",
                  data: [],
                  color: "#000000",
                  pointWidth: 15,
                  name: "P2",
                  marker: false,
                },
                {
                  type: "arearange",
                  data: firstColorValue,
                  color: "#BFDBE8",
                  //pointWidth: 15,
                  name: "",
                  marker: false,
                  fillOpacity: 0.2,
                },
                {
                  type: "arearange",
                  data: [],
                  color: "#BFDBE8",
                  // pointWidth: 15,
                  name: "",
                  marker: false,
                  fillOpacity: 0.2,
                },
                {
                  type: "arearange",
                  data: firstRulColorValue,
                  color: "#B9D6AA",
                  // pointWidth: 15,
                  name: "",
                  marker: false,
                  fillOpacity: 0.2,
                  yAxis: 1,
                },
                {
                  type: "arearange",
                  data: [],
                  color: "#B9D6AA",
                  // pointWidth: 15,
                  name: "",
                  marker: false,
                  fillOpacity: 0.2,
                  yAxis: 1,
                },
              ],
            },
          ],
        });
      } else if (!p1Checkbox && !p2Checkbox && !difference) {
        mainData.push({
          name: key,
          data: [
            {
              categories:
                categories.length != 0 ? categories : secondCategories,
              graphLabels: graphLabels,
              data: [
                {
                  type: "line",
                  data: [],
                  color: "#6FAC4E",
                  pointWidth: 15,
                  name: "P1",
                  marker: false,
                },

                {
                  type: "line",
                  data: [],
                  color: "#000000",
                  pointWidth: 15,
                  name: "P2",
                  marker: false,
                },
                {
                  type: "line",
                  data: [],
                  color: "#000000",
                  pointWidth: 15,
                  name: "P1",
                  marker: false,
                },
                {
                  type: "line",
                  data: [],
                  color: "#000000",
                  pointWidth: 15,
                  name: "P2",
                  marker: false,
                },
                {
                  type: "arearange",
                  data: [],
                  color: "#BFDBE8",
                  //pointWidth: 15,
                  name: "",
                  marker: false,
                  fillOpacity: 0.2,
                },
                {
                  type: "arearange",
                  data: [],
                  color: "#BFDBE8",
                  // pointWidth: 15,
                  name: "",
                  marker: false,
                  fillOpacity: 0.2,
                },
                {
                  type: "arearange",
                  data: [],
                  color: "#B9D6AA",
                  // pointWidth: 15,
                  name: "",
                  marker: false,
                  fillOpacity: 0.2,
                  yAxis: 1,
                },
                {
                  type: "arearange",
                  data: [],
                  color: "#B9D6AA",
                  // pointWidth: 15,
                  name: "",
                  marker: false,
                  fillOpacity: 0.2,
                  yAxis: 1,
                },
              ],
            },
          ],
        });
      } else if (!p1Checkbox && p2Checkbox && difference) {
        mainData.push({
          name: key,
          data: [
            {
              categories:
                categories.length != 0 ? categories : secondCategories,
              graphLabels: graphLabels,
              data: [
                {
                  type: "line",
                  data: [],
                  color: "#6FAC4E",
                  pointWidth: 15,
                  name: "P1",
                  marker: false,
                },

                {
                  type: "line",
                  data: secondResData,
                  color: "#000000",
                  pointWidth: 15,
                  name: "P2",
                  marker: false,
                },
                {
                  type: "line",
                  data: [],
                  color: "#000000",
                  pointWidth: 15,
                  name: "P1",
                  marker: false,
                },
                {
                  type: "line",
                  data: rulPopulation2,
                  color: "#165C0B",
                  pointWidth: 15,
                  name: "P2",
                  marker: false,
                  yAxis: 1,
                },
                {
                  type: "arearange",
                  data: [],
                  color: "#BFDBE8",
                  //pointWidth: 15,
                  name: "",
                  marker: false,
                  fillOpacity: 0.2,
                },
                {
                  type: "arearange",
                  data: SecondColorValue,
                  color: "#BFDBE8",
                  // pointWidth: 15,
                  name: "",
                  marker: false,
                  fillOpacity: 0.2,
                },
                {
                  type: "arearange",
                  data: [],
                  color: "#B9D6AA",
                  // pointWidth: 15,
                  name: "",
                  marker: false,
                  fillOpacity: 0.2,
                  yAxis: 1,
                },
                {
                  type: "arearange",
                  data: SecondRulColorValue,
                  color: "#B9D6AA",
                  // pointWidth: 15,
                  name: "",
                  marker: false,
                  fillOpacity: 0.2,
                  yAxis: 1,
                },
                {
                  type: "arearange",
                  data: differenceResData,
                  color: "#BFDBE8",
                  name: "",
                  marker: false,
                  fillOpacity: 0.2,
                },
                {
                  type: "arearange",
                  data: rulDifferenceResData,
                  color: "#B9D6AA",
                  // pointWidth: 15,
                  name: "",
                  marker: false,
                  fillOpacity: 0.2,
                  yAxis: 1,
                },
              ],
            },
          ],
        });
      } else if (p1Checkbox && !p2Checkbox && difference) {
        mainData.push({
          name: key,
          data: [
            {
              categories:
                categories.length != 0 ? categories : secondCategories,
              graphLabels: graphLabels,
              data: [
                {
                  type: "line",
                  data: firstResData,
                  color: "#0574a6",
                  pointWidth: 15,
                  name: "P1",
                  marker: false,
                },

                {
                  type: "line",
                  data: [],
                  color: "#000000",
                  pointWidth: 15,
                  name: "P2",
                  marker: false,
                },
                {
                  type: "line",
                  data: rulPopulation1,
                  color: "#6FAC4E",
                  pointWidth: 15,
                  name: "P1",
                  marker: false,
                  yAxis: 1,
                },
                {
                  type: "line",
                  data: [],
                  color: "#000000",
                  pointWidth: 15,
                  name: "P2",
                  marker: false,
                },
                {
                  type: "arearange",
                  data: firstColorValue,
                  color: "#BFDBE8",
                  //pointWidth: 15,
                  name: "",
                  marker: false,
                  fillOpacity: 0.2,
                },
                {
                  type: "arearange",
                  data: [],
                  color: "#BFDBE8",
                  // pointWidth: 15,
                  name: "",
                  marker: false,
                  fillOpacity: 0.2,
                },
                {
                  type: "arearange",
                  data: firstRulColorValue,
                  color: "#B9D6AA",
                  // pointWidth: 15,
                  name: "",
                  marker: false,
                  fillOpacity: 0.2,
                  yAxis: 1,
                },
                {
                  type: "arearange",
                  data: [],
                  color: "#B9D6AA",
                  // pointWidth: 15,
                  name: "",
                  marker: false,
                  fillOpacity: 0.2,
                  yAxis: 1,
                },
                {
                  type: "arearange",
                  data: differenceResData,
                  color: "#BFDBE8",
                  name: "",
                  marker: false,
                  fillOpacity: 0.2,
                },
                {
                  type: "arearange",
                  data: rulDifferenceResData,
                  color: "#B9D6AA",
                  // pointWidth: 15,
                  name: "",
                  marker: false,
                  fillOpacity: 0.2,
                  yAxis: 1,
                },
              ],
            },
          ],
        });
      } else if (p1Checkbox && p2Checkbox && !difference) {
        mainData.push({
          name: key,
          data: [
            {
              categories:
                categories.length != 0 ? categories : secondCategories,
              graphLabels: graphLabels,
              data: [
                {
                  type: "line",
                  data: firstResData,
                  color: "#0574a6",
                  pointWidth: 15,
                  name: "P1",
                  marker: false,
                },

                {
                  type: "line",
                  data: secondResData,
                  color: "#000000",
                  pointWidth: 15,
                  name: "P2",
                  marker: false,
                },
                {
                  type: "line",
                  data: rulPopulation1,
                  color: "#6FAC4E",
                  pointWidth: 15,
                  name: "P1",
                  marker: false,
                  yAxis: 1,
                },
                {
                  type: "line",
                  data: rulPopulation2,
                  color: "#165C0B",
                  pointWidth: 15,
                  name: "P2",
                  marker: false,
                  yAxis: 1,
                },
                {
                  type: "arearange",
                  data: firstColorValue,
                  color: "#BFDBE8",
                  //pointWidth: 15,
                  name: "",
                  marker: false,
                  fillOpacity: 0.2,
                },
                {
                  type: "arearange",
                  data: SecondColorValue,
                  color: "#BFDBE8",
                  // pointWidth: 15,
                  name: "",
                  marker: false,
                  fillOpacity: 0.2,
                },
                {
                  type: "arearange",
                  data: firstRulColorValue,
                  color: "#B9D6AA",
                  // pointWidth: 15,
                  name: "",
                  marker: false,
                  fillOpacity: 0.2,
                  yAxis: 1,
                },
                {
                  type: "arearange",
                  data: SecondRulColorValue,
                  color: "#B9D6AA",
                  // pointWidth: 15,
                  name: "",
                  marker: false,
                  fillOpacity: 0.2,
                  yAxis: 1,
                },
              ],
            },
          ],
        });
      }
    });
    setChartData(mainData);
  };

  const getDataForBarChart = (element) => {
    const barChartData = chartData?.filter((el) => el.name === element.id);
    dataStore.push(barChartData?.[0]?.data);
    return barChartData?.[0]?.data;
  };

  const handleP1Checkbox = () => {
    setP1Checkbox(!p1Checkbox);
  };

  const handleP2Checkbox = () => {
    setP2Checkbox(!p2Checkbox);
  };

  const handleDifference = () => {
    setDifference(!difference);
  };

  const setSelectAllvalue = (data) => {
    const checkAllSelected = data.filter((el) => el.isChecked === false);
    if (checkAllSelected.length > 0) {
      setSelectAll(false);
    } else {
      setSelectAll(true);
    }
  };

  const handleFilterClick = (val) => {
    const newFilterData = filterData.map((el) => {
      if (el.displayname === val.displayname) {
        el.isChecked = !el.isChecked;
      }
      return el;
    });
    setFilterData([...newFilterData]);
    setSelectAllvalue(newFilterData);
  };

  const AllSelect = (val) => {
    const getUpdatedfilterData = filterData.map((el) => {
      return { ...el, isChecked: val };
    });
    setFilterData(...[getUpdatedfilterData]);
    setSelectAll(val);
  };

  // useEffect(() => {
  //   getPageData();
  // }, []);

  return (
    <div
      className="page-wrapper vehicle-health-check"
      style={{ backgroundColor: "#EEEEEE" }}
    >
      <div className="flex page-content-header--filter vp-health-filter">
        {showFilter === true && (
          <Filter data={AllSelect} selectAll={selectAll}>
            {filterData.map((el) => (
              <Checkbox
                title={el.displayname}
                handleInputClick={() => handleFilterClick(el)}
                alignProp={{
                  borderTop: "0.5px solid #C4C4C4",
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "10px",
                  alignItems: "baseline",
                  flexDirection: "row-reverse",
                }}
                checkboxStyle={{ marginRight: "10px" }}
                isChecked={el.isChecked}
              />
            ))}
          </Filter>
        )}
      </div>
      <div
        className="legend-container"
        style={{
          alignItems: "end",
          justifyContent: "space-between",
          marginBottom: "30px",
        }}
      >
        <div className="health-check-checkbox" style={{ display: "flex" }}>
          <div
            className={p1Checkbox ? "checkbox-color" : "checkbox-Bordercolor"}
            style={{ marginRight: "15px" }}
            title={
              firstfilter?.filterName === undefined
                ? "Filter"
                : firstfilter?.filterName
            }
          >
            <Checkbox
              title={`P1 : ${firstfilter?.filterName === undefined
                  ? "Filter"
                  : firstfilter?.filterName
                }`}
              key={"P1"}
              isChecked={p1Checkbox}
              handleInputClick={handleP1Checkbox}
            />
          </div>
          <div
            className={p2Checkbox ? "checkbox-color" : "checkbox-Bordercolor"}
            style={{ marginRight: "15px" }}
            title={secondfilter?.filterName}
          >
            <Checkbox
              title={`P2 : ${secondfilter?.filterName}`}
              key={"P2"}
              isChecked={p2Checkbox}
              handleInputClick={handleP2Checkbox}
            />
          </div>
          <div
            className={difference ? "checkbox-color" : "checkbox-Bordercolor"}
          >
            <Checkbox
              title={"Difference"}
              key={"Difference"}
              isChecked={difference}
              handleInputClick={handleDifference}
            />
          </div>
        </div>
      </div>

      {!noDataFound && (
        <div className="usageprofile--compare">
          {!noDataFound &&
            filterData.map(
              (el) =>
                el.isChecked && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        width: "100%",
                      }}
                    >
                      <span>{el.displayname}</span>
                      <div className="legend-wrapper">
                        <div className="legend">
                          <div
                            className="green-legend"
                            style={{ marginRight: "15px" }}
                          >
                            <span className="color-box blue"></span>
                            <span
                              className="color-legend"
                              style={{
                                width: "150px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                              title={`P1 ${el.displayname}`}
                            >
                              P1 {el.displayname}
                            </span>
                          </div>
                          <div
                            className="green-legend"
                            style={{ marginRight: "15px" }}
                          >
                            <span className="color-box black"></span>
                            <span
                              className="color-legend"
                              style={{
                                width: "155px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                              title={`P2 ${el.displayname}`}
                            >
                              P2 {el.displayname}
                            </span>
                          </div>
                          <div
                            className="yellow-legend"
                            style={{ marginRight: "15px" }}
                          >
                            <span
                              className="color-box"
                              style={{ backgroundColor: "#6FAC4E" }}
                            ></span>
                            <span className="color-legend">P1 RUL </span>
                          </div>
                          <div className="yellow-legend">
                            <span
                              className="color-box"
                              style={{ backgroundColor: "#165C0B" }}
                            ></span>
                            <span className="color-legend">P2 RUL </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <DualLineGraph
                      id={el.id}
                      data={getDataForBarChart(el)}
                      title={el.displayname}
                      fromVpUp={true}
                    />
                  </>
                )
            )}
        </div>
      )}

      {noDataFound && showChartLoader && (
        <div>
          <ChartLoader />
        </div>
      )}

      {noDataFound &&
        !errorMsg &&
        !showChartLoader &&
        dataStore?.length === 0 ? (
        <div>
          <Feedback />
        </div>
      ) : !showChartLoader && dataStore?.length === 0 && !selectAll ? (
        <div>
          <Feedback />
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default VPCompareWithFilter;
