import {
  ServiceConfig,
  VehiclePopulationDashBoard,
} from "../../../services/service-constants";
import { mainAxiosInstance } from "../../../services/service";

const defaultData = async () => {
  let url = `${VehiclePopulationDashBoard.defaultApi}`;
  try {
    const response = await mainAxiosInstance.get(url);
    return {
      data: response.data,
    };
  } catch (er) {
    return {
      data: null,
    };
  }
};

export const getMakedata = async (payload) => {
  var pageSize = payload === undefined ? 0 : payload;
  const status = localStorage.getItem("VP Diagnostic/Prognostic");
  const selectedData = JSON.parse(localStorage.getItem("selectedData"));
  const selectedDiagonsticsData = JSON.parse(
    localStorage.getItem("selectedDiagonosticsData")
  );
  let params = {};
  const rul = localStorage.getItem("rul");
  let lowerLimitData = localStorage.getItem("lowerLimitData");
  let upperLimitData = localStorage.getItem("upperLimitData");
  if (rul !== null) {
    switch (rul) {
      case "Miles": {
        params = {
          fromRulMiles: parseInt(lowerLimitData),
          toRulMiles: parseInt(upperLimitData),
          rul: "by_miles",
        };
        break;
      }

      case "Engine Run Time": {
        params = {
          fromRulEngineRunTime: parseInt(lowerLimitData),
          toRulEngineRunTime: parseInt(upperLimitData),
          rul: "by_engine_runtime",
        };
        break;
      }

      case "Hours": {
        params = {
          fromRulHours: parseInt(lowerLimitData),
          toRulHours: parseInt(upperLimitData),
          rul: "by_hours",
        };
        break;
      }

      case "Key starts": {
        params = {
          fromRulKeyStarts: parseInt(lowerLimitData),
          toRulKeyStarts: parseInt(upperLimitData),
          rul: "by_key_starts",
        };
        break;
      }

      default: {
        params = {
          fromRulMiles: parseInt(lowerLimitData),
          toRulMiles: parseInt(upperLimitData),
          rul: "by_miles",
        };
      }
    }
  }

  const FilterSelectedValues = {
    ...selectedData,
    ...selectedDiagonsticsData,
  };
  const paramData = await defaultData();
  const url = `${VehiclePopulationDashBoard.make}/${status}?page=${pageSize}&size=10`;
  let filteredValues = {};
  if (Object.keys(FilterSelectedValues).length === 0) {
    filteredValues = paramData.data;
  } else {
    filteredValues = FilterSelectedValues;
  }

  let data = Object.assign(filteredValues);

  return mainAxiosInstance
    .post(url, data)
    .then((response) => {
      if (response.status === 200) {
        const detailsData = [];
        const PaginationDetails = response.data.makeCountDtos;
        const graphValues = response.data.makeCountDtos.content;
        graphValues.forEach((val) => {
          detailsData.push({
            y: val.noOfOccurrences,
            name: val.make,
            values: val.make,
          });
        });
        return {
          code: response?.status,
          data: response?.data,
          detailsData: detailsData,
          PaginationDetails: PaginationDetails,
        };
      }
      if (response.status === 401) {
        return {
          code: response?.status,
          data: response.data?.detail,
        };
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        return {
          code: err.response?.status,
          data: err.response.data?.detail || "You are not authorized",
        };
      } else {
        return {
          code: "error",
          data: err.response.data?.detail || "Something went wrong ",
        };
      }
    });
};

export const getModeldata = async (payload) => {
  var pageSize = payload === undefined ? 0 : payload;
  const status = localStorage.getItem("VP Diagnostic/Prognostic");
  const selectedData = JSON.parse(localStorage.getItem("selectedData"));
  const selectedDiagonsticsData = JSON.parse(
    localStorage.getItem("selectedDiagonosticsData")
  );
  let params = {};
  const rul = localStorage.getItem("rul");
  let lowerLimitData = localStorage.getItem("lowerLimitData");
  let upperLimitData = localStorage.getItem("upperLimitData");
  if (rul !== null) {
    switch (rul) {
      case "Miles": {
        params = {
          fromRulMiles: parseInt(lowerLimitData),
          toRulMiles: parseInt(upperLimitData),
          rul: "by_miles",
        };
        break;
      }

      case "Engine Run Time": {
        params = {
          fromRulEngineRunTime: parseInt(lowerLimitData),
          toRulEngineRunTime: parseInt(upperLimitData),
          rul: "by_engine_runtime",
        };
        break;
      }

      case "Hours": {
        params = {
          fromRulHours: parseInt(lowerLimitData),
          toRulHours: parseInt(upperLimitData),
          rul: "by_hours",
        };
        break;
      }

      case "Key starts": {
        params = {
          fromRulKeyStarts: parseInt(lowerLimitData),
          toRulKeyStarts: parseInt(upperLimitData),
          rul: "by_key_starts",
        };
        break;
      }

      default: {
        params = {
          fromRulMiles: parseInt(lowerLimitData),
          toRulMiles: parseInt(upperLimitData),
          rul: "by_miles",
        };
      }
    }
  }

  const FilterSelectedValues = {
    ...selectedData,
    ...selectedDiagonsticsData,
  };

  const paramData = await defaultData();
  const url = `${VehiclePopulationDashBoard.model}/${status}?page=${pageSize}&size=10`;
  let filteredValues = {};
  if (Object.keys(FilterSelectedValues).length === 0) {
    filteredValues = paramData.data;
  } else {
    filteredValues = FilterSelectedValues;
  }

  let data = Object.assign(filteredValues);

  return mainAxiosInstance
    .post(url, data)
    .then((response) => {
      if (response.status === 200) {
        const detailsData = [];
        const PaginationDetails = response.data.modelCountDtos;
        const graphValues = response.data.modelCountDtos.content;
        graphValues.forEach((val) => {
          detailsData.push({
            y: val.noOfOccurrences,
            name: val.model,
            values: val.model,
          });
        });
        return {
          code: response?.status,
          data: response?.data,
          detailsData: detailsData,
          PaginationDetails: PaginationDetails,
        };
      }
      if (response.status === 401) {
        return {
          code: response?.status,
          data: response.data?.detail,
        };
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        return {
          code: err.response?.status,
          data: err.response.data?.detail || "You are not authorized",
        };
      } else {
        return {
          code: "error",
          data: err.response.data?.detail || "Something went wrong ",
        };
      }
    });
};

export const getModelYeardata = async (payload) => {
  var pageSize = payload === undefined ? 0 : payload;
  const status = localStorage.getItem("VP Diagnostic/Prognostic");
  const selectedData = JSON.parse(localStorage.getItem("selectedData"));
  const selectedDiagonsticsData = JSON.parse(
    localStorage.getItem("selectedDiagonosticsData")
  );
  let params = {};
  const rul = localStorage.getItem("rul");
  let lowerLimitData = localStorage.getItem("lowerLimitData");
  let upperLimitData = localStorage.getItem("upperLimitData");
  if (rul !== null) {
    switch (rul) {
      case "Miles": {
        params = {
          fromRulMiles: parseInt(lowerLimitData),
          toRulMiles: parseInt(upperLimitData),
          rul: "by_miles",
        };
        break;
      }

      case "Engine Run Time": {
        params = {
          fromRulEngineRunTime: parseInt(lowerLimitData),
          toRulEngineRunTime: parseInt(upperLimitData),
          rul: "by_engine_runtime",
        };
        break;
      }

      case "Hours": {
        params = {
          fromRulHours: parseInt(lowerLimitData),
          toRulHours: parseInt(upperLimitData),
          rul: "by_hours",
        };
        break;
      }

      case "Key starts": {
        params = {
          fromRulKeyStarts: parseInt(lowerLimitData),
          toRulKeyStarts: parseInt(upperLimitData),
          rul: "by_key_starts",
        };
        break;
      }

      default: {
        params = {
          fromRulMiles: parseInt(lowerLimitData),
          toRulMiles: parseInt(upperLimitData),
          rul: "by_miles",
        };
      }
    }
  }

  const FilterSelectedValues = {
    ...selectedData,
    ...selectedDiagonsticsData,
  };
  const paramData = await defaultData();
  const url = `${VehiclePopulationDashBoard.model_year}/${status}?page=${pageSize}&size=10`;
  let filteredValues = {};
  if (Object.keys(FilterSelectedValues).length === 0) {
    filteredValues = paramData.data;
  } else {
    filteredValues = FilterSelectedValues;
  }

  let data = Object.assign(filteredValues);

  return mainAxiosInstance
    .post(url, data)
    .then((response) => {
      if (response.status === 200) {
        const detailsData = [];
        const graphValues = response.data.modelYearCountDtos.content;
        const PaginationDetails = response.data.modelYearCountDtos;
        graphValues.forEach((val) => {
          detailsData.push({
            y: val.noOfOccurrences,
            name: val.modelYear,
            values: val.modelYear,
          });
        });
        return {
          code: response?.status,
          data: response?.data,
          detailsData: detailsData,
          PaginationDetails: PaginationDetails,
        };
      }
      if (response.status === 401) {
        return {
          code: response?.status,
          data: response.data?.detail,
        };
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        return {
          code: err.response?.status,
          data: err.response.data?.detail || "You are not authorized",
        };
      } else {
        return {
          code: "error",
          data: err.response.data?.detail || "Something went wrong ",
        };
      }
    });
};

export const getManufacturedata = async (payload) => {
  var pageSize = payload === undefined ? 0 : payload;
  const status = localStorage.getItem("VP Diagnostic/Prognostic");
  const selectedData = JSON.parse(localStorage.getItem("selectedData"));
  const selectedDiagonsticsData = JSON.parse(
    localStorage.getItem("selectedDiagonosticsData")
  );
  let params = {};
  const rul = localStorage.getItem("rul");
  let lowerLimitData = localStorage.getItem("lowerLimitData");
  let upperLimitData = localStorage.getItem("upperLimitData");
  if (rul !== null) {
    switch (rul) {
      case "Miles": {
        params = {
          fromRulMiles: parseInt(lowerLimitData),
          toRulMiles: parseInt(upperLimitData),
          rul: "by_miles",
        };
        break;
      }

      case "Engine Run Time": {
        params = {
          fromRulEngineRunTime: parseInt(lowerLimitData),
          toRulEngineRunTime: parseInt(upperLimitData),
          rul: "by_engine_runtime",
        };
        break;
      }

      case "Hours": {
        params = {
          fromRulHours: parseInt(lowerLimitData),
          toRulHours: parseInt(upperLimitData),
          rul: "by_hours",
        };
        break;
      }

      case "Key starts": {
        params = {
          fromRulKeyStarts: parseInt(lowerLimitData),
          toRulKeyStarts: parseInt(upperLimitData),
          rul: "by_key_starts",
        };
        break;
      }

      default: {
        params = {
          fromRulMiles: parseInt(lowerLimitData),
          toRulMiles: parseInt(upperLimitData),
          rul: "by_miles",
        };
      }
    }
  }

  const FilterSelectedValues = {
    ...selectedData,
    ...selectedDiagonsticsData,
  };
  const paramData = await defaultData();
  const url = `${VehiclePopulationDashBoard.manufacting}/${status}?page=${pageSize}&size=10`;
  let filteredValues = {};
  if (Object.keys(FilterSelectedValues).length === 0) {
    filteredValues = paramData.data;
  } else {
    filteredValues = FilterSelectedValues;
  }

  let data = Object.assign(filteredValues);

  return mainAxiosInstance
    .post(url, data)
    .then((response) => {
      if (response.status === 200) {
        const detailsData = [];
        const PaginationDetails = response.data.manufacturingPlantDtos;
        const graphValues = response.data.manufacturingPlantDtos.content;
        graphValues.forEach((val) => {
          detailsData.push({
            y: val.noOfOccurrences,
            name: val.manufacturingPlant,
            values: val.manufacturingPlant,
          });
        });
        return {
          code: response?.status,
          data: response?.data,
          detailsData: detailsData,
          PaginationDetails: PaginationDetails,
        };
      }
      if (response.status === 401) {
        return {
          code: response?.status,
          data: response.data?.detail,
        };
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        return {
          code: err.response?.status,
          data: err.response.data?.detail || "You are not authorized",
        };
      } else {
        return {
          code: "error",
          data: err.response.data?.detail || "Something went wrong ",
        };
      }
    });
};

export const getBuildDatesdata = async (payload) => {
  var pageSize = payload === undefined ? 0 : payload;
  const status = localStorage.getItem("VP Diagnostic/Prognostic");
  const selectedData = JSON.parse(localStorage.getItem("selectedData"));
  const selectedDiagonsticsData = JSON.parse(
    localStorage.getItem("selectedDiagonosticsData")
  );
  let params = {};
  const rul = localStorage.getItem("rul");
  let lowerLimitData = localStorage.getItem("lowerLimitData");
  let upperLimitData = localStorage.getItem("upperLimitData");
  if (rul !== null) {
    switch (rul) {
      case "Miles": {
        params = {
          fromRulMiles: parseInt(lowerLimitData),
          toRulMiles: parseInt(upperLimitData),
          rul: "by_miles",
        };
        break;
      }

      case "Engine Run Time": {
        params = {
          fromRulEngineRunTime: parseInt(lowerLimitData),
          toRulEngineRunTime: parseInt(upperLimitData),
          rul: "by_engine_runtime",
        };
        break;
      }

      case "Hours": {
        params = {
          fromRulHours: parseInt(lowerLimitData),
          toRulHours: parseInt(upperLimitData),
          rul: "by_hours",
        };
        break;
      }

      case "Key starts": {
        params = {
          fromRulKeyStarts: parseInt(lowerLimitData),
          toRulKeyStarts: parseInt(upperLimitData),
          rul: "by_key_starts",
        };
        break;
      }

      default: {
        params = {
          fromRulMiles: parseInt(lowerLimitData),
          toRulMiles: parseInt(upperLimitData),
          rul: "by_miles",
        };
      }
    }
  }

  const FilterSelectedValues = {
    ...selectedData,
    ...selectedDiagonsticsData,
  };
  const paramData = await defaultData();
  const url = `${VehiclePopulationDashBoard.build_dates}/${status}?page=${pageSize}&size=10`;
  let filteredValues = {};
  if (Object.keys(FilterSelectedValues).length === 0) {
    filteredValues = paramData.data;
  } else {
    filteredValues = FilterSelectedValues;
  }

  let data = Object.assign(filteredValues);

  return mainAxiosInstance
    .post(url, data)
    .then((response) => {
      if (response.status === 200) {
        const detailsData = [];
        const PaginationDetails = response.data.buildDatesDtos;
        const graphValues = response.data.buildDatesDtos.content;
        graphValues.forEach((val) => {
          detailsData.push({ y: val.noOfOccurrences, values: val.buildDates });
        });
        // const names=[];
        // graphValues.forEach((val) => {
        //     detailsData.push({ name: val.buildDates });
        // })
        return {
          code: response?.status,
          data: response?.data,
          detailsData: detailsData,
          PaginationDetails: PaginationDetails,
        };
      }
      if (response.status === 401) {
        return {
          code: response?.status,
          data: response.data?.detail,
        };
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        return {
          code: err.response?.status,
          data: err.response.data?.detail || "You are not authorized",
        };
      } else {
        return {
          code: "error",
          data: err.response.data?.detail || "Something went wrong ",
        };
      }
    });
};

export const getPowerPackdata = async (payload) => {
  var pageSize = payload === undefined ? 0 : payload;
  const status = localStorage.getItem("VP Diagnostic/Prognostic");
  const selectedData = JSON.parse(localStorage.getItem("selectedData"));
  const selectedDiagonsticsData = JSON.parse(
    localStorage.getItem("selectedDiagonosticsData")
  );
  let params = {};
  const rul = localStorage.getItem("rul");
  let lowerLimitData = localStorage.getItem("lowerLimitData");
  let upperLimitData = localStorage.getItem("upperLimitData");
  if (rul !== null) {
    switch (rul) {
      case "Miles": {
        params = {
          fromRulMiles: parseInt(lowerLimitData),
          toRulMiles: parseInt(upperLimitData),
          rul: "by_miles",
        };
        break;
      }

      case "Engine Run Time": {
        params = {
          fromRulEngineRunTime: parseInt(lowerLimitData),
          toRulEngineRunTime: parseInt(upperLimitData),
          rul: "by_engine_runtime",
        };
        break;
      }

      case "Hours": {
        params = {
          fromRulHours: parseInt(lowerLimitData),
          toRulHours: parseInt(upperLimitData),
          rul: "by_hours",
        };
        break;
      }

      case "Key starts": {
        params = {
          fromRulKeyStarts: parseInt(lowerLimitData),
          toRulKeyStarts: parseInt(upperLimitData),
          rul: "by_key_starts",
        };
        break;
      }

      default: {
        params = {
          fromRulMiles: parseInt(lowerLimitData),
          toRulMiles: parseInt(upperLimitData),
          rul: "by_miles",
        };
      }
    }
  }

  const FilterSelectedValues = {
    ...selectedData,
    ...selectedDiagonsticsData,
  };
  const paramData = await defaultData();
  const url = `${VehiclePopulationDashBoard.powerpack}/${status}?page=${pageSize}&size=10`;
  let filteredValues = {};
  if (Object.keys(FilterSelectedValues).length === 0) {
    filteredValues = paramData.data;
  } else {
    filteredValues = FilterSelectedValues;
  }

  let data = Object.assign(filteredValues);

  return mainAxiosInstance
    .post(url, data)
    .then((response) => {
      if (response.status === 200) {
        const detailsData = [];
        const graphValues = response.data.powerPackDto.content;
        const PaginationDetails = response.data.powerPackDto;
        graphValues.forEach((val) => {
          detailsData.push({ y: val.noOfOccurrences, values: val.powerPack });
        });
        return {
          code: response?.status,
          data: response?.data,
          detailsData: detailsData,
          PaginationDetails: PaginationDetails,
        };
      }
      if (response.status === 401) {
        return {
          code: response?.status,
          data: response.data?.detail,
        };
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        return {
          code: err.response?.status,
          data: err.response.data?.detail || "You are not authorized",
        };
      } else {
        return {
          code: "error",
          data: err.response.data?.detail || "Something went wrong ",
        };
      }
    });
};

export const getSubSystemdata = async (payload) => {
  var pageSize = payload === undefined ? 0 : payload;
  const status = localStorage.getItem("VP Diagnostic/Prognostic");
  const selectedData = JSON.parse(localStorage.getItem("selectedData"));
  const selectedDiagonsticsData = JSON.parse(
    localStorage.getItem("selectedDiagonosticsData")
  );
  let params = {};
  const rul = localStorage.getItem("rul");
  let lowerLimitData = localStorage.getItem("lowerLimitData");
  let upperLimitData = localStorage.getItem("upperLimitData");
  if (rul !== null) {
    switch (rul) {
      case "Miles": {
        params = {
          fromRulMiles: parseInt(lowerLimitData),
          toRulMiles: parseInt(upperLimitData),
          rul: "by_miles",
        };
        break;
      }

      case "Engine Run Time": {
        params = {
          fromRulEngineRunTime: parseInt(lowerLimitData),
          toRulEngineRunTime: parseInt(upperLimitData),
          rul: "by_engine_runtime",
        };
        break;
      }

      case "Hours": {
        params = {
          fromRulHours: parseInt(lowerLimitData),
          toRulHours: parseInt(upperLimitData),
          rul: "by_hours",
        };
        break;
      }

      case "Key starts": {
        params = {
          fromRulKeyStarts: parseInt(lowerLimitData),
          toRulKeyStarts: parseInt(upperLimitData),
          rul: "by_key_starts",
        };
        break;
      }

      default: {
        params = {
          fromRulMiles: parseInt(lowerLimitData),
          toRulMiles: parseInt(upperLimitData),
          rul: "by_miles",
        };
      }
    }
  }

  const FilterSelectedValues = {
    ...selectedData,
    ...selectedDiagonsticsData,
  };
  const paramData = await defaultData();
  const url = `${VehiclePopulationDashBoard.sub_system}/${status}?page=${pageSize}&size=10`;
  let filteredValues = {};
  if (Object.keys(FilterSelectedValues).length === 0) {
    filteredValues = paramData.data;
  } else {
    filteredValues = FilterSelectedValues;
  }

  let data = Object.assign(filteredValues);

  return mainAxiosInstance
    .post(url, data)
    .then((response) => {
      if (response.status === 200) {
        const detailsData = [];
        const PaginationDetails = response.data.subSystemCountDtos;
        const graphValues = response.data.subSystemCountDtos.content;
        graphValues.forEach((val) => {
          detailsData.push({
            y: val.noOfOccurrences,
            name: val.subSystem,
            values: val.subSystem,
          });
        });
        return {
          code: response?.status,
          data: response?.data,
          detailsData: detailsData,
          PaginationDetails: PaginationDetails,
        };
      }
      if (response.status === 401) {
        return {
          code: response?.status,
          data: response.data?.detail,
        };
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        return {
          code: err.response?.status,
          data: err.response.data?.detail || "You are not authorized",
        };
      } else {
        return {
          code: "error",
          data: err.response.data?.detail || "Something went wrong ",
        };
      }
    });
};

export const getSoftwaredata = async (payload) => {
  var pageSize = payload === undefined ? 0 : payload;
  const status = localStorage.getItem("VP Diagnostic/Prognostic");
  const selectedData = JSON.parse(localStorage.getItem("selectedData"));
  const selectedDiagonsticsData = JSON.parse(
    localStorage.getItem("selectedDiagonosticsData")
  );
  let params = {};
  const rul = localStorage.getItem("rul");
  let lowerLimitData = localStorage.getItem("lowerLimitData");
  let upperLimitData = localStorage.getItem("upperLimitData");
  if (rul !== null) {
    switch (rul) {
      case "Miles": {
        params = {
          fromRulMiles: parseInt(lowerLimitData),
          toRulMiles: parseInt(upperLimitData),
          rul: "by_miles",
        };
        break;
      }

      case "Engine Run Time": {
        params = {
          fromRulEngineRunTime: parseInt(lowerLimitData),
          toRulEngineRunTime: parseInt(upperLimitData),
          rul: "by_engine_runtime",
        };
        break;
      }

      case "Hours": {
        params = {
          fromRulHours: parseInt(lowerLimitData),
          toRulHours: parseInt(upperLimitData),
          rul: "by_hours",
        };
        break;
      }

      case "Key starts": {
        params = {
          fromRulKeyStarts: parseInt(lowerLimitData),
          toRulKeyStarts: parseInt(upperLimitData),
          rul: "by_key_starts",
        };
        break;
      }

      default: {
        params = {
          fromRulMiles: parseInt(lowerLimitData),
          toRulMiles: parseInt(upperLimitData),
          rul: "by_miles",
        };
      }
    }
  }

  const FilterSelectedValues = {
    ...selectedData,
    ...selectedDiagonsticsData,
  };
  const paramData = await defaultData();
  const url = `${VehiclePopulationDashBoard.software_version}/${status}?page=${pageSize}&size=10`;
  let filteredValues = {};
  if (Object.keys(FilterSelectedValues).length === 0) {
    filteredValues = paramData.data;
  } else {
    filteredValues = FilterSelectedValues;
  }

  let data = Object.assign(filteredValues);

  return mainAxiosInstance
    .post(url, data)
    .then((response) => {
      if (response.status === 200) {
        const detailsData = [];
        const graphValues = response.data.softwareResponseDtos.content;
        const PaginationDetails = response.data.softwareResponseDtos;
        graphValues.forEach((val) => {
          detailsData.push({
            y: val.noOfOccurrences,
            values: val.softwareVersion,
            name: val.version,
            color: val.color,
          });
        });
        return {
          code: response?.status,
          data: response?.data,
          detailsData: detailsData,
          PaginationDetails: PaginationDetails,
        };
      }
      if (response.status === 401) {
        return {
          code: response?.status,
          data: response.data?.detail,
        };
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        return {
          code: err.response?.status,
          data: err.response.data?.detail || "You are not authorized",
        };
      } else {
        return {
          code: "error",
          data: err.response.data?.detail || "Something went wrong ",
        };
      }
    });
};

export const getCalibrationdata = async (payload) => {
  var pageSize = payload === undefined ? 0 : payload;
  const status = localStorage.getItem("VP Diagnostic/Prognostic");
  const selectedData = JSON.parse(localStorage.getItem("selectedData"));
  const selectedDiagonsticsData = JSON.parse(
    localStorage.getItem("selectedDiagonosticsData")
  );
  let params = {};
  const rul = localStorage.getItem("rul");
  let lowerLimitData = localStorage.getItem("lowerLimitData");
  let upperLimitData = localStorage.getItem("upperLimitData");
  if (rul !== null) {
    switch (rul) {
      case "Miles": {
        params = {
          fromRulMiles: parseInt(lowerLimitData),
          toRulMiles: parseInt(upperLimitData),
          rul: "by_miles",
        };
        break;
      }

      case "Engine Run Time": {
        params = {
          fromRulEngineRunTime: parseInt(lowerLimitData),
          toRulEngineRunTime: parseInt(upperLimitData),
          rul: "by_engine_runtime",
        };
        break;
      }

      case "Hours": {
        params = {
          fromRulHours: parseInt(lowerLimitData),
          toRulHours: parseInt(upperLimitData),
          rul: "by_hours",
        };
        break;
      }

      case "Key starts": {
        params = {
          fromRulKeyStarts: parseInt(lowerLimitData),
          toRulKeyStarts: parseInt(upperLimitData),
          rul: "by_key_starts",
        };
        break;
      }

      default: {
        params = {
          fromRulMiles: parseInt(lowerLimitData),
          toRulMiles: parseInt(upperLimitData),
          rul: "by_miles",
        };
      }
    }
  }

  const FilterSelectedValues = {
    ...selectedData,
    ...selectedDiagonsticsData,
  };
  const paramData = await defaultData();
  const url = `${VehiclePopulationDashBoard.calibration_version}/${status}?page=${pageSize}&size=10`;
  let filteredValues = {};
  if (Object.keys(FilterSelectedValues).length === 0) {
    filteredValues = paramData.data;
  } else {
    filteredValues = FilterSelectedValues;
  }

  let data = Object.assign(filteredValues);

  return mainAxiosInstance
    .post(url, data)
    .then((response) => {
      if (response.status === 200) {
        const detailsData = [];
        const graphValues = response.data.calibrationResponseDtos.content;
        const PaginationDetails = response.data.calibrationResponseDtos;
        graphValues.forEach((val) => {
          detailsData.push({
            y: val.noOfOccurrences,
            name: val.version,
            values: val.calibrationVersion,
            color: val.color,
          });
        });
        return {
          code: response?.status,
          data: response?.data,
          detailsData: detailsData,
          PaginationDetails: PaginationDetails,
        };
      }
      if (response.status === 401) {
        return {
          code: response?.status,
          data: response.data?.detail,
        };
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        return {
          code: err.response?.status,
          data: err.response.data?.detail || "You are not authorized",
        };
      } else {
        return {
          code: "error",
          data: err.response.data?.detail || "Something went wrong ",
        };
      }
    });
};

export const getdtcdata = async (payload) => {
  var pageSize = payload === undefined ? 0 : payload;
  const status = localStorage.getItem("VP Diagnostic/Prognostic");
  const selectedData = JSON.parse(localStorage.getItem("selectedData"));
  const selectedDiagonsticsData = JSON.parse(
    localStorage.getItem("selectedDiagonosticsData")
  );
  let params = {};
  const rul = localStorage.getItem("rul");
  let lowerLimitData = localStorage.getItem("lowerLimitData");
  let upperLimitData = localStorage.getItem("upperLimitData");
  if (rul !== null) {
    switch (rul) {
      case "Miles": {
        params = {
          fromRulMiles: parseInt(lowerLimitData),
          toRulMiles: parseInt(upperLimitData),
          rul: "by_miles",
        };
        break;
      }

      case "Engine Run Time": {
        params = {
          fromRulEngineRunTime: parseInt(lowerLimitData),
          toRulEngineRunTime: parseInt(upperLimitData),
          rul: "by_engine_runtime",
        };
        break;
      }

      case "Hours": {
        params = {
          fromRulHours: parseInt(lowerLimitData),
          toRulHours: parseInt(upperLimitData),
          rul: "by_hours",
        };
        break;
      }

      case "Key starts": {
        params = {
          fromRulKeyStarts: parseInt(lowerLimitData),
          toRulKeyStarts: parseInt(upperLimitData),
          rul: "by_key_starts",
        };
        break;
      }

      default: {
        params = {
          fromRulMiles: parseInt(lowerLimitData),
          toRulMiles: parseInt(upperLimitData),
          rul: "by_miles",
        };
      }
    }
  }

  const FilterSelectedValues = {
    ...selectedData,
    ...selectedDiagonsticsData,
  };
  const paramData = await defaultData();
  const url = `${VehiclePopulationDashBoard.dtc}/${status}?page=${pageSize}&size=10`;
  let filteredValues = {};
  if (Object.keys(FilterSelectedValues).length === 0) {
    filteredValues = paramData.data;
  } else {
    filteredValues = FilterSelectedValues;
  }

  let data = Object.assign(filteredValues);

  return mainAxiosInstance
    .post(url, data)
    .then((response) => {
      if (response.status === 200) {
        const detailsData = [];
        const graphValues = response.data.dtcCountDtos.content;
        const PaginationDetails = response.data.dtcCountDtos;
        graphValues.forEach((val) => {
          detailsData.push({
            y: val.noOfOccurrences,
            name: val.dtc,
            values: val.dtc,
          });
        });
        return {
          code: response?.status,
          data: response?.data,
          detailsData: detailsData,
          PaginationDetails: PaginationDetails,
        };
      }
      if (response.status === 401) {
        return {
          code: response?.status,
          data: response.data?.detail,
        };
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        return {
          code: err.response?.status,
          data: err.response.data?.detail || "You are not authorized",
        };
      } else {
        return {
          code: "error",
          data: err.response.data?.detail || "Something went wrong ",
        };
      }
    });
};

export const gettemperaturedata = async (payload) => {
  var pageSize = payload === undefined ? 0 : payload;
  const status = localStorage.getItem("VP Diagnostic/Prognostic");
  const selectedData = JSON.parse(localStorage.getItem("selectedData"));
  const selectedDiagonsticsData = JSON.parse(
    localStorage.getItem("selectedDiagonosticsData")
  );
  let params = {};
  const rul = localStorage.getItem("rul");
  let lowerLimitData = localStorage.getItem("lowerLimitData");
  let upperLimitData = localStorage.getItem("upperLimitData");
  if (rul !== null) {
    switch (rul) {
      case "Miles": {
        params = {
          fromRulMiles: parseInt(lowerLimitData),
          toRulMiles: parseInt(upperLimitData),
          rul: "by_miles",
        };
        break;
      }

      case "Engine Run Time": {
        params = {
          fromRulEngineRunTime: parseInt(lowerLimitData),
          toRulEngineRunTime: parseInt(upperLimitData),
          rul: "by_engine_runtime",
        };
        break;
      }

      case "Hours": {
        params = {
          fromRulHours: parseInt(lowerLimitData),
          toRulHours: parseInt(upperLimitData),
          rul: "by_hours",
        };
        break;
      }

      case "Key starts": {
        params = {
          fromRulKeyStarts: parseInt(lowerLimitData),
          toRulKeyStarts: parseInt(upperLimitData),
          rul: "by_key_starts",
        };
        break;
      }

      default: {
        params = {
          fromRulMiles: parseInt(lowerLimitData),
          toRulMiles: parseInt(upperLimitData),
          rul: "by_miles",
        };
      }
    }
  }

  const FilterSelectedValues = {
    ...selectedData,
    ...selectedDiagonsticsData,
  };
  const paramData = await defaultData();
  const url = `${VehiclePopulationDashBoard.temperature}/${status}?page=${pageSize}&size=10`;
  let filteredValues = {};
  if (Object.keys(FilterSelectedValues).length === 0) {
    filteredValues = paramData.data;
  } else {
    filteredValues = FilterSelectedValues;
  }

  let data = Object.assign(filteredValues);

  return mainAxiosInstance
    .post(url, data)
    .then((response) => {
      if (response.status === 200) {
        const detailsData = [];
        const graphValues = response.data.temperatureCountDtos.content;
        const PaginationDetails = response.data.temperatureCountDtos;
        graphValues.forEach((val) => {
          detailsData.push({
            y: val.noOfOccurrences,
            name: val.ambientTemperature,
            values: "Temperature :" + val.ambientTemperature,
          });
        });
        return {
          code: response?.status,
          data: response?.data,
          detailsData: detailsData,
          PaginationDetails: PaginationDetails,
        };
      }
      if (response.status === 401) {
        return {
          code: response?.status,
          data: response.data?.detail,
        };
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        return {
          code: err.response?.status,
          data: err.response.data?.detail || "You are not authorized",
        };
      } else {
        return {
          code: "error",
          data: err.response.data?.detail || "Something went wrong ",
        };
      }
    });
};

export const getAltitudedata = async (payload) => {
  var pageSize = payload === undefined ? 0 : payload;
  const status = localStorage.getItem("VP Diagnostic/Prognostic");
  const selectedData = JSON.parse(localStorage.getItem("selectedData"));
  const selectedDiagonsticsData = JSON.parse(
    localStorage.getItem("selectedDiagonosticsData")
  );
  let params = {};
  const rul = localStorage.getItem("rul");
  let lowerLimitData = localStorage.getItem("lowerLimitData");
  let upperLimitData = localStorage.getItem("upperLimitData");
  if (rul !== null) {
    switch (rul) {
      case "Miles": {
        params = {
          fromRulMiles: parseInt(lowerLimitData),
          toRulMiles: parseInt(upperLimitData),
          rul: "by_miles",
        };
        break;
      }

      case "Engine Run Time": {
        params = {
          fromRulEngineRunTime: parseInt(lowerLimitData),
          toRulEngineRunTime: parseInt(upperLimitData),
          rul: "by_engine_runtime",
        };
        break;
      }

      case "Hours": {
        params = {
          fromRulHours: parseInt(lowerLimitData),
          toRulHours: parseInt(upperLimitData),
          rul: "by_hours",
        };
        break;
      }

      case "Key starts": {
        params = {
          fromRulKeyStarts: parseInt(lowerLimitData),
          toRulKeyStarts: parseInt(upperLimitData),
          rul: "by_key_starts",
        };
        break;
      }

      default: {
        params = {
          fromRulMiles: parseInt(lowerLimitData),
          toRulMiles: parseInt(upperLimitData),
          rul: "by_miles",
        };
      }
    }
  }

  const FilterSelectedValues = {
    ...selectedData,
    ...selectedDiagonsticsData,
  };
  const paramData = await defaultData();
  const url = `${VehiclePopulationDashBoard.altitude}/${status}?page=${pageSize}&size=10`;
  let filteredValues = {};
  if (Object.keys(FilterSelectedValues).length === 0) {
    filteredValues = paramData.data;
  } else {
    filteredValues = FilterSelectedValues;
  }

  let data = Object.assign(filteredValues);

  return mainAxiosInstance

    .post(url, data)
    .then((response) => {
      if (response.status === 200) {
        const detailsData = [];
        const graphValues = response.data.altitudeCountDtos.content;
        const PaginationDetails = response.data.altitudeCountDtos;
        graphValues.forEach((val) => {
          detailsData.push({
            y: val.noOfOccurrences,
            name: val.altitude,
            values: val.altitude,
          });
        });
        return {
          code: response?.status,
          data: response?.data,
          detailsData: detailsData,
          PaginationDetails: PaginationDetails,
        };
      }
      if (response.status === 401) {
        return {
          code: response?.status,
          data: response.data?.detail,
        };
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        return {
          code: err.response?.status,
          data: err.response.data?.detail || "You are not authorized",
        };
      } else {
        return {
          code: "error",
          data: err.response.data?.detail || "Something went wrong ",
        };
      }
    });
};

export const getSystemStatedata = async (payload) => {
  var pageSize = payload === undefined ? 0 : payload;
  const status = localStorage.getItem("VP Diagnostic/Prognostic");
  const selectedData = JSON.parse(localStorage.getItem("selectedData"));
  const selectedDiagonsticsData = JSON.parse(
    localStorage.getItem("selectedDiagonosticsData")
  );
  let params = {};
  const rul = localStorage.getItem("rul");
  let lowerLimitData = localStorage.getItem("lowerLimitData");
  let upperLimitData = localStorage.getItem("upperLimitData");
  if (rul !== null) {
    switch (rul) {
      case "Miles": {
        params = {
          fromRulMiles: parseInt(lowerLimitData),
          toRulMiles: parseInt(upperLimitData),
          rul: "by_miles",
        };
        break;
      }

      case "Engine Run Time": {
        params = {
          fromRulEngineRunTime: parseInt(lowerLimitData),
          toRulEngineRunTime: parseInt(upperLimitData),
          rul: "by_engine_runtime",
        };
        break;
      }

      case "Hours": {
        params = {
          fromRulHours: parseInt(lowerLimitData),
          toRulHours: parseInt(upperLimitData),
          rul: "by_hours",
        };
        break;
      }

      case "Key starts": {
        params = {
          fromRulKeyStarts: parseInt(lowerLimitData),
          toRulKeyStarts: parseInt(upperLimitData),
          rul: "by_key_starts",
        };
        break;
      }

      default: {
        params = {
          fromRulMiles: parseInt(lowerLimitData),
          toRulMiles: parseInt(upperLimitData),
          rul: "by_miles",
        };
      }
    }
  }

  const FilterSelectedValues = {
    ...selectedData,
    ...selectedDiagonsticsData,
  };
  const paramData = await defaultData();
  const url = `${VehiclePopulationDashBoard.system_state}/${status}?page=${pageSize}&size=10`;
  let filteredValues = {};
  if (Object.keys(FilterSelectedValues).length === 0) {
    filteredValues = paramData.data;
  } else {
    filteredValues = FilterSelectedValues;
  }

  let data = Object.assign(filteredValues);

  return mainAxiosInstance
    .post(url, data)
    .then((response) => {
      if (response.status === 200) {
        const detailsData = [];
        const graphValues = response.data.systemStateCountDtos.content;
        const PaginationDetails = response.data.systemStateCountDtos;
        graphValues.forEach((val) => {
          detailsData.push({
            y: val.noOfOccurrences,
            name: val.systemState,
            values: val.systemState,
          });
        });
        return {
          code: response?.status,
          data: response?.data,
          detailsData: detailsData,
          PaginationDetails: PaginationDetails,
        };
      }
      if (response.status === 401) {
        return {
          code: response?.status,
          data: response.data?.detail,
        };
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        return {
          code: err.response?.status,
          data: err.response.data?.detail || "You are not authorized",
        };
      } else {
        return {
          code: "error",
          data: err.response.data?.detail || "Something went wrong ",
        };
      }
    });
};

export const getOperatingdata = async (payload) => {
  var pageSize = payload === undefined ? 0 : payload;
  const status = localStorage.getItem("VP Diagnostic/Prognostic");
  const selectedData = JSON.parse(localStorage.getItem("selectedData"));
  const selectedDiagonsticsData = JSON.parse(
    localStorage.getItem("selectedDiagonosticsData")
  );
  let params = {};
  const rul = localStorage.getItem("rul");
  let lowerLimitData = localStorage.getItem("lowerLimitData");
  let upperLimitData = localStorage.getItem("upperLimitData");
  if (rul !== null) {
    switch (rul) {
      case "Miles": {
        params = {
          fromRulMiles: parseInt(lowerLimitData),
          toRulMiles: parseInt(upperLimitData),
          rul: "by_miles",
        };
        break;
      }

      case "Engine Run Time": {
        params = {
          fromRulEngineRunTime: parseInt(lowerLimitData),
          toRulEngineRunTime: parseInt(upperLimitData),
          rul: "by_engine_runtime",
        };
        break;
      }

      case "Hours": {
        params = {
          fromRulHours: parseInt(lowerLimitData),
          toRulHours: parseInt(upperLimitData),
          rul: "by_hours",
        };
        break;
      }

      case "Key starts": {
        params = {
          fromRulKeyStarts: parseInt(lowerLimitData),
          toRulKeyStarts: parseInt(upperLimitData),
          rul: "by_key_starts",
        };
        break;
      }

      default: {
        params = {
          fromRulMiles: parseInt(lowerLimitData),
          toRulMiles: parseInt(upperLimitData),
          rul: "by_miles",
        };
      }
    }
  }

  const FilterSelectedValues = {
    ...selectedData,
    ...selectedDiagonsticsData,
  };
  const paramData = await defaultData();
  const url = `${VehiclePopulationDashBoard.operating_state}/${status}?page=${pageSize}&size=10`;
  let filteredValues = {};
  if (Object.keys(FilterSelectedValues).length === 0) {
    filteredValues = paramData.data;
  } else {
    filteredValues = FilterSelectedValues;
  }

  let data = Object.assign(filteredValues);

  return mainAxiosInstance
    .post(url, data)
    .then((response) => {
      if (response.status === 200) {
        const detailsData = [];
        const graphValues = response.data.operatingStateCountDtos.content;
        const PaginationDetails = response.data.operatingStateCountDtos;
        graphValues.forEach((val) => {
          detailsData.push({
            y: val.noOfOccurrences,
            name: val.operatingState,
            values: val.operatingState,
            PaginationDetails: PaginationDetails,
          });
        });
        return {
          code: response?.status,
          data: response?.data,
          detailsData: detailsData,
        };
      }
      if (response.status === 401) {
        return {
          code: response?.status,
          data: response.data?.detail,
        };
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        return {
          code: err.response?.status,
          data: err.response.data?.detail || "You are not authorized",
        };
      } else {
        return {
          code: "error",
          data: err.response.data?.detail || "Something went wrong ",
        };
      }
    });
};

export const getCustomerdata = async (payload) => {
  var pageSize = payload === undefined ? 0 : payload;
  const status = localStorage.getItem("VP Diagnostic/Prognostic");
  const selectedData = JSON.parse(localStorage.getItem("selectedData"));
  const selectedDiagonsticsData = JSON.parse(
    localStorage.getItem("selectedDiagonosticsData")
  );
  let params = {};
  const rul = localStorage.getItem("rul");
  let lowerLimitData = localStorage.getItem("lowerLimitData");
  let upperLimitData = localStorage.getItem("upperLimitData");
  if (rul !== null) {
    switch (rul) {
      case "Miles": {
        params = {
          fromRulMiles: parseInt(lowerLimitData),
          toRulMiles: parseInt(upperLimitData),
          rul: "by_miles",
        };
        break;
      }

      case "Engine Run Time": {
        params = {
          fromRulEngineRunTime: parseInt(lowerLimitData),
          toRulEngineRunTime: parseInt(upperLimitData),
          rul: "by_engine_runtime",
        };
        break;
      }

      case "Hours": {
        params = {
          fromRulHours: parseInt(lowerLimitData),
          toRulHours: parseInt(upperLimitData),
          rul: "by_hours",
        };
        break;
      }

      case "Key starts": {
        params = {
          fromRulKeyStarts: parseInt(lowerLimitData),
          toRulKeyStarts: parseInt(upperLimitData),
          rul: "by_key_starts",
        };
        break;
      }

      default: {
        params = {
          fromRulMiles: parseInt(lowerLimitData),
          toRulMiles: parseInt(upperLimitData),
          rul: "by_miles",
        };
      }
    }
  }

  const FilterSelectedValues = {
    ...selectedData,
    ...selectedDiagonsticsData,
  };
  const paramData = await defaultData();
  const url = `${VehiclePopulationDashBoard.customer_complaints}/${status}?page=${pageSize}&size=10`;
  let filteredValues = {};
  if (Object.keys(FilterSelectedValues).length === 0) {
    filteredValues = paramData.data;
  } else {
    filteredValues = FilterSelectedValues;
  }

  let data = Object.assign(filteredValues);

  return mainAxiosInstance
    .post(url, data)
    .then((response) => {
      if (response.status === 200) {
        const detailsData = [];
        const graphValues = response.data.customerComplaintCountDtos.content;
        const PaginationDetails = response.data.customerComplaintCountDtos;
        graphValues.forEach((val) => {
          detailsData.push({
            y: val.noOfOccurrences,
            name: val.customerComplaint,
            values: val.customerComplaint,
          });
        });
        return {
          code: response?.status,
          data: response?.data,
          detailsData: detailsData,
          PaginationDetails: PaginationDetails,
        };
      }
      if (response.status === 401) {
        return {
          code: response?.status,
          data: response.data?.detail,
        };
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        return {
          code: err.response?.status,
          data: err.response.data?.detail || "You are not authorized",
        };
      } else {
        return {
          code: "error",
          data: err.response.data?.detail || "Something went wrong ",
        };
      }
    });
};

export const getPercentageSettingdata = async (payload) => {
  var pageSize = payload === undefined ? 0 : payload;
  const status = localStorage.getItem("VP Diagnostic/Prognostic");
  const selectedData = JSON.parse(localStorage.getItem("selectedData"));
  const selectedDiagonsticsData = JSON.parse(
    localStorage.getItem("selectedDiagonosticsData")
  );
  let params = {};
  const rul = localStorage.getItem("rul");
  let lowerLimitData = localStorage.getItem("lowerLimitData");
  let upperLimitData = localStorage.getItem("upperLimitData");
  if (rul !== null) {
    switch (rul) {
      case "Miles": {
        params = {
          fromRulMiles: parseInt(lowerLimitData),
          toRulMiles: parseInt(upperLimitData),
          rul: "by_miles",
        };
        break;
      }

      case "Engine Run Time": {
        params = {
          fromRulEngineRunTime: parseInt(lowerLimitData),
          toRulEngineRunTime: parseInt(upperLimitData),
          rul: "by_engine_runtime",
        };
        break;
      }

      case "Hours": {
        params = {
          fromRulHours: parseInt(lowerLimitData),
          toRulHours: parseInt(upperLimitData),
          rul: "by_hours",
        };
        break;
      }

      case "Key starts": {
        params = {
          fromRulKeyStarts: parseInt(lowerLimitData),
          toRulKeyStarts: parseInt(upperLimitData),
          rul: "by_key_starts",
        };
        break;
      }

      default: {
        params = {
          fromRulMiles: parseInt(lowerLimitData),
          toRulMiles: parseInt(upperLimitData),
          rul: "by_miles",
        };
      }
    }
  }

  const FilterSelectedValues = {
    ...selectedData,
    ...selectedDiagonsticsData,
  };

  const paramData = await defaultData();
  const url = `${VehiclePopulationDashBoard.percentage_setting_dtc}/${status}?page=${pageSize}&size=10`;
  let filteredValues = {};
  if (Object.keys(FilterSelectedValues).length === 0) {
    filteredValues = paramData.data;
  } else {
    filteredValues = FilterSelectedValues;
  }

  let data = Object.assign(filteredValues);

  return mainAxiosInstance
    .post(url, data)
    .then((response) => {
      if (response.status === 200) {
        const detailsData = [];
        const graphValues =
          response.data.percentageOfPopulationSettingDtcDto.content;
        const PaginationDetails =
          response.data.percentageOfPopulationSettingDtcDto;
        graphValues.forEach((val) => {
          detailsData.push({
            y: val.percentageOfPopulationSettingDtc,
            name: val.miles,
            values: val.miles,
          });
        });
        return {
          code: response?.status,
          data: response?.data,
          detailsData: detailsData,
          PaginationDetails: PaginationDetails,
        };
      }
      if (response.status === 401) {
        return {
          code: response?.status,
          data: response.data?.detail,
        };
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        return {
          code: err.response?.status,
          data: err.response.data?.detail || "You are not authorized",
        };
      } else {
        return {
          code: "error",
          data: err.response.data?.detail || "Something went wrong ",
        };
      }
    });
};
export const getWarrantydata = async (payload) => {
  var pageSize = payload === undefined ? 0 : payload;
  const status = localStorage.getItem("VP Diagnostic/Prognostic");
  const selectedData = JSON.parse(localStorage.getItem("selectedData"));
  const selectedDiagonsticsData = JSON.parse(
    localStorage.getItem("selectedDiagonosticsData")
  );
  let params = {};
  const rul = localStorage.getItem("rul");
  let lowerLimitData = localStorage.getItem("lowerLimitData");
  let upperLimitData = localStorage.getItem("upperLimitData");
  if (rul !== null) {
    switch (rul) {
      case "Miles": {
        params = {
          fromRulMiles: parseInt(lowerLimitData),
          toRulMiles: parseInt(upperLimitData),
          rul: "by_miles",
        };
        break;
      }

      case "Engine Run Time": {
        params = {
          fromRulEngineRunTime: parseInt(lowerLimitData),
          toRulEngineRunTime: parseInt(upperLimitData),
          rul: "by_engine_runtime",
        };
        break;
      }

      case "Hours": {
        params = {
          fromRulHours: parseInt(lowerLimitData),
          toRulHours: parseInt(upperLimitData),
          rul: "by_hours",
        };
        break;
      }

      case "Key starts": {
        params = {
          fromRulKeyStarts: parseInt(lowerLimitData),
          toRulKeyStarts: parseInt(upperLimitData),
          rul: "by_key_starts",
        };
        break;
      }

      default: {
        params = {
          fromRulMiles: parseInt(lowerLimitData),
          toRulMiles: parseInt(upperLimitData),
          rul: "by_miles",
        };
      }
    }
  }

  const FilterSelectedValues = {
    ...selectedData,
    ...selectedDiagonsticsData,
  };
  const paramData = await defaultData();
  const url = `${VehiclePopulationDashBoard.warranty}/${status}?page=${pageSize}&size=10`;
  let filteredValues = {};
  if (Object.keys(FilterSelectedValues).length === 0) {
    filteredValues = paramData.data;
  } else {
    filteredValues = FilterSelectedValues;
  }

  let data = Object.assign(filteredValues);

  return mainAxiosInstance
    .post(url, data)
    .then((response) => {
      if (response.status === 200) {
        const detailsData = [];
        const graphValues = response.data.dtcWarrantyScoreCardDto.content;
        const PaginationDetails = response.data.dtcWarrantyScoreCardDto;
        graphValues.forEach((val) => {
          detailsData.push({
            y: val.warrantyScoreCard,
            name: val.dtcCode,
            values: val.dtcCode,
          });
        });
        return {
          code: response?.status,
          data: response?.data,
          detailsData: detailsData,
          PaginationDetails: PaginationDetails,
        };
      }
      if (response.status === 401) {
        return {
          code: response?.status,
          data: response.data?.detail,
        };
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        return {
          code: err.response?.status,
          data: err.response.data?.detail || "You are not authorized",
        };
      } else {
        return {
          code: "error",
          data: err.response.data?.detail || "Something went wrong ",
        };
      }
    });
};

export const getRuldata = async (payload) => {
  var pageSize = payload === undefined ? 0 : payload;
  const status = localStorage.getItem("VP Diagnostic/Prognostic");
  const selectedData = JSON.parse(localStorage.getItem("selectedData"));
  const selectedDiagonsticsData = JSON.parse(
    localStorage.getItem("selectedDiagonosticsData")
  );
  let params = {};
  const rul = localStorage.getItem("rul");
  let lowerLimitData = localStorage.getItem("lowerLimitData");
  let upperLimitData = localStorage.getItem("upperLimitData");
  if (rul !== null) {
    switch (rul) {
      case "Miles": {
        params = {
          fromRulMiles: parseInt(lowerLimitData),
          toRulMiles: parseInt(upperLimitData),
          rul: "by_miles",
        };
        break;
      }

      case "Engine Run Time": {
        params = {
          fromRulEngineRunTime: parseInt(lowerLimitData),
          toRulEngineRunTime: parseInt(upperLimitData),
          rul: "by_engine_runtime",
        };
        break;
      }

      case "Hours": {
        params = {
          fromRulHours: parseInt(lowerLimitData),
          toRulHours: parseInt(upperLimitData),
          rul: "by_hours",
        };
        break;
      }

      case "Key starts": {
        params = {
          fromRulKeyStarts: parseInt(lowerLimitData),
          toRulKeyStarts: parseInt(upperLimitData),
          rul: "by_key_starts",
        };
        break;
      }

      default: {
        params = {
          fromRulMiles: parseInt(lowerLimitData),
          toRulMiles: parseInt(upperLimitData),
          rul: "by_miles",
        };
      }
    }
  }

  const FilterSelectedValues = {
    ...selectedData,
    ...selectedDiagonsticsData,
  };
  const paramData = await defaultData();
  const url = `${VehiclePopulationDashBoard.Rul}/${status}?page=${pageSize}&size=10`;
  const Ruldata = localStorage.getItem("rul");
  const lowerLimitdata = localStorage.getItem("lowerLimitData");
  const upperLimitDatadata = localStorage.getItem("upperLimitData");

  let filteredValues = {};
  if (Object.keys(FilterSelectedValues).length === 0) {
    filteredValues = paramData.data;
  } else {
    filteredValues = FilterSelectedValues;
  }

  let data = Object.assign(filteredValues);

  return mainAxiosInstance
    .post(url, data)
    .then((response) => {
      if (response.status === 200) {
        const detailsData = [];
        const graphValues = response.data.Rul.content;
        const PaginationDetails = response.data.Rul;
        graphValues.forEach((val) => {
          detailsData.push({ y: val.Rul, name: val.Rul, values: val.Rul });
        });
        return {
          code: response?.status,
          data: response?.data,
          detailsData: detailsData,
          PaginationDetails: PaginationDetails,
        };
      }
      if (response.status === 401) {
        return {
          code: response?.status,
          data: response.data?.detail,
        };
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        return {
          code: err.response?.status,
          data: err.response.data?.detail || "You are not authorized",
        };
      } else {
        return {
          code: "error",
          data: err.response.data?.detail || "Something went wrong ",
        };
      }
    });
};
