import React from "react";
import ReportsIcon from "../WarrantyManagement/assets/reports.svg";
import WarrantyCard from "../WarrantyManagement/components/WarrantyCard";
import "./index.scss";
import VehiclePopulation from "../Dashboard/assets/vehicle_population.svg";
import IndividualVehicle from "../Dashboard/assets/individual-vehical.svg";
import Modal from "../../components/common/Modal/Modal";
import { addvin_Number } from "../../store/actions/fetchActions";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { vehicleOverviewEndpoints } from "../../services/service-constants";
import { mainAxiosInstance } from "../../services/service";

const DashBoard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [model, setmodel] = useState(false);
  const [vinNumber, setVinNumber] = useState("DH34ASD7SDFF84742");
  const [errorModel, setErrorModel] = useState("");

  const showDetails = (val) => {
    setmodel(true);
  };

  useEffect(() => {
    localStorage.removeItem("vinId");
    dispatch(addvin_Number(null));
  }, []);

  const ModelCloseButton = (val) => {
    setmodel(false);
    setErrorModel("");
  };

  let submitHandler = () => {
    const url = `${vehicleOverviewEndpoints.vehilceInformation}`;
    const params = {
      vin_number: vinNumber,
      organization_id: 1,
    };
    mainAxiosInstance
      .get(url, { params: params })
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("vinId", vinNumber);
          dispatch(addvin_Number(vinNumber));
          navigate("/vehicle-overview/information", { replace: true });
          setmodel(false);
        }
      })
      .catch((err) => {
        setErrorModel("No records found with the entered VIN Number");
        dispatch(addvin_Number(null));
      });
  };
  return (
    <>
      {/* <div className="page-wrapper">
        <div className="first-container">
          <div className="individual-vehicle-health-data">
            <button onClick={() => showDetails()}>
              <WarrantyCard
                img={IndividualVehicle}
                backgroundColor="#C9DBFF"
                text="Individual Vehicle"
                secondText="Health Data"
              />
            </button>
          </div>
          <WarrantyCard
            img={VehiclePopulation}
            backgroundColor="#E3F5E3"
            text="Vehicle Population"
            secondText="Health Data"
          />

          <WarrantyCard
            img={ReportsIcon}
            backgroundColor="#F4E6E7"
            text="My Reports"
          />
        </div>
      </div>

      <div>
        {model && (
          <Modal
            handleClose={() => ModelCloseButton()}
            wrapperClass="vin-modal"
          >
            <div className="model-container">
              <span className="error-text">{errorModel}</span>
              <p>Please enter your VIN number here</p>
              <input
                type="text"
                value={vinNumber}
                placeholder="Enter the VIN number"
                maxlength="17"
                onChange={(e) => setVinNumber(e.target.value)}
              ></input>
              <div className="individual-vehicle-button">
                <button
                  className="close-button"
                  onClick={() => {
                    setmodel(false);
                    navigate("/vehicle-overview/information", {
                      replace: true,
                    });
                  }}
                >
                  Proceed
                </button>
                <button
                  className="save-vin-number-button"
                  onClick={() => submitHandler()}
                >
                  Save and Proceed
                </button>
              </div>
            </div>
          </Modal>
        )}
      </div> */}
    </>
  );
};
export default DashBoard;
