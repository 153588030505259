import React from "react";
import WarrantyCard from "../../../../WarrantyManagement/components/WarrantyCard";
import ReportsIcon from "../../../../WarrantyManagement/assets/reports.svg";
import CustomersAffectedIcon from "../../../../WarrantyManagement/assets/customers-affected.svg";
import CostIcon from "../../../../WarrantyManagement/assets/cost.svg";
import Severity from "../../../../WarrantyManagement/assets/severity.svg";
import DtcWarrantyIcon from "../../../../WarrantyManagement/assets/dtc-warranty-score.svg";
import RiskAnalysisIcon from "../../../../WarrantyManagement/assets/risk-analysis.svg";
import { Link } from "react-router-dom";

const VpTopDtcNonComplaints = (props) => {
  const routes = props.route ? props.route : "diagnostics";

  return (
    <div
      className="diagnostics-topdtc-complaints page-wrapper"
      style={{ backgroundColor: "#EEEEEE" }}
    >
      <div className="first-container">
        <Link
          to={`/vehicle-population/vehicle-population-warranty-management/${routes}/top-10-non-dtc-complaints/reports`}
        >
          <WarrantyCard
            img={ReportsIcon}
            backgroundColor="#fff"
            text="Reports"
          />
        </Link>
        <Link
          to={`/vehicle-population/vehicle-population-warranty-management/${routes}/top-10-non-dtc-complaints/customer-affected`}
        >
          <WarrantyCard
            img={CustomersAffectedIcon}
            backgroundColor="#fff"
            text="Customers Affected"
          />
        </Link>
        <Link
          to={`/vehicle-population/vehicle-population-warranty-management/${routes}/top-10-non-dtc-complaints/cost`}
        >
          <WarrantyCard img={CostIcon} backgroundColor="#fff" text="Cost" />
        </Link>
      </div>

      <div className="second-container">
        <Link
          to={`/vehicle-population/vehicle-population-warranty-management/${routes}/top-10-non-dtc-complaints/severity`}
        >
          <WarrantyCard img={Severity} backgroundColor="#fff" text="Severity" />
        </Link>
        <Link
          to={`/vehicle-population/vehicle-population-warranty-management/${routes}/top-10-non-dtc-complaints/score-card`}
        >
          <WarrantyCard
            img={DtcWarrantyIcon}
            backgroundColor="#fff"
            text="DTC Warranty Scorecard"
          />
        </Link>
        <Link
          to={`/vehicle-population/vehicle-population-warranty-management/${routes}/top-10-non-dtc-complaints/risk-analysis`}
        >
          <WarrantyCard
            img={RiskAnalysisIcon}
            backgroundColor="#fff"
            text="Risk Analysis and Required Action"
            containerStyles={{ paddingTop: "20px" }}
          />
        </Link>
      </div>
    </div>
  );
};

export default VpTopDtcNonComplaints;
