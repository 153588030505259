import React, { useState, useEffect, useRef } from "react";
import { Outlet, useLocation } from "react-router";
import {
  AdminNavigationMenuList,
  NavigationMenuList,
} from "../../utils/constants";
import { NavigationMenu } from "../common/NavigationMenu";
import Footer from "../Footer/footer";
import searchIcon from "../../assets/search.svg";
import individualVehicalIcon from "../../assets/individualvehhealthdata.svg";
import AdminDashboradIcon from "../../assets/new-admin-dashboard-icon.svg";
import HelpOutlineIcon from "../../assets/baseline-help-outline.svg";
import myreportIcon from "../../assets/myreporthealthdata.svg";
import vehecalpolIcon from "../../assets/vehecalpolhealthdata.svg";
import userProfileIcon from "../../assets/userIcon-profile.svg";
import notificationbellIcon from "../../assets/notifications-tang-bell.svg";
import questionIcon from "../../assets/question-circle-tang.svg";
import TopBarheaderCard from "../Header/TopBarheaderCard";
import { VehiclePopulationNavigationMenuList } from "../../utils/constants";
import HeaderIconAndText from "../Header/headerIconAndText";
import Modal from "../../components/common/Modal/Modal";
import {
  addFirstFilter,
  addSecondFilter,
  addVinFilter,
  addvin_Number,
} from "../../store/actions/fetchActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ServiceConfig,
  vehicleOverviewEndpoints,
  VehiclePopulationDashBoard,
} from "../../services/service-constants";
import { mainAxiosInstance } from "../../services/service";
import { logout } from "../../store/actions/authActions";
import { Tooltip } from "@mui/material";
import { getUserProfile } from "../../store/actions/fetchActions";
import ModalPopup from "../common/Modal/ModalPopup";
import "./index.scss";
import close from "../../assets/close.svg";

const MainLayout = () => {
  const ProfileRole = useSelector(
    (state) => state.mainReducer.User_Profile.role
  );
  const vinNumber = useSelector((state) => state.mainReducer.vin_Number);
  const [searchVal, setSearchVal] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [model, setmodel] = useState(false);
  const [vinNumberInput, setVinNumberInput] = useState("DH34ASD7SDFF84742");
  const [errorModel, setErrorModel] = useState("");
  const [searchErrorModel, setSearchErrorModel] = useState("");
  const [isExpanded, setExpanded] = useState(true);
  const [userProfile, setUserProfile] = useState({});
  const [logoutDropdown, setLogoutDropdown] = useState(false);
  const [navigationMenuType, setNavigationMenuType] =
    useState("IndividualVehicle");
  const location = useLocation();
  const userName = useSelector(
    (state) => state.mainReducer.User_Profile.username
  );
  const logoutDropdownRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const showPopup = localStorage.getItem("token");
  const [filterCardname, setFilterCardname] = useState("");
  const [filterErrModel, setFilterErrModel] = useState("");
  const [clickedpage, setClickedpage] = useState({
    page: "",
    paths: "",
  });
  const [vinNotFound, setVinNotFound] = useState("");
  const removeFromLocalStorage = () => {
    localStorage.removeItem("selectedData");
    localStorage.removeItem("selectedDiagonosticsData");
    localStorage.removeItem("rul");
    localStorage.removeItem("lowerLimitData");
    localStorage.removeItem("upperLimitData");
    localStorage.removeItem("isFilterSaved");
  };

  window.addEventListener("beforeunload", function () {
    removeFromLocalStorage();
  });

  useEffect(() => {
    setSearchVal(vinNumber);
    profileHandler();
  }, [vinNumber]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        logoutDropdownRef.current &&
        !logoutDropdownRef.current.contains(event.target)
      ) {
        setLogoutDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [logoutDropdownRef]);

  const HandleUserDashBoardButton = () => {
    setLogoutDropdown(false);
    setNavigationMenuType("IndividualVehicle");
    navigate("./dashboard");
    localStorage.removeItem("NavigationMenType");
  };

  const HandleAdminDashBoardButton = () => {
    setLogoutDropdown(false);
    setNavigationMenuType("AdminDashBoard");
    navigate("/admin-dashboard", { replace: false });
    localStorage.removeItem("NavigationMenType");
    localStorage.setItem("NavigationMenType", "AdminDashBoard");
  };

  const profileHandler = () => {
    let url = `${ServiceConfig.profile_user}`;
    mainAxiosInstance
      .get(url)
      .then((response) => {
        setUserProfile(response.data);
      })
      .catch((er) => {
        throw er;
      });
  };

  const handleSearch = (e) => {
    const url = `${vehicleOverviewEndpoints.vehilceInformation}`;
    const params = {
      vin_number: e,
      organization_id: 1,
    };
    mainAxiosInstance
      .get(url, { params: params })
      .then((response) => {
        if (response.status === 200) {
          dispatch(addvin_Number(e));
          localStorage.setItem("vinId", e);
          window.location.reload();
        }
      })
      .catch((err) => {
        localStorage.setItem("vinId", e);
        window.location.reload();
      });
  };
  const handleSaveFilter = () => {
    setFilterCardname("");
    setShowModal(false);
    setSaveModal(true);
  };
  const handleRmFilter = () => {
    localStorage.removeItem("selectedData");
    localStorage.removeItem("selectedDiagonosticsData");
    localStorage.removeItem("rul");
    localStorage.removeItem("lowerLimitData");
    localStorage.removeItem("upperLimitData");
    localStorage.removeItem("isFilterSaved");
    dispatch(addFirstFilter(null));
    dispatch(addSecondFilter(null));
    dispatch(addVinFilter(null));
    setShowModal(false);
    if (clickedpage.page === "IndividualVehicle") {
      showDetails();
    }
    if (clickedpage.page === "VehiclePopulation") {
      navigate(`${clickedpage.paths}`, { replace: true });
    }
  };
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchVal(value);
    const hasSpecialChar = /[^\w]/.test(value);
    if (value.length === 0) {
      setSearchErrorModel("VIN number should not be empty");
    } else if (hasSpecialChar) {
      setSearchErrorModel("VIN number should not contain space or special characters");
    } else if (value.includes(" ")) {
      setSearchErrorModel("VIN number should not contain space");
    } else {
      setSearchErrorModel("");
    }
  };

  const handleEnter = (e) => {
    const code = e.keyCode ? e.keyCode : e.which;

    if (code === 13) {
      handleSearch(e.target.value);
    }
  };

  useEffect(() => {
    dispatch(getUserProfile(userProfile));
  }, [userProfile]);

  const expandClicked = (val) => {
    setExpanded(val);
  };
  const checkandRedirect = (page, paths = "") => {
    const FilterSelectedValues = localStorage.getItem("isFilterSaved");
    if (FilterSelectedValues !== "true" && FilterSelectedValues !== null) {
      if (
        paths === "/vehicle-population/vehicle-population-warranty-management"
      ) {
        navigate(`/vehicle-population/vehicle-population-warranty-management`, {
          replace: true,
        });
      } else {
        setShowModal(true);
        setClickedpage({
          page,
          paths,
        });
      }
    } else {
      //localStorage.removeItem("selectedData");
      localStorage.removeItem("selectedDiagonosticsData");
      localStorage.removeItem("rul");
      localStorage.removeItem("lowerLimitData");
      localStorage.removeItem("upperLimitData");
      localStorage.removeItem("isFilterSaved");
      if (page === "IndividualVehicle") {
        showDetails();
      }
      if (page === "VehiclePopulation") {
        navigate(`${paths}`, { replace: false });
      }
    }
  };
  const handleSaveCancel = () => {
    setSaveModal(false);
    setFilterErrModel("");
  };

  const removeFilterAndRedirect = () => {
    localStorage.removeItem("selectedData");
    localStorage.removeItem("selectedDiagonosticsData");
    localStorage.removeItem("rul");
    localStorage.removeItem("lowerLimitData");
    localStorage.removeItem("upperLimitData");
    localStorage.removeItem("isFilterSaved");
    window.location.reload();
  };

  const saveToFilterCard = async () => {
    let params = {};
    let selectedData = JSON.parse(localStorage.getItem("selectedData"));
    let selectedDiagonosticsData = JSON.parse(
      localStorage.getItem("selectedDiagonosticsData")
    );
    let rul = localStorage.getItem("rul");
    let lowerLimitData = localStorage.getItem("lowerLimitData");
    let upperLimitData = localStorage.getItem("upperLimitData");
    if (rul !== null) {
      switch (rul) {
        case "Miles": {
          params = {
            fromRulMiles: parseInt(lowerLimitData),
            toRulMiles: parseInt(upperLimitData),
            rul: "by_miles",
          };
          break;
        }

        case "Engine Run Time": {
          params = {
            fromRulEngineRunTime: parseInt(lowerLimitData),
            toRulEngineRunTime: parseInt(upperLimitData),
            rul: "by_engine_runtime",
          };
          break;
        }

        case "Hours": {
          params = {
            fromRulHours: parseInt(lowerLimitData),
            toRulHours: parseInt(upperLimitData),
            rul: "by_hours",
          };
          break;
        }

        case "Key starts": {
          params = {
            fromRulKeyStarts: parseInt(lowerLimitData),
            toRulKeyStarts: parseInt(upperLimitData),
            rul: "by_key_starts",
          };
          break;
        }

        default: {
          params = {
            fromRulMiles: parseInt(lowerLimitData),
            toRulMiles: parseInt(upperLimitData),
            rul: "by_miles",
          };
        }
      }
    }

    if (filterCardname !== "") {
      const url = `${VehiclePopulationDashBoard.saved_filters}`;
      let filterType = rul ? "Prognostics" : "Diagnostics";
      let filterData = {
        ...selectedData, //for general data
        ...selectedDiagonosticsData, // for diagnostics data
        ...params, //for rul data
      };
      let data = {
        make: "Ford",
        filterName: filterCardname,
        filterType: filterType,
        ...filterData,
      };
      return mainAxiosInstance
        .post(url, data)
        .then((response) => {
          if (response.status === 200) {
            removeFilterAndRedirect();
            setSaveModal(false);
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            setFilterErrModel("You are not authorized");
          } else {
            setFilterErrModel("Something went wrong ");
          }
        });
    }
  };

  const showDetails = (val) => {
    setNavigationMenuType("IndividualVehicle");
    localStorage.removeItem("NavigationMenType");
    setmodel(true);
    navigate("/dashboard", { replace: true });
    window.history.pushState(null, "");
  };

  const ModelCloseButton = (val) => {
    setmodel(false);
    setErrorModel("");
    setVinNotFound("");
  };

  useEffect(() => {
    window.addEventListener("popstate", ModelCloseButton);
    return () => {
      window.removeEventListener("popstate", ModelCloseButton);
    };
  }, [model]);

  const HandleVehiclePopulation = () => {
    setNavigationMenuType("VehiclePopulation");
    navigate("/vehicle-population");
    localStorage.removeItem("NavigationMenType");
    localStorage.setItem("NavigationMenType", "VehiclePopulation");
  };

  const onValueChange = (value) => {
    setVinNumberInput(value);
    const hasSpecialChar = /[^\w]/.test(value);
    if (value.length === 0) {
      setErrorModel("VIN number should not be empty");
    } else if (hasSpecialChar) {
      setErrorModel("VIN number should not contain space or special characters");
    } else if (value.includes(" ")) {
      setErrorModel("VIN number should not contain space");
    } else {
      setErrorModel("");
    }
  };

  let submitHandler = () => {
    const url = `${vehicleOverviewEndpoints.vehilceInformation}`;
    const params = {
      vin_number: vinNumberInput,
      organization_id: 1,
    };
    mainAxiosInstance
      .get(url, { params: params })
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("vinId", vinNumberInput);
          dispatch(addvin_Number(vinNumberInput));
          navigate("/vehicle-overview/information", { replace: true });
          setmodel(false);
          setErrorModel("");
          setVinNotFound("");
          setNavigationMenuType("IndividualVehicle");
          localStorage.removeItem("NavigationMenType");
          localStorage.setItem("NavigationMenType", "IndividualVehicle");
        }
      })
      .catch((err) => {
        setErrorModel("No records found with the entered VIN Number");
        setVinNotFound("No records found with the entered VIN Number");
        dispatch(addvin_Number(null));
      });
  };

  useEffect(() => {
    if (
      location.pathname.includes("/vehicle-population") ||
      location.pathname.includes("/vp-saved-filter")
    ) {
      setNavigationMenuType("VehiclePopulation");
      localStorage.removeItem("NavigationMenType");
      localStorage.setItem("NavigationMenType", "VehiclePopulation");
    } else if (location.pathname.includes("/admin-dashboard")) {
      setNavigationMenuType("AdminDashBoard");
      localStorage.removeItem("NavigationMenType");
      localStorage.setItem("NavigationMenType", "AdminDashBoard");
    } else if (location.pathname.includes("/configure-features")) {
      setNavigationMenuType("AdminDashBoard");
      localStorage.removeItem("NavigationMenType");
      localStorage.setItem("NavigationMenType", "AdminDashBoard");
    } else if (location.pathname.includes("/dashboard")) {
      setNavigationMenuType("IndividualVehicle");
      localStorage.removeItem("NavigationMenType");
    } else if (location.pathname.includes("/configure-features")) {
      setNavigationMenuType("AdminDashBoard");
      localStorage.removeItem("NavigationMenType");
      localStorage.setItem("NavigationMenType", "AdminDashBoard");
    } else {
      if (vinNumber !== null) {
        setNavigationMenuType("IndividualVehicle");
        localStorage.removeItem("NavigationMenType");
        localStorage.setItem("NavigationMenType", "IndividualVehicle");
      } else {
        navigate("/dashboard", { replace: true });
        setNavigationMenuType("IndividualVehicle");
        localStorage.removeItem("NavigationMenType");
      }
    }
  }, [location.key]);

  const handleInputCardnameChange = (e) => {
    setFilterCardname(e.target.value);
    validateInput(e.target.name, e.target.value);
  };

  const validateInput = (name, value) => {
    let errors = "";
    switch (name) {
      case "filterCardname":
        errors =
          value.length === 0
            ? "Filter name should not be empty"
            : value.length >= 30
            ? "Filter name should not be more than 30 characters"
            : "";
        break;
      default:
        break;
    }
    setFilterErrModel(errors);
  };

  const closeModelPopup = () => {
    setShowModal(false);
    setSaveModal(false);
  };

  return (
    <>
      {/* <Header /> */}
      <div className="container flex ">
        {navigationMenuType === "AdminDashBoard" ? (
          <NavigationMenu
            className="nav-content"
            menuItmes={AdminNavigationMenuList}
            clickExpand={(val) => expandClicked(val)}
            dashboardRoute="/admin-dashboard"
            redirect={HandleUserDashBoardButton}
          />
        ) : navigationMenuType === "VehiclePopulation" ? (
          <NavigationMenu
            className="nav-content"
            menuItmes={VehiclePopulationNavigationMenuList}
            clickExpand={(val) => expandClicked(val)}
            dashboardRoute="/vehicle-population"
            redirect={HandleUserDashBoardButton}
            checkandRedirect={checkandRedirect}
          />
        ) : navigationMenuType === "IndividualVehicle" ? (
          <NavigationMenu
            className="nav-content"
            menuItmes={NavigationMenuList}
            clickExpand={(val) => expandClicked(val)}
            dashboardRoute="/dashboard"
            redirect={HandleUserDashBoardButton}
          />
        ) : (
          <></>
        )}

        <div className={`content ${isExpanded ? "" : "expand"}`}>
          <div className="top-headerbar">
            {vinNumber !== null &&
            !location.pathname.includes("/vehicle-population") &&
            !location.pathname.includes("/admin-dashboard") &&
            !location.pathname.includes("/configure-features") &&
            !location.pathname.includes("/vp-saved-filter") ? (
              <div className="header-search">
                <input
                  type="text"
                  value={searchVal}
                  placeholder="Enter VIN number"
                  onChange={handleSearchChange}
                  onKeyPress={handleEnter}
                />
                <img className="search-icon" src={searchIcon} alt="search" />
              </div>
            ) : (
              ""
            )}
            <div className="header-BarCard">
              <TopBarheaderCard
                icon={individualVehicalIcon}
                text={"Individual Vehicle"}
                secondtext={"Health Data"}
                handleOnClick={() => checkandRedirect("IndividualVehicle")}
                status="IndividualVehicle"
              />
              <TopBarheaderCard
                icon={vehecalpolIcon}
                text={"Vehicle Population"}
                secondtext={"Health Data"}
                handleOnClick={HandleVehiclePopulation}
                status="VehiclePopulation"
              />
              <TopBarheaderCard
                icon={myreportIcon}
                text={"My"}
                secondtext={"Reports"}
              />
            </div>

            <div className="header-icons">
              {/* {ProfileRole === "LEVEL1_USER" &&
                !location.pathname.includes("/admin-dashboard") && (
                  <Tooltip title="Admin Dashboard">
                    <img
                      src={userProfileIcon}
                      alt="user"
                      onClick={HandleAdminDashBoardButton}
                    />
                  </Tooltip>
                )}

              {location.pathname.includes("/admin-dashboard") && (
                <Tooltip title="User Dashboard">
                  <img
                    src={userProfileIcon}
                    alt="user"
                    onClick={HandleUserDashBoardButton}
                  />
                </Tooltip>
              )}  */}

              <Tooltip title="Notifications" className="nav-icons">
                <img src={notificationbellIcon} alt="bell" />
              </Tooltip>
              <Tooltip title="Help" className="nav-icons">
                <img src={questionIcon} alt="Q" />
              </Tooltip>

              <Tooltip>
                <img
                  src={userProfileIcon}
                  alt="user"
                  onClick={() => setLogoutDropdown(!logoutDropdown)}
                />
              </Tooltip>

              {logoutDropdown ? (
                <div className="logout-dropdown" ref={logoutDropdownRef}>
                  <p className="user-name">
                    <button>
                      <img src={userProfileIcon} alt="user"></img>
                      <span>{userName}</span>
                    </button>
                  </p>
                  {ProfileRole === "LEVEL1_USER" && (
                    <>
                      {location.pathname.includes("/admin-dashboard") ? (
                        <>
                          <p className="admin-dashboard-text">
                            <button onClick={HandleUserDashBoardButton}>
                              <img
                                src={AdminDashboradIcon}
                                alt="admin dashboard"
                              />
                              <span>User dashboard</span>
                            </button>
                          </p>
                        </>
                      ) : (
                        <p className="admin-dashboard-text">
                          <button onClick={HandleAdminDashBoardButton}>
                            <img
                              src={AdminDashboradIcon}
                              alt="admin dashboard"
                            />
                            <span>Admin dashboard</span>
                          </button>
                        </p>
                      )}
                    </>
                  )}
                  <p className="help">
                    <button>
                      <img src={HelpOutlineIcon} alt="admin dashboard" />
                      <span>Help</span>
                    </button>
                  </p>
                  <p className="logout" onClick={() => dispatch(logout())}>
                    <button>
                      <img src={AdminDashboradIcon} alt="admin dashboard" />
                      <span>Logout</span>
                    </button>
                  </p>
                </div>
              ) : null}
            </div>
            <div
              style={{
                position: "absolute",
                color: "red",
                fontSize: "12px",
                top: "100%",
                left: "1%",
              }}
            >
              {searchErrorModel}
            </div>
          </div>
          <div className="outlet">
            <Outlet />
          </div>
        </div>
      </div>
      <Footer />
      {model && (
        <ModalPopup
          firstParagraph="Enter your VIN Number"
          firstButton="Close"
          secondButton="Save and Proceed"
          onValueChange={onValueChange}
          closeModal={ModelCloseButton}
          errorModel={errorModel || vinNotFound}
          onClick={submitHandler}
          inputValue={"DH34ASD7SDFF84742"}
          placeholder={"Enter the VIN number"}
          buttonClassName={
            vinNumberInput.length > 0 && errorModel.length === 0
              ? "btn-primary-blue"
              : "btn-primary-blue disabled"
          }
          disableSecondButton={
            vinNumberInput.length > 0 && errorModel.length === 0 ? false : true
          }
        />
      )}
      {showModal && (
        <div style={modalStyle}>
          <div style={modalContentStyle}>
            <div
              className="modal-close"
              onClick={closeModelPopup}
              style={{
                display: "flex",
                justifyContent: "end",
                position: "relative",
                bottom: "15px",
                left: "16px",
              }}
            >
              <img src={close} alt="close" />
            </div>
            <p style={{ marginTop: "6px" }}>
              Do you want to discard the changes
            </p>
            <div className="discardBtns">
              <button onClick={handleSaveFilter} className="btn-secondary-blue">
                No
              </button>
              <button
                onClick={handleRmFilter}
                style={{ width: 80, marginLeft: "1em" }}
                className="btn-primary-blue"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
      {saveModal && (
        <div style={modalStyle}>
          <div style={modalContentStyle}>
            <span className="error-text">{filterErrModel}</span>
            <div className="discard-card-modal-close" onClick={closeModelPopup}>
              <img src={close} alt="close" />
            </div>
            <p style={{ marginTop: "6px" }}>Save this filter as</p>
            <input
              name="filterCardname"
              type="text"
              className="saveInput"
              value={filterCardname}
              onChange={handleInputCardnameChange}
              maxLength={30}
            />
            <div className="modBtns">
              <button onClick={handleSaveCancel} className="btn-secondary-blue">
                Cancel
              </button>
              <button
                onClick={saveToFilterCard}
                className={
                  filterCardname.length >= 1
                    ? "btn-primary-blue"
                    : "btn-primary-blue disabled"
                }
                style={{ width: 100, marginLeft: "1em" }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
const modalStyle = {
  position: "fixed",
  background: "#000000aa",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 1111,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
};

const modalContentStyle = {
  textAlign: "left",
  position: "fixed",
  top: "50%",
  left: "50%",
  width: 400,
  transform: "translate(-50%, -50%)",
  background: "white",
  padding: "20px",
  borderRadius: "10px",
  display: "",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 11111,
};

export default MainLayout;
