import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import solidGauge from "highcharts/modules/solid-gauge.js";
import highchartsMore from "highcharts/highcharts-more";
import {
  colorMap,
  gaugeSelectStyles,
  getChartOptions,
} from "../../utils/utils";
import { tickRange } from "../../utils/helpers";
import Select from "../../../../components/common/Select/Select";
import ChartCard from "./ChartCard";

highchartsMore(Highcharts);
solidGauge(Highcharts);
const EngineOilPressureWidget = ({ height, width, engineOilData }) => {
  const [data, setData] = useState(null);
  const chartType = ["Dial Gauge", "Digital"];
  const [defaultType, setDefaultType] = useState(chartType[0]);

  const handleTypeChange = (val) => {
    setDefaultType(val);
  };
  let tickRangeArray;
  let plotBandsArray;

  useEffect(() => {
    setData(engineOilData);
  }, [engineOilData]);

  if (data) {
    tickRangeArray = tickRange(
      data?.engineOilPressurePsisDto[0]?.minValue,
      data?.engineOilPressurePsisDto[0]?.maxValue,
      10
    );
    plotBandsArray = data?.colorRanges
      ?.sort((a, b) => (a.start > b.start ? 0 : -1))
      .map((range) => ({
        from: range.start,
        to: range.end,
        color: range.color,
        innerRadius: "90%",
        outerRadius: "100%",
      }));
  }

  const options = getChartOptions({
    height: height,
    width: width,
    minValue: data?.engineOilPressurePsisDto[0]?.minValue,
    maxValue: data?.engineOilPressurePsisDto[0]?.maxValue,
    currentValue: data?.engineOilPressurePsisDto[0]?.currentValue,
    unit: data?.engineOilPressurePsisDto[0]?.unit,
    plotBandsArray: plotBandsArray,
    tickRangeArray: tickRangeArray,
    chartText: "Engine Oil Pressure",
  });

  return (
    <div
      style={{
        background: "#FFFF",
        borderRadius: "10px",
        position: "relative",
        height: "100%",
      }}
    >
      <div style={{ position: "absolute", zIndex: "1", right: "-10px" }}>
        <Select
          options={chartType}
          defaultSelected={defaultType}
          handleOptionClick={handleTypeChange}
          containerClass="realtime-gauge-select"
          styles={gaugeSelectStyles}
        />
      </div>
      {defaultType === chartType[0] ? (
        <HighchartsReact highcharts={Highcharts} options={options} />
      ) : (
        <>
          <ChartCard
            currentValue={data?.engineOilPressurePsisDto[0]?.currentValue}
            unit={data?.engineOilPressurePsisDto[0]?.unit}
            chartText="Engine Oil Pressure"
          />
        </>
      )}
    </div>
  );
};

export default EngineOilPressureWidget;
