import React, { useState } from "react";
import Select from "../../../../components/common/Select/Select";
import Daterangepicker from "../../../../components/Datepicker/daterangepicker";
import Filter from "../../../../components/Filter/Filter";
import intervalPreIcon from "../../../../assets/interval.png";
import { forwardRef } from "react";
import timeIcon from "../../../../assets/datepicker.png";
import { Checkbox } from "../../../../components/common/Checkbox/Checkbox";
import boxIcon from "../../../../assets/boxIcon.svg";

const DatepickerCustomInput = forwardRef(({ value, onClick }, ref) => (
  <div className="btn btn-secondary date-selector" onClick={onClick} ref={ref}>
    <img className="time-icon" src={timeIcon} alt="datePicker" />
    {value}
  </div>
));

const FuelEconomyHeader = ({
  interval,
  defaultInterval,
  showInput,
  inputData,
  setInputData,
  showDatepicker,
  selectedEndDate,
  selectedStartDate,
  handleOnDateChange,
  handleIntervalOptionClick,
  setNoDataFound,
  filterData,
  setFilterData,
  isZoom,
}) => {
  const [selectAll, setSelectAll] = useState(true);

  const setSelectAllvalue = (data) => {
    const checkAllSelected = data.filter((el) => el.isChecked === false);
    if (checkAllSelected.length > 0) {
      setSelectAll(false);
    } else {
      setSelectAll(true);
    }
  };

  const AllSelect = (val) => {
    const getUpdatedfilterData = filterData.map((el) => {
      return { ...el, isChecked: val };
    });
    setFilterData(getUpdatedfilterData);
    setSelectAll(val);
  };
  const handleFilterClick = (val) => {
    const newFilterData = filterData.map((el) => {
      if (el.displayname === val.displayname) {
        el.isChecked = !el.isChecked;
      }
      return el;
    });
    const isAnyModuleSelected = newFilterData.some((el) => el.isChecked);
    setNoDataFound(!isAnyModuleSelected);
    setFilterData([...newFilterData]);
    setSelectAllvalue(newFilterData);
  };
  return (
    <div className="flex page-content-header" style={{paddingTop:"0px"}}>
      <div className="page-options-container">
        <Select
          heading="Data Interval"
          options={interval}
          defaultSelected={defaultInterval}
          handleOptionClick={(value) => handleIntervalOptionClick(value)}
          headingIcon={boxIcon}
          // preIcon={intervalPreIcon}
        />
        {showInput && (
          <input
            type="text"
            className="value-input"
            placeholder={`Please enter ${defaultInterval}`}
            value={inputData}
            onChange={(e) => {
              setInputData(e);
            }}
            maxLength={10}
          />
        )}
        {showDatepicker && (
          <Daterangepicker
            customInput={<DatepickerCustomInput />}
            startDate={selectedStartDate}
            endDate={selectedEndDate}
            handleOnChange={(dates) => handleOnDateChange(dates)}
          />
        )}
      </div>
      {!isZoom ? (
        <>
          <div className="page-filter" style={{justifyContent: "end"}}>
            <Filter data={AllSelect} selectAll={selectAll}>
              {filterData.map((el) => (
                <Checkbox
                  title={el.displayname}
                  handleInputClick={() => handleFilterClick(el)}
                  alignProp={{
                    borderTop: "0.5px solid #C4C4C4",
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "10px",
                    alignItems: "center",
                    flexDirection: "row-reverse",
                  }}
                  checkboxStyle={{ marginRight: "10px" }}
                  isChecked={el.isChecked}
                />
              ))}
            </Filter>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default FuelEconomyHeader;
