import React, { useEffect, useState } from "react";
import { NavLink, Outlet, useNavigate, useLocation } from "react-router-dom";
import TabLayout from "../../../components/Layout/TabLayout";
import "../VehiclePopulation.scss";
import plusIcon from "../assets/plus-icon.svg";
import compare_vin from "../assets/compare-with-vin-icon.svg";
import SearchIcon from "../../../assets/search-icon-white.svg";
import { VehiclePopulationDashBoard } from "../../../services/service-constants";
import { mainAxiosInstance } from "../../../services/service";
import useCloseOnClickOutside from "../../../hooks/useCloseOnClickOutside";
import ModalPopup from "../../../components/common/Modal/ModalPopup";
import { useDispatch } from "react-redux";
import {
  addFirstFilter,
  addSecondFilter,
  addVinFilter,
} from "../../../store/actions/fetchActions";

const UsageProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [defFilterData, setDefFilterData] = useState(null);
  const [filterData, setFilterData] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [data, setData] = useState([]);
  const [comparePopup, setComparePopup] = useState(false);
  const [errorModel, setErrorModel] = useState("");
  const [compareVinNumber, setCompareVinNumber] = useState("");
  const [progFilter, setProgFilter] = useState(null);
  const pathName = location.pathname.includes("diagnostics")
    ? "diagnostics"
    : "prognostics";

  const HandleClick = () => {
    navigate("/vehicle-population/FormLayout", {
      state: { path: location.pathname },
    });
    localStorage.removeItem("filterSelectedValues");
  };

  useEffect(() => {
    const selectedData = JSON.parse(localStorage.getItem("selectedData"));
    const selectedDiagonsticsData = JSON.parse(
      localStorage.getItem("selectedDiagonosticsData")
    );
    const fromMilesStr = JSON.parse(localStorage.getItem("lowerLimitData"));
    const fromMiles = parseInt(fromMilesStr, 10);
    let toMilesStr = JSON.parse(localStorage.getItem("upperLimitData"));
    const toMiles = parseInt(toMilesStr, 10);
    let FilterSelectedValues = {};
    if (selectedData !== null) {
      FilterSelectedValues = {
        ...selectedData,
        ...selectedDiagonsticsData,
        fromRulMiles: fromMiles,
        toRulMiles: toMiles,
      };
    } else {
      FilterSelectedValues = {
        ...selectedData,
        ...selectedDiagonsticsData,
      };
    }
    setFilterData(FilterSelectedValues);
  }, []);

  useEffect(() => {
    let url = `${VehiclePopulationDashBoard.defaultApi}`;
    mainAxiosInstance
      .get(url)
      .then((response) => {
        setDefFilterData(response.data);
      })
      .catch((er) => {
        throw er;
      });
    //getSFilteredData();
  }, []);

  useEffect(() => {
    if (userInput.length > 3) {
      getSFilteredData();
    } else {
      getSFilteredData();
    }
  }, [userInput]);

  let selectRef = useCloseOnClickOutside(() => {
    setShowDropdown(false);
  });

  const getSFilteredData = () => {
    const url = `${VehiclePopulationDashBoard.search_filter}`;
    let params = {
      make: "Ford",
      searchValue: userInput,
      filterType: "All",
      page: 0,
      size: 50,
    };
    mainAxiosInstance
      .get(url, { params: params })
      .then((response) => {
        if (response.status === 200) {
          setData(response.data?.content || []);
          // setApiError(false);
        }
        if (response.status === 401) {
          // setErrorMsg(response.data?.details || "You are not authorized.");
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          // setApiError(true);
        } else {
          // setApiError(true);
        }
      });
  };

  const handleCompare = () => {
    setShowDropdown(!showDropdown);
  };

  const handleSearch = (e) => {
    setUserInput(e.target.value);
  };

  const handleFilterNameClick = (value) => {
    let filteredValues = {};
    if (Object.keys(filterData).length === 0) {
      filteredValues = defFilterData;
    } else {
      filteredValues = filterData;
    }
    dispatch(addFirstFilter(filteredValues));
    dispatch(addSecondFilter(value));
    dispatch(addVinFilter(null));
    navigate("/vp-saved-filter/usageprofile/comparision", {
      replace: true,
      state: {
        diagnosesState: pathName === "diagnostics" ? 0 : 1,
      },
    });
  };

  const handleCompareVin = () => {
    setComparePopup(true);
  };

  useEffect(() => {
    const selectedData = JSON.parse(localStorage.getItem("selectedData"));
    const selectedDiagonsticsData = JSON.parse(
      localStorage.getItem("selectedDiagonosticsData")
    );
    const selectedRulData = localStorage.getItem("rul");
    const RULlowerLimitData = localStorage.getItem("lowerLimitData");
    const RULupperLimitData = localStorage.getItem("upperLimitData");
    let params = {};
    switch (selectedRulData) {
      case "Miles": {
        params = {
          fromRulMiles: parseInt(RULlowerLimitData),
          toRulMiles: parseInt(RULupperLimitData),
          rul: "by_miles",
        };
        break;
      }

      case "Engine Run Time": {
        params = {
          fromRulEngineRunTime: parseInt(RULlowerLimitData),
          toRulEngineRunTime: parseInt(RULupperLimitData),
          rul: "by_engine_runtime",
        };
        break;
      }

      case "Hours": {
        params = {
          fromRulHours: parseInt(RULlowerLimitData),
          toRulHours: parseInt(RULupperLimitData),
          rul: "by_hours",
        };
        break;
      }

      case "Key starts": {
        params = {
          fromRulKeyStarts: parseInt(RULlowerLimitData),
          toRulKeyStarts: parseInt(RULupperLimitData),
          rul: "by_key_starts",
        };
        break;
      }

      default: {
        params = {};
      }
    }

    const ProgFilterSelectedValues = {
      ...selectedData,
      ...selectedDiagonsticsData,
      ...params,
    };
    setProgFilter(ProgFilterSelectedValues);
  }, [location.pathname]);

  const handleCompareVinNumber = (value) => {
    setCompareVinNumber(value);
    const hasSpecialChar = /[^\w]/.test(value);
    if (value.length === 0) {
      setErrorModel("VIN number should not be empty");
    } else if (hasSpecialChar) {
      setErrorModel("VIN number should not contain space or special characters");
    } else if (value.includes(" ")) {
      setErrorModel("VIN number should not contain space");
    } else {
      setErrorModel("");
    }
  };

  const ModelCloseButton = () => {
    setComparePopup(false);
  };

  const submitVinCompare = () => {
    let filteredValues = {};
    if (Object.keys(filterData).length === 0) {
      filteredValues = defFilterData;
    } else {
      filteredValues = filterData;
    }
    dispatch(addFirstFilter(filteredValues));
    dispatch(addSecondFilter(null));
    dispatch(addVinFilter(compareVinNumber));
    navigate("/vp-saved-filter/usageprofile/comparision", {
      replace: true,
    });
  };

  useEffect(() => {
    if (location.pathname === "/vehicle-population/usageprofile") {
      navigate("/vehicle-population/usageprofile/diagnostic", {
        replace: true,
      });
      const selectedData = JSON.parse(localStorage.getItem("selectedData"));
      const selectedDiagonsticsData = JSON.parse(
        localStorage.getItem("selectedDiagonosticsData")
      );
      const FilterSelectedValues = {
        ...selectedData,
        ...selectedDiagonsticsData,
      };
      setFilterData(FilterSelectedValues);
    }
  }, [location.pathname]);

  const filteredFilterData = (key) => {
    if (location.pathname.includes("diagnostic")) {
      return (
        key !== "id" &&
        key !== "userRole" &&
        key !== "organizationId" &&
        key !== "filterName" &&
        key !== "filterType" &&
        key !== "username" &&
        key !== "lastUpdated" &&
        key !== "rul" &&
        key !== "type" &&
        key !== "fromMiles" &&
        key !== "toMiles" &&
        key !== "fromRulMiles" &&
        key !== "toRulMiles" &&
        key !== "module" &&
        key !== "fromRulHours" &&
        key !== "fromRulEngineRunTime" &&
        key !== "toRulEngineRunTime" &&
        key !== "toRulHours" &&
        key !== "toRulKeyStarts" &&
        key !== "fromRulKeyStarts"
      );
    } else {
      return (
        key !== "id" &&
        key !== "userRole" &&
        key !== "organizationId" &&
        key !== "filterName" &&
        key !== "filterType" &&
        key !== "username" &&
        key !== "lastUpdated" &&
        key !== "rul" &&
        key !== "fromMiles" &&
        key !== "toMiles" &&
        key !== "type" &&
        key !== "module"
      );
    }
  };

  return (
    <div className="full-width ">
      <div className="vehicle-population-container">
        <div className="top-button-container">
          <div className="inp">
            {location.pathname ===
            "/vehicle-population/usageprofile/diagnostic" ? (
              <>
                {filterData && Object.keys(filterData).length > 0 && (
                  <div
                    className="display-selected-data"
                    title={Object.keys(filterData)
                      .filter(
                        (key) =>
                          key !== "id" &&
                          key !== "userRole" &&
                          key !== "organizationId" &&
                          key !== "filterName" &&
                          key !== "filterType" &&
                          key !== "username" &&
                          key !== "type" &&
                          key !== "fromMiles" &&
                          key !== "toMiles" &&
                          key !== "fromRulMiles" &&
                          key !== "toMiles" &&
                          key !== "toRulEngineRunTime" &&
                          key !== "toRulHours" &&
                          key !== "toRulKeyStarts" &&
                          key !== "toRulMiles" &&
                          key !== "lastUpdated" &&
                          key !== "rul" &&
                          key !== "module" &&
                          key !== "fromRulHours" &&
                          key !== "fromRulEngineRunTime" &&
                          key !== "toRulEngineRunTime" &&
                          key !== "toRulHours" &&
                          key !== "toRulKeyStarts" &&
                          key !== "fromRulKeyStarts" &&
                          filterData[key] !== null
                      )
                      .map((key) => key.charAt(0).toUpperCase() + key.slice(1))
                      .join("_")}
                  >
                    {Object.keys(filterData)
                      .filter(
                        (key) =>
                          key !== "id" &&
                          key !== "userRole" &&
                          key !== "organizationId" &&
                          key !== "filterName" &&
                          key !== "filterType" &&
                          key !== "username" &&
                          key !== "type" &&
                          key !== "fromMiles" &&
                          key !== "toMiles" &&
                          key !== "fromRulMiles" &&
                          key !== "toRulMiles" &&
                          key !== "lastUpdated" &&
                          key !== "rul" &&
                          key !== "module" &&
                          key !== "fromRulHours" &&
                          key !== "fromRulEngineRunTime" &&
                          key !== "toRulEngineRunTime" &&
                          key !== "toRulHours" &&
                          key !== "toRulKeyStarts" &&
                          key !== "fromRulKeyStarts" &&
                          filterData[key] !== null
                      )
                      .map((key) => key.charAt(0).toUpperCase() + key.slice(1))
                      .join("_")}
                  </div>
                )}
              </>
            ) : (
              <>
                {progFilter && Object.keys(progFilter).length > 0 && (
                  <div
                    className="display-selected-data"
                    title={Object.keys(progFilter)
                      .filter(
                        (key) =>
                          key !== "id" &&
                          key !== "userRole" &&
                          key !== "organizationId" &&
                          key !== "filterName" &&
                          key !== "filterType" &&
                          key !== "username" &&
                          key !== "lastUpdated" &&
                          key !== "rul" &&
                          key !== "type" &&
                          progFilter[key] !== null
                      )
                      .map((key) => key.charAt(0).toUpperCase() + key.slice(1))
                      .join("_")}
                  >
                    {Object.keys(progFilter)
                      .filter(
                        (key) =>
                          key !== "id" &&
                          key !== "userRole" &&
                          key !== "organizationId" &&
                          key !== "filterName" &&
                          key !== "filterType" &&
                          key !== "username" &&
                          progFilter[key] !== null
                      )
                      .map((key) => key.charAt(0).toUpperCase() + key.slice(1))
                      .join("_")}
                  </div>
                )}
              </>
            )}
          </div>
          <div className="top-button-container-icons">
            <p className="plus-icon-button" onClick={HandleClick}>
              <img className="" src={plusIcon} alt="wa" />
            </p>
            <p className="compare-with-vin-button" onClick={handleCompareVin}>
              Compare with VIN
              <img className="" src={compare_vin} alt="wa" />
            </p>
            <p className="compare-button" onClick={handleCompare}>
              Compare
              <img className="" src={compare_vin} alt="wa" />
            </p>
            {showDropdown ? (
              <div className="compare-dropdown" ref={selectRef}>
                <div className="compare-dropdown-search">
                  <img src={SearchIcon} alt="search" className="search_image" />
                  <input
                    type="text"
                    className="search_field"
                    placeholder="Search for attribute"
                    value={userInput}
                    onChange={handleSearch}
                    // onKeyPress={onEnter}
                  />
                </div>
                <div className="compare-dropdown-values">
                  {data.map((values) => {
                    return (
                      <div
                        className="compare-dropdown-value"
                        onClick={() => handleFilterNameClick(values)}
                      >
                        {values.filterName}
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        {comparePopup ? (
          <>
            <ModalPopup
              firstParagraph={"Enter VIN number here"}
              firstButton="Cancel"
              secondButton="Compare"
              onValueChange={handleCompareVinNumber}
              closeModal={ModelCloseButton}
              errorModel={errorModel}
              onClick={submitVinCompare}
              inputValue={"DH34ASD7SDFF84742"}
              placeholder={"Enter the VIN number"}
              buttonClassName={
                compareVinNumber.length > 0 && errorModel.length === 0
                  ? "btn-primary-blue"
                  : "btn-primary-blue disabled"
              }
              disableSecondButton={
                compareVinNumber.length > 0 && errorModel.length === 0
                  ? false
                  : true
              }
            />
          </>
        ) : (
          <></>
        )}
        <div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default UsageProfile;
