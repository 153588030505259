import React, { useState, useEffect } from "react";
import "./index.scss";
import { Button } from "../../components/common/Button/Button";
import Input from "../../components/common/Input";
import Modal from "../../components/common/Modal/Modal";
import Setpassword from "./SetPassword";
import { Link, useLocation, useMatch, useNavigate } from "react-router-dom";
import LoginLayout from "../../components/Layout/LoginLayout";
import * as message from "../../utils/messages";
import * as regex from "../../utils/regex";
import { useDispatch } from "react-redux";
import { register } from "../../store/actions/authActions";
import * as AuthService from "../../services/auth-service";
import logo from "../../assets/logo_blue.png";
import rightArrow from "../../assets/right-arrow-mark.svg";
import { formatPhoneNumber } from "../../components/common/FormatPhoneNumber";

const Signup = () => {
  const location = useLocation();
  const matchsetPwd = useMatch({
    path: "/set-password/:emailId",
    caseSensitive: true,
    end: true,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialState = {
    fname: "",
    lname: "",
    email: "",
    countryCode: "+1",
    phnumber: "",
    reason: "",
    SPpassword: "",
    SPcpassword: "",
  };
  const [errorMsg, setErrorMsg] = useState({
    signup: "",
    setpwd: "",
  });
  const [showModal, setShowModal] = useState({
    showAdminVerifyModal: false,
    showSetPwdModal: false,
  });
  const [formInputs, setForminputs] = useState(initialState);
  const [error, setError] = useState({
    fname: "",
    lname: "",
    email: "",
    countryCode: "",
    phnumber: "",
    reason: "",
    SPpassword: "",
    SPcpassword: "",
  });
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSignupSubmit();
    }
  };
  const handleSetPwdModalShowhide = () => {
    setShowModal({ ...showModal, showSetPwdModal: !showModal.showSetPwdModal });
    setForminputs({ ...formInputs, SPpassword: "", SPcpassword: "" });
    setError({
      fname: "",
      lname: "",
      email: "",
      countryCode: "",
      phnumber: "",
      reason: "",
      SPpassword: "",
      SPcpassword: "",
    });
    setErrorMsg({ ...errorMsg, setpwd: "" });
  };
  const handleInputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    if (name === "phnumber" && value) {
      value = formatPhoneNumber(value);
    }
    setForminputs({ ...formInputs, [name]: value });
    validate(name, value);
    setErrorMsg("");
  };
  const checkData = () => {
    validate("fname", formInputs.fname);
    validate("lname", formInputs.lname);
    validate("email", formInputs.email);
    // validate("countryCode", formInputs.countryCode);
    validate("phnumber", formInputs.phnumber);
    validate("reason", formInputs.reason);
  };
  const checkSetPwdData = () => {
    validate("SPpassword", formInputs.SPpassword);
    validate("SPcpassword", formInputs.SPcpassword);
  };
  const validate = (name, value) => {
    let errors = error;
    switch (name) {
      case "fname":
        errors.fname =
          value.length === 0
            ? message.FNAME_REQUIRED
            : !regex.LETTERS_REGEX.test(value)
            ? message.INVALID_NAME
            : value.length < 2 || value.length > 20
            ? message.MIN_REQUIRED +
              2 +
              message.MAX_REQUIRED +
              20 +
              message.EXTRA_VALUE
            : "";
        break;
      case "lname":
        errors.lname =
          value.length === 0
            ? message.LNAME_REQUIRED
            : !regex.LETTERS_REGEX.test(value)
            ? message.INVALID_NAME
            : value.length < 2 || value.length > 20
            ? message.MIN_REQUIRED +
              2 +
              message.MAX_REQUIRED +
              20 +
              message.EXTRA_VALUE
            : "";
        break;
      case "email":
        errors.email =
          value.length === 0
            ? message.EMAIL_REG_REQUIRED
            : !regex.EMAIL_REGEX.test(value)
            ? message.EMAIL_REG_VAILD
            : "";
        break;
      case "phnumber":
        errors.phnumber =
          value.length === 0
            ? message.PHNUMBER_REQUIRED
            : !regex.PHONE_US_REGEX.test(value)
            ? message.INVALID_PHNUMBER
            : value.length < 14
            ? message.PHNUMBER_LENGTH
            : "";
        break;
      case "countryCode":
        errors.countryCode =
          value.length === 0
            ? message.COUNTRYCODE_REQUIRED
            : !regex.NUMBER_REGEX.test(value)
            ? message.INVALID_COUNTRYCODE
            : value.length < 2 || value.length > 3
            ? message.COUNTRYCODE_LENGTH
            : "";
        break;
      case "reason":
        errors.reason =
          value.length === 0
            ? message.REASON_REQUIRED
            : value.length < 20 || value.length > 100
            ? message.MIN_REQUIRED +
              20 +
              message.MAX_REQUIRED +
              100 +
              message.EXTRA_VALUE
            : "";
        break;
      case "SPpassword":
        errors.SPpassword =
          value.length === 0
            ? message.SET_PASSWORD_REQUIRED
            : !regex.PASSWORD_VALUE_REGEX.test(value)
            ? message.PASSWORD_VALID
            : value.length < 8 || value.length > 20
            ? message.PASSWORD_MINMAX_REQUIRED
            : "";
        break;
      case "SPcpassword":
        errors.SPcpassword =
          value.length === 0
            ? message.CONFIRM_PASSWORD_REQUIRED
            : !(value.length > 0 && formInputs.SPpassword === value)
            ? message.PASSWORD_MISMATCH
            : "";
        break;
      default:
        break;
    }
    setError({ ...error, errors });
  };
  const validateForm = (err) => {
    let valid = true;
    Object.values(err).forEach((val) => val.length > 0 && (valid = false));
    return valid;
  };
  const handleSignupSubmit = () => {
    checkData();
    if (validateForm(error)) {
      dispatch(
        register({
          firstName: formInputs.fname,
          lastName: formInputs.lname,
          emailId: formInputs.email,
          phoneNumber: formInputs.phnumber,
          countryCode: formInputs.countryCode,
          accessReason: formInputs.reason,
        })
      )
        .then(() => {
          setShowModal({
            ...showModal,
            showAdminVerifyModal: true,
          });
        })
        .catch((err) => {
          if (err?.data?.detail) {
            if (err?.data?.detail.includes("does not support this domain")) {
              setErrorMsg({ ...errorMsg, signup: message.ACCESS_DENIED });
            } else if (
              err?.data?.detail.includes("is already registered. Kindly login")
            ) {
              setErrorMsg({ ...errorMsg, signup: message.EMAIL_FOUND });
            } else {
              setErrorMsg({ ...errorMsg, signup: err?.data?.detail });
            }
          } else {
            setErrorMsg({
              ...errorMsg,
              signup: message.PATIENT_REGISTRATION_UNSUCCESS,
            });
          }
        });
    }
  };
  const handleSetPwdSubmit = () => {
    checkSetPwdData();
    if (validateForm(error)) {
      AuthService.setPassword({
        emailId: formInputs.email,
        password: formInputs.SPpassword,
        confirmPassword: formInputs.SPcpassword,
      })
        .then((res) => {
          if (res.status === 200) {
            setShowModal({
              ...showModal,
              showSetPwdModal: false,
            });
            navigate("/login", {
              replace: true,
              state: { msg: "Password successfully updated. Please Login" },
            });
          }
        })
        .catch((err) => {
          if (err && err.response && err.response.status) {
            let errdata = err.response.data.detail;
            if (errdata.includes("Could not find user in our system")) {
              setErrorMsg({ ...errorMsg, setpwd: message.EMAIL_NOT_FOUND });
            } else {
              setErrorMsg({ ...errorMsg, setpwd: errdata });
            }
          } else {
            setErrorMsg({
              ...errorMsg,
              setpwd: message.SOMETHING_WENT_WRONG,
            });
          }
        });
    }
  };

  useEffect(() => {
    if (matchsetPwd !== null) {
      validate("email", matchsetPwd.params.emailId);
      if (validateForm(error)) {
        setForminputs({ ...formInputs, email: matchsetPwd.params.emailId });
        setShowModal({ ...showModal, showSetPwdModal: true });
      } else {
        setError({ ...error, email: "" });
        setShowModal({ ...showModal, showSetPwdModal: false });
      }
    } else {
      setShowModal({ ...showModal, showSetPwdModal: false });
    }
  }, [location]);
  return (
    <>
      <div>
        <div className="signup">
          <LoginLayout />
          <div className="signup__Form">
            <div className="signup__rightside__container">
              <div className="login--logo">
                <img
                  src={logo}
                  width="100%"
                  height="100%"
                  alt="autonostix_logo"
                ></img>
              </div>
              <div className="signup__Form--title">
                <p>Create an account</p>
              </div>
              <span className="signup__Form--error">{errorMsg.signup}</span>
              <div className="signup__Form--inputs">
                <Input
                  htmlForId="fname"
                  type="text"
                  placeholder="First name"
                  value={formInputs.fname}
                  handleOnChange={handleInputChange}
                  error={error.fname}
                  onBlur={handleInputChange}
                  onKeyDown={handleKeyDown}
                />
                <Input
                  htmlForId="lname"
                  type="text"
                  placeholder="Last name"
                  value={formInputs.lname}
                  handleOnChange={handleInputChange}
                  error={error.lname}
                  onBlur={handleInputChange}
                  onKeyDown={handleKeyDown}
                />
                <Input
                  htmlForId="email"
                  type="text"
                  placeholder="Email"
                  value={formInputs.email}
                  handleOnChange={handleInputChange}
                  error={error.email}
                  onBlur={handleInputChange}
                  onKeyDown={handleKeyDown}
                />
                <div className="phnumber__container">
                  <div className="signup__Form--phnumber input__field--mobile">
                    <input
                      value={formInputs.countryCode}
                      placeholder="+1"
                      onChange={handleInputChange}
                      onBlur={handleInputChange}
                      type="tel"
                      id="countryCode"
                      name="countryCode"
                      maxLength="3"
                      size="3"
                      onKeyDown={handleKeyDown}
                      disabled
                    />
                    <input
                      value={formInputs.phnumber}
                      placeholder="Phone number"
                      onChange={handleInputChange}
                      onBlur={handleInputChange}
                      type="tel"
                      id="phnumber"
                      name="phnumber"
                      maxLength="14"
                      size="10"
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                  {error.countryCode && <span>{error.countryCode}</span>}
                  {error.phnumber && <span>{error.phnumber}</span>}
                </div>
                <div className="textareabox">
                  <textarea
                    id="reason"
                    name="reason"
                    rows={3}
                    onChange={handleInputChange}
                    //placeholder="Please write the reason for authentication here so that we can process your request and give you the concerned level of access to you."
                    value={formInputs.reason}
                    onKeyDown={handleKeyDown}
                    required="required"
                  />
                  <label htmlFor="reason">
                    Please write the reason for authentication here
                  </label>
                </div>
                {error.reason && <span>{error.reason}</span>}
              </div>

              <div className="signup-footer">
                <div className="signup__Form--footer">
                  <span>
                    Already have an account?
                    <Link to="/login">
                      <b>Login</b>
                    </Link>
                  </span>
                </div>
                <div className="signup__Form--btn">
                  <div
                    onClick={handleSignupSubmit}
                    className="btn-primary-blue"
                  >
                    SignUp
                    <div style={{ marginTop: "5px" }}>
                      <img src={rightArrow} alt="rightArrow" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal.showAdminVerifyModal && (
        <Modal
          type={"medium"}
          title={""}
          titlePosition={"center"}
          isError={false}
          displayCloseIcon={false}
        >
          <div className="adminverify__pop">
            <div className="adminverify__pop--title">
              <p>Admin Verification</p>
            </div>
            <div className="adminverify__pop--inputs">
              <p>
                Your request has been sent to the admin, we will notify you once
                you have been approved.
              </p>
              <Button
                title="Close"
                handleClick={() => {
                  setForminputs(initialState);
                  setShowModal({
                    ...showModal,
                    showAdminVerifyModal: !showModal.showAdminVerifyModal,
                  });
                }}
                styleClasses="btn-primary-blue"
              />
            </div>
          </div>
        </Modal>
      )}
      {showModal.showSetPwdModal && (
        <Setpassword
          handleFPInputChange={handleInputChange}
          SPpassword={formInputs.SPpassword}
          SPcpassword={formInputs.SPcpassword}
          error={error}
          handleClose={(e) => e.stopPropagation()}
          handleClick={handleSetPwdSubmit}
          errorSPMsg={errorMsg.setpwd}
        />
      )}
    </>
  );
};

export default Signup;
