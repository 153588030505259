import React, { useEffect } from "react";
import { useState } from "react";
import Modal from "../Modal/Modal";
import { useNavigate } from "react-router-dom";

const ModalPopup = ({
  firstParagraph,
  secondParagraph,
  thirdParagraph,
  hideInput,
  firstButton,
  secondButton,
  onValueChange,
  closeModal,
  errorModel,
  onClick,
  inputValue,
  placeholder,
  maxLength,
  ModalPopup,
  displayInput,
  buttonClassName = "btn-primary-blue",
  disableSecondButton = false,
}) => {
  const [value, setValue] = useState(inputValue);
  const navigate = useNavigate();

  useEffect(() => onValueChange(value));
  return (
    <>
      <Modal handleClose={closeModal} wrapperClass="vin-modal">
        <div className="model-container">
          <span className="error-text">{errorModel}</span>
          <p>{firstParagraph}</p>
          <p>{secondParagraph}</p>
          <p>{thirdParagraph}</p>
          {displayInput !== "true" && (
            <input
              type="text"
              value={value}
              placeholder={placeholder}
              maxLength={maxLength ? maxLength : "17"}
              onChange={(e) => setValue(e.target.value)}
              hidden={hideInput}
            ></input>
          )}
          <div className="individual-vehicle-button">
            <button className="btn-secondary-blue" onClick={closeModal}>
              {firstButton}
            </button>
            <button
              className={buttonClassName}
              onClick={onClick}
              disabled={disableSecondButton}
            >
              {secondButton}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalPopup;
