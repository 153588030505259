import React from "react";
import "../VehiclePopulation.scss";
import SelectFromApi from "./SelectFromApi";

const Diagonisticsform = ({ handleDiagonisticsData }) => {
  return (
    <div>
      <div className="filters" id="DTC">
        <label htmlFor="DTC">DTC</label>
        <SelectFromApi
          name={"dtcs"}
          handleOnChange={handleDiagonisticsData}
          filterValue={"dtc"}
        />
      </div>

      <div className="filters" id="Customercomplaint">
        <label htmlFor="Customercomplaint">Customer complaint</label>
        <SelectFromApi
          name={"customerComplaint"}
          handleOnChange={handleDiagonisticsData}
          filterValue={"customerComplaint"}
        />
      </div>
      <div className="filters" id="FreezeFramedata">
        <label htmlFor="FreezeFramedata">Freeze Frame data</label>
        <SelectFromApi
          name={"freezeFrameData"}
          handleOnChange={handleDiagonisticsData}
          filterValue={"freezeFrameData"}
        />
      </div>
    </div>
  );
};

export default Diagonisticsform;
