import React, { useState } from "react";
import { ConfigureFeatures } from "../../../services/service-constants";
import { mainAxiosInstance } from "../../../services/service";
import "../index.scss";

const ConfigureFeature = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [textData, setTextData] = useState("");

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleDelete = () => {
    const url = `${ConfigureFeatures.fileUploadDeleteEndpoint}`;
    mainAxiosInstance
      .delete(url)
      .then((response) => {
        setTextData(response.data);
      })
      .catch((error) => {
        setTextData("Something went worng");
      });
  };

  const handleTrainingDelete = () => {
    const url = `${ConfigureFeatures.fileUploadTrainingDeleteEndpoint}`;
    mainAxiosInstance
      .delete(url)
      .then((response) => {
        setTextData(response.data);
      })
      .catch((error) => {
        setTextData("Something went worng");
      });
  };

  const handleTrainingSubmission = () => {
    if (selectedFile) {
      const url = `${ConfigureFeatures.fileUploadTrainingpoint}`;
      let formData = new FormData();
      formData.append("multipartFile", selectedFile);

      mainAxiosInstance
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setTextData(response.data);
          }
          if (response.status === 401) {
            setTextData("Something went worng");
          }
        })
        .catch((err) => {
          setTextData("Something went worng");
        });
    }
  };

  const handleSubmission = () => {
    if (selectedFile) {
      const url = `${ConfigureFeatures.fileUploadpoint}`;
      let formData = new FormData();
      formData.append("multipartFile", selectedFile);

      mainAxiosInstance
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setTextData(response.data);
          }
          if (response.status === 401) {
            setTextData("Something went worng");
          }
        })
        .catch((err) => {
          setTextData("Something went worng");
        });
    }
  };

  return (
    <div className="page-wrapper">
      <div className="configure-feature">
        <p>Please follow below step for digital twin data creation</p>
        <div className="clear-all-text">
          <p className="upload-text">Clear all training data</p>
          <button onClick={handleTrainingDelete} style={{ marginLeft: "80px" }}>
            Clear
          </button>
        </div>
        <div className="clear-all-text">
          <p className="upload-text">Clear all data</p>
          <button onClick={handleDelete} style={{ marginLeft: "140px" }}>
            Clear
          </button>
        </div>
        <div className="upload-file">
          <p className="upload-text">Upload digital twin Data</p>
          <input
            type="file"
            id="file-upload"
            name="file"
            onChange={changeHandler}
            style={{ marginLeft: "60px" }}
          />

          <button onClick={handleSubmission}>Upload File</button>
        </div>
        <div className="upload-file">
          <p className="upload-text">Upload training digital twin Data</p>
          <input
            type="file"
            id="file-upload"
            name="file"
            onChange={changeHandler}
          />

          <button onClick={handleTrainingSubmission}>Upload File</button>
        </div>
        <p className="upload_Error_Msg">{textData}</p>
      </div>
    </div>
  );
};

export default ConfigureFeature;
