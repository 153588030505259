import { useEffect } from "react";
import Highcharts from "highcharts";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useState } from "react";
import arrowXAxis from "../../../assets/arrow-xaxis.svg";
import HighchartsPatternFill from "highcharts/modules/pattern-fill";
// import HighchartsPatternFill from "highcharts-pattern-fill";

HighchartsPatternFill(Highcharts);
const linePattern = "M 0 10 L 10 0 M 9 11 L 11 9 M -1 1 L 1 -1";
const DualColumnGraph = (data) => {
  const [FirstColumnData, setFirstColumnData] = useState(null);
  const [SecondColumn, setSecondColumn] = useState(null);
  const [xAxisNames, setxAxisNames] = useState([]);
  const [graphInformation, setgraphInformation] = useState(null);
  const location = useLocation();
  const containerId = location.search.slice(5);
  const [toolTipValue, setToolTipValue] = useState("");
  const pageSize = data.pageSize;
  const yLabel =
    data?.chartdata[0]?.graphInformation?.yAxisName || "Number of occurrences";

  useEffect(() => {
    if (data.chartdata[0]?.FirstColumnData) {
      const firstData = data.chartdata[0]?.FirstColumnData?.map((data) => ({
        y:
          containerId === "13" || containerId === "12"
            ? data.noOfOccurrences
            : data,
        color:
          containerId === "13" || containerId === "12"
            ? data.color
            : // : containerId === "0" || containerId === "1"
              // ? "#041F3E"
              "#041F3E",
      }));
      if (containerId === "12" || containerId === "13") {
        setFirstColumnData(firstData.filter((val) => val.y !== 0));
      } else {
        setFirstColumnData(firstData);
      }
    }
    if (data.chartdata[0]?.SecondColumn) {
      const secondData = data.chartdata[0]?.SecondColumn?.map((data) => ({
        y:
          containerId === "13" || containerId === "12"
            ? data.noOfOccurrences
            : data,
        color:
          containerId === "13" || containerId === "12"
            ? {
                pattern: {
                  backgroundColor: data.color,
                  path: {
                    d: linePattern,
                    stroke: "white",
                  },
                  width: 10,
                  height: 10,
                },
              }
            : // : containerId === "0" || containerId === "1"
              // ? "#0574A6"
              //   :
              "#0574A6",
      }));
      if (containerId === "12" || containerId === "13") {
        setSecondColumn(secondData.filter((val) => val.y !== 0));
      } else {
        setSecondColumn(secondData);
      }
    }
    // setFirstColumnData(data.chartdata[0]?.FirstColumnData);
    // setSecondColumn(data.chartdata[0]?.SecondColumn);
    if (containerId === "1" || containerId === "10" || containerId === "9") {
      let containerTen = data.chartdata[0]?.xAxisNames?.map((data, index) => {
        return index + 1;
      });
      setToolTipValue(data.chartdata[0]?.xAxisNames);
      setxAxisNames(containerTen);
    } else if (containerId === "12" || containerId === "13") {
      const xAxis = Object.values(data?.chartdata[0]?.xAxisNames).filter(
        (val) => val !== ""
      );
      let version = xAxis?.map((value) => {
        return value.split("_").reverse()[0].slice(2);
      });
      setxAxisNames(version);
      setToolTipValue(
        data?.chartdata[0]?.xAxisNames.filter((val) => val !== "")
      );
    } else {
      setxAxisNames(
        data?.chartdata[0]?.xAxisNames?.filter((values) => values !== null)
      );
    }
    setgraphInformation(data.chartdata[0]?.graphInformation);
  }, [data]);

  useEffect(() => {
    renderChart(graphInformation, FirstColumnData, SecondColumn, xAxisNames);
  }, [FirstColumnData, SecondColumn, graphInformation]);

  const getyAxisTitle = (graphInformation, xAxisNames) => {
    return xAxisNames?.length < 1
      ? {
          text: null,
        }
      : {
          text: `<div class="y-axis-legend-label"><div>${graphInformation?.yAxisName}</div><div class="y-axis-arrow"></div></div>`,
          style: {
            color: "#353536",
            lineHeight: 1,
            textAlign: "right",
            font: "11px Open Sans",
            letterSpacing: "1px",
            fontWeight: 800,
          },
          align: "high",
          margin: 20,
          useHTML: true,
        };
  };
  function AxisNames(xAxisNames) {
    if (
      !xAxisNames ||
      xAxisNames.length === 0 ||
      typeof xAxisNames[0] !== "string"
    ) {
      return [];
    } else {
      return xAxisNames;
    }
  }

  const getxAxisTitle = (graphInformation, xAxisNames) => {
    return xAxisNames?.length < 1
      ? {
          text: null,
        }
      : {
          text: `<div class="x-axis-legend"><img src='${arrowXAxis}'/><div>${graphInformation?.xAxisName}</div></div>`,
          style: {
            color: "#353536",
            lineHeight: 1,
            textAlign: "right",
            font: "11px Open Sans",
            letterSpacing: "1px",
            fontWeight: 800,
          },
          align: "high",
          useHTML: true,
          margin: 20,
        };
  };

  const renderChart = (
    graphInformation,
    FirstColumnData,
    SecondColumn,
    graxAxisNamesphId
  ) => {
    Highcharts.chart("Container", {
      chart: {
        // backgroundColor: '#c9ced740',
        type: "column",
        height: 350,
        spacing: [50, 10, 10, 0],
      },
      title: {
        text: null,
      },
      yAxis: {
        title: getyAxisTitle(graphInformation, xAxisNames),

        gridLineColor: "#CECECE",

        labels: {
          style: {
            color: "#353536",
            lineHeight: 1,
            font: "12px Open Sans",
            letterSpacing: "1px",
            fontWeight: 600,
          },
        },
      },

      xAxis: {
        categories: xAxisNames?.map((number, index) => {
          if (typeof number === "number") {
            if (pageSize == 1) {
              return index + 11;
            } else {
              return index + 1;
            }
          } else {
            return number;
          }
        }),
        // categories: AxisNames(xAxisNames),

        title: getxAxisTitle(graphInformation, xAxisNames),
        style: {
          color: "#353536",
          lineHeight: 1,
          font: "11px Open Sans",
          letterSpacing: "1px",
          fontWeight: 600,
          fontWeight: "bold",
        },
        startOnTick: true,
        min: 0,
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },

      plotOptions: {
        series: {
          pointWidth: 10,
          borderRadius: 3,
        },
      },

      tooltip: {
        formatter: function () {
          return location.search === "?key=3"
            ? this.point.category +
                "</br> " +
                "Warranty Score" +
                ":" +
                this.point.y
            : containerId === "1" ||
              containerId === "9" ||
              containerId === "10" ||
              containerId === "12" ||
              containerId === "13"
            ? toolTipValue[this.point.index] +
              "</br> " +
              yLabel +
              ":" +
              this.point.y
            : containerId === "15"
            ? `Temperature :` +
              this.point.category +
              "</br> " +
              yLabel +
              ":" +
              this.point.y
            : this.point.category + "</br> " + yLabel + ":" + this.point.y;
        },
      },

      series: [
        {
          data: FirstColumnData?.length === 0 ? [0] : FirstColumnData,
          name: yLabel,
        },
        {
          data: SecondColumn?.length === 0 ? [0] : SecondColumn,
          name: yLabel,
        },
      ],

      // colors: ["#041F3E", "#0574A6"],
    });
  };

  return (
    <figure className="vehicle-population-highchart">
      <div id="Container"></div>
    </figure>
  );
};

export default DualColumnGraph;
