import { mainAxiosInstance } from "../../../services/service";
import { vehicleOverviewEndpoints } from "../../../services/service-constants";


export const getVehicleNotificationData = async (payload) => {
  const url = `${vehicleOverviewEndpoints.vehicleNotification}`;
  //   TODO : dynamic vin_number and org_id required
  const params = {
    vin_number: payload?.vinNumber,
    organization_id: 1,
    page: payload?.page || 0,
    size: payload?.size || 10,
  };
  return mainAxiosInstance
    .get(url, { params: params })
    .then((response) => {
      if (response.status === 200) {
        return {
          code: response.status,
          data: response.data,
        };
      }
      if (response.status === 401) {
        return {
          code: response.status,
          data: response.data?.details || "You are not authorized.",
        };
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        return {
          code: err.response.status,
          data: err.response.data?.details || "You are not authorized",
        };
      } else {
        return {
          code: "error",
          data: err.response.data?.details || "Something went wrong",
        };
      }
    });
};

export const getVehicleInformationData = async (payload) => {
  const url = `${vehicleOverviewEndpoints.vehilceInformation}`;
  const { vinNumber } = payload;
  const params = {
    vin_number: vinNumber,
    organization_id: 1,
  };
  return mainAxiosInstance
    .get(url, { params: params })
    .then((response) => {
      if (response.status === 200) {
        return {
          code: response.status,
          data: response.data,
        };
      }
      if (response.status === 401) {
        return {
          code: response.status,
          data: response.data?.details || "You are not authorized ",
        };
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        return {
          code: err.response.status,
          data: err.response.data?.details || "You are not authorized ",
        };
      } else {
        return {
          code: "error",
          data: err.response.data?.details || "Something went wrong",
        };
      }
    });
};

export const getAllChartData = async (payload) => {
  const url = `${vehicleOverviewEndpoints.vehilceRealtime}`;
  const params = {
    vin_number: payload.VinNumber,
    organization_id: 1,
  }
  return mainAxiosInstance
    .get(url, { params: params })
    .then((response) => {
      if (response.status === 200) {

        return {
          code: response.status,
          data: response.data,
        };
      }
      if (response.status === 401) {

        return {
          code: response.status,
          data: response.data?.details || "You are not authorized",

        };

      }

    })
    .catch((err) => {
      if (err.response.status === 401) {
        return {
          code: err.response.status,
          data: err.response.data?.details || "You are not authorized.",
        };

      }
      else {
        return {
          code: "error",
          data: err.response.data?.details || "Something went wrong",
        };

      }
    });
};
