import moment from "moment";
import React from "react";
import { useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import RightArrowLongIcon from "../assets/right-arrow-long.svg";
import LengendContainer from "./LengendContainer";
import { useSelector } from "react-redux";
import { colorMap, getTickIntervals, graphType } from "../utils/constants";
import HC_Regression from "highcharts-regression";
import NoDataFound from "./NoDataFound";

// usage of highcharts-regression
HC_Regression(Highcharts);

const TotalEngineRuntime = ({ data, filterType }) => {
  const isSidemenuExpanded = useSelector(
    (state) => state.mainReducer.isSidemenuExpanded
  );

  const [odometerTick, setOdometerTick] = useState([]);

  const [chartData, setChartData] = useState(null);
  const [engineLoadArray, setEngineLoadArray] = useState(null);
  const [chartInfo, setChartInfo] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  useEffect(() => {
    setChartData(data?.timeAccessoriesInUse);
    if (data && data?.timeAccessoriesInUse.length > 0) {
      let engineLoadArray = [];
      switch (filterType) {
        case graphType.DATE: {
          engineLoadArray = data.timeAccessoriesInUse.map((item) => [
            moment(item.statsDate).format("DD"),
            item.timeAccessoriesInUseWrtTotalEngineRuntime,
          ]);
          break;
        }
        case graphType.MILES: {
          engineLoadArray = data.timeAccessoriesInUse.map((item) => [
            item.odometer,
            item.timeAccessoriesInUseWrtTotalEngineRuntime,
          ]);
          if (engineLoadArray.length > 1) {
            const tickArray = data.timeAccessoriesInUse
              .map((item) => item.odometer)
              .sort((a, b) => a - b);
            const tArray = getTickIntervals(tickArray);
            setOdometerTick(tArray);
          }
          break;
        }
        case graphType.TRIP: {
          engineLoadArray = data.timeAccessoriesInUse.map((item) => [
            item.trip,
            item.timeAccessoriesInUseWrtTotalEngineRuntime,
          ]);
          break;
        }
        default: {
          engineLoadArray = data.timeAccessoriesInUse.map((item) => [
            moment(item.statsDate).format("DD"),
            item.timeAccessoriesInUseWrtTotalEngineRuntime,
          ]);
        }
      }

      setChartInfo(data?.information);
      setEngineLoadArray(engineLoadArray);
      const month = moment(data?.timeAccessoriesInUse[0].statsDate).format(
        "MMMM"
      );
      const year = moment(data?.timeAccessoriesInUse[0].statsDate).format(
        "YYYY"
      );
      setMonth(month);
      setYear(year);
    } else {
      setEngineLoadArray(null);
    }
  }, [data]);

  const options = {
    chart: {
      backgroundColor: "#FFFFFF",
      spacing: [50, 50, 30, 20],
      height: 350,
      type: "scatter",
      // borderColor: "#CECECE",
      borderRadius: 20,
      // borderWidth: 1,
      events: {
        load: function () {
          this.series[1]?.update({
            enableMouseTracking: false,
          });
        },
      },
    },
    title: {
      text: null,
    },
    yAxis: {
      title: {
        useHTML: true,
        text: `
        <div style="font-size: 11px; left:-160px;top:4px;position:absolute;">
         Total Engine Runtime (%)
          <img src='${RightArrowLongIcon}'/>
        </div>
        `,
        align: "high",
      },
      min: 0,
      labels: {
        style: {
          color: "#353536",
          lineHeight: 1,
          font: "10px Open Sans",
          letterSpacing: "1px",
        },
      },
      gridLineColor: "#F2F2F2",
    },
    xAxis: {
      type: "category",
      tickPositions: filterType === graphType.MILES ? odometerTick : null,
      title: {
        useHTML: true,
        text: `
        <div style="font-size: 11px; left:-60px;top:4px;position:absolute;">
           ${
             filterType === graphType.DATE
               ? `${month},${year}`
               : filterType === graphType.MILES
               ? `Miles`
               : filterType === graphType.TRIP
               ? `Trips`
               : `${month},${year}`
           }
          <img src='${RightArrowLongIcon}'/>
        </div>
        `,
        align: "high",
      },
      labels: {
        style: {
          color: "#353536",
          lineHeight: 1,
          font: "10px Open Sans",
          letterSpacing: "1px",
        },
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: true,
        },
        marker: {
          radius: 3,
        },
      },
      line: {
        dashStyle: "Dash",
      },
    },
    tooltip: {
      backgroundColor:"#000",
      style: {
        color: "#FFFFFF",
        font: "12px Open Sans",
      },
    },
    series: [
      {
        data: engineLoadArray,
        showInLegend: false,
        name: "Mpg Target HighWay",
        color: chartInfo && colorMap[chartInfo[0]?.color],
        regression: engineLoadArray?.length > 1 ? true : false,
        regressionSettings: {
          type: "polynomial",
          order: "2",
          color: chartInfo && colorMap[chartInfo[0]?.color],
          dashStyle: "dot",
        },
      },
    ],
  };

  return (
    <div key={engineLoadArray}>
      <div key={isSidemenuExpanded}>
        {Array.isArray(engineLoadArray) && engineLoadArray.length > 0 ? (
          <>
            <LengendContainer chartInfo={chartInfo} />
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              updateArgs={[true, true, true]}
            />
            <div
              style={{ width: "100%", textAlign: "center", paddingTop: "10px" }}
            >
              <p style={{ fontSize: "14px", fontWeight: "600" }}>
                Time Accessories in use / Total Engine Runtime (%)
              </p>
            </div>
          </>
        ) : (
          <>
            <NoDataFound chartName="Time Accessories in use / Total Engine Runtime (%)" />
          </>
        )}
      </div>
    </div>
  );
};

export default TotalEngineRuntime;
