import { useEffect } from "react";
import { useSelector } from "react-redux";
import Highcharts from "highcharts";
import arrowYAxis2 from "../../../assets/arrow_second_axis.svg";
import arrowXAxis from "../../../assets/arrow-xaxis.svg";
import "../Graphs/index.scss";

export const DualColumn = (data) => {
  const {
    id,
    dataAxis1,
    dataAxis2,
    title,
    xLabel,
    yLabel,
    yLabel2,
    xAxisValues,
    labelfirstText,
    labelSecondText,
  } = data.data[0];

  const isSidemenuExpanded = useSelector(
    (state) => state.mainReducer.isSidemenuExpanded
  );

  useEffect(() => {
    renderChart(
      id,
      dataAxis1,
      dataAxis2,
      title,
      xLabel,
      yLabel,
      yLabel2,
      xAxisValues,
      labelSecondText,
      labelfirstText
    );
  }, [dataAxis1, dataAxis2, isSidemenuExpanded]);

  const getPrimaryYAxisTitle = (data1, data2, yLabel) => {
    return data1?.length < 1 && data2?.length < 1
      ? {
          text: null,
        }
      : {
          text: `<div class="y-axis-legend"><div>${yLabel}</div><div class="y-axis-arrow"></div></div>`,
          style: {
            color: "#353536",
            lineHeight: 1,
            textAlign: "right",
            font: "10px Open Sans",
            letterSpacing: "1px",
          },
          align: "high",
          useHTML: true,
          margin: 20,
        };
  };

  const getSecondaryYAxisTitle = (data1, data2, yLabel) => {
    return data1?.length < 1 && data2?.length < 1
      ? {
          text: null,
        }
      : {
          text: `<div class="axis-legend"><img src='${arrowYAxis2}'/><div>${yLabel}</div></div>`,
          style: {
            color: "#353536",
            lineHeight: 1,
            textAlign: "right",
            font: "10px Open Sans",
            letterSpacing: "1px",
          },
          align: "high",
          useHTML: true,
          margin: 20,
        };
  };

  const getxAxisTitle = (data1, data2, xLabel) => {
    return data1?.length < 1 && data2?.length < 1
      ? {
          text: null,
        }
      : {
          text: `<div class="x-axis-legend"><div>${xLabel}</div><img src='${arrowXAxis}'/></div>`,
          style: {
            color: "#353536",
            lineHeight: 1,
            textAlign: "right",
            font: "10px Open Sans",
            letterSpacing: "1px",
          },
          align: "high",
          useHTML: true,
          margin: 20,
        };
  };

  const renderChart = (
    id,
    dataAxis1,
    dataAxis2,
    title,
    xLabel,
    yLabel,
    yLabel2
  ) => {
    Highcharts.chart(
      title,
      {
        chart: {
          backgroundColor: "#FFFFFF",
          type: "column",
          height: 350,
          spacing: [50, 10, 10, 0],
          borderRadius: 20,
          // borderWidth: 1,
        },
        title: {
          text: null,
        },
        yAxis: [
          {
            title: getPrimaryYAxisTitle(dataAxis1, dataAxis2, yLabel),
            labels: {
              style: {
                color: "#353536",
                lineHeight: 1,
                font: "10px Open Sans",
                letterSpacing: "1px",
              },
              formatter: function () {
                if (id === "cost") {
                  return "$" + this.value + " " + "USD";
                } else {
                  return this.value;
                }
              },
            },
            gridLineColor: "#F2F2F2",
          },
          {
            title: getSecondaryYAxisTitle(dataAxis1, dataAxis2, yLabel2),
            labels: {
              style: {
                color: "#353536",
                lineHeight: 1,
                font: "10px Open Sans",
                letterSpacing: "1px",
              },
            },
            opposite: true,
            gridLineColor: "#F2F2F2",
          },
        ],
        xAxis: {
          categories: xAxisValues,
          title: getxAxisTitle(dataAxis1, dataAxis2, xLabel),
          labels: {
            style: {
              color: "#353536",
              // lineHeight: 1,
              font: "10px Open Sans",
              letterSpacing: "1px",
            },
          },
          tickWidth: 0,
        },
        plotOptions: {
          series: {
            pointWidth: 20,
          },
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          backgroundColor: "#000",
          style: {
            color: "#FFFFFF",
            font: "12px Open Sans",
          },
        },
        series: [
          {
            name: yLabel,
            data: dataAxis1,
          },
          {
            name: yLabel2,
            data: dataAxis2,
            yAxis: 1,
          },
        ],
      },
      function (chart) {
        const data1Len = chart.series?.[0]?.data?.length || 0;
        const data2Len = chart.series?.[1]?.data?.length || 0;
        if (data1Len < 1 && data2Len < 1) {
          // check series is empty
          chart.renderer
            .text("No Data Available", 50, 100)
            .css({
              color: "#000000",
              fontSize: "16px",
            })
            .add();
        }
      }
    );
  };

  return (
    <figure className="vehicle-population-highchart">
      <div id={title}></div>
      <div className="legend-wrapper">
        <div className="green-legend"></div>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className="green-legend">
          <span
            className="color-box"
            style={{ "background-color": "#4472c4" }}
          ></span>
          <span className="color-legend">{labelfirstText}</span>
        </div>
        <div className="green-legend">
          <span
            className="color-box"
            style={{ "background-color": "#ffc000" }}
          ></span>
          <span className="color-legend">{labelSecondText}</span>
        </div>
      </div>
    </figure>
  );
};
