import React, { useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import RightArrowLongIcon from "../../assets/right-arrow-long.svg";
import LottieLoad from "../../../../components/common/ErrorComponents/LottieLoad";

const BrakePedalPositionChart = ({ height, width, brakePedalData }) => {
  const [data, setData] = useState(null);
  const [brakePedalArray, setBrakePedalArray] = useState(null);
  useEffect(() => {
    setData(brakePedalData);
    // We are slicing the data upto 1000 points b.c highcharts allow dataset upto 1000 points in normal config
    if (data) {
      const brakePedalArray = data
        ?.splice(0, 1000)
        ?.map((item) => [
          item?.dataRecordTime,
          String(item?.brakePedalPositionSensor).includes("%")
            ? parseFloat(item?.brakePedalPositionSensor) / 1
            : parseFloat(item?.brakePedalPositionSensor),
        ]);
      setBrakePedalArray(brakePedalArray);
    }
  }, [brakePedalData, data]);

  const options = {
    chart: {
      type: "areaspline",
      // zoomType: "x",
      height: height || "250px",
      width: width || "100%",
      spacing: [10, 10, 10, 20],
      backgroundColor: null,
      borderRadius: 20,
    },
    tooltip: {
      backgroundColor: "#000",
      style: {
        color: "#FFFFFF",
        font: "12px Open Sans",
      },
      // backgroundColor: "#FCF1CC",
      borderColor: "none",
      shared: true,
      useHTML: true,
      headerFormat: "Time: {point.key}",
      pointFormat: "<div>Position: {point.y}</div>",
      footerFormat: "",
      padding: 4,
    },

    title: {
      text: "Brake Pedal Position",
      align: "center",
      verticalAlign: "bottom",
      // floating: true,
    },
    credits: {
      enabled: false,
    },

    xAxis: {
      type: "category",
      labels: {
        enabled: false,
      },

      title: {
        useHTML: true,
        text: `
        <div style="margin-right:24px;  font-size: 10px; position:relative;top:10px">
          Time
          <br/>
          <img src='${RightArrowLongIcon}'/>
        </div>
        `,
        align: "high",
      },
      visible: true,
      lineWidth: 1,
      lineColor: "#000000",
      gridLineWidth: 0,
    },
    yAxis: {
      title: {
        useHTML: true,
        text: `
        <div style="position: absolute;
          top: 0px; !important; 
          left: -155px; !important;
          font-size: 11px;
          ">
          Brake Pedal Position
          <img src='${RightArrowLongIcon}'/>
        </div>
        `,
        align: "high",
      },
      visible: true,
      lineWidth: 1,
      lineColor: "#000000",
      gridLineWidth: 0,
    },
    plotOptions: {
      areaspline: {
        lineColor: "#0574A6",
        fillColor: "#B6E5FA",
        width: 1,
      },
      series: {
        marker: {
          enabled: false,
        },
        lineWidth: 1,
      },
    },
    series: [
      {
        data: brakePedalArray,
        showInLegend: false,
        name: "GPP",
        color: "#0574A6",
      },
    ],
  };
  return (
    <div style={{ background: "#FFFF", borderRadius: "20px" }}>
      {Array.isArray(brakePedalArray) ? (
        <HighchartsReact highcharts={Highcharts} options={options} />
      ) : (
        <LottieLoad
          style={{ minHeight: "300px", height: "0px", marginTop: "0px" }}
        />
      )}
    </div>
  );
};

export default BrakePedalPositionChart;
