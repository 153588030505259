import {
  VIN_NUMBER,
  IS_SIDEMENU_EXPANDED,
  USER_PROFILE,
  SELECTED_FILTER_SELFTEST,
  FIRST_FILTER,
  SECOND_FILTER,
  VIN_FILTER,
  FLAG_DATA,
  DROP_DOWN_FILTER_SAVE,
} from "../types";

const initialState = {
  vin_Number: localStorage.getItem("vinId") || null,
  isSidemenuExpanded: true,
  User_Profile: {},
  selected_Filter_SelfTest: "KOEO",
  first_filter: null,
  second_filter: null,
  vin_filter: null,
  FLAG_DATA: [],
  dropDown_Filter_Save: false,
};

export const mainReducer = (state = initialState, action) => {
  switch (action.type) {
    case VIN_NUMBER:
      return { ...state, vin_Number: action.payload };
    case IS_SIDEMENU_EXPANDED:
      return { ...state, isSidemenuExpanded: action.payload };

    case USER_PROFILE:
      return { ...state, User_Profile: action.payload };

    case SELECTED_FILTER_SELFTEST:
      return { ...state, selected_Filter_SelfTest: action.payload };

    case FIRST_FILTER:
      return { ...state, first_filter: action.payload };

    case SECOND_FILTER:
      return { ...state, second_filter: action.payload };

    case VIN_FILTER:
      return { ...state, vin_filter: action.payload };
    case FLAG_DATA:
      return { ...state, Flag_data: action.payload };
    case DROP_DOWN_FILTER_SAVE:
      return { ...state, dropDown_Filter_Save: action.payload };
    default:
      return state;
  }
};
