export const EMAIL_REQUIRED = "Please enter a valid email address";
export const EMAIL_VAILD = "Please enter a valid email Address";
export const PASSWORD_REQUIRED = "Please enter a valid password";
export const CONFIRM_PASSWORD_REQUIRED =
  "Confirm password  is mandatory to update password";
export const PASSWORD_MISMATCH = "Password mismatch";
export const PASSWORD_VALID =
  "Password should contain at least one uppercase letter, one lowercase letter, one special character and one number";
export const UPDATE_PASSWORD_REQUIRED =
  "New password  is mandatory to update password";
export const SET_PASSWORD_REQUIRED = "Password  is mandatory";
export const PASSWORD_MINMAX_REQUIRED =
  "Min length of 8 characters and max of 20 characters are accepted";
export const INVALID_lOGIN = "Email or password is incorrect";
export const EMAIL_REG_REQUIRED = "Email ID is mandatory for registration";
export const EMAIL_REG_VAILD = "Please enter a valid email Address";
export const EMAIL_REG_ALREADY =
  "There is already an account existing with this email address. Please login in or reset password";
export const FNAME_REQUIRED = "First Name is mandatory for registration";
export const LNAME_REQUIRED = "Last Name is mandatory for registration";
export const INVALID_NAME = "Only alphabets are accepted";
export const COUNTRYCODE_REQUIRED =
  "Country code is mandatory for registration";
export const INVALID_COUNTRYCODE = "Please enter a valid Country code";
export const COUNTRYCODE_LENGTH = "Country code length in between 2 and 3";
export const PHNUMBER_REQUIRED = "Phone number is mandatory for registration";
export const INVALID_PHNUMBER = "Please enter a valid phone number";
export const PHNUMBER_LENGTH = "Max length of 10 numbers";
export const REASON_REQUIRED = "Reason is mandatory for registration";
export const MIN_REQUIRED = "Min length of ";
export const MAX_REQUIRED = " characters and max of ";
export const EXTRA_VALUE = " characters are accepted";
export const EMAIL_RESETPWD_REQUIRED =
  "Email ID is mandatory to get reset password link";
export const EMAIL_RESETPWD_VAILD = "Please enter a valid email Address";
export const EMAIL_NOT_FOUND =
  "There is no account associated with this email address. Kindly register";
export const EMAIL_FOUND =
  "There is already an account existing with this email address. Please login in or reset password";
export const ACCESS_DENIED = "Access denied";
export const PATIENT_REGISTRATION_UNSUCCESS =
  "Registration unsuccessful, kindly retry";
export const SOMETHING_WENT_WRONG = "Something went wrong";
export const CODE_REQUIRED = "Code is mandatory to login";
export const CODE_INVALID = "Invalid code";
export const CONTACT_ADMIN =
  "Your access is denied. Please contact your Administrator";
