import axios from "axios";

import {
  FETCH_VEHICLES,
  IS_SIDEMENU_EXPANDED,
  USER_PROFILE,
  VP_PAGE_TYPE,
  DROP_DOWN_FILTER_SAVE,
} from "../types";

import {
  VIN_NUMBER,
  SELECTED_FILTER_SELFTEST,
  FIRST_FILTER,
  SECOND_FILTER,
  VIN_FILTER,
  FLAG_DATA,
} from "../types";

export const fetchVehicles = () => (dispatch) => {
  axios
    .get("url")
    .then((res) => {
      dispatch({
        type: FETCH_VEHICLES,
        payload: res.data,
      });
    })
    .catch((err) => {});
};

export const addvin_Number = (payload) => ({
  type: VIN_NUMBER,
  payload: payload,
});

export const addFirstFilter = (payload) => ({
  type: FIRST_FILTER,
  payload: payload,
});

export const addSecondFilter = (payload) => ({
  type: SECOND_FILTER,
  payload: payload,
});

export const addVinFilter = (payload) => ({
  type: VIN_FILTER,
  payload: payload,
});

export const getIsMenuExpanded = (payload) => ({
  type: IS_SIDEMENU_EXPANDED,
  payload: payload,
});
export const getSelectedFilter_SelfTest = (payload) => ({
  type: SELECTED_FILTER_SELFTEST,
  payload: payload,
});

export const getUserProfile = (payload) => ({
  type: USER_PROFILE,
  payload: payload,
});

export const Flag_Data = (payload) => ({
  type: FLAG_DATA,
  payload: payload,
});

export const dropDownFilterSave = (payload) => ({
  type: DROP_DOWN_FILTER_SAVE,
  payload: payload,
});
