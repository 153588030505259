import { useEffect } from "react";
import Highcharts from "highcharts";
import "./DualColumnLinegraph.scss";

const DualColumnLineGraph = (props) => {
  const { data, id, fromVpUp = false, onClickHandler, title } = props;

  useEffect(() => {
    renderChart(data);
  }, [data]);

  const renderChart = (chartdata) => {
    Highcharts.chart(
      `Container-${id}`,
      {
        chart: {
          backgroundColor: "#FFFFFF",
          type: "column",
          height: 300,
          spacing: [30, 20, 20, 20],
          borderRadius: 20,
        },
        title: {
          text: null,
        },
        yAxis: {
          title: "",

          gridLineColor: "#CECECE",

          labels: {
            style: {
              color: "#353536",
              lineHeight: 1,
              font: "12px Open Sans",
              letterSpacing: "1px",
              fontWeight: 600,
            },
          },
        },

        xAxis: {
          categories: chartdata?.[0]?.categories,
          labels: {
            enabled: false,
          },
          title: {
            text: title,
            style: {
              color: "#000000",
              lineHeight: 1,
              textAlign: "right",
              font: "12px Open Sans",
              fontWeight: 600,
            },
            useHTML: true,
            margin: 20,
          },
          style: {
            color: "#353536",
            lineHeight: 1,
            font: "11px Open Sans",
            letterSpacing: "1px",
            fontWeight: 600,
          },
          startOnTick: true,
          min: 0,
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },

        plotOptions: {
          series: {
            pointWidth: 10,
            borderRadius: 3,
          },
        },

        tooltip: {
          backgroundColor: "#000",
          style: {
            color: "#FFFFFF",
            font: "12px Open Sans",
          },

          formatter: function () {
            if (fromVpUp) {
              if (Object.keys(this.point.options).length > 1) {
                return (
                  "<b>" +
                  this.x +
                  "</b><br/>" +
                  this.series.name +
                  " : " +
                  this.y +
                  " (" +
                  this.point.options.minValue +
                  "," +
                  this.point.options.maxValue +
                  ")" +
                  "</b><br/>" +
                  "Iqr : " +
                  this.point?.options.iqr +
                  "</b><br/>" +
                  "Standard Deviation : " +
                  this.point?.options.standardDeviation +
                  "</b><br/>" +
                  "Range : " +
                  this.point?.options.range +
                  "</b><br/>" +
                  "Variance : " +
                  this.point?.options.variance
                );
              } else {
                return (
                  "<b>" +
                  this.x +
                  "</b><br/>" +
                  this.series?.name +
                  " : " +
                  this.y
                );
              }
            }
          },
        },

        series: chartdata[0]?.data,
      },

      function (chart) {
        // on complete
        const dataLen = chart.series?.[0]?.data?.length || 0;

        if (dataLen < 1) {
          chart.renderer
            .text("No Data Available", 150, 140)
            .css({
              color: "#000000",
              fontSize: "16px",
            })
            .add();
        }
      }
    );
  };

  return (
    <>
      <figure className="highcharts-figure" onClick={() => onClickHandler()}>
        <div id={`Container-${id}`}></div>
      </figure>
    </>
  );
};

export default DualColumnLineGraph;
