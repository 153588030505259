import { useSelector } from "react-redux";
import { AiOutlineArrowLeft } from "react-icons/ai";
import VibrationalAnalysisTable from "./VibrationalAnalysisTable";
import { useNavigate } from "react-router";

const VATables = () => {
  const performanceData = useSelector(
    (state) => state.vAnalytics.performanceData
  );
  const navigate = useNavigate();

  return (
    <>
      <div className="page-wrapper vibrational-analytics-table">
        <button
          style={{ display: "flex", alignItems: "center", gridGap: "4px" }}
          onClick={() =>
            navigate("/vehicle-health/diagnostics/vibrational-analytics")
          }
        >
          <AiOutlineArrowLeft />
          Go Back
        </button>
        <VibrationalAnalysisTable performanceData={performanceData} />
      </div>
    </>
  );
};
export default VATables;
