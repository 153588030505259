import { useEffect } from "react";
import Highcharts from "highcharts";
import "./RangeChart.scss";
import close from "../../../assets/close.svg";
import { useSelector } from "react-redux";
// import { engineSpeedMile, engineSpeedLastTrip } from "../../../_mock/vehicle-usage-profile/engineSpeed";

export const BarChartLarge = ({
  id,
  data,
  title,
  yLabel,
  clickOnClose,
  fromVpUp = false,
}) => {
  const isSidemenuExpanded = useSelector(
    (state) => state.mainReducer.isSidemenuExpanded
  );
  let ratedRpmIndex = null;
  useEffect(() => {
    renderChart(data, title, yLabel);
  }, [data, isSidemenuExpanded]);

  const renderChart = (chartData, title, yLabel) => {
    ratedRpmIndex = (data?.[0]?.ratedRpm || []).findIndex((el) => el);
    Highcharts.chart(
      id,
      {
        chart: {
          backgroundColor: "#FFFFFF",
          type: "column",
          spacing: [60, 40, 20, 40],
          borderRadius: 20,
          // borderWidth: 1,
          // borderColor: "#CECECE",
        },
        title: {
          text: null,
        },
        yAxis: {
          title: {
            text: `<div class="y-axis-legend"><div>${yLabel}</div><div class="y-axis-arrow"></div></div>`,
            style: {
              color: "#000000",
              lineHeight: 1,
              textAlign: "right",
              font: "10px Open Sans",
              letterSpacing: "1px",
              fontWeight: 600,
            },
            align: "high",
            useHTML: true,
            margin: 20,
          },
        },
        xAxis: {
          categories: chartData?.[0]?.categories,
          title: {
            text: title,
            style: {
              color: "#000000",
              lineHeight: 1,
              textAlign: "right",
              font: "12px Open Sans",
              fontWeight: 600,
            },
            useHTML: true,
            margin: 20,
          },
          plotLines:
            ratedRpmIndex > -1
              ? [
                  {
                    color: "black", // Color value
                    dashStyle: "ShortDash", // Style of the plot line. Default to solid
                    value: ratedRpmIndex, // Value of where the line will appear
                    width: 2, // Width of the line
                    zIndex: 5,
                    label: {
                      text: "Rated Rpm", // Content of the label.
                      align: "left", // Positioning of the label.
                    },
                  },
                ]
              : [],
          // gridLineWidth:0,
          // lineWidth:2,
          // lineColor: '#000000',
          // labels: {
          //     enabled: false
          // }
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          backgroundColor: "#000",
          style: {
            color: "#FFFFFF",
            font: "12px Open Sans",
          },
          // formatter: function () {
          //   if (fromVpUp) {
          //     let points = this.points.map(function (point) {
          //       if (point.series.index === 0) {
          //         return point.series.name + ": " + point.y;
          //       } else {
          //         return point.y;
          //       }
          //     });
          //     return (
          //       "<b>" +
          //       this.x +
          //       "</b><br/>" +
          //       points[0] +
          //       "(" +
          //       points[2] +
          //       "," +
          //       points[1] +
          //       ")" +
          //       "</b><br/>" +
          //       "Iqr : " +
          //       this.points[0].point.options.iqr +
          //       "</b><br/>" +
          //       "Standard Deviation : " +
          //       this.points[0].point.options.standardDeviation +
          //       "</b><br/>" +
          //       "Range : " +
          //       this.points[0].point.options.range +
          //       "</b><br/>" +
          //       "Variance : " +
          //       this.points[0].point.options.variance
          //     );
          //   } else {
          //     return this.series.name + ": " + this.y;
          //   }
          // },
          // shared: fromVpUp,

          formatter: function () {
            if (fromVpUp) {
              if (Object.keys(this.point.options).length > 1) {
                return (
                  "<b>" +
                  this.x +
                  "</b><br/>" +
                  this.series.name +
                  " : " +
                  this.y +
                  " (" +
                  this.point.options.minValue +
                  "," +
                  this.point.options.maxValue +
                  ")" +
                  "</b><br/>" +
                  "Iqr : " +
                  this.point.options.iqr +
                  "</b><br/>" +
                  "Standard Deviation : " +
                  this.point.options.standardDeviation +
                  "</b><br/>" +
                  "Range : " +
                  this.point.options.range +
                  "</b><br/>" +
                  "Variance : " +
                  this.point.options.variance
                );
              } else {
                return (
                  "<b>" +
                  this.x +
                  "</b><br/>" +
                  this.series.name +
                  " : " +
                  this.y
                );
              }
            } else {
              return this.series.name + ": " + Math.ceil(this.y);
            }
          },
        },
        // plotOptions: {
        //     column: {
        //         borderWidth: 0,
        //         groupPadding: 0.1
        //     }
        // },
        series: fromVpUp ? chartData?.[0]?.data : chartData,
      },
      function (chart) {
        // on complete

        // if (chart.series?.[0]?.data?.length < 1) {
        //   // check series is empty
        //   chart.renderer
        //     .text("No Data Available", 290, 180)
        //     .css({
        //       color: "#000000",
        //       fontSize: "16px",
        //     })
        //     .add();
        // }

        const dataLen = chart.series?.[0]?.data?.length || 0;

        if (dataLen < 1) {
          chart.renderer
            .text("", 280, 180)
            .css({
              color: "#000000",
              fontSize: "16px",
            })
            .add();
          chart.renderer
            .text(290, 370)
            .css({
              color: "#000000",
              lineHeight: 1,
              textAlign: "right",
              font: "12px Open Sans",
              fontWeight: 600,
            })
            .add();
        }
      }
    );
  };

  return (
    <div class="figure-large-container">
      <div className="modal-close" onClick={clickOnClose}>
        <img src={close} alt="close" />
      </div>
      <figure className="highcharts-figure-large">
        <div className="figurre-content" id={id}></div>
      </figure>
    </div>
  );
};
