import { ReactComponent as SomethingWentWrongIcon } from "./assets/went-wrong.svg";

const Action = ({ label = "Go Back", ...rest }) => {
  return (
    <button
      size="small"
      variant="contained"
      color="primary"
      sx={{ mt: 2 }}
      {...rest}
    >
      {label}
    </button>
  );
};

const Feedback = ({
  title = "No records found",
  subtitle,
  children,
  action,
  illustration,
  illustrationHeight,
  feedbackClassName,
}) => {
  return (
    <div
      className="page-wrapper"
      style={{ marginTop: "30px", minHeight: "auto" }}
    >
      <div className={`apiError ${feedbackClassName}`}>
        {/* {illustration || <SomethingWentWrongIcon />} */}
        {/* <h2 style={{ "marginTop": "0px", "position": "relative", "bottom": "40px" }}>{title}</h2> */}
        <span>{title}</span>
        <h2>ERROR 404</h2>
        {subtitle && <p>{subtitle}</p>}
        {action && <Action {...action} />}
        {children && <div>{children}</div>}
      </div>
    </div>
  );
};

export default Feedback;
