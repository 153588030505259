import { useEffect } from "react";
import { useSelector } from "react-redux";
import Highcharts from "highcharts";
import arrowXAxis from "../../../assets/arrow-xaxis.svg";
import "./index.scss";

export const SingleColumn = (data) => {
  const { id, chartData, title, xLabel, yLabel, chartDetails, graphId } =
    data.data;
  const onClickHandler = data.onClickHandler;
  const wrapProperty = data.properties;
  const isSidemenuExpanded = useSelector(
    (state) => state.mainReducer.isSidemenuExpanded
  );

  useEffect(() => {
    renderChart(id, chartData, title, xLabel, yLabel);
  }, [chartData, isSidemenuExpanded]);

  const getyAxisTitle = (chartData, yLabel) => {
    return chartData?.length < 1
      ? {
          text: null,
        }
      : {
          text: `<div class="y-axis-legend"><div>${yLabel}</div><div class="y-axis-arrow"></div></div>`,
          style: {
            color: "#353536",
            lineHeight: 1,
            textAlign: "right",
            font: "10px Open Sans",
            letterSpacing: "1px",
          },
          align: "high",
          margin: 20,
          useHTML: true,
        };
  };

  const getxAxisTitle = (chartData, xLabel) => {
    return chartData?.length < 1
      ? {
          text: null,
        }
      : {
          text: `<div class="x-axis-legend"><div>${xLabel}</div><img src='${arrowXAxis}'/></div>`,
          style: {
            color: "#353536",
            lineHeight: 1,
            textAlign: "right",
            font: "10px Open Sans",
            letterSpacing: "1px",
          },
          align: "high",
          useHTML: true,
          margin: 20,
        };
  };

  const renderChart = (id, chartData, title, xLabel, yLabel) => {
    Highcharts.chart(
      id,
      {
        chart: {
          backgroundColor: "#FFFFFF",
          type: "column",
          spacing: [50, 30, 10, 5],
          height: 300,
          // borderColor: "#CECECE",
          borderRadius: 20,
          // borderWidth: 1,
        },
        title: {
          text: null,
        },
        yAxis: {
          title: getyAxisTitle(chartData, yLabel),
          labels: {
            style: {
              color: "#353536",
              lineHeight: 1,
              font: "10px Open Sans",
              letterSpacing: "1px",
            },
          },
          gridLineColor: "#F2F2F2",
        },
        xAxis: {
          //type: "category",
          categories: chartData?.map((number) => {
            return number.name;
          }),
          title: getxAxisTitle(chartData, xLabel),
          labels: {
            useHTML: true,
            style: wrapProperty
              ? {
                  color: "#353536",
                  lineHeight: 1,
                  whiteSpace: wrapProperty,
                  font: "10px Open Sans",
                  letterSpacing: "1px",
                }
              : {
                  color: "#353536",
                  lineHeight: 1,
                  font: "10px Open Sans",
                  letterSpacing: "1px",
                },
          },
        },
        plotOptions: {
          series: {
            pointWidth: 20,
          },
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          backgroundColor: "#000",
          style: {
            color: "#FFFFFF",
            font: "12px Open Sans",
          },
        },
        series: [
          {
            name: yLabel,
            data: chartData,
          },
        ],
      },
      function (chart) {
        const dataLen = chart.series?.[0]?.data?.length || 0;
        if (dataLen < 1) {
          // check series is empty
          chart.renderer
            .text("No Data Available", 50, 100)
            .css({
              color: "#000000",
              fontSize: "16px",
            })
            .add();
        }
      }
    );
  };

  return (
    <figure className="highcharts-figure" onClick={() => onClickHandler()}>
      <div className="chart-details-wrapper">
        {graphId == "milesDrivenOverSpeeding" ? (
          <>
            {/* <div className="legend-wrapper">
                        
                        <div className="green-legend">
                        </div>
                        
                    </div>  */}

            <div className="details-wrapper">
              <div className={`chart-details`}>
                <span className={`value`} style={{ color: "red" }}>
                  30
                </span>
                <span className="color-legend">
                  Miles driven while overspeeding
                </span>
              </div>
            </div>
          </>
        ) : (
          <>
            {graphId == "outOfLaneDriving" ? (
              <>
                <div className="legend-wrapper">
                  <div className="green-legend"></div>
                </div>

                <div style={{ display: "flex" }}>
                  {chartDetails?.map(
                    (el) =>
                      !el.value &&
                      el.color && (
                        <>
                          <div
                            className="legend-wrapper"
                            style={{ marginLeft: "15px" }}
                          >
                            <div className={`${el.color.toLowerCase()}-legend`}>
                              <span className={`color-box ${el.color}`}></span>
                              <span className="color-legend">{el.label}</span>
                            </div>
                          </div>
                        </>
                      )
                  )}
                </div>
              </>
            ) : (
              <>
                {chartDetails?.map(
                  (el) =>
                    !el.value &&
                    el.color && (
                      <>
                        <div className="legend-wrapper">
                          <div className={`${el.color.toLowerCase()}-legend`}>
                            <span
                              className={`color-box ${el.color.toLowerCase()}`}
                            ></span>
                            <span className="color-legend">{el.label}</span>
                          </div>
                        </div>
                      </>
                    )
                )}
                {chartDetails?.map(
                  (el) =>
                    ((el.value && el.color) || (!el.value && !el.color)) && (
                      <div className="details-wrapper">
                        <div className={`chart-details`}>
                          <span className={`value ${el.color.toLowerCase()}`}>
                            {el.value}
                          </span>
                          <span className="color-legend">{el.label}</span>
                        </div>
                      </div>
                    )
                )}
              </>
            )}
          </>
        )}
      </div>

      <div id={id}></div>
      <div className="graph-name">{title}</div>
    </figure>
  );
};
