import { useEffect } from "react";
import { useSelector } from "react-redux";
import Highcharts from "highcharts";
import arrowXAxis from "../../../assets/arrow-xaxis.svg";
import "./index.scss";

export const SingleLine = (data) => {
  const { id, chartdata, title, chartDetails, xLabel, yLabel } = data.data;
  const onClickHandler = data.onClickHandler;
  const isSidemenuExpanded = useSelector(
    (state) => state.mainReducer.isSidemenuExpanded
  );

  useEffect(() => {
    renderChart(id, chartdata, title, xLabel, yLabel);
  }, [chartdata, isSidemenuExpanded]);

  const getyAxisTitle = (chartData, yLabel) => {
    return chartData?.length < 1
      ? {
          text: null,
        }
      : {
          text: `<div class="y-axis-legend"><div>${yLabel}</div><div class="y-axis-arrow"></div></div>`,
          style: {
            color: "#353536",
            lineHeight: 1,
            textAlign: "right",
            font: "9.5px Open Sans",
            letterSpacing: "1px",
          },
          align: "high",
          useHTML: true,
          margin: 20,
        };
  };

  const getxAxisTitle = (chartData, xLabel) => {
    return chartData?.length < 1
      ? {
          text: null,
        }
      : {
          text: `<div class="x-axis-legend"><div>${xLabel}</div><img src='${arrowXAxis}'/></div>`,
          style: {
            color: "#353536",
            lineHeight: 1,
            textAlign: "right",
            font: "10px Open Sans",
            letterSpacing: "1px",
          },
          align: "high",
          useHTML: true,
          margin: 20,
        };
  };

  const renderChart = (id, chartdata, title, xLabel, yLabel) => {
    Highcharts.chart(
      id,
      {
        chart: {
          backgroundColor: "#FFFFFF",
          spacing: [50, 30, 10, 5],
          height: 350,
          borderRadius: 20,
          // borderColor: "#cecece",
          // borderWidth: 1,
        },
        title: {
          text: null,
        },

        yAxis: {
          title: getyAxisTitle(chartdata, yLabel),
          labels: {
            style: {
              color: "#353536",
              lineHeight: 1,
              font: "10px Open Sans",
              letterSpacing: "1px",
            },
          },
          gridLineColor: "#F2F2F2",
        },
        xAxis: {
          type: "category",
          title: getxAxisTitle(chartdata, xLabel),
          labels: {
            style: {
              color: "#353536",
              lineHeight: 1,
              font: "10px Open Sans",
              letterSpacing: "1px",
            },
          },
          tickPositions: chartdata[0].map((item) => {
            return item.x;
          }),
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          series: {
            label: {
              connectorAllowed: true,
            },
          },
        },
        tooltip: {
          backgroundColor: "#000",
          style: {
            color: "#FFFFFF",
            font: "12px Open Sans",
          },
        },

        series: [
          {
            name: yLabel,
            data: chartdata[0],
          },
        ],

        colors: [chartDetails[0].color],
      },
      function (chart) {
        // on complete
        const dataLen = chart.series?.[0]?.data?.length || 0;
        if (dataLen < 1) {
          // check series is empty
          chart.renderer
            .text("No Data Available", 50, 125)
            .css({
              color: "#000000",
              fontSize: "16px",
            })
            .add();
        }
      }
    );
  };

  return (
    <figure className="highcharts-figure" onClick={() => onClickHandler()}>
      <div id={id}></div>
      <div className="graph-name">{title}</div>
    </figure>
  );
};

export const DualLine = (data) => {
  const {
    id,
    dataAxis1,
    dataAxis2,
    title,
    xLabel,
    yLabel,
    yLabel2,
    chartDetails,
  } = data.data;
  const onClickHandler = data.onClickHandler;
  const isSidemenuExpanded = useSelector(
    (state) => state.mainReducer.isSidemenuExpanded
  );

  useEffect(() => {
    renderChart(id, dataAxis1, title, xLabel, yLabel);
  }, [dataAxis1, isSidemenuExpanded]);

  const getyAxisTitle = (dataAxis1, yLabel) => {
    return dataAxis1?.length < 1
      ? {
          text: null,
        }
      : {
          text: `<div class="y-axis-legend"><div>${yLabel}</div><div class="y-axis-arrow"></div></div>`,
          style: {
            color: "#353536",
            lineHeight: 1,
            textAlign: "right",
            font: "9.5px Open Sans",
            letterSpacing: "1px",
          },
          align: "high",
          useHTML: true,
          margin: 20,
        };
  };

  const getxAxisTitle = (dataAxis1, xLabel) => {
    return dataAxis1?.length < 1
      ? {
          text: null,
        }
      : {
          text: `<div class="x-axis-legend"><div>${xLabel}</div><img src='${arrowXAxis}'/></div>`,
          style: {
            color: "#353536",
            lineHeight: 1,
            textAlign: "right",
            font: "10px Open Sans",
            letterSpacing: "1px",
          },
          align: "high",
          useHTML: true,
          margin: 20,
        };
  };

  const renderChart = (id, dataAxis1, title, xLabel, yLabel) => {
    Highcharts.chart(
      id,
      {
        chart: {
          backgroundColor: "#FFFFFF",
          spacing: [50, 30, 10, 5],
          height: 350,
          borderRadius: 20,
          // borderColor: "#cecece",
          // borderWidth: 1,
        },
        title: {
          text: null,
        },

        yAxis: {
          title: getyAxisTitle(dataAxis1, yLabel),

          labels: {
            style: {
              color: "#353536",
              lineHeight: 1,
              font: "10px Open Sans",
              letterSpacing: "1px",
            },
          },
          gridLineColor: "#F2F2F2",
        },
        xAxis: {
          type: "category",
          title: getxAxisTitle(dataAxis1, xLabel),
          labels: {
            style: {
              color: "#353536",
              lineHeight: 1,
              font: "10px Open Sans",
              letterSpacing: "1px",
            },
          },
          tickPositions: dataAxis1.map((item) => {
            return item.x;
          }),
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          series: {
            label: {
              connectorAllowed: true,
            },
          },
        },
        tooltip: {
          backgroundColor: "#000",
          style: {
            color: "#FFFFFF",
            font: "12px Open Sans",
          },
        },

        series: [
          {
            name: yLabel,
            data: dataAxis1,
            showInLegend: false,
            marker: false,
          },
          {
            name: yLabel,
            data: dataAxis2,
            showInLegend: false,
            marker: false,
          },
        ],

        colors: [chartDetails[0].color, chartDetails[1].color],
      },
      function (chart) {
        const dataLen = chart.series?.[0]?.data?.length || 0;
        if (dataLen < 1) {
          chart.renderer
            .text("No Data Available", 50, 125)
            .css({
              color: "#000000",
              fontSize: "16px",
            })
            .add();
        }
      }
    );
  };

  return (
    <figure className="highcharts-figure" onClick={() => onClickHandler()}>
      <div id={id}></div>
      <div className="graph-name ">{title}</div>
    </figure>
  );
};

export const DotLine = (data) => {
  const { id, dataAxis1, dataAxis2, title, chartDetails, xLabel, yLabel } =
    data.data;
  const onClickHandler = data.onClickHandler;
  const isSidemenuExpanded = useSelector(
    (state) => state.mainReducer.isSidemenuExpanded
  );

  useEffect(() => {
    renderChart(id, dataAxis1, dataAxis2, title, xLabel, yLabel);
  }, [dataAxis1, isSidemenuExpanded]);

  const getyAxisTitle = (dataAxis1, yLabel) => {
    return dataAxis1?.length < 1
      ? {
          text: null,
        }
      : {
          text: `<div class="y-axis-legend"><div>${yLabel}</div><div class="y-axis-arrow"></div></div>`,
          style: {
            color: "#353536",
            lineHeight: 1,
            textAlign: "right",
            font: "9.5px Open Sans",
            letterSpacing: "1px",
          },
          align: "high",
          useHTML: true,
          margin: 20,
        };
  };

  const getxAxisTitle = (dataAxis1, xLabel) => {
    return dataAxis1?.length < 1
      ? {
          text: null,
        }
      : {
          text: `<div class="x-axis-legend"><div>${xLabel}</div><img src='${arrowXAxis}'/></div>`,
          style: {
            color: "#353536",
            lineHeight: 1,
            textAlign: "right",
            font: "10px Open Sans",
            letterSpacing: "1px",
          },
          align: "high",
          useHTML: true,
          margin: 20,
        };
  };

  const renderChart = (id, dataAxis1, dataAxis2, title, xLabel, yLabel) => {
    Highcharts.chart(
      id,
      {
        chart: {
          backgroundColor: "#FFFFFF",
          spacing: [50, 30, 10, 5],
          height: 350,
          type: "scatter",
          zoomType: "yx",
          borderRadius: 20,
          // borderColor: "#cecece",
          // borderWidth: 1,
        },
        title: {
          text: null,
        },

        yAxis: {
          title: getyAxisTitle(dataAxis1, yLabel),
          labels: {
            style: {
              color: "#353536",
              lineHeight: 1,
              font: "10px Open Sans",
              letterSpacing: "1px",
            },
          },
          gridLineColor: "#F2F2F2",
        },
        xAxis: {
          type: "category",
          title: getxAxisTitle(dataAxis1, xLabel),
          labels: {
            style: {
              color: "#353536",
              lineHeight: 1,
              font: "10px Open Sans",
              letterSpacing: "1px",
            },
          },
          tickPositions: dataAxis1.map((item) => {
            return item.x;
          }),
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          series: {
            label: {
              connectorAllowed: true,
            },
          },
        },
        tooltip: {
          backgroundColor: "#000",
          style: {
            color: "#FFFFFF",
            font: "12px Open Sans",
          },
          headerFormat: "<div>{point.x}</div>",
          pointFormat: `</br><div>${yLabel}:<b>{point.y}</b></div>`,
        },

        series: [
          {
            name: yLabel,
            data: dataAxis1,
            marker: {
              symbol: "circle",
            },
          },
          {
            name: yLabel,
            data: dataAxis2,
            marker: {
              symbol: "circle",
            },
          },
        ],

        colors: [chartDetails[0].color, chartDetails[1].color],
      },
      function (chart) {
        // on complete
        const data1Len = chart.series?.[0]?.data?.length || 0;
        const data2Len = chart.series?.[1]?.data?.length || 0;
        if (data1Len < 1 && data2Len < 1) {
          // check series is empty
          chart.renderer
            .text("No Data Available", 50, 125)
            .css({
              color: "#000000",
              fontSize: "16px",
            })
            .add();
        }
      }
    );
  };

  return (
    <figure className="highcharts-figure" onClick={() => onClickHandler()}>
      <div id={id}></div>
      <div className="graph-name">{title}</div>
    </figure>
  );
};

export const SingleColumn = (data) => {
  const { id, title, chartDetails, dataAxis1, xLabel, yLabel } = data.data;
  const onClickHandler = data.onClickHandler;
  const isSidemenuExpanded = useSelector(
    (state) => state.mainReducer.isSidemenuExpanded
  );

  useEffect(() => {
    renderChart(id, dataAxis1, title, xLabel, yLabel);
  }, [dataAxis1, isSidemenuExpanded]);

  const getyAxisTitle = (dataAxis1, yLabel) => {
    return dataAxis1?.length < 1
      ? {
          text: null,
        }
      : {
          text: `<div class="y-axis-legend"><div>${yLabel}</div><div class="y-axis-arrow"></div></div>`,
          style: {
            color: "#353536",
            lineHeight: 1,
            textAlign: "right",
            font: "9px Open Sans",
            letterSpacing: "1px",
          },
          align: "high",
          useHTML: true,
          margin: 20,
        };
  };

  const getxAxisTitle = (dataAxis1, xLabel) => {
    return dataAxis1?.length < 1
      ? {
          text: null,
        }
      : {
          text: `<div class="x-axis-legend"><div>${xLabel}</div><img src='${arrowXAxis}'/></div>`,
          style: {
            color: "#353536",
            lineHeight: 1,
            textAlign: "right",
            font: "10px Open Sans",
            letterSpacing: "1px",
          },
          align: "high",
          useHTML: true,
          margin: 20,
        };
  };

  const renderChart = (id, dataAxis1, title, xLabel, yLabel) => {
    Highcharts.chart(
      id,
      {
        chart: {
          backgroundColor: "#FFFFFF",
          spacing: [50, 30, 10, 5],
          height: 350,
          type: "column",
          borderRadius: 20,
          // borderColor: "#cecece",
          // borderWidth: 1,
        },
        title: {
          text: null,
        },

        yAxis: {
          title: getyAxisTitle(dataAxis1, yLabel),
          labels: {
            style: {
              color: "#353536",
              lineHeight: 1,
              font: "10px Open Sans",
              letterSpacing: "1px",
            },
          },
          gridLineColor: "#F2F2F2",
        },
        xAxis: {
          type: "category",
          title: getxAxisTitle(dataAxis1, xLabel),
          labels: {
            style: {
              color: "#353536",
              lineHeight: 1,
              font: "10px Open Sans",
              letterSpacing: "1px",
            },
          },
          //         tickPositions: [0,20,40,60,80,100,120,140,160],
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          series: {
            label: {
              connectorAllowed: true,
            },
          },
        },
        tooltip: {
          style: {
            color: "#353536",
            font: "12px Open Sans",
          },
        },

        series: [
          {
            name: yLabel,
            data: dataAxis1,
            pointWidth: 20,
          },
        ],
      },
      function (chart) {
        // on complete
        const dataLen = chart.series?.[0]?.data?.length || 0;
        if (dataLen < 1) {
          // check series is empty
          chart.renderer
            .text("No Data Available", 50, 125)
            .css({
              color: "#000000",
              fontSize: "16px",
            })
            .add();
        }
      }
    );
  };

  return (
    <figure className="highcharts-figure" onClick={() => onClickHandler()}>
      <div id={id}></div>
      <div className="graph-name">{title}</div>
    </figure>
  );
};

export const Line = (data) => {
  const {
    id,
    title,
    chartDetails,
    dataAxis1,
    dataAxis2,
    dataAxis3,
    xLabel,
    yLabel,
  } = data.data;
  const onClickHandler = data.onClickHandler;
  const isSidemenuExpanded = useSelector(
    (state) => state.mainReducer.isSidemenuExpanded
  );

  useEffect(() => {
    renderChart(id, dataAxis1, dataAxis2, dataAxis3, title, xLabel, yLabel);
  }, [dataAxis1, isSidemenuExpanded]);

  const getyAxisTitle = (dataAxis1, yLabel) => {
    return dataAxis1?.length < 1
      ? {
          text: null,
        }
      : {
          text: `<div class="y-axis-legend"><div>${yLabel}</div><div class="y-axis-arrow"></div></div>`,
          style: {
            color: "#353536",
            lineHeight: 1,
            textAlign: "right",
            font: "10px Open Sans",
            letterSpacing: "1px",
          },
          align: "high",
          useHTML: true,
          margin: 20,
        };
  };

  const getxAxisTitle = (dataAxis1, xLabel) => {
    return dataAxis1?.length < 1
      ? {
          text: null,
        }
      : {
          text: `<div class="x-axis-legend"><div>${xLabel}</div><img src='${arrowXAxis}'/></div>`,
          style: {
            color: "#353536",
            lineHeight: 1,
            textAlign: "right",
            font: "10px Open Sans",
            letterSpacing: "1px",
          },
          align: "high",
          useHTML: true,
          margin: 20,
        };
  };

  const renderChart = (
    id,
    dataAxis1,
    dataAxis2,
    dataAxis3,
    title,
    xLabel,
    yLabel
  ) => {
    Highcharts.chart(
      id,
      {
        chart: {
          backgroundColor: "#FFFFFF",
          spacing: [50, 30, 10, 5],
          height: 350,
          borderRadius: 20,
          // borderColor: "#cecece",
          // borderWidth: 1,
        },
        title: {
          text: null,
        },

        yAxis: {
          title: getyAxisTitle(dataAxis1, yLabel),
          labels: {
            style: {
              color: "#353536",
              lineHeight: 1,
              font: "10px Open Sans",
              letterSpacing: "1px",
            },
          },
          gridLineColor: "#F2F2F2",
        },
        xAxis: {
          type: "category",
          title: getxAxisTitle(dataAxis1, xLabel),
          labels: {
            style: {
              color: "#353536",
              lineHeight: 1,
              font: "10px Open Sans",
              letterSpacing: "1px",
            },
          },
          // tickPositions: [0,20,40,60,80,100,120,140,160],
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          series: {
            label: {
              connectorAllowed: true,
            },
          },
        },
        tooltip: {
          backgroundColor: "#000",
          style: {
            color: "#FFFFFF",
            font: "12px Open Sans",
          },
        },

        series: [
          {
            name: yLabel,
            data: dataAxis1,
            showInLegend: false,
            marker: false,
          },
          {
            name: yLabel,
            data: dataAxis2,
            step: "center",
            showInLegend: false,
            marker: false,
          },
          {
            name: yLabel,
            data: dataAxis3,
            step: "center",
            showInLegend: false,
            marker: false,
          },
        ],

        colors: [
          chartDetails[1].color,
          chartDetails[2].color,
          chartDetails[0].color,
        ],
      },
      function (chart) {
        // on complete
        const dataLen = chart.series?.[0]?.data?.length || 0;
        if (dataLen < 1) {
          // check series is empty
          chart.renderer
            .text("No Data Available", 50, 125)
            .css({
              color: "#000000",
              fontSize: "16px",
            })
            .add();
        }
      }
    );
  };

  return (
    <figure className="highcharts-figure" onClick={() => onClickHandler()}>
      <div className="graph-info-container">
        <div className="graph-inner">
          <div className="inner">
            <div className="box" style={{ backgroundColor: "#FF7E06" }}></div>
            <p style={{ fontSize: "10px" }}>Good Signal</p>
          </div>
          <div className="inner">
            <div className="box" style={{ backgroundColor: "#8A94A7" }}></div>
            <p style={{ fontSize: "10px" }}>Failure Signal</p>
          </div>
          <div className="inner">
            <div className="box" style={{ backgroundColor: "#0954E5" }}></div>
            <p style={{ fontSize: "10px" }}>ACTLS</p>
          </div>
        </div>
      </div>
      <div id={id}></div>
      <div className="graph-name">{title}</div>
    </figure>
  );
};
