import React, { useEffect } from "react";
import { NavLink, Outlet, useNavigate, useLocation } from "react-router-dom";
import "./digitalTwin.scss";

const DigitalTwin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/vehicle-health/digital-twin") {
      navigate("/vehicle-health/digital-twin/performance-digital-twin", {
        replace: true,
      });
    }
  }, [location.pathname]);
  return (
    <div className="full-width digital-twin-container">
      <div className="sec-tabs-container">
        <NavLink
          to="/vehicle-health/digital-twin/performance-digital-twin"
          className={({ isActive }) =>
            isActive ? "btn-primary-sec-tabs" : "btn-secondary-sec-tabs"
          }
        >
          Performance Digital Twin
        </NavLink>
        <NavLink
          to="/vehicle-health/digital-twin/component-sub-system"
          className={({ isActive }) =>
            isActive ? "btn-primary-sec-tabs" : "btn-secondary-sec-tabs"
          }
        >
          Component Sub System
        </NavLink>
      </div>
      <Outlet />
    </div>
  );
};

export default DigitalTwin;
