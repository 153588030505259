import "../index.scss";
import Select from "../../../components/common/Select/Select";
import { Checkbox } from "../../../components/common/Checkbox/Checkbox";
import Filter from "../../../components/Filter/Filter";
import React, { forwardRef, useEffect, useState } from "react";
import Daterangepicker from "../../../components/Datepicker/daterangepicker";
import {
  digitalTwinEndpoints,
  ServiceConfig,
} from "../../../services/service-constants";
import moment from "moment";
import { mainAxiosInstance } from "../../../services/service";
import { LineChart } from "../../common/LineGraph";

import metricPreIcon from "../../../assets/metric.png";
import intervalPreIcon from "../../../assets/interval.png";
import timeIcon from "../../../assets/datepicker.png";
import navLeft from "../../../assets/left-arrow-square.svg";
import navRight from "../../../assets/right-arrow-square.svg";
import close from "../../../assets/close.svg";
import { useSelector } from "react-redux";
import Feedback from "../../../components/common/ErrorComponents/Feedback";
import NotAuthorized from "../../../components/common/ErrorComponents/NotAuthorized";
import ChartLoader from "../../../components/common/ErrorComponents/ChartLoader";
import clockIcon from "../../../../src/assets/clockIcon.svg";
import boxIcon from "../../../../src/assets/boxIcon.svg";

const PerformanceDigitalTwin = () => {
  const [selectAll, setSelectAll] = useState(true);
  const intervalTypes = {
    trips: "by_trip",
    miles: "by_miles",
    date: "by_date",
  };
  const metrics = ["Miles", "Hours", "Engine Run Time", "Key starts"];
  const interval = ["Lifetime", "Last Trips", "Last Miles", "Date"];
  const [defaultMetric, setDefaultMetric] = useState("Miles");
  const [defaultInterval, setDefaultInterval] = useState("Lifetime");
  const [chartData, setChartData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedChart, setSelectedChart] = useState(null);
  const [showFilter, setShowFilter] = useState(true);
  const filterkey = [];
  const vin_Number = useSelector((state) => state.mainReducer.vin_Number);
  const filterOptions = [
    {
      displayname: "Time To Start Engine",
      id: "timeToStartEngine",
      isChecked: true,
    },
    {
      displayname: "Oil Change intervals",
      id: "oilChangeInterval",
      isChecked: true,
    },
    {
      displayname: "Distance between particulate filter regeneration (miles)",
      id: "timeBetweenParticularFilterRegen",
      isChecked: true,
    },
    {
      displayname: "Average Fuel Consumption",
      id: "fuelConsumptionGalPer100Miles",
      isChecked: true,
    },
    {
      displayname: "Engine Oil Consumption",
      id: "engineFuelConsumption",
      isChecked: true,
    },
    {
      displayname: "Average Electric Vehicle Battery Consumption",
      id: "batteryEnergyConsumption",
      isChecked: true,
    },
    {
      displayname: "Key Off-Battery Drain",
      id: "batteryEnergyConsumptionAtKeyOff",
      isChecked: true,
    },
    {
      displayname: "Urea Consumption",
      id: "ureaDieselConsumption",
      isChecked: true,
    },
  ];
  const [filterData, setFilterData] = useState([...filterOptions]);
  const [inputData, setInputData] = useState(1000);
  const [showInput, setShowInput] = useState(false);
  const [showDatepicker, setShowDatepicker] = useState(false);

  const [selectedStartDate, setStartDate] = useState(new Date());
  const [selectedEndDate, setEndDate] = useState(new Date());
  const [noDataFound, setNoDataFound] = useState(false);
  const [MatricType, setMatricType] = useState("by_miles");

  const [thresholdEndYValue, setThresholdEndYValue] = useState(null);
  const [thresholdStartXvalue, setThresholdStartXvalue] = useState(null);
  const [thresholdEndXValue, setThresholdEndXValue] = useState(null);
  const [dispRUL, setDispRUL] = useState(false);
  const [errorMsg, setErrorMsg] = useState(true);
  const [authenticationMsg, setAuthenticationMsg] = useState(false);

  const setSelectAllvalue = (data) => {
    const checkAllSelected = data.filter((el) => el.isChecked === false);
    if (checkAllSelected.length > 0) {
      setSelectAll(false);
    } else {
      setSelectAll(true);
    }
  };

  const handleFilterClick = (val) => {
    const newFilterData = filterData.map((el) => {
      if (el.displayname === val.displayname) {
        el.isChecked = !el.isChecked;
      }
      return el;
    });
    setFilterData([...newFilterData]);
    setSelectAllvalue(newFilterData);
  };

  const handleMetricClick = (val) => {
    setDefaultMetric(val);
    const metrics = ["Miles", "Hours", "Engine Run Time", "Key starts"];
    if (val === "Miles") {
      setMatricType("by_miles");
    }
    if (val === "Hours") {
      setMatricType("by_hours");
    }
    if (val === "Engine Run Time") {
      setMatricType("by_engine_run_times");
    }
    if (val === "Key starts") {
      setMatricType("by_key_starts");
    }
  };

  const handleIntervalOptionClick = (val) => {
    setDefaultInterval(val);
    switch (val) {
      case "Lifetime":
        setShowInput(false);
        setShowDatepicker(false);
        break;
      case "Last Trips":
        setShowInput(true);
        setShowDatepicker(false);
        setInputData(1);
        break;
      case "Last Miles":
        setShowInput(true);
        setShowDatepicker(false);
        setInputData(1000);
        break;
      case "Date":
        setShowInput(false);
        setShowDatepicker(true);
        break;
      default:
        setShowInput(true);
        setShowDatepicker(false);
        setInputData(1000);
        break;
    }
  };

  const DatepickerCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div
      className="btn btn-secondary date-selector"
      onClick={onClick}
      ref={ref}
    >
      <img className="time-icon" src={timeIcon} alt="datePicker" />
      {value}
    </div>
  ));

  const handleOnDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const valueMap = {
    batteryEnergyConsumption: "batteryEnergyConsumptionKwhPer100Miles",
    batteryEnergyConsumptionAtKeyOff: "batteryEnergyConsumptionWH",
    engineFuelConsumption: "averageFuelConsumption",
    fuelConsumptionGalPer100Miles: "galPer100Miles",
    oilChangeInterval: "oilChangeInterval",
    timeBetweenParticularFilterRegen: "timeBetweenParticularFilterRegenMin",
    timeToStartEngine: "timeToStartEngine",
    ureaDieselConsumption: "timeToStartTheEngineSeconds",
  };

  const getOutputData = (data) => {
    const milesData = [];

    const isNoDataAvailable = Object.values(data)
      .map((value) => {
        return value.vehicleHealthCheckDigitalTwinGraphsThresholds;
      })
      .every((val) => !val);
    if (isNoDataAvailable) {
      setNoDataFound(true);
    } else {
      setNoDataFound(false);
      Object.keys(data).map((val) => {
        const detailsData = [];
        const thresholdArray = [];
        let xAxisLabel = "";
        let yAxisLabel = "";
        const RulValue = [];
        data[val][val].forEach((value) => {
          xAxisLabel =
            data[val].vehicleHealthCheckDigitalTwinGraphsThresholds.xAxisName;
          yAxisLabel =
            data[val].vehicleHealthCheckDigitalTwinGraphsThresholds.yAxisName;
          detailsData.push({ x: value.xAxisData, y: value.yAxisData });
        });
        RulValue.push(
          data[val].vehicleHealthCheckDigitalTwinGraphsThresholds.rulValue
        );
        const thresholdData =
          data[val].vehicleHealthCheckDigitalTwinGraphsThresholds;

        thresholdArray.push(
          {
            x: thresholdData.thresholdStart[0],
            y: thresholdData.thresholdStart[1],
          },
          { x: thresholdData.thresholdEnd[0], y: thresholdData.thresholdEnd[1] }
        );
        milesData.push({
          type: val,
          data: detailsData,
          thresholdData: thresholdArray,
          RulValue: RulValue,
          xLabel: xAxisLabel,
          yLabel: yAxisLabel,
        });
      });
    }
    return milesData;
  };

  const getChartData = async (response) => {
    const data = [];
    const metricSelected = [defaultMetric];
    metricSelected.forEach(() => {
      const sectionData = {};
      sectionData["data"] = [];
      sectionData["name"] = "Miles";
      sectionData["data"] = getOutputData(response);
      data.push(sectionData);
    });

    const mainData = [];
    data.forEach((value) => {
      value.data.forEach((val) => {
        val.name = value.name;
      });
    });

    Object.keys(valueMap).map((key) => {
      data.forEach((val) => {
        const requiredData = val.data.filter((el) => el.type === key);
        if (requiredData?.length) {
          delete requiredData?.[0]?.type;
        }
        const index = mainData.findIndex((element) => {
          if (element?.name === key) {
            return true;
          }
          return false;
        });
        if (index === -1) {
          mainData.push({ name: key, data: requiredData });
        } else {
          mainData.map((el) => {
            if (el.name === key) {
              el.data = [...el.data, ...requiredData];
            }
          });
        }
      });
    });
    setChartData(mainData);
  };

  const prepareChartData = (dataSet, replaceText) => {
    let aggregatedData = {};
    Object.keys(dataSet).map((val) => {
      const name = val.replace(replaceText, "");
      const obj = {};
      Object.keys(dataSet[val]).map((value) => {
        const internalName = value.replace(replaceText, "");
        obj[internalName] = dataSet[val][value];
      });
      aggregatedData[name] = obj;
    });
    getChartData(aggregatedData);
  };

  const getPageData = (val) => {
    const url = `${ServiceConfig.vehicle_health_check}digital_twin_all_graphs`;
    let params = {};
    let replaceText = "Dto";
    switch (defaultInterval) {
      case "Last Miles": {
        params = {
          type: intervalTypes.miles,
          vinNumber: val.vin_Number,
          organizationId: 1,
          lastMiles: inputData === "0" ? -1 : inputData,
          remainingUseFulLife: MatricType,
        };
        break;
      }
      case "Last Trips": {
        params = {
          type: intervalTypes.trips,
          vinNumber: val.vin_Number,
          organizationId: 1,
          lastTrips: inputData,
          remainingUseFulLife: MatricType,
        };
        break;
      }
      case "Date": {
        params = {
          type: intervalTypes.date,
          vinNumber: val.vin_Number,
          organizationId: 1,
          fromDate: moment(selectedStartDate).format("yyyy-MM-DD"),
          toDate: moment(selectedEndDate).format("yyyy-MM-DD"),
          remainingUseFulLife: MatricType,
        };
        break;
      }
      case "Lifetime": {
        params = {
          type: intervalTypes.date,
          vinNumber: val.vin_Number,
          organizationId: 1,
          remainingUseFulLife: MatricType,
        };
        break;
      }
      default: {
        params = {
          type: intervalTypes.miles,
          vinNumber: val.vin_Number,
          organizationId: 1,
          lastMiles: inputData === "0" ? -1 : inputData,
          remainingUseFulLife: MatricType,
        };
      }
    }

    mainAxiosInstance
      .post(url, { ...params })
      .then((response) => {
        if (response.status === 200) {
          setNoDataFound(false);
          prepareChartData(response.data, replaceText);
        }
        if (response.status === 401) {
          setErrorMsg(response.data?.details || "You are not authorized.");
          setAuthenticationMsg(true);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setAuthenticationMsg(true);
          setErrorMsg(err.response.data?.details || "You are not authorized.");
        } else {
          setNoDataFound(true);
          setErrorMsg(false);
        }
      });
  };

  const handleChartClick = (el) => {
    setShowModal(true);
    setSelectedChart(el);
    setDispRUL(true);
    setShowFilter(false);
  };

  const getDataForBarChart = (element) => {
    const barChartData = chartData.filter((el) => el.name === element.id);
    const returnData = [];
    barChartData?.forEach((val) => {
      val.data.forEach((value) => {
        const dataToPlot = [...value.data, ...value.thresholdData];

        if (dispRUL) {
          returnData.push(
            {
              name: value.yLabel,
              data: dataToPlot,
              dashStyle: "Solid",
              color: "#0574A6",
              zoneAxis: "x",
              zones: [
                { value: value.thresholdData[0].x },
                { dashStyle: "dot" },
              ],
              RulValue: value.RulValue,
            },
            {
              name: "Threshold",
              data: value.thresholdData,
              dashStyle: "ShortDash",
              color: "#F23732",
            }
          );
        } else {
          returnData.push(
            {
              name: value.yLabel,
              data: dataToPlot,
              dashStyle: "Solid",
              color: "#0574A6",
              zoneAxis: "x",
              zones: [
                { value: value.thresholdData[0].x },
                { dashStyle: "dot" },
              ],
            },
            {
              name: "Threshold",
              data: value.thresholdData,
              dashStyle: "ShortDash",
              color: "#F23732",
            }
          );
        }
      });
    });

    return returnData;
  };

  const getLabelsForChart = (element) => {
    const barChartData = chartData.filter((el) => el.name === element.id);
    filterkey.push(barChartData[0]?.name);
    let returnData = "";
    barChartData?.forEach((val) => {
      val.data.forEach((value) => {
        returnData = value.xLabel;
      });
    });

    return returnData;
  };

  const showPrev = (el) => {
    const data = filterData.filter((el) => el.isChecked);
    if (data.length > 0) {
      const index = data.findIndex((elem) => elem.id === el.id);
      let selectElement = el;
      if (index !== 0) {
        selectElement = data[index - 1];
      } else if (index === 0) {
        selectElement = data[data.length - 1];
      }
      setNoDataFound(false);
      setSelectedChart(selectElement);
    } else {
      setNoDataFound(true);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const showNext = (el) => {
    const data = filterData.filter((el) => el.isChecked);
    if (data.length > 0) {
      const index = data.findIndex((elem) => elem.id === el.id);
      let selectElement = el;
      if (index !== data.length - 1) {
        selectElement = data[index + 1];
      } else if (index === data.length - 1) {
        selectElement = data[0];
      }
      setNoDataFound(false);
      setSelectedChart(selectElement);
    } else {
      setNoDataFound(true);
    }
  };

  const clickOnClose = (e) => {
    e.stopPropagation();
    setShowModal(false);
    setDispRUL(false);
    setShowFilter(true);
  };

  useEffect(() => {
    setChartData([]);
    getPageData({ vin_Number: vin_Number });
  }, [
    defaultMetric,
    defaultInterval,
    inputData,
    selectedStartDate,
    selectedEndDate,
  ]);

  const AllSelect = (val) => {
    const getUpdatedfilterData = filterData.map((el) => {
      return { ...el, isChecked: val };
    });

    setFilterData(getUpdatedfilterData);
    setSelectAll(val);
  };

  return (
    <div className="page-wrapper " style={{ background: "#EEEEEE" }}>
      <div className="flex page-content-header digital-twin">
        <div className="page-options-container">
          <div className="remaining-useful-life-filter">
            <Select
              heading="Remaining useful life"
              headingIcon={clockIcon}
              options={metrics}
              defaultSelected={defaultMetric}
              handleOptionClick={handleMetricClick}
              // preIcon={metricPreIcon}
            />
          </div>
          <Select
            heading="Data Interval"
            headingIcon={boxIcon}
            options={interval}
            defaultSelected={defaultInterval}
            handleOptionClick={handleIntervalOptionClick}
            // preIcon={intervalPreIcon}
          />
          {showInput && (
            <input
              type="text"
              className="value-input"
              placeholder={`Please enter ${defaultInterval}`}
              value={inputData}
              onChange={(e) => {
                setInputData(e.target.value);
              }}
              maxLength={10}
            />
          )}
          {showDatepicker && (
            <Daterangepicker
              customInput={<DatepickerCustomInput />}
              startDate={selectedStartDate}
              endDate={selectedEndDate}
              handleOnChange={handleOnDateChange}
            />
          )}
        </div>

        {showFilter === true && (
          <div className="page-filter performance-digital-twin">
            <Filter data={AllSelect} selectAll={selectAll}>
              {filterData.map((el) => (
                <Checkbox
                  title={el.displayname}
                  handleInputClick={() => handleFilterClick(el)}
                  alignProp={{
                    borderTop: "0.5px solid #C4C4C4",
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "10px",
                    alignItems: "center",
                    flexDirection: "row-reverse",
                  }}
                  checkboxStyle={{ marginRight: "10px" }}
                  isChecked={el.isChecked}
                />
              ))}
            </Filter>
          </div>
        )}
      </div>

      <div className="page-chart-container digital-twin-charts">
        {!noDataFound &&
          filterData.map(
            (el) =>
              el.isChecked &&
              !showModal &&
              chartData.length > 0 && (
                <LineChart
                  id={el.id}
                  data={getDataForBarChart(el)}
                  title={el.displayname}
                  xLabel={getLabelsForChart(el)}
                  plotXLines={false}
                  plotYLines={true}
                  dispRUL={dispRUL}
                  onClickHandler={() => handleChartClick(el)}
                />
              )
          )}
        {showModal && !noDataFound && (
          <>
            <div className="nav-icons" onClick={() => showPrev(selectedChart)}>
              <img src={navLeft} alt="previous" />
            </div>
            <div className="large-charts">
              <div className={`modal-close`} onClick={clickOnClose}>
                <img src={close} alt="close" />
              </div>
              <LineChart
                id={`${selectedChart.id}Large`}
                data={getDataForBarChart(selectedChart)}
                title={selectedChart.displayname}
                xLabel={getLabelsForChart(selectedChart)}
                plotXLines={true}
                plotYLines={true}
                dispRUL={dispRUL}
                onClickHandler={() => {}}
              />
            </div>
            <div className="nav-icons" onClick={() => showNext(selectedChart)}>
              <img src={navRight} alt="previous" />
            </div>
          </>
        )}
      </div>
      {/* {noDataFound && (!errorMsg) && (
        <div>
          <Feedback />
        </div>
      )} */}
      {authenticationMsg && (
        <div>
          <NotAuthorized title={errorMsg} />
        </div>
      )}
      {!authenticationMsg && errorMsg && chartData.length == 0 && (
        <div>
          <ChartLoader />
        </div>
      )}

      {filterkey?.length === 0 && (
        <div>
          <Feedback />{" "}
        </div>
      )}
    </div>
  );
};

export default PerformanceDigitalTwin;
