import moment from "moment";
import React from "react";
import { useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import RightArrowLongIcon from "../assets/right-arrow-long.svg";
import LengendContainer from "./LengendContainer";
import { useSelector } from "react-redux";
import { colorMap, graphType } from "../utils/constants";
import NoDataFound from "./NoDataFound";

const TirePressure = ({ data, filterType }) => {
  const isSidemenuExpanded = useSelector(
    (state) => state.mainReducer.isSidemenuExpanded
  );

  const [chartData, setChartData] = useState(null);
  const [tirePressureArray, setTirePressureArray] = useState(null);
  const [chartInfo, setChartInfo] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);

  useEffect(() => {
    setChartData(data?.tirePressurePsi);
    if (data && data?.tirePressurePsi.length > 0) {
      let tirePressureArray = [];
      switch (filterType) {
        case graphType.DATE: {
          tirePressureArray = data.tirePressurePsi.map((item) => [
            moment(item.statsDate).format("DD"),
            item.tirePressurePsi,
          ]);
          tirePressureArray.sort((a, b) => a[0] - b[0]);
          break;
        }

        case graphType.MILES: {
          tirePressureArray = data.tirePressurePsi.map((item) => [
            item.miles,
            item.tirePressurePsi,
          ]);
          tirePressureArray.sort((a, b) => a[0] - b[0]);
          break;
        }
        case graphType.TRIP: {
          tirePressureArray = data.tirePressurePsi.map((item) => [
            item.trip,
            item.tirePressurePsi,
          ]);
          tirePressureArray.sort((a, b) => a[0] - b[0]);
          break;
        }
        default: {
          tirePressureArray = data.tirePressurePsi.map((item) => [
            moment(item.statsDate).format("DD"),
            item.tirePressurePsi,
          ]);
          tirePressureArray.sort((a, b) => a[0] - b[0]);
        }
      }

      setChartInfo(data?.information);
      setTirePressureArray(tirePressureArray);
      const month = moment(data?.tirePressurePsi[0].statsDate).format("MMMM");
      const year = moment(data?.tirePressurePsi[0].statsDate).format("YYYY");
      setMonth(month);
      setYear(year);
    } else {
      setTirePressureArray(null);
    }
  }, [data]);

  const options = {
    chart: {
      type: "column",
      backgroundColor: "#FFFFFF",
      spacing: [50, 50, 30, 20],
      height: 350,
      // borderColor: "#CECECE",
      borderRadius: 20,
      // borderWidth: 1,
    },
    title: {
      text: null,
    },
    yAxis: {
      title: {
        useHTML: true,
        text: `
        <div style="font-size: 11px; left:-120px;top:4px;position:absolute;">
         Tire Pressure(PSI)
          <img src='${RightArrowLongIcon}'/>
        </div>
        `,
        align: "high",
      },
      min: 0,
      labels: {
        style: {
          color: "#353536",
          lineHeight: 1,
          font: "10px Open Sans",
          letterSpacing: "1px",
        },
      },
      gridLineColor: "#F2F2F2",
    },
    xAxis: {
      type: "category",
      title: {
        useHTML: true,
        text: `
        <div style="font-size: 11px; left:-60px;top:4px;position:absolute;">
           ${
             filterType === graphType.DATE
               ? `${month},${year}`
               : filterType === graphType.MILES
               ? `Miles`
               : filterType === graphType.TRIP
               ? `Trips`
               : `${month},${year}`
           }
          <img src='${RightArrowLongIcon}'/>
        </div>
        `,
        align: "high",
      },
      labels: {
        style: {
          color: "#353536",
          lineHeight: 1,
          font: "10px Open Sans",
          letterSpacing: "1px",
        },
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: true,
        },
      },
      line: {
        dashStyle: "Dash",
      },
    },
    tooltip: {
      backgroundColor: "#000",
      style: {
        color: "#FFFFFF",
        font: "12px Open Sans",
      },
    },
    series: [
      {
        data: tirePressureArray,
        showInLegend: false,
        name: "Tire Pressure",
        color: chartInfo && colorMap[chartInfo[0]?.color],
      },
    ],
  };

  return (
    <div key={tirePressureArray}>
      <div key={isSidemenuExpanded}>
        {Array.isArray(tirePressureArray) && tirePressureArray.length > 0 ? (
          <>
            <LengendContainer chartInfo={chartInfo} />
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              updateArgs={[true, true, true]}
            />
            <div
              style={{ width: "100%", textAlign: "center", paddingTop: "10px" }}
            >
              <p style={{ fontSize: "14px", fontWeight: "600" }}>
                Tire Pressure
              </p>
            </div>
          </>
        ) : (
          <>
            <NoDataFound chartName="Tire Pressure" />
          </>
        )}
      </div>
    </div>
  );
};

export default TirePressure;
