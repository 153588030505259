import React from "react";
import VpTopDtcNonComplaints from "../../Diagonstic/Top10NonDtcComplaints/Top10NonDtcComplaints";


const VpTopDtcNonComplaintsPrognostic = () => {
  
  return <VpTopDtcNonComplaints route="prognostics" />;
};

export default VpTopDtcNonComplaintsPrognostic;
