import React, { useEffect, useState } from "react";
import { mainAxiosInstance } from "../../../services/service";
import { ServiceConfig } from "../../../services/service-constants";
import intervalPreIcon from "../../../assets/interval.png";
import Modal from "../../../components/common/Modal/Modal";
import DTCDetails from "../../../components/VehicleHealthCheck/Diagnostics/DTCDetails";
import LeftIcon from "../../../assets/left-icon.svg";
import RightIcon from "../../../assets/right-icon.svg";
import "../MaintenanceDiagnostics/index.scss";
import { Button } from "../../../components/common/Button/Button";
import { useNavigate } from "react-router-dom";
import Select from "../../../components/common/Select/Select";
import Filter from "../../../components/Filter/Filter";
import { Checkbox } from "../../../components/common/Checkbox/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { addRootCausePayload } from "../../../store/rootCauseAnalysis/actions";
import Feedback from "../../../components/common/ErrorComponents/Feedback";
import NotAuthorized from "../../../components/common/ErrorComponents/NotAuthorized";
import LottieLoad from "../../../components/common/ErrorComponents/LottieLoad";

const MaintenancePrognostics = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const vin_Number = useSelector((state) => state.mainReducer.vin_Number);
  const [dtcValues, setDTCValues] = useState([]);
  const [selectAll, setSelectAll] = useState(true);
  const [selectedDTC, setSelectedDTC] = useState({});
  const [minimumInputValue, setMinimumInputValue] = useState(0);
  const [maximumInputValue, setMaximumInputValue] = useState(10000);
  const [showModal, setShowModal] = useState(false);
  const [maxPageNum, setMaxPageNum] = useState(0);
  const [loaderChart, setLoaderChart] = useState();

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [dtcNumbers, setDTCNumbers] = useState([]);

  const [selectedDTCName, setSelectedDTCName] = useState();
  const [apiError, setAPiError] = useState(false);
  const [errormsg, setErrorMsg] = useState("");
  const [authenticationMsg, setAuthenticationMsg] = useState(false);

  // Adding metrics and filter
  const metrics = ["Miles", "Hours", "Engine Run Time", "Key starts"];
  const [defaultMetric, setDefaultMetric] = useState("Miles");
  const filterOptions = [
    { displayname: "Engine", id: "engine", isChecked: true },
    { displayname: "Transmission", id: "transmission", isChecked: true },
    {
      displayname: "BCM",
      id: "bcm",
      isChecked: true,
    },
    {
      displayname: "Battery Management Controller",
      id: "tirePressure",
      isChecked: true,
    },
    { displayname: "ABS Controller", id: "engineTorque", isChecked: true },
    {
      displayname: "Airbag",
      id: "transmissionOilTemp",
      isChecked: true,
    },
    {
      displayname: "4WD",
      id: "engineOilTemperature",
      isChecked: true,
    },
  ];
  const [filterData, setFilterData] = useState([...filterOptions]);

  const setSelectAllvalue = (data) => {
    const checkAllSelected = data.filter((el) => el.isChecked === false);
    if (checkAllSelected.length > 0) {
      setSelectAll(false);
    } else {
      setSelectAll(true);
    }
  };
  const handleIntervalOptionClick = (val) => {
    setDefaultMetric(val);
  };

  const handleFilterClick = (val) => {
    const newFilterData = filterData.map((el) => {
      if (el.displayname === val.displayname) {
        el.isChecked = !el.isChecked;
      }
      return el;
    });
    setFilterData([...newFilterData]);
    sortDTC(dtcValues, newFilterData);
    setSelectAllvalue(newFilterData);
  };

  const sortDTC = (dtcValues, newFilterData) => {
    let newDTCList = dtcValues;
    newFilterData.map((el) => {
      if (!el.isChecked) {
        newDTCList = newDTCList.filter(
          (ele) => ele.subSystem !== el.displayname
        );
      }
    });
    getDTCList(newDTCList);
  };

  const selectDtc = (val) => {
    setSelectedDTCName(val);
    const dtc = dtcValues.filter((el) => el.dtcCode === val);
    setSelectedDTC(dtc?.[0] || []);
  };

  const getDTCList = (values) => {
    const dtcList = values.map((el) => el.dtcCode);
    setDTCNumbers(dtcList || []);
    const dtcNumberSelected = dtcList?.[0] || "";
    setSelectedDTCName(dtcNumberSelected);
    const dtcSelection = values.find((el) => el.dtcCode === dtcNumberSelected);
    setSelectedDTC(dtcSelection || {});
  };

  const showDetails = (val) => {
    setShowModal(true);
  };

  const previousHandler = () => {
    const pageNum = pageNumber - 1;
    setPageNumber(pageNum < 0 ? 0 : pageNum);
  };

  const nextHandler = () => {
    const pageNum = pageNumber + 1;
    setPageNumber(pageNum > maxPageNum - 1 ? maxPageNum - 1 : pageNum);
  };

  const getDTC = (val) => {
    let url = `${ServiceConfig.mod_prognostics}?page=${pageNumber}&size=10`;
    let params = {};
    switch (defaultMetric) {
      case "Miles": {
        //    url = `${ServiceConfig.mod_prognostics}miles`;
        params = {
          queryType: "by_miles",
          vinNumber: val.vin_Number,
          organizationId: 1,
          fromRulMiles: minimumInputValue,
          toRulMiles: maximumInputValue,
          page: pageNumber,
          size: pageSize,
        };
        break;
      }
      case "Hours": {
        // url = `${ServiceConfig.mod_prognostics}time`;
        params = {
          queryType: "by_hours",
          vinNumber: val.vin_Number,
          organizationId: 1,
          fromRulHours: minimumInputValue,
          toRulHours: maximumInputValue,
          page: pageNumber,
          size: pageSize,
        };
        break;
      }
      case "Engine Run Time": {
        //  url = `${ServiceConfig.mod_prognostics}engine_runtime`;
        params = {
          queryType: "by_run_time",
          vinNumber: val.vin_Number,
          organizationId: 1,
          fromRulEngineRuntime: minimumInputValue,
          toRulEngineRuntime: maximumInputValue,
          page: pageNumber,
          size: pageSize,
        };
        break;
      }
      case "Key starts": {
        //  url = `${ServiceConfig.mod_prognostics}engine_starts`;
        params = {
          queryType: "by_key_starts",
          vinNumber: val.vin_Number,
          organizationId: 1,
          fromRulKeyStarts: minimumInputValue,
          toRulKeyStarts: maximumInputValue,
          page: pageNumber,
          size: pageSize,
        };
        break;
      }
      // default: {
      //   //  url = `${ServiceConfig.mod_prognostics}miles`;
      //   params = {
      //     queryType: "by_miles",
      //     vinNumber: val.vin_Number,
      //     organizationId: 1,
      //     fromRulMiles: minimumInputValue,
      //     toRulMiles: maximumInputValue,
      //     page: pageNumber,
      //     size: pageSize,
      //   };
      //   break;
      // }
    }

    mainAxiosInstance
      .post(url, params)
      .then((response) => {
        if (response.status === 200) {
          setDTCValues(response.data?.content || []);
          getDTCList(response.data?.content);
          sortDTC(response.data?.content || [], filterData);
          setMaxPageNum(response.data.totalPages);
          setAPiError(false);
          setLoaderChart(response.data?.content);
          return response?.data;
        }

        if (response.status === 401) {
          setAuthenticationMsg(true);
          setErrorMsg(response.data?.details || "You are not authorized.");
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setAuthenticationMsg(true);
          setErrorMsg(err.response.data?.details || "You are not authorized.");
        } else {
          setAPiError(true);
          setErrorMsg(false);
        }
      });
  };

  useEffect(() => {
    getDTC({
      vin_Number: vin_Number,
    });
    setPageSize(10);
  }, [pageNumber, pageSize]);

  const AllSelect = (val) => {
    const getUpdatedfilterData = filterData.map((el) => {
      return { ...el, isChecked: val };
    });
    setFilterData(getUpdatedfilterData);
    setSelectAll(val);
    if (val) {
      getDTCList(dtcValues);
    } else {
      let newDTCList = dtcValues;
      filterData.map((el) => {
        newDTCList = newDTCList.filter(
          (ele) => ele.subSystem !== el.displayname
        );

        getDTCList(newDTCList);
      });
    }
  };

  const goToRootCause = () => {
    navigate("./root-cause-analysis");
  };

  useEffect(() => {
    const state = {
      vin: selectedDTC.vinNumber,
      oId: selectedDTC.organizationId,
      dcode: selectedDTC.dtcCode,
      stats: selectedDTC.statsDate,
    };
    dispatch(addRootCausePayload({ ...state }));
  }, [selectedDTC]);

  const renderDTCSpecificResponse = () => {
    const heading =
      selectedDTC?.dtcCode && selectedDTC?.name
        ? `${selectedDTC?.dtcCode} - ${selectedDTC?.name}`
        : "";
    const description = selectedDTC?.description?.split(".") || [];
    const subSystems = selectedDTC?.subSystem?.split(",") || [];
    const rootCauses = selectedDTC?.rcaAndProbability.split(",") || [];
    return (
      <>
        {(dtcNumbers?.length !== 1 || selectAll) && (
          <div>
            <div className="selected-dtc-name-number">
              <span className="dtc-name-number">{heading}</span>
              <button
                className="show-details-button-fault btn btn-secondary-blue"
                onClick={() => showDetails()}
              >
                Show Details
              </button>
            </div>

            {showModal && (
              <Modal
                type={"large"}
                title={"DTC Details"}
                titlePosition={"center"}
                wrapperClass="main-diagnostics-modal showDetailsModal"
                isError={false}
                handleClose={() => setShowModal(false)}
              >
                <DTCDetails
                  dtcValue={selectedDTC.dtcCode}
                  statsDate={selectedDTC.statsDate}
                  handleClose={() => setShowModal(false)}
                  isFieldHidden={true}
                  handleClick={() => goToRootCause()}
                  buttonText="Root Cause Analysis"
                />
              </Modal>
            )}

            <div className="fault-description-wrapper">
              <div className="heading">Description:</div>
              <ul className="pointers-wrapper">
                {description.map((el) => (
                  <li key={el} className="pointers">
                    {el}
                  </li>
                ))}
              </ul>
            </div>
            <div className="fault-sub-system-wrapper sub-systems">
              <div className="heading">Sub Systems:</div>
              <ul className="pointers-wrapper">
                {subSystems.map((el) => (
                  <li key={el} className="pointers">
                    {el}
                  </li>
                ))}
              </ul>
            </div>
            <div className="fault-sub-system-wrapper remaining-useful-life">
              <div className="heading flex sp-bw">
                <div className="cause-item-left">Root cause:</div>
                <div className="cause-item-right">Probability</div>
              </div>
              <ul className="pointers-wrapper">
                {rootCauses?.length ? (
                  rootCauses.map((item) => (
                    <li key={item} className="pointers">
                      <div className="cause-item-left inline-block">
                        {item.split(":")?.[0]}
                      </div>
                      <div className="cause-item-right inline-block">
                        {item.split(":")?.[1]}
                      </div>
                    </li>
                  ))
                ) : (
                  <li>No root causes found.</li>
                )}
              </ul>
            </div>
          </div>
        )}
      </>
    );
  };

  if (!loaderChart && !apiError) {
    return <LottieLoad />;
  }

  //  if (apiError){
  //   return <Feedback />
  //  }

  return (
    <>
      <div className="page-wrapper main_on_demand_prog">
        <div className="flex page-content-header">
          <div className="page-options-container">
            <Select
              heading="Remaining Useful Life"
              options={metrics}
              defaultSelected={defaultMetric}
              handleOptionClick={handleIntervalOptionClick}
              // preIcon={intervalPreIcon}
            />
            <input
              type="text"
              className="value-input fault-trend-input"
              placeholder="Enter Lower Limit"
              value={minimumInputValue}
              onChange={(e) => {
                setMinimumInputValue(e.target.value);
              }}
              maxLength={10}
            />
            <input
              type="text"
              className="value-input fault-trend-input"
              placeholder="Enter Upper Limit"
              value={maximumInputValue}
              onChange={(e) => {
                setMaximumInputValue(e.target.value);
              }}
              maxLength={10}
            />
            <input
              type="submit"
              className="btn btn-primary-blue go-button"
              value="Get DTCs"
              onClick={() =>
                getDTC({
                  vin_Number: vin_Number,
                })
              }
            />
          </div>
          <div className="page-filter">
            <Filter data={AllSelect} selectAll={selectAll}>
              {filterData.map((el) => (
                <Checkbox
                  title={el.displayname}
                  handleInputClick={() => handleFilterClick(el)}
                  alignProp={{
                    borderTop: "0.5px solid #C4C4C4",
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "10px",
                    alignItems: "center",
                    flexDirection: "row-reverse",
                  }}
                  checkboxStyle={{ marginRight: "10px" }}
                  isChecked={el.isChecked}
                />
              ))}
            </Filter>
          </div>
        </div>
        {!apiError && !authenticationMsg ? (
          <>
            <div className="service-help-wrapper">
              <div className="service-help-button">
                Service Help
                <Button
                  title="Get Summary"
                  handleClick={() =>
                    navigate(
                      "/maintenance-overview/prognostics/service-guide/architecture",
                      {
                        replace: true,
                      }
                    )
                  }
                  styleClasses="btn btn-secondary-blue"
                />
              </div>
            </div>

            <div>
              {(dtcNumbers?.length !== 1 || selectAll) && (
                <div className="predicted-dtc-wrapper">
                  <div className="predicted-dtc-text">
                    Predicted Diagnostic Trouble Codes (DTC)
                  </div>
                  <div className="predicted-dtc">
                    <div
                      className={`left-arrow-wrapper ${
                        pageNumber === 0 ? "disabled" : ""
                      }`}
                      onClick={() => previousHandler()}
                    >
                      <img
                        className="left-arrow"
                        src={LeftIcon}
                        alt="previous"
                      ></img>
                    </div>
                    <span className="low-rul">Low RUL</span>
                    <div className="dtc-numbers-wrapper">
                      <ul className="dtc-numbers-ul">
                        {dtcNumbers.map((el) => (
                          <li
                            key={el}
                            className={`dtc-numbers-li ${
                              selectedDTCName === el ? "active" : ""
                            }`}
                            onClick={() => selectDtc(el)}
                          >
                            {el}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <span className="high-rul">High RUL</span>
                    <div
                      className={`right-arrow-wrapper ${
                        pageNumber === maxPageNum - 1 ? "disabled" : ""
                      }`}
                      onClick={() => nextHandler()}
                    >
                      <img
                        className="right-arrow"
                        src={RightIcon}
                        alt="next"
                      ></img>
                    </div>
                  </div>
                </div>
              )}
              {dtcNumbers?.length > 0 && renderDTCSpecificResponse()}
              {(dtcNumbers?.length == 0 && !authenticationMsg) ||
                (dtcNumbers?.length == 1 && !selectAll && (
                  <div>
                    <Feedback />
                  </div>
                ))}
            </div>
          </>
        ) : (
          <>
            {!errormsg && (
              <div>
                <Feedback />
              </div>
            )}
          </>
        )}
        {authenticationMsg && (
          <div>
            <NotAuthorized title={errormsg} />
          </div>
        )}
      </div>
    </>
  );
};

export default MaintenancePrognostics;
