import { Checkbox } from "../../../../components/common/Checkbox/Checkbox";
import Filter from "../../../../components/Filter/Filter";
import React, { useEffect, useState } from "react";
import { BarChart } from "../../../../components/common/RangeChart/RangeChart";
import { VehiclePopulationDashBoard } from "../../../../services/service-constants";
import navLeft from "../../../../assets/left-arrow-square.svg";
import navRight from "../../../../assets/right-arrow-square.svg";
import { mainAxiosInstance } from "../../../../services/service";
import { BarChartLarge } from "../../../../components/common/RangeChart/BarChartLarge";
import { useSelector } from "react-redux";
import Feedback from "../../../../components/common/ErrorComponents/Feedback";
import NotAuthorized from "../../../../components/common/ErrorComponents/NotAuthorized";
import ChartLoader from "../../../../components/common/ErrorComponents/ChartLoader";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../../components/common/Pagination";

const ReportForPrognostic = () => {
  const [defFilterData, setDefFilterData] = useState(null);
  const [selectAll, setSelectAll] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showFilter, setShowFilter] = useState(true);
  const vinNumber = useSelector((state) => state.mainReducer.vin_Number);
  const [selectedChartData, setSelectedChartData] = useState(null);
  const navigate = useNavigate();
  const [filterData, setFilterData] = useState([]);
  const [yAxisLabel, setYAxisLabel] = useState("Total number of miles");
  const [noDataFound, setNoDataFound] = useState(true);
  const [errorMsg, setErrorMsg] = useState(true);
  const [authenticationMsg, setAuthenticationMsg] = useState(false);
  const [pageSize, setPageSize] = useState(0);
  const [pagination, setPagination] = useState(0);
  const [filtData, setFiltData] = useState(null);
  const dataStore = [];

  const setSelectAllvalue = (data) => {
    const checkAllSelected = data.filter((el) => el.isChecked === false);
    if (checkAllSelected.length > 0) {
      setSelectAll(false);
    } else {
      setSelectAll(true);
    }
  };

  useEffect(() => {
    const selectedData = JSON.parse(localStorage.getItem("selectedData"));
    const selectedDiagonsticsData = JSON.parse(
      localStorage.getItem("selectedDiagonosticsData")
    );
    const FilterSelectedValues = {
      ...selectedData,
      ...selectedDiagonsticsData,
    };
    setFiltData(FilterSelectedValues);
  }, []);

  const handleFilterClick = (val) => {
    const newFilterData = filterData.map((el) => {
      if (el.displayname === val.displayname) {
        el.isChecked = !el.isChecked;
      }
      return el;
    });

    const isAnyModuleSelected = newFilterData.some((el) => el.isChecked);
    //setNoDataFound(!isAnyModuleSelected);
    setFilterData([...newFilterData]);
    setSelectAllvalue(newFilterData);
  };

  const valueMap = {
    engineCoolantTemperatureResponse: "temperatureInFahrenheit",
    transmissionGearResponse: "transmissionGear",
    engineOilTemperatureResponse: "oilTemperatureFahrenheit",
    transmissionOilTemperatureResponse: "transmissionOilTemperatureFahrenheit",
    engineTorqueResponse: "engineTorque",
    tirePressureResponse: "tirePressure",
    engineOilLevelResponse: "engineOilLevel",
    engineSpeedResponse: "engineSpeed",
  };

  const getChartData = async (response) => {
    const colorMap = {
      yellow: "#09A1A1",
      green: "#0574A6",
      red: "#DD359A",
    };
    let mainData = [];
    let filterKey = [];
    let PaginationInfo = 1;

    Object.keys(response)?.map((key) => {
      PaginationInfo = response[key]?.pageResponse?.totalPages;
      let resKey = valueMap[key];
      let meanData = [];
      let maxData = [];
      let minData = [];
      let resData = [];

      filterKey.push({
        displayname: response[key]?.graphLabelsDto?.graphLabel,
        id: response[key]?.graphLabelsDto?.graphLabel,
        isChecked: true,
      });
      response[key]?.pageResponse?.content?.map((data) => {
        meanData.push({
          y: data.meanValue,
          color: colorMap[data.threshold],
          maxValue: data?.maxValue,
          minValue: data?.minValue,
          iqr: data?.iqr,
          standardDeviation: data?.standardDeviation,
          variance: data?.variance,
          range: data?.range,
        });
        maxData.push({
          y: data?.maxValue === null ? 0 : data?.maxValue,
        });
        minData.push({
          y: data.minValue === null ? 0 : data?.minValue,
        });
        resData.push(data?.xAxisValue);
      });
      mainData.push({
        name: response[key]?.graphLabelsDto?.graphLabel,
        PaginationInfo: PaginationInfo,
        data: [
          {
            data: [
              {
                type: "column",
                data: meanData,
                pointWidth: 10,
                name: "Miles",
              },
              {
                type: "line",
                data: maxData,

                marker: {
                  radius: 3,
                  symbol: "circle",
                  fillColor: {
                    radialGradient: {
                      cx: 0.5,
                      cy: 0.3,
                      r: 0.5,
                    },
                    stops: [
                      [0, "#FFFFFF"],
                      [1, "#DD359A"],
                    ],
                  },
                },
                lineColor: "#DD359A",
                lineWidth: 1,
                name: "Max",
              },
              {
                type: "line",
                data: minData,
                marker: {
                  radius: 3,
                  symbol: "circle",
                  fillColor: {
                    radialGradient: {
                      cx: 0.5,
                      cy: 0.3,
                      r: 0.5,
                    },
                    stops: [
                      [0, "#FFFFFF"],
                      [1, "#0574A6"],
                    ],
                  },
                },
                lineColor: "#0574A6",
                lineWidth: 1,
                name: "Min",
              },
            ],
            categories: resData,
          },
        ],
      });
    });
    setChartData(mainData);
    setFilterData(filterKey);
  };

  useEffect(() => {
    getChartData(data);
  }, [data]);

  useEffect(() => {
    setPagination(0);
  }, []);

  useEffect(() => {
    let url = `${VehiclePopulationDashBoard.defaultApi}`;
    mainAxiosInstance
      .get(url)
      .then((response) => {
        setDefFilterData(response.data);
      })
      .catch((er) => {
        throw er;
      });

    //getSFilteredData();
  }, []);

  const getPageData = () => {
    const url = `${
      VehiclePopulationDashBoard.vp_usage_profile
    }/${1}?page=${pageSize}&size=10`;

    let filteredValues = {};
    if (Object.keys(filtData).length === 0) {
      filteredValues = {
        ...defFilterData,
      };
    } else {
      const fromMilesStr = JSON.parse(localStorage.getItem("lowerLimitData"));
      const RULlowerLimitData = parseInt(fromMilesStr, 10);
      let toMilesStr = JSON.parse(localStorage.getItem("upperLimitData"));
      const RULupperLimitData = parseInt(toMilesStr, 10);
      const selectedRulData = localStorage.getItem("rul");

      let params = {};
      switch (selectedRulData) {
        case "Miles": {
          params = {
            fromRulMiles: RULlowerLimitData,
            toRulMiles: RULupperLimitData,
            rul: "by_miles",
          };
          break;
        }

        case "Engine Run Time": {
          params = {
            fromRulEngineRunTime: RULlowerLimitData,
            toRulEngineRunTime: RULupperLimitData,
            rul: "by_engine_runtime",
          };
          break;
        }

        case "Hours": {
          params = {
            fromRulHours: RULlowerLimitData,
            toRulHours: RULupperLimitData,
            rul: "by_hours",
          };
          break;
        }

        case "Key starts": {
          params = {
            fromRulKeyStarts: RULlowerLimitData,
            toRulKeyStarts: RULupperLimitData,
            rul: "by_key_starts",
          };
          break;
        }

        default: {
          params = {
            fromRulMiles: 1,
            toRulMiles: 200000,
            rul: "by_miles",
          };
        }
      }

      filteredValues = {
        ...filtData,
        ...params,
      };
    }

    let data = Object.assign(filteredValues);
    mainAxiosInstance
      .post(url, data)
      .then((response) => {
        if (response.status === 200) {
          setNoDataFound(false);
          //prepareChartData(response.data);
          setData(response.data);
        }
        if (response.status === 401) {
          setErrorMsg(response.data?.details || "You are not authorized.");
          setAuthenticationMsg(true);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setAuthenticationMsg(true);
          setErrorMsg(err.response.data?.details || "You are not authorized.");
        } else {
          setNoDataFound(true);
          setErrorMsg(false);
        }
      });
  };

  const getDataForBarChart = (element) => {
    const barChartData = chartData.filter((el) => el.name === element.id);
    dataStore.push(barChartData?.[0]?.data);
    return barChartData?.[0]?.data;
  };

  const getTotalPages = (element) => {
    const barChartData = chartData.filter((el) => el.name === element.id);
    const totalPages = barChartData[0]?.PaginationInfo;
    setPagination(totalPages);
  };

  const handleChartClick = (el) => {
    setShowModal(true);
    setSelectedChartData(el);
    setShowFilter(false);
    getTotalPages(el);

    const barChartData = chartData.filter((elemet) => elemet.name === el.id);
    const totalPages = barChartData[0]?.PaginationInfo;
    setPagination(totalPages);
  };

  const showPrev = (el) => {
    const data = filterData.filter((el) => el.isChecked);
    if (data.length > 0) {
      const index = data.findIndex((elem) => elem.id === el.id);
      let selectElement = el;
      if (index !== 0) {
        selectElement = data[index - 1];
      } else if (index === 0) {
        selectElement = data[data.length - 1];
      }
      setNoDataFound(false);
      setSelectedChartData(selectElement);
      getTotalPages(selectElement);
      setPageSize(0);
    } else {
      setNoDataFound(true);
    }
  };

  const clickOnClose = (e) => {
    setShowModal(false);
    setShowFilter(true);
    setPageSize(0);
  };
  const showNext = (el) => {
    const data = filterData.filter((el) => el.isChecked);

    if (data.length > 0) {
      const index = data.findIndex((elem) => elem.id === el.id);
      let selectElement = el;
      if (index !== data.length - 1) {
        selectElement = data[index + 1];
      } else if (index === data.length - 1) {
        selectElement = data[0];
      }
      setNoDataFound(false);
      setSelectedChartData(selectElement);
      getTotalPages(selectElement);
      setPageSize(0);
    } else {
      setNoDataFound(true);
    }
  };

  useEffect(() => {
    if (defFilterData !== null) getPageData();
  }, [defFilterData, pageSize]);

  const AllSelect = (val) => {
    const getUpdatedfilterData = filterData.map((el) => {
      return { ...el, isChecked: val };
    });
    setFilterData(...[getUpdatedfilterData]);
    setSelectAll(val);
  };

  const onPageChanged = (val) => {
    setPageSize(val);
  };

  return (
    <div
      className="page-wrapper vehicle-usage"
      style={{ backgroundColor: "#EEEEEE" }}
    >
      <div className="flex page-content-header--filter">
        {showFilter === true && (
          <Filter data={AllSelect} selectAll={selectAll}>
            {filterData.map((el) => (
              <Checkbox
                title={el.displayname}
                handleInputClick={() => handleFilterClick(el)}
                alignProp={{
                  borderTop: "0.5px solid #C4C4C4",
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "10px",
                  alignItems: "baseline",
                  flexDirection: "row-reverse",
                }}
                checkboxStyle={{ marginRight: "10px" }}
                isChecked={el.isChecked}
              />
            ))}
          </Filter>
        )}
      </div>
      {!noDataFound && (
        <div className="legend-wrapper">
          <div className="legend">
            <div className="green-legend">
              <span className="line-box blue"></span>
              <span className="color-legend">Min Values</span>
            </div>
            <div className="green-legend">
              <span className="line-box red"></span>
              <span className="color-legend">Max Values</span>
            </div>
            <div className="green-legend">
              <span className="color-box green"></span>
              <span className="color-legend">Within the Expected range</span>
            </div>
            <div className="yellow-legend">
              <span className="color-box yellow"></span>
              <span className="color-legend">
                Within Expected range but too high/low
              </span>
            </div>
            <div className="red-legend">
              <span className="color-box pink"></span>
              <span className="color-legend">Outside Expected range</span>
            </div>
          </div>
        </div>
      )}
      <div>
        {noDataFound && !errorMsg && (
          <div>
            <Feedback />
          </div>
        )}
        {authenticationMsg && (
          <div>
            <NotAuthorized title={errorMsg} />
          </div>
        )}
        {noDataFound && !authenticationMsg && errorMsg && (
          <div>
            <ChartLoader />
          </div>
        )}
      </div>

      {!noDataFound && (
        <div className="page-chart-container vehicle-usage-charts">
          {!noDataFound &&
            filterData.map(
              (el) =>
                el.isChecked &&
                !showModal && (
                  <BarChart
                    id={el.id}
                    data={getDataForBarChart(el)}
                    // categories={getCategoryDataForBarChart(el)}
                    title={el.displayname}
                    yLabel={yAxisLabel}
                    onClickHandler={() => handleChartClick(el)}
                    fromVpUp={true}
                  />
                )
            )}

          {showModal && !noDataFound && (
            <>
              <div
                className="nav-icons"
                onClick={() => showPrev(selectedChartData)}
              >
                <img src={navLeft} alt="previous" />
              </div>
              <BarChartLarge
                id={selectedChartData.displayname}
                data={getDataForBarChart(selectedChartData)}
                title={selectedChartData.displayname}
                yLabel={yAxisLabel}
                clickOnClose={clickOnClose}
                fromVpUp={true}
              />
              <div
                className="nav-icons"
                onClick={() => showNext(selectedChartData)}
              >
                <img src={navRight} alt="previous" />
              </div>
            </>
          )}

          {dataStore?.length == 0 && (
            <div style={{ width: "900px" }}>
              <Feedback />
            </div>
          )}
        </div>
      )}
      {showModal && pagination > 1 && (
        <Pagination
          pageCount={pagination}
          pageRange={10}
          onPageChanged={onPageChanged}
        />
      )}
    </div>
  );
};

export default ReportForPrognostic;
