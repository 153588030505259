import React, { useEffect, useState } from "react";
import { NavLink, Outlet, useNavigate, useLocation } from "react-router-dom";
import TabLayout from "../../../components/Layout/TabLayout";

const SavedFilters = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showNavMenu, setShowNavMenu] = useState(false);
  const [forUPNavMenu, setForUPNavMenu] = useState(false);

  useEffect(() => {
    if (location.pathname === "/vp-saved-filter") {
      navigate("/vp-saved-filter/savedfilter", {
        replace: true,
      });
      setShowNavMenu(false);
    } else if (location.pathname.includes("/vp-saved-filter/comparision")) {
      setShowNavMenu(true);
      setForUPNavMenu(false);
    } else if (
      location.pathname.includes("/vp-saved-filter/usageprofile/comparision")
    ) {
      setShowNavMenu(true);
      setForUPNavMenu(true);
    } else if (
      location.pathname.includes("/vp-saved-filter/health-check/comparision")
    ) {
      setShowNavMenu(true);
      setForUPNavMenu(true);
    }
  }, [location.pathname]);

  const navLink = !forUPNavMenu
    ? [
        {
          label: "Reports For Diagnostics",
          to: "/vp-saved-filter/comparision/GeneratedDashboard/diagnostics",
        },
        {
          label: "Reports For Prognostics",
          to: "/vp-saved-filter/comparision/GeneratedDashboard/prognostics",
        },
      ]
    : [
        {
          label: "Reports For Diagnostics",
          to: "/vp-saved-filter/usageprofile/comparision/diagnostics",
        },
        {
          label: "Reports For Prognostics",
          to: "/vp-saved-filter/usageprofile/comparision/prognostics",
        },
      ];

  const seondNavLink = [
    {
      label: "Reports For Diagnostics",
      to: "/vp-saved-filter/health-check/comparision/diagnostics",
    },
    {
      label: "Reports For Prognostics",
      to: "/vp-saved-filter/health-check/comparision/prognostics",
    },
  ];

  return (
    <div className="full-width ">
      {/* <Outlet /> */}
      {location.pathname.includes(
        "/vp-saved-filter/usageprofile/comparision"
      ) || location.pathname.includes("/vp-saved-filter/comparision") ? (
        <TabLayout
          tabLink={showNavMenu ? navLink : []}
          shouldIncludeBreadcrumbs={false}
        />
      ) : location.pathname.includes(
          "vp-saved-filter/health-check/comparision"
        ) ? (
        <TabLayout
          tabLink={showNavMenu ? seondNavLink : []}
          shouldIncludeBreadcrumbs={false}
        />
      ) : (
        <TabLayout shouldIncludeBreadcrumbs={false} />
      )}
    </div>
  );
};

export default SavedFilters;
