import React from "react";
import { ReactComponent as UploadIcon } from "../../../../assets/upload.svg";
import Feedback from "../../../../components/common/ErrorComponents/Feedback";
import LottieLoad from "../../../../components/common/ErrorComponents/LottieLoad";
import NotAuthorized from "../../../../components/common/ErrorComponents/NotAuthorized";

const VibrationalAnalysisTable = ({ performanceData }) => {
  if (!performanceData) {
    return <LottieLoad />;
  }

  if (performanceData === "No records are Found") {
    return <Feedback />;
  }

  if (performanceData === "You are not authorized") {
    return <NotAuthorized tittle={performanceData} />;
  }
  return (
    <div className="vibrational-analysis-table">
      <table>
        <thead>
          <tr>
            <th>Part</th>
            <th>Device ID</th>
            <th>Device Name</th>
            <th>Measurement</th>
            <th>Upload Data Date</th>
            <th>Results (KPIs Attached)</th>
            <th>RUL(Miles)</th>
            <th>Service Actions</th>
          </tr>
        </thead>
        <tbody>
          {performanceData?.length &&
            performanceData?.map((part) => (
              <tr key={part?.deviceId}>
                <td className="part-name">{part?.parts[0]}</td>
                <td>{part?.deviceId}</td>
                <td>{part?.deviceName}</td>
                <td className="measurement">
                  {part?.measurement?.map((measure) => (
                    <div className="inner">{measure}</div>
                  ))}
                </td>
                <td>
                  <div>
                    <p>{part?.uploadDataDate}</p>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gridGap: "10px",
                      }}
                    >
                      <p style={{ textDecoration: "underline" }}>Upload</p>
                      <UploadIcon style={{ marginTop: "6px" }} />
                    </div>
                  </div>
                </td>
                <td>
                  <p style={{ textDecoration: "underline" }}>View Details</p>
                </td>

                <td className="rul">
                  {part?.rulMiles?.map((rul) => (
                    <div className="inner">{rul}</div>
                  ))}
                </td>
                <td className="service">
                  {part?.serviceActions?.map((service) => (
                    <div className="inner">{service}</div>
                  ))}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default VibrationalAnalysisTable;
