import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
} from "../types";

const userToken = JSON.parse(localStorage.getItem("token"));

const initialState = userToken
  ? { isLoggedIn: true, userToken: userToken }
  : { isLoggedIn: false, userToken: null };

export const authReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        userToken: payload.userToken,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        userToken: null,
      };
    case LOGOUT:
      localStorage.removeItem("vinId");
      return {
        ...state,
        isLoggedIn: false,
        userToken: null,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    default:
      return state;
  }
};
