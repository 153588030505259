import React, { useEffect, useState } from "react";
import { getServiceGuideData } from "../api";
import DtcHeading from "../components/DtcHeading";
import LargeContent from "../components/LargeContent";
import ServiceCard from "../components/ServiceCard";
import { useDispatch, useSelector } from 'react-redux';
import Feedback from "../../../../components/common/ErrorComponents/Feedback"
import LottieLoad from "../../../../components/common/ErrorComponents/LottieLoad"
import NotAuthorized from "../../../../components/common/ErrorComponents/NotAuthorized"

const AugmentedRealityServiceGuide = () => {
  const [data, setData] = useState(null);
  const [isContentLarge, setIsContentLarge] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [notAuthorized, setNotAuthorized] = useState(false);
  const [apiError, setApiError] = useState(false);
  const vinNumber = useSelector((state) => state.mainReducer.vin_Number);
  const getServiceGuide = async (payload) => {
    const { data, code } = await getServiceGuideData(payload);
    if (code === 200 && data) {
      setData(data);
      setNotAuthorized(false);
      setApiError(false);
    } else if (code === 401) {
      setNotAuthorized(true);
    }
    else {
      setApiError(true);
    }
  };
  useEffect(() => {
    getServiceGuide({
      serviceType: "Augmented Reality",
      vinNumber: vinNumber,
    });
  }, []);
  if (notAuthorized) {
    return <NotAuthorized title={data} />;
  }
  if (apiError) {
    return <Feedback />;
  }
  if (!data) {
    return <LottieLoad />;
  }
  return (
    <div className="page-wrapper serviceguide serviceguide_architecture" style={{"backgroundColor":"#EEEEEE"}}>
      {data ? (
        <>
          {!isContentLarge ? (
            <>
              <DtcHeading />
              <div className="content_container">
                {data?.map((item, index) => (
                  <div key={index}>
                    <ServiceCard
                      data={item}
                      onClick={(data) => {
                        setIsContentLarge(true);
                        setSelectedCard(data);
                      }}
                    />
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              <LargeContent
                selectedCard={selectedCard}
                onClose={() => setIsContentLarge(false)}
                data={data}
                onServiceCardClick={(singleData) => {
                  setIsContentLarge(true);
                  setSelectedCard(singleData);
                }}
              />
            </>
          )}
        </>
      ) : (
        <>
          <>No Data found</>
        </>
      )}
    </div>
  );
};

export default AugmentedRealityServiceGuide;
