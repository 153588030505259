import { BarGraph } from "../../../../components/common/blackbarGraph/Graph";
import {
  ServiceConfig,
  VehiclePopulationDashBoard,
} from "../../../../services/service-constants";
import { mainAxiosInstance } from "../../../../services/service";
import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "../../../../components/common/Select/Select";
import Datepicker from "../../../../components/Datepicker/datepicker";
import "../../VehiclePopulation.scss";
import intervalPreIcon from "../../assets/select-metric-icon.svg";
import Feedback from "../../../../components/common/ErrorComponents/Feedback";
import SingleChartLoader from "../../../../components/common/ErrorComponents/SingleChartLoader";
import moment from "moment";
import Pagination from "../../../../components/common/Pagination";

const RUL = () => {
  const [noDataFound, setNoDataFound] = useState(true);
  const [pagination, setPagination] = useState({});
  const [apiError, setApiError] = useState(false);
  const [defaultInterval, setDefaultInterval] = useState("Miles");
  const vinNumber = useSelector((state) => state.mainReducer.vin_Number);
  const [chartData, setChartData] = useState(null);
  const interval = ["Miles", "Hours", "Engine Run Time", "Key starts"];
  const [showInput, setShowInput] = useState(true);
  const [showDatepicker, setShowDatepicker] = useState(false);
  const [upperLimitData, setUpperLimitData] = useState(5000);
  const [lowerLimitData, setLowerLimitData] = useState(1);
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndtDate, setSelectedEndDate] = useState(new Date());
  const [paramData, setParamData] = useState(null);
  const RULData = localStorage.getItem("rul");
  const RULlowerLimitData = localStorage.getItem("lowerLimitData");
  const RULupperLimitData = localStorage.getItem("upperLimitData");
  const [showChartLoader, setShowChartloader] = useState(false);
  const [pageSize, setPageSize] = useState(0);

  const prepareChartData = (dataSet) => {
    getChartData(dataSet);
  };

  const getOutputData = (data) => {
    setPagination(data?.rulGraphDto);
    const milesData = [];
    const detailsData = [];
    var paginationData = {};
    let xAxisLabel = data.informationDto.xAxisName;
    let yAxisLabel = data.informationDto.yAxisName;
    let graphName = data.informationDto.graphName;
    let rulData = data.rulGraphDto.content;
    rulData.forEach((val) => {
      detailsData.push({
        y: val.noOfOccurrences,
        name: val.dtcCode,
        values: val.dtcCode,
      });
    });
    milesData.push({
      data: detailsData,
      xLabel: xAxisLabel,
      yLabel: yAxisLabel,
      id: graphName,
    });
    setChartData(milesData);
  };

  const getChartData = async (response) => {
    getOutputData(response);
  };

  useEffect(() => {
    if (RULData !== null) {
      setDefaultInterval(RULData);
      setLowerLimitData(RULlowerLimitData);
      setUpperLimitData(RULupperLimitData);
    }
  }, []);
  useEffect(() => {
    let url = `${VehiclePopulationDashBoard.defaultApi}`;
    mainAxiosInstance
      .get(url)
      .then((response) => {
        setParamData(response.data);
      })
      .catch((er) => {
        throw er;
      });
  }, []);

  const getPageData = (val) => {
    setShowChartloader(true);
    const url = `${VehiclePopulationDashBoard.Rul}/1?page=${pageSize}&size=10`;
    let params = {};
    switch (defaultInterval) {
      case "Miles": {
        delete paramData.type;
        params = {
          fromRulMiles: parseInt(lowerLimitData),
          toRulMiles: parseInt(upperLimitData),
          rul: "by_miles",
        };
        break;
      }

      case "Engine Run Time": {
        delete paramData.type;
        params = {
          fromRulEngineRunTime: parseInt(lowerLimitData),
          toRulEngineRunTime: parseInt(upperLimitData),
          rul: "by_engine_runtime",
        };
        break;
      }

      case "Hours": {
        delete paramData.type;
        params = {
          fromRulHours: parseInt(lowerLimitData),
          toRulHours: parseInt(upperLimitData),
          rul: "by_hours",
        };
        break;
      }

      case "Key starts": {
        delete paramData.type;
        params = {
          fromRulKeyStarts: parseInt(lowerLimitData),
          toRulKeyStarts: parseInt(upperLimitData),
          rul: "by_key_starts",
        };
        break;
      }

      default: {
        params = {
          fromRulMiles: parseInt(lowerLimitData),
          toRulMiles: parseInt(upperLimitData),
          rul: "by_miles",
        };
      }
    }
    const selectedData = JSON.parse(localStorage.getItem("selectedData"));
    const selectedDiagonsticsData = JSON.parse(
      localStorage.getItem("selectedDiagonosticsData")
    );
    const FilterSelectedValues = {
      ...selectedData,
      ...selectedDiagonsticsData,
    };
    let filteredValues = {};
    if (Object.keys(FilterSelectedValues).length === 0) {
      filteredValues = paramData;
    } else {
      filteredValues = FilterSelectedValues;
    }

    let data = Object.assign(params, filteredValues);

    mainAxiosInstance
      .post(url, data)
      .then((response) => {
        if (response.status === 200) {
          setNoDataFound(false);
          prepareChartData(response.data);
          setShowChartloader(false);
        }
        if (response.status === 401) {
          setApiError(response.data?.details || "You are not authorized.");
          setNoDataFound(true);
          setShowChartloader(false);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setApiError(err.response.data?.details || "You are not authorized.");
          setNoDataFound(true);
          setShowChartloader(false);
        } else {
          setNoDataFound(true);
          setShowChartloader(false);
        }
      });
  };
  useEffect(() => {
    if (paramData !== null) getPageData();
  }, [
    defaultInterval,
    lowerLimitData,
    upperLimitData,
    selectedStartDate,
    selectedEndtDate,
    paramData,
    pageSize,
  ]);

  const handleOnStartDateChange = (dates) => {
    const startDate = moment(dates).format("yyyy-MM-DD");
    setSelectedStartDate(startDate);
  };

  const handleOnEndDateChange = (dates) => {
    const endDate = moment(dates).format("yyyy-MM-DD");
    setSelectedEndDate(endDate);
  };

  const handleIntervalOptionClick = (val) => {
    setDefaultInterval(val);
    switch (val) {
      case "Miles":
        setShowInput(true);
        setPageSize(0);
        setShowDatepicker(false);
        setLowerLimitData(1);
        setUpperLimitData(5000);
        break;

      case "Hours":
        setShowInput(true);
        setPageSize(0);
        setShowDatepicker(false);
        setLowerLimitData(0);
        setUpperLimitData(1);
        break;

      case "Engine Run Time":
        setShowInput(true);
        setShowDatepicker(false);
        setLowerLimitData(0);
        setUpperLimitData(1);
        setPageSize(0);
        break;

      case "Key starts":
        setShowInput(true);
        setShowDatepicker(false);
        setLowerLimitData(1);
        setPageSize(0);
        setUpperLimitData(5000);
        break;

      default:
        setShowInput(true);
        setShowDatepicker(false);
        setLowerLimitData(1);
        setUpperLimitData(5000);
        break;
    }
  };

  const handleLowerRulInput = (event) => {
    const { value } = event.target;
    const letters = /^-?\d*\.?\d*$/.test(value);
    if (letters) {
      setLowerLimitData(value);
    }
  };

  const handleUpperRulInput = (event) => {
    const { value } = event.target;
    const letters = /^-?\d*\.?\d*$/.test(value);
    if (letters) {
      setUpperLimitData(value);
    }
  };

  // if (chartData === null && noDataFound === false) {
  //   return (
  //     <div className="vehicle-dashboard-middle-container">
  //       <SingleChartLoader />;
  //     </div>
  //   );
  // }

  const onPageChanged = (val) => {
    setPageSize(val);
  };

  return (
    <>
      <div className="vehicle-dashboard-middle-container">
        <div className="page-options-container metric-box">
          <Select
            options={interval}
            defaultSelected={defaultInterval}
            handleOptionClick={handleIntervalOptionClick}
            secondaryContainerClass="top-10-input-box tab"
            containerClass="top-10-dtc"
            preIcon={intervalPreIcon}
          />
          {showInput && (
            <>
              <input
                type="text"
                className="Dtc-value-input "
                placeholder={`Enter Lower Limit`}
                value={lowerLimitData}
                onChange={handleLowerRulInput}
                maxLength={10}
              />
              <input
                type="text"
                className="Dtc-value-input"
                placeholder={`Enter Upper Limit`}
                value={upperLimitData}
                onChange={handleUpperRulInput}
                maxLength={10}
              />
            </>
          )}
          {showDatepicker && (
            <div
              style={{
                display: "flex",
                position: "relative",
                bottom: "5px",
              }}
            >
              <Datepicker
                onChange={handleOnStartDateChange}
                classContainer="top-10-dtc-date"
                secondaryClassContainer="input-value-top-10-dtc"
              />

              <Datepicker
                onChange={handleOnEndDateChange}
                classContainer="top-10-dtc-date"
                secondaryClassContainer="input-value-top-10-dtc"
              />
            </div>
          )}
        </div>
        {!noDataFound ? (
          <>
            <BarGraph chartdata={chartData} />
          </>
        ) : chartData === null ? (
          <div>
            <SingleChartLoader />;
          </div>
        ) : (
          <div>
            <Feedback feedbackClassName="feedback" />
          </div>
        )}
      </div>
      {noDataFound === false && (
        <>
          <div className="graph-name">RUL </div>
          {pagination?.totalPages > 1 && (
            <Pagination
              pageCount={pagination?.totalPages}
              pageRange={pagination?.size}
              onPageChanged={onPageChanged}
              forcePage={pageSize}
            />
          )}
        </>
      )}
    </>
  );
};

export default RUL;
