import React from "react";
import { useEffect, useState } from "react";
import { VehiclePopulationDashBoard } from "../../../services/service-constants";
import { mainAxiosInstance } from "../../../services/service";
import { components } from "react-select";
import SelectWithCheckbox from "../../../components/common/SelectWithCheckbox";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const SelectFromApi = ({ name, handleOnChange, filterValue }) => {
  const [options, setOptions] = useState([]);
  const [optionSelected, setOptionSelected] = useState(null);

  useEffect(() => {
    if (filterValue !== "make") {
      getFilterValues(filterValue);
    } else {
      setOptions([{ value: "Ford", label: "Ford" }]);
      setOptionSelected([{ value: "Ford", label: "Ford" }]);
    }
  }, [filterValue]);

  const getFilterValues = (value) => {
    const url = `${VehiclePopulationDashBoard.filters}/` + value;
    return mainAxiosInstance
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          const res = response.data?.map((opt) => {
            return {
              value: opt,
              label: opt,
            };
          });
          setOptions(res);
        }
      })
      .catch((err) => {});
  };
  const handleChange = (selected) => {
    setOptionSelected(selected);
    const values = selected.map((option) => option.value);
    handleOnChange(name, values);
  };
  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? "#446EB8"
        : state.isSelected
        ? "#446EB8"
        : provided.backgroundColor,
    }),
  };
  return (
    <div className="select--checkbox">
      {/* <select name={name} id={name} onChange={handleOnChange}>
        <option value="">--select--</option>
        {options?.map((opt, i) => {
          return <option>{opt}</option>;
        })}
      </select> */}
      <SelectWithCheckbox
        options={options}
        isMulti={filterValue !== "make" ? true : false}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{ Option, MultiValue }}
        onChange={handleChange}
        allowSelectAll={false}
        value={optionSelected}
        styles={customStyles}
      />
    </div>
  );
};

export default SelectFromApi;
