import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { componentSystemHistoryEndPoints } from "../../../services/service-constants";
import { getComponentSystemHistoryTableData } from "../api";
import Pagination from "../../common/Pagination";
import "./index.scss";
import { useLocation } from "react-router";
import FeedBack from "../../common/ErrorComponents/Feedback";
import NotAuthorized from "../../common/ErrorComponents/NotAuthorized";
import LottieLoad from "../../common/ErrorComponents/LottieLoad";

const ComponentSystemHistoryPrognostics = () => {
  const location = useLocation();
  const [initialTableData, setInitialTableData] = useState(null);

  const [currentPage, setCurrentPage] = useState();

  const [isPageFirst, setIsPageFirst] = useState(false);

  const [isPageLast, setIsPageLast] = useState(false);

  const [noDataFound, setNoDataFound] = useState(false);

  const vin_Number = useSelector((state) => state.mainReducer.vin_Number);

  const [notAuthorized, setNotAuthorized] = useState(false);

  const [apiError, setApiError] = useState(false);

  const [pagenation, setPagenation] = useState({
    totalpages: 0,
    size: 0,
    currentPageNo: 0,
  });

  const onPageChanged = (val) => {
    const nextPage = val;
    getInitialData({
      page: nextPage,
    });
  };

  const getInitialData = async (payload) => {
    const { page, vinNumber, subSystem } = payload;
    const { code, data } = await getComponentSystemHistoryTableData({
      page: page,
      url: `${componentSystemHistoryEndPoints.prognostics}`,
      vinNumber: vinNumber,
      subSystem: subSystem,
      type: "prognostics",
    });
    if (code === 200 && data) {
      setNoDataFound(false);
      setInitialTableData(data?.content);
      setCurrentPage(data?.pageable?.pageNumber);
      setIsPageFirst(data?.first);
      setIsPageLast(data?.last);
      setPagenation({
        totalpages: data?.totalPages,
        size: data?.size,
        currentPageNo: data?.pageable?.pageNumber,
      });
    } else if (code === 401) {
      setNotAuthorized(true);
    } else {
      setNoDataFound(true);
      setApiError(true);
    }
  };

  useEffect(() => {
    if (location?.state?.subSystem) {
      getInitialData({
        page: 0,
        vinNumber: vin_Number,
        subSystem: location.state.subSystem,
      });
    } else {
      setNoDataFound(true);
    }
  }, []);

  if (notAuthorized) {
    return <NotAuthorized />;
  }

  if (apiError) {
    return <FeedBack />;
  }

  if (initialTableData === null) {
    return <LottieLoad />;
  }

  return (
    <>
      {noDataFound && <div className="no-data-found"> No Data Found </div>}
      {!noDataFound && (
        <>
          <table className="component-system-history-prognostics-table">
            <thead>
              <tr>
                <th>DTC</th>
                <th>Root Cause</th>
                <th>RUL(Miles)</th>
              </tr>
            </thead>
            <tbody>
              {initialTableData?.length &&
                initialTableData.map((item, index) => (
                  <tr key={index}>
                    <td>{item?.dtcCode || "-"}</td>
                    <td>{item?.name || "-"}</td>
                    <td>{item?.rulMiles || "-"}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          {pagenation.totalpages > 1 && (
            <div className="pagination-container">
              <Pagination
                pageCount={pagenation.totalpages}
                pageRange={pagenation.size}
                onPageChanged={onPageChanged}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ComponentSystemHistoryPrognostics;
