import { Checkbox } from "../../../../components/common/Checkbox/Checkbox";
import Filter from "../../../../components/Filter/Filter";
import React, { useEffect, useState } from "react";
import {
  VPHealthCheck,
  VehiclePopulationDashBoard,
} from "../../../../services/service-constants";
import { mainAxiosInstance } from "../../../../services/service";
import { useSelector } from "react-redux";
import Feedback from "../../../../components/common/ErrorComponents/Feedback";
import NotAuthorized from "../../../../components/common/ErrorComponents/NotAuthorized";
import ChartLoader from "../../../../components/common/ErrorComponents/ChartLoader";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../../components/common/Pagination";
import { DualLine } from "../../components/graphs/DualLine";

const ReportForDiagnostic = () => {
  const [chartData, setChartData] = useState([]);
  const [isChecked, setIsCheckbox] = useState(true);
  const [selectAll, setSelectAll] = useState(true);
  const [showFilter, setShowFilter] = useState(true);
  const [noDataFound, setNoDataFound] = useState(true);
  const [errorMsg, setErrorMsg] = useState(true);
  const [authenticationMsg, setAuthenticationMsg] = useState(false);
  const dataStore = [];
  const [showChartLoader, setShowChartLoader] = useState(false);

  const valueMap = {
    timeToStartEngineResponseDto: "avgTimeToStartEngine",
    engineOilConsumptionResponseDto: "avgAverageFuelConsumption",
    averageElectricVehicleBatteryConsumptionResponse:
      "avgAverageElectricVehicleBatteryConsumption",
    averageFuelConsumptionResponseDto: "avgGalPer100Miles",
    distanceBetweenParticulateFilterRegenerationResponse:
      "avgDistanceBetweenParticulateFilterReg",
    keyOffBatteryDrainResponseDto: "avgBatteryEnergyConsumptionWH",
    oilChangeIntervalResponseDto: "avgOilChangeInterval",
    ureaConsumptionResponseDto: "avgUreaConsumption",
  };

  const filterOptions = [
    {
      displayname: "Time To Start Engine",
      id: "timeToStartEngineResponseDto",
      isChecked: true,
    },
    {
      displayname: "Engine Oil Consumption",
      id: "engineOilConsumptionResponseDto",
      isChecked: true,
    },
    {
      displayname: "Average Electric Vehicle Battery Consumption",
      id: "averageElectricVehicleBatteryConsumptionResponse",
      isChecked: true,
    },
    {
      displayname: "Average Fuel Consumption",
      id: "averageFuelConsumptionResponseDto",
      isChecked: true,
    },
    {
      displayname: "Distance Between Particulate Filter Regeneration",
      id: "distanceBetweenParticulateFilterRegenerationResponse",
      isChecked: true,
    },
    {
      displayname: "key Off Battery Drain",
      id: "keyOffBatteryDrainResponseDto",
      isChecked: true,
    },
    {
      displayname: "Oil Change Interval",
      id: "oilChangeIntervalResponseDto",
      isChecked: true,
    },
    {
      displayname: "Urea Consumption",
      id: "ureaConsumptionResponseDto",
      isChecked: true,
    },
  ];

  const [filterData, setFilterData] = useState([...filterOptions]);

  const setSelectAllvalue = (data) => {
    const checkAllSelected = data.filter((el) => el.isChecked === false);
    if (checkAllSelected.length > 0) {
      setSelectAll(false);
    } else {
      setSelectAll(true);
    }
  };

  const handleFilterClick = (val) => {
    const newFilterData = filterData.map((el) => {
      if (el.displayname === val.displayname) {
        el.isChecked = !el.isChecked;
      }
      return el;
    });

    setFilterData([...newFilterData]);
    setSelectAllvalue(newFilterData);
  };

  const prepareChartData = (response) => {
    let mainData = [];
    Object.keys(response).map((key) => {
      let firstResData = [];
      let secondResData = [];
      let resKey = valueMap[key];
      let graphLabels = [];
      let categories = [];
      if (response[key] !== null) {
        response[key].responseDto?.map((data) => {
          firstResData.push({ y: data?.yaxisAvgValue });
          categories.push(data?.odometer);
        });
      } else {
        // firstResData.push({ y: 0 });
        firstResData.push([]);
        categories.push(0);
      }

      if (response[key] !== null) {
        response[key].remainingUsefulLifeDTOList?.map((data) => {
          secondResData.push({ y: data?.avgRul });
        });
      } else {
        // secondResData.push({ y: 0 });
        secondResData.push([]);
      }

      if (response[key] !== null) {
        graphLabels.push({
          xAxisLabel: response[key].thresholdDTO?.xAxisName,
          y1AxisLabel: response[key].thresholdDTO?.yAxisName,
          y2AxisLabel: "RUL(Miles)",
          graphTitle: response[key].thresholdDTO?.graphLabel,
          thresholdValue: response[key].thresholdDTO?.threshold,
        });
      } else {
        graphLabels.push({
          xAxisLabel: "",
          y1AxisLabel: "",
          y2AxisLabel: "RUL(Miles)",
          graphTitle: "",
        });
      }

      mainData.push({
        name: key,
        data: [
          {
            categories: categories,
            graphLabels: graphLabels,
            data: [
              {
                type: "line",
                data: secondResData,
                color: "#6FAC4E",
                pointWidth: 15,
                name: "P1",
                marker: false,
                yAxis: 1,
              },

              {
                type: "line",
                data: firstResData,
                color: "#0574a6",
                pointWidth: 15,
                name: "P2",
                marker: false,
              },
            ],
          },
        ],
      });
    });
    setChartData(mainData);
  };

  const AllSelect = (val) => {
    const getUpdatedfilterData = filterData.map((el) => {
      return { ...el, isChecked: val };
    });
    setFilterData(...[getUpdatedfilterData]);
    setSelectAll(val);
  };

  useEffect(() => {
    getPageData();
  }, []);

  const getPageData = () => {
    setShowChartLoader(true);
    const url = `${VPHealthCheck.digital_twin}/${0}?rangeSize=${100}`;
    const selectedData = JSON.parse(localStorage.getItem("selectedData"));
    const selectedDiagonsticsData = JSON.parse(
      localStorage.getItem("selectedDiagonosticsData")
    );
    const FilterSelectedValues = {
      ...selectedData,
      ...selectedDiagonsticsData,
    };
    if (Object.keys(FilterSelectedValues).length === 0) {
      mainAxiosInstance
        .post(url)
        .then((response) => {
          if (response.status === 200) {
            setNoDataFound(false);
            setErrorMsg(true);
            prepareChartData(response.data);
            setShowChartLoader(false);
          }
          if (response.status === 401) {
            setErrorMsg(response.data?.details || "You are not authorized.");
            setAuthenticationMsg(true);
          }
        })
        .catch((err) => {
          setNoDataFound(true);
          setErrorMsg(false);
          setShowChartLoader(true);
        });
    } else {
      mainAxiosInstance
        .post(url, FilterSelectedValues)
        .then((response) => {
          if (response.status === 200) {
            setNoDataFound(false);
            setErrorMsg(true);
            prepareChartData(response.data);
          }
          if (response.status === 401) {
            setErrorMsg(response.data?.details || "You are not authorized.");
            setAuthenticationMsg(true);
          }
        })
        .catch((err) => {
          setNoDataFound(true);
          setErrorMsg(false);
        });
    }
  };

  const getDataForBarChart = (element) => {
    const barChartData = chartData?.filter((el) => el.name === element.id);
    dataStore.push(barChartData?.[0]?.data);
    return barChartData?.[0]?.data;
  };

  return (
    <div
      className="page-wrapper vehicle-health-check"
      style={{ backgroundColor: "#EEEEEE" }}
    >
      <div
        className="flex page-content-header--filter vp-health-filter"
        style={{ paddingTop: "30px" }}
      >
        {showFilter === true && (
          <Filter data={AllSelect} selectAll={selectAll}>
            {filterData.map((el) => (
              <Checkbox
                title={el.displayname}
                handleInputClick={() => handleFilterClick(el)}
                alignProp={{
                  borderTop: "0.5px solid #C4C4C4",
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "10px",
                  alignItems: "baseline",
                  flexDirection: "row-reverse",
                }}
                checkboxStyle={{ marginRight: "10px" }}
                isChecked={el.isChecked}
              />
            ))}
          </Filter>
        )}
      </div>
      <div className="legend-container">
        {/* <div className="health-check-checkbox">
          <Checkbox
            title={"P1 : Filter 1"}
            key={"P1"}
            isChecked={isChecked}
          // handleInputClick={handleCheckbox}
          />
        </div> */}
      </div>
      {!noDataFound &&
        filterData.map(
          (el) =>
            el.isChecked && (
              <DualLine
                id={el.id}
                data={getDataForBarChart(el)}
                title={el.displayname}
                fromVpUp={true}
              />
            )
        )}

      {noDataFound && !authenticationMsg && errorMsg && (
        <div>
          <ChartLoader />
        </div>
      )}

      {noDataFound &&
      !errorMsg &&
      !showChartLoader &&
      dataStore?.length === 0 ? (
        <div>
          <Feedback />
        </div>
      ) : !showChartLoader && dataStore?.length === 0 && !selectAll ? (
        <div>
          <Feedback />
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default ReportForDiagnostic;
