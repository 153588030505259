import * as React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Link, Outlet, useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/common/Breadcrumb/Breadcrumbs";
import routes from "../../utils/constants";
import { useEffect, useState } from "react";

function LinkTab(props) {
  return <Tab component={Link} {...props} className="tab-link" />;
}

export default function TabLayout({
  tabLink = [],
  submenuTabLink = [],
  state = "",
  shouldIncludeBreadcrumbs = true,
}) {
  const location = useLocation();
  const pathName = location.pathname;
  const [isExpanded, setExpanded] = useState(true);
  // const index = tabLink.findIndex((obj) =>
  //   pathName.includes(obj.to)
  //     ? true
  //     : pathName.slice(35).includes(obj.to.slice(2))
  // );
  const mainTabIndex = tabLink?.findIndex((obj) =>
    pathName.includes(obj.to)
      ? true
      : pathName.slice(35).includes(obj.to.slice(2))
  );

  const submenuTabIndex = submenuTabLink?.findIndex((obj) =>
    pathName.includes(obj.to)
      ? true
      : pathName.slice(35).includes(obj.to.slice(2))
  );

  const tabIndex =
    submenuTabIndex !== -1
      ? submenuTabIndex
      : mainTabIndex === -1
      ? 0
      : mainTabIndex;

  return (
    <div className="tabs-container-box">
      <Box>
        <Tabs
          value={tabIndex}
          aria-label="nav tabs example"
          className={
            tabLink?.length === 0 && submenuTabLink?.length === 0
              ? "tabheight--hidden"
              : ""
          }
        >
          {tabLink?.map((nav) => {
            return <LinkTab label={nav.label} to={nav.to} state={state} />;
          })}
          {submenuTabLink?.map((nav) => {
            return <LinkTab label={nav.label} to={nav.to} state={state} />;
          })}
        </Tabs>
        {pathName === "/vehicle-overview/information" ||
        pathName === "/vehicle-overview/notification" ||
        pathName === "/vehicle-health/diagnostics/on-board" ||
        pathName === "/maintenance-overview/routine-maintenance" ||
        pathName === "/maintenance-overview/diagnostics" ||
        pathName === "/maintenance-overview/prognostics" ||
        pathName === "/vehicle-health/diagnostics/remote-testing" ||
        pathName ===
          "/vehicle-health/diagnostics/remote-testing/performance-profile" ||
        pathName === "/vehicle-health/diagnostics/vibrational-analytics" ||
        pathName ===
          "/vehicle-health/diagnostics/vibrational-analytics/engine/combustion" ? (
          <Box
            className={
              tabLink?.length === 0 && submenuTabLink?.length === 0
                ? "tab-outlet tab-outlet--notabs"
                : "tab-outlet"
            }
            style={{ backgroundColor: "#ffffff" }}
          >
            <div className={` ${isExpanded ? "" : "expand"}`}>
              <Breadcrumbs routes={routes} />
            </div>
            <Outlet />
          </Box>
        ) : (
          <>
            <Box
              className={
                tabLink?.length === 0 && submenuTabLink?.length === 0
                  ? "tab-outlet tab-outlet--notabs"
                  : "tab-outlet"
              }
            >
              <div className={` ${isExpanded ? "" : "expand"}`}>
                <Breadcrumbs
                  routes={routes}
                  shouldIncludeBreadcrumbs={shouldIncludeBreadcrumbs}
                />
              </div>
              <Outlet />
            </Box>
          </>
        )}
      </Box>
    </div>
  );
}
