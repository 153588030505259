import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../VehiclePopulation.scss";
import { useLocation } from "react-router-dom";
import SingleChartLoader from "../../../../../components/common/ErrorComponents/SingleChartLoader";
import Feedback from "../../../../../components/common/ErrorComponents/Feedback";
import {
  ServiceConfig,
  VehiclePopulationDashBoard,
} from "../../../../../services/service-constants";
import "../../../VehiclePopulation.scss";
import { mainAxiosInstance } from "../../../../../services/service";
import DualColumnGraph from "../../../../../components/common/DualColumnGraph/DualColumnGraph";
import MyMap from "../../../../../components/common/MapGraph/MyMap";
import mapDataApi from "../../../../../components/common/MapGraph/MapApi";
import toggleLeftIcon from "../../../../../assets/compareCardLeftIcon.svg";
import toggleRightIcon from "../../../../../assets/compareCardRightIcon.svg";
import stripBarIcon from "../../../../../assets/StripBarGraphIcon.png";
import planBarIcon from "../../../../../assets/PlanBarGraphIcon.png";
import moment from "moment";
import Select from "../../../../..//components/common/Select/Select";
import Datepicker from "../../../../../components/Datepicker/datepicker";
import { Flag_Data } from "../../../../../store/actions/fetchActions";
import Pagination from "../../../../../components/common/Pagination";

const CompareWithFilter = () => {
  const [noDataFound, setNoDataFound] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [data, setData] = useState(null);
  const [graphName, setGraphName] = useState("");
  const dispatch = useDispatch();
  const url = window.location.href;
  const urlParams = new URLSearchParams(url.split("?")[1]);
  const location = useLocation();
  const [containerName, setContainerName] = useState(0);
  const [diagnosesState, setDiagnosesState] = useState(
    location.pathname.includes("diagnostics") ? 0 : 1
  );
  const firstfilter = useSelector((state) => state.mainReducer.first_filter);
  const secondfilter = useSelector((state) => state.mainReducer.second_filter);
  const [mapData, setMapData] = useState(null);
  const [secondGeoMapData, setSecondGeoMapData] = useState(null);
  const [commonRegionButton, setCommonRegionButton] = useState(false);
  const [defaultInterval, setDefaultInterval] = useState("Miles");
  const [defaultAllInterval, setDefaultAllInterval] = useState("All");
  const interval = ["Miles", "Date"];
  const rulInterval = ["Miles", "Hours", "Engine Run Time", "Key starts"];
  const allInterval = ["All", "P1", "P2"];
  const [pagination, setPagination] = useState({});
  const [showInput, setShowInput] = useState(true);
  const [showDatepicker, setShowDatepicker] = useState(false);
  const [upperLimitData, setUpperLimitData] = useState(5000);
  const [lowerLimitData, setLowerLimitData] = useState(1);
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndtDate, setSelectedEndDate] = useState(new Date());
  const [showMetric, setShowMetric] = useState(false);
  const [commonRegionGeoMapData, setCommonRegionGeoMapData] = useState(null);
  const [showRulMetric, setshowRulMetric] = useState(false);
  const [showChartLoader, setShowChartLoader] = useState(false);
  const [pageSize, setPageSize] = useState(0);
  const [showFeedback, setShowFeedback] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const containerId = urlParams.get("key");
    setContainerName(parseInt(containerId));
    if (location?.state) {
      setDiagnosesState(location?.state?.diagnosesState);
    } else {
      setDiagnosesState(location.pathname.includes("diagnostics") ? 0 : 1);
    }
  }, [location]);

  useEffect(() => {
    if (diagnosesState !== null) {
      getPageData();
    }
  }, [
    diagnosesState,
    defaultInterval,
    upperLimitData,
    lowerLimitData,
    selectedStartDate,
    selectedEndtDate,
    pageSize,
  ]);

  const getChartData = (data) => {
    const allGraphNames = data?.informationDto?.graphName;
    const paginationData = data?.pageResponse;
    setPagination(paginationData);
    if (
      data?.pageResponse !== null &&
      Object.keys(paginationData)?.length === 0
    ) {
      setNoDataFound(true);
    } else {
      setNoDataFound(false);
      setShowFeedback(false);
    }
    setGraphName(allGraphNames);
    let graphData = "";
    var xAxisNames = [];
    if (
      data?.pageResponse !== null &&
      Object.keys(data?.pageResponse).length > 0
    ) {
      if (containerName === 0 || containerName === 1) {
        graphData = data.pageResponse;
        var graphInformation = data.informationDto;
        const graphValues = [];

        const FirstColumnData = Object.keys(graphData).map((obj) => {
          const FirstColumnKey = obj;
          const FirstColumnArray = graphData[FirstColumnKey];
          return FirstColumnArray[0];
        });

        const SecondColumn = Object.keys(graphData).map((obj) => {
          const FirstColumnKey = obj;
          const FirstColumnArray = graphData[FirstColumnKey];
          return FirstColumnArray[1];
        });

        const xAxisNames = () => {
          const key = Object.keys(graphData);
          return key;
        };

        switch (defaultAllInterval) {
          case "All": {
            graphValues.push({
              graphInformation: graphInformation,
              FirstColumnData: FirstColumnData,
              SecondColumn: SecondColumn.filter((val) => val !== 0),
              xAxisNames: xAxisNames(),
            });
            break;
          }
          case "P1": {
            graphValues.push({
              graphInformation: graphInformation,
              FirstColumnData: FirstColumnData.filter((val) => val !== 0),
              SecondColumn: [],
              xAxisNames:
                FirstColumnData.filter((val) => val !== 0).length > 1
                  ? xAxisNames()
                  : null,
            });
            break;
          }
          case "P2": {
            graphValues.push({
              graphInformation: graphInformation,
              FirstColumnData: [],
              SecondColumn: SecondColumn.filter((val) => val !== 0),
              xAxisNames: SecondColumn.filter((val) => val !== 0)
                ? xAxisNames()
                : null,
            });
            break;
          }
          default: {
            graphValues.push({
              graphInformation: graphInformation,
              FirstColumnData: FirstColumnData.filter((val) => val !== 0),
              SecondColumn: SecondColumn.filter((val) => val !== 0),
              xAxisNames: xAxisNames(),
            });
          }
        }
        setChartData(graphValues);
      } else if (
        (containerName != 0 || containerName != 1) &&
        data.pageResponse?.content?.length > 0
      ) {
        graphData = data.pageResponse?.content;
        var graphInformation = data.informationDto;
        const graphValues = [];

        const FirstColumnData = graphData.map((obj) => {
          const FirstColumnKey = Object.keys(obj)[0];
          const FirstColumnArray = obj[FirstColumnKey];
          return FirstColumnArray[0];
        });

        const SecondColumn = graphData.map((obj) => {
          const FirstColumnKey = Object.keys(obj)[0];
          const FirstColumnArray = obj[FirstColumnKey];
          return FirstColumnArray[1];
        });

        if (containerName === 12 || containerName === 13) {
          // xAxisNames = graphData.flatMap((obj) =>
          //   Object.values(obj).flatMap((versionArray) =>
          //     versionArray.map((item) => item.version)
          //   )
          // );

          switch (defaultAllInterval) {
            case "All": {
              xAxisNames = graphData.map((obj) => {
                const key = Object.keys(obj)[0];
                return key;
              });
              break;
            }
            case "P1": {
              xAxisNames = FirstColumnData.map((obj) => {
                const key = Object.assign(
                  [Object.values(obj)[0]].filter((keys) => keys !== null)
                );
                if (key.length > 0) {
                  return key[0];
                } else {
                  return "";
                }
              });
              break;
            }
            case "P2": {
              xAxisNames = SecondColumn.map((obj) => {
                const key = Object.assign(
                  [Object.values(obj)[0]].filter((keys) => keys !== null)
                );
                if (key.length > 0) {
                  return key[0];
                } else {
                  return "";
                }
              });
              break;
            }
            default: {
              xAxisNames = graphData.map((obj) => {
                const key = Object.keys(obj)[0];
                return key;
              });
            }
          }

          // xAxisNames = graphData.map((obj) => {
          //   const key = Object.keys(obj)[0];
          //   return key;
          // });
        } else {
          xAxisNames = graphData.map((obj) => {
            const key = Object.keys(obj)[0];
            return key;
          });
        }

        switch (defaultAllInterval) {
          case "All": {
            graphValues.push({
              graphInformation: graphInformation,
              FirstColumnData: FirstColumnData.filter((val) => val !== 0),
              SecondColumn: SecondColumn.filter((val) => val !== 0),
              xAxisNames: xAxisNames,
            });
            break;
          }

          case "P1": {
            graphValues.push({
              graphInformation: graphInformation,
              FirstColumnData: FirstColumnData.filter((val) => val !== 0),
              SecondColumn: [],
              xAxisNames: xAxisNames,
            });
            break;
          }
          case "P2": {
            graphValues.push({
              graphInformation: graphInformation,
              FirstColumnData: [],
              SecondColumn: SecondColumn.filter((val) => val !== 0),
              xAxisNames: xAxisNames,
            });
            break;
          }

          default: {
            graphValues.push({
              graphInformation: graphInformation,
              FirstColumnData: FirstColumnData.filter((val) => val !== 0),
              SecondColumn: SecondColumn.filter((val) => val !== 0),
              xAxisNames: xAxisNames,
            });
          }
        }

        setChartData(graphValues);
      } else {
        setChartData(null);
        setNoDataFound(true);
      }
    } else {
      setChartData(null);
      // setNoDataFound(true);
    }
  };

  const fetchMapData = async () => {
    const mapDataRes = await mapDataApi.getWorld();
    setMapData(mapDataRes.data);
  };

  useEffect(() => {
    fetchMapData();
  }, []);

  useEffect(() => {
    if (location.search === "?key=20") {
      setshowRulMetric(true);
      setDefaultInterval("Miles");
      setUpperLimitData(5000);
      setLowerLimitData(1);
      setShowInput(true);
      setShowDatepicker(false);
    } else {
      setshowRulMetric(false);
      setShowDatepicker(false);
      setShowInput(true);
      setDefaultInterval("Miles");
      setUpperLimitData(5000);
      setLowerLimitData(1);
    }
  }, [location]);

  const prepareChartData = (data) => {
    const transformedData = Object.entries(data).map(([name, value]) => ({
      name,
      flag: value.informationDto?.flag,
    }));
    const FlagData = transformedData
      .filter((item) => item.flag === true)
      .map((item) => item.name);

    dispatch(Flag_Data(FlagData));
    var pageData = {};
    if (containerName === 0) {
      pageData = data.topTenDtcsCountComparisonDto;
    } else if (containerName === 1) {
      pageData = data.topTenNonDtcsCountComparisonDto;
    } else if (containerName === 2) {
      pageData = data.percentageOfPopulationSettingDtcsComparisonDto;
    } else if (containerName === 3) {
      pageData = data.dtcWarrantyScoreCardComparisonDto;
    } else if (containerName === 4) {
      pageData = data.geospatialMappingComparisonDto;
    } else if (containerName === 5) {
      pageData = data.makeCountComparisonDto;
    } else if (containerName === 6) {
      pageData = data.modelCountComparisonDto;
    } else if (containerName === 7) {
      pageData = data.modelYearComparisonDto;
    } else if (containerName === 8) {
      pageData = data.manufacturingPlantComparisonDto;
    } else if (containerName === 9) {
      pageData = data.buildDateComparisonDto;
    } else if (containerName === 10) {
      pageData = data.powerPackComparisonDto;
    } else if (containerName === 11) {
      pageData = data.subSystemComparisonDto;
    } else if (containerName === 12) {
      pageData = data.softwareVersionComparisonDto;
    } else if (containerName === 13) {
      pageData = data.calibrationVersionComparisonDto;
    } else if (containerName === 14) {
      pageData = data.dtcCountComparisonDto;
    } else if (containerName === 15) {
      pageData = data.temperatureComparisonDto;
    } else if (containerName === 16) {
      pageData = data.altitudeComparisonDto;
    } else if (containerName === 17) {
      pageData = data.systemStateComparisonDto;
    } else if (containerName === 18) {
      pageData = data.operatingStateComparisonDto;
    } else if (containerName === 19) {
      pageData = data.customerComplaintComparisonDto;
    } else if (containerName === 20) {
      pageData = data.rulComparisonDto;
    }
    if (containerName === 4) {
      getGeoSpatialMapData(pageData);
    } else {
      getChartData(pageData);
    }
  };

  const getGeoSpatialMapData = (val) => {
    const allGraphNames = val?.informationDto?.graphName;
    setGraphName(allGraphNames);
    setNoDataFound(false);
    const FirstMapData = val.pageResponse[0];
    const firstformData = FirstMapData.map((mapping) => ({
      name: mapping.state[0].name,
      totalCount: mapping.state[0].totalCount,
      details: mapping.state[0].details.map(
        (detail) => `${detail.dtcCode}(${detail.noOfOccurrences})`
      ),
    }));

    const SecondMapData = val?.pageResponse[1];
    const SecondformData = SecondMapData?.map((mapping) => ({
      name: mapping.state[0].name,
      totalCount: mapping.state[0].totalCount,
      details: mapping.state[0].details.map(
        (detail) => `${detail.dtcCode}(${detail.noOfOccurrences})`
      ),
    }));

    const commonRegionsData = val.geospatialMappingCommonRegionsDtos;
    const commonRegionsGraphs = commonRegionsData?.map((mapping) => ({
      name: mapping.state[0].name,
      totalCount: mapping.state[0].totalCount,
      details: mapping.state[0].details.map(
        (detail) => `${detail.dtcCode}(${detail.noOfOccurrences})`
      ),
    }));

    setChartData(firstformData);
    setSecondGeoMapData(SecondformData);
    setCommonRegionGeoMapData(commonRegionsGraphs);
  };

  const getPageData = (val) => {
    setShowChartLoader(true);
    const url = `${VehiclePopulationDashBoard.compare_with_card}/${diagnosesState}?page=${pageSize}&size=10`;
    let params = {};
    if (showRulMetric) {
      switch (defaultInterval) {
        case "Miles": {
          params = {
            fromRulMiles: parseInt(lowerLimitData),
            toRulMiles: parseInt(upperLimitData),
            rul: "by_miles",
          };
          break;
        }

        case "Engine Run Time": {
          params = {
            fromRulEngineRunTime: parseInt(lowerLimitData),
            toRulEngineRunTime: parseInt(upperLimitData),
            rul: "by_engine_runtime",
          };
          break;
        }

        case "Hours": {
          params = {
            fromRulHours: parseInt(lowerLimitData),
            toRulHours: parseInt(upperLimitData),
            rul: "by_hours",
          };
          break;
        }

        case "Key starts": {
          params = {
            fromRulKeyStarts: parseInt(lowerLimitData),
            toRulKeyStarts: parseInt(upperLimitData),
            rul: "by_key_starts",
          };
          break;
        }

        default: {
          params = {
            fromRulMiles: parseInt(lowerLimitData),
            toRulMiles: parseInt(upperLimitData),
            rul: "by_miles",
          };
        }
      }
    } else {
      switch (defaultInterval) {
        case "Miles": {
          // delete paramData.type;
          params = {
            fromMiles: lowerLimitData,
            toMiles: upperLimitData,
            type: "by_miles",
          };
          break;
        }

        case "Date": {
          // delete paramData.type;
          params = {
            fromDate: moment(selectedStartDate).format("yyyy-MM-DD"),
            toDate: moment(selectedEndtDate).format("yyyy-MM-DD"),
            type: "by_date",
          };
          break;
        }

        default: {
          params = {
            fromMiles: lowerLimitData,
            toMiles: upperLimitData,
            type: "by_miles",
          };
        }
      }
    }

    let firstParam = { ...firstfilter, ...params };
    let secondParam = { ...secondfilter, ...params };
    let paramData = [firstParam, secondParam];

    mainAxiosInstance
      .post(url, paramData)
      .then((response) => {
        if (response.status === 200) {
          setNoDataFound(false);
          prepareChartData(response.data);
          setData(response.data);
          setShowChartLoader(false);
        }
        if (response.status === 401) {
          //setApiError(response.data?.details || "You are not authorized.");
          setNoDataFound(true);
          setShowChartLoader(false);
        }
      })
      .catch((err) => {
        //setApiError(err.response.data?.details || "You are not authorized.");
        setNoDataFound(true);
        setShowChartLoader(false);
      });
  };

  useEffect(() => {
    setPageSize(0);
    getPageData();
  }, [containerName]);

  useEffect(() => {
    if (data) {
      prepareChartData(data);
    }
  }, [showRulMetric, data, defaultAllInterval]);

  //   useEffect(() => {
  //   if (data) {
  //     prepareChartData(data);

  //   }
  // }, [containerName]);

  useEffect(() => {
    setDefaultAllInterval("All");
    if (containerName === 0 || containerName === 1 || containerName === 20) {
      setShowMetric(true);
    } else {
      setShowMetric(false);
    }
  }, [containerName]);

  const handleOnStartDateChange = (dates) => {
    const startDate = moment(dates).format("yyyy-MM-DD");
    setSelectedStartDate(startDate);
  };

  const handleOnEndDateChange = (dates) => {
    const endDate = moment(dates).format("yyyy-MM-DD");
    setSelectedEndDate(endDate);
  };

  const handleLowerRulInput = (event) => {
    const { value } = event.target;
    const letters = /^-?\d*\.?\d*$/.test(value);
    if (letters) {
      setLowerLimitData(value);
    }
  };

  const handleUpperRulInput = (event) => {
    const { value } = event.target;
    const letters = /^-?\d*\.?\d*$/.test(value);
    if (letters) {
      setUpperLimitData(value);
    }
  };

  const handleIntervalOptionClick = (val) => {
    setDefaultInterval(val);
    switch (val) {
      case "Miles":
        setShowInput(true);
        setShowDatepicker(false);
        setLowerLimitData(1);
        setUpperLimitData(5000);
        break;

      case "Date":
        setShowInput(false);
        setShowDatepicker(true);
        setSelectedStartDate(new Date());
        setSelectedEndDate(new Date());
        break;

      default:
        setShowInput(true);
        setShowDatepicker(false);
        setLowerLimitData(1);
        setUpperLimitData(5000);
        break;
    }
  };

  const rulHandleIntervalOptionClick = (val) => {
    setDefaultInterval(val);
    switch (val) {
      case "Miles":
        setShowInput(true);
        setShowDatepicker(false);
        setLowerLimitData(1);
        setPageSize(0)
        setUpperLimitData(5000);
        break;

      case "Hours":
        setShowInput(true);
        setShowDatepicker(false);
        setLowerLimitData(0);
        setPageSize(0)
        setUpperLimitData(1);
        break;

      case "Engine Run Time":
        setShowInput(true);
        setShowDatepicker(false);
        setLowerLimitData(0);
        setPageSize(0)
        setUpperLimitData(1);
        break;

      case "Key starts":
        setShowInput(true);
        setShowDatepicker(false);
        setLowerLimitData(1);
        setPageSize(0)
        setUpperLimitData(5000);
        break;

      default:
        setShowInput(true);
        setShowDatepicker(false);
        setLowerLimitData(1);
        setUpperLimitData(5000);
        break;
    }
  };

  const handleAllIntervalOptionClick = (value) => {
    setDefaultAllInterval(value);
  };

  const onPageChanged = (val) => {
    setPageSize(val);
  };

  return (
    <>
      <div className="vehicle-dashboard-middle-container">
        {containerName != 4 && (
          <div>
            <div className="legend-wrapper">
              <div className="select-container-all-compare">
                <Select
                  options={allInterval}
                  defaultSelected={defaultAllInterval}
                  handleOptionClick={(val) => handleAllIntervalOptionClick(val)}
                  secondaryContainerClass="top-10-input-box tab"
                  containerClass="top-10-dtc"
                />
              </div>
              <div className="color-wrapper">
                {containerName === 12 || containerName === 13 ? (
                  // <img src={stripBarIcon} alt="stripBar"></img>
                  <img
                    src={planBarIcon}
                    alt="stripBar"
                    style={{ height: "14px" }}
                  ></img>
                ) : (
                  <span
                    className="color-box"
                    style={{
                      backgroundColor: "#041F3E",
                      marginRight: "15px",
                    }}
                  ></span>
                )}
                <span class="color-legend" style={{ marginRight: "15px" }}>
                  <b>P1</b>
                </span>
                {containerName === 12 || containerName === 13 ? (
                  <img src={stripBarIcon} alt="stripBar"></img>
                ) : (
                  <span
                    className="color-box"
                    style={{
                      backgroundColor: "#0574A6",
                      marginRight: "15px",
                    }}
                  ></span>
                )}
                <span class="color-legend">
                  <b>P2</b>
                </span>
              </div>
            </div>

            {showMetric ? (
              <div className="select-container-metrics-compare">
                {showRulMetric ? (
                  <Select
                    options={rulInterval}
                    defaultSelected={defaultInterval}
                    handleOptionClick={(val) =>
                      rulHandleIntervalOptionClick(val)
                    }
                    secondaryContainerClass="top-10-input-box tab2"
                    containerClass="compare-rul-top-10-dtc"
                  />
                ) : (
                  <Select
                    options={interval}
                    defaultSelected={defaultInterval}
                    handleOptionClick={(val) => handleIntervalOptionClick(val)}
                    secondaryContainerClass="top-10-input-box tab"
                    containerClass="top-10-dtc"
                  />
                )}

                {showInput && (
                  <>
                    <input
                      type="text"
                      className="Dtc-value-input"
                      placeholder={`Enter Lower Limit`}
                      value={lowerLimitData}
                      name="lowerLimitData"
                      onChange={(e) => handleLowerRulInput(e)}
                      maxLength={10}
                    />
                    <input
                      type="text"
                      className="Dtc-value-input"
                      placeholder={`Enter Upper Limit`}
                      value={upperLimitData}
                      name="upperLimitData"
                      onChange={(e) => handleUpperRulInput(e)}
                      maxLength={10}
                    />
                  </>
                )}
                {showDatepicker && (
                  <div
                    style={{
                      display: "flex",
                      position: "relative",
                      bottom: "5px",
                    }}
                  >
                    <Datepicker
                      onChange={handleOnStartDateChange}
                      classContainer="top-10-dtc-date"
                      secondaryClassContainer="input-value-top-10-dtc"
                    />
                    <Datepicker
                      onChange={handleOnEndDateChange}
                      classContainer="top-10-dtc-date"
                      secondaryClassContainer="input-value-top-10-dtc"
                    />
                  </div>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        )}

        {(noDataFound && !showChartLoader) || !upperLimitData ? (
          <div>
            <Feedback feedbackClassName="feedback" />
          </div>
        ) : !noDataFound && showChartLoader ? (
          <div>
            <SingleChartLoader />;
          </div>
        ) : containerName === 4 ? (
          <>
            {!commonRegionButton ? (
              <>
                <div className="on-off-button">
                  <div style={{ marginRight: "10px", fontSize: "13px" }}>
                    population 1 vs population 2
                  </div>
                  <img
                    src={toggleLeftIcon}
                    alt=""
                    onClick={() => setCommonRegionButton(!commonRegionButton)}
                  />
                </div>

                <div className="comparison-geomap" style={{ display: "flex" }}>
                  <div style={{ position: "relative", top: "40px" }}>
                    <p>Population 1</p>
                    <MyMap chartData={chartData} mapData={mapData} />
                  </div>
                  <div style={{ position: "relative", top: "40px" }}>
                    <p>Population 2</p>
                    <MyMap chartData={secondGeoMapData} mapData={mapData} />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="on-off-button">
                  <div style={{ marginRight: "10px", fontSize: "13px" }}>
                    Common Regions
                  </div>
                  <img
                    src={toggleRightIcon}
                    alt=""
                    onClick={() => setCommonRegionButton(!commonRegionButton)}
                  />
                </div>

                <div className="comparison-geomap" style={{ display: "flex" }}>
                  <div
                    style={{
                      position: "relative",
                      top: "20px",
                      left: "200px",
                    }}
                  >
                    <MyMap
                      chartData={commonRegionGeoMapData}
                      mapData={mapData}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="comparecard-text">
              <div style={{ fontWeight: "500" }}>
                <p>Number of occurrences</p>
              </div>
              <div className="geo-map-occurance-container">
                <p
                  className="geo-map-occurance-color"
                  style={{ backgroundColor: "#92C5DE" }}
                ></p>
                <p className="geo-map-occurance-data">0-40</p>
              </div>
              <div className="geo-map-occurance-container">
                <p
                  className="geo-map-occurance-color"
                  style={{ backgroundColor: "#4393C3" }}
                ></p>
                <p className="geo-map-occurance-data">41-70</p>
              </div>
              <div className="geo-map-occurance-container">
                <p
                  className="geo-map-occurance-color"
                  style={{ backgroundColor: "#0575A8" }}
                ></p>
                <p className="geo-map-occurance-data">71-90</p>
              </div>
            </div>
          </>
        ) : (
          <div>
            {!showChartLoader && !noDataFound && chartData !== null ? (
              <DualColumnGraph chartdata={chartData} pageSize={pageSize} />
            ) : (
              // <SingleChartLoader />
              <Feedback feedbackClassName="feedback" />
            )}
          </div>
        )}
      </div>

      {!noDataFound && containerName !== 4 && (
        <>
          <div className="graph-name">{graphName} </div>
          {pagination?.totalPages > 1 && (
            <Pagination
              pageCount={pagination?.totalPages}
              pageRange={pagination?.size}
              onPageChanged={onPageChanged}
              forcePage={pageSize}
            />
          )}
        </>
      )}
      {!noDataFound && containerName === 4 && (
        <div className="graph-name">Geo-Spatial mapping </div>
      )}
    </>
  );
};

export default CompareWithFilter;
