import React from "react";
//import { ReactComponent as ChatIcon } from "../assets/message-icon.svg";
import { ReactComponent as ChatIcon } from "../assets/BlueMsg.svg";
import ChatImage from "../assets/message-box.png";
import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { addRootCausePayload } from '../../../../store/rootCauseAnalysis/actions';


const DtcHeading = () => {
  const dispatch = useDispatch();
  const [isChatVisible, setIsChatVisible] = useState(false);

  let rcaPayload = useSelector(
    (state) => state.rootCauseReducer.rootCauseAnalysisPayload
  );
  if (rcaPayload && rcaPayload !== {}) {
    window.localStorage.setItem(
      "root-cause-payload",
      JSON.stringify(rcaPayload)
    );
  } else {
    rcaPayload = JSON.parse(
      window.localStorage.getItem("root-cause-payload") || {}
    );
    dispatch(addRootCausePayload({ ...rcaPayload }));
  }
  return (
    <>
      <div className="dtc_heading_row">
        <div className="dtc_heading_container">
          <h2>{rcaPayload.dcode}-Check Airflow Clog</h2>
        </div>
        <div
          className="live_chat_button"
          onClick={() => setIsChatVisible(!isChatVisible)}
        >
          Live chat<div className="pink-dot"></div>
        </div>
      </div>
      <div
        className="chat-bot"
        onClick={() => setIsChatVisible(!isChatVisible)}
      >
        <ChatIcon />
      </div>
      {isChatVisible && (
        <div
          className="chat-image"
          onClick={() => setIsChatVisible(!isChatVisible)}
        >
          <img src={ChatImage} alt="" />
        </div>
      )}
    </>
  );
};

export default DtcHeading;
