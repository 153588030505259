import { useEffect } from "react";
import { useSelector } from "react-redux";
import Highcharts from "highcharts";
import arrowYAxis2 from "../../../assets/arrow_second_axis.svg";
import arrowXAxis from "../../../assets/arrow-xaxis.svg";
import "./index.scss";
// import { engineSpeedMile, engineSpeedLastTrip } from "../../../_mock/vehicle-usage-profile/engineSpeed";

export const DualLine = (data) => {
  const {
    id,
    dataAxis1,
    dataAxis2,
    title,
    xLabel,
    yLabel,
    yLabel2,
    chartDetails,
  } = data.data;
  const onClickHandler = data.onClickHandler;
  const isSidemenuExpanded = useSelector(
    (state) => state.mainReducer.isSidemenuExpanded
  );
  const colorMap = {
    yellow: "#FDFD62",
    green: "#A9D18E",
    red: "#FD8390",
    pink: "#DD359A",
    darkblue: "#0574A6",
    cyan: "#09A1A1",
  };
  useEffect(() => {
    renderChart(id, dataAxis1, dataAxis2, title, xLabel, yLabel, yLabel2);
  }, [dataAxis1, dataAxis2, isSidemenuExpanded]);

  const getPrimaryYAxisTitle = (data1, data2, yLabel) => {
    return data1?.length < 1 && data2?.length < 1
      ? {
          text: null,
        }
      : {
          text: `<div class="y-axis-legend"><div>${yLabel}</div><div class="y-axis-arrow"></div></div>`,
          style: {
            color: "#353536",
            lineHeight: 1,
            textAlign: "right",
            font: "10px Open Sans",
            letterSpacing: "1px",
          },
          align: "high",
          useHTML: true,
          margin: 20,
        };
  };

  const getSecondaryYAxisTitle = (data1, data2, yLabel) => {
    // return data1?.length < 1 && data2?.length < 1
    //   ? {
    //       text: null,
    //     }
    //   : {
    //       text: `<div class="axis-legend"><img src='${arrowYAxis2}'/><div>${yLabel}</div></div>`,
    //       style: {
    //         color: "#353536",
    //         lineHeight: 1,
    //         textAlign: "right",
    //         font: "10px Open Sans",
    //         letterSpacing: "1px",
    //       },
    //       align: "high",
    //       useHTML: true,
    //       margin: 20,
    //     };
  };

  const getxAxisTitle = (data1, data2, xLabel) => {
    return data1?.length < 1 && data2?.length < 1
      ? {
          text: null,
        }
      : {
          text: `<div class="x-axis-legend"><div>${xLabel}</div><img src='${arrowXAxis}'/></div>`,
          style: {
            color: "#353536",
            lineHeight: 1,
            textAlign: "right",
            font: "10px Open Sans",
            letterSpacing: "1px",
          },
          align: "high",
          useHTML: true,
          margin: 20,
        };
  };

  const renderChart = (
    id,
    dataAxis1,
    dataAxis2,
    title,
    xLabel,
    yLabel,
    yLabel2
  ) => {
    Highcharts.chart(
      id,
      {
        chart: {
          backgroundColor: "#FFFFFF",
          spacing: [50, 30, 10, 5],
          height: 300,
          // borderColor: "#CECECE",
          borderRadius: 20,
          // borderWidth: 1,
        },
        title: {
          text: null,
        },

        yAxis: [
          {
            title: getPrimaryYAxisTitle(dataAxis1, dataAxis2, yLabel),
            labels: {
              style: {
                color: "#353536",
                lineHeight: 1,
                font: "10px Open Sans",
                letterSpacing: "1px",
              },
            },
            gridLineColor: "#F2F2F2",
          },
          {
            title: getSecondaryYAxisTitle(dataAxis1, dataAxis2, yLabel2),
            labels: {
              style: {
                color: "#353536",
                lineHeight: 1,
                font: "10px Open Sans",
                letterSpacing: "1px",
              },
            },
            opposite: true,
            gridLineColor: "#ffffff",
          },
        ],

        xAxis: {
          type: "category",
          title: getxAxisTitle(dataAxis1, dataAxis2, xLabel),
          labels: {
            style: {
              color: "#353536",
              lineHeight: 1,
              font: "10px Open Sans",
              letterSpacing: "1px",
            },
          },
          tickWidth: 0,
        },
        
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          series: {
            color: "#0574A6",
          },
        },
        tooltip: {
          backgroundColor: "#000",
          style: {
            color: "#FFFFFF",
            font: "12px Open Sans",
          },
        },
        series: [
          {
            name: yLabel,
            data: dataAxis1,
          },
          {
            name: yLabel2,
            data: dataAxis2,
            yAxis: 1,
          },
        ],
      },
      function (chart) {
        // on complete
        const data1Len = chart.series?.[0]?.data?.length || 0;
        const data2Len = chart.series?.[1]?.data?.length || 0;
        if (data1Len < 1 && data2Len < 1) {
          // check series is empty
          chart.renderer
            .text("No Data Available", 50, 125)
            .css({
              color: "#000000",
              fontSize: "16px",
            })
            .add();
        }
      }
    );
  };

  return (
    <>
      <figure className="highcharts-figure" onClick={() => onClickHandler()}>
        <div className="chart-details-wrapper">
          {chartDetails?.map(
            (el) =>
              !el.value &&
              el.color && (
                <>
                  <div className="legend-wrapper">
                    <div className={`${el.color.toLowerCase()}-legend`}>
                      <span
                        className={`color-box ${el.color.toLowerCase()}`}
                      ></span>
                      <span className="color-legend">{el.label}</span>
                    </div>
                  </div>
                </>
              )
          )}
          {chartDetails?.map(
            (el) =>
              ((el.value && el.color) || (!el.value && !el.color)) && (
                <div className="details-wrapper">
                  <div className={`chart-details`}>
                    <span className={`value ${el.color.toLowerCase()}`}>
                      {el.value}
                    </span>
                    <span className="color-legend">{el.label}</span>
                  </div>
                </div>
              )
          )}
        </div>
        <div id={id}></div>
        <div className="graph-name">{title}</div>
      </figure>
    </>
  );
};
