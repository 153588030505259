import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";

const Index = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname === "/vehicle-health/diagnostics/vibrational-analytic"
    ) {
      navigate("/vehicle-health/diagnostics/vibrational-analytics");
    }
  }, []);

  return (
    <div>
      <Outlet />
    </div>
  );
};

export default Index;
