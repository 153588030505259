import moment from "moment";
import React from "react";
import { useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import RightArrowLongIcon from "../assets/right-arrow-long.svg";
import LengendContainer from "./LengendContainer";
import { useSelector } from "react-redux";
import { colorMap, getTickIntervals, graphType } from "../utils/constants";
import NoDataFound from "./NoDataFound";

const FuelConsumption = ({ data, filterType }) => {
  const isSidemenuExpanded = useSelector(
    (state) => state.mainReducer.isSidemenuExpanded
  );

  const [odometerTick, setOdometerTick] = useState([]);

  const [chartData, setChartData] = useState(null);
  const [fuelConsAvgCityArray, setfuelConsAvgCityArray] = useState(null);
  const [fuelConsAvgHighwayArray, setfuelConsAvgHighwayArray] = useState(null);
  const [fuelConsTargetCityArray, setfuelConsTargetCityArray] = useState(null);
  const [fuelConsTargetHighwayArray, setFuelConsTargetHighwayArray] =
    useState(null);
  const [chartInfo, setChartInfo] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);

  useEffect(() => {
    setChartData(data?.fuelConsumptionPerHPForCityAndHighway);
    if (data && data?.fuelConsumptionPerHPForCityAndHighway.length > 0) {
      let fuelConsAvgCityArray = [];
      let fuelConsAvgHighwayArray = [];
      let fuelConsTargetCityArray = [];
      let fuelConsTargetHighwayArray = [];
      switch (filterType) {
        case graphType.DATE: {
          fuelConsAvgCityArray = data.fuelConsumptionPerHPForCityAndHighway.map(
            (item) => [
              moment(item.statsDate).format("DD"),
              item.fuelConsumptionAverageCity,
            ]
          );
          fuelConsAvgHighwayArray =
            data.fuelConsumptionPerHPForCityAndHighway.map((item) => [
              moment(item.statsDate).format("DD"),
              item.fuelConsumptionAverageHighway,
            ]);
          fuelConsTargetCityArray =
            data.fuelConsumptionPerHPForCityAndHighway.map((item) => [
              moment(item.statsDate).format("DD"),
              item.fuelConsumptionTargetCity,
            ]);
          fuelConsTargetHighwayArray =
            data.fuelConsumptionPerHPForCityAndHighway.map((item) => [
              moment(item.statsDate).format("DD"),
              item.fuelConsumptionTargetHighway,
            ]);
          break;
        }
        case graphType.MILES: {
          fuelConsAvgCityArray = data.fuelConsumptionPerHPForCityAndHighway.map(
            (item) => [item.odometer, item.fuelConsumptionAverageCity]
          );
          fuelConsAvgHighwayArray =
            data.fuelConsumptionPerHPForCityAndHighway.map((item) => [
              item.odometer,
              item.fuelConsumptionAverageHighway,
            ]);
          fuelConsTargetCityArray =
            data.fuelConsumptionPerHPForCityAndHighway.map((item) => [
              item.odometer,
              item.fuelConsumptionTargetCity,
            ]);
          fuelConsTargetHighwayArray =
            data.fuelConsumptionPerHPForCityAndHighway.map((item) => [
              item.odometer,
              item.fuelConsumptionTargetHighway,
            ]);
          if (fuelConsTargetHighwayArray.length > 1) {
            const tickArray = data.fuelConsumptionPerHPForCityAndHighway
              .map((item) => item.odometer)
              .sort((a, b) => a - b);
            const tArray = getTickIntervals(tickArray);
            setOdometerTick(tArray);
          }
          break;
        }
        case graphType.TRIP: {
          fuelConsAvgCityArray = data.fuelConsumptionPerHPForCityAndHighway.map(
            (item) => [item.trip, item.fuelConsumptionAverageCity]
          );
          fuelConsAvgHighwayArray =
            data.fuelConsumptionPerHPForCityAndHighway.map((item) => [
              item.trip,
              item.fuelConsumptionAverageHighway,
            ]);
          fuelConsTargetCityArray =
            data.fuelConsumptionPerHPForCityAndHighway.map((item) => [
              item.trip,
              item.fuelConsumptionTargetCity,
            ]);
          fuelConsTargetHighwayArray =
            data.fuelConsumptionPerHPForCityAndHighway.map((item) => [
              item.trip,
              item.fuelConsumptionTargetHighway,
            ]);
          break;
        }

        default: {
          fuelConsAvgCityArray = data.fuelConsumptionPerHPForCityAndHighway.map(
            (item) => [
              moment(item.statsDate).format("DD"),
              item.fuelConsumptionAverageCity,
            ]
          );
          fuelConsAvgHighwayArray =
            data.fuelConsumptionPerHPForCityAndHighway.map((item) => [
              moment(item.statsDate).format("DD"),
              item.fuelConsumptionAverageHighway,
            ]);
          fuelConsTargetCityArray =
            data.fuelConsumptionPerHPForCityAndHighway.map((item) => [
              moment(item.statsDate).format("DD"),
              item.fuelConsumptionTargetCity,
            ]);
          fuelConsTargetHighwayArray =
            data.fuelConsumptionPerHPForCityAndHighway.map((item) => [
              moment(item.statsDate).format("DD"),
              item.fuelConsumptionTargetHighway,
            ]);
        }
      }

      setChartInfo(data?.information);
      setfuelConsAvgCityArray(fuelConsAvgCityArray);
      setfuelConsAvgHighwayArray(fuelConsAvgHighwayArray);
      setfuelConsTargetCityArray(fuelConsTargetCityArray);
      setFuelConsTargetHighwayArray(fuelConsTargetHighwayArray);
      const month = moment(
        data?.fuelConsumptionPerHPForCityAndHighway[0].statsDate
      ).format("MMMM");
      const year = moment(
        data?.fuelConsumptionPerHPForCityAndHighway[0].statsDate
      ).format("YYYY");
      setYear(year);
      setMonth(month);
    } else {
      setfuelConsAvgCityArray(null);
      setfuelConsAvgHighwayArray(null);
      setfuelConsTargetCityArray(null);
      setFuelConsTargetHighwayArray(null);
    }
  }, [data]);

  const options = {
    chart: {
      backgroundColor: "#FFFFFF",
      spacing: [50, 30, 30, 20],
      height: 350,
      type: "scatter",
      // borderColor: "#CECECE",
      borderRadius: 20,
      // borderWidth: 1,
      events: {
        load: function () {
          this.series.forEach((serie) => {
            if (serie) {
              serie.update({
                enableMouseTracking: false,
              });
            }
          });
        },
      },
    },
    title: {
      text: null,
    },
    yAxis: {
      title: {
        useHTML: true,
        text: `
         <div style="font-size: 11px; left:-200px;top:4px;position:absolute;">
         Fuel Consumption /HP  (City/HW)
          <img src='${RightArrowLongIcon}'/>
        </div>
        `,
        align: "high",
      },
      min: 0,
      labels: {
        style: {
          color: "#353536",
          lineHeight: 1,
          font: "10px Open Sans",
          letterSpacing: "1px",
        },
      },
      gridLineColor: "#F2F2F2",
    },
    xAxis: {
      type: "category",
      tickPositions: filterType === graphType.MILES ? odometerTick : null,
      title: {
        useHTML: true,
        text: `
         <div style="font-size: 11px; left:-60px;top:4px;position:absolute;">
           ${filterType === graphType.DATE
            ? `${month},${year}`
            : filterType === graphType.MILES
              ? `Miles`
              : filterType === graphType.TRIP
                ? `Trips`
                : `${month},${year}`
          }
          <img src='${RightArrowLongIcon}'/>
        </div>
        `,
        align: "high",
      },
      labels: {
        style: {
          color: "#353536",
          lineHeight: 1,
          font: "10px Open Sans",
          letterSpacing: "1px",
        },
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: true,
        },
        marker: {
          radius: 3,
        },
      },
      line: {
        dashStyle: "Dash",
      },
    },
    tooltip: {
      backgroundColor:"#000",
      style: {
        color: "#FFFFFF",
        font: "12px Open Sans",
      },
    },
    series: [
      {
        data: fuelConsAvgCityArray,
        showInLegend: false,
        name: "Mpg Target HighWay",
        color: chartInfo && colorMap[chartInfo[0]?.color],
        regression: fuelConsAvgCityArray?.length > 1 ? true : false,
        regressionSettings: {
          type: "polynomial",
          order: "2",
          color: chartInfo && colorMap[chartInfo[0]?.color],
          dashStyle: "dot",
        },
        marker: {
          symbol: "circle",
        },
      },
      {
        data: fuelConsAvgHighwayArray,
        showInLegend: false,
        name: "Mpg Target City",
        color: chartInfo && colorMap[chartInfo[2]?.color],
        regression: fuelConsAvgHighwayArray?.length > 1 ? true : false,
        regressionSettings: {
          type: "polynomial",
          order: "2",
          color: chartInfo && colorMap[chartInfo[2]?.color],
          dashStyle: "dot",
        },
        marker: {
          symbol: "circle",
        },
      },
      {
        data: fuelConsTargetCityArray,
        showInLegend: false,
        name: "Mpg Target City",
        color: chartInfo && colorMap[chartInfo[1]?.color],
        regression: fuelConsTargetCityArray?.length > 1 ? true : false,
        regressionSettings: {
          type: "polynomial",
          order: "2",
          color: chartInfo && colorMap[chartInfo[1]?.color],
          dashStyle: "dot",
        },
        marker: {
          symbol: "circle",
        },
      },
      {
        data: fuelConsTargetHighwayArray,
        showInLegend: false,
        name: "Mpg Target City",
        color: chartInfo && colorMap[chartInfo[3]?.color],
        regression: fuelConsTargetHighwayArray?.length > 1 ? true : false,
        regressionSettings: {
          type: "polynomial",
          order: "2",
          color: chartInfo && colorMap[chartInfo[3]?.color],
          dashStyle: "dot",
        },
        marker: {
          symbol: "circle",
        },
      },
    ],
  };

  return (
    <div key={fuelConsAvgCityArray}>
      <div key={isSidemenuExpanded}>
        {Array.isArray(fuelConsAvgCityArray) &&
          Array.isArray(fuelConsAvgHighwayArray) &&
          Array.isArray(fuelConsTargetCityArray) &&
          Array.isArray(fuelConsTargetHighwayArray) &&
          fuelConsAvgCityArray.length > 0 ? (
          <>
            <LengendContainer chartInfo={chartInfo} />
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              updateArgs={[true, true, true]}
            />
            <div
              style={{
                width: "100%",
                textAlign: "center",
                paddingTop: "10px",
              }}
            >
              <p style={{ fontSize: "14px", fontWeight: "600" }}>
                Fuel Consumption /HP (City/HW)
              </p>
            </div>
          </>
        ) : (
          <>
            <NoDataFound chartName="Fuel Consumption /HP (City/HW)" />
          </>
        )}
      </div>
    </div>
  );
};

export default FuelConsumption;
