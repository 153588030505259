import React from "react";
import { useNavigate } from "react-router";
import logo from "../../assets/logo_blue.png";
import logo_shrink from "../../assets/logo_blue_shrink.svg";
import "./header.scss";

const HeaderLogo = ({ isExpanded,redirectToDashborad }) => {
  const navigate = useNavigate();
  // const redirectToDashborad = () => {
  //   localStorage.removeItem("NavigationMenType");
  //    localStorage.setItem("NavigationMenType", "IndividualVehicle")
  //    navigate("./dashboard")
  // }

  return (
    <div className="header-logo " onClick={redirectToDashborad}>
      <img src={isExpanded ? logo : logo_shrink} alt="logo"></img>
    </div>
  );
};

export default HeaderLogo;
