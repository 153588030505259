import { useEffect, useState } from "react";
import { mainAxiosInstance } from "../../../../services/service";
import { ServiceConfig } from "../../../../services/service-constants";
import { Button } from "../../../common/Button/Button";
import { Checkbox } from "../../../common/Checkbox/Checkbox";
import { useLocation, useNavigate } from "react-router";
import "./RemoteTesting.scss";
import { LineChart } from "../../../common/LineGraph";
import { useSelector } from "react-redux";
import { RemoteTestingGraph } from "./Graph/RemoteTestingGraph";
import ChartLoader from "../../../common/ErrorComponents/ChartLoader";
import NotAuthorized from "../../../common/ErrorComponents/NotAuthorized";
import Feedback from "../../../common/ErrorComponents/Feedback";

const getSingleLineData = (data, xLineType, yLineType) => {
  let lineArr = [];
  for (let i = 0; i < data?.length; i++) {
    lineArr.push({
      y: data[i][xLineType],
      x: data[i]["time"],
    });
  }
  return lineArr;
};

export const handlePerProfile = async ({
  urlEnd,
  imgUrlEnd,
  saveData,
  saveImgData,
  xAxisSaveData,
  graphUrl,
  vin_Number,
  errorMsg,
  notAuthorized,
  apiError,
}) => {
  const url = `${ServiceConfig.vehicle_health_check}${urlEnd}`;
  const imgUrl = `${ServiceConfig.vehicle_health_check}${imgUrlEnd}`;

  const params = {
    vin_number: vin_Number,
    organization_id: 1,
  };
  const paramsImg = {
    ...params,
    stats_date: DateString,
    graph_name: graphUrl,
  };

  await mainAxiosInstance
    .get(imgUrl, { params: paramsImg })
    .then((response) => {
      let title = "";
      if (response.data?.[0]?.graphName === "right_rear_inlet_valve") {
        title = "FRONT_RIGHT_INLET_VALVE_HEALTH_CHECK";
      } else if (
        response.data?.[0]?.graphName === "right_rear_brake_pad_wear"
      ) {
        title = "FRONT_RIGHT_BRAKE_PAD_WEAR";
      } else if (response.data?.[0]?.graphName === "right_rear_outlet_valve") {
        title = "FRONT_RIGHT_OUTLET_VALVE_HEALTH_CHECK";
      } else {
        title = response.data?.[0]?.graphName;
      }

      let responseData = [{ ...response.data?.[0] }];
      responseData[0]["title"] = title;
      saveImgData(responseData);
      // saveImgData(response.data);
    })
    .catch((err) => {
      if (err.response.status === 401) {
        notAuthorized(true);
        errorMsg(err.response.data?.details || "You are not authorized.");
      } else {
        apiError(true);
      }
    });

  await mainAxiosInstance
    .get(url, { params: params })
    .then((response) => {
      const { data } = response;
      if (data?.length) {
        const pressure20k = getSingleLineData(
          data,
          FluidPressure20k,
          ActuationDutyCyclePWM
        );
        const pressure50k = getSingleLineData(
          data,
          FluidPressure50k,
          ActuationDutyCyclePWM
        );
        const pressure100k = getSingleLineData(
          data,
          FluidPressure100k,
          ActuationDutyCyclePWM
        );
        const pressure120k = getSingleLineData(
          data,
          FluidPressure120k,
          ActuationDutyCyclePWM
        );
        const pressure150k = getSingleLineData(
          data,
          FluidPressure150k,
          ActuationDutyCyclePWM
        );
        const outletValveDutyCycleData = getSingleLineData(
          data,
          outletValveDutyCycle
        );
        const inletValveDutyCycleData = getSingleLineData(
          data,
          inletValveDutyCycle
        );
        let chartData = [];
        if (pressure20k?.length) {
          chartData.push({
            name: "20k Miles",
            data: pressure20k,
            color: "#b38b6d",
          });
        }
        if (pressure50k?.length) {
          chartData.push({
            name: "50k Miles",
            data: pressure50k,
            color: "#7CB9E8",
          });
        }
        if (pressure100k?.length) {
          chartData.push({
            name: "100k Miles",
            data: pressure100k,
            color: "#72A0C1",
          });
        }
        if (pressure120k?.length) {
          chartData.push({
            name: "120k Miles",
            data: pressure120k,
            color: "#00FFFF",
          });
        }
        if (pressure150k?.length) {
          chartData.push({
            name: "150k Miles",
            data: pressure150k,
            color: "#e32636",
          });
        }
        if (outletValveDutyCycleData?.length) {
          chartData.push({
            name: "Outlet Valve Duty Cycle",
            data: outletValveDutyCycleData,
            color: "#ff0038",
          });
        }
        if (inletValveDutyCycleData?.length) {
          chartData.push({
            name: "Inlet Valve Duty Cycle Data",
            data: inletValveDutyCycleData,
            color: "#007FFF",
          });
        }
        saveData(chartData);
      }
    })
    .catch((er) => {
      throw er;
    });
};

// right_rear_outlet_valve
const DateString = "2022-07-13";
const RightRearInletValve = "right_rear_inlet_valve";
const RightRearBrakePadValve = "right_rear_brake_pad_wear";
const RightRearOutletValve = "right_rear_outlet_valve";
const imageUrlPath = "remote_testing_graphs";
const FluidPressure20k = "miles20k";
const FluidPressure50k = "miles50k";
const FluidPressure100k = "miles100k";
const FluidPressure120k = "miles120k";
const FluidPressure150k = "miles150k";
const ActuationDutyCyclePWM = "valveActuationDutyCyclePWM";
const inletValveDutyCycle = "inletValveDutyCycle";
const outletValveDutyCycle = "outletValveDutyCycle";

const ProfilePerformanceOptions = [
  { displayname: "Engine", isChecked: false, isDisabled: true },
  {
    displayname: "ABS",
    isChecked: false,
    isDisabled: false,
    imgUrls: {
      inlet: {
        url: "right_rear_inlet_valve",
        imgUrl: "right_rear_inlet_valve_graph",
      },
      brake: {
        url: "right_rear_inlet_valve",
        imgUrl: "right_rear_inlet_valve_graph",
      },
      outlet: {
        url: "right_rear_inlet_valve",
        imgUrl: "right_rear_inlet_valve_graph",
      },
    },
  },
  { displayname: "Steering", isChecked: false, isDisabled: true },
];

const RemoteTesting = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const vin_Number = useSelector((state) => state.mainReducer.vin_Number);
  const [profilePerformance, setProfilePerformance] = useState([
    ...ProfilePerformanceOptions,
  ]);
  const [inletChartData, setInletChartData] = useState([]);
  const [inletChartDataWithImg, setInletChartDataWithImg] = useState([]);
  const [outletChartData, setOutletChartData] = useState([]);
  const [outletChartDataWithImg, setOutletChartDataWithImg] = useState([]);
  const [padChartData, setPadChartData] = useState([]);
  const [padChartDataWithImg, setPadChartDataWithImg] = useState([]);
  const [data, setData] = useState("");
  const [timeAxis, setTimeAxis] = useState("");
  const [dataBrakePad, setDataBrakePad] = useState("");
  const [timeAxisBrakePad, setTimeAxisBrakePad] = useState("");
  const [dataOutletValve, setDataOutletValve] = useState("");
  const [timeAxisOutletValve, setTimeAxisOutletValve] = useState("");
  const [clickGenerateButton, setClickGenerateButton] = useState(false);
  const [notAuthorized, setNotAuthorized] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [selectedCheckbox, setSelectedCheckbox] = useState([]);
  const [showCharts, setShowCharts] = useState(false);

  const handleProfilePerformanceClick = (val) => {
    const newFilterData = profilePerformance.map((el) => {
      if (el.displayname === val.displayname) {
        el.isChecked = !el.isChecked;
      }
      return el;
    });
    const selectedCheckbox = newFilterData.map((key) => key.isChecked);
    const value = selectedCheckbox.filter((key) => key === true);
    setSelectedCheckbox(value);
    if (value.length === 0) {
      setClickGenerateButton(false);
      setShowCharts(false);
    }
    setProfilePerformance([...newFilterData]);
  };

  useEffect(() => {
    if (location?.state === "used") {
      ProfilePerformanceOptions[1].isChecked = true;
      clickGenerate();
      window.history.replaceState({}, document.title);
    }
  }, [location?.state]);

  useEffect(() => {
    if (location.pathname !== "vehicle-health/diagnostics/remote-testing") {
      setProfilePerformance([
        { displayname: "Engine", isChecked: false, isDisabled: true },
        {
          displayname: "ABS",
          isChecked: false,
          isDisabled: false,
          imgUrls: {
            inlet: {
              url: "right_rear_inlet_valve",
              imgUrl: "right_rear_inlet_valve_graph",
            },
            brake: {
              url: "right_rear_inlet_valve",
              imgUrl: "right_rear_inlet_valve_graph",
            },
            outlet: {
              url: "right_rear_inlet_valve",
              imgUrl: "right_rear_inlet_valve_graph",
            },
          },
        },
        { displayname: "Steering", isChecked: false, isDisabled: true },
      ]);
    }
  }, []);

  useEffect(() => {
    if (inletChartData?.length) {
      setData(inletChartData);
    }
  }, [JSON.stringify(inletChartData)]);

  useEffect(() => {
    if (padChartData?.length) {
      setDataBrakePad(padChartData);
    }
  }, [JSON.stringify(padChartData)]);

  useEffect(() => {
    if (location.state === "used") {
      const updatedData = profilePerformance.map(item => {
        if (item.displayname === 'ABS') {
          return { ...item, isChecked: true };
        }
        return item;
      });
      setProfilePerformance(updatedData)
      const checkboxData=[true]
      clickGenerate(checkboxData)
    }
  },[]);

  useEffect(() => {
    if (outletChartData?.length) {
      setDataOutletValve(outletChartData);
    }
  }, [JSON.stringify(outletChartData)]);

  const handleViewDiagram = ({ routePath, urlEnd, imgUrlEnd, graphUrl }) => {
    navigate(
      "/vehicle-health/diagnostics/remote-testing/" +
        routePath +
        "?" +
        "urlEnd=" +
        urlEnd +
        "&imgUrlEnd=" +
        imgUrlEnd +
        "&graphUrl=" +
        graphUrl
    );
  };

  const clickGenerate = async (checkboxData) => {
    if (((selectedCheckbox.length >= 1) ||(checkboxData)) ) {
      setClickGenerateButton(true);
      setShowCharts(true);
      await handlePerProfile({
        urlEnd: RightRearInletValve,
        imgUrlEnd: imageUrlPath,
        saveData: setInletChartData,
        saveImgData: setInletChartDataWithImg,
        xAxisSaveData: setTimeAxis,
        graphUrl: RightRearInletValve,
        vin_Number: vin_Number,
        errorMsg: setErrorMsg,
        notAuthorized: setNotAuthorized,
        apiError: setApiError,
      });
      await handlePerProfile({
        urlEnd: "right_rear_brake_pad_wear",
        imgUrlEnd: imageUrlPath,
        saveData: setPadChartData,
        saveImgData: setPadChartDataWithImg,
        xAxisSaveData: setTimeAxisBrakePad,
        graphUrl: RightRearBrakePadValve,
        vin_Number: vin_Number,
        errorMsg: setErrorMsg,
        notAuthorized: setNotAuthorized,
        apiError: setApiError,
      });
      await handlePerProfile({
        urlEnd: RightRearOutletValve,
        imgUrlEnd: imageUrlPath,
        saveData: setOutletChartData,
        saveImgData: setOutletChartDataWithImg,
        xAxisSaveData: setTimeAxisOutletValve,
        graphUrl: RightRearOutletValve,
        vin_Number: vin_Number,
        errorMsg: setErrorMsg,
        notAuthorized: setNotAuthorized,
        apiError: setApiError,
      });
    }
  };
  if (
    padChartDataWithImg == 0 &&
    clickGenerateButton &&
    !notAuthorized &&
    !apiError
  ) {
    return <ChartLoader />;
  }

  if (notAuthorized && clickGenerateButton) {
    return <NotAuthorized title={errorMsg} />;
  }
  if (apiError && clickGenerateButton) {
    return <Feedback />;
  }

  return (
    <>
      <div className="row flex"></div>
      <div className="page-wrapper" style={{ marginTop: "20px" }}>
        <div className="flex page-content-header rem-test-hdr">
          {/* Please Select the component deep dive to generate Profile */}
          <div className="flex remotet">
            {profilePerformance.map((el) => (
              <Checkbox
                title={el.displayname}
                handleInputClick={() => handleProfilePerformanceClick(el)}
                alignProp={{
                  display: "flex",
                  padding: "10px",
                  justifyContent: "space-between",
                }}
                checkboxStyle={{}}
                isChecked={el.isChecked}
                disabled={el.isDisabled}
              />
            ))}
          </div>
          <div
            className="text-align-right"
            style={{
              display: "flex",
              marginTop: "40px",
            }}
          >
            {clickGenerateButton ? (
              ""
            ) : (
              <>
                <Button
                  title="Generate Performance Profile"
                  handleClick={() => clickGenerate()}
                  styleClasses={
                    profilePerformance.filter((i) => i?.isChecked).length
                      ? "btn-btn-primary"
                      : "btn-btn-disabled"
                  }
                />
              </>
            )}
          </div>
        </div>

        {showCharts && (
          <div className="rem-test-container">
            <div className="rem-test-card flex">
              {data && inletChartData?.length ? (
                <div className="rem-test-graph">
                  {inletChartData?.length > 0 &&
                    inletChartDataWithImg?.length > 0 && (
                      <RemoteTestingGraph
                        id={inletChartDataWithImg?.[0]?.id}
                        data={data}
                        onClickHandler={() =>
                          handleViewDiagram({
                            routePath: "performance-profile",
                            urlEnd: RightRearInletValve,
                            imgUrlEnd: imageUrlPath,
                            graphUrl: RightRearInletValve,
                            remote: "remote-testing",
                            vin_Number: vin_Number,
                          })
                        }
                        title={inletChartDataWithImg?.[0]?.title
                          ?.split("_")
                          .join(" ")
                          ?.toUpperCase()}
                        xLabel={inletChartDataWithImg?.[0]?.xAxisName}
                        xAxisData={timeAxis}
                        yAxisLabel={inletChartDataWithImg?.[0]?.yAxisName}
                        showLegend={true}
                      />
                    )}
                </div>
              ) : (
                <></>
              )}
              {inletChartDataWithImg?.[0]?.purpose ? (
                <div className="rem-test-graph-text">
                  <div>{inletChartDataWithImg?.[0]?.purpose}</div>
                  <Button
                    title="View Diagram"
                    handleClick={() =>
                      handleViewDiagram({
                        routePath: "abs-diagram",
                        urlEnd: RightRearInletValve,
                        imgUrlEnd: imageUrlPath,
                        graphUrl: RightRearInletValve,
                      })
                    }
                    styleClasses={"btn button"}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="rem-test-card flex">
              <div className="rem-test-graph">
                {padChartData?.length > 0 &&
                  padChartDataWithImg?.length > 0 && (
                    <RemoteTestingGraph
                      id={padChartDataWithImg?.[0]?.id}
                      data={dataBrakePad}
                      onClickHandler={() =>
                        handleViewDiagram({
                          routePath: "performance-profile",
                          urlEnd: RightRearBrakePadValve,
                          imgUrlEnd: imageUrlPath,
                          graphUrl: RightRearBrakePadValve,
                        })
                      }
                      title={padChartDataWithImg?.[0]?.title
                        ?.split("_")
                        .join(" ")
                        ?.toUpperCase()}
                      xLabel={padChartDataWithImg?.[0]?.xAxisName}
                      xAxisData={timeAxisBrakePad}
                      yAxisLabel={padChartDataWithImg?.[0]?.yAxisName}
                      showLegend={true}
                    />
                  )}
              </div>
              {padChartDataWithImg?.[0]?.purpose ? (
                <div className="rem-test-graph-text">
                  <div>{padChartDataWithImg?.[0]?.purpose}</div>
                  <Button
                    title="View Diagram"
                    handleClick={() =>
                      handleViewDiagram({
                        routePath: "abs-diagram",
                        urlEnd: RightRearBrakePadValve,
                        imgUrlEnd: imageUrlPath,
                        graphUrl: RightRearBrakePadValve,
                      })
                    }
                    styleClasses={"btn button"}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="rem-test-card flex">
              <div className="rem-test-graph">
                {outletChartData?.length > 0 &&
                  outletChartDataWithImg?.length > 0 && (
                    <RemoteTestingGraph
                      id={outletChartDataWithImg?.[0]?.id}
                      data={dataOutletValve}
                      onClickHandler={() =>
                        handleViewDiagram({
                          routePath: "performance-profile",
                          urlEnd: "right_rear_outlet_valve",
                          imgUrlEnd: imageUrlPath,
                          graphUrl: RightRearOutletValve,
                          vin_Number: vin_Number,
                        })
                      }
                      title={outletChartDataWithImg?.[0]?.title
                        ?.split("_")
                        .join(" ")
                        ?.toUpperCase()}
                      xLabel={outletChartDataWithImg?.[0]?.xAxisName}
                      xAxisData={timeAxisOutletValve}
                      yAxisLabel={outletChartDataWithImg?.[0]?.yAxisName}
                      showLegend={true}
                    />
                  )}
              </div>
              {outletChartDataWithImg?.[0]?.purpose ? (
                <div className="rem-test-graph-text">
                  <div>{outletChartDataWithImg?.[0]?.purpose}</div>
                  <Button
                    title="View Diagram"
                    handleClick={() =>
                      handleViewDiagram({
                        routePath: "abs-diagram",
                        urlEnd: "right_rear_outlet_valve",
                        imgUrlEnd: imageUrlPath,
                        graphUrl: RightRearOutletValve,
                        vin_Number: vin_Number,
                      })
                    }
                    styleClasses={"btn button"}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default RemoteTesting;
