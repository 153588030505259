import React, { useEffect, useState } from "react";
import { NavLink, Outlet, useNavigate, useLocation } from "react-router-dom";
import TabLayout from "../../components/Layout/TabLayout";

const VehiclePopulation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showSubmenu, setShowSubmenu] = useState(false);
  //const [subnavLink ,setSubnavLink] =useState()

  let subnavLink = [];

  useEffect(() => {
    if (location.pathname === "/vehicle-population") {
      navigate("/vehicle-population/diagnostic", { replace: true });
      setShowSubmenu(false);
    } else if (location.pathname.includes("/vehicle-population/usageprofile")) {
      // navigate("/vehicle-population/usageprofile/diagnostic", {
      //   replace: true,
      // });
      setShowSubmenu(true);
    } else if (
      location.pathname.includes(
        "vehicle-population/vehicle-population-warranty-management"
      )
    ) {
      setShowSubmenu(true);
    } else if (location.pathname.includes("vehicle-population/health-check")) {
      setShowSubmenu(true);
    }
  }, [location.pathname]);

  const navLink = [
    { label: "Reports For Diagnostics", to: "/vehicle-population/diagnostic" },
    { label: "Reports For Prognostics", to: "/vehicle-population/prognostic" },
  ];

  if (location.pathname.includes("/vehicle-population/usageprofile")) {
    subnavLink = [
      {
        label: "Reports For Diagnostics",
        to: "/vehicle-population/usageprofile/diagnostic",
      },
      {
        label: "Reports For Prognostics",
        to: "/vehicle-population/usageprofile/prognostic",
      },
    ];
  } else if (
    location.pathname.includes(
      "/vehicle-population/vehicle-population-warranty-management"
    )
  ) {
    subnavLink = [
      {
        label: "Diagnostics",
        to: "/vehicle-population/vehicle-population-warranty-management/diagnostics",
      },
      {
        label: "Prognostics",
        to: "/vehicle-population/vehicle-population-warranty-management/prognostics",
      },
      {
        label: "Warranty Rate",
        to: "/vehicle-population/vehicle-population-warranty-management/warranty-rate",
      },
    ];
  } else if (location.pathname.includes("/vehicle-population/health-check")) {
    subnavLink = [
      {
        label: "Reports For Diagnostics",
        to: "/vehicle-population/health-check/diagnostic",
      },
      {
        label: "Reports For Prognostics",
        to: "/vehicle-population/health-check/prognostic",
      },
    ];
  }
  return (
    <div className="full-width ">
      <TabLayout
        tabLink={showSubmenu ? [] : navLink}
        submenuTabLink={showSubmenu ? subnavLink : []}
        shouldIncludeBreadcrumbs={false}
      />
    </div>
  );
};

export default VehiclePopulation;
