import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";

const Index = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (
      location.pathname ===
      "/warranty-management/diagnostics/top-10-DTC-complaints"
    ) {
      navigate("/warranty-management/diagnostics/top-10-DTC-complaints", {
        replace: true,
      });
    }
  }, [location.pathname]);
  return (
    <div
      className="diagnostics-topdtc-complaints page-wrapper"
      style={{ backgroundColor: "#EEEEEE" }}
    >
      <Outlet />
    </div>
  );
};

export default Index;
