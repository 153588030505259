import React, { useEffect } from "react";
import { NavLink, Outlet, useNavigate, useLocation } from "react-router-dom";
import TabLayout from "../../components/Layout/TabLayout";
import "./maintenanceOnDemand.scss";

const MaintenanceOnDemand = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/maintenance-overview") {
      navigate("/maintenance-overview/routine-maintenance", { replace: true });
    }
  }, [location.pathname]);
  const navLink = [
    {
      label: "Routine Maintenance",
      to: "/maintenance-overview/routine-maintenance",
    },
    { label: "Diagnostics", to: "/maintenance-overview/diagnostics" },
    { label: "Prognostics", to: "/maintenance-overview/prognostics" },
  ];
  return (
    <div className="full-width ">
      {location.pathname !== "/maintenance-overview/root-cause-analysis" && (
        // <div className="tabs-container maintenance-on-demand">
        //   <NavLink
        //     to="/maintenance-overview/routine-maintenance"
        //     className={({ isActive }) =>
        //       isActive ? "btn-primary tabs" : "btn-secondary tabs"
        //     }
        //   >
        //     Routine Maintenance
        //   </NavLink>
        //   <NavLink
        //     to="/maintenance-overview/diagnostics"
        //     className={({ isActive }) =>
        //       isActive ? "btn-primary tabs" : "btn-secondary tabs"
        //     }
        //   >
        //     Diagnostics
        //   </NavLink>
        //   <NavLink
        //     to="/maintenance-overview/prognostics"
        //     className={({ isActive }) =>
        //       isActive ? "btn-primary tabs" : "btn-secondary tabs"
        //     }
        //   >
        //     Prognostics
        //   </NavLink>
        // </div>
        <TabLayout tabLink={navLink} />
      )}

      {/* <Outlet /> */}
    </div>
  );
};

export default MaintenanceOnDemand;
