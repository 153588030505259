import React, { useEffect } from "react";
import { NavLink, Outlet, useNavigate, useLocation } from "react-router-dom";
import TabLayout from "../Layout/TabLayout";
import "./index.scss";
import { replace } from "lodash";

const ComponentSystemHistory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const savedState = JSON.parse(
    localStorage.getItem("componentSystemHistoryState") || ""
  );
  let stateObj;
  if (savedState) {
    stateObj = { subSystem: savedState.subSystem, date: savedState.date };
  }
  useEffect(() => {
    if (stateObj && stateObj !== {}) {
      if (
        location.pathname.includes("/component-system-history") &&
        !location.pathname.includes("/component-system-history/")
      ) {
        navigate("./diagnostics", {
          relative: "route",
          state: stateObj,
          replace: true,
        });
      }
    }
  }, [location.pathname]);

  const navLink = [
    { label: "Diagnostics", to: "./diagnostics" },
    { label: "Prognostics", to: "./prognostics" },
    { label: "Service Conditions", to: "./service-conditions" },
    { label: "Maintenance History", to: "./maintenance-history" },
    { label: "Maintenance Profile", to: "./maintenance-profile" },
    { label: "Usage Profile", to: "./usage-profile" },
  ];

  return (
    <>
      {/* <div className="full-width">
        <div className="tabs-container-table">
          <NavLink
            to="./diagnostics"
            state={stateObj}
            className={({ isActive }) =>
              isActive ? "btn-primary tabs" : "btn-secondary tabs"
            }
          >
            Diagnostics
          </NavLink>
          <NavLink
            to="./prognostics"
            state={stateObj}
            className={({ isActive }) =>
              isActive ? "btn-primary tabs" : "btn-secondary tabs"
            }
          >
            Prognostics
          </NavLink>
          <NavLink
            to="./maintenance-history"
            state={stateObj}
            className={({ isActive }) =>
              isActive ? "btn-primary tabs" : "btn-secondary tabs"
            }
          >
            Maintenance History
          </NavLink>
          <NavLink
            to="./maintenance-profile"
            state={stateObj}
            className={({ isActive }) =>
              isActive ? "btn-primary tabs" : "btn-secondary tabs"
            }
          >
            Maintenance Profile
          </NavLink>
          <NavLink
            to="./usage-profile"
            state={stateObj}
            className={({ isActive }) =>
              isActive ? "btn-primary tabs" : "btn-secondary tabs"
            }
          >
            Usage Profile
          </NavLink>
          <NavLink
            to="./service-conditions"
            state={stateObj}
            className={({ isActive }) =>
              isActive ? "btn-primary tabs" : "btn-secondary tabs"
            }
          >
            Service Conditions
          </NavLink>
        </div>
        <Outlet />
      </div> */}

      <TabLayout tabLink={navLink} state={stateObj} />
    </>
  );
};

export default ComponentSystemHistory;
