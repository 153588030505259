import React, { useEffect, useState } from "react";
import ReactGridLayout, { WidthProvider, Responsive } from "react-grid-layout";
import { withSize } from "react-sizeme";

import "react-grid-layout/css/styles.css";
import { useSelector } from "react-redux";
import "react-resizable/css/styles.css";
import { getAllChartData } from "../api";
import BrakePedalPositionChart from "./components/BrakePedalPositionChart";
import EngineCoolantTemperatureWidget from "./components/EngineCoolantTemperatureWidget";
import EngineLoadWidget from "./components/EngineLoadWidget";
import EngineOilPressureWidget from "./components/EngineOilPressureWidget";
import EngineSpeedWidget from "./components/EngineSpeedWidget";
import FuelLevelWidget from "./components/FuelLevelWidget";
import GasPedalPositionWidget from "./components/GasPedalPositionWidget";
import ManifoldAirPressureWidget from "./components/ManifoldAirPressureWidget";
import TirePressureWidget from "./components/TirePressureWidget";
import TransmissionOilPressure from "./components/TransmissionOilPressure";
import "./VehicleRealtimeData.scss";
import NotAuthorized from "../../../components/common/ErrorComponents/NotAuthorized";
import Feedback from "../../../components/common/ErrorComponents/Feedback";
import LottieLoad from "../../../components/common/ErrorComponents/LottieLoad";
import navLeft from "../../../assets/left-arrow-square.svg";
import navRight from "../../../assets/right-arrow-square.svg";
import close from "../../../assets/close.svg";

const GridLayout = WidthProvider(ReactGridLayout);

const BasicLayout = ({
  className,
  items,
  rowHeight,
  onLayoutChange,
  layout,
  filterData,
  cols,
  // ..rest
}) => {
  const generateDOM = () => {
    return items.map((item, idx) => {
      return (
        <div key={idx} className="realtime-graph">
          <span className="graph">{item}</span>
        </div>
      );
    });
  };
  return (
    <>
      <GridLayout
        rowHeight={rowHeight}
        layout={layout}
        onLayoutChange={onLayoutChange}
        cols={cols}
      >
        {generateDOM()}
      </GridLayout>
    </>
  );
};

/**
 * Hack to re-render the Widget component with withProvider when side menu is closed.
 * TODO : Find a better solution
 */

const WidgetExpanded = ({ layoutKey, keyArray, handleLargeGraph }) => {
  const isMenuExpanded = useSelector(
    (state) => state.mainReducer.isSidemenuExpanded
  );

  const vin_Number = useSelector((state) => state.mainReducer.vin_Number);
  const [allChartData, setAllChartData] = useState(null);
  const [notAuthorized, setNotAuthorized] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [layout, setLayout] = useState(null);
  const [itemList, setItemList] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [filterApi, setFilterApi] = useState(false);

  const [graphLarge, setGraphLarge] = useState(false);
  const [currentGraph, setCurrentGraph] = useState("gasPedal");

  const size = isMenuExpanded ? 250 : 250;
  const cols = 4;

  const getChartData = async (payload) => {
    const { code, data } = await getAllChartData(payload);
    if (code === 200 && data) {
      setAllChartData(data);
    } else if (code === 401) {
      setNotAuthorized(true);
      setErrorMsg(data);
      setFilterApi(filterApi);
    } else {
      setApiError(true);
      setErrorMsg(data);
      setFilterApi(filterApi);
    }
  };

  const getSize = (index, param) => {
    const windowWidth = isMenuExpanded ? 0.7 : 0.9;
    if (layout) {
      const colWidth = (window.innerWidth * windowWidth) / cols;
      const multiplier = param === "w" ? colWidth : size;
      return layout[index] && layout[index][param] * multiplier;
    }
  };

  useEffect(() => {
    const items = [];
    if (keyArray?.includes("fuelLevelData")) {
      items.push(
        <FuelLevelWidget
          height={getSize(keyArray.indexOf("fuelLevelData"), "h")}
          width={getSize(keyArray.indexOf("fuelLevelData"), "w")}
          fuelLevelData={allChartData?.fuelLevelAggregatorDto}
        />
      );
    }
    if (keyArray?.includes("engineSpeedData")) {
      items.push(
        <EngineSpeedWidget
          height={getSize(keyArray?.indexOf("engineSpeedData"), "h")}
          width={getSize(keyArray?.indexOf("engineSpeedData"), "w")}
          engineSpeedData={allChartData?.engineSpeedRpmAggregatorDto}
        />
      );
    }
    if (keyArray?.includes("gasPedalData")) {
      items.push(
        <div className="gas-pedal" onClick={() => handleGraphLarge("gasPedal")}>
          <GasPedalPositionWidget
            height={getSize(keyArray?.indexOf("gasPedalData"), "h")}
            width={getSize(keyArray?.indexOf("gasPedalData"), "w")}
            gasPedalData={allChartData?.gasPedalPositionSensorDtos}
          />
        </div>
      );
    }
    if (keyArray?.includes("airPressureData")) {
      items.push(
        <ManifoldAirPressureWidget
          height={getSize(keyArray?.indexOf("airPressureData"), "h")}
          width={getSize(keyArray?.indexOf("airPressureData"), "w")}
          airPressureData={allChartData?.manifoldAirPressureAggregatorDto}
        />
      );
    }
    if (keyArray?.includes("oilPressureData")) {
      items.push(
        <TransmissionOilPressure
          height={getSize(keyArray?.indexOf("oilPressureData"), "h")}
          width={getSize(keyArray?.indexOf("oilPressureData"), "w")}
          oilPressureData={allChartData?.transmissionOilPressureAggregatorDto}
        />
      );
    }
    if (keyArray?.includes("brakePedalData")) {
      items.push(
        <div
          className="brake-pedal"
          onClick={(allChartData) => handleGraphLarge("brakePedal")}
        >
          <BrakePedalPositionChart
            height={getSize(keyArray?.indexOf("brakePedalData"), "h")}
            width={getSize(keyArray?.indexOf("brakePedalData"), "w")}
            brakePedalData={allChartData?.brakePedalPositionDtos}
          />
        </div>
      );
    }
    if (keyArray?.includes("engineLoadData")) {
      items.push(
        <EngineLoadWidget
          height={getSize(keyArray?.indexOf("engineLoadData"), "h")}
          width={getSize(keyArray?.indexOf("engineLoadData"), "w")}
          engineLoadData={allChartData?.engineLoadAggregatorDto}
        />
      );
    }
    if (keyArray?.includes("engineOilData")) {
      items.push(
        <EngineOilPressureWidget
          height={getSize(keyArray?.indexOf("engineOilData"), "h")}
          width={getSize(keyArray?.indexOf("engineOilData"), "w")}
          engineOilData={allChartData?.engineOilPressurePsiAggregatorDto}
        />
      );
    }
    if (keyArray?.includes("engineCoolantData")) {
      items.push(
        <EngineCoolantTemperatureWidget
          height={getSize(keyArray?.indexOf("engineCoolantData"), "h")}
          width={getSize(keyArray?.indexOf("engineCoolantData"), "w")}
          engineCoolantData={
            allChartData?.engineCoolantTemperatureAggregatorDto
          }
        />
      );
    }
    if (keyArray?.includes("tirePressureData")) {
      items.push(
        <TirePressureWidget
          height={getSize(keyArray?.indexOf("tirePressureData"), "h")}
          width={getSize(keyArray?.indexOf("tirePressureData"), "w")}
          tirePressureData={allChartData?.tirePressurePsiAggregatorDto}
        />
      );
    }
    setItemList(items);
  }, [allChartData, keyArray, layout, graphLarge]);

  useEffect(() => {
    getChartData({
      VinNumber: vin_Number,
    });
  }, [keyArray, graphLarge, currentGraph]);

  useEffect(() => {
    setLayout(layoutKey);
  }, [layoutKey]);

  if (notAuthorized) {
    return <NotAuthorized title={errorMsg} />;
  }
  if (apiError) {
    return <Feedback />;
  }
  if (!allChartData) {
    return <LottieLoad />;
  }

  function handleGraphLarge(value) {
    if (value === "brakePedal") {
      setCurrentGraph("brakePedal");
    } else {
      setCurrentGraph("gasPedal");
    }
    setGraphLarge(!graphLarge);
    handleLargeGraph(!graphLarge);
  }

  const showPrev = () => {
    if (currentGraph === "brakePedal") {
      setCurrentGraph("gasPedal");
    } else {
      setCurrentGraph("brakePedal");
    }
  };

  const showNext = () => {
    if (currentGraph === "brakePedal") {
      setCurrentGraph("gasPedal");
    } else {
      setCurrentGraph("brakePedal");
    }
  };

  const clickOnClose = () => {
    setGraphLarge(!graphLarge);
    handleLargeGraph(!graphLarge);
  };

  return (
    <div>
      {allChartData && itemList ? (
        !graphLarge ? (
          <BasicLayout
            onLayoutChange={setLayout}
            rowHeight={size}
            layout={layout}
            cols={cols}
            items={itemList}
          />
        ) : (
          <div className="real-time-large-graphs">
            <div className="nav-icons" onClick={() => showPrev()}>
              <img src={navLeft} alt="previous" />
            </div>
            <div className="real-time-close-icon" onClick={clickOnClose}>
              <img
                src={close}
                alt="close"
                style={{
                  position: "relative",
                  left: "660px",
                  bottom: "127px",
                }}
              />
            </div>
            {currentGraph === "brakePedal" ? (
              <div className="brake-pedal-large">
                <BrakePedalPositionChart
                  height={300}
                  width={600}
                  brakePedalData={allChartData?.brakePedalPositionDtos}
                />
              </div>
            ) : (
              <div className="gas-pedal-large">
                <GasPedalPositionWidget
                  height={300}
                  width={600}
                  gasPedalData={allChartData?.gasPedalPositionSensorDtos}
                />
              </div>
            )}
            <div className="nav-icons" onClick={() => showNext()}>
              <img src={navRight} alt="previous" />
            </div>
          </div>
        )
      ) : (
        <>Data not availaible</>
      )}
    </div>
  );
};

export default WidgetExpanded;
