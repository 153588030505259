import React, { useEffect, useState } from "react";
import LeftVehileInformation from "./components/LeftVehileInformation";
import TwoColumn from "../components/TwoColumn";

import "./VehicleInformation.scss";
import RightVehicleInformation from "./components/RightVehicleInformation";
import { getVehicleInformationData } from "../api";
import { useSelector } from "react-redux";
import LottieLoad from "../../../components/common/ErrorComponents/LottieLoad";
import Feedback from "../../../components/common/ErrorComponents/Feedback";
import NotAuthorized from "../../../components/common/ErrorComponents/NotAuthorized";
import BottomVehicleInformation from "./components/BottomVehicleInformation";

const VehicleInformation = () => {
  const vinNumber = useSelector((state) => state.mainReducer.vin_Number);
  const [vehicleInfo, setVehicleInfo] = useState(null);
  const [notAuthorized, setNotAuthorized] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("")

  const getInformationData = async (payload) => {
    const { vinNumber } = payload;
    const { data, code } = await getVehicleInformationData({
      vinNumber: vinNumber,
    });

    if (code === 200 && data) {
      setVehicleInfo(data);
    } else if (code === 401) {
      setNotAuthorized(true);
      setErrorMsg(data);
    } else {
      setApiError(true);
      setErrorMsg(data);
    }
  };

  useEffect(() => {
    getInformationData({
      page: 0,
      vinNumber: vinNumber,
    });
  }, []);

  if (notAuthorized) {
    return <NotAuthorized title={errorMsg} />;
  }
  if (apiError) {
    return <Feedback />;
  }
  if (!vehicleInfo) {
    return <LottieLoad />;
  }

  return (
    <TwoColumn className="vechile-information">
      {vehicleInfo ? (
        <>
          <LeftVehileInformation vehicleInfo={vehicleInfo} />
          <RightVehicleInformation vehicleInfo={vehicleInfo} />
          {/* <BottomVehicleInformation /> */}
        </>
      ) : (
        <>No data availaible</>
      )}
    </TwoColumn>
  );
};

export default VehicleInformation;
