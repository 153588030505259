import Feedback from "./Feedback";
const ErrorPage = () => {

return(
  <div  className="page-wrapper admin_dashboard" >
    <h1> <Feedback/></h1>
    </div>
)

}

export default ErrorPage;