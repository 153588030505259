import React, { useEffect, useState } from "react";
import { mainAxiosInstance } from "../../../services/service";
import { PictorialRepresentationApi } from "../../../services/service-constants";
import "./index.scss";
import { Button } from "../../../components/common/Button/Button";
import SinglebarCard from "./SinglebarCard";
import { useDispatch, useSelector } from "react-redux";
import Feedback from "../../../components/common/ErrorComponents/Feedback";
import NotAuthorized from "../../../components/common/ErrorComponents/NotAuthorized";
import LottieLoad from "../../../components/common/ErrorComponents/LottieLoad";
import Breadcrumbs from "../../../components/common/Breadcrumb/Breadcrumbs";
import routes from "../../../utils/constants";

import { useNavigate } from "react-router";

const PictorialRepresentation = () => {
  const navigate = useNavigate();
  const [isExpanded, setExpanded] = useState(true);

  const [notAuthorized, setNotAuthorized] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("")

  const vin_Number = useSelector((state) => state.mainReducer.vin_Number);
  const [driverBehaviourSummaryData, setDriverBehaviourSummaryData] = useState(
    []
  );
  const [noDataFound, setNoDataFound] = useState(true);

  const getPageData = () => {
    let url = "";
    let params = {};
    url = `${PictorialRepresentationApi}`;
    params = {
      vin_number: vin_Number,
      organization_id: 1,
    };

    mainAxiosInstance
      .get(url, { params: params })
      .then((response) => {
        if (response.status === 200) {
          setDriverBehaviourSummaryData(response?.data);
          setNoDataFound(false);
        }
        if (response.status === 401) {
          setErrorMsg(response.data.detail || "You are not authorized");
          setNotAuthorized(true)
        }

      })
      .catch((err) => {
        if (err.response.status === 401) {
          setNotAuthorized(true)
          setErrorMsg(err.response.data?.details || "You are not authorized.");
        }
        else {
          setApiError(true);
          setErrorMsg(err.response.data.details || "No records Found")
          setNoDataFound(true);
        }

      });
  };

  const redirectToIdealBehaviour = () => {
    var pageType = {
      pathname:
        "/operational-analytics/driver-behaviour/summary/pictorial-representation/ideal-behaviour",
    };
    navigate(pageType, {
      // state: {
      //   pictorialData: driverBehaviourSummaryData?.pictorialRepresentation,
      //   driverScore: driverBehaviourSummaryData?.driverScore,
      // },
      replace: true,
    });
  };
  useEffect(() => {
    getPageData();
  }, []);

  if (notAuthorized) {
    return <NotAuthorized title={errorMsg} />;
  }
  if (apiError) {
    return <Feedback />;
  }


  return (
    <div className="driver-behaviour-summary-wrapper">
      <div className={` ${isExpanded ? "" : "expand"}`} >
        <Breadcrumbs routes={routes} />
      </div>
      <div className="summary-driver-behaviour">
        Driver Behaviour score is {driverBehaviourSummaryData?.driverScore}%
        (Good)
      </div>
      <>
        {" "}
        <div className="flex page-content-header"
          style={
            { "paddingTop": "0px", "marginTop": "-10px", "marginBottom": "10px" }
          }>
          <div className="page-options-container"></div>
          <div className="service-help-wrapper">
            <div className="service-help">
              <Button
                title="Ideal behaviour View"
                handleClick={() => redirectToIdealBehaviour()}
                styleClasses="btn btn-secondary-blue idealBehaviour"
              />
            </div>
          </div>
        </div>
        <div className="">
          <div className="picRepbar-container">
            {!noDataFound &&
              driverBehaviourSummaryData?.pictorialRepresentation?.map(
                (item, i) => {
                  return i <= 6 && <SinglebarCard item={item} />;
                }
              )}
          </div>
          <div className="picRepbar-container">
            {!noDataFound &&
              driverBehaviourSummaryData?.pictorialRepresentation?.map(
                (item, i) => {
                  return i >= 7 && <SinglebarCard item={item} />;
                }
              )}
          </div>
          <div className="picRepbar_footer">
            {!noDataFound && (
              <div className="picRepbar_footer--right">
                <div className="picRepbar_footer--row">
                  <span class="one">Ideal Behavior</span>
                  <span class="two">Good</span>
                </div>

                <span class="three">Average</span>
                <span class="four">Bad</span>
              </div>
            )}

          </div>
          {noDataFound && (
            <div >
              <LottieLoad />
            </div>
          )}
        </div>
      </>
    </div>
  );
};

export default PictorialRepresentation;
