import React, { useEffect } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import TabLayout from "../../../components/Layout/TabLayout";
import "./index.scss";

const ServiceGuide = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (
      location.pathname === "/maintenance-overview/diagnostics/service-guide"
    ) {
      navigate("/maintenance-overview/diagnostics/service-guide/architecture", {
        replace: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  const navLink = [
    {
      label: "Architecture",
      to: "/maintenance-overview/diagnostics/service-guide/architecture",
    },
    {
      label: "User Manual",
      to: "/maintenance-overview/diagnostics/service-guide/user-manual",
    },
    {
      label: "Augmented Reality",
      to: "/maintenance-overview/diagnostics/service-guide/augmented-reality",
    },
    {
      label: "Youtube Videos",
      to: "/maintenance-overview/diagnostics/service-guide/youtube-videos",
    },
    {
      label: "Internet Contents",
      to: "/maintenance-overview/diagnostics/service-guide/internet-contents",
    },
  ];
  return (
    <div className="full-width">
      <TabLayout tabLink={navLink} />
    </div>
  );
};

export default ServiceGuide;
