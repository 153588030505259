import React from "react";

export const Checkbox = ({
  title,
  handleClick,
  handleInputClick,
  styleClasses,
  alignProp,
  checkboxStyle,
  isChecked,
  disabled,
}) => {
  
  return (
    <div onClick={handleClick}>
      <label style={alignProp} className="filter-label">
        <div className="filter-label-title">{title}</div>
        <input
          onChange={() => {}}
          onClick={handleInputClick}
          checked={isChecked}
          defaultValue=""
          style={checkboxStyle}
          className={styleClasses}
          type="checkbox"
          disabled={disabled}
        />
        <span></span>
      </label>
    </div>
  );
};
