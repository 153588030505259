import React from "react";
import { useLocation, useNavigate } from "react-router";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import "../VehiclePopulation.scss";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const VehicleCard = ({
  img,
  text,
  secondText,
  thirdText,
  graphContainer,
}) => {
  return (
    <>
      <div>
        <p
          style={{
            position: "relative",
            bottom: "6px",
            left: "4px",
            fontSize: "13px",
            fontWeight: "500",
            marginBottom: "2px",
            width: "90%",
          }}
        >
          {text}
          <br />
          {secondText}
          <br />
          <span style={{ fontWeight: "400" }}>{thirdText}</span>
          <br />
        </p>
      </div>

      {graphContainer === "Up-down" ? (
        <div className="box-container">
          <div className="grid-item tab1"></div>
          <div className="grid-item tab2"></div>
          <div className="grid-item tab3"></div>
          <div className="grid-item tab4"></div>
          <div className="grid-item tab5"></div>
          <div className="grid-item tab6"></div>
        </div>
      ) : graphContainer === "Down-up" ? (
        <div className="box-container">
          <div className="grid-item tab6"></div>
          <div className="grid-item tab5"></div>
          <div className="grid-item tab4"></div>
          <div className="grid-item tab3"></div>
          <div className="grid-item tab2"></div>
          <div className="grid-item tab1"></div>
        </div>
      ) : (
        <>
          <img className="img-icon" src={img} alt="wa" />
        </>
      )}
    </>
  );
};

export const RightVehiclePagesCompareWithCardDiagnostic = ({
  img,
  text,
  containerId,
  isSelected,
  handleClick,
  containerName,
  pathName,
  dashboardType,
  FlagName,
}) => {
  const location = useLocation();
  const keyValue = parseInt(location.search.slice(5));
  const className =
    keyValue === containerId ? "select-conatiner-active" : "select-conatiner";
  const FlagNameData = useSelector((state) => state.mainReducer.Flag_data);
  const shouldBlink = FlagNameData?.includes(FlagName);
  const url = location.pathname;

  return (
    <>
      <NavLink
        to={{
          pathname: `/vp-saved-filter/comparision/GeneratedDashboard/${pathName}/${dashboardType}`,
          search: containerId !== null ? `?key=${containerId}` : "",
        }}
        className={className}
        onClick={() => handleClick(containerId)}
      >
        <div>
          <img
            className="image-vehicle"
            style={{ marginTop: "4px" }}
            src={img}
            alt={text}
          />
          <p
            style={{
              fontSize: "14px",
              fontWeight: "400",
              marginTop: "5px",
            }}
          >
            {text}
          </p>
        </div>
        {shouldBlink &&
        (url ===
          "/vp-saved-filter/comparision/GeneratedDashboard/diagnostics/cwf" ||
          url ===
            "/vp-saved-filter/comparision/GeneratedDashboard/prognostics/cwf") ? (
          <span class="blink"></span>
        ) : (
          <></>
        )}
      </NavLink>
    </>
  );
};

export const RightVehiclePagesCompareWithCardIcon = ({
  img,
  containerId,
  isSelected,
  handleClick,
  containerName,
  pathName,
  dashboardType,
}) => {
  const className = isSelected ? "select-conatiner-active" : "select-conatiner";
  return (
    <>
      <NavLink
        to={{
          pathname: `/vp-saved-filter/comparision/GeneratedDashboard/${pathName}/${dashboardType}`,
          search: containerId !== null ? `?key=${containerId}` : "",
        }}
        className={className}
        onClick={() => handleClick(containerId)}
      >
        <div>
          <img
            className="image-vehicle"
            style={{ marginTop: "4px", marginBottom: "10px", height: "15px" }}
            src={img}
          />
        </div>
      </NavLink>
    </>
  );
};

export const RightVehiclePagesContainerCard = ({
  img,
  text,
  containerId,
  isSelected,
  handleClick,
  containerName,
}) => {
  const className = isSelected
    ? "select-conatiner-active vcard--blink"
    : "select-conatiner vcard--blink";

  return (
    <NavLink
      to={{
        pathname: "/vehicle-population/diagnostic/pages",
        search: containerId !== null ? `?key=${containerId}` : "",
      }}
      className={className}
      onClick={() => handleClick(containerId)}
    >
      <div className="vcard--div">
        <img
          className="image-vehicle"
          style={{ marginTop: "4px" }}
          src={img}
          alt={text}
        />
        <p
          style={{
            fontSize: "14px",
            fontWeight: "400",
            marginTop: "5px",
          }}
        >
          {text}
        </p>
      </div>
    </NavLink>
  );
};

export const RightVehiclePagesContainerCardPrognostic = ({
  img,
  text,
  containerId,
  isSelected,
  handleClick,
  containerName,
}) => {
  const className = isSelected ? "select-conatiner-active" : "select-conatiner";

  return (
    <>
      {containerId === 15 ? (
        <>
          <NavLink
            to={{
              pathname: "/vehicle-population/prognostic/rul",
            }}
            className={({ isActive }) =>
              isActive ? "select-conatiner-active" : "select-conatiner"
            }
          >
            <div>
              <img
                className="image-vehicle"
                style={{ marginTop: "4px" }}
                src={img}
                alt={text}
              />
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  marginTop: "5px",
                }}
              >
                {text}
              </p>
            </div>
          </NavLink>
        </>
      ) : (
        <NavLink
          to={{
            pathname: "/vehicle-population/prognostic/pages",
            search: containerId !== null ? `?key=${containerId}` : "",
          }}
          className={className}
          onClick={() => handleClick(containerId)}
        >
          <div>
            <img
              className="image-vehicle"
              style={{ marginTop: "4px" }}
              src={img}
              alt={text}
            />
            <p
              style={{
                fontSize: "14px",
                fontWeight: "400",
                marginTop: "5px",
              }}
            >
              {text}
            </p>
          </div>
        </NavLink>
      )}
    </>
  );
};
