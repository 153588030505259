import React, { useState, useEffect } from "react";
import Prognasticsform from "./Prognasticsform";
import Generalform from "./Generalform";
import Diagonisticsform from "./Diagonisticsform";
import { styled } from "@mui/material/styles";
import back_arrow from "../../../assets/back-arrow.svg";
import blueback from "../assets/blueback.svg";
import "./linear.scss";
import Modal from "../../../components/common/Modal/Modal";
import ModalPopup from "../../../components/common/Modal/ModalPopup";
import { useLocation, useNavigate } from "react-router-dom";
import UndoIcon from "@mui/icons-material/Undo";
import moment from "moment";
import { Theme } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import button from "../../../components/common/Button/Button";

const StepsStyle = styled("div")(({ theme: Theme }) => ({
  display: "flex",
  justifyContent: " end",
  width: "45%",
  cursor: "pointer",
  "& .Mui-disabled .MuiStepConnector-line": {
    border: "2px dashed #A1B6DB !important",
  },
  "& .Mui-active .MuiStepConnector-line": {
    border: "2px dashed #446EB8 !important",
  },
  "& .Mui-completed .MuiStepConnector-line": {
    border: "2px dashed #446EB8 !important",
  },
  "& .MuiStepper-root": {
    padding: "0px 0px 24px 0px",
    width: "80%",
    cursor: "pointer",
  },
  "& .MuiStepIcon-root": {
    color: "#A1B6DB !important",
    width: "14px",
    height: "14px",
    position: "relative",
    top: " 7px",
    borderRadius: "50%",
  },
  "& text.MuiStepIcon-text": {
    visibility: "hidden",
  },
  "& .MuiStepLabel-label": {
    color: "#A1B6DB !important",
    fontSize: "14px",
    fontWeight: 600,
  },
  "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
    color: "#446EB8 !important",
    fontSize: "14px",
    fontWeight: 600,
  },
  "& .MuiStepLabel-label.Mui-completed": {
    color: "#446EB8 !important",
    fontSize: "14px",
    fontWeight: 600,
  },
  "& .MuiStepIcon-root.Mui-completed": {
    color: "#446EB8 !important",
    opacity: 1,
  },
  "& .MuiStepIcon-root.Mui-active": {
    color: "#446EB8 !important",
    opacity: 1,
  },
  "& .MuiStepLabel-label.MuiStepLabel-completed": {
    opacity: 1,
  },

  "& .Mui-completed .MuiSvgIcon-root": {
    background: "#446EB8 !important",
  },
}));

const ButtonStyle = styled("div")(({ theme }) => ({
  "& .MuiButton-root:hover": {
    backgroundColor: "#E58817 !important",
    color: "#fff",
    border: "none",
    fontWeight: 600,
    fontSize: "16px",
  },
  "& .MuiButton-root": {
    backgroundColor: "#E58817",
    color: "#fff",
    border: "none",
    fontWeight: 600,
    fontSize: "16px",
  },
}));

const BackButtonStyle = styled("div")(({ theme }) => ({
  " & .MuiButton-root": {
    color: "#000",
    fontWeight: 600,
    fontSize: "16px",
    //backgroundColor: " #EFF1F4",
    border: "none",
    width: "50px",
    height: "36px",
  },
  // "& .MuiButton-root:hover": {
  //   backgroundColor: "#EFF1F4",
  //   border: "none",
  // },
}));
const NextButtonStyle = styled("div")(({ theme }) => ({
  " & .MuiButton-root": {
    color: "#fff",
    fontWeight: 600,
    fontSize: "16px",
    fontFamily: "open sans",
    fontStyle: "normal",
    backgroundColor: "#E58817",
    textTransform: "capitalize",
    border: "none",
    boxShadow: "none",
    width: "95px",
    height: "30px",
  },
  "& .MuiButton-root:hover": {
    backgroundColor: "#E58817",
    border: "none",
    boxShadow: "none",
  },
}));

const FilterStepper = (props) => {
  //const classes = ThemeStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [skippedSteps, setSkippedSteps] = useState([]);
  const [open, setOpen] = useState(false);
  const [variable, setVariable] = useState(false);
  const location = useLocation();
  const [upperLimitData, setUpperLimitData] = useState(200000);
  const [lowerLimitData, setLowerLimitData] = useState(1);
  const [defaultInterval, setDefaultInterval] = useState("Miles");
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndtDate, setSelectedEndDate] = useState(new Date());
  const [showInput, setShowInput] = useState(true);
  const [showDatepicker, setShowDatepicker] = useState(false);
  const [selectedData, setSelectedData] = useState({
    make: "Ford",
    organizationId: 1,
  });
  const [selectedDiagonosticsData, setselectedDiagonosticsData] = useState({});
  const [selectedRULData, setSelectedRULData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const showPopup = localStorage.getItem("token");
  const [lowerLimitError, setLowerLimitError] = useState(false);
  const [upperLimitError, setUpperLimitError] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [buildFromDate, setBuildFromDate] = useState(null);
  const [buildEndDate, setBuildEndDate] = useState(null);
  const [isOnDtchange, setIsOnDtchange] = useState(false);

  const handleOnDateChange = (date, dtFor) => {
    setIsOnDtchange(true);
    if (dtFor === "f") {
      setBuildFromDate(date);
    }
    if (dtFor === "t") {
      setBuildEndDate(date);
    }
  };

  function getSteps() {
    return ["General", "Diagnostics", "Prognostics"];
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <Generalform
            filterChangeValues={filterChangeValues}
            setBuildFromDate={setBuildFromDate}
            setBuildEndDate={setBuildEndDate}
            buildEndDate={buildEndDate}
            buildFromDate={buildFromDate}
            handleOnDateChange={handleOnDateChange}
          />
        );

      case 1:
        return (
          <Diagonisticsform handleDiagonisticsData={handleDiagonisticsData} />
        );
      case 2:
        return (
          <Prognasticsform
            HandleRulInput={HandleRulInput}
            handleIntervalOptionClick={handleIntervalOptionClick}
            lowerLimitData={lowerLimitData}
            upperLimitData={upperLimitData}
            defaultInterval={defaultInterval}
            inputError={inputError}
          />
        );

      default:
        return " ";
    }
  }

  const saveToLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  useEffect(() => {
    if (location.pathname !== "vehicle-population/FormLayout") {
      localStorage.removeItem("selectedData");
      localStorage.removeItem("selectedDiagonosticsData");
      localStorage.removeItem("rul");
      localStorage.removeItem("lowerLimitData");
      localStorage.removeItem("upperLimitData");
      localStorage.removeItem("isFilterSaved");
    }
  }, [location.pathname]);

  const handleIntervalOptionClick = (val) => {
    setDefaultInterval(val);
    switch (val) {
      case "Miles":
        setShowInput(true);
        setShowDatepicker(false);
        setLowerLimitData(1);
        setUpperLimitData(200000);
        break;

      case "Hours":
        setShowInput(true);
        setShowDatepicker(false);
        setLowerLimitData(0);
        setUpperLimitData(1);
        break;

      case "Engine Run Time":
        setShowInput(true);
        setShowDatepicker(false);
        setLowerLimitData(0);
        setUpperLimitData(1);
        break;

      case "Key starts":
        setShowInput(true);
        setShowDatepicker(false);
        setLowerLimitData(1);
        setUpperLimitData(200000);
        break;

      default:
        setShowInput(true);
        setShowDatepicker(false);
        setLowerLimitData(1);
        setUpperLimitData(200000);
        break;
    }
  };

  const handlePopupOpen = () => {
    setOpen(true);
  };

  const handlePopupClose = () => {
    setOpen(false);
  };

  const steps = getSteps();

  const isStepOptional = (step) => {
    return step === 1 || step === 2;
  };

  const isStepSkipped = (step) => {
    return skippedSteps.includes(step);
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
    setSkippedSteps(skippedSteps.filter((skipItem) => skipItem !== activeStep));
  };

  const handleBack = () => {
    if (activeStep == 0) {
      redirectBack();
    } else if (activeStep == 2) {
      setActiveStep(activeStep - 1);
      setselectedDiagonosticsData({});
    } else if (activeStep == 1) {
      setActiveStep(activeStep - 1);
      setSelectedData({
        make: "Ford",
        organizationId: 1,
      });
    }
  };

  const redirectBack = () => {
    // next("/vehicle-population/diagnostic/top-10-dtc");
    next(location.state.path);
  };

  const HandleRulInput = (event) => {
    const { name, value } = event.target;
    if (name === "lowerLimitData") {
      setLowerLimitData(value);
    } else if (name === "upperLimitData") {
      setUpperLimitData(value);
    }
    // setInputError(!lowerLimitData || !upperLimitData);
    validateRulInput(name, value);
  };

  const validateRulInput = (name, value) => {
    if (value.length !== 0) {
      setInputError(false);
    } else {
      setInputError(true);
    }
  };

  const handleSkip = () => {
    if (!isStepSkipped(activeStep)) {
      setSkippedSteps([...skippedSteps, activeStep]);
    }
    if (activeStep < 2) {
      setselectedDiagonosticsData({});
      setInputError("");
      setActiveStep(activeStep + 1);
    } else {
      if (lowerLimitData !== "" && upperLimitData !== "") {
        // redirectBack();
        localStorage.setItem("selectedData", JSON.stringify(selectedData));
        localStorage.setItem(
          "selectedDiagonosticsData",
          JSON.stringify(selectedDiagonosticsData)
        );
        localStorage.setItem("isFilterSaved", false);
        // next("/vehicle-population/diagnostic/top-10-dtc");
        next(location.state.path);
      } else {
        setInputError(true);
      }
    }
  };
  const next = useNavigate();
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleCloseModal1 = () => {
    setShowModal1(false);
  };

  useEffect(() => {
    if (isOnDtchange) {
      setSelectedData({
        ...selectedData,
        fromBuildDate: moment(buildFromDate).format("yyyy-MM-DD"),
        toBuildDate: moment(buildEndDate).format("yyyy-MM-DD"),
      });
    }
  }, [buildFromDate, buildEndDate]);

  const handleNxtOnclick = () => {
    if (activeStep === 0 && !isDataSelected()) {
    } else if (activeStep === 2) {
      if (lowerLimitData !== "" && upperLimitData !== "") {
        // redirectBack();
        localStorage.setItem("selectedData", JSON.stringify(selectedData));
        localStorage.setItem(
          "selectedDiagonosticsData",
          JSON.stringify(selectedDiagonosticsData)
        );
        localStorage.setItem("isFilterSaved", false);
        localStorage.setItem("rul", defaultInterval);
        localStorage.setItem("lowerLimitData", lowerLimitData);
        localStorage.setItem("upperLimitData", upperLimitData);
        // next("/vehicle-population/diagnostic/top-10-dtc");
        if (location.state.path === "/vp-saved-filter/savedfilter") {
          next("/vehicle-population/diagnostic/top-10-dtc");
        } else {
          next(location.state.path);
        }
      }
    } else {
      setInputError("");
      handleNext();
    }
  };

  const filterChangeValues = (name, value) => {
    // setSelectedData({ ...selectedData, [name]: value });
    if (value?.length > 0) {
      setSelectedData({ ...selectedData, [name]: value });
    } else {
      const updatedObject = { ...selectedData };
      delete updatedObject[name];
      setSelectedData(updatedObject);
    }
  };

  const handleDiagonisticsData = (name, value) => {
    // setselectedDiagonosticsData({
    //   ...selectedDiagonosticsData,
    //   [name]: value,
    // });
    if (value?.length > 0) {
      setselectedDiagonosticsData({
        ...selectedDiagonosticsData,
        [name]: value,
      });
    } else {
      const updatedObject = { ...selectedDiagonosticsData };
      delete updatedObject[name];
      setselectedDiagonosticsData(updatedObject);
    }
  };

  const handleRUL = (selectedOption) => {
    const { name, value } = selectedOption.target;
    setSelectedRULData({ ...selectedRULData, [name]: value });
  };

  const isDataSelected = () => {
    let valid = false;
    Object.values(selectedData).forEach(
      (val) => val?.length > 0 && (valid = true)
    );
    return valid;
  };

  const isDiagnosticsDataSelected = () => {
    let valid = false;
    Object.values(selectedDiagonosticsData).forEach(
      (val) => val?.length > 0 && (valid = true)
    );
    return valid;
  };
  const isRULDataSelcted = () => {
    let valid = false;
    Object.values(selectedRULData).forEach(
      (val) => val?.length > 0 && (valid = true)
    );
    return valid;
  };

  return (
    <>
      <div className="top">
        <BackButtonStyle>
          <Button
            variant="outlined"
            color="primary"
            //className={classes.button}
            //sx={{ backgroundColor: "red" }}
            onClick={handleBack}
            endIcon={<KeyboardBackspaceIcon />}
          ></Button>
        </BackButtonStyle>

        <StepsStyle>
          <Stepper alternativeLabel activeStep={activeStep}>
            {steps.map((step, index) => {
              const labelProps = {};
              const stepProps = {};
              if (isStepOptional(index)) {
                labelProps.optional = (
                  <Typography
                    variant="caption"
                    align="center"
                    style={{ display: "block" }}
                  ></Typography>
                );
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step {...stepProps} key={index}>
                  <StepLabel {...labelProps}>{step}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </StepsStyle>
      </div>
      <div className="generate-vehicle-population left-class">
        Generate Vehicle population Reports:{steps[activeStep]}
      </div>
      <form>{getStepContent(activeStep)}</form>
      <div
        className="BottomButtons"
        style={{
          padding: "0px 30px",
          display: "flex",
          justifyContent: "flex-end",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <button
          //className={classes.button}
          //disabled={activeStep === 0}
          onClick={handleBack}
          className="btnn"
          style={{
            margin: "7px 25px",
            padding: "0px",
          }}
        >
          <img src={blueback} alt="wa" />
        </button>
        {isStepOptional(activeStep) && (
          <button
            className="btn-stepper-secondary btn-secondary-blue "
            onClick={handleSkip}
          >
            Skip
          </button>
        )}

        <button
          className={
            activeStep === 0 && Object.values(selectedData).length <= 2
              ? "btn-stepper-primary btn-primary-blue disabled"
              : "btn-stepper-primary btn-primary-blue"
          }
          // className={`btn-stepper-primary btn-primary-blue ${
          //   activeStep === 0 && !isDataSelected() ? "nxtbtn-disabled" : ""
          // }`}
          onClick={handleNxtOnclick}
          disabled={activeStep === 0 && Object.values(selectedData).length <= 2}
        >
          Next
        </button>

        {showModal && showPopup && (
          <div style={modalStyle}>
            <div style={modalContentStyle}>
              <p>You need to select atleast one field from the given options</p>
              <button
                onClick={handleCloseModal}
                style={{ backgroundColor: "#e58817", color: "#fff" }}
              >
                OK
              </button>
            </div>
          </div>
        )}
        {showModal1 && showPopup && (
          <div style={modalStyle}>
            <div style={modalContentStyle}>
              <p>
                <b>
                  You need to select atleast one from Prognastics and
                  Diagonostics
                </b>
              </p>
              <button
                onClick={handleCloseModal1}
                style={{ backgroundColor: "#e58817", color: "#fff" }}
              >
                OK
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
const modalStyle = {
  position: "fixed",
  background: "#000000aa",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 1111,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
};

const modalContentStyle = {
  textAlign: "center",
  position: "fixed",
  top: "50%",
  left: "50%",
  width: 450,
  transform: "translate(-50%, -50%)",
  background: "white",
  padding: "20px",
  borderRadius: "10px",
  display: "",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 11111,
};

export default FilterStepper;
