import React from "react";
import { useState, useEffect } from "react";
import "../VehiclePopulation.scss";
import Select from "../../../components/common/Select/Select";
import SelectFromApi from "./SelectFromApi";
import "./linear.scss";
import { useDispatch, useSelector } from "react-redux";
import Datepicker from "../../../components/Datepicker/datepicker";
import moment from "moment";

const Prognasticsform = ({
  inputError,
  HandleRulInput,
  handleIntervalOptionClick,
  upperLimitData,
  lowerLimitData,
  defaultInterval,
}) => {
  const [noDataFound, setNoDataFound] = useState(true);
  const [errorMsg, setErrorMsg] = useState(true);
  const [apiError, setApiError] = useState(false);
  //const [defaultInterval, setDefaultInterval] = useState("Miles");
  const vinNumber = useSelector((state) => state.mainReducer.vin_Number);
  const [chartData, setChartData] = useState();
  const interval = ["Miles", "Hours", "Engine Run Time", "Key starts"];
  const [showInput, setShowInput] = useState(true);
  const [showDatepicker, setShowDatepicker] = useState(false);
  //  const [upperLimitData, setUpperLimitData] = useState(200000);
  //  const [lowerLimitData, setLowerLimitData] = useState(1);
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndtDate, setSelectedEndDate] = useState(new Date());
  const [paramData, setParamData] = useState({});

  const handleOnStartDateChange = (dates) => {
    const startDate = moment(dates).format("yyyy-MM-DD");
    setSelectedStartDate(startDate);
  };

  const handleOnEndDateChange = (dates) => {
    const endDate = moment(dates).format("yyyy-MM-DD");
    setSelectedEndDate(endDate);
  };

  return (
    <div>
      {/* <div className="filters" id="RUL">
        <label htmlFor="RUL">RUL</label>
        <SelectFromApi
          name={"RUL"}
          handleOnChange={handleRUL}
          filterValue={"RUL"}
        />
        
      </div> */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "10px",
          color: "red",
        }}
      >
        {inputError ? "Lower Limit and Upper Limit are required" : ""}
      </div>
      <div className="rul-container">
        <div>
          {" "}
          <label htmlFor="RUL">RUL</label>
        </div>
        <div className="page-options-container metric-box d-flex">
          <Select
            options={interval}
            defaultSelected={defaultInterval}
            handleOptionClick={(val) => handleIntervalOptionClick(val)}
            secondaryContainerClass="top-10-input-box tab"
            containerClass="top-10-dtc"
            // preIcon={intervalPreIcon}
            //onChange={handleRUL}
          />

          {showInput && (
            <>
              <input
                type="text"
                className="Dtc-value-input"
                placeholder={`Enter Lower Limit`}
                value={lowerLimitData}
                name="lowerLimitData"
                onChange={(e) => HandleRulInput(e)}
                maxLength={10}
              />
              <input
                type="text"
                className="Dtc-value-input"
                placeholder={`Enter Upper Limit`}
                value={upperLimitData}
                name="upperLimitData"
                onChange={(e) => HandleRulInput(e)}
                maxLength={10}
              />
            </>
          )}
          {showDatepicker && (
            <div
              style={{
                display: "flex",
                position: "relative",
                bottom: "5px",
              }}
            >
              <Datepicker
                onChange={handleOnStartDateChange}
                classContainer="top-10-dtc-date"
                secondaryClassContainer="input-value-top-10-dtc"
              />

              <Datepicker
                onChange={handleOnEndDateChange}
                classContainer="top-10-dtc-date"
                secondaryClassContainer="input-value-top-10-dtc"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Prognasticsform;
