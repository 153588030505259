import React, { useEffect, useState } from "react";
import { NavLink, Outlet, useNavigate, useLocation } from "react-router-dom";
import TabLayout from "../../../components/Layout/TabLayout";
import "../VehiclePopulation.scss";
import plusIcon from "../assets/plus-icon.svg";
import compare_vin from "../assets/compare-with-vin-icon.svg";
import SearchIcon from "../../../assets/search-icon-white.svg";
import { VehiclePopulationDashBoard } from "../../../services/service-constants";
import { mainAxiosInstance } from "../../../services/service";
import useCloseOnClickOutside from "../../../hooks/useCloseOnClickOutside";
import ModalPopup from "../../../components/common/Modal/ModalPopup";
import { useDispatch } from "react-redux";
import {
  addFirstFilter,
  addSecondFilter,
  addVinFilter,
} from "../../../store/actions/fetchActions";
import close from "../../../assets/close.svg";

const HealthCheck = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [defFilterData, setDefFilterData] = useState(null);
  const [filterData, setFilterData] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [data, setData] = useState([]);
  const [comparePopup, setComparePopup] = useState(false);
  const [errorModel, setErrorModel] = useState("");
  const [compareVinNumber, setCompareVinNumber] = useState("");
  const [progFilter, setProgFilter] = useState(null);
  const pathName = location.pathname.includes("diagnostics")
    ? "diagnostics"
    : "prognostics";
  const [showModal, setShowModal] = useState(false);
  const [filterCardname, setFilterCardname] = useState("");
  const [saveModal, setSaveModal] = useState(false);
  const [filterErrModel, setFilterErrModel] = useState("");

  const HandleClick = () => {
    const FilterSelectedValues = localStorage.getItem("isFilterSaved");
    if (FilterSelectedValues !== "true" && FilterSelectedValues !== null) {
      setShowModal(true);
    } else {
      setShowModal(false);
      navigate("/vehicle-population/FormLayout", {
        state: { path: location.pathname },
      });
      localStorage.removeItem("filterSelectedValues");
    }
  };

  const modalStyle = {
    position: "fixed",
    background: "#000000aa",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1111,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  };

  const modalContentStyle = {
    textAlign: "left",
    position: "fixed",
    top: "50%",
    left: "50%",
    width: 400,
    transform: "translate(-50%, -50%)",
    background: "white",
    padding: "20px",
    borderRadius: "10px",
    display: "",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 11111,
  };

  const closeModelPopup = () => {
    setShowModal(false);
    setSaveModal(false);
  };

  const handleSaveFilter = () => {
    setFilterCardname("");
    setShowModal(false);
    setSaveModal(true);
  };

  const handleRmFilter = () => {
    setShowModal(false);
    navigate("/vehicle-population/FormLayout", {
      state: { path: location.pathname },
    });
    localStorage.removeItem("filterSelectedValues");
  };

  const handleInputCardnameChange = (e) => {
    setFilterCardname(e.target.value);
    validateInput(e.target.name, e.target.value);
  };

  const validateInput = (name, value) => {
    let errors = "";
    switch (name) {
      case "filterCardname":
        errors =
          value.length === 0
            ? "Filter name should not be empty"
            : value.length >= 30
              ? "Filter name should not be more than 30 characters"
              : "";
        break;
      default:
        break;
    }
    setFilterErrModel(errors);
  };

  const handleSaveCancel = () => {
    setSaveModal(false);
    setFilterErrModel("");
  };

  const saveToFilterCard = async () => {
    let params = {};
    let selectedData = JSON.parse(localStorage.getItem("selectedData"));
    let selectedDiagonosticsData = JSON.parse(
      localStorage.getItem("selectedDiagonosticsData")
    );
    let rul = localStorage.getItem("rul");
    let lowerLimitData = localStorage.getItem("lowerLimitData");
    let upperLimitData = localStorage.getItem("upperLimitData");
    if (rul !== null) {
      switch (rul) {
        case "Miles": {
          params = {
            fromRulMiles: parseInt(lowerLimitData),
            toRulMiles: parseInt(upperLimitData),
            rul: "by_miles",
          };
          break;
        }

        case "Engine Run Time": {
          params = {
            fromRulEngineRunTime: parseInt(lowerLimitData),
            toRulEngineRunTime: parseInt(upperLimitData),
            rul: "by_engine_runtime",
          };
          break;
        }

        case "Hours": {
          params = {
            fromRulHours: parseInt(lowerLimitData),
            toRulHours: parseInt(upperLimitData),
            rul: "by_hours",
          };
          break;
        }

        case "Key starts": {
          params = {
            fromRulKeyStarts: parseInt(lowerLimitData),
            toRulKeyStarts: parseInt(upperLimitData),
            rul: "by_key_starts",
          };
          break;
        }

        default: {
          params = {
            fromRulMiles: parseInt(lowerLimitData),
            toRulMiles: parseInt(upperLimitData),
            rul: "by_miles",
          };
        }
      }
    }

    if (filterCardname !== "") {
      const url = `${VehiclePopulationDashBoard.saved_filters}`;
      let filterType = rul ? "Prognostics" : "Diagnostics";
      let filterData = {
        ...selectedData, //for general data
        ...selectedDiagonosticsData, // for diagnostics data
        ...params, //for rul data
      };
      let data = {
        make: "Ford",
        filterName: filterCardname,
        filterType: filterType,
        ...filterData,
      };
      return mainAxiosInstance
        .post(url, data)
        .then((response) => {
          if (response.status === 200) {
            removeFilterAndRedirect();
            setSaveModal(false);
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            setFilterErrModel("You are not authorized");
          } else {
            setFilterErrModel("Something went wrong ");
          }
        });
    }
  };

  const removeFilterAndRedirect = () => {
    localStorage.removeItem("selectedData");
    localStorage.removeItem("selectedDiagonosticsData");
    localStorage.removeItem("rul");
    localStorage.removeItem("lowerLimitData");
    localStorage.removeItem("upperLimitData");
    localStorage.removeItem("isFilterSaved");
    navigate("/vehicle-population/FormLayout", {
      state: { path: location.pathname },
    });
    localStorage.removeItem("filterSelectedValues");
  };

  useEffect(() => {
    const selectedData = JSON.parse(localStorage.getItem("selectedData"));
    const selectedDiagonsticsData = JSON.parse(
      localStorage.getItem("selectedDiagonosticsData")
    );
    const fromMilesStr = JSON.parse(localStorage.getItem("lowerLimitData"));
    const fromMiles = parseInt(fromMilesStr, 10);
    let toMilesStr = JSON.parse(localStorage.getItem("upperLimitData"));
    const toMiles = parseInt(toMilesStr, 10);
    let FilterSelectedValues = {};
    if (selectedData !== null) {
      FilterSelectedValues = {
        ...selectedData,
        ...selectedDiagonsticsData,
        fromRulMiles: fromMiles,
        toRulMiles: toMiles,
      };
    } else {
      FilterSelectedValues = {
        ...selectedData,
        ...selectedDiagonsticsData,
      };
    }
    setFilterData(FilterSelectedValues);
  }, []);

  useEffect(() => {
    let url = `${VehiclePopulationDashBoard.defaultApi}`;
    mainAxiosInstance
      .get(url)
      .then((response) => {
        setDefFilterData(response.data);
      })
      .catch((er) => {
        throw er;
      });
    //getSFilteredData();
  }, []);

  useEffect(() => {
    if (userInput.length > 3) {
      getSFilteredData();
    } else {
      getSFilteredData();
    }
  }, [userInput]);

  let selectRef = useCloseOnClickOutside(() => {
    setShowDropdown(false);
  });

  const getSFilteredData = () => {
    const url = `${VehiclePopulationDashBoard.search_filter}`;
    let params = {
      make: "Ford",
      searchValue: userInput,
      filterType: "All",
      page: 0,
      size: 50,
    };
    mainAxiosInstance
      .get(url, { params: params })
      .then((response) => {
        if (response.status === 200) {
          setData(response.data?.content || []);
          // setApiError(false);
        }
        if (response.status === 401) {
          // setErrorMsg(response.data?.details || "You are not authorized.");
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          // setApiError(true);
        } else {
          // setApiError(true);
        }
      });
  };

  const handleCompare = () => {
    setShowDropdown(!showDropdown);
  };

  const handleSearch = (e) => {
    setUserInput(e.target.value);
  };

  const handleFilterNameClick = (value) => {
    let filteredValues = {};
    if (Object.keys(filterData).length === 0) {
      filteredValues = defFilterData;
    } else {
      filteredValues = filterData;
    }
    dispatch(addFirstFilter(filteredValues));
    dispatch(addSecondFilter(value));
    dispatch(addVinFilter(null));
    navigate("/vp-saved-filter/health-check/comparision", {
      replace: true,
      state: {
        diagnosesState: pathName === "diagnostics" ? 0 : 1,
      },
    });
  };

  const handleCompareVin = () => {
    setComparePopup(true);
  };

  useEffect(() => {
    const selectedData = JSON.parse(localStorage.getItem("selectedData"));
    const selectedDiagonsticsData = JSON.parse(
      localStorage.getItem("selectedDiagonosticsData")
    );
    const selectedRulData = localStorage.getItem("rul");
    const RULlowerLimitData = localStorage.getItem("lowerLimitData");
    const RULupperLimitData = localStorage.getItem("upperLimitData");
    let params = {};
    switch (selectedRulData) {
      case "Miles": {
        params = {
          fromRulMiles: parseInt(RULlowerLimitData),
          toRulMiles: parseInt(RULupperLimitData),
          rul: "by_miles",
        };
        break;
      }

      case "Engine Run Time": {
        params = {
          fromRulEngineRunTime: parseInt(RULlowerLimitData),
          toRulEngineRunTime: parseInt(RULupperLimitData),
          rul: "by_engine_runtime",
        };
        break;
      }

      case "Hours": {
        params = {
          fromRulHours: parseInt(RULlowerLimitData),
          toRulHours: parseInt(RULupperLimitData),
          rul: "by_hours",
        };
        break;
      }

      case "Key starts": {
        params = {
          fromRulKeyStarts: parseInt(RULlowerLimitData),
          toRulKeyStarts: parseInt(RULupperLimitData),
          rul: "by_key_starts",
        };
        break;
      }

      default: {
        params = {};
      }
    }

    const ProgFilterSelectedValues = {
      ...selectedData,
      ...selectedDiagonsticsData,
      ...params,
    };
    setProgFilter(ProgFilterSelectedValues);
  }, [location.pathname]);

  const handleCompareVinNumber = (value) => {
    setCompareVinNumber(value);
    const hasSpecialChar = /[^\w]/.test(value);
    if (value.length === 0) {
      setErrorModel("VIN number should not be empty");
    } else if (hasSpecialChar) {
      setErrorModel("VIN number should not contain space or special characters");
    } else if (value.includes(" ")) {
      setErrorModel("VIN number should not contain space");
    } else {
      setErrorModel("");
    }
  };

  const ModelCloseButton = () => {
    setComparePopup(false);
  };

  const submitVinCompare = () => {
    let filteredValues = {};
    if (Object.keys(filterData).length === 0) {
      filteredValues = defFilterData;
    } else {
      filteredValues = filterData;
    }
    dispatch(addFirstFilter(filteredValues));
    dispatch(addSecondFilter(null));
    dispatch(addVinFilter(compareVinNumber));
    navigate("/vp-saved-filter/health-check/comparision", {
      replace: true,
    });
  };

  useEffect(() => {
    if (location.pathname === "/vehicle-population/health-check") {
      navigate("/vehicle-population/health-check/diagnostic", {
        replace: true,
      });
      const selectedData = JSON.parse(localStorage.getItem("selectedData"));
      const selectedDiagonsticsData = JSON.parse(
        localStorage.getItem("selectedDiagonosticsData")
      );
      const FilterSelectedValues = {
        ...selectedData,
        ...selectedDiagonsticsData,
      };
      setFilterData(FilterSelectedValues);
    }
  }, [location.pathname]);

  const filteredFilterData = (key) => {
    if (location.pathname.includes("diagnostic")) {
      return (
        key !== "id" &&
        key !== "userRole" &&
        key !== "organizationId" &&
        key !== "filterName" &&
        key !== "filterType" &&
        key !== "username" &&
        key !== "lastUpdated" &&
        key !== "rul" &&
        key !== "type" &&
        key !== "fromMiles" &&
        key !== "toMiles" &&
        key !== "fromRulMiles" &&
        key !== "toRulMiles" &&
        key !== "fromRulHours" &&
        key !== "fromRulEngineRunTime" &&
        key !== "toRulEngineRunTime" &&
        key !== "toRulHours" &&
        key !== "toRulKeyStarts" &&
        key !== "fromRulKeyStarts"
      );
    } else {
      return (
        key !== "id" &&
        key !== "userRole" &&
        key !== "organizationId" &&
        key !== "filterName" &&
        key !== "filterType" &&
        key !== "username" &&
        key !== "lastUpdated" &&
        key !== "rul" &&
        key !== "fromMiles" &&
        key !== "toMiles" &&
        key !== "type" &&
        key !== "lastUpdated"
      );
    }
  };

  return (
    <div className="full-width ">
      <div className="vehicle-population-container">
        <div className="top-button-container">
          <div className="inp">
            {location.pathname ===
              "/vehicle-population/health-check/diagnostic" ? (
              <>
                {filterData && Object.keys(filterData).length > 0 && (
                  <div
                    className="display-selected-data"
                    title={Object.keys(filterData)
                      .filter(
                        (key) =>
                          key !== "id" &&
                          key !== "userRole" &&
                          key !== "organizationId" &&
                          key !== "filterName" &&
                          key !== "filterType" &&
                          key !== "username" &&
                          key !== "lastUpdated" &&
                          key !== "rul" &&
                          key !== "type" &&
                          key !== "fromRulMiles" &&
                          key !== "toRulMiles" &&
                          key !== "fromRulMiles" &&
                          key !== "toRulMiles" &&
                          key !== "fromRulHours" &&
                          key !== "fromRulEngineRunTime" &&
                          key !== "toRulEngineRunTime" &&
                          key !== "toRulHours" &&
                          key !== "toRulKeyStarts" &&
                          key !== "fromRulKeyStarts" &&
                          filterData[key] !== null
                      )
                      .map((key) => key.charAt(0).toUpperCase() + key.slice(1))
                      .join("_")}
                  >
                    {Object.keys(filterData)
                      .filter(
                        (key) =>
                          key !== "id" &&
                          key !== "userRole" &&
                          key !== "organizationId" &&
                          key !== "filterName" &&
                          key !== "filterType" &&
                          key !== "username" &&
                          key !== "fromRulMiles" &&
                          key !== "toRulMiles" &&
                          key !== "lastUpdated" &&
                          key !== "rul" &&
                          key !== "fromRulMiles" &&
                          key !== "toRulMiles" &&
                          key !== "fromRulHours" &&
                          key !== "fromRulEngineRunTime" &&
                          key !== "toRulEngineRunTime" &&
                          key !== "toRulHours" &&
                          key !== "toRulKeyStarts" &&
                          key !== "fromRulKeyStarts" &&
                          filterData[key] !== null
                      )
                      .map((key) => key.charAt(0).toUpperCase() + key.slice(1))
                      .join("_")}
                  </div>
                )}
              </>
            ) : (
              <>
                {progFilter && Object.keys(progFilter).length > 0 && (
                  <div
                    className="display-selected-data"
                    title={Object.keys(progFilter)
                      .filter(
                        (key) =>
                          key !== "id" &&
                          key !== "userRole" &&
                          key !== "organizationId" &&
                          key !== "filterName" &&
                          key !== "filterType" &&
                          key !== "username" &&
                          key !== "lastUpdated" &&
                          key !== "rul" &&
                          key !== "type" &&
                          progFilter[key] !== null
                      )
                      .map((key) => key.charAt(0).toUpperCase() + key.slice(1))
                      .join("_")}
                  >
                    {Object.keys(progFilter)
                      .filter(
                        (key) =>
                          key !== "id" &&
                          key !== "userRole" &&
                          key !== "organizationId" &&
                          key !== "filterName" &&
                          key !== "filterType" &&
                          key !== "username" &&
                          progFilter[key] !== null
                      )
                      .map((key) => key.charAt(0).toUpperCase() + key.slice(1))
                      .join("_")}
                  </div>
                )}
              </>
            )}
          </div>
          <div className="top-button-container-icons">
            <p className="plus-icon-button" onClick={HandleClick}>
              <img className="" src={plusIcon} alt="wa" />
            </p>
            <p className="compare-with-vin-button"
              //onClick={handleCompareVin}
              style={{ "backgroundColor": "#446eb8", opacity: "70%" }}>
              Compare with VIN
              <img className="" src={compare_vin} alt="wa" />
            </p>
            <p className="compare-button" onClick={handleCompare}>
              Compare
              <img className="" src={compare_vin} alt="wa" />
            </p>
            {showDropdown ? (
              <div className="compare-dropdown" ref={selectRef}>
                <div className="compare-dropdown-search">
                  <img src={SearchIcon} alt="search" className="search_image" />
                  <input
                    type="text"
                    className="search_field"
                    placeholder="Search for attribute"
                    value={userInput}
                    onChange={handleSearch}
                  // onKeyPress={onEnter}
                  />
                </div>
                <div className="compare-dropdown-values">
                  {data.map((values) => {
                    return (
                      <div
                        className="compare-dropdown-value"
                        onClick={() => handleFilterNameClick(values)}
                      >
                        {values.filterName}
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        {comparePopup ? (
          <>
            <ModalPopup
              firstParagraph={"Enter VIN number here"}
              firstButton="Cancel"
              secondButton="Compare"
              onValueChange={handleCompareVinNumber}
              closeModal={ModelCloseButton}
              errorModel={errorModel}
              onClick={submitVinCompare}
              inputValue={"DH34ASD7SDFF84742"}
              placeholder={"Enter the VIN number"}
              buttonClassName={
                compareVinNumber.length > 0 && errorModel.length === 0
                  ? "btn-primary-blue"
                  : "btn-primary-blue disabled"
              }
              disableSecondButton={
                compareVinNumber.length > 0 && errorModel.length === 0
                  ? false
                  : true
              }
            />
          </>
        ) : (
          <></>
        )}
        {showModal && (
          <div style={modalStyle}>
            <div style={modalContentStyle}>
              <div
                className="discard-card-modal-close"
                onClick={closeModelPopup}
              >
                <img src={close} alt="close" />
              </div>
              <p style={{ marginTop: "6px" }}>
                Do you want to discard the changes
              </p>
              <div className="discardBtns">
                <button
                  onClick={handleSaveFilter}
                  className="btn-secondary-blue"
                >
                  No
                </button>
                <button
                  onClick={handleRmFilter}
                  style={{ width: 80, marginLeft: "1em" }}
                  className="btn-primary-blue"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        )}
        {saveModal && (
          <div style={modalStyle}>
            <div style={modalContentStyle}>
              <span className="error-text">{filterErrModel}</span>
              <div
                className="discard-card-modal-close"
                onClick={closeModelPopup}
              >
                <img src={close} alt="close" />
              </div>
              <p style={{ marginTop: "6px" }}>Save this filter as</p>
              <input
                name="filterCardname"
                type="text"
                className="saveInput"
                value={filterCardname}
                onChange={handleInputCardnameChange}
                maxLength={30}
              />
              <div className="modBtns">
                <button
                  onClick={handleSaveCancel}
                  className="btn-secondary-blue"
                >
                  Cancel
                </button>
                <button
                  onClick={saveToFilterCard}
                  className={
                    filterCardname.length >= 1
                      ? "btn-primary-blue"
                      : "btn-primary-blue disabled"
                  }
                  style={{ width: 100, marginLeft: "1em" }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
        <div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default HealthCheck;
