import React from "react";
import { useEffect, forwardRef } from "react";
import {
  changeUserRole,
  getFilterUserlist,
  getUserlist,
  userApporval,
  userDecline,
  userDelete,
} from "./api";
import HeaderCard from "./components/HeaderCard";
import { ReactComponent as TotalUsersIcon } from "./assets/total-users.svg";
import SearchIcon from "./assets/search.svg";
import "./index.scss";
import { useState } from "react";
import { ReactComponent as ConfirmUserIcon } from "./assets/confirm-user.svg";
import { ReactComponent as EditUserIcon } from "./assets/edit-user.svg";
import { ReactComponent as DeleteUserIcon } from "./assets/delete-user.svg";
import { ReactComponent as ViewUserIcon } from "./assets/view-user.svg";
import { roleType, statusView, userRoleView } from "./utils/index";
import Modal from "../../components/common/Modal/Modal";
import { status } from "./utils";
import moment from "moment";
import { Button } from "../../components/common/Button/Button";
import { toast, Toaster } from "react-hot-toast";
import Select from "../../components/common/Select/Select";
import useEffectExcludingMount from "../../hooks/useEffectExcludingMount";
import Pagination from "../../components/common/Pagination/index";
import ReactDatePicker from "react-datepicker";
import NotAuthorized from "../../components/common/ErrorComponents/NotAuthorized";
import Feedback from "../../components/common/ErrorComponents/Feedback";
import LottieLoad from "../../components/common/ErrorComponents/LottieLoad";
import Daterangepicker from "../../components/Datepicker/daterangepicker";
import Datepicker from "../../components/Datepicker/datepicker";
import "../VehiclePopulation/VehiclePopulation.scss";

const AdminDashboard = () => {
  const [data, setData] = useState(null);
  const [notAuthorized, setNotAuthorized] = useState(false);
  const [users, setUsers] = useState(null);
  const [apiError, setApiError] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [actionUser, setActionUser] = useState(null);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [accessLevel, setAccessLevel] = useState(null);
  const [isApproveOpen, setIsApproveOpen] = useState(false);
  const [remarkPopup, setRemarkPopup] = useState(false);
  const [approveAccessLevel, setApproveAccessLevel] = useState(null);
  const [remarksText, setRemarksText] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [fromDate, setFromDate] = useState(new Date());
  const interval = ["Name", "Mail Id", "Requested Date", "Status"];
  const allStatus = ["All", "Pending", "Confirmed", "Declined", "Removed"];
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [apiSelectStatus, setApiSelectStatus] = useState("");
  const [apiInterval, setApiInterval] = useState("firstName");
  const [defaultInterval, setDefaultInterval] = useState("Requested Date");
  const [showStatusSelect, setShowStatusSelect] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [errormsg, setErrormsg] = useState("");

  const [selectedStartDate, setStartDate] = useState(new Date());
  const [selectedEndDate, setEndDate] = useState(new Date());

  const [pagenation, setPagenation] = useState({
    totalpages: 0,
    size: 0,
    currentPageNo: 0,
  });
  const nonEditableUsers = [status.removed, status.declined];

  const getUsers = async () => {
    const { code, data } = await getUserlist();
    if (code === 200 && data) {
      setData(data);
      setPagenation({
        totalpages: data.users.totalPages,
        size: data.users.size,
        currentPageNo: data.users.pageable.pageNumber,
      });
      setApiError(false);
    } else if (code === 401) {
      setNotAuthorized(true);
      setErrormsg(data);
    } else {
      setErrormsg(data);
      setApiError(true);
    }
  };
  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (actionUser) {
      setAccessLevel(actionUser.userRole);
    }
  }, [actionUser]);

  useEffect(() => {
    if (data) {
      const usersData = data.users.content;
      usersData.forEach((item) => {
        const utcTime = new Date(item.createdDate).getTime();
        const etOffset = -9 * 60 * 60 * 1000 - 30 * 60 * 1000;
        const EST = new Date(utcTime + etOffset);
        item.createdDate = new Date(EST);
      });
      usersData.sort((a, b) => b.createdDate - a.createdDate);
      setUsers(usersData);
    }
  }, [data]);

  const onChange = (e) => {
    setAccessLevel(e.target.value);
  };
  const onApproveChange = (e) => {
    setApproveAccessLevel(e.target.value);
  };

  const onAccessChange = async () => {
    const payload = {
      emailId: actionUser.emailId,
      userRole: accessLevel,
    };
    try {
      const { code, data } = await changeUserRole(payload);
      if (code === 200) {
        setIsEditOpen(false);
        toast.success(`${actionUser.emailId} access changed successfully`);
        getFilterUsers(pagenation.currentPageNo);
      }
      if (code === 401) {
        toast.error(data);
      }
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setIsEditOpen(false);
    }
  };

  const onDelete = async () => {
    const payload = {
      emailId: actionUser.emailId,
      remarks: remarksText,
    };
    try {
      const { code, data } = await userDelete(payload);
      if (code === 200) {
        toast.success(`${actionUser.emailId} deleted successfully`);
        getFilterUsers(pagenation.currentPageNo);
      }
      if (code === 401) {
        toast.error(data);
      }
    } catch (error) {
      toast.error(data);
    } finally {
      setIsDeleteOpen(false);
    }
  };

  const onDecline = async () => {
    const payload = {
      emailId: actionUser.emailId,
      remarks: remarksText,
    };
    try {
      const { code, data } = await userDecline(payload);
      if (code === 200) {
        toast.success(`${actionUser.emailId} declined successfully`);
        getFilterUsers(pagenation.currentPageNo);
      }
      if (code === 401) {
        toast.error(data);
      }
    } catch (error) {
      toast.error(data);
    } finally {
      setRemarkPopup(false);
      setIsConfirmOpen(false);
    }
  };

  const onApproveUser = async () => {
    const payload = {
      emailId: actionUser.emailId,
      userRole: approveAccessLevel,
    };
    try {
      const { code, data } = await userApporval(payload);
      if (code === 200) {
        toast.success(`${actionUser.emailId} approved successfully`);
        getFilterUsers(pagenation.currentPageNo);
      }
      if (code === 401) {
        toast.error(data);
      }
    } catch (error) {
      toast.error(data);
    } finally {
      setIsConfirmOpen(false);
      setIsApproveOpen(false);
    }
  };

  const onEnter = (e) => {
    const code = e.keyCode ? e.keyCode : e.which;
    if (code === 13) {
      getFilterUsers(pagenation.currentPageNo);
    }
  };

  const handleIntervalOptionClick = (val) => {
    setDefaultInterval(val);
    switch (val) {
      case "Name":
        setShowStatusSelect(false);
        setShowDatePicker(false);
        setApiInterval("firstName");
        setUserInput("");
        break;
      case "Mail Id":
        setShowStatusSelect(false);
        setShowDatePicker(false);
        setApiInterval("emailId");
        setUserInput("");
        break;
      case "Requested Date":
        // setShowStatusSelect(false);
        // setShowDatePicker(true);
        // setApiInterval("createdDate");
        setShowStatusSelect(false);
        setShowDatePicker(false);
        setApiInterval("firstName");
        setUserInput("");
        break;
      case "Status":
        setShowStatusSelect(true);
        setShowDatePicker(false);
        setApiInterval("approvalStatus");
        setUserInput("");
        break;
      default:
        setShowStatusSelect(false);
        setShowDatePicker(false);
    }
  };

  const onStatusSelectChange = (val) => {
    setSelectedStatus(val);
    switch (val) {
      case "All": {
        setSelectedStatus("All");
        setApiSelectStatus("");
        break;
      }
      case "Pending": {
        setSelectedStatus("Pending");
        setApiSelectStatus(status.pending);
        break;
      }
      case "Confirmed": {
        setSelectedStatus("Confirmed");
        setApiSelectStatus(status.confirmed);
        break;
      }
      case "Declined": {
        setSelectedStatus("Declined");
        setApiSelectStatus(status.declined);
        break;
      }
      case "Removed": {
        setSelectedStatus("Removed");
        setApiSelectStatus(status.removed);
        break;
      }
      default:
        setSelectedStatus("All");
    }
  };

  const getFilterUsers = async (page) => {
    let payload = {};
    if (apiInterval === "approvalStatus") {
      payload = {
        approvalStatus: apiSelectStatus,
      };
    } else if (apiInterval === "createdDate") {
      payload = {
        [apiInterval]: fromDate ? moment(fromDate).format("yyyy-MM-DD") : "",
      };
    } else {
      payload = {
        [apiInterval]: userInput,
      };
    }

    const { code, data } = await getFilterUserlist(payload, page);
    if (code === 200 && data) {
      setData(data);
      setPagenation({
        totalpages: data.users.totalPages,
        size: data.users.size,
        currentPageNo: data.users.pageable.pageNumber,
      });
      setApiError(false);
    } else if (code === 401) {
      setNotAuthorized(true);
      setErrormsg(data);
    } else {
      setApiError(true);
      setErrormsg(data);
    }
  };
  useEffectExcludingMount(() => {
    getFilterUsers();
  }, [apiSelectStatus, fromDate, apiInterval]);

  const onPageChanged = (val) => {
    getFilterUsers(val);
  };

  // useEffect(() =>{
  //   getFilterUsers();
  // },[fromDate])

  const handleOnStartDateChange = (dates) => {
    const startDate = moment(dates).format("yyyy-MM-DD");
    setFromDate(startDate);
  };

  if (notAuthorized) {
    return <NotAuthorized title={errormsg} />;
  }
  // if (apiError) {
  //   return <Feedback title={errormsg} />;
  // }
  if (!data) {
    return <LottieLoad />;
  }
  const DatepickerCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div
      className="btn btn-secondary date-selector"
      onClick={onClick}
      ref={ref}
    >
      {/* <img className="time-icon" src={timeIcon} alt="datePicker" /> */}
      {value}
    </div>
  ));

  return (
    <div className="page-wrapper admin_dashboard">
      <div className="header_content">
        <HeaderCard
          text="Total Users Onboarded"
          icon={<TotalUsersIcon />}
          count={data.activeUserCount}
          // backgroundColor="#C9DBFF"
        />
        <HeaderCard
          text="Pending User Authentications"
          icon={<TotalUsersIcon />}
          count={data.pendingUserCount}
          // backgroundColor="#E3F5E3"
        />
        <HeaderCard
          text="Users active in the last 7 days"
          icon={<TotalUsersIcon />}
          count={data.activeUserCountForPastOneWeek}
          // backgroundColor="#EFE5FF"
        />
      </div>
      <div className="search_input">
        <div className="dropdown_content">
          <Select
            options={interval}
            defaultSelected={defaultInterval}
            handleOptionClick={handleIntervalOptionClick}
          />
        </div>
        <div className="search_textfield">
          {showDatePicker ? (
            <>
              <div className="react_date">
                {/* <ReactDatePicker
                  placeholderText="Please select date"
                  selected={fromDate}
                  maxDate={new Date()}
                  onChange={(date) => setFromDate(date)}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                /> */}
                {/* <input type="date" placeholderText="Please select date"  onChange={(date) => setFromDate(date)}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}   selected={fromDate}
                  maxDate={new Date()}/> */}
                {/* <input
                  type="date"
                  placeholder="Please select date"
                  max={new Date().toISOString().split('T')[0]}
                  onChange={(e) => setFromDate(e.target.value)}
                /> */}
                <div>
                  <Datepicker
                    onChange={handleOnStartDateChange}
                    classContainer="top-10-dtc-date Admin-dashboard-datepicker-primary"
                    secondaryClassContainer="input-value-top-10-dtc Admin-dashboard-datepicker"
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              {!showStatusSelect ? (
                <>
                  <div className="search_container">
                    <input
                      type="text"
                      className="search_field"
                      placeholder="Search users by attribute "
                      value={userInput}
                      onChange={(e) => {
                        setUserInput(e.target.value);
                        if (e.target.value === "") {
                          getUsers();
                        }
                      }}
                      onKeyPress={onEnter}
                    />
                    <img
                      src={SearchIcon}
                      alt="search"
                      className="search_image"
                    />
                  </div>
                  {/* <div className="go_buton">
                    <Button
                      styleClasses="btn btn-secondary-blue "
                      handleClick={() =>
                        getFilterUsers(pagenation.currentPageNo)
                      }
                      buttonStyles={{ width: "auto" }}
                    >
                      Search
                    </Button>
                  </div> */}
                </>
              ) : (
                <>
                  <Select
                    options={allStatus}
                    defaultSelected={selectedStatus}
                    handleOptionClick={onStatusSelectChange}
                  />
                </>
              )}
            </>
          )}
        </div>
      </div>
      {Array.isArray(users) && users.length === 0 ? (
        <Feedback />
      ) : (
        <>
          {!apiError && (
            <div className="users_table">
              <table>
                <thead>
                  <tr>
                    <th className="name">Name</th>
                    <th className="mail">Mail I’D</th>
                    <th className="date">Requested date</th>
                    <th className="status">Status</th>
                    <th className="actions">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(users) &&
                    users.length &&
                    users.map((user) => (
                      <tr
                        key={user.id}
                        style={
                          user.approvalStatus === status.pending
                            ? { fontWeight: 600 }
                            : {}
                        }
                      >
                        <td>{`${user.firstName} ${user.lastName}`}</td>
                        <td
                          style={{
                            maxWidth: "150px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {user.emailId}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {moment(user.createdDate).format("MM/DD/YY, hh:mm A")}
                        </td>
                        <td
                          style={
                            user.approvalStatus === status.confirmed
                              ? { color: "#0574A6" }
                              : user.approvalStatus === status.removed ||
                                user.approvalStatus === status.declined
                              ? { color: "#DD359A" }
                              : user.approvalStatus === status.pending
                              ? { color: "#09A1A1" }
                              : {}
                          }
                        >
                          {statusView[user.approvalStatus]}
                        </td>
                        <td>
                          <div className="action_container">
                            {user.approvalStatus === status.pending ? (
                              <div
                                className="confirm_user"
                                onClick={() => {
                                  setIsConfirmOpen(true);
                                  setActionUser(user);
                                }}
                              >
                                <ConfirmUserIcon />
                              </div>
                            ) : (
                              <>
                                <div
                                  className="view_user"
                                  onClick={() => {
                                    setIsViewOpen(true);
                                    setActionUser(user);
                                  }}
                                >
                                  <ViewUserIcon />
                                </div>
                              </>
                            )}
                            {!nonEditableUsers.includes(user.approvalStatus) &&
                            user.approvalStatus !== status.pending ? (
                              <>
                                <div
                                  className="edit_user"
                                  onClick={() => {
                                    setIsEditOpen(true);
                                    setActionUser(user);
                                  }}
                                  style={
                                    user.approvalStatus === status.pending
                                      ? {
                                          pointerEvents: "none",
                                        }
                                      : {}
                                  }
                                >
                                  <EditUserIcon />
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="space_div"></div>
                              </>
                            )}
                            {user.approvalStatus !== status.removed &&
                              user.approvalStatus !== status.pending && (
                                <div
                                  className="delete_user"
                                  style={
                                    user.approvalStatus === status.pending
                                      ? {
                                          pointerEvents: "none",
                                        }
                                      : {}
                                  }
                                  onClick={() => {
                                    setIsConfirmDelete(true);
                                    setActionUser(user);
                                  }}
                                >
                                  <DeleteUserIcon />
                                </div>
                              )}
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {pagenation.totalpages > 1 && (
                <div>
                  <Pagination
                    pageCount={pagenation.totalpages}
                    pageRange={pagenation.size}
                    onPageChanged={onPageChanged}
                  />
                </div>
              )}
            </div>
          )}
        </>
      )}
      {isConfirmOpen && (
        <Modal
          type={"medium"}
          title="User Profile"
          titlePosition={"center"}
          wrapperClass="confirm_user_modal"
          isError={false}
          handleClose={() => setIsConfirmOpen(false)}
        >
          <div className="user_details">
            <div className="user_keys">
              <p>Name</p>:
              <p
                style={{
                  wordBreak: "break-all",
                }}
              >{`${actionUser.firstName} ${actionUser.lastName}`}</p>
            </div>
            <div className="user_keys">
              <p>Email </p>:
              <p
                style={{
                  wordBreak: "break-all",
                }}
              >
                {actionUser.emailId}
              </p>
            </div>
            <div className="user_keys">
              <p>Request for approval date</p>:
              <p>
                {moment(actionUser.createdDate).format("MM/DD/YY, hh:mm A")}
              </p>
            </div>
            <div className="user_keys">
              <p>Reason for access</p>:<p>{actionUser.accessReason}</p>
            </div>
          </div>
          <div className="button_container">
            <Button
              styleClasses="btn btn-secondary-blue cancel_button"
              handleClick={() => {
                setRemarkPopup(true);
                setIsConfirmOpen(false);
              }}
            >
              Decline
            </Button>
            <Button
              styleClasses="btn  btn-primary-blue approve_button"
              handleClick={() => {
                setIsApproveOpen(true);
                setIsConfirmOpen(false);
              }}
            >
              Approve
            </Button>
          </div>
        </Modal>
      )}
      {isEditOpen && (
        <Modal
          type="small"
          title=""
          titlePosition={"center"}
          wrapperClass="edit_user_modal"
          isError={false}
          handleClose={() => setIsEditOpen(false)}
        >
          <div className="authorisation_details">
            <p>Change Authorization level :</p>
            <div className="radio_group">
              <div onChange={onChange}>
                <input
                  type="radio"
                  value={roleType.level1}
                  name="accessLevel"
                  id="1"
                  checked={accessLevel === roleType.level1}
                />
                <label htmlFor="1">Level 1</label>
                <input
                  type="radio"
                  value={roleType.level2}
                  name="accessLevel"
                  id="2"
                  checked={accessLevel === roleType.level2}
                />
                <label htmlFor="2">Level 2</label>
                <input
                  type="radio"
                  value={roleType.level3}
                  name="accessLevel"
                  id="3"
                  checked={accessLevel === roleType.level3}
                />
                <label htmlFor="3">Level 3</label>
              </div>
            </div>
            <div className="button_container">
              <Button
                styleClasses="btn btn-secondary-blue cancel_button"
                handleClick={() => setIsEditOpen(false)}
              >
                Cancel
              </Button>
              <Button
                styleClasses="btn  btn-primary-blue approve_button"
                handleClick={() => onAccessChange()}
              >
                Save
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {isConfirmDelete && (
        <Modal
          type="small"
          title=""
          titlePosition={"center"}
          wrapperClass="delete_user_modal remark_modal"
          isError={false}
          handleClose={() => setIsConfirmDelete(false)}
        >
          <p style={{ position: "relative", top: "-20px", lineHeight: "24px" }}>
            Are you sure you want to delete{" "}
            <strong>
              {" "}
              {actionUser.firstName} {actionUser.lastName}
            </strong>
            ?
          </p>
          <div className="button_container">
            <Button
              styleClasses="btn btn-secondary-blue cancel_button"
              handleClick={() => {
                setIsConfirmDelete(false);
                setRemarksText(null);
              }}
            >
              Cancel
            </Button>
            <Button
              styleClasses="btn  btn-primary-blue approve_button"
              handleClick={() => {
                setIsDeleteOpen(true);
                setIsConfirmDelete(false);
                setRemarksText(null);
              }}
            >
              Yes
            </Button>
          </div>
        </Modal>
      )}
      {isDeleteOpen && (
        <Modal
          type="medium"
          title=""
          titlePosition={"center"}
          wrapperClass="delete_user_modal remark_modal"
          isError={false}
          handleClose={() => setIsDeleteOpen(false)}
        >
          <h2 className="remark_heading">
            {actionUser.approvalStatus === status.confirmed
              ? "Please write reason for deleting active user."
              : "Please write reason for deleting user."}
          </h2>
          <textarea
            className="remarks_text"
            name="Text1"
            rows="10"
            onChange={(e) => setRemarksText(e.target.value)}
          ></textarea>
          <div className="button_container">
            <Button
              styleClasses="btn btn-secondary-blue cancel_button"
              handleClick={() => {
                setIsDeleteOpen(false);
                setRemarksText(null);
              }}
            >
              Cancel
            </Button>
            <Button
              styleClasses="btn  btn-primary-blue approve_button"
              handleClick={() => {
                onDelete();
                setRemarksText(null);
              }}
              buttonStyles={
                !remarksText
                  ? {
                      backgroundColor: "#c9ced7",
                      border: "1px solid #c9ced7",
                      pointerEvents: "none",
                      color: "#000000",
                    }
                  : {}
              }
            >
              Delete
            </Button>
          </div>
        </Modal>
      )}
      {isViewOpen && (
        <Modal
          type={"medium"}
          title="User Profile"
          titlePosition={"center"}
          wrapperClass="confirm_user_modal"
          isError={false}
          handleClose={() => setIsViewOpen(false)}
        >
          <div className="user_details">
            <div className="user_keys">
              <p>Name</p>:
              <p
                style={{ wordBreak: "break-all" }}
              >{`${actionUser.firstName} ${actionUser.lastName}`}</p>
            </div>
            <div className="user_keys">
              <p>Email</p>:
              <p
                style={{
                  wordBreak: "break-all",
                }}
              >
                {actionUser.emailId}
              </p>
            </div>
            <div className="user_keys">
              <p>Request for approval date</p>:
              <p>
                {moment(actionUser.createdDate).format("MM/DD/YY, hh:mm A")}
              </p>
            </div>
            <div className="user_keys">
              <p>Reason for access</p>:<p>{actionUser.accessReason}</p>
            </div>
            <div className="user_keys">
              <p>Access Level</p>:<p>{userRoleView[actionUser.userRole]}</p>
            </div>
          </div>
        </Modal>
      )}
      {isApproveOpen && (
        <Modal
          type="small"
          title=""
          titlePosition={"center"}
          wrapperClass="edit_user_modal"
          isError={false}
          handleClose={() => {
            setIsApproveOpen(false);
            setApproveAccessLevel(null);
          }}
        >
          <div className="authorisation_details">
            <p>Change Authorization level :</p>
            <div className="radio_group">
              <div onChange={onApproveChange}>
                <input
                  type="radio"
                  value={roleType.level1}
                  name="accessLevel"
                  id="1"
                  checked={approveAccessLevel === roleType.level1}
                />
                <label htmlFor="1">Level 1</label>
                <input
                  type="radio"
                  value={roleType.level2}
                  name="accessLevel"
                  id="2"
                  checked={approveAccessLevel === roleType.level2}
                />
                <label htmlFor="2">Level 2</label>
                <input
                  type="radio"
                  value={roleType.level3}
                  name="accessLevel"
                  id="3"
                  checked={approveAccessLevel === roleType.level3}
                />
                <label htmlFor="3">Level 3</label>
              </div>
            </div>
            <div className="button_container">
              <Button
                styleClasses="btn btn-secondary-blue cancel_button"
                handleClick={() => {
                  setIsConfirmOpen(true);
                  setIsApproveOpen(false);
                  setApproveAccessLevel(null);
                }}
              >
                Cancel
              </Button>
              <Button
                styleClasses="btn  btn-primary-blue"
                handleClick={() => onApproveUser()}
                buttonStyles={
                  !approveAccessLevel
                    ? {
                        backgroundColor: "#c9ced7",
                        border: "1px solid #c9ced7",
                        pointerEvents: "none",
                        color: "#000000",
                      }
                    : {}
                }
              >
                Save
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {remarkPopup && (
        <Modal
          type={"medium"}
          title=""
          titlePosition={"center"}
          wrapperClass="remark_modal"
          isError={false}
          handleClose={() => setRemarkPopup(false)}
        >
          <h2 className="remark_heading">
            Please write reason for declining request.
          </h2>
          <textarea
            className="remarks_text"
            name="Text1"
            rows="10"
            onChange={(e) => setRemarksText(e.target.value)}
          ></textarea>
          <div className="button_container">
            <Button
              styleClasses="btn btn-secondary-blue cancel_button"
              handleClick={() => {
                setRemarkPopup(false);
                setRemarksText(null);
              }}
            >
              Cancel
            </Button>
            <Button
              styleClasses="btn  btn-primary-blue approve_button"
              handleClick={() => {
                onDecline();
                setRemarksText(null);
              }}
              buttonStyles={
                !remarksText
                  ? {
                      backgroundColor: "#c9ced7",
                      border: "1px solid #c9ced7",
                      pointerEvents: "none",
                      color: "#000000",
                    }
                  : {}
              }
            >
              Decline
            </Button>
          </div>
        </Modal>
      )}
      {apiError && (
        <div>
          <Feedback />
        </div>
      )}
    </div>
  );
};

export default AdminDashboard;
