import { useEffect } from "react";
import { useSelector } from "react-redux";
import Highcharts from "highcharts";
import arrowYAxis2 from "../../../../assets/arrow_second_axis.svg";
import arrowXAxis from "../../../../assets/arrow-xaxis.svg";
import "../../HealthCheck/index.scss";

export const DualLine = (props) => {
  const data = props?.data;
  const categories = data[0]?.categories;
  const xAxisLabel = data[0]?.graphLabels[0]?.xAxisLabel;
  const y1AxisLabel = data[0]?.graphLabels[0]?.y1AxisLabel;
  const y2AxisLabel = data[0]?.graphLabels[0]?.y2AxisLabel;
  const graphtitle = data[0]?.graphLabels[0]?.graphTitle;
  const ThresholdValue = data[0]?.graphLabels[0]?.thresholdValue;
  const id = props.id;
  const P1data = data[0]?.data[0];
  const P2data = data[0]?.data[1];
  const title = props.title;

  const isSidemenuExpanded = useSelector(
    (state) => state.mainReducer.isSidemenuExpanded
  );

  useEffect(() => {
    renderChart(id, data, xAxisLabel, y1AxisLabel, y2AxisLabel);
  }, [isSidemenuExpanded, data, P1data, P2data]);

  const getPrimaryYAxisTitle = () => {
    return data?.length < 1
      ? {
          text: null,
        }
      : {
          text: `<div class="y-axis-legend"><div>${y1AxisLabel}</div><div class="y-axis-arrow"></div></div>`,
          style: {
            color: "#353536",
            lineHeight: 1,
            textAlign: "right",
            font: "10px Open Sans",
            letterSpacing: "1px",
          },
          align: "high",
          useHTML: true,
          margin: 20,
        };
  };

  const getSecondaryYAxisTitle = () => {
    return data.length < 1
      ? {
          text: null,
        }
      : {
          text: `<div class="y-axis-legend" style="padding-right: 50px;"><div>${y2AxisLabel}</div><div class="y-axis-arrow" ></div></div>`,
          style: {
            color: "#353536",
            lineHeight: 1,
            textAlign: "right",
            font: "10px Open Sans",
            letterSpacing: "1px",
          },
          align: "high",
          useHTML: true,
          offset: 60,
          rotation: -90,
          y: 130,
        };
  };

  const getxAxisTitle = (data, y1AxisLabel) => {
    return data?.length < 1
      ? {
          text: null,
        }
      : {
          text: `<div class="x-axis-legend"><div>${xAxisLabel}</div><img src='${arrowXAxis}'/></div>`,
          style: {
            color: "#353536",
            lineHeight: 1,
            textAlign: "right",
            font: "10px Open Sans",
            letterSpacing: "1px",
          },
          align: "high",
          useHTML: true,
          margin: 20,
        };
  };

  const renderChart = (xAxisLabel, y1AxisLabel, y2AxisLabel, data) => {
    Highcharts.chart(
      id,
      {
        chart: {
          backgroundColor: "#FFFFFF",
          spacing: [50, 30, 10, 10],
          borderColor: "#CECECE",
          borderRadius: 20,
        },
        title: {
          text: null,
        },

        xAxis: {
          min: 0,
          categories: categories,
          title: getxAxisTitle(),
          labels: {
            style: {
              color: "#353536",
              lineHeight: 1,
              font: "10px Open Sans",
              letterSpacing: "1px",
            },
          },
        },

        yAxis: [
          {
            title: getPrimaryYAxisTitle(),
            min: 0,
            max: ThresholdValue,
            labels: {
              style: {
                color: "#353536",
                lineHeight: 1,
                font: "10px Open Sans",
                letterSpacing: "1px",
              },
            },

            gridLineColor: "#F2F2F2",
            plotLines: [
              {
                color: "#ffcccc",
                value: ThresholdValue, //chartData?.[1]?.data?.[1]?.y,
                width: 2,
                dashStyle: "dash",
              },
            ],
          },
          {
            title: getSecondaryYAxisTitle(),
            labels: {
              style: {
                color: "#353536",
                lineHeight: 1,
                font: "10px Open Sans",
                letterSpacing: "1px",
              },
            },
            opposite: true,
            gridLineColor: "#F2F2F2",
          },
        ],

        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              crop: false,
              overflow: "none",
              align: "right",
              verticalAlign: "middle",
              formatter: function () {
                if (this.point.index === 0) {
                  return "";
                } else {
                  return "";
                }
              },
            },
          },
        },
        tooltip: {
          backgroundColor: "#000",
          style: {
            color: "#FFFFFF",
            font: "12px Open Sans",
          },
          formatter: function () {
            return `Miles(X) : ${this.x} </b><br/> ${
              this.color === "#6FAC4E" ? "RUL(Y)" : title
            } : ${this.y}`;
          },
        },

        series: [P1data, P2data],
      },
      function (chart) {
        // on complete
        const data1Len = chart.series?.[0]?.data[0].y;
        const data2Len = chart.series?.[1]?.data[0].y;
        if (data1Len === undefined && data2Len === undefined) {
          // check series is empty
          chart.renderer
            .text("No Data Available", 335, 180)
            .css({
              color: "#000000",
              fontSize: "16px",
            })
            .add();
        }
      }
    );
  };

  return (
    <>
      <div
        className="legend-wrapper"
        style={{
          width: "100%",
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        <div>{title}</div>
        <div className="legend-container">
          <div className="legend">
            <div className="green-legend">
              <span className="color-box green"></span>
              <span className="color-legend">{title}</span>
            </div>
            <div className="yellow-legend">
              <span
                className="color-box "
                style={{ backgroundColor: "#6FAC4E" }}
              ></span>
              <span className="color-legend">RUL </span>
            </div>
          </div>
        </div>
      </div>
      <figure className="highcharts-figure">
        <div id={id}></div>
      </figure>
    </>
  );
};
