import { useState, useEffect } from "react";
import Select from "../../../../components/common/Select/Select";
import { mainAxiosInstance } from "../../../../services/service";
import { WarrantymanagementDashBoard } from "../../../../services/service-constants";

import uparrow from "../../../../assets/uparrow.svg";
import downarrow from "../../../../assets/downarrow.svg";

const WMRiskAnalysis = () => {
  const options = ["Reports", "Cost", "Severity", "Warranty Scorecard"];
  const [defaultOption, setDefaultOption] = useState("reports");
  const [selectedOption, setSelectedOption] = useState("Reports");
  const [noDataFound, setNoDataFound] = useState(false);
  const [data, setData] = useState([]);
  const [sortBy, setSortBy] = useState("noOfDaysOpen");
  const [sortDirection, setSortDirection] = useState(true);

  const handleOptionClick = (val) => {
    setSelectedOption(val);
    setSortBy("noOfDaysOpen");
    setSortDirection(true);
    if (val === "Reports") {
      setDefaultOption("reports");
    }
    if (val === "Cost") {
      setDefaultOption("cost");
    }
    if (val === "Severity") {
      setDefaultOption("severity");
    }
    if (val === "Warranty Scorecard") {
      setDefaultOption("warranty_score");
    }
  };

  const getPageData = () => {
    setNoDataFound(false);
    const url = `${
      WarrantymanagementDashBoard.warranty_management
    }/0/non_dtc/risk_analysis/${defaultOption}?sortBy=${sortBy}&sortDirection=${
      sortDirection ? "desc" : "asc"
    }`;
    let params = {
      vinNumber: "DH34ASD7SDFF84742", // for VP no need to send vinnumber
      make: "Ford",
      organizationId: 1,
    };

    mainAxiosInstance
      .post(url, params)
      .then((response) => {
        if (response.status === 200) {
          setNoDataFound(false);
          setData(response?.data?.content);
        }
      })
      .catch((err) => {
        setNoDataFound(true);
      });
  };

  useEffect(() => {
    setData([]);
    getPageData();
  }, [defaultOption, sortDirection]);
  const handleSorting = (by) => {
    setSortBy(by);
    setSortDirection(!sortDirection);
  };
  return (
    <div className="wm-dashboard-middle-container">
      <div className="wm-drop-down">
        <Select
          options={options}
          defaultSelected={selectedOption}
          handleOptionClick={handleOptionClick}
        />
      </div>

      <div className="wm-table">
        <table>
          <tr>
            <th>
              <span
                style={{ paddingLeft: "50px" }}
                onClick={() => handleSorting("dtcCode")}
              >
                Non DTC Complaints
                <img src={sortDirection ? downarrow : uparrow} alt="up/down" />
              </span>
            </th>
            <th>
              <span
                onClick={() =>
                  handleSorting(
                    defaultOption === "warranty_score"
                      ? "warrantyScore"
                      : defaultOption
                  )
                }
                style={{ gap: "5px" }}
              >
                {selectedOption}
                <img src={sortDirection ? downarrow : uparrow} alt="up/down" />
              </span>
            </th>
            <th>
              <span onClick={() => handleSorting("noOfDaysOpen")}>
                No of Days Open
                <img src={sortDirection ? downarrow : uparrow} alt="up/down" />
              </span>
            </th>
            <th>
              <span onClick={() => handleSorting("actionPlan")}>
                Action Plan
                <img src={sortDirection ? downarrow : uparrow} alt="up/down" />
              </span>
            </th>
            <th>
              <span
                onClick={() => handleSorting("status")}
                style={{ gap: "5px" }}
              >
                Status
                <img src={sortDirection ? downarrow : uparrow} alt="up/down" />
              </span>
            </th>
            <th>
              <span onClick={() => handleSorting("leadName")}>
                Lead Name
                <img src={sortDirection ? downarrow : uparrow} alt="up/down" />
              </span>
            </th>
          </tr>
          {noDataFound && (
            <tr>
              <td colSpan={5}>No Data Found</td>
            </tr>
          )}
          {data?.length > 0 &&
            data?.map((item, i) => {
              return (
                <tr>
                  <td>{item.dtcCode}</td>
                  <td>
                    {defaultOption === "warranty_score"
                      ? item.warrantyScore
                      : item[defaultOption]}
                  </td>
                  <td>{item.noOfDaysOpen}</td>
                  <td>{item.actionPlan}</td>
                  <td>{item.status}</td>
                  <td>{item.leadName}</td>
                </tr>
              );
            })}
        </table>
      </div>
    </div>
  );
};

export default WMRiskAnalysis;
