import React from "react";

const ChartCard = ({ chartText, currentValue, showUnit = false, unit }) => {
  return (
    <>
      <div className="chart-card">
        <div className="unit-container">
          <h2>{showUnit ? `${currentValue}${unit}` : `${currentValue}`}</h2>
        </div>
        <div className="chart-text-container">
          <p>
            {`${chartText}
            (${unit})`}
          </p>
        </div>
      </div>
    </>
  );
};

export default ChartCard;
