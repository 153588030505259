import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { componentSystemHistoryEndPoints } from "../../../services/service-constants";
import { getComponentSystemHistoryTableData } from "../api";
import Pagination from "../../common/Pagination";
import "./index.scss";
import { useLocation } from "react-router";
import moment from "moment";
import FeedBack from "../../common/ErrorComponents/Feedback"
import NotAuthorized from "../../common/ErrorComponents/NotAuthorized"
import LottieLoad from "../../common/ErrorComponents/LottieLoad"

const ComponentSystemHistoryUsageProfile = () => {
  const location = useLocation();
  const [initialTableData, setInitialTableData] = useState(null);

  const [currentPage, setCurrentPage] = useState();

  const [isPageFirst, setIsPageFirst] = useState(false);

  const [isPageLast, setIsPageLast] = useState(false);

  const [noDataFound, setNoDataFound] = useState(false);

  const vin_Number = useSelector((state) => state.mainReducer.vin_Number);

  const [notAuthorized, setNotAuthorized] = useState(false);

  const [apiError, setApiError] = useState(false);

  const [pagenation, setPagenation] = useState({
    totalpages: 0,
    size: 0,
    currentPageNo: 0,
  });

  const onPageChanged = (val) => {
    const nextPage = val;
    getInitialData({
      page: nextPage,
    });
  };

  const getInitialData = async (payload) => {
    const { page, vinNumber, subSystem } = payload;
    const {code,data} = await getComponentSystemHistoryTableData({
      page: page,
      url: `${componentSystemHistoryEndPoints.usage_profile}`,
      vinNumber: vinNumber,
      subSystem: subSystem,
      graphName: "rm_no_of_harsh_breaking",
    });
    if (code === 200 && data) {
      setNoDataFound(false);
      setInitialTableData(data?.content);
      setCurrentPage(data?.pageable?.pageNumber);
      setIsPageFirst(data?.first);
      setIsPageLast(data?.last);
      setPagenation({
        totalpages: data?.totalPages,
        size: data?.size,
        currentPageNo: data?.pageable?.pageNumber,
      });
    }  else if (code === 401) {
      setNotAuthorized(true);
    }
    else {
      setNoDataFound(true);
      setApiError(true);
    }
  };

  useEffect(() => {
    if (location?.state?.subSystem) {
      getInitialData({
        page: 0,
        vinNumber: vin_Number,
        subSystem: location.state.subSystem,
      });
    } else {
      setNoDataFound(true);
    }
  }, []);

  if (notAuthorized) {
    return <NotAuthorized />;
  }

  if (apiError) {
    return <FeedBack />;
  }

  if (initialTableData===null){
  return <LottieLoad />
  }

  return (
    <>
      
      {!noDataFound && (
        <>
          <table className="component-system-history-usage-profile-table">
            <thead>
              <tr>
                <th>System Usage Profile</th>
                <th>No of Occurrences</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {initialTableData?.length &&
                initialTableData.map((item, index) => (
                  <tr key={index}>
                    <td>Number of harsh braking</td>
                    <td>{item?.noOfHarshBreaking || "-"}</td>
                    {/* <td>{moment().add(-index, "days").format("yyyy-MM-DD")}</td> */}
                    <td>{(moment(item["statsDate"]).format("yyyy-MM-DD"))}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          {pagenation.totalpages > 1 && (
            <div className="pagination-container">
              <Pagination
                pageCount={pagenation.totalpages}
                pageRange={pagenation.size}
                onPageChanged={onPageChanged}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ComponentSystemHistoryUsageProfile;
