import { useEffect } from "react";
import Highcharts from "highcharts";
import arrowXAxis from "../../../assets/arrow-xaxis.svg";
import { useSelector } from "react-redux";
// import useLocation from "react-router-dom";
import { useLocation } from "react-router";

export const BarGraph = (data) => {
  const { chartdata, id } = data;
  const xLabel = chartdata[0]?.xLabel;
  const yLabel = chartdata[0]?.yLabel;
  const graphData = chartdata[0]?.data;
  const pageSize = data.pageSize;
  const location = useLocation();
  const graphId = chartdata[0]?.id;
  const isSidemenuExpanded = useSelector(
    (state) => state.mainReducer.isSidemenuExpanded
  );

  const tooltipName = chartdata[0]?.yLabel
    ? chartdata[0]?.yLabel
    : "Number of occurrences";

  useEffect(() => {
    renderChart(id, xLabel, yLabel, graphData, graphId, chartdata);
  }, [graphData, isSidemenuExpanded]);

  const getyAxisTitle = (chartData, yLabel) => {
    return chartData?.length < 1
      ? {
          text: null,
        }
      : {
          text: `<div class="y-axis-legend-label"><div>${yLabel}</div><div class="y-axis-arrow"></div></div>`,
          style: {
            color: "#353536",
            lineHeight: 1,
            textAlign: "right",
            font: "11px Open Sans",
            letterSpacing: "1px",
            fontWeight: 800,
          },
          align: "high",
          margin: 20,
          useHTML: true,
        };
  };

  const getxAxisTitle = (chartData, xLabel) => {
    return chartData?.length < 1
      ? {
          text: null,
        }
      : {
          text: `<div class="x-axis-legend"><img src='${arrowXAxis}'/><div>${xLabel}</div></div>`,
          style: {
            color: "#353536",
            lineHeight: 1,
            textAlign: "right",
            font: "11px Open Sans",
            letterSpacing: "1px",
            fontWeight: 800,
          },
          align: "high",
          useHTML: true,
          margin: 20,
        };
  };

  const renderChart = (id, xLabel, yLabel, chartdata, graphId) => {
    Highcharts.chart("Container", {
      chart: {
        // backgroundColor: '#c9ced740',
        type: "column",
        height: 350,
        spacing: [50, 10, 10, 0],
        borderRadius: 20,
      },
      title: {
        text: null,
      },
      yAxis: {
        title: getyAxisTitle(chartdata, yLabel),
        maxPadding: 0,
        gridLineColor: "#CECECE",

        labels: {
          style: {
            color: "#353536",
            lineHeight: 1,
            font: "12px Open Sans",
            letterSpacing: "1px",
            fontWeight: 600,
          },
          formatter: function () {
            if (id === "cost") {
              return "$" + this.value + " " + "USD";
            } else {
              return this.value;
            }
          },
        },
      },

      xAxis: {
        categories: graphData?.map((number, index) => {
          if (typeof number.name === "undefined") {
            if (pageSize == 1) {
              return index + 11;
            } else {
              return index + 1;
            }
          } else {
            return number.name;
          }
        }),
        title: getxAxisTitle(chartdata, xLabel),
        style: {
          color: "#353536",
          lineHeight: 1,
          font: "11px Open Sans",
          letterSpacing: "1px",
          fontWeight: 600,
          fontWeight: "bold",
        },
        startOnTick: true,
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },

      plotOptions: {
        series: {
          color: "#0574a6",
          pointWidth: 10,
        },
      },

      // tooltip: {
      //     formatter: function () {
      //       return this.point.options.values +"</br> " +"Number of occurrences"+ ":" + this.point.y;
      //     }
      //   },
      tooltip: {
        style: {
          width: "130px",
        },
        formatter: function () {
          return location.pathname ===
            "/vehicle-population/diagnostic/warranty-scorecard" ||
            location.pathname ===
              "/vehicle-population/prognostic/warranty-scorecard" ||
            (location.search === "?key=3" &&
              location.pathname.includes("/vp-saved-filter/")) ||
            location.pathname ===
              "/warranty-management/diagnostics/top-10-DTC-complaints/score-card" ||
            location.pathname ===
              "/warranty-management/diagnostics/top-10-non-DTC-complaints/score-card" ||
            location.pathname ===
              "/warranty-management/prognostics/top-10-DTC-complaints/score-card" ||
            location.pathname ===
              "/warranty-management/prognostics/top-10-non-DTC-complaints/score-card" ||
            location.pathname ===
              "/vehicle-population/vehicle-population-warranty-management/diagnostics/top-10-dtc-complaints/score-card" ||
            location.pathname ===
              "/vehicle-population/vehicle-population-warranty-management/diagnostics/top-10-non-dtc-complaints/score-card" ||
            location.pathname ===
              "/vehicle-population/vehicle-population-warranty-management/prognostics/top-10-dtc-complaints/score-card" ||
            location.pathname ===
              "/vehicle-population/vehicle-population-warranty-management/prognostics/top-10-non-dtc-complaints/score-card"
            ? this.point.options.values +
                "</br> " +
                "Warranty Score" +
                ":" +
                this.point.y
            : this.point.options.values +
                "</br> " +
                "</br> " +
                tooltipName +
                ":" +
                this.point.y;
        },
      },

      series: [
        {
          name: "Number of occurrences",
          data: graphData,
          borderRadius: 3,
        },
      ],

      colors: ["black"],
    });
  };

  return (
    <figure className="vehicle-population-highchart">
      <div id="Container"></div>
    </figure>
  );
};
