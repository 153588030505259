export const FETCH_VEHICLES = "FETCH_VEHICLES";

export const VIN_NUMBER = "VIN_NUMBER";

export const USER_PROFILE = "USER_PROFILE";

export const IS_SIDEMENU_EXPANDED = "IS_SIDEMENU_EXPANDED";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const SELECTED_FILTER_SELFTEST = "SELECTED_FILTER_SELFTEST";
export const SECOND_FILTER = "SECOND_FILTER";
export const FIRST_FILTER = "FIRST_FILTER";
export const VIN_FILTER = "VIN_FILTER";
export const FLAG_DATA = "FLAG_DATA";
export const DROP_DOWN_FILTER_SAVE = "DROP_DOWN_FILTER_SAVE"

