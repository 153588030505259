import React, { useState, useEffect } from "react";
import { Button } from "../../../common/Button/Button";
import { mainAxiosInstance } from "../../../../services/service";
import {
  ServiceConfig,
  vehicleOverviewEndpoints,
} from "../../../../services/service-constants";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import NotAuthorized from "../../../common/ErrorComponents/NotAuthorized";
import Feedback from "../../../common/ErrorComponents/Feedback";
import LottieLoad from "../../../common/ErrorComponents/LottieLoad";
import { useLocation } from "react-router";

const DTCDetails = ({
  dtcValue,
  statsDate,
  handleClose,
  isFieldHidden,
  vinNumber,
  organizationId,
  uuid,
  isNotification = false,
  handleClick,
  buttonText = "Component System History",
}) => {
  const [dtcDetails, setDTCDetails] = useState({});
  const vin_Number = useSelector((state) => state.mainReducer.vin_Number);
  const [notAuthorized, setNotAuthorized] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [apiCode, setApiCode] = useState(null);
  const location = useLocation();
  const formatData = (data) => {
    const obj = {
      vin: data.vinNumber,
      dtcCode: data.dtcCode,
      dtcTimeStamp: data.statsDate,
      dtcDescription: data.description,
      rootCause: data.historicalRootCause,
      solution: data.potentialSolution,
      probability: data.causalProbability,
      subSystem: data.subSystem,
      minRUL: data.minRUL,
    };
    const associatedDtc = data.associatedDtcs.split(",");
    obj.associatedDtc = associatedDtc;
    setDTCDetails(obj);
  };

  const getPageData = (dtc) => {
    const url = `${ServiceConfig.vehicle_health_check}dtc_details/${dtc.dtcValue}`;
    const params = {
      vin_number: dtc.vin_Number,
      organization_id: 1,
      stats_date: statsDate,
    };

    mainAxiosInstance
      .get(url, { params: params })
      .then((response) => {
        if (response.status === 200) {
          formatData(response.data);
          setApiCode(200);
        }
        if (response.status === 401) {
          setApiCode(401);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setApiCode(401);
        } else {
          setApiCode("error");
        }
      });
  };

  const updateNotificationStatus = () => {
    const url = vehicleOverviewEndpoints.vehilceReadStatusUpdate;
    const reqBody = {
      vinNumber: vinNumber,
      organizationId: organizationId,
      id: uuid,
      dtcCode: dtcValue,
    };
    mainAxiosInstance
      .post(url, reqBody)
      .then((response) => {
        if (response.status === 200) {
          formatData(response.data);
          setApiCode(200);
        }
        if (response.status === 401) {
          setApiCode(401);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setApiCode(401);
        } else {
          setApiCode("error");
        }
      });
  };

  const handleSuccess = () => {
    const obj = {
      subSystem: dtcDetails.subSystem,
      date: dtcDetails.dtcTimeStamp,
    };
    localStorage.setItem("componentSystemHistoryState", JSON.stringify(obj));
    handleClick(obj);
  };

  useEffect(() => {
    /** If its a notification we want to call the update status vehicle notification api */
    if (isNotification) {
      updateNotificationStatus();
    } else {
      getPageData({
        dtcValue: dtcValue,
        vin_Number: vin_Number,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dtcValue]);

  if (apiCode === 401) {
    return <NotAuthorized />;
  }
  if (apiCode === "error") {
    return <div className="no-details">No Details Found</div>;
  }
  if (Object.keys(dtcDetails).length === 0) {
    return <LottieLoad style={{ height: "200px" }} />;
  }

  return (
    <>
      {Object.keys(dtcDetails).length !== 0 && (
        <div className="dtc-details-wrapper">
          <div className="key-value-holder">
            <div className="key-name">VIN</div>
            <div className="value-details">{dtcDetails.vin}</div>
          </div>
          {!isFieldHidden ? (
            <div className="dtc-probability-holder">
              <div className="key-value-holder">
                <div className="key-name">DTC</div>
                <div className="value-details">{dtcDetails.dtcCode}</div>
              </div>
              <div className="key-value-holder">
                <div className="key-name right-name">Causal Probability</div>
                <div className="value-details">{dtcDetails.probability}</div>
              </div>
            </div>
          ) : (
            ""
          )}
          {!isFieldHidden ? (
            <div className="key-value-holder acc_dtc_container">
              <div className="key-name">Associated DTCs</div>
              <div className="dtc_container">
                {dtcDetails?.associatedDtc?.map((el) => (
                  <div className="value-details associated-dtc">{el}</div>
                ))}
              </div>
            </div>
          ) : (
            <div className="key-value-holder">
              <div className="key-name">Potential DTCs</div>
              <input
                type="text"
                className="dtc-input"
                value={dtcValue}
                onChange={() => {}}
              />
            </div>
          )}
          <div className="key-value-holder">
            <div className="key-name">
              {location.pathname.includes("/diagnostics")
                ? "DTC Time Stamp"
                : "Min RUL"}
            </div>
            <div className="value-details">
              {location.pathname.includes("/diagnostics")
                ? dtcDetails.dtcTimeStamp
                : dtcDetails.minRUL}
            </div>
          </div>
          <div className="key-value-holder decription__value_holder">
            <div className="key-name">DTC Description</div>
            <div className="value-details">{dtcDetails.dtcDescription}</div>
          </div>
          <div className="key-value-holder potential_root_cause">
            <div className="key-name">Potential Root causes</div>
            <div className="value-details">{dtcDetails.rootCause}</div>
          </div>
          <div className="key-value-holder potential_solution">
            <div className="key-name">Potential Solutions</div>
            <div className="value-details">{dtcDetails.solution}</div>
          </div>
          <div className="dtc-button-container">
            <Button
              title="Close"
              handleClick={() => {
                handleClose();
              }}
              styleClasses="btn btn-secondary-blue"
            />
            <Button
              title={buttonText}
              handleClick={() => handleSuccess()}
              styleClasses="btn btn-primary-blue"
            />
          </div>
        </div>
      )}
      {Object.keys(dtcDetails).length === 0 && (
        <div className="no-details">No Details Found</div>
      )}
    </>
  );
};

export default DTCDetails;
