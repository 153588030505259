import React, { useEffect, useState } from "react";
import { maintenanceLogEndPoints } from "../../../../services/service-constants";
import { getMaintenanceScoreTableData } from "../../api";
import Pagination from "../../../../components/common/Pagination";
import { useDispatch, useSelector } from 'react-redux';

import './index.scss';

const Location = () => {
  const [initialTableData, setInitialTableData] = useState(null);

  const [currentPage, setCurrentPage] = useState();

  const [isPageFirst, setIsPageFirst] = useState(false);

  const [isPageLast, setIsPageLast] = useState(false);

  const [performance, setPerformance] = useState([]);
  const vin_Number = useSelector((state) => state.mainReducer.vin_Number);

  const [pagenation, setPagenation] = useState({
    totalpages: 0,
    size: 0,
    currentPageNo: 0,
  });

  const onPageChanged = (val) => {
    const nextPage = val;
    getInitialData({
      page: nextPage,
    });
  };

  const getInitialData = async (payload) => {
    const { page, vinNumber } = payload;

    const data = await getMaintenanceScoreTableData({
      page: page,
      vinNumber: vinNumber,
      url: `${maintenanceLogEndPoints.location}`,


    });
    if (data) {
      const messages = data.messages?.split(',');
      setPerformance([...messages]);
      setInitialTableData(data?.paginatedResponse.content);
      setCurrentPage(data?.paginatedResponse.pageable?.pageNumber);
      setIsPageFirst(data?.paginatedResponse.first);
      setIsPageLast(data?.paginatedResponse.last);
      setPagenation({
        totalpages: data?.paginatedResponse.totalPages,
        size: data?.paginatedResponse.size,
        currentPageNo: data?.paginatedResponse.pageable?.pageNumber,
      });
    }
  };

  useEffect(() => {
    getInitialData({
      page: 0,
      vinNumber: vin_Number,

    });
  }, []);

  return (
    <>
      <div className="maintenance-performance">
        <ul className="">
          {performance.map((el) =>
            <li className="">{el}</li>
          )}
        </ul>
      </div>
      <table className="location-table">
        <thead>
          <tr>
            <th>Component Replaced</th>
            <th>Location of Service</th>
            <th>Service Center Ranking</th>
            <th>Weighing factor</th>
          </tr>
        </thead>
        <tbody>
          {initialTableData?.length &&
            initialTableData.map((item) => (
              <tr key={item?.id}>
                <td>{item?.componentReplaced || '-'}</td>
                <td>{item?.locationOfService || '-'}</td>
                <td>{item?.serviceCenterRanking || '-'}</td>
                <td>{item?.weighingFactor || '0.0'}</td>
              </tr>
            ))}
        </tbody>
      </table>
      {pagenation.totalpages > 1 && (
        <div className="pagination-container">
          <Pagination
            pageCount={pagenation.totalpages}
            pageRange={pagenation.size}
            onPageChanged={onPageChanged}
          />
        </div>
      )}
    </>
  )
}

export default Location;