import axios from "axios";
import { ServiceConfig } from "./service-constants";

let token = JSON.parse(localStorage.getItem("token"));
let accessToken = token?.accessToken;
let refreshToken = token?.refreshToken;
const axiosLoginOptions = {
  baseURL: ServiceConfig.loginbaseUrl,
  timeout: 30000,
  headers: {
    crossDomain: true,
  },
};
const axiosOptions = {
  baseURL: ServiceConfig.baseUrl,
  timeout: 30000,
  headers: {
    Authorization: "Bearer " + accessToken,
    crossDomain: true,
  },
};

const axiosOptionsWithContentType = {
  baseURL: ServiceConfig.baseUrl,
  timeout: 30000,
  headers: {
    Authorization: "Bearer " + accessToken,
    crossDomain: true,
    "Content-Type": "application/json",
  },
};

const tokenRefresh = () => {
  let url = `auth/refresh_token`;
  const data = { refreshToken: refreshToken };
  loginAxiosInstance
    .post(url, data)
    .then((response) => {
      if (response.status === 200) {
        let updatedtoken = JSON.parse(localStorage.getItem("token"));
        updatedtoken.accessToken = response.data.accessToken;

        localStorage.setItem("token", JSON.stringify(updatedtoken));
      } else {
        localStorage.removeItem("token");
        window.location.reload();
      }
    })
    .catch((er) => {
      throw er;
    });
};

export const mainAxiosInstance = axios.create(axiosOptions);
export const axiosWithContentType = axios.create(axiosOptionsWithContentType);
export const loginAxiosInstance = axios.create(axiosLoginOptions);

mainAxiosInstance.interceptors.request.use((req) => {
  if (accessToken) {
    let newDateTime = new Date().getTime() / 1000;
    const newTime = Math.trunc(newDateTime);
    const splitToken = accessToken.split(".");
    const decodeToken = atob(splitToken[1]);
    const tokenObj = JSON.parse(decodeToken);
    const newTimeMin = newTime + 5 * 60; //adding 5min faster from current time
    if (newTimeMin > tokenObj.exp) {
      tokenRefresh();
      let token = JSON.parse(localStorage.getItem("token"));
      let accessToken = token?.accessToken;
      req.headers.Authorization = accessToken;
    }
    // if (newTime >= tokenObj.exp) {

    //   localStorage.removeItem("token");
    //   window.location.reload();
    // }
  }
  return req;
});
