import moment from "moment";
import React from "react";
import { useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import RightArrowLongIcon from "../assets/right-arrow-long.svg";
import LengendContainer from "./LengendContainer";
import { useSelector } from "react-redux";
import { colorMap, getTickIntervals, graphType } from "../utils/constants";
import NoDataFound from "./NoDataFound";

const HighPressureFuelPump = ({ data, filterType }) => {
  const isSidemenuExpanded = useSelector(
    (state) => state.mainReducer.isSidemenuExpanded
  );

  const [odometerTick, setOdometerTick] = useState([]);

  const [chartData, setChartData] = useState(null);
  const [highPressureFuelPumpArray, sethighPressureFuelPumpArray] =
    useState(null);
  const [chartInfo, setChartInfo] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);

  useEffect(() => {
    setChartData(data?.highPressureFuelPumpEfficiencyPercentage);
    if (data && data?.highPressureFuelPumpEfficiencyPercentage.length > 0) {
      let highPressureFuelPumpArray = [];
      switch (filterType) {
        case graphType.DATE: {
          highPressureFuelPumpArray =
            data.highPressureFuelPumpEfficiencyPercentage.map((item) => [
              moment(item.statsDate).format("DD"),
              item.highPressureFuelPumpEfficiencyPercentage,
            ]);
          break;
        }

        case graphType.MILES: {
          highPressureFuelPumpArray =
            data.highPressureFuelPumpEfficiencyPercentage.map((item) => [
              item.odometer,
              item.highPressureFuelPumpEfficiencyPercentage,
            ]);
          highPressureFuelPumpArray.sort((a, b) => a[1] - b[1]);
          if (highPressureFuelPumpArray.length > 1) {
            const labels = highPressureFuelPumpArray.map((ele) => ele[0]);
            setOdometerTick(labels);
          }
          // if (highPressureFuelPumpArray.length > 1) {
          //   const tickArray = data.highPressureFuelPumpEfficiencyPercentage
          //     .map((item) => item.odometer)
          //     .sort((a, b) => a - b);
          //   const tArray = getTickIntervals(tickArray);
          //   setOdometerTick(tArray);
          // }
          break;
        }
        case graphType.TRIP: {
          highPressureFuelPumpArray =
            data.highPressureFuelPumpEfficiencyPercentage.map((item) => [
              item.trip,
              item.highPressureFuelPumpEfficiencyPercentage,
            ]);
          break;
        }
        default: {
          highPressureFuelPumpArray =
            data.highPressureFuelPumpEfficiencyPercentage.map((item) => [
              moment(item.statsDate).format("DD"),
              item.highPressureFuelPumpEfficiencyPercentage,
            ]);
        }
      }
      highPressureFuelPumpArray =
        data.highPressureFuelPumpEfficiencyPercentage.map((item) => [
          moment(item.statsDate).format("DD"),
          item.highPressureFuelPumpEfficiencyPercentage,
        ]);
      setChartInfo(data?.information);
      sethighPressureFuelPumpArray(highPressureFuelPumpArray);
      const month = moment(
        data?.highPressureFuelPumpEfficiencyPercentage[0].statsDate
      ).format("MMMM");
      const year = moment(
        data?.highPressureFuelPumpEfficiencyPercentage[0].statsDate
      ).format("YYYY");
      setMonth(month);
      setYear(year);
    } else {
      sethighPressureFuelPumpArray(null);
    }
  }, [data]);

  const options = {
    chart: {
      backgroundColor: "#FFFFFF",
      spacing: [50, 50, 30, 20],
      height: 350,
      type: "scatter",
      // borderColor: "#CECECE",
      borderRadius: 20,
      // borderWidth: 1,
      events: {
        load: function () {
          this.series[1]?.update({
            enableMouseTracking: false,
          });
        },
      },
    },
    title: {
      text: null,
    },
    yAxis: {
      title: {
        useHTML: true,
        text: `
        <div style="font-size: 11px; left:-200px;top:4px;position:absolute;">
       High Pressure Fuel Pump Efficiency
          <img src='${RightArrowLongIcon}'/>
        </div>
        `,
        align: "high",
      },
      min: 0,
      labels: {
        style: {
          color: "#353536",
          lineHeight: 1,
          font: "10px Open Sans",
          letterSpacing: "1px",
        },
      },
      gridLineColor: "#F2F2F2",
    },
    xAxis: {
      type: "category",
      // tickPositions: filterType === graphType.MILES ? odometerTick : null,
      categories: filterType === graphType.MILES ? odometerTick : null,
      title: {
        useHTML: true,
        text: `
        <div style="font-size: 11px; left:-60px;top:4px;position:absolute;">
           ${
             filterType === graphType.DATE
               ? `${month},${year}`
               : filterType === graphType.MILES
               ? `Miles`
               : filterType === graphType.TRIP
               ? `Trips`
               : `${month},${year}`
           }
          <img src='${RightArrowLongIcon}'/>
        </div>
        `,
        align: "high",
      },
      labels: {
        style: {
          color: "#353536",
          lineHeight: 1,
          font: "10px Open Sans",
          letterSpacing: "1px",
        },
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: true,
        },
        marker: {
          radius: 3,
        },
      },
      line: {
        dashStyle: "Dash",
      },
    },
    tooltip: {
      backgroundColor: "#000",
      style: {
        color: "#FFFFFF",
        font: "12px Open Sans",
      },
    },
    series: [
      {
        data: highPressureFuelPumpArray,
        showInLegend: false,
        name: "High Pressure Fuel Pump Efficiency",
        color: chartInfo && colorMap[chartInfo[0]?.color],
        regression: highPressureFuelPumpArray?.length > 1 ? true : false,
        regressionSettings: {
          type: "polynomial",
          order: "2",
          color: chartInfo && colorMap[chartInfo[0]?.color],
          dashStyle: "dot",
        },
      },
    ],
  };

  return (
    <div key={highPressureFuelPumpArray}>
      <div key={isSidemenuExpanded}>
        {Array.isArray(highPressureFuelPumpArray) &&
        highPressureFuelPumpArray.length > 0 ? (
          <>
            <LengendContainer chartInfo={chartInfo} />
            <HighchartsReact highcharts={Highcharts} options={options} />
            <div
              style={{
                width: "100%",
                textAlign: "center",
                paddingTop: "10px",
              }}
            >
              <p style={{ fontSize: "14px", fontWeight: "600" }}>
                High Pressure Fuel Pump Efficiency
              </p>
            </div>
          </>
        ) : (
          <>
            <NoDataFound chartName="High Pressure Fuel Pump Efficiency" />
          </>
        )}
      </div>
    </div>
  );
};

export default HighPressureFuelPump;
