import React, { useEffect, useState } from "react";
import { getVehicleNotificationData } from "../api";
import OneColumn from "../components/OneColumn";
import { Strings } from "../utils/utils";
import NotificationTable from "./components/NotificationTable";
import Pagination from "../../../components/common/Pagination/index";
import { useDispatch, useSelector } from "react-redux";
import NotAuthorized from "../../../components/common/ErrorComponents/NotAuthorized";
import Feedback from "../../../components/common/ErrorComponents/Feedback";
import LottieLoad from "../../../components/common/ErrorComponents/LottieLoad";

const VehicleNotification = () => {
  const [notificationData, setNotificationData] = useState(null);
  const vinNumber = useSelector((state) => state.mainReducer.vin_Number);
  const [notAuthorized, setNotAuthorized] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("")

  const [pagenation, setPagenation] = useState({
    totalpages: 0,
    size: 0,
    currentPageNo: 0,
  });

  const getNotificationData = async (payload) => {
    const { code, data } = await getVehicleNotificationData(payload);
    if (code === 200 && data) {
      setNotificationData(data);
      setPagenation({
        totalpages: data?.totalPages,
        size: data?.size,
        currentPageNo: data?.pageable?.pageNumber,
      });
    } else if (code === 401) {
      setNotAuthorized(true);
      setErrorMsg(data)

    } else {
      setApiError(true);
      setErrorMsg(data)

    }
  };

  const onPageChanged = (val) => {
    getNotificationData({
      page: val,
      vinNumber: vinNumber,
    });
  };

  useEffect(() => {
    getNotificationData({
      page: 0,
      vinNumber: vinNumber,
    });
  }, []);

  if (notAuthorized) {
    return <NotAuthorized title={errorMsg} />;
  }
  if (apiError) {
    return <Feedback />;
  }
  if (!notificationData) {
    return <LottieLoad />;
  }

  return (
    <div>
      {notificationData ? (
        <>
          {/* <OneColumn className="vehicle-notification"> */}

          <NotificationTable
            columnHeading={Strings.notificationTableColumnHead}
            tableData={notificationData?.content}
          />
          {pagenation.totalpages > 1 && (
            <div>
              <Pagination
                pageCount={pagenation.totalpages}
                pageRange={pagenation.size}
                onPageChanged={onPageChanged}
              />
            </div>
          )}
          {/* </OneColumn> */}
        </>
      ) : (
        <>
          <p>No data availaible</p>
        </>
      )}
    </div>
  );
};

export default VehicleNotification;
