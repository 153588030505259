import { useEffect, useState } from "react";
import { mainAxiosInstance } from "../../../../services/service";
import { ServiceConfig } from "../../../../services/service-constants";
import { Button } from "../../../common/Button/Button";
import Modal from "../../../common/Modal/Modal";
import DTCDetails from "../DTCDetails";
import Filter from "../../../Filter/Filter";
import { Checkbox } from "../../../common/Checkbox/Checkbox";
import "./OnBoard.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Feedback from "../../../../components/common/ErrorComponents/Feedback";
import NotAuthorized from "../../../../components/common/ErrorComponents/NotAuthorized";
import LottieLoad from "../../../../components/common/ErrorComponents/LottieLoad";
import { ReactComponent as RightArrowIcon } from "../../../../pages/VehicleOverview/assets/right-arrow.svg";

const OnBoard = () => {
  const navigate = useNavigate();
  const [onBoardData, setOnBoardData] = useState([]);
  const [selectAll, setSelectAll] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedDTC, setSelectedDTC] = useState("");
  const [selectedStatsDate, setSelectedStatsDate] = useState("");
  const vin_Number = useSelector((state) => state.mainReducer.vin_Number);
  const [noDataFound, setNoDataFound] = useState(true);
  const [errorMsg, setErrorMsg] = useState(true);
  const [authenticationMsg, setAuthenticationMsg] = useState(false);
  const [filterLength, setFilterLength] = useState({});
  //  const [isDataPresent, setIsDataPresent] = useState(true);

  const isDataPresent = [];

  const filterOptions = [
    { displayname: "Engine", isChecked: true },
    { displayname: "Transmission", isChecked: true },
    {
      displayname: "Body Control Module",
      isChecked: true,
    },
    { displayname: "Steering Control Module", isChecked: true },
    { displayname: "ABS", isChecked: true },
    {
      displayname: "Air Bag",
      isChecked: true,
    },
    {
      displayname: "Instrument Cluster Fields",
      isChecked: true,
    },
  ];

  const [filterData, setFilterData] = useState([...filterOptions]);

  const setSelectAllvalue = (data) => {
    const checkAllSelected = data.filter((el) => el.isChecked === false);
    setFilterLength(checkAllSelected);
    if (checkAllSelected.length > 0) {
      setSelectAll(false);
    } else {
      setSelectAll(true);
    }
  };

  const handleFilterClick = (val) => {
    const newFilterData = filterData.map((el) => {
      if (el.displayname === val.displayname) {
        el.isChecked = !el.isChecked;
      }
      return el;
    });
    setFilterData([...newFilterData]);
    setSelectAllvalue(newFilterData);
  };

  const getTableData = (val) => {
    const currentTrans = val.filter(
      (value) =>
        value.dtcStatus === "current" && value.category === "Transmission"
    );
    const currentABS = val.filter(
      (value) => value.dtcStatus === "current" && value.category === "ABS"
    );
    const pendingTrans = val.filter(
      (value) =>
        value.dtcStatus === "pending" && value.category === "Transmission"
    );
    const pendingABS = val.filter(
      (value) => value.dtcStatus === "pending" && value.category === "ABS"
    );
    const historicalTrans = val.filter(
      (value) =>
        value.dtcStatus === "historical" && value.category === "Transmission"
    );
    const historicalABS = val.filter(
      (value) => value.dtcStatus === "historical" && value.category === "ABS"
    );
    const aggregatedCurrent = [];
    if (currentTrans.length) {
      const obj = { type: "Transmission", values: [...currentTrans] };
      aggregatedCurrent.push(obj);
    }
    if (currentABS.length) {
      const obj = { type: "ABS", values: [...currentABS] };
      aggregatedCurrent.push(obj);
    }

    const aggregatedPending = [];
    if (pendingTrans.length) {
      const obj = { type: "Transmission", values: [...pendingTrans] };
      aggregatedPending.push(obj);
    }
    if (pendingABS.length) {
      const obj = { type: "ABS", values: [...pendingABS] };
      aggregatedPending.push(obj);
    }

    const aggregatedHistorical = [];
    if (historicalTrans.length) {
      const obj = { type: "Transmission", values: [...historicalTrans] };
      aggregatedHistorical.push(obj);
    }
    if (historicalABS.length) {
      //remove this static date after
      let hABS = [...historicalABS];
      hABS[0]["statsDate"] = hABS[0].statsDate;
      const obj = { type: "ABS", values: [...hABS] };
      // const obj = { type: "ABS", values: [...historicalABS] };
      aggregatedHistorical.push(obj);
    }

    const aggregated = [];
    if (aggregatedCurrent.length) {
      const obj = { name: "current", values: [...aggregatedCurrent] };
      aggregated.push(obj);
    }
    if (aggregatedPending.length) {
      const obj = { name: "pending", values: [...aggregatedPending] };
      aggregated.push(obj);
    }
    if (aggregatedHistorical.length) {
      const obj = { name: "historical", values: [...aggregatedHistorical] };
      aggregated.push(obj);
    }
    setOnBoardData([...aggregated]);
  };

  const getOnBoardData = (val) => {
    const url = `${ServiceConfig.vehicle_health_check}dtc_all_modules`;
    const params = {
      vin_number: val.vin_Number,
      organization_id: 1,
    };

    mainAxiosInstance
      .get(url, { params: params })
      .then((response) => {
        if (response.status === 200) {
          setNoDataFound(false);
          getTableData(response.data);
        }
        if (response.status === 401) {
          setErrorMsg(response.data?.details || "You are not authorized.");
          setAuthenticationMsg(true);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setAuthenticationMsg(true);
          setErrorMsg(err.response.data?.details || "You are not authorized.");
        } else {
          setNoDataFound(true);
          setErrorMsg(false);
        }
      });
  };

  useEffect(() => {
    getOnBoardData({
      vin_Number: vin_Number,
    });
  }, []);

  const renderHeading = (value) => {
    if (value === "current") {
      return <div className="onboard-dtc-header-s">Current DTC</div>;
    } else if (value === "pending") {
      return <div className="onboard-dtc-header-s">Pending DTC</div>;
    } else if (value === "historical") {
      return <div className="onboard-dtc-header-s">Historical DTC</div>;
    }
  };

  const handleClick = (dtc) => {
    setSelectedDTC(dtc.dtcCode);
    setSelectedStatsDate(dtc.statsDate);
    setShowModal(true);
  };

  const renderDetails = (value) => {
    return (
      <>
        <div
          className="onboard-dtc-params-s"
          onClick={() => {
            handleClick(value);
          }}
        >
          <div className="onboard-dtc-param-s">{value.dtcCode}</div>
          <div className="onboard-dtc-description-s">{value.description}</div>
          <div className="onboard-dtc-time-s">{value.statsDate}</div>
          <div className="onboard-dtc-arrow-s">
            <div>
              <RightArrowIcon />
            </div>
          </div>
        </div>
      </>
    );
  };

  const checkIfCategoryToShow = (value, category) => {
    const filteredData = filterData.filter(
      (el) => el.displayname.toLowerCase() === value.type.toLowerCase()
    );
    if (filteredData?.[0].isChecked == true) {
      isDataPresent.push({ value: [value], category: category });
    }
  };

  const renderBody = (value, dtcName) => {
    return (
      <div className={`onboard-dtc-container-s ${value.type}`}>
        {renderHeading(dtcName)}
        <div className="onboard-dtc-type-s">{value.type}</div>
        {value.values.map((el) => {
          return renderDetails(el);
        })}
      </div>
    );
  };

  const AllSelect = (val) => {
    const getUpdatedfilterData = filterData.map((el) => {
      return { ...el, isChecked: val };
    });
    setFilterData(getUpdatedfilterData);
    setSelectAll(val);
    const checkAllSelected = getUpdatedfilterData.filter(
      (el) => el.isChecked === false
    );
    setFilterLength(checkAllSelected);
  };

  const goToComponentSubSystemHistory = (data) => {
    navigate("./component-system-history/diagnostics", {
      relative: "route",
      state: { subSystem: data.subSystem, date: data.date },
    });
  };

  return (
    <>
      <div className="page-wrapper onboard_page_wrapper">
        <div className="onboard-container">
          <div className="flex page-content-header onboard-filter">
            <div
              className="page-filter"
              style={{ position: "relative", bottom: "70px", left: "80px" }}
            >
              <Filter data={AllSelect} selectAll={selectAll}>
                {filterData.map((el) => (
                  <Checkbox
                    title={el.displayname}
                    handleInputClick={() => handleFilterClick(el)}
                    alignProp={{
                      borderTop: "0.5px solid #C4C4C4",
                      display: "flex",
                      justifyContent: "flex-end",
                      padding: "10px",
                      alignItems: "center",
                      flexDirection: "row-reverse",
                    }}
                    checkboxStyle={{ marginRight: "10px" }}
                    isChecked={el.isChecked}
                  />
                ))}
              </Filter>
            </div>
          </div>

          <div className="onboard-dtc-container flex flex-column">
            {onBoardData.map((type) => {
              return (
                <>
                  {type.values.map((category) => {
                    return checkIfCategoryToShow(category, type.name);
                  })}
                </>
              );
            })}
          </div>
          <div>
            {isDataPresent.length != 0 ? (
              <>
                {isDataPresent.map((type) => {
                  return selectAll || filterLength?.length < 7 ? (
                    <div
                      className={`onboard-dtc-wrapper-s ${
                        isDataPresent.length !== 0
                          ? type.category
                          : "non-onboard-dtc-wrapper-s"
                      }`}
                    >
                      {type.value.map((category) => {
                        return renderBody(category, type.category);
                      })}
                    </div>
                  ) : (
                    <>
                      <Feedback />
                    </>
                  );
                })}
              </>
            ) : (
              <>{!selectAll && <Feedback />}</>
            )}
          </div>

          {((noDataFound && !errorMsg) || filterLength.length >= 7) && selectAll &&(
            <div>
              <Feedback />
            </div>
          )}
          {authenticationMsg && (
            <div>
              <NotAuthorized title={errorMsg} />
            </div>
          )}
          {noDataFound && !authenticationMsg && errorMsg && (
            <div>
              <LottieLoad />
            </div>
          )}
        </div>
        {showModal && (
          <Modal
            type={"large"}
            title={"DTC Details"}
            wrapperClass="main-diagnostics-modal on-board-modal"
            titlePosition={"center"}
            isError={false}
            handleClose={() => setShowModal(false)}
          >
            <DTCDetails
              dtcValue={selectedDTC}
              statsDate={selectedStatsDate}
              handleClose={() => setShowModal(false)}
              handleClick={goToComponentSubSystemHistory}
            />
          </Modal>
        )}
      </div>
    </>
  );
};

export default OnBoard;
