import React, { useEffect, useState } from "react";
import Feedback from "../../../../components/common/ErrorComponents/Feedback";
import { BarGraph } from "../../../../components/common/blackbarGraph/Graph";
import { mainAxiosInstance } from "../../../../services/service";
import { WarrantymanagementDashBoard } from "../../../../services/service-constants";
import { useSelector } from "react-redux";
import SingleChartLoader from "../../../../components/common/ErrorComponents/SingleChartLoader";

const WMPrognosticReports = () => {
  const vinNumber = useSelector((state) => state.mainReducer.vin_Number);
  const [chartData, setChartData] = useState(null);
  const [noDataFound, setNoDataFound] = useState(false);
  const [graphID , setGraphID]=useState(null)

  const prepareChartData = (dataSet) => {
    getOutputData(dataSet);
  };

  const getOutputData = (data) => {
    const milesData = [];
    const detailsData = [];
    let xAxisLabel = data.informationDto.xAxisName;
    let yAxisLabel = data.informationDto.yAxisName;
    let graphName = data.informationDto.graphName;
    setGraphID(graphName)
    data.topTenDtcsDtos.forEach((val) => {
      detailsData.push({
        y: val.noOfOccurrences,
        name: val.dtcCode,
        values: val.dtcCode,
      });
    });
    milesData.push({
      data: detailsData,
      xLabel: xAxisLabel,
      yLabel: yAxisLabel,
      id: graphName,
    });
    setChartData(milesData);
  };

  const getPageData = (val) => {
    setNoDataFound(false);
    const url = `${WarrantymanagementDashBoard.warranty_management}/1/dtc/reports`;
    let params = {
      vinNumber: vinNumber, // for VP no need to send vinnumber
      make: "Ford",
      organizationId: 1,
    };

    mainAxiosInstance
      .post(url, params)
      .then((response) => {
        if (response.status === 200) {
          setNoDataFound(false);
          prepareChartData(response.data);
        }
      })
      .catch((err) => {
        setNoDataFound(true);
      });
  };

  useEffect(() => {
    getPageData();
  }, []);
  if (chartData === null && noDataFound === false) {
    return (
      <div className="wm-dashboard-middle-container">
        <SingleChartLoader />;
      </div>
    );
  }
  return (
    <>
      <div className="wm-dashboard-middle-container">
        {noDataFound ? (
          <div>
            <Feedback feedbackClassName="feedback" />
          </div>
        ) : (
          <>
            <BarGraph chartdata={chartData} />
          </>
        )}
      </div>
      {noDataFound === false ? <div className="graph-name">{graphID}</div> : ""}
    </>
  );
};

export default WMPrognosticReports;
