import moment from "moment";
import React, { useState } from "react";
import "./NotificationTable.scss";
import { ReactComponent as RightArrowIcon } from "../../assets/right-arrow.svg";
import Modal from "../../../../components/common/Modal/Modal";
import DTCDetails from "../../../../components/VehicleHealthCheck/Diagnostics/DTCDetails";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { mainAxiosInstance } from "../../../../services/service";
import { vehicleOverviewEndpoints } from "../../../../services/service-constants";

const NotificationTable = ({ columnHeading, tableData }) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [dtcSelected, setDtcSelected] = useState(null);
  const [statsDate, setDtatsDate] = useState(null);
  const [organizationId, setOrganizationId] = useState(null);
  const [vinNumber, setVinNumber] = useState(null);
  const [uuid, setUuid] = useState(null);
  const [notificationData, setNotificationData] = useState(null);
  const momentTime = (date) => {
    return moment(date).format("DD/MM/YYYY - HH:mm");
  };

  useEffect(() => {
    if (tableData) {
      setNotificationData([...tableData]);
    }
  }, [tableData]);

  const onClick = (notification) => {
    setShowModal(true);
    setDtcSelected(notification.dtc);
    setDtatsDate(notification.statsDate);
    setOrganizationId(notification.organizationId);
    setVinNumber(notification.vinNumber);
    setUuid(notification.id);
    const tempNotificationArray = notificationData.map((item) => {
      if (item.id === notification.id) {
        return { ...item, readStatus: 1 };
      } else return { ...item };
    });
    setNotificationData(tempNotificationArray);
  };

  const onNonDtcClick = (notification) => {
    const tempNotificationArray = notificationData.map((item) => {
      if (item.id === notification.id) {
        const url = `${vehicleOverviewEndpoints.vehicleNotificationStatusUpdate}`;
        const params = {
          id: item.id,
          vinNumber: item.vinNumber,
          organizationId: item.organizationId,
          dtcCode: item.dtc,
        };
        mainAxiosInstance.post(url, params);
        return { ...item, readStatus: 1 };
      } else return { ...item };
    });
    setNotificationData(tempNotificationArray);
  };

  const goToComponentSubSystemHistory = (data) => {
    navigate("./component-system-history/diagnostics", {
      relative: "route",
      state: { subSystem: data.subSystem, date: data.date },
    });
  };

  return (
    <>
      <div className="notification-table">
        <table>
          <tbody>
            {notificationData?.map((notification) => (
              <tr
                onClick={() => {
                  if (notification.dtc) {
                    onClick(notification);
                  } else {
                    onNonDtcClick(notification);
                  }
                }}
                className={
                  (notification.dtc ? "cursor-pointer " : "") +
                  (notification.notificationType === 1
                    ? "cursor-pointer notificationType"
                    : "")

                  // notification.readStatus
                  //   ? notification.notificationType === 1
                  //     ? "cursor-pointer notificationType"
                  //     : ""
                  //   : ""

                }
              >
                <td>
                  {notification.dtc ? (
                    `${notification.dtc}:`
                  ) : notification.type ? (
                    `${notification.type}`
                  ) : (
                    <img
                      src={notification.imageUrl}
                      alt="wa"
                      style={{ marginLeft: "10px" }}
                    />
                  )}
                </td>
                <td>{notification.issue}</td>
                <td>{momentTime(notification.lastUpdated)}</td>
                <td
                  onClick={() => {
                    if (notification.dtc && !notification?.type) {
                      onClick(notification);
                    } else {
                      onNonDtcClick(notification);
                    }
                  }}
                >
                  {notification.type ? (
                    ""
                  ) : notification.imageUrl ? (
                    ""
                  ) : (
                    <div className="rightarrow">
                      <RightArrowIcon />
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="notification-table-modal">
        {showModal && (
          <Modal
            type={"large"}
            title={"DTC Details"}
            titlePosition={"center"}
            wrapperClass="main-diagnostics-modal"
            isError={false}
            handleClose={() => setShowModal(false)}
          >
            <DTCDetails
              dtcValue={dtcSelected}
              organizationId={organizationId}
              vinNumber={vinNumber}
              statsDate={statsDate}
              handleClose={() => setShowModal(false)}
              isFieldHidden={false}
              uuid={uuid}
              isNotification={true}
              handleClick={goToComponentSubSystemHistory}
            />
          </Modal>
        )}
      </div>
    </>
  );
};

export default NotificationTable;
