import "../../scss/_common.scss";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import dateIcon from "../../assets/datepicker.png";

const Datepicker = (props) => {
  const { dateValue, onChange, classContainer, secondaryClassContainer } =
    props;
  const [value, setValue] = useState();

  useEffect(() => {
    setValue(dateValue ? dateValue : "");
  }, [dateValue]);

  const CustomInput = () => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <img src={dateIcon} alt="calendar icon" />
      </div>
    );
  };

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          className={`btn btn-secondary date-selector ${classContainer}`}
          disableFuture
          value={value}
          format={"MM/DD/YYYY"}
          onChange={(newValue) => {
            setValue(newValue);
            onChange(newValue?.toString());
          }}
          components={{
            OpenPickerIcon: CustomInput,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              onClick={params.openPicker}
              InputProps={{
                ...params.InputProps,
                className: `datepicker-input-field ${secondaryClassContainer}`,
              }}
            />
          )}
        />
      </LocalizationProvider>
    </div>
  );
};
export default Datepicker;
