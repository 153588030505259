import { dateFormat } from "highcharts";
import { useHref } from "react-router";
import { useEffect, useState } from "react";
import Image from "../assets/customer-image.svg";
import { VehiclePopulationDashBoard } from "../../../services/service-constants";
import { mainAxiosInstance } from "../../../services/service";
import "../VehiclePopulation.scss";
import SelectFromApi from "./SelectFromApi";
import Datepicker from "../../../components/Datepicker/datepicker";

const Generalform = ({
  filterChangeValues,
  setBuildFromDate,
  setBuildEndDate,
  buildEndDate,
  buildFromDate,
  handleOnDateChange,
}) => {
  useEffect(() => {
    setBuildFromDate(new Date());
    setBuildEndDate(new Date());
  }, []);

  return (
    <>
      <div className="filters" id="make">
        <label htmlFor="make">Make</label>

        <SelectFromApi
          name={"make"}
          handleOnChange={filterChangeValues}
          filterValue={"make"}
        />
      </div>

      <div className="filters" id="model">
        <label htmlFor="model">Model</label>
        <SelectFromApi
          name={"model"}
          handleOnChange={filterChangeValues}
          filterValue={"model"}
        />
      </div>

      <div className="filters" id="modelYear">
        <label htmlFor="modelYear">Model Year</label>
        <SelectFromApi
          name={"modelYear"}
          handleOnChange={filterChangeValues}
          filterValue={"modelYear"}
        />
      </div>

      <div className="filters" id="Powerpack">
        <label htmlFor="Powerpack">Powerpack</label>
        <SelectFromApi
          name={"powerPack"}
          handleOnChange={filterChangeValues}
          filterValue={"powerPack"}
        />
      </div>

      <div className="filters" id="Build-dates">
        <label htmlFor="Build-dates">Build dates</label>
        <div
          className="data-picker"
          style={{ position: "relative", right: "17.7rem" }}
        >
          <div className="generalDt">
            <Datepicker
              dateValue={buildFromDate}
              onChange={(fromDate) => handleOnDateChange(fromDate, "f")}
              placeholderText={"mm/dd/yyyy"}
              className="gfDatePicker"
            />
            <span
              style={{
                margin: "0px 10px 0px 10px",
                position: "relative",
                top: 10,
              }}
            >
              To
            </span>
            <Datepicker
              dateValue={buildEndDate}
              onChange={(fromDate) => handleOnDateChange(fromDate, "t")}
              placeholderText={"mm/dd/yyyy"}
              className="gfDatePicker"
            />
          </div>
        </div>
      </div>

      <div className="filters" id="Manufacturingplant">
        <label htmlFor="Manufacturing plant">Manufacturing plant</label>
        <SelectFromApi
          name={"manufacturingPlant"}
          handleOnChange={filterChangeValues}
          filterValue={"manufacturingPlant"}
        />
      </div>

      <div className="filters" id="Vehicle Calibration version">
        <label htmlFor="Vehicle Calibration version">
          Vehicle Calibration version
        </label>
        <SelectFromApi
          name={"calibrationVersion"}
          handleOnChange={filterChangeValues}
          filterValue={"vehicleCalibrationVersion"}
        />
      </div>

      <div className="filters" id="Vehicle Software version">
        <label htmlFor="Vehicle Software version">
          Vehicle Software version
        </label>
        <SelectFromApi
          name={"softwareVersion"}
          handleOnChange={filterChangeValues}
          filterValue={"vehicleSoftwareVersion"}
        />
      </div>

      <div className="filters" id="Sub-system">
        <label htmlFor="Sub-system">Sub-system</label>
        <SelectFromApi
          name={"subSystem"}
          handleOnChange={filterChangeValues}
          filterValue={"subSystem"}
        />
      </div>

      <div className="filters" id="Sub-system Software version">
        <label htmlFor="Sub-system Software version">
          Sub-system Software version
        </label>
        <SelectFromApi
          name={"subSystemSoftwareVersion"}
          handleOnChange={filterChangeValues}
          filterValue={"subSystemSoftwareVersion"}
        />
      </div>

      <div className="filters" id="Sub-system Calibration version">
        <label htmlFor="Sub-system Calibration version">
          Sub-system Calibration version
        </label>
        <SelectFromApi
          name={"subSystemCalibrationVersion"}
          handleOnChange={filterChangeValues}
          filterValue={"subSystemCalibrationVersion"}
        />
      </div>

      <div className="filters" id="Months in Service">
        <label htmlFor="Months in Service">Months in Service</label>
        <SelectFromApi
          name={"monthsInService"}
          handleOnChange={filterChangeValues}
          filterValue={"monthsInService"}
        />
      </div>

      <div className="filters" id="Application">
        <label htmlFor="Application">Application</label>
        <SelectFromApi
          name={"application"}
          handleOnChange={filterChangeValues}
          filterValue={"application"}
        />
      </div>

      <div className="filters" id="Altitude">
        <label htmlFor="Altitude">Altitude</label>
        <SelectFromApi
          name={"altitudeInFeet"}
          handleOnChange={filterChangeValues}
          filterValue={"altitude"}
        />
      </div>

      <div className="filters" id="Ambient Temp">
        <label htmlFor="Ambient Temp">Ambient Temp(Deg.C)</label>
        <SelectFromApi
          name={"ambientTemperature"}
          handleOnChange={filterChangeValues}
          filterValue={"ambientTemperature"}
        />
      </div>

      <div className="filters" id="Vehicle operating state">
        <label htmlFor="Vehicle operating state">Vehicle operating state</label>
        <SelectFromApi
          name={"vehicleOperatingState"}
          handleOnChange={filterChangeValues}
          filterValue={"vehicleOperatingState"}
        />
      </div>

      <div className="filters" id="Vehicle System state">
        <label htmlFor="Vehicle System state">Vehicle System state</label>
        <SelectFromApi
          name={"vehicleSystemState"}
          handleOnChange={filterChangeValues}
          filterValue={"vehicleSystemState"}
        />
      </div>

      <div className="filters" id="Geography">
        <label htmlFor="Geography">Geography</label>
        <SelectFromApi
          name={"geography"}
          handleOnChange={filterChangeValues}
          filterValue={"geography"}
        />
      </div>
    </>
  );
};

export default Generalform;
