import { mainAxiosInstance } from "../../../../services/service";
import { serviceGuideEndPoints } from "../../../../services/service-constants";
import { useDispatch, useSelector } from 'react-redux';

export const getServiceGuideData = async (payload) => {
  const url = `${serviceGuideEndPoints.getServiceGuide}`;

  //   TODO : dynamic vin_number and org_id required
  const params = {
    vin_number: payload?.vinNumber,
    organization_id: 1,
    dtc_code: "P0102",
    service_guide_type: payload?.serviceType,
  };
  return mainAxiosInstance
    .get(url, { params: params })
    .then((response) => {
      if (response.status === 200) {
        return {
          code: response.status,
          data: response.data,
        };
      } if (response.status === 401) {
        return {
          code: response.status,
          data: response.data.detail || "You are not authorized",
        };
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        return {
          code: err.response.status,
          data: err.response.data.detail || "You are not authorized",
        };
      } else {
        return {
          code: "error",
          data: err.response.data.detail || "Something went wrong",
        };
      }
    });
};
