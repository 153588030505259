import React, { useEffect } from "react";
import { NavLink, Outlet, useNavigate, useLocation } from "react-router-dom";
import TabLayout from "../../../components/Layout/TabLayout";

const GeneratedDashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/vp-saved-filter/comparision") {
      navigate("/vp-saved-filter/comparision/GeneratedDashboard/diagnostics", {
        replace: true,
      });
    }
  }, [location.pathname]);

  const navLink = [
    {
      label: "Reports For Diagnostics",
      to: "/vp-saved-filter/comparision/GeneratedDashboard/diagnostics",
    },
    {
      label: "Reports For Prognostics",
      to: "/vp-saved-filter/comparision/GeneratedDashboard/prognostics",
    },
  ];

  return (
    <div className="full-width ">
      {/* <TabLayout tabLink={navLink} /> */}
      <Outlet />
    </div>
  );
};

export default GeneratedDashboard;
