import { BarGraph } from "../../../components/common/blackbarGraph/Graph";
import { DotGraph } from "../../../components/common/blackdotGraph/Graph";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../VehiclePopulation.scss";
import {
  getMakedata,
  getModeldata,
  getModelYeardata,
  getManufacturedata,
  getBuildDatesdata,
  getPowerPackdata,
  getSubSystemdata,
  getSoftwaredata,
  getCalibrationdata,
  getdtcdata,
  gettemperaturedata,
  getAltitudedata,
  getSystemStatedata,
  getOperatingdata,
  getCustomerdata,
  getRuldata,
} from "../Apis/Apis";
import { useLocation } from "react-router-dom";
import SingleChartLoader from "../../../components/common/ErrorComponents/SingleChartLoader";
import Feedback from "../../../components/common/ErrorComponents/Feedback";
import Pagination from "../../../components/common/Pagination";
import { logDOM } from "@testing-library/react";

const VPRightContainerPagesComponent = () => {
  const [noDataFound, setNoDataFound] = useState(false);
  const vinNumber = useSelector((state) => state.mainReducer.vin_Number);
  const [chartData, setChartData] = useState(null);
  const [graphName, setGraphName] = useState("");
  const [pagination, setPagination] = useState({});
  const url = window.location.href;
  const urlParams = new URLSearchParams(url.split("?")[1]);
  const location = useLocation();
  const [containerName, setContainerName] = useState(
    urlParams.get("containerId")
  );
  const [pageSize, setPageSize] = useState(0);

  const getChartData = async (getDataFunc, val) => {
    //setChartData(null);


    setNoDataFound(false);
    const { code, data, detailsData, PaginationDetails } = await getDataFunc(
      pageSize
    );
    setPagination(PaginationDetails);
    if (code === 200) {
      setNoDataFound(false);
      const milesData = [];
      let xAxisLabel = data.informationDto.xAxisName;
      let yAxisLabel = data.informationDto.yAxisName;
      setGraphName(data.informationDto.graphName);
      milesData.push({
        data: detailsData,
        xLabel: xAxisLabel,
        yLabel: yAxisLabel,
        id: graphName,
      });
      setChartData(milesData);
    }
    if (code === "error") {
      setNoDataFound(true);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const containerId = urlParams.get("key");
    setContainerName(parseInt(containerId));
  }, [location]);

  useEffect(() => {
    if (containerName === 0) {
      getChartData(getMakedata);
    } else if (containerName === 1) {
      getChartData(getModeldata);
    } else if (containerName === 2) {
      getChartData(getModelYeardata);
    } else if (containerName === 3) {
      getChartData(getManufacturedata);
    } else if (containerName === 4) {
      getChartData(getBuildDatesdata);
    } else if (containerName === 5) {
      getChartData(getPowerPackdata);
    } else if (containerName === 6) {
      getChartData(getSubSystemdata);
    } else if (containerName === 7) {
      getChartData(getSoftwaredata);
    } else if (containerName === 8) {
      getChartData(getCalibrationdata);
    } else if (containerName === 9) {
      getChartData(getdtcdata);
    } else if (containerName === 10) {
      getChartData(gettemperaturedata);
    } else if (containerName === 11) {
      getChartData(getAltitudedata);
    } else if (containerName === 12) {
      getChartData(getSystemStatedata);
    } else if (containerName === 13) {
      getChartData(getOperatingdata);
    } else if (containerName === 14) {
      getChartData(getCustomerdata);
    }
  }, [containerName, pageSize]);

  useEffect(() => {
    setPageSize(0);
  }, [containerName]);

  const onPageChanged = (val) => {
    setPageSize(val);
  };

  if (chartData === null && noDataFound === false) {
    return (
      <div className="vehicle-dashboard-middle-container">
        <SingleChartLoader />
      </div>
    );
  }

  return (
    <>
      <div className="vehicle-dashboard-middle-container">
        {noDataFound && chartData === null ?  (
            <div>
            <Feedback feedbackClassName="feedback" />
          </div>
         
        ) : (
        
           <>
           <BarGraph chartdata={chartData} pageSize={pageSize} />
         </>
        )}
      </div>

      {!noDataFound && (
        <>
          <div className="graph-name">{graphName} </div>
          {pagination?.totalPages > 1 && (
            <Pagination
              pageCount={pagination?.totalPages}
              pageRange={pagination?.size}
              onPageChanged={onPageChanged}
              forcePage={pageSize}
            />
          )}
        </>
      )}
    </>
  );
};

export default VPRightContainerPagesComponent;
