import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { addRootCausePayload } from "../../../store/rootCauseAnalysis/actions";
import { useDispatch, useSelector } from "react-redux";
import { getActions } from "./api";
import LottieLoad from "../../../components/common/ErrorComponents/LottieLoad";
import Feedback from "../../../components/common/ErrorComponents/Feedback";
import NotAuthorized from "../../../components/common/ErrorComponents/NotAuthorized";
import "./index.scss";
import Breadcrumbs from "../../../components/common/Breadcrumb/Breadcrumbs";
import routes from "../../../utils/constants";

const PrescribedActions = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState(false);
  const [notAuthorized, setNotAuthorized] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const vinNumber = useSelector((state) => state.mainReducer.vin_Number);
  let rcaPayload = useSelector(
    (state) => state.rootCauseReducer.rootCauseAnalysisPayload
  );
  if (!rcaPayload || rcaPayload === {}) {
    rcaPayload = JSON.parse(
      window.localStorage.getItem("root-cause-payload") || {}
    );
    dispatch(addRootCausePayload({ ...rcaPayload }));
  }

  const dtcCode = rcaPayload.dcode;

  const getPrescribedActions = async () => {
    const { code, data } = await getActions({
      vinNumber: vinNumber,
      organization: 1,
      statsdate: "2022-06-02",
      dtcCode: dtcCode,
    });
    if (code === 200) {
      setData(data);
    } else if (code === 401) {
      setNotAuthorized(true);
      setErrorMsg(data);
    } else {
      setApiError(true);
      setErrorMsg(data);
    }
  };

  useEffect(() => {
    getPrescribedActions();
  }, []);

  if (notAuthorized) {
    return <NotAuthorized title={errorMsg} />;
  }
  if (apiError) {
    return <Feedback />;
  }
  if (!data) {
    return <LottieLoad />;
  }

  return (
    <div className="page-wrapper prescribed_actions">
      <div>
        <Breadcrumbs routes={routes} />
      </div>
      <h3 className="sensor">{data.sensor}</h3>
      <div className="actions_table">
        <table>
          <thead>
            <tr>
              <th>Root Cause Probability</th>
              <th>Possible Faults</th>
              <th>Corrective Actions</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(data.prescribedActions) &&
              data.prescribedActions.length &&
              data.prescribedActions.map((action) => (
                <tr>
                  <td style={{ textAlign: "center" }}>
                    {action.rootCauseProbability}%
                  </td>
                  <td>{action.possibleFaults}</td>
                  <td>{action.correctiveActions}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PrescribedActions;
