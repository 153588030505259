import { BarGraph } from "../../../../components/common/blackbarGraph/Graph";
import {
  ServiceConfig,
  VehiclePopulationDashBoard,
} from "../../../../services/service-constants";
import { mainAxiosInstance } from "../../../../services/service";
import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "../../../../components/common/Select/Select";
import Datepicker from "../../../../components/Datepicker/datepicker";
import "../../VehiclePopulation.scss";
import intervalPreIcon from "../../assets/select-metric-icon.svg";
import Feedback from "../../../../components/common/ErrorComponents/Feedback";
import SingleChartLoader from "../../../../components/common/ErrorComponents/SingleChartLoader";

import moment from "moment";

const Top10Dtc = () => {
  const [noDataFound, setNoDataFound] = useState(false);
  const [showChartLoader, setShowChartLoader] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [defaultInterval, setDefaultInterval] = useState("Miles");
  const vinNumber = useSelector((state) => state.mainReducer.vin_Number);
  const [chartData, setChartData] = useState(null);
  const interval = ["Miles", "Date"];
  const [showInput, setShowInput] = useState(true);
  const [showDatepicker, setShowDatepicker] = useState(false);
  const [upperLimitData, setUpperLimitData] = useState(5000);
  const [lowerLimitData, setLowerLimitData] = useState(1);
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndtDate, setSelectedEndDate] = useState(new Date());
  const [paramData, setParamData] = useState(null);
  const [showModal2, setShowModal2] = useState(false);
  const showPopup = localStorage.getItem("token");
  const [graphPopup, setGraphPopup] = useState(false);

  const prepareChartData = (dataSet) => {
    getChartData(dataSet);
  };

  const getOutputData = (data) => {
    const milesData = [];
    const detailsData = [];
    let xAxisLabel = data.informationDto.xAxisName;
    let yAxisLabel = data.informationDto.yAxisName;
    let graphName = data.informationDto.graphName;
    data.topTenDtcsDtos.forEach((val) => {
      detailsData.push({
        y: val.noOfOccurrences,
        name: val.dtcCode,
        values: val.dtcCode,
      });
    });
    milesData.push({
      data: detailsData,
      xLabel: xAxisLabel,
      yLabel: yAxisLabel,
      id: graphName,
    });
    setChartData(milesData);
  };

  const getChartData = async (response) => {
    getOutputData(response);
  };

  useEffect(() => {
    let url = `${VehiclePopulationDashBoard.defaultApi}`;
    mainAxiosInstance
      .get(url)
      .then((response) => {
        setParamData(response.data);
      })
      .catch((er) => {
        throw er;
      });
  }, []);
  useEffect(() => {
    if (paramData !== null) getPageData();
  }, [
    paramData,
    defaultInterval,
    lowerLimitData,
    upperLimitData,
    selectedStartDate,
    selectedEndtDate,
  ]);

  const getPageData = (val) => {
    setNoDataFound(false);
    const url = `${VehiclePopulationDashBoard.top_10_dtc}/0`;
    let params = {};

    switch (defaultInterval) {
      case "Miles": {
        delete paramData.type;
        params = {
          fromMiles: lowerLimitData,
          toMiles: upperLimitData,
          type: "by_miles",
        };
        break;
      }

      case "Date": {
        delete paramData.type;
        params = {
          fromDate: moment(selectedStartDate).format("yyyy-MM-DD"),
          toDate: moment(selectedEndtDate).format("yyyy-MM-DD"),
          type: "by_date",
        };
        break;
      }

      default: {
        params = {
          fromMiles: lowerLimitData,
          toMiles: upperLimitData,
          type: "by_miles",
        };
      }
    }

    const selectedData = JSON.parse(localStorage.getItem("selectedData"));
    const selectedDiagonsticsData = JSON.parse(
      localStorage.getItem("selectedDiagonosticsData")
    );
    const FilterSelectedValues = {
      ...selectedData,
      ...selectedDiagonsticsData,
    };
    let filteredValues = {};
    if (Object.keys(FilterSelectedValues).length === 0) {
      filteredValues = paramData;
    } else {
      filteredValues = FilterSelectedValues;
    }

    let data = Object.assign({ ...filteredValues }, { ...params });

    mainAxiosInstance
      .post(url, data)
      .then((response) => {
        if (response.status === 200) {
          setNoDataFound(false);
          setShowChartLoader(false);
          prepareChartData(response.data);
        }
        if (response.status === 401) {
          setApiError(response.data?.details || "You are not authorized.");
          setNoDataFound(true);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setApiError(err.response.data?.details || "You are not authorized.");
          setNoDataFound(true);
        } else {
          setShowChartLoader(true);
          setNoDataFound(true);
        }
      });
  };

  const handleOnStartDateChange = (dates) => {
    const startDate = moment(dates).format("yyyy-MM-DD");
    setSelectedStartDate(startDate);
  };

  const handleOnEndDateChange = (dates) => {
    const endDate = moment(dates).format("yyyy-MM-DD");
    setSelectedEndDate(endDate);
  };

  const handleIntervalOptionClick = (val) => {
    setDefaultInterval(val);
    switch (val) {
      case "Miles":
        setShowInput(true);
        setShowDatepicker(false);
        setLowerLimitData(1);
        setUpperLimitData(5000);
        break;

      case "Date":
        setShowInput(false);
        setShowDatepicker(true);
        setSelectedStartDate(new Date());
        setSelectedEndDate(new Date());

        break;

      default:
        setShowInput(true);
        setShowDatepicker(false);
        setLowerLimitData(1);
        setUpperLimitData(5000);
        break;
    }
  };

  if (chartData === null && noDataFound === false) {
    return (
      <div className="vehicle-dashboard-middle-container">
        <SingleChartLoader />;
      </div>
    );
  }

  const handleCloseModal2 = () => {
    setShowModal2(false);
  };

  const handleLowerRulInput = (event) => {
    const { value } = event.target;
    const letters = /^-?\d*\.?\d*$/.test(value);
    if (letters) {
      setLowerLimitData(value);
    }
  };

  const handleUpperRulInput = (event) => {
    const { value } = event.target;
    const letters = /^-?\d*\.?\d*$/.test(value);
    if (letters) {
      setUpperLimitData(value);
    }
  };

  return (
    <>
      <div className="vehicle-dashboard-middle-container">
        <div
          className="page-options-container metric-box"
          style={{ zIndex: 1 }}
        >
          <Select
            options={interval}
            defaultSelected={defaultInterval}
            handleOptionClick={handleIntervalOptionClick}
            secondaryContainerClass="top-10-input-box"
            containerClass="top-10-dtc"
            // preIcon={intervalPreIcon}
          />
          {showInput && (
            <>
              <input
                type="text"
                className="Dtc-value-input "
                placeholder={`Enter Lower Limit`}
                value={lowerLimitData}
                onChange={handleLowerRulInput}
                maxLength={10}
              />
              <input
                type="text"
                className="Dtc-value-input"
                placeholder={`Enter Upper Limit`}
                value={upperLimitData}
                onChange={handleUpperRulInput}
                maxLength={10}
              />
            </>
          )}
          {showDatepicker && (
            <div
              style={{
                display: "flex",
                position: "relative",
                bottom: "5px",
              }}
            >
              <Datepicker
                onChange={handleOnStartDateChange}
                classContainer="top-10-dtc-date"
                secondaryClassContainer="input-value-top-10-dtc"
              />

              <Datepicker
                onChange={handleOnEndDateChange}
                classContainer="top-10-dtc-date"
                secondaryClassContainer="input-value-top-10-dtc"
              />
            </div>
          )}
        </div>
        {noDataFound ? (
          <div>
            <Feedback feedbackClassName="feedback" />
          </div>
        ) : !noDataFound && showChartLoader ? (
          <>
            <Feedback feedbackClassName="feedback" />
          </>
        ) : (
          <>
            <BarGraph chartdata={chartData} />
          </>
        )}
      </div>
      {!noDataFound && !showChartLoader ? (
        <div className="graph-name">Top 10 DTC </div>
      ) : (
        ""
      )}
      {graphPopup && (
        <button onClick={() => setShowModal2(true)} className="popBtn"></button>
      )}

      {showModal2 && showPopup && (
        <div style={modalStyle}>
          <div style={modalContentStyle}>
            <p>
              <b>
                You need to select atleast one from Prognastics and Diagonostics
              </b>
            </p>
            <button
              onClick={handleCloseModal2}
              style={{ backgroundColor: "#e58817", color: "#fff" }}
            >
              OK
            </button>
          </div>
        </div>
      )}
    </>
  );
};
const modalStyle = {
  position: "fixed",
  background: "#000000aa",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 1111,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
};

const modalContentStyle = {
  textAlign: "center",
  position: "fixed",
  top: "50%",
  left: "50%",
  width: 450,
  transform: "translate(-50%, -50%)",
  background: "white",
  padding: "20px",
  borderRadius: "10px",
  display: "",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 11111,
};

export default Top10Dtc;
