import React from "react";
import Select from "../../../components/common/Select/Select";
import { useState } from "react";
import downArrow from "../../../assets/arrow-drop-down-black.svg";
import diamondArrow from "../../../assets/arrow-dropdown-diamond.svg";
import editLogo from "../../../assets/edit-pen-logo.svg";
import close from "../../../assets/close.svg";
import threeDots from "../../../assets/three-dots-vertical.svg";
import ModalPopup from "../../../../src/components/common/Modal/ModalPopup";
import { mainAxiosInstance } from "../../../services/service";
import { VehiclePopulationDashBoard } from "../../../services/service-constants";
import { useEffect } from "react";
import Datepicker from "../../../components/Datepicker/datepicker";
import moment from "moment";
import doubleArrow from "../../../assets/double-arrow-icon.svg";

const FilterCard = ({
  data,
  handleCheckedCard,
  checkedCards,
  getData,
  validationMsg,
  checkedCardsValidation,
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [showDropDownList, setShowDropDownList] = useState(false);
  const [viewAll, setViewAll] = useState(false);
  const [showSign, setShowSign] = useState(false);
  const [saveAsModal, setSaveAsModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [modelDropdown, setModelDropdown] = useState(true);
  const [popupMakeDropdown, setPopupMakeDropdown] = useState(true);
  const [popupModelDropdown, setPopupModelDropdown] = useState(true);
  const [popupYearDropdown, setPopupYearDropdown] = useState(true);
  const [popupManufacturingPlantDropdown, setPopupManufacturingPlantDropdown] =
    useState(true);
  const [popupDtcDropdown, setPopupDtcDropdown] = useState(true);
  const [popupBuildDateDropdown, setPopupBuildDateDropdown] = useState(true);
  const [
    popupVehicleCalibrationversionDropdown,
    setPopupVehicleCalibrationversionDropdown,
  ] = useState(true);
  const [
    popupVehicleSoftwareversionDropdown,
    setPopupVehicleSoftwareversionDropdown,
  ] = useState(true);
  const [popupCustomercomplaintDropdown, setPopupCustomercomplaintDropdown] =
    useState(true);
  const [popupSubSystemDropdown, setPopupSubSystemDropdown] = useState(true);
  const [popupFreezeFrameDataDropdown, setPopupFreezeFrameDataDropdown] =
    useState(true);
  const [popupAltitudeDropdown, setPopupAltitudeDropdown] = useState(true);
  const [popupAmbientTempDropdown, setPopupAmbientTempDropdown] =
    useState(true);
  const [popupVehicleOperatingDropdown, setPopupVehicleOperatingDropdown] =
    useState(true);
  const [popupVehicleSystemDropdown, setPopupVehicleSystemDropdown] =
    useState(true);
  const [popupGeographyDropdown, setPopupGeographyDropdown] = useState(true);
  const [viewAllMakeDropdown, setViewAllMakeDropdown] = useState(true);
  const [viewAllYearDropdown, setViewAllYearDropdown] = useState(true);
  const [
    viewAllManufacturingPlantDropdown,
    setViewAllManufacturingPlantDropdown,
  ] = useState(true);
  const [viewAllDtcDropdown, setViewAllDtcDropdown] = useState(true);
  const [viewAllBuildDateDropdown, setViewAllBuildDateDropdown] =
    useState(true);
  const [
    viewAllVehicleCalibrationversionDropdown,
    setViewAllVehicleCalibrationversionDropdown,
  ] = useState(true);
  const [
    viewAllVehicleSoftwareversionDropdown,
    setViewAllVehicleSoftwareversionDropdown,
  ] = useState(true);
  const [
    viewAllCustomercomplaintDropdown,
    setViewAllCustomercomplaintDropdown,
  ] = useState(true);
  const [viewAllSubSystemDropdown, setViewAllSubSystemDropdown] =
    useState(true);
  const [viewAllFreezeFrameDataDropdown, setViewAllFreezeFrameDataDropdown] =
    useState(true);
  const [viewAllAltitudeDropdown, setViewAllAltitudeDropdown] = useState(true);
  const [viewAllAmbientTempDropdown, setViewAllAmbientTempDropdown] =
    useState(true);
  const [viewAllVehicleOperatingDropdown, setViewAllVehicleOperatingDropdown] =
    useState(true);
  const [viewAllVehicleSystemDropdown, setViewAllVehicleSystemDropdown] =
    useState(true);
  const [viewAllGeographyDropdown, setViewAllGeographyDropdown] =
    useState(true);
  const [modelData, setModelData] = useState([]);
  const [yearData, setYearData] = useState([]);
  const [manufacturingData, setManufacturingData] = useState([]);
  const [dtcData, setDtcData] = useState([]);
  const [calibrationData, setCalibrationData] = useState([]);
  const [softwareData, setSoftwareData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [subSystemData, setSubSystemData] = useState([]);
  const [freezeFrameData, setFreezeFrameData] = useState([]);
  const [altitudeData, setAltitudeData] = useState([]);
  const [temperatureData, setTemperatureData] = useState([]);
  const [operatingData, setOperatingData] = useState([]);
  const [systemStateData, setSystemStateData] = useState([]);
  const [geographyData, setGeographyData] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  const [checkedCard, setCheckedCards] = useState(checkedCards);
  const [noDataSearch, setNoDataSearch] = useState("");
  const [DiscardChangeMsg, setdiscardChangeMsg] = useState(false);

  const onValueChange = (value) => {
    if (value?.length === 0) {
      setNoDataSearch("Filter name should not be empty");
    } else if (value?.length === 30) {
      setNoDataSearch("Filter name should not be more than 30 characters");
    } else {
      setCheckedItems({
        ...checkedItems,
        filterName: value,
      });
      setNoDataSearch("");
    }
  };

  const handleEdit = () => {
    setShowDropDown(true);
    const modelUrl = `${VehiclePopulationDashBoard.modelDropdown}`;
    const modelYearUrl = `${VehiclePopulationDashBoard.model_yearDropdown}`;
    const manufacturingUrl = `${VehiclePopulationDashBoard.manufactoringDropdown}`;
    const dtcUrl = `${VehiclePopulationDashBoard.dtcDropdown}`;
    const calibrationUrl = `${VehiclePopulationDashBoard.calibration_versionDropdown}`;
    const softwareUrl = `${VehiclePopulationDashBoard.software_versionDropdown}`;
    const customerUrl = `${VehiclePopulationDashBoard.customer_complaintsDropdown}`;
    const subSystemUrl = `${VehiclePopulationDashBoard.sub_systemDropdown}`;
    const freezeUrl = `${VehiclePopulationDashBoard.freezeDropdown}`;
    const altitudeUrl = `${VehiclePopulationDashBoard.altitudeDropdown}`;
    const temperatureUrl = `${VehiclePopulationDashBoard.temperatureDropdown}`;
    const operatingUrl = `${VehiclePopulationDashBoard.operating_stateDropdown}`;
    const systemSateUrl = `${VehiclePopulationDashBoard.system_stateDropdown}`;
    const geographyUrl = `${VehiclePopulationDashBoard.geographyDropdown}`;

    mainAxiosInstance
      .get(modelUrl)
      .then((response) => {
        setModelData(response.data);
      })
      .catch((err) => {});
    mainAxiosInstance
      .get(modelYearUrl)
      .then((response) => {
        setYearData(response.data);
      })
      .catch((err) => {});
    mainAxiosInstance
      .get(manufacturingUrl)
      .then((response) => {
        setManufacturingData(response.data);
      })
      .catch((err) => {});
    mainAxiosInstance
      .get(dtcUrl)
      .then((response) => {
        setDtcData(response.data);
      })
      .catch((err) => {});
    mainAxiosInstance
      .get(calibrationUrl)
      .then((response) => {
        setCalibrationData(response.data);
      })
      .catch((err) => {});
    mainAxiosInstance
      .get(softwareUrl)
      .then((response) => {
        setSoftwareData(response.data);
      })
      .catch((err) => {});
    mainAxiosInstance
      .get(customerUrl)
      .then((response) => {
        setCustomerData(response.data);
      })
      .catch((err) => {});
    mainAxiosInstance
      .get(subSystemUrl)
      .then((response) => {
        setSubSystemData(response.data);
      })
      .catch((err) => {});
    mainAxiosInstance
      .get(freezeUrl)
      .then((response) => {
        setFreezeFrameData(response.data);
      })
      .catch((err) => {});
    mainAxiosInstance
      .get(altitudeUrl)
      .then((response) => {
        setAltitudeData(response.data);
      })
      .catch((err) => {});
    mainAxiosInstance
      .get(temperatureUrl)
      .then((response) => {
        setTemperatureData(response.data);
      })
      .catch((err) => {});
    mainAxiosInstance
      .get(operatingUrl)
      .then((response) => {
        setOperatingData(response.data);
      })
      .catch((err) => {});
    mainAxiosInstance
      .get(systemSateUrl)
      .then((response) => {
        setSystemStateData(response.data);
      })
      .catch((err) => {});
    mainAxiosInstance
      .get(geographyUrl)
      .then((response) => {
        setGeographyData(response.data);
      })
      .catch((err) => {});
  };

  const handleSave = () => {
    setShowDropDownList(false);
    setShowDropDown(false);
    const url = `${VehiclePopulationDashBoard.saved_filters}`;
    mainAxiosInstance
      .patch(url, checkedItems)
      .then((response) => {
        setSaveAsModal(false);
        setShowDropDown(false);
        setViewAll(false);
        getData();
      })
      .catch((err) => {});
  };

  useEffect(() => {
    setCheckedItems(data);
    setCheckedCards(checkedCards);
  }, [data, checkedCards]);

  const handleSaveAs = () => {
    if (noDataSearch.length > 0) {
    } else {
      setSaveAsModal(false);
      const url = `${VehiclePopulationDashBoard.saved_filters}`;
      mainAxiosInstance
        .post(url, checkedItems)
        .then((response) => {
          setSaveAsModal(false);
          setShowDropDown(false);
          setShowDropDownList(false);
          setViewAll(false);
          getData();
        })
        .catch((errr) => {});
    }
  };

  const handleSaveAsModel = () => {
    setSaveAsModal(false);
    setdiscardChangeMsg(true);
    setCheckedItems(data);
  };

  const handleDischargedMsg = () => {
    setSaveAsModal(false);
    setdiscardChangeMsg(false);
  };

  const defaultData = () => {
    setCheckedItems(data);
  };

  const handleCancel = () => {
    setCancelModal(false);
    defaultData();
    setShowDropDown(false);
  };

  const handleCancelModal = () => {
    setCancelModal(false);
  };

  const handleEditClose = () => {
    setShowDropDown(false);
    setShowDropDownList(false);
    defaultData();
    setViewAll(false);
  };

  const [dtccheckedItems, setDtcCheckedItems] = useState({ dtcs: [] });

  const handleChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    const checked = event.target.checked;

    if (name === "dtcs") {
      if (checked) {
        setCheckedItems({
          ...checkedItems,
          [name]:
            checkedItems[name] === null
              ? [value]
              : [...checkedItems[name], value],
        });
      } else {
        setCheckedItems({
          ...checkedItems,
          [name]:
            checkedItems[name].filter((item) => item !== value).length >= 1
              ? checkedItems[name].filter((item) => item !== value)
              : null,
        });
      }
    } else {
      setCheckedItems({
        ...checkedItems,
        [name]: value,
      });
    }
  };

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    const checked = event.target.checked;

    if (checked) {
      setCheckedItems({
        ...checkedItems,
        [name]:
          checkedItems[name] === null
            ? [value]
            : [...checkedItems[name], value],
      });
    } else {
      setCheckedItems({
        ...checkedItems,
        [name]:
          checkedItems[name].filter((item) => item !== value).length >= 1
            ? checkedItems[name].filter((item) => item !== value)
            : null,
      });
    }
  };

  const handleModelYearChange = (event) => {
    const value = parseInt(event.target.value);
    const name = event.target.name;
    const checked = event.target.checked;

    if (checked) {
      setCheckedItems({
        ...checkedItems,
        [name]:
          checkedItems[name] === null
            ? [value]
            : [...checkedItems[name], value],
      });
    } else {
      setCheckedItems({
        ...checkedItems,
        [name]:
          checkedItems[name].filter((item) => item !== value).length >= 1
            ? checkedItems[name].filter((item) => item !== value)
            : null,
      });
    }
  };

  const handleFromBuildDateChange = (dates) => {
    const fromBuildDate = moment(dates).format("yyyy-MM-DD");
    setCheckedItems({
      ...checkedItems,
      fromBuildDate: fromBuildDate,
    });
  };

  const handleToBuildDateChange = (dates) => {
    const toBuildDate = moment(dates).format("yyyy-MM-DD");
    setCheckedItems({
      ...checkedItems,
      toBuildDate: toBuildDate,
    });
  };

  const handleCancelModelButton = () => {};

  return (
    <div className="saved-filter">
      <div
        className={
          showDropDown || viewAll
            ? "saved-filter-card-container-full"
            : checkedCards.id?.includes(checkedItems.id)
            ? "saved-filter-card-container active"
            : "saved-filter-card-container"
        }
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "5px",
            fontSize: "10px",
            color: "red",
            position: "absolute",
            marginLeft: "18px",
          }}
        >
          {checkedCardsValidation?.id?.length === 3 &&
          checkedItems.id?.includes(checkedCardsValidation?.id[2])
            ? validationMsg
            : ""}
        </div>
        {showDropDown ? (
          <div className="popup">
            <div className="popup-wrapper">
              <div className="popup-container">
                <div className="popup-header">
                  <div className="popup-close">
                    <img
                      src={close}
                      alt="close"
                      onClick={() => handleEditClose()}
                    />
                  </div>
                  <div className="button">
                    <button
                      className="btn btn-primary-blue saved-filter"
                      onClick={() => setShowDropDownList(!showDropDownList)}
                    >
                      Save
                      <img
                        className="edit-icon"
                        src={threeDots}
                        alt="down-arrow"
                      />
                    </button>
                    {showDropDownList ? (
                      <div className="popup-dropdownlist-save">
                        <div
                          className="popup-dropdown-btn"
                          onClick={() => handleSave()}
                        >
                          Save
                        </div>
                        <div
                          className="popup-dropdown-btn"
                          onClick={() => setSaveAsModal(true)}
                        >
                          Save as
                        </div>
                        <div
                          className="popup-dropdown-btn"
                          onClick={() => setCancelModal(true)}
                        >
                          Cancel
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    {DiscardChangeMsg ? (
                      <>
                        <ModalPopup
                          firstParagraph={"Discard Changes?"}
                          onValueChange={handleCancelModelButton}
                          firstButton={"Back"}
                          secondButton={"Yes"}
                          displayInput={"true"}
                          // errorModel={noDataSearch}
                          //inputValue={checkedItems.filterName}
                          closeModal={() => setSaveAsModal(true)}
                          onClick={() => handleDischargedMsg()}
                          //   maxLength={30}
                        />
                      </>
                    ) : (
                      <></>
                    )}

                    {saveAsModal ? (
                      <>
                        <ModalPopup
                          firstParagraph={"Save this filter as"}
                          onValueChange={onValueChange}
                          firstButton={"Cancel"}
                          secondButton={"Save"}
                          inputValue={checkedItems.filterName}
                          closeModal={() => handleSaveAsModel()}
                          onClick={() => handleSaveAs()}
                          maxLength={30}
                          errorModel={noDataSearch}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    {cancelModal ? (
                      <>
                        <ModalPopup
                          firstParagraph={"Discard Changes?"}
                          onValueChange={onValueChange}
                          firstButton={"Back"}
                          secondButton={"Yes"}
                          hideInput={true}
                          closeModal={() => setCancelModal(false)}
                          onClick={() => handleCancel()}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className="popup-content">
                <div className="popup-dropdown">
                  <div
                    className="popup-label"
                    onClick={() => {
                      setPopupMakeDropdown(!popupMakeDropdown);
                    }}
                  >
                    <div className="popup-label-content">Make</div>
                    <img
                      className={
                        popupMakeDropdown ? "arrow-icon-up" : "arrow-icon"
                      }
                      src={diamondArrow}
                      alt="down-arrow"
                    />
                  </div>
                  {popupMakeDropdown ? (
                    <>
                      <div className="popup-checkbox active">
                        <input type="checkbox" checked />
                        <span></span>
                        {checkedItems.make}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="empty-checkbox"></div>
                    </>
                  )}
                </div>
                <div className="popup-dropdown">
                  <div
                    className="popup-label"
                    onClick={() => {
                      setPopupModelDropdown(!popupModelDropdown);
                    }}
                  >
                    <div className="popup-label-content">Model</div>
                    <img
                      className={
                        popupModelDropdown ? "arrow-icon-up" : "arrow-icon"
                      }
                      src={diamondArrow}
                      alt="down-arrow"
                    />
                  </div>
                  {popupModelDropdown ? (
                    <>
                      {modelData.map((obj) => {
                        return (
                          <>
                            <div
                              className={
                                checkedItems.model !== null
                                  ? checkedItems.model.includes(obj)
                                    ? "popup-checkbox active"
                                    : "popup-checkbox"
                                  : "popup-checkbox"
                              }
                            >
                              <label className="popup-label-checkbox">
                                <input
                                  type="checkbox"
                                  defaultChecked={
                                    checkedItems.model !== null
                                      ? checkedItems.model.includes(obj)
                                      : checkedItems.model
                                  }
                                  name="model"
                                  value={obj}
                                  onChange={handleCheckboxChange}
                                />
                                <span></span>
                                <div className="popup-checkbox-content">
                                  {obj}
                                </div>
                              </label>
                            </div>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <div className="empty-checkbox"></div>
                    </>
                  )}
                </div>
                <div className="popup-dropdown">
                  <div
                    className="popup-label"
                    onClick={() => {
                      setPopupYearDropdown(!popupYearDropdown);
                    }}
                  >
                    <div className="popup-label-content">Year</div>
                    <img
                      className={
                        popupYearDropdown ? "arrow-icon-up" : "arrow-icon"
                      }
                      src={diamondArrow}
                      alt="down-arrow"
                    />
                  </div>
                  {popupYearDropdown ? (
                    <>
                      {yearData.map((obj) => {
                        return (
                          <>
                            <div
                              className={
                                checkedItems.modelYear !== null
                                  ? checkedItems.modelYear.includes(obj)
                                    ? "popup-checkbox active"
                                    : "popup-checkbox"
                                  : "popup-checkbox"
                              }
                            >
                              <label className="popup-label-checkbox">
                                <input
                                  type="checkbox"
                                  defaultChecked={
                                    checkedItems.modelYear !== null
                                      ? checkedItems.modelYear.includes(obj)
                                      : checkedItems.modelYear
                                  }
                                  name="modelYear"
                                  value={obj}
                                  onChange={handleModelYearChange}
                                />
                                <span></span>
                                <div className="popup-checkbox-content">
                                  {obj}
                                </div>
                              </label>
                            </div>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <div className="empty-checkbox"></div>
                    </>
                  )}
                </div>
                <div className="popup-dropdown">
                  <div
                    className="popup-label"
                    onClick={() => {
                      setPopupManufacturingPlantDropdown(
                        !popupManufacturingPlantDropdown
                      );
                    }}
                  >
                    <div className="popup-label-content">
                      Manufacturing Plant
                    </div>
                    <img
                      className={
                        popupManufacturingPlantDropdown
                          ? "arrow-icon-up"
                          : "arrow-icon"
                      }
                      src={diamondArrow}
                      alt="down-arrow"
                    />
                  </div>
                  {popupManufacturingPlantDropdown ? (
                    <>
                      {manufacturingData.map((obj) => {
                        return (
                          <>
                            <div
                              className={
                                checkedItems.manufacturingPlant !== null
                                  ? checkedItems.manufacturingPlant.includes(
                                      obj
                                    )
                                    ? "popup-checkbox active"
                                    : "popup-checkbox"
                                  : "popup-checkbox"
                              }
                            >
                              <label className="popup-label-checkbox">
                                <input
                                  type="checkbox"
                                  defaultChecked={
                                    checkedItems.manufacturingPlant !== null
                                      ? checkedItems.manufacturingPlant.includes(
                                          obj
                                        )
                                      : checkedItems.manufacturingPlant
                                  }
                                  name="manufacturingPlant"
                                  value={obj}
                                  onChange={handleCheckboxChange}
                                />
                                <span></span>
                                <div className="popup-checkbox-content">
                                  {obj}
                                </div>
                              </label>
                            </div>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <div className="empty-checkbox"></div>
                    </>
                  )}
                </div>
                <div className="popup-dropdown">
                  <div
                    className="popup-label"
                    onClick={() => {
                      setPopupDtcDropdown(!popupDtcDropdown);
                    }}
                  >
                    <div className="popup-label-content">DTC</div>
                    <img
                      className={
                        popupDtcDropdown ? "arrow-icon-up" : "arrow-icon"
                      }
                      src={diamondArrow}
                      alt="down-arrow"
                    />
                  </div>
                  {popupDtcDropdown ? (
                    <>
                      {dtcData.map((obj) => {
                        return (
                          <>
                            <div
                              className={
                                checkedItems.dtcs !== null
                                  ? checkedItems.dtcs.includes(obj)
                                    ? "popup-checkbox active"
                                    : "popup-checkbox"
                                  : "popup-checkbox"
                              }
                            >
                              <label className="popup-label-checkbox">
                                <input
                                  type="checkbox"
                                  name="dtcs"
                                  value={obj}
                                  defaultChecked={
                                    checkedItems.dtcs !== null
                                      ? checkedItems.dtcs.includes(obj)
                                      : checkedItems.dtcs
                                  }
                                  // onChange={handleCheckboxChange}
                                  onChange={handleChange}
                                />
                                <span></span>
                                <div className="popup-checkbox-content">
                                  {obj}
                                </div>
                              </label>
                            </div>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <div className="empty-checkbox"></div>
                    </>
                  )}
                </div>
                <div className="popup-dropdown">
                  <div
                    className="popup-label"
                    onClick={() => {
                      setPopupBuildDateDropdown(!popupBuildDateDropdown);
                    }}
                  >
                    <div className="popup-label-content">Build date</div>
                    <img
                      className={
                        popupBuildDateDropdown ? "arrow-icon-up" : "arrow-icon"
                      }
                      src={diamondArrow}
                      alt="down-arrow"
                    />
                  </div>
                  {popupBuildDateDropdown ? (
                    <>
                      <div className="popup-checkbox">
                        <div className="popup-date">
                          <Datepicker
                            onChange={handleFromBuildDateChange}
                            classContainer="saved-filters-date"
                            secondaryClassContainer="input-value-saved-filters-date"
                            dateValue={
                              checkedItems.fromBuildDate
                                ? new Date(checkedItems.fromBuildDate)
                                : " "
                            }
                          />
                          {/* {checkedItems.fromBuildDate} */}
                          to
                          <Datepicker
                            onChange={handleToBuildDateChange}
                            classContainer="saved-filters-date"
                            secondaryClassContainer="input-value-saved-filters-date"
                            dateValue={
                              checkedItems.toBuildDate
                                ? new Date(checkedItems.toBuildDate)
                                : " "
                            }
                          />
                          {/* {checkedItems.toBuildDate} */}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="empty-checkbox"></div>
                    </>
                  )}
                </div>
                <div className="popup-dropdown">
                  <div
                    className="popup-label"
                    onClick={() => {
                      setPopupVehicleCalibrationversionDropdown(
                        !popupVehicleCalibrationversionDropdown
                      );
                    }}
                  >
                    <div className="popup-label-content">
                      Vehicle Calibration version
                    </div>

                    <img
                      className={
                        popupVehicleCalibrationversionDropdown
                          ? "arrow-icon-up"
                          : "arrow-icon"
                      }
                      src={diamondArrow}
                      alt="down-arrow"
                    />
                  </div>
                  {popupVehicleCalibrationversionDropdown ? (
                    <>
                      {calibrationData.map((obj) => {
                        return (
                          <>
                            <div
                              className={
                                checkedItems.calibrationVersion !== null
                                  ? checkedItems.calibrationVersion.includes(
                                      obj
                                    )
                                    ? "popup-checkbox active"
                                    : "popup-checkbox"
                                  : "popup-checkbox"
                              }
                            >
                              <label className="popup-label-checkbox">
                                <input
                                  type="checkbox"
                                  defaultChecked={
                                    checkedItems.calibrationVersion !== null
                                      ? checkedItems.calibrationVersion.includes(
                                          obj
                                        )
                                      : checkedItems.calibrationVersion
                                  }
                                  name="calibrationVersion"
                                  value={obj}
                                  onChange={handleCheckboxChange}
                                />
                                <span></span>
                                <div className="popup-checkbox-content">
                                  {obj}
                                </div>
                              </label>
                            </div>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <div className="empty-checkbox"></div>
                    </>
                  )}
                </div>
                <div className="popup-dropdown">
                  <div
                    className="popup-label"
                    onClick={() => {
                      setPopupVehicleSoftwareversionDropdown(
                        !popupVehicleSoftwareversionDropdown
                      );
                    }}
                  >
                    <div className="popup-label-content">
                      Vehicle Software version
                    </div>
                    <img
                      className={
                        popupVehicleSoftwareversionDropdown
                          ? "arrow-icon-up"
                          : "arrow-icon"
                      }
                      src={diamondArrow}
                      alt="down-arrow"
                    />
                  </div>
                  {popupVehicleSoftwareversionDropdown ? (
                    <>
                      {softwareData.map((obj) => {
                        return (
                          <>
                            <div
                              className={
                                checkedItems.softwareVersion !== null
                                  ? checkedItems.softwareVersion.includes(obj)
                                    ? "popup-checkbox active"
                                    : "popup-checkbox"
                                  : "popup-checkbox"
                              }
                            >
                              <label className="popup-label-checkbox">
                                <input
                                  type="checkbox"
                                  defaultChecked={
                                    checkedItems.softwareVersion !== null
                                      ? checkedItems.softwareVersion.includes(
                                          obj
                                        )
                                      : checkedItems.softwareVersion
                                  }
                                  name="softwareVersion"
                                  value={obj}
                                  onChange={handleCheckboxChange}
                                />
                                <span></span>
                                <div className="popup-checkbox-content">
                                  {obj}
                                </div>
                              </label>
                            </div>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <div className="empty-checkbox"></div>
                    </>
                  )}
                </div>
                <div className="popup-dropdown">
                  <div
                    className="popup-label"
                    onClick={() => {
                      setPopupCustomercomplaintDropdown(
                        !popupCustomercomplaintDropdown
                      );
                    }}
                  >
                    <div className="popup-label-content">
                      Customer complaint
                    </div>
                    <img
                      className={
                        popupCustomercomplaintDropdown
                          ? "arrow-icon-up"
                          : "arrow-icon"
                      }
                      src={diamondArrow}
                      alt="down-arrow"
                    />
                  </div>
                  {popupCustomercomplaintDropdown ? (
                    <>
                      {customerData.map((obj) => {
                        return (
                          <>
                            <div
                              className={
                                checkedItems.customerComplaint !== null
                                  ? checkedItems.customerComplaint.includes(obj)
                                    ? "popup-checkbox active"
                                    : "popup-checkbox"
                                  : "popup-checkbox"
                              }
                            >
                              <label className="popup-label-checkbox">
                                <input
                                  type="checkbox"
                                  defaultChecked={
                                    checkedItems.customerComplaint !== null
                                      ? checkedItems.customerComplaint.includes(
                                          obj
                                        )
                                      : checkedItems.customerComplaint
                                  }
                                  name="customerComplaint"
                                  value={obj}
                                  onChange={handleCheckboxChange}
                                />
                                <span></span>
                                <div className="popup-checkbox-content">
                                  {obj}
                                </div>
                              </label>
                            </div>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <div className="empty-checkbox"></div>
                    </>
                  )}
                </div>
                <div className="popup-dropdown">
                  <div
                    className="popup-label"
                    onClick={() => {
                      setPopupSubSystemDropdown(!popupSubSystemDropdown);
                    }}
                  >
                    <div className="popup-label-content">Sub-system</div>
                    <img
                      className={
                        popupSubSystemDropdown ? "arrow-icon-up" : "arrow-icon"
                      }
                      src={diamondArrow}
                      alt="down-arrow"
                    />
                  </div>
                  {popupSubSystemDropdown ? (
                    <>
                      {subSystemData.map((obj) => {
                        return (
                          <>
                            <div
                              className={
                                checkedItems.subSystem !== null
                                  ? checkedItems.subSystem.includes(obj)
                                    ? "popup-checkbox active"
                                    : "popup-checkbox"
                                  : "popup-checkbox"
                              }
                            >
                              <label className="popup-label-checkbox">
                                <input
                                  type="checkbox"
                                  defaultChecked={
                                    checkedItems.subSystem !== null
                                      ? checkedItems.subSystem.includes(obj)
                                      : checkedItems.subSystem
                                  }
                                  name="subSystem"
                                  value={obj}
                                  onChange={handleCheckboxChange}
                                />
                                <span></span>
                                <div className="popup-checkbox-content">
                                  {obj}
                                </div>
                              </label>
                            </div>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <div className="empty-checkbox"></div>
                    </>
                  )}
                </div>
                <div className="popup-dropdown">
                  <div
                    className="popup-label"
                    onClick={() => {
                      setPopupFreezeFrameDataDropdown(
                        !popupFreezeFrameDataDropdown
                      );
                    }}
                  >
                    <div className="popup-label-content">Freeze Frame data</div>
                    <img
                      className={
                        popupFreezeFrameDataDropdown
                          ? "arrow-icon-up"
                          : "arrow-icon"
                      }
                      src={diamondArrow}
                      alt="down-arrow"
                    />
                  </div>
                  {popupFreezeFrameDataDropdown ? (
                    <>
                      {freezeFrameData.map((obj) => {
                        return (
                          <>
                            <div
                              className={
                                checkedItems.freezeFrameData !== null
                                  ? checkedItems.freezeFrameData.includes(obj)
                                    ? "popup-checkbox active"
                                    : "popup-checkbox"
                                  : "popup-checkbox"
                              }
                            >
                              <label className="popup-label-checkbox">
                                <input
                                  type="checkbox"
                                  defaultChecked={
                                    checkedItems.freezeFrameData !== null
                                      ? checkedItems.freezeFrameData.includes(
                                          obj
                                        )
                                      : checkedItems.freezeFrameData
                                  }
                                  name="freezeFrameData"
                                  value={obj}
                                  onChange={handleCheckboxChange}
                                />
                                <span></span>
                                <div className="popup-checkbox-content">
                                  {obj}
                                </div>
                              </label>
                            </div>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <div className="empty-checkbox"></div>
                    </>
                  )}
                </div>
                <div className="popup-dropdown">
                  <div
                    className="popup-label"
                    onClick={() => {
                      setPopupAltitudeDropdown(!popupAltitudeDropdown);
                    }}
                  >
                    <div className="popup-label-content">Altitude</div>
                    <img
                      className={
                        popupAltitudeDropdown ? "arrow-icon-up" : "arrow-icon"
                      }
                      src={diamondArrow}
                      alt="down-arrow"
                    />
                  </div>
                  {popupAltitudeDropdown ? (
                    <>
                      {altitudeData.map((obj) => {
                        return (
                          <>
                            <div
                              className={
                                checkedItems.altitudeInFeet !== null
                                  ? checkedItems.altitudeInFeet.includes(obj)
                                    ? "popup-checkbox active"
                                    : "popup-checkbox"
                                  : "popup-checkbox"
                              }
                            >
                              <label className="popup-label-checkbox">
                                <input
                                  type="checkbox"
                                  defaultChecked={
                                    checkedItems.altitudeInFeet !== null
                                      ? checkedItems.altitudeInFeet.includes(
                                          obj
                                        )
                                      : checkedItems.altitudeInFeet
                                  }
                                  name="altitudeInFeet"
                                  value={obj}
                                  onChange={handleCheckboxChange}
                                />
                                <span></span>
                                <div className="popup-checkbox-content">
                                  {obj}
                                </div>
                              </label>
                            </div>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <div className="empty-checkbox"></div>
                    </>
                  )}
                </div>
                <div className="popup-dropdown">
                  <div
                    className="popup-label"
                    onClick={() => {
                      setPopupAmbientTempDropdown(!popupAmbientTempDropdown);
                    }}
                  >
                    <div className="popup-label-content">
                      Ambient Temp(Deg.C)
                    </div>
                    <img
                      className={
                        popupAmbientTempDropdown
                          ? "arrow-icon-up"
                          : "arrow-icon"
                      }
                      src={diamondArrow}
                      alt="down-arrow"
                    />
                  </div>
                  {popupAmbientTempDropdown ? (
                    <>
                      {temperatureData.map((obj) => {
                        return (
                          <>
                            <div
                              className={
                                checkedItems.ambientTemperature !== null
                                  ? checkedItems.ambientTemperature.includes(
                                      obj
                                    )
                                    ? "popup-checkbox active"
                                    : "popup-checkbox"
                                  : "popup-checkbox"
                              }
                            >
                              <label className="popup-label-checkbox">
                                <input
                                  type="checkbox"
                                  defaultChecked={
                                    checkedItems.ambientTemperature !== null
                                      ? checkedItems.ambientTemperature.includes(
                                          obj
                                        )
                                      : checkedItems.ambientTemperature
                                  }
                                  name="ambientTemperature"
                                  value={obj}
                                  onChange={handleCheckboxChange}
                                />
                                <span></span>
                                <div className="popup-checkbox-content">
                                  {obj}
                                </div>
                              </label>
                            </div>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <div className="empty-checkbox"></div>
                    </>
                  )}
                </div>
                <div className="popup-dropdown">
                  <div
                    className="popup-label"
                    onClick={() => {
                      setPopupVehicleOperatingDropdown(
                        !popupVehicleOperatingDropdown
                      );
                    }}
                  >
                    <div className="popup-label-content">
                      Vehicle operating state
                    </div>
                    <img
                      className={
                        popupVehicleOperatingDropdown
                          ? "arrow-icon-up"
                          : "arrow-icon"
                      }
                      src={diamondArrow}
                      alt="down-arrow"
                    />
                  </div>
                  {popupVehicleOperatingDropdown ? (
                    <>
                      {operatingData.map((obj) => {
                        return (
                          <>
                            <div
                              className={
                                checkedItems.vehicleOperatingState !== null
                                  ? checkedItems.vehicleOperatingState.includes(
                                      obj
                                    )
                                    ? "popup-checkbox active"
                                    : "popup-checkbox"
                                  : "popup-checkbox"
                              }
                            >
                              <label className="popup-label-checkbox">
                                <input
                                  type="checkbox"
                                  defaultChecked={
                                    checkedItems.vehicleOperatingState !== null
                                      ? checkedItems.vehicleOperatingState.includes(
                                          obj
                                        )
                                      : checkedItems.vehicleOperatingState
                                  }
                                  name="vehicleOperatingState"
                                  value={obj}
                                  onChange={handleCheckboxChange}
                                />
                                <span></span>
                                <div className="popup-checkbox-content">
                                  {obj}
                                </div>
                              </label>
                            </div>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <div className="empty-checkbox"></div>
                    </>
                  )}
                </div>
                <div className="popup-dropdown">
                  <div
                    className="popup-label"
                    onClick={() => {
                      setPopupVehicleSystemDropdown(
                        !popupVehicleSystemDropdown
                      );
                    }}
                  >
                    <div className="popup-label-content">
                      Vehicle System state
                    </div>
                    <img
                      className={
                        popupVehicleSystemDropdown
                          ? "arrow-icon-up"
                          : "arrow-icon"
                      }
                      src={diamondArrow}
                      alt="down-arrow"
                    />
                  </div>
                  {popupVehicleSystemDropdown ? (
                    <>
                      {systemStateData.map((obj) => {
                        return (
                          <>
                            <div
                              className={
                                checkedItems.vehicleSystemState !== null
                                  ? checkedItems.vehicleSystemState.includes(
                                      obj
                                    )
                                    ? "popup-checkbox active"
                                    : "popup-checkbox"
                                  : "popup-checkbox"
                              }
                            >
                              <label className="popup-label-checkbox">
                                <input
                                  type="checkbox"
                                  defaultChecked={
                                    checkedItems.vehicleSystemState !== null
                                      ? checkedItems.vehicleSystemState.includes(
                                          obj
                                        )
                                      : checkedItems.vehicleSystemState
                                  }
                                  name="vehicleSystemState"
                                  value={obj}
                                  onChange={handleCheckboxChange}
                                />
                                <span></span>
                                <div className="popup-checkbox-content">
                                  {obj}
                                </div>
                              </label>
                            </div>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <div className="empty-checkbox"></div>
                    </>
                  )}
                </div>
                <div className="popup-dropdown">
                  <div
                    className="popup-label"
                    onClick={() => {
                      setPopupGeographyDropdown(!popupGeographyDropdown);
                    }}
                  >
                    <div className="popup-label-content">Geography</div>
                    <img
                      className={
                        popupGeographyDropdown ? "arrow-icon-up" : "arrow-icon"
                      }
                      src={diamondArrow}
                      alt="down-arrow"
                    />
                  </div>
                  {popupGeographyDropdown ? (
                    <>
                      {geographyData.map((obj) => {
                        return (
                          <>
                            <div
                              className={
                                checkedItems.geography !== null
                                  ? checkedItems.geography.includes(obj)
                                    ? "popup-checkbox active"
                                    : "popup-checkbox"
                                  : "popup-checkbox"
                              }
                            >
                              <label className="popup-label-checkbox">
                                <input
                                  type="checkbox"
                                  defaultChecked={
                                    checkedItems.geography !== null
                                      ? checkedItems.geography.includes(obj)
                                      : checkedItems.geography
                                  }
                                  name="geography"
                                  value={obj}
                                  onChange={handleCheckboxChange}
                                />
                                <span></span>
                                <div className="popup-checkbox-content">
                                  {obj}
                                </div>
                              </label>
                            </div>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <div className="empty-checkbox"></div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        {showDropDown === false && (
          <>
            {!viewAll ? (
              <>
                <label>
                  <div className="saved-filter-card-header">
                    <div className="saved-filter-checkbox main">
                      {!viewAll ? (
                        <>
                          <input
                            type="checkbox"
                            onChange={handleCheckedCard}
                            value={checkedItems.id}
                            checked={
                              checkedCards.id?.includes(checkedItems.id)
                                ? true
                                : false
                            }
                            // disabled={
                            //   checkedCards.id?.length >= 2
                            //     ? checkedCards.id?.includes(checkedItems.id)
                            //       ? false
                            //       : true
                            //     : false
                            // }
                            style={{
                              position: "relative",
                              marginTop: "41px",
                              "z-index": "1",
                              top: "23px",
                              width: "14px",
                              height: "14px",
                              display: "flex",
                              opacity: "0",
                            }}
                          />
                        </>
                      ) : (
                        <div className="popup-close">
                          <img
                            src={close}
                            alt="close"
                            onClick={() => handleEditClose()}
                            style={{
                              width: "20px",
                              height: "20px",
                              position: "relative",
                              top: "8px",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      )}
                      <span style={{ marginBottom: "56px" }}></span>
                    </div>

                    <div
                      className={
                        checkedItems.filterType === "Diagnostics"
                          ? "saved-filter-sign D"
                          : "saved-filter-sign P"
                      }
                      onClick={() => setShowSign(!showSign)}
                    >
                      {checkedItems.filterType === "Diagnostics" ? "D" : "P"}
                    </div>
                  </div>

                  <div className="saved-filter-description">
                    {checkedItems.make}
                    {checkedItems.model ? (
                      <img
                        src={doubleArrow}
                        alt="doubleArrow"
                        className="double-arrow"
                      />
                    ) : (
                      ""
                    )}
                    {checkedItems.model?.join(", ")}
                    {checkedItems.modelYear ? (
                      <img
                        src={doubleArrow}
                        alt="doubleArrow"
                        className="double-arrow"
                      />
                    ) : (
                      ""
                    )}
                    {checkedItems.modelYear?.join(", ")}
                    {checkedItems.manufacturingPlant ? (
                      <img
                        src={doubleArrow}
                        alt="doubleArrow"
                        className="double-arrow"
                      />
                    ) : (
                      ""
                    )}
                    {checkedItems.manufacturingPlant?.join(", ")}
                    {checkedItems.dtcs?.length ? (
                      <img
                        src={doubleArrow}
                        alt="doubleArrow"
                        className="double-arrow"
                      />
                    ) : (
                      ""
                    )}
                    {checkedItems.dtcs?.join(", ")}
                  </div>
                  <hr />
                </label>
              </>
            ) : (
              <>
                <div className="saved-filter-card-header">
                  <div className="saved-filter-checkbox main">
                    {!viewAll ? (
                      <>
                        <input
                          type="checkbox"
                          value={checkedItems.id}
                          checked={
                            checkedCards.id?.includes(checkedItems.id)
                              ? true
                              : false
                          }
                          disabled={
                            checkedCards.id?.length >= 2
                              ? checkedCards.id?.includes(checkedItems.id)
                                ? false
                                : true
                              : false
                          }
                          style={{
                            position: "relative",
                            marginTop: "41px",
                            "z-index": "1",
                            top: "23px",
                            width: "14px",
                            height: "14px",
                            display: "flex",
                            opacity: "0",
                          }}
                        />
                      </>
                    ) : (
                      <div className="popup-close">
                        <img
                          src={close}
                          alt="close"
                          onClick={() => handleEditClose()}
                          style={{
                            width: "20px",
                            height: "20px",
                            position: "relative",
                            top: "8px",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    )}
                    <span style={{ marginBottom: "56px" }}></span>
                  </div>

                  <div
                    className={
                      checkedItems.filterType === "Diagnostics"
                        ? "saved-filter-sign D"
                        : "saved-filter-sign P"
                    }
                    onClick={() => setShowSign(!showSign)}
                  >
                    {checkedItems.filterType === "Diagnostics" ? "D" : "P"}
                  </div>
                </div>

                <div className="saved-filter-description">
                  {checkedItems.make}
                  {checkedItems.modelYear ? (
                    <img
                      src={doubleArrow}
                      alt="doubleArrow"
                      className="double-arrow"
                    />
                  ) : (
                    ""
                  )}
                  {checkedItems.modelYear?.join(", ")}
                  {checkedItems.manufacturingPlant ? (
                    <img
                      src={doubleArrow}
                      alt="doubleArrow"
                      className="double-arrow"
                    />
                  ) : (
                    ""
                  )}
                  {checkedItems.manufacturingPlant?.join(", ")}
                  {checkedItems.dtcs?.length ? (
                    <img
                      src={doubleArrow}
                      alt="doubleArrow"
                      className="double-arrow"
                    />
                  ) : (
                    ""
                  )}
                  {checkedItems.dtcs?.join(", ")}
                </div>
                <hr />
              </>
            )}

            {/* view all */}

            <div
              className={
                viewAll
                  ? `viewall-content`
                  : `viewall-content viewall-content--minh`
              }
            >
              <div className="viewall-dropdown">
                <div
                  className="viewall-label"
                  onClick={() => {
                    setModelDropdown(!modelDropdown);
                  }}
                >
                  <div className="viewall-label-content">Model</div>
                  <img
                    className={modelDropdown ? "arrow-icon-up" : "arrow-icon"}
                    src={diamondArrow}
                    alt="down-arrow"
                  />
                </div>

                {modelDropdown ? (
                  <>
                    {checkedItems.model !== null ? (
                      checkedItems.model?.map((item) => {
                        return (
                          <>
                            <div className="viewall-checkbox">
                              <div className="viewall-checkbox-label">
                                {checkedItems.model ? item : ""}
                              </div>
                              {checkedItems.model ? (
                                <>
                                  <input
                                    type="checkbox"
                                    checked={checkedItems.model ? true : false}
                                    readOnly
                                  />
                                  <span></span>
                                </>
                              ) : (
                                <div className="empty-checkbox"></div>
                              )}
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="viewall-dropdown">
                <div
                  className="viewall-label"
                  onClick={() => {
                    setViewAllMakeDropdown(!viewAllMakeDropdown);
                  }}
                >
                  <div className="viewall-label-content">Make</div>
                  <img
                    className={
                      viewAllMakeDropdown ? "arrow-icon-up" : "arrow-icon"
                    }
                    src={diamondArrow}
                    alt="down-arrow"
                  />
                </div>

                {viewAllMakeDropdown ? (
                  <>
                    <div className="viewall-checkbox">
                      <div className="viewall-checkbox-label">
                        {checkedItems.make}
                      </div>
                      {checkedItems.make ? (
                        <>
                          <input type="checkbox" checked={true} readOnly />
                          <span></span>
                        </>
                      ) : (
                        <div className="empty-checkbox"></div>
                      )}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="viewall-dropdown">
                <div
                  className="viewall-label"
                  onClick={() => {
                    setViewAllYearDropdown(!viewAllYearDropdown);
                  }}
                >
                  <div className="viewall-label-content">Year</div>
                  <img
                    className={
                      viewAllYearDropdown ? "arrow-icon-up" : "arrow-icon"
                    }
                    src={diamondArrow}
                    alt="down-arrow"
                  />
                </div>
                {viewAllYearDropdown ? (
                  <>
                    {checkedItems.modelYear !== null ? (
                      checkedItems.modelYear?.map((item) => {
                        return (
                          <>
                            <div className="viewall-checkbox">
                              <div className="viewall-checkbox-label">
                                {checkedItems.modelYear ? item : ""}
                              </div>
                              {checkedItems.modelYear ? (
                                <>
                                  <input
                                    type="checkbox"
                                    checked={
                                      checkedItems.modelYear ? true : false
                                    }
                                    readOnly
                                  />
                                  <span></span>
                                </>
                              ) : (
                                <div className="empty-checkbox"></div>
                              )}
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="viewall-dropdown">
                <div
                  className="viewall-label"
                  onClick={() => {
                    setViewAllManufacturingPlantDropdown(
                      !viewAllManufacturingPlantDropdown
                    );
                  }}
                >
                  <div className="viewall-label-content">
                    Manufacturing Plant
                  </div>
                  <img
                    className={
                      viewAllManufacturingPlantDropdown
                        ? "arrow-icon-up"
                        : "arrow-icon"
                    }
                    src={diamondArrow}
                    alt="down-arrow"
                  />
                </div>
                {viewAllManufacturingPlantDropdown ? (
                  <>
                    {checkedItems.manufacturingPlant !== null ? (
                      checkedItems.manufacturingPlant?.map((item) => {
                        return (
                          <>
                            <div className="viewall-checkbox">
                              <div className="viewall-checkbox-label">
                                {checkedItems.manufacturingPlant ? item : ""}
                              </div>
                              {checkedItems.manufacturingPlant ? (
                                <>
                                  <input
                                    type="checkbox"
                                    checked={
                                      checkedItems.manufacturingPlant
                                        ? true
                                        : false
                                    }
                                    readOnly
                                  />
                                  <span></span>
                                </>
                              ) : (
                                <div className="empty-checkbox"></div>
                              )}
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="viewall-dropdown">
                <div
                  className="viewall-label"
                  onClick={() => {
                    setViewAllDtcDropdown(!viewAllDtcDropdown);
                  }}
                >
                  <div className="viewall-label-content">DTC</div>
                  <img
                    className={
                      viewAllDtcDropdown ? "arrow-icon-up" : "arrow-icon"
                    }
                    src={diamondArrow}
                    alt="down-arrow"
                  />
                </div>
                {viewAllDtcDropdown ? (
                  <>
                    {checkedItems.dtcs !== null ? (
                      checkedItems.dtcs?.map((item) => {
                        return (
                          <>
                            <div className="viewall-checkbox">
                              <div className="viewall-checkbox-label">
                                {checkedItems.dtcs ? item : ""}
                              </div>
                              {checkedItems.dtcs ? (
                                <>
                                  <input
                                    type="checkbox"
                                    checked={checkedItems.dtcs ? true : false}
                                    readOnly
                                  />
                                  <span></span>
                                </>
                              ) : (
                                <div className="empty-checkbox"></div>
                              )}
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="viewall-dropdown">
                <div
                  className="viewall-label"
                  onClick={() => {
                    setViewAllBuildDateDropdown(!viewAllBuildDateDropdown);
                  }}
                >
                  <div className="viewall-label-content">Build Date</div>
                  <img
                    className={
                      viewAllBuildDateDropdown ? "arrow-icon-up" : "arrow-icon"
                    }
                    src={diamondArrow}
                    alt="down-arrow"
                  />
                </div>
                {viewAllBuildDateDropdown ? (
                  <>
                    {checkedItems.fromBuildDate ? (
                      <div className="viewall-checkbox">
                        {checkedItems.fromBuildDate} to{" "}
                        {checkedItems.toBuildDate}
                      </div>
                    ) : (
                      <div className="empty-checkbox"></div>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="viewall-dropdown">
                <div
                  className="viewall-label"
                  onClick={() => {
                    setViewAllVehicleCalibrationversionDropdown(
                      !viewAllVehicleCalibrationversionDropdown
                    );
                  }}
                >
                  <div className="viewall-label-content">
                    Vehicle Calibration version
                  </div>
                  <img
                    className={
                      viewAllVehicleCalibrationversionDropdown
                        ? "arrow-icon-up"
                        : "arrow-icon"
                    }
                    src={diamondArrow}
                    alt="down-arrow"
                  />
                </div>
                {viewAllVehicleCalibrationversionDropdown ? (
                  <>
                    {checkedItems.calibrationVersion !== null ? (
                      checkedItems.calibrationVersion?.map((item) => {
                        return (
                          <>
                            <div className="viewall-checkbox">
                              <div className="viewall-checkbox-label">
                                {checkedItems.calibrationVersion ? item : ""}
                              </div>
                              {checkedItems.calibrationVersion ? (
                                <>
                                  <input
                                    type="checkbox"
                                    checked={
                                      checkedItems.calibrationVersion
                                        ? true
                                        : false
                                    }
                                    readOnly
                                  />
                                  <span></span>
                                </>
                              ) : (
                                <div className="empty-checkbox"></div>
                              )}
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="viewall-dropdown">
                <div
                  className="viewall-label"
                  onClick={() => {
                    setViewAllVehicleSoftwareversionDropdown(
                      !viewAllVehicleSoftwareversionDropdown
                    );
                  }}
                >
                  <div className="viewall-label-content">
                    Vehicle Software version
                  </div>
                  <img
                    className={
                      viewAllVehicleSoftwareversionDropdown
                        ? "arrow-icon-up"
                        : "arrow-icon"
                    }
                    src={diamondArrow}
                    alt="down-arrow"
                  />
                </div>
                {viewAllVehicleSoftwareversionDropdown ? (
                  <>
                    {checkedItems.softwareVersion !== null ? (
                      checkedItems.softwareVersion?.map((item) => {
                        return (
                          <>
                            <div className="viewall-checkbox">
                              <div className="viewall-checkbox-label">
                                {checkedItems.softwareVersion ? item : ""}
                              </div>
                              {checkedItems.softwareVersion ? (
                                <>
                                  <input
                                    type="checkbox"
                                    checked={
                                      checkedItems.softwareVersion
                                        ? true
                                        : false
                                    }
                                    readOnly
                                  />
                                  <span></span>
                                </>
                              ) : (
                                <div className="empty-checkbox"></div>
                              )}
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="viewall-dropdown">
                <div
                  className="viewall-label"
                  onClick={() => {
                    setViewAllCustomercomplaintDropdown(
                      !viewAllCustomercomplaintDropdown
                    );
                  }}
                >
                  <div className="viewall-label-content">
                    Customer complaint
                  </div>
                  <img
                    className={
                      viewAllCustomercomplaintDropdown
                        ? "arrow-icon-up"
                        : "arrow-icon"
                    }
                    src={diamondArrow}
                    alt="down-arrow"
                  />
                </div>
                {viewAllCustomercomplaintDropdown ? (
                  <>
                    {checkedItems.customerComplaint !== null ? (
                      checkedItems.customerComplaint?.map((item) => {
                        return (
                          <>
                            <div className="viewall-checkbox">
                              <div className="viewall-checkbox-label">
                                {checkedItems.customerComplaint ? item : ""}
                              </div>
                              {checkedItems.customerComplaint ? (
                                <>
                                  <input
                                    type="checkbox"
                                    checked={
                                      checkedItems.customerComplaint
                                        ? true
                                        : false
                                    }
                                    readOnly
                                  />
                                  <span></span>
                                </>
                              ) : (
                                <div className="empty-checkbox"></div>
                              )}
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="viewall-dropdown">
                <div
                  className="viewall-label"
                  onClick={() => {
                    setViewAllSubSystemDropdown(!viewAllSubSystemDropdown);
                  }}
                >
                  <div className="viewall-label-content">Sub-system</div>
                  <img
                    className={
                      viewAllSubSystemDropdown ? "arrow-icon-up" : "arrow-icon"
                    }
                    src={diamondArrow}
                    alt="down-arrow"
                  />
                </div>
                {viewAllSubSystemDropdown ? (
                  <>
                    {checkedItems.subSystem !== null ? (
                      checkedItems.subSystem?.map((item) => {
                        return (
                          <>
                            <div className="viewall-checkbox">
                              <div className="viewall-checkbox-label">
                                {checkedItems.subSystem ? item : ""}
                              </div>
                              {checkedItems.subSystem ? (
                                <>
                                  <input
                                    type="checkbox"
                                    checked={
                                      checkedItems.subSystem ? true : false
                                    }
                                    readOnly
                                  />
                                  <span></span>
                                </>
                              ) : (
                                <div className="empty-checkbox"></div>
                              )}
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="viewall-dropdown">
                <div
                  className="viewall-label"
                  onClick={() => {
                    setViewAllFreezeFrameDataDropdown(
                      !viewAllFreezeFrameDataDropdown
                    );
                  }}
                >
                  <div className="viewall-label-content">Freeze Frame data</div>
                  <img
                    className={
                      viewAllFreezeFrameDataDropdown
                        ? "arrow-icon-up"
                        : "arrow-icon"
                    }
                    src={diamondArrow}
                    alt="down-arrow"
                  />
                </div>
                {viewAllFreezeFrameDataDropdown ? (
                  <>
                    {checkedItems.freezeFrameData !== null ? (
                      checkedItems.freezeFrameData?.map((item) => {
                        return (
                          <>
                            <div className="viewall-checkbox">
                              <div className="viewall-checkbox-label">
                                {checkedItems.freezeFrameData ? item : ""}
                              </div>
                              {checkedItems.freezeFrameData ? (
                                <>
                                  <input
                                    type="checkbox"
                                    checked={
                                      checkedItems.freezeFrameData
                                        ? true
                                        : false
                                    }
                                    readOnly
                                  />
                                  <span></span>
                                </>
                              ) : (
                                <div className="empty-checkbox"></div>
                              )}
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="viewall-dropdown">
                <div
                  className="viewall-label"
                  onClick={() => {
                    setViewAllAltitudeDropdown(!viewAllAltitudeDropdown);
                  }}
                >
                  <div className="viewall-label-content">Altitude</div>
                  <img
                    className={
                      viewAllAltitudeDropdown ? "arrow-icon-up" : "arrow-icon"
                    }
                    src={diamondArrow}
                    alt="down-arrow"
                  />
                </div>
                {viewAllAltitudeDropdown ? (
                  <>
                    {checkedItems.altitudeInFeet !== null ? (
                      checkedItems.altitudeInFeet?.map((item) => {
                        return (
                          <>
                            <div className="viewall-checkbox">
                              <div className="viewall-checkbox-label">
                                {checkedItems.altitudeInFeet ? item : ""}
                              </div>
                              {checkedItems.altitudeInFeet ? (
                                <>
                                  <input
                                    type="checkbox"
                                    checked={
                                      checkedItems.altitudeInFeet ? true : false
                                    }
                                    readOnly
                                  />
                                  <span></span>
                                </>
                              ) : (
                                <div className="empty-checkbox"></div>
                              )}
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="viewall-dropdown">
                <div
                  className="viewall-label"
                  onClick={() => {
                    setViewAllAmbientTempDropdown(!viewAllAmbientTempDropdown);
                  }}
                >
                  <div className="viewall-label-content">
                    Ambient Temp(Deg.C)
                  </div>
                  <img
                    className={
                      viewAllAmbientTempDropdown
                        ? "arrow-icon-up"
                        : "arrow-icon"
                    }
                    src={diamondArrow}
                    alt="down-arrow"
                  />
                </div>
                {viewAllAmbientTempDropdown ? (
                  <>
                    {checkedItems.ambientTemperature !== null ? (
                      checkedItems.ambientTemperature?.map((item) => {
                        return (
                          <>
                            <div className="viewall-checkbox">
                              <div className="viewall-checkbox-label">
                                {checkedItems.ambientTemperature ? item : ""}
                              </div>
                              {checkedItems.ambientTemperature ? (
                                <>
                                  <input
                                    type="checkbox"
                                    checked={
                                      checkedItems.ambientTemperature
                                        ? true
                                        : false
                                    }
                                    readOnly
                                  />
                                  <span></span>
                                </>
                              ) : (
                                <div className="empty-checkbox"></div>
                              )}
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="viewall-dropdown">
                <div
                  className="viewall-label"
                  onClick={() => {
                    setViewAllVehicleOperatingDropdown(
                      !viewAllVehicleOperatingDropdown
                    );
                  }}
                >
                  <div className="viewall-label-content">
                    Vehicle operating state
                  </div>
                  <img
                    className={
                      viewAllVehicleOperatingDropdown
                        ? "arrow-icon-up"
                        : "arrow-icon"
                    }
                    src={diamondArrow}
                    alt="down-arrow"
                  />
                </div>
                {viewAllVehicleOperatingDropdown ? (
                  <>
                    {checkedItems.vehicleOperatingState !== null ? (
                      checkedItems.vehicleOperatingState?.map((item) => {
                        return (
                          <>
                            <div className="viewall-checkbox">
                              <div className="viewall-checkbox-label">
                                {checkedItems.vehicleOperatingState ? item : ""}
                              </div>
                              {checkedItems.vehicleOperatingState ? (
                                <>
                                  <input
                                    type="checkbox"
                                    checked={
                                      checkedItems.vehicleOperatingState
                                        ? true
                                        : false
                                    }
                                    readOnly
                                  />
                                  <span></span>
                                </>
                              ) : (
                                <div className="empty-checkbox"></div>
                              )}
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="viewall-dropdown">
                <div
                  className="viewall-label"
                  onClick={() => {
                    setViewAllVehicleSystemDropdown(
                      !viewAllVehicleSystemDropdown
                    );
                  }}
                >
                  <div className="viewall-label-content">
                    Vehicle System state
                  </div>
                  <img
                    className={
                      viewAllVehicleSystemDropdown
                        ? "arrow-icon-up"
                        : "arrow-icon"
                    }
                    src={diamondArrow}
                    alt="down-arrow"
                  />
                </div>
                {viewAllVehicleSystemDropdown ? (
                  <>
                    {checkedItems.vehicleSystemState !== null ? (
                      checkedItems.vehicleSystemState?.map((item) => {
                        return (
                          <>
                            <div className="viewall-checkbox">
                              <div className="viewall-checkbox-label">
                                {checkedItems.vehicleSystemState ? item : ""}
                              </div>
                              {checkedItems.vehicleSystemState ? (
                                <>
                                  <input
                                    type="checkbox"
                                    checked={
                                      checkedItems.vehicleSystemState
                                        ? true
                                        : false
                                    }
                                    readOnly
                                  />
                                  <span></span>
                                </>
                              ) : (
                                <div className="empty-checkbox"></div>
                              )}
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="viewall-dropdown">
                <div
                  className="viewall-label"
                  onClick={() => {
                    setViewAllGeographyDropdown(!viewAllGeographyDropdown);
                  }}
                >
                  <div className="viewall-label-content"> Geography </div>
                  <img
                    className={
                      viewAllGeographyDropdown ? "arrow-icon-up" : "arrow-icon"
                    }
                    src={diamondArrow}
                    alt="down-arrow"
                  />
                </div>
                {viewAllGeographyDropdown ? (
                  <>
                    {checkedItems.geography !== null ? (
                      checkedItems.geography?.map((item) => {
                        return (
                          <>
                            <div className="viewall-checkbox">
                              <div className="viewall-checkbox-label">
                                {checkedItems.geography ? item : ""}
                              </div>
                              {checkedItems.geography ? (
                                <>
                                  <input
                                    type="checkbox"
                                    checked={
                                      checkedItems.geography ? true : false
                                    }
                                    readOnly
                                  />
                                  <span></span>
                                </>
                              ) : (
                                <div className="empty-checkbox"></div>
                              )}
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className={viewAll ? "edit-button" : ""}>
              <button
                className="btn btn-primary-blue saved-filter"
                onClick={() => handleEdit()}
              >
                Edit
                <img className="edit-icon" src={editLogo} alt="down-arrow" />
              </button>
            </div>
            <div
              className="saved-filter-dropdown-viewall"
              onClick={() => setViewAll(!viewAll)}
            >
              {viewAll ? "CLOSE" : "VIEW ALL"}
              <img
                className={viewAll ? "arrow-icon-up" : "arrow-icon"}
                src={downArrow}
                alt="down-arrow"
              />
            </div>
          </>
        )}
      </div>
      <div className="saved-filter-test-name">{checkedItems.filterName}</div>
    </div>
  );
};

export default FilterCard;
