import React, { useEffect } from "react";
import { NavLink, Outlet, useNavigate, useLocation } from "react-router-dom";
import TabLayout from "../../components/Layout/TabLayout";

const VehicleOverview = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/vehicle-overview") {
      navigate("/vehicle-overview/information", { replace: true });
    }
  }, [location.pathname]);

  const navLink = [
    { label: "Vehicle Information", to: "/vehicle-overview/information" },
    { label: "Vehicle Notification", to: "/vehicle-overview/notification" },
    { label: "Vehicle Real Time Data", to: "/vehicle-overview/realtime-data" },
  ];
  return (
    <div className="full-width ">
      <TabLayout tabLink={navLink} />
    </div>
  );
};

export default VehicleOverview;
