import React, { useEffect } from "react";
import "./VibrationalAnalytics.scss";
import { buttonStrings } from "./utils/utils";
import ButtonCard from "./components/buttonCard";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { replace } from "lodash";

const VibrationalAnalytics = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname ===
      "/vehicle-health/diagnostics/vibrational-analytics/engine"
    ) {
      navigate("/vehicle-health/diagnostics/vibrational-analytics", {
        replace: true,
      });
    }
  });

  return (
    <div className="page-wrapper vibrational-analytics">
      {/* <h3 className="heading">
            Select Component​ to generate Performance profile
          </h3> */}
      <div className="button-container">
        <div className="left-container">
          {/* TO DO : Need to remove disabled prop once we get the data */}
          {buttonStrings.map((button, index) => (
            <ButtonCard
              buttonText={button.text}
              id={button.id}
              disabled={index !== 0}
              buttonContent={button}
              showTable={() =>
                navigate(
                  "/vehicle-health/diagnostics/vibrational-analytics/engine/combustion"
                )
              }
            />
          ))}
        </div>
        <div className="right-container"></div>
      </div>
    </div>
  );
};

export default VibrationalAnalytics;
