import { useEffect } from "react";
import Highcharts from "highcharts";
import "./DualColumnLinegraph.scss";
import close from "../../../assets/close.svg";

const DualColumnLineGraph = (props) => {
  const { data, id, fromVpUp = false, clickOnClose, title } = props;

  useEffect(() => {
    renderChart(data);
  }, [data]);

  const renderChart = (chartdata) => {
    Highcharts.chart(`Container-${id}`, {
      chart: {
        backgroundColor: "#FFFFFF",
        type: "column",
        spacing: [60, 40, 20, 40],
        borderRadius: 20,
      },
      title: {
        text: null,
      },
      yAxis: {
        title: "",

        gridLineColor: "#CECECE",

        labels: {
          style: {
            color: "#353536",
            lineHeight: 1,
            font: "12px Open Sans",
            letterSpacing: "1px",
            fontWeight: 600,
          },
        },
      },

      xAxis: {
        categories: chartdata?.[0]?.categories,

        title: {
          text: title,
          style: {
            color: "#000000",
            lineHeight: 1,
            textAlign: "right",
            font: "12px Open Sans",
            fontWeight: 600,
          },
          useHTML: true,
          margin: 20,
        },
        style: {
          color: "#353536",
          lineHeight: 1,
          font: "11px Open Sans",
          letterSpacing: "1px",
          fontWeight: 600,
        },
        startOnTick: true,
        min: 0,
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },

      plotOptions: {
        series: {
          pointWidth: 10,
          borderRadius: 3,
        },
      },

      tooltip: {
        backgroundColor: "#000",
        style: {
          color: "#FFFFFF",
          font: "12px Open Sans",
        },

        formatter: function () {
          if (fromVpUp) {
            if (Object.keys(this.point.options).length > 1) {
              return (
                "<b>" +
                this.x +
                "</b><br/>" +
                this.series.name +
                " : " +
                this.y +
                " (" +
                this.point.options.minValue +
                "," +
                this.point.options.maxValue +
                ")" +
                "</b><br/>" +
                "Iqr : " +
                this.point?.options.iqr +
                "</b><br/>" +
                "Standard Deviation : " +
                this.point?.options.standardDeviation +
                "</b><br/>" +
                "Range : " +
                this.point?.options.range +
                "</b><br/>" +
                "Variance : " +
                this.point?.options.variance
              );
            } else {
              return (
                "<b>" +
                this.x +
                "</b><br/>" +
                this.series?.name +
                " : " +
                this.y
              );
            }
          }
        },
      },

      series: chartdata[0].data,
    });
  };

  return (
    <>
      <div className="figure-large-container">
        <div className="modal-close" onClick={clickOnClose}>
          <img src={close} alt="close" />
        </div>
        <figure className="highcharts-figure-large">
          <div id={`Container-${id}`}></div>
        </figure>
      </div>
    </>
  );
};

export default DualColumnLineGraph;
