import React, { useEffect, useState } from "react";
import Feedback from "../../../../../components/common/ErrorComponents/Feedback";
import { BarGraph } from "../../../../../components/common/blackbarGraph/Graph";
import { mainAxiosInstance } from "../../../../../services/service";
import { WarrantymanagementDashBoard } from "../../../../../services/service-constants";
import { VehiclePopulationDashBoard } from "../../../../../services/service-constants";
import { useSelector } from "react-redux";
import SingleChartLoader from "../../../../../components/common/ErrorComponents/SingleChartLoader";
import toggleLeftIcon from "../../../../../assets/compareCardLeftIcon.svg";
import toggleRightIcon from "../../../../../assets/compareCardRightIcon.svg";
import { Button } from "../../../../../components/common/Button/Button";

const VPWMPrognosticScoreCard = () => {
  const vinNumber = useSelector((state) => state.mainReducer.vin_Number);
  const [chartData, setChartData] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [noDataFound, setNoDataFound] = useState(false);
  const [metrics, setMetrics] = useState({});
  const [totalMetricValue, settotalMetricValue] = useState(0);
  const [paramData, setParamData] = useState(null);
  const [toggleButton, settoggleButton] = useState(false);
  const [filterData, setFilterData] = useState(null);
  const [toggleNumber, settoggleNumber] = useState(0);
  const prepareChartData = (dataSet) => {
    getOutputData(dataSet);
  };

  const getOutputData = (data) => {
    const milesData = [];
    const detailsData = [];
    const cardMetrics = {};

    let xAxisLabel = data.informationDto.xAxisName;
    let yAxisLabel = data.informationDto.yAxisName;
    let graphName = data.informationDto.graphName;
    data.dtcWarrantyScoreCardDto.content.forEach((val) => {
      detailsData.push({
        y: val.warrantyScoreCard,
        name: val.dtcCode,
        values: val.dtcCode,
      });
    });
    milesData.push({
      data: detailsData,
      xLabel: xAxisLabel,
      yLabel: yAxisLabel,
      id: graphName,
    });

    data?.warrantyScoreCardMetricsDto?.map((metric) => {
      cardMetrics[metric.metric] = metric.metricValue;
    });
    setMetrics(cardMetrics);
    settotalMetricValue(data?.totalMetricValue);
    setChartData(milesData);
  };

  useEffect(() => {
    let url = `${VehiclePopulationDashBoard.defaultApi}`;
    mainAxiosInstance
      .get(url)
      .then((response) => {
        setParamData(response.data);
      })
      .catch((er) => {
        throw er;
      });
  }, []);

  const getPageData = (val) => {
    setNoDataFound(false);
    const selectedData = JSON.parse(localStorage.getItem("selectedData"));
    const selectedDiagonsticsData = JSON.parse(
      localStorage.getItem("selectedDiagonosticsData")
    );
    const FilterSelectedValues = {
      ...selectedData,
      ...selectedDiagonsticsData,
    };
    var url = ``;
    if (Object.keys(FilterSelectedValues).length > 0) {
      if (toggleButton) {
        url = `${WarrantymanagementDashBoard.warranty_management}/dtc_warranty_score_card_population/dtc/1/?toggle=1`;
      } else {
        url = `${WarrantymanagementDashBoard.warranty_management}/dtc_warranty_score_card_population/dtc/1/?toggle=0`;
      }
    } else {
      url = `${WarrantymanagementDashBoard.warranty_management}/dtc_warranty_score_card_population/dtc/1`;
    }

    let filteredValues = {};
    if (Object.keys(FilterSelectedValues).length === 0) {
      filteredValues = paramData;
    } else {
      if (toggleButton) {
        filteredValues = FilterSelectedValues;
      } else {
        const { geography, make, model, modelYear } = FilterSelectedValues;
        const filterData = {
          geography: geography == undefined ? null : geography,
          make: make == undefined ? null : make,
          model: model == undefined ? null : model,
          modelYear: modelYear == undefined ? null : modelYear,
        };

        filteredValues = FilterSelectedValues;
      }
    }

    let data = Object.assign(filteredValues);

    if (Object.keys(FilterSelectedValues).length === 0 && !toggleButton) {
      mainAxiosInstance
        .post(url)
        .then((response) => {
          if (response.status === 200) {
            setNoDataFound(false);
            prepareChartData(response.data);
          }
        })
        .catch((err) => {
          setNoDataFound(true);
        });
    } else {
      mainAxiosInstance
        .post(url, data)
        .then((response) => {
          if (response.status === 200) {
            setNoDataFound(false);
            prepareChartData(response.data);
          }
        })
        .catch((err) => {
          setNoDataFound(true);
        });
    }
  };
  useEffect(() => {
    if (toggleButton) {
      setFilterData("All Filter");
    } else {
      setFilterData("Make_Model_ModelYear_State");
    }
  }, [toggleButton]);

  useEffect(() => {
    if (paramData !== null) getPageData();
  }, [paramData, toggleButton]);
  if (chartData === null && noDataFound === false) {
    return (
      <div className="wm-dashboard-middle-container">
        <SingleChartLoader />;
      </div>
    );
  }
  return (
    <>
      <div className="wm-dashboard-middle-container">
        <div
          style={{
            display: "flex",
            marginTop: "10px",
            justifyContent: "flex-end",
          }}
        >
          <div
            className="inp"
            style={{ marginRight: "12em", width: "0px" }}
            title={filterData}
          >
            <div className="display-selected-data">{filterData}</div>
          </div>
          <div
            style={{
              fontSize: "13px",
              display: "flex",
              justifyContent: "right",
              paddingRight: "45px",
              cursor: "pointer",
            }}
          >
            {toggleButton ? (
              <>
                <img
                  src={toggleRightIcon}
                  alt=""
                  onClick={() => settoggleButton(!toggleButton)}
                />
              </>
            ) : (
              <>
                <img
                  src={toggleLeftIcon}
                  alt=""
                  onClick={() => settoggleButton(!toggleButton)}
                />
              </>
            )}
          </div>
        </div>
        {noDataFound ? (
          <div>
            <Feedback feedbackClassName="feedback" />
          </div>
        ) : (
          <>
            <BarGraph chartdata={chartData} />
            <div className="graph-name">DTC Warranty Scorecard</div>
          </>
        )}
        {noDataFound === false && (
          <Button
            title={showDetails ? "Hide Details" : "Show Details"}
            handleClick={() => setShowDetails(!showDetails)}
            styleClasses="showdetails showdetailsIndividual"
          />
        )}

        {noDataFound === false && showDetails ? (
          <div className="mainTable-container">
            <div className="firstTable">
              <table style={{ minHeight: "320px" }}>
                <thead>
                  <tr>
                    <th colspan="2">DTC Warranty Scorecard Factors</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Failure Reports:</td>
                    <td>{metrics["Failure Reports"]}</td>
                  </tr>
                  <tr>
                    <td>Failure Rate:</td>
                    <td>{metrics["Failure Rates"]}</td>
                  </tr>
                  <tr>
                    <td>Repeat Failures</td>
                    <td>{metrics["Repeat Failures"]}</td>
                  </tr>
                  <tr>
                    <td>Cost</td>
                    <td>{metrics["Cost"]}</td>
                  </tr>
                  <tr>
                    <td>Severity</td>
                    <td>{metrics["severity"]}</td>
                  </tr>

                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>

              <div className="bmt-text">
                <p>Total</p>
                <p>{totalMetricValue + "%"}</p>
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex" }}>
                <div className="secondTable" style={{ marginLeft: "5px" }}>
                  <table>
                    <thead>
                      <tr>
                        <th colspan="2">Failure reports</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>0</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td>1-10</td>
                        <td>2</td>
                      </tr>
                      <tr>
                        <td>11-15</td>
                        <td>4</td>
                      </tr>
                      <tr>
                        <td>16-20</td>
                        <td>6</td>
                      </tr>
                      <tr>
                        <td>21-30</td>
                        <td>8</td>
                      </tr>
                      <tr>
                        <td>&gt;31</td>
                        <td>10</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="thirdTable" style={{ marginLeft: "5px" }}>
                  <table>
                    <thead>
                      <tr>
                        <th colspan="2">Cost</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>0</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td>1-10</td>
                        <td>2</td>
                      </tr>
                      <tr>
                        <td>11-15</td>
                        <td>4</td>
                      </tr>
                      <tr>
                        <td>16-20</td>
                        <td>6</td>
                      </tr>
                      <tr>
                        <td>21-30</td>
                        <td>8</td>
                      </tr>
                      <tr>
                        <td>&gt;31</td>
                        <td>10</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="fourthTable" style={{ marginLeft: "5px" }}>
                  <table>
                    <thead>
                      <tr>
                        <th colspan="2">Severity</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Poor Performance</td>
                        <td>2</td>
                      </tr>
                      <tr>
                        <td>Intermittent</td>
                        <td>4</td>
                      </tr>
                      <tr>
                        <td>Limp home mode</td>
                        <td>6</td>
                      </tr>
                      <tr>
                        <td>Customer stranded</td>
                        <td>8</td>
                      </tr>
                      <tr>
                        <td>Safety/Emissions</td>
                        <td>10</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div>
                <div className="btmText">
                  <p>Repeat Failure</p>
                  <p className="equalsTo1">=</p>
                  <p className="formula1">
                    Failure Reports /Vehicles affected{" "}
                  </p>
                </div>
                <div className="btmText">
                  <p>Failure Rate</p>
                  <p>=</p>
                  <p className="formula2">Vehicles affected/Population </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default VPWMPrognosticScoreCard;
