import { useEffect } from "react";
import { useSelector } from "react-redux";
import Highcharts from "highcharts";
import "../LineGraph/index.scss";
import doubleArrow from "../../../assets/double-arrow.svg";
import arrowXAxis from "../../../assets/arrow-xaxis.svg";

export const PocDualGraph = ({
  id,
  data,
  title,
  xLabel,
  onClickHandler,
  plotXLines,
  plotYLines,
  xAxisData,
  yAxisLabel,
  dispRUL = false,
  defaultMetric,
  graphStatus,
  showRul,
  units,
  inputData,
  defaultInterval,
}) => {
  const isSidemenuExpanded = useSelector(
    (state) => state.mainReducer.isSidemenuExpanded
  );

  useEffect(() => {
    if (data.length) {
      renderChart(data, title, xLabel, plotXLines, plotYLines, yAxisLabel, inputData, defaultInterval);
    }
  }, [data, isSidemenuExpanded]);

  const getyAxisTitle = (chartData, yAxisLabel) => {
    const yLabel = chartData?.[0]?.name;
    return chartData?.length < 1
      ? {
        text: null,
      }
      : {
        text: `<div class="y-axis-legend"><div>${yLabel}</div><div class="y-axis-arrow"></div></div>`,
        style: {
          color: "#353536",
          lineHeight: 1,
          textAlign: "right",
          font: "10px Open Sans",
          letterSpacing: "1px",
        },
        align: "high",
        useHTML: true,
        margin: 20,
      };
  };

  const getxAxisTitle = (chartData, xLabel) => {
    return chartData?.length < 1
      ? {
        text: null,
      }
      : {
        text: `<div class="x-axis-legend"><div>${xLabel}</div><img src='${arrowXAxis}'/></div>`,
        style: {
          color: "#353536",
          lineHeight: 1,
          textAlign: "right",
          font: "10px Open Sans",
          letterSpacing: "1px",
        },
        align: "high",
        useHTML: true,
        margin: 20,
      };
  };

  const renderChart = (
    chartData,
    title,
    xLabel,
    plotXLines,
    plotYLines,
    yAxisLabel,
  ) => {
    Highcharts.chart(
      id,
      {
        chart: {
          type: "spline",
          backgroundColor: "#ffffff",
          spacing: [50, 30, 10, 5],
          height: 350,
          borderRadius: 20,
          // borderColor: "#cecece",
          // borderWidth: 1,
          events: {
            load: function () {
              if (dispRUL) {
                const chart = this;
                const ren = this.renderer;
                const distplotX = chart.plotWidth;
                const distplotY = chart.plotHeight;
                const maxWidth = chart.chartWidth;
                const xMin = chart.xAxis[0].min;
                const xMax = chart.xAxis[0].max;
                const width = -(((chartData?.[1].data?.[1].x - chartData?.[1].data?.[0].x) /
                  (xMax - xMin)) *
                  distplotX);
                if (defaultMetric === "Hours") {
                  if (defaultInterval === "Last Trips" & inputData == 1) {
                    ren
                      .image(
                        doubleArrow,
                        maxWidth - width + 14,
                        distplotY + 30,
                        width - 120,
                        10
                      )
                      .add();
                  }

                  else if (defaultInterval === "Last Miles" && inputData >= 1 && inputData < 1000){

                    ren
                      .image(
                        doubleArrow,
                        maxWidth - width + 55,
                        distplotY + 30,
                        width - 200,
                        10
                      )
                      .add();

                  }
                  else {
                    ren
                      .image(
                        doubleArrow,
                        maxWidth - width - 15,
                        distplotY + 30,
                        width - 70,
                        10
                      )
                      .add();
                  }
                } else {
                  ren
                    .image(
                      doubleArrow,
                      maxWidth - width - 30,
                      distplotY + 30,
                      width,
                      10
                    )
                    .add();
                }
                ren
                  .label(
                    `RUL: ${data[0].RulValue[0]}`,
                    maxWidth - width / 2 - 70,
                    distplotY + 13
                  )
                  .css({
                    color: "#386ED6",
                    fontSize: "10px",
                  })
                  .add();
              }
            },
          },
        },

        title: {
          text: null,
        },

        yAxis: {
          title: getyAxisTitle(chartData, yAxisLabel),
          labels: {
            style: {
              color: "#353536",
              lineHeight: 1,
              font: "10px Open Sans",
              letterSpacing: "1px",
            },
          },
          gridLineColor: "#F2F2F2",
          plotLines: plotYLines
            ? [
              {
                color: "#ffcccc",
                value: chartData[0]?.ThresholdsValue,
                width: 2,
                dashStyle: chartData?.[1]?.dashStyle,
              },
            ]
            : [],
        },
        xAxis: {
          type: "category",
          title: getxAxisTitle(xAxisData ? xAxisData : chartData, xLabel),
          labels: {
            style: {
              color: "#353536",
              lineHeight: 1,
              font: "10px Open Sans",
              letterSpacing: "1px",
            },
            formatter: function () {
              if (this.value < 1000) {
                return this.value;
              }
              if (this.value > 1000 && this.value < 1000000) {
                return this.value / 1000 + "K";
              }
              if (this.value > 1000000) {
                return this.value / 1000000 + "M";
              }
            },
          },

          tickPositioner: function () {
            var positions = [],
              tick = Math.floor(this.dataMin),
              increment = Math.ceil((this.dataMax - this.dataMin) / 7);

            if (this.dataMax !== null && this.dataMin !== null) {
              for (tick; tick - increment <= this.dataMax; tick += increment) {
                positions.push(tick);
              }
            }
            return positions;
          },

          //tickPositioner:5,

          //tickInterval:100,

          plotLines: plotXLines
            ? [
              {
                color: "#d1e1c0",
                value: chartData?.[1]?.data?.[0]?.x,
                width: 2,
                dashStyle: chartData?.[1]?.dashStyle,
              },
              {
                color: "#d1e1c0",
                value: chartData?.[1]?.data?.[1]?.x,
                width: 2,
                dashStyle: chartData?.[1]?.dashStyle,
              },
            ]
            : [],
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          backgroundColor: "#000",
          style: {
            color: "#FFFFFF",
            font: "12px Open Sans",
          },
        },
        plotOptions: {
          spline: {
            marker: {
              enabled: true,
              radius: 3,
            },
          },
        },

        tooltip: {
          formatter: function () {
            return xLabel + ":" + this.point.x;
          },
        },

        series: plotXLines || plotYLines ? [chartData[2]] : chartData,
      },

      function (chart) {
        const dataLen = chart.series?.[0]?.data?.length || 0;
        if (dataLen < 1) {
          chart.renderer
            .text("No Data Available", 50, 125)
            .css({
              color: "#000000",
              fontSize: "16px",
            })
            .add();
        }
      }
    );
  };

  return (
    <figure className="highcharts-figure" onClick={() => onClickHandler()}>
      {showRul && (
        <div className="rul-text">
          <p>
            RUL : {data[2]?.RulValue[0]} {units}
          </p>
        </div>
      )}
      <div id={id}></div>
      <div className="graph-name">{title}</div>
    </figure>
  );
};
