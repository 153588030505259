import moment from "moment";
import React from "react";
import { useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import RightArrowLongIcon from "../assets/right-arrow-long.svg";
import LengendContainer from "./LengendContainer";
import { useSelector } from "react-redux";
import { colorMap, getTickIntervals, graphType } from "../utils/constants";
import NoDataFound from "./NoDataFound";

const AlternateEfficiency = ({ data, filterType }) => {
  const isSidemenuExpanded = useSelector(
    (state) => state.mainReducer.isSidemenuExpanded
  );
  const [odometerTick, setOdometerTick] = useState([]);
  const [chartData, setChartData] = useState(null);
  const [alternateEfficiencyArray, setAlternateEfficiencyArray] =
    useState(null);
  const [chartInfo, setChartInfo] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);

  useEffect(() => {
    setChartData(data?.alternatorEfficiencyPercentage);
    if (data && data?.alternatorEfficiencyPercentage.length > 0) {
      let alternateEfficiencyArray = [];
      switch (filterType) {
        case graphType.DATE: {
          alternateEfficiencyArray = data.alternatorEfficiencyPercentage.map(
          (item, index) => [index, item.alternatorEfficiencyPercentage]
          );
          break;
        }

        case graphType.MILES: {
          alternateEfficiencyArray = data.alternatorEfficiencyPercentage.map(
            (item) => [item.odometer, item.alternatorEfficiencyPercentage]
          );
          if (alternateEfficiencyArray.length > 1) {
            const tickArray = data.alternatorEfficiencyPercentage
              .map((item) => item.odometer)
              .sort((a, b) => a - b);
            const tArray = getTickIntervals(tickArray);
            setOdometerTick(tArray);
          }

          break;
        }

        case graphType.TRIP: {
          alternateEfficiencyArray = data.alternatorEfficiencyPercentage.map(
            (item) => [item.trip, item.alternatorEfficiencyPercentage]
          );
          break;
        }

        default: {
          alternateEfficiencyArray = data.alternatorEfficiencyPercentage.map(
            (item, index) => [index, item.alternatorEfficiencyPercentage]
          );
        }
      }

      setChartInfo(data?.information);
      setAlternateEfficiencyArray(alternateEfficiencyArray);
      const month = moment(
        data?.alternatorEfficiencyPercentage[0].statsDate
      ).format("MMMM");
      const year = moment(
        data?.alternatorEfficiencyPercentage[0].statsDate
      ).format("YYYY");
      setMonth(month);
      setYear(year);
    } else {
      setAlternateEfficiencyArray(null);
    }
  }, [data]);

  const options = {
    chart: {
      backgroundColor: "#FFFFFF",
      spacing: [50, 30, 30, 20],
      height: 350,
      type: "scatter",
      // borderColor: "#CECECE",
      borderRadius: 20,
      // borderWidth: 1,
      events: {
        load: function () {
          this.series[1]?.update({
            enableMouseTracking: false,
          });
        },
      },
    },
    title: {
      text: null,
    },
    yAxis: {
      title: {
        useHTML: true,
        text: `
        <div style="font-size: 11px; left:-120px;top:4px;position:absolute;">
        Alternate Efficiency
          <img src='${RightArrowLongIcon}'/>
        </div>
        `,
        align: "high",
      },
      min: 0,
      labels: {
        style: {
          color: "#353536",
          lineHeight: 1,
          font: "10px Open Sans",
          letterSpacing: "1px",
        },
      },
      gridLineColor: "#F2F2F2",
    },
    xAxis: {
      type: "category",
      tickPositions: filterType === graphType.MILES ? odometerTick : null,
      title: {
        useHTML: true,
        text: `
        <div style="font-size: 11px; left:-60px;top:4px;position:absolute;">
           ${
             filterType === graphType.DATE
               ? `${month},${year}`
               : filterType === graphType.MILES
               ? `Miles`
               : filterType === graphType.TRIP
               ? `Trips`
               : `${month},${year}`
           }
          <img src='${RightArrowLongIcon}'/>
        </div>
        `,
        align: "high",
      },
      labels: {
        style: {
          color: "#353536",
          lineHeight: 1,
          font: "10px Open Sans",
          letterSpacing: "1px",
        },
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: true,
        },
        marker: {
          radius: 3,
        },
      },
      line: {
        dashStyle: "Dash",
      },
    },
    tooltip: {
      backgroundColor: "#000",
      style: {
        color: "#FFFFFF",
        font: "12px Open Sans",
      },
    },
    series: [
      {
        data: alternateEfficiencyArray,
        showInLegend: false,
        name: "Alternator Efficiency (%)",
        color: chartInfo && colorMap[chartInfo[0]?.color],
        regression: alternateEfficiencyArray?.length > 1 ? true : false,
        regressionSettings: {
          type: "polynomial",
          order: "2",
          color: chartInfo && colorMap[chartInfo[0]?.color],
          dashStyle: "dot",
        },
      },
    ],
  };
  return (
    <div key={alternateEfficiencyArray}>
      <div key={isSidemenuExpanded}>
        {Array.isArray(alternateEfficiencyArray) &&
        alternateEfficiencyArray.length > 0 ? (
          <>
            <LengendContainer chartInfo={chartInfo} />
            <HighchartsReact highcharts={Highcharts} options={options} />
            <div
              style={{ width: "100%", textAlign: "center", paddingTop: "10px" }}
            >
              <p style={{ fontSize: "14px", fontWeight: "600" }}>
                Alternator Efficiency (%)
              </p>
            </div>
          </>
        ) : (
          <>
            <NoDataFound chartName="Alternator Efficiency (%)" />
          </>
        )}
      </div>
    </div>
  );
};

export default AlternateEfficiency;
