import React, { useState } from "react";
import "./index.scss";

const WarrantyRateCard = ({ text, value, handle }) => {
  const [activeValue, setValue] = useState("1MIS");

  const handleCalander = (text, value, calander) => {
    setValue(value + calander);
    handle(text, value, calander);
  };

  return (
    <div>
      <div className="warranty-rate-card-title">{text}</div>
      <div className="warranty-rate-card-container">
        <div className="warranty-rate-card-calander-container">
          <div
            className={
              activeValue === "1MIS"
                ? "warranty-rate-card-calander active"
                : "warranty-rate-card-calander"
            }
            onClick={() => handleCalander(text, 1, "MIS")}
          >
            1 MIS
          </div>
          <div
            className={
              activeValue === "3MIS"
                ? "warranty-rate-card-calander active"
                : "warranty-rate-card-calander"
            }
            onClick={() => handleCalander(text, 3, "MIS")}
          >
            3 MIS
          </div>
          <div
            className={
              activeValue === "6MIS"
                ? "warranty-rate-card-calander active"
                : "warranty-rate-card-calander"
            }
            onClick={() => handleCalander(text, 6, "MIS")}
          >
            6 MIS
          </div>
          <div
            className={
              activeValue === "12MIS"
                ? "warranty-rate-card-calander active"
                : "warranty-rate-card-calander"
            }
            onClick={() => handleCalander(text, 12, "MIS")}
          >
            12 MIS
          </div>
          <div
            className={
              activeValue === "36MIS"
                ? "warranty-rate-card-calander active"
                : "warranty-rate-card-calander"
            }
            onClick={() => handleCalander(text, 36, "MIS")}
          >
            36 MIS
          </div>
        </div>
        <div className="warranty-rate-card-text">
          <div>{text}</div>
          <div className="warranty-rate-card-value">{value}</div>
        </div>
      </div>
    </div>
  );
};

export default WarrantyRateCard;
