import React, { useEffect } from "react";
import "../WarrantyManagement.scss";
import WarrantyRateCard from "./WarrantyRateCard";
import { mainAxiosInstance } from "../../../services/service";
import { useState } from "react";
import { WarrantymanagementDashBoard } from "../../../services/service-constants";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

const WarrantyRate = () => {
  const [currentWarrantyValue, setCurrentWarrantyValue] = useState("--");
  const [projectWarrantyValue, setProjectWarrantyValue] = useState("--");
  const vinNumber = useSelector((state) => state.mainReducer.vin_Number);
  const location = useLocation();
  const getCurrentWarranty = (value, calander) => {
    setCurrentWarrantyValue("--");
    const diagnosesState = 0;
    let url = `${WarrantymanagementDashBoard.warranty_rate}/${diagnosesState}`;
    let params = {};
    if (calander === "MIS") {
      if (
        location.pathname.includes(
          "vehicle-population/vehicle-population-warranty-management"
        )
      ) {
        params = {
          make: "Ford",
          organizationId: 1,
          lastMonths: value,
        };
      } else {
        params = {
          make: "Ford",
          organizationId: 1,
          lastMonths: value,
          vinNumber: vinNumber,
        };
      }
    } else {
      if (
        location.pathname.includes(
          "vehicle-population/vehicle-population-warranty-management"
        )
      ) {
        params = {
          make: "Ford",
          organizationId: 1,
          lastMonths: value,
        };
      } else {
        params = {
          make: "Ford",
          organizationId: 1,
          lastMonths: value,
          vinNumber: vinNumber,
        };
      }
    }
    mainAxiosInstance
      .post(url, params)
      .then((response) => {
        if (response.status === 200) {
          setCurrentWarrantyValue(response.data);
        } else {
          setCurrentWarrantyValue("--");
        }
      })
      .catch((err) => {
        setCurrentWarrantyValue("--");
      });
  };

  const getProjectWarranty = (value, calander) => {
    setProjectWarrantyValue("--");
    const diagnosesState = 1;
    let url = `${WarrantymanagementDashBoard.warranty_rate}/${diagnosesState}`;
    let params = {};
    if (calander === "MIS") {
      if (
        location.pathname.includes(
          "vehicle-population/vehicle-population-warranty-management"
        )
      ) {
        params = {
          make: "Ford",
          organizationId: 1,
          nextMonths: value,
        };
      } else {
        params = {
          make: "Ford",
          organizationId: 1,
          nextMonths: value,
          vinNumber: vinNumber,
        };
      }
    } else {
      if (
        location.pathname.includes(
          "vehicle-population/vehicle-population-warranty-management"
        )
      ) {
        params = {
          make: "Ford",
          organizationId: 1,
          nextMonths: value,
        };
      } else {
        params = {
          make: "Ford",
          organizationId: 1,
          nextMonths: value,
          vinNumber: vinNumber,
        };
      }
    }
    mainAxiosInstance
      .post(url, params)
      .then((response) => {
        if (response.status === 200) {
          setProjectWarrantyValue(response.data);
        } else {
          setProjectWarrantyValue("--");
        }
      })
      .catch((err) => {
        setProjectWarrantyValue("--");
      });
  };

  const handleClick = (text, value, calander) => {
    if (text === "Current Warranty") {
      getCurrentWarranty(value, calander);
    } else if (text === "Project Warranty") {
      getProjectWarranty(value, calander);
    }
  };

  useEffect(() => {
    getCurrentWarranty(1, "MIS");
    getProjectWarranty(1, "MIS");
  }, []);

  return (
    <div
      className="warranty-rate page-wrapper"
      style={{ backgroundColor: "#FFF" }}
    >
      <div className="first-container">
        <WarrantyRateCard
          text="Current Warranty"
          value={currentWarrantyValue}
          handle={handleClick}
        />
        <WarrantyRateCard
          text="Project Warranty"
          value={projectWarrantyValue}
          handle={handleClick}
        />
      </div>
    </div>
  );
};

export default WarrantyRate;
