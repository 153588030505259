import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import "../../../VehiclePopulation.scss";
import { useLocation } from "react-router-dom";
import SingleChartLoader from "../../../../../components/common/ErrorComponents/SingleChartLoader";
import Feedback from "../../../../../components/common/ErrorComponents/Feedback";
import { VehiclePopulationDashBoard } from "../../../../../services/service-constants";
import { mainAxiosInstance } from "../../../../../services/service";
import { BarGraph } from "../../../../../components/common/blackbarGraph/Graph";
import DataContext from "../../../../../utils/dataContext";
import "../../../VehiclePopulation.scss";
import Select from "../../../../..//components/common/Select/Select";
import Datepicker from "../../../../../components/Datepicker/datepicker";
import moment from "moment";
import MyMap from "../../../../../components/common/MapGraph/MyMap";
import mapDataApi from "../../../../../components/common/MapGraph/MapApi";
import Pagination from "../../../../../components/common/Pagination";

const CompareWithVin = () => {
  const location = useLocation();
  const { setBlinkData } = useContext(DataContext);
  const [noDataFound, setNoDataFound] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [data, setData] = useState(null);
  const [graphName, setGraphName] = useState("");
  const [containerName, setContainerName] = useState(0);
  const [diagnosesState, setDiagnosesState] = useState(null);
  const firstfilter = useSelector((state) => state.mainReducer.first_filter);
  const vinfilter = useSelector((state) => state.mainReducer.vin_filter);
  const [defaultInterval, setDefaultInterval] = useState("Miles");
  const [defaultAllInterval, setDefaultAllInterval] = useState("All");
  const interval = ["Miles", "Date"];
  const allInterval = ["All", "P1", "VIN"];
  const [showInput, setShowInput] = useState(true);
  const [showDatepicker, setShowDatepicker] = useState(false);
  const [upperLimitData, setUpperLimitData] = useState(5000);
  const [lowerLimitData, setLowerLimitData] = useState(1);
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndtDate, setSelectedEndDate] = useState(new Date());
  const [showMetric, setShowMetric] = useState(false);
  const colorMap = { black: "#000000", blue: "#0574A6" };
  const [secondGeoMapData, setSecondGeoMapData] = useState(null);
  const [firstGeoMapData, setFirstGeoMapData] = useState(null);
  const [mapData, setMapData] = useState(null);
  const rulInterval = ["Miles", "Hours", "Engine Run Time", "Key starts"];
  const [showRulMetric, setshowRulMetric] = useState(false);
  const [showChartLoader, setShowChartLoader] = useState(false);
  const [pagination, setPagination] = useState({});
  const [showFeedback, setShowFeedback] = useState(false);
  const [pageSize, setPageSize] = useState(0);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const containerId = urlParams.get("key");
    setContainerName(parseInt(containerId));
    if (location?.state) {
      setDiagnosesState(location?.state?.diagnosesState);
    } else {
      setDiagnosesState(location.pathname.includes("diagnostics") ? 0 : 1);
    }
  }, [location]);

  useEffect(() => {
    setDefaultAllInterval("All");
    if (containerName === 0 || containerName === 1 || containerName === 20) {
      setShowMetric(true);
    } else {
      setShowMetric(false);
    }
  }, [containerName]);

  useEffect(() => {
    if (location.search === "?key=20") {
      setshowRulMetric(true);
      setDefaultInterval("Miles");
      setUpperLimitData(5000);
      setLowerLimitData(1);
      setShowInput(true);
      setShowDatepicker(false);
    } else {
      setshowRulMetric(false);
      setShowDatepicker(false);
      setDefaultInterval("Miles");
      setUpperLimitData(5000);
      setLowerLimitData(1);
      setShowInput(true);
    }
  }, [location]);

  const getChartData = (detailsData, info, PaginationInfo) => {
    setNoDataFound(false);
    setPagination(PaginationInfo);
    if (detailsData?.length > 0) {
      var graphData = detailsData;
      var graphInformation = info;
      const graphValues = [];
      let xAxisLabel = graphInformation?.xAxisName;
      let yAxisLabel = graphInformation?.yAxisName;
      let graphName = graphInformation?.graphName;
      setGraphName(graphName);
      graphValues.push({
        data: graphData,
        xLabel: xAxisLabel,
        yLabel: yAxisLabel,
        id: graphName,
      });
      setChartData(graphValues);
    } else {
      setChartData(null);
      setNoDataFound(true);
    }
  };

  const prepareChartData = (data) => {
    let pageDataContent = {};
    let pageDataInfo = {};
    let detailsData = [];
    let PaginationInfo = null;
    if (upperLimitData && lowerLimitData) {
      if (containerName === 0) {
        pageDataContent = data?.topTenDtcsAggregatorDto?.topTenDtcsDtos;
        pageDataContent?.forEach((val) => {
          detailsData.push({
            y: val.noOfOccurrences,
            name: val.dtcCode,
            values: val.dtcCode,
            color: colorMap[val.color],
          });
        });
        pageDataInfo = data.topTenDtcsAggregatorDto?.informationDto;
      } else if (containerName === 1) {
        pageDataContent = data?.topTenNonDtcsAggregatorDto?.topTenNonDtcsDto;
        pageDataContent?.forEach((val) => {
          detailsData.push({
            y: val.noOfOccurrences,
            // name: val.nonDtc,
            values: val.nonDtc,
            color: colorMap[val.color],
          });
        });

        pageDataInfo = data?.topTenNonDtcsAggregatorDto?.informationDto;
      } else if (containerName === 2) {
        pageDataContent =
          data?.percentageOfSettingDtcAggregatorDto
            ?.percentageOfPopulationSettingDtcDto?.content;
        PaginationInfo =
          data?.percentageOfSettingDtcAggregatorDto
            ?.percentageOfPopulationSettingDtcDto;
        pageDataContent?.forEach((val) => {
          detailsData.push({
            y: val.percentageOfPopulationSettingDtc,
            name: val.miles,
            values: val.miles,
            color: colorMap[val.color],
          });
        });

        pageDataInfo =
          data?.percentageOfSettingDtcAggregatorDto?.informationDto;
      } else if (containerName === 3) {
        pageDataContent =
          data?.dtcWarrantyScoreCardAggregatorDto?.dtcWarrantyScoreCardDto
            ?.content;
        PaginationInfo =
          data?.dtcWarrantyScoreCardAggregatorDto?.dtcWarrantyScoreCardDto;
        pageDataContent?.forEach((val) => {
          detailsData.push({
            y: val.warrantyScoreCard,
            name: val.dtcCode,
            values: val.dtcCode,
            color: colorMap[val.color],
          });
        });

        pageDataInfo = data?.dtcWarrantyScoreCardAggregatorDto?.informationDto;
      } else if (containerName === 4) {
        pageDataContent = data.geospatialMappingAggregatorDto;
      } else if (containerName === 5) {
        pageDataContent = data?.makeCountAggregatorDto?.makeCountDtos?.content;
        PaginationInfo = data?.makeCountAggregatorDto?.makeCountDtos;
        pageDataContent?.forEach((val) => {
          detailsData.push({
            y: val.noOfOccurrences,
            name: val.make,
            values: val.make,
            color: colorMap[val.color],
          });
        });

        pageDataInfo = data?.makeCountAggregatorDto?.informationDto;
      } else if (containerName === 6) {
        pageDataContent =
          data?.modelCountAggregatorDto?.modelCountDtos?.content;
        PaginationInfo = data?.modelCountAggregatorDto?.modelCountDtos;
        pageDataContent?.forEach((val) => {
          detailsData.push({
            y: val.noOfOccurrences,
            name: val.model,
            values: val.model,
            color: colorMap[val.color],
          });
        });

        pageDataInfo = data?.modelCountAggregatorDto?.informationDto;
      } else if (containerName === 7) {
        pageDataContent =
          data?.modelYearCountAggregatorDto?.modelYearCountDtos?.content;
        PaginationInfo = data?.modelYearCountAggregatorDto?.modelYearCountDtos;
        pageDataContent?.forEach((val) => {
          detailsData.push({
            y: val.noOfOccurrences,
            name: val.modelYear,
            values: val.modelYear,
            color: colorMap[val.color],
          });
        });

        pageDataInfo = data?.modelYearCountAggregatorDto?.informationDto;
      } else if (containerName === 8) {
        PaginationInfo =
          data?.manufacturingPlantAggregatorDto?.manufacturingPlantDtos;
        pageDataContent =
          data?.manufacturingPlantAggregatorDto?.manufacturingPlantDtos
            ?.content;
        pageDataContent?.forEach((val) => {
          detailsData.push({
            y: val.noOfOccurrences,
            name: val.manufacturingPlant,
            values: val.manufacturingPlant,
            color: colorMap[val.color],
          });
        });

        pageDataInfo = data?.manufacturingPlantAggregatorDto?.informationDto;
      } else if (containerName === 9) {
        PaginationInfo = data?.buildDatesAggregatorDto?.buildDatesDtos;
        pageDataContent =
          data?.buildDatesAggregatorDto?.buildDatesDtos?.content;
        pageDataContent?.forEach((val) => {
          detailsData.push({
            y: val.noOfOccurrences,
            // name: val.buildDates,
            values: val.buildDates,
            color: colorMap[val.color],
          });
        });

        pageDataInfo = data?.buildDatesAggregatorDto?.informationDto;
      } else if (containerName === 10) {
        PaginationInfo = data?.powerPackAggregatorDto?.powerPackDto;
        pageDataContent = data?.powerPackAggregatorDto?.powerPackDto?.content;
        pageDataContent?.forEach((val) => {
          detailsData.push({
            y: val.noOfOccurrences,
            // name: val.powerPack,
            values: val.powerPack,
            color: colorMap[val.color],
          });
        });

        pageDataInfo = data?.powerPackAggregatorDto?.informationDto;
      } else if (containerName === 11) {
        PaginationInfo = data?.subSystemCountAggregatorDto?.subSystemCountDtos;
        pageDataContent =
          data?.subSystemCountAggregatorDto?.subSystemCountDtos?.content;
        pageDataContent?.forEach((val) => {
          detailsData.push({
            y: val.noOfOccurrences,
            name: val.subSystem,
            values: val.subSystem,
            color: colorMap[val.color],
          });
        });

        pageDataInfo = data?.subSystemCountAggregatorDto?.informationDto;
      } else if (containerName === 12) {
        PaginationInfo =
          data?.softwareVersionAggregatorDto?.softwareResponseDtos;
        pageDataContent =
          data?.softwareVersionAggregatorDto?.softwareResponseDtos?.content;
        pageDataContent?.forEach((val) => {
          detailsData.push({
            y: val.noOfOccurrences,
            name: val.version,
            values: val.softwareVersion,
            color: colorMap[val.color],
          });
        });

        pageDataInfo = data?.softwareVersionAggregatorDto?.informationDto;
      } else if (containerName === 13) {
        PaginationInfo =
          data?.calibrationVersionAggregatorDto?.calibrationResponseDtos;
        pageDataContent =
          data?.calibrationVersionAggregatorDto?.calibrationResponseDtos
            ?.content;
        pageDataContent?.forEach((val) => {
          detailsData.push({
            y: val.noOfOccurrences,
            name: val.version,
            values: val.calibrationVersion,
            color: colorMap[val.color],
          });
        });

        pageDataInfo = data?.calibrationVersionAggregatorDto?.informationDto;
      } else if (containerName === 14) {
        PaginationInfo = data?.dtcCountAggregatorDto?.dtcCountDtos;
        pageDataContent = data?.dtcCountAggregatorDto?.dtcCountDtos?.content;
        pageDataContent?.forEach((val) => {
          detailsData.push({
            y: val.noOfOccurrences,
            name: val.dtc,
            values: val.dtc,
            color: colorMap[val.color],
          });
        });

        pageDataInfo = data?.dtcCountAggregatorDto?.informationDto;
      } else if (containerName === 15) {
        PaginationInfo =
          data?.temperatureCountAggregatorDto?.temperatureCountDtos;
        pageDataContent =
          data?.temperatureCountAggregatorDto?.temperatureCountDtos?.content;
        pageDataContent?.forEach((val) => {
          detailsData.push({
            y: val.noOfOccurrences,
            name: val.ambientTemperature,
            values: "Temperature :" + val.ambientTemperature,
            color: colorMap[val.color],
          });
        });

        pageDataInfo = data?.temperatureCountAggregatorDto?.informationDto;
      } else if (containerName === 16) {
        PaginationInfo = data?.altitudeCountAggregatorDto?.altitudeCountDtos;
        pageDataContent =
          data?.altitudeCountAggregatorDto?.altitudeCountDtos?.content;
        pageDataContent?.forEach((val) => {
          detailsData.push({
            y: val.noOfOccurrences,
            name: val.altitude,
            values: val.altitude,
            color: colorMap[val.color],
          });
        });

        pageDataInfo = data?.altitudeCountAggregatorDto?.informationDto;
      } else if (containerName === 17) {
        PaginationInfo =
          data?.systemStateCountAggregatorDto?.systemStateCountDtos;
        pageDataContent =
          data?.systemStateCountAggregatorDto?.systemStateCountDtos?.content;
        pageDataContent?.forEach((val) => {
          detailsData.push({
            y: val.noOfOccurrences,
            name: val.systemState,
            values: val.systemState,
            color: colorMap[val.color],
          });
        });

        pageDataInfo = data?.systemStateCountAggregatorDto?.informationDto;
      } else if (containerName === 18) {
        PaginationInfo =
          data?.operatingStateCountAggregatorDto?.operatingStateCountDtos;
        pageDataContent =
          data?.operatingStateCountAggregatorDto?.operatingStateCountDtos
            ?.content;
        pageDataContent?.forEach((val) => {
          detailsData.push({
            y: val.noOfOccurrences,
            name: val.operatingState,
            values: val.operatingState,
            color: colorMap[val.color],
          });
        });

        pageDataInfo = data?.operatingStateCountAggregatorDto?.informationDto;
      } else if (containerName === 19) {
        PaginationInfo =
          data?.customerComplaintCountAggregatorDto?.customerComplaintCountDtos;
        pageDataContent =
          data?.customerComplaintCountAggregatorDto?.customerComplaintCountDtos
            ?.content;
        pageDataContent?.forEach((val) => {
          detailsData.push({
            y: val.noOfOccurrences,
            name: val.customerComplaint,
            values: val.customerComplaint,
            color: colorMap[val.color],
          });
        });

        pageDataInfo =
          data?.customerComplaintCountAggregatorDto?.informationDto;
      } else if (containerName === 20) {
        PaginationInfo = data?.rulGraphAggregatorDto?.rulGraphDto;
        pageDataContent = data?.rulGraphAggregatorDto?.rulGraphDto?.content;
        pageDataContent?.forEach((val) => {
          detailsData.push({
            y: val.noOfOccurrences,
            name: val.dtcCode,
            values: val.dtcCode,
            color: colorMap[val.color],
          });
        });

        pageDataInfo = data?.rulGraphAggregatorDto?.informationDto;
      }

      let filteredData = detailsData;
      if (defaultAllInterval === "VIN") {
        const vinData = detailsData.filter((item) => item?.color === "#000000");
        if (vinData.length > 0) {
          filteredData = vinData;
        } else {
          filteredData = [
            {
              y: 0,
              name: "",
              values: "",
              color: "",
            },
          ];
        }
      } else if (defaultAllInterval === "P1") {
        const cardData = detailsData.filter(
          (item) => item?.color === "#0574A6"
        );
        if (cardData.length > 0) {
          filteredData = cardData;
        } else {
          filteredData = [
            {
              y: 0,
              name: "",
              values: "",
              color: "",
            },
          ];
        }
      } else {
        filteredData = detailsData;
      }

      if (containerName === 4) {
        getGeoSpatialMapData(pageDataContent);
      } else {
        getChartData(filteredData, pageDataInfo, PaginationInfo);
      }
    } else {
      if (lowerLimitData.length === 0) setNoDataFound(true);
    }
  };

  const getGeoSpatialMapData = (val) => {
    const geoMapData = val.geospatialMappingDtoList;
    const firstGraphData = geoMapData?.filter((item) => item.color === "blue");
    const Population1 = firstGraphData.flatMap((item) => item.state);
    const filterDataPopulation1 = Population1.map((item) => {
      const details = item.details.map(
        (detail) => `${detail.dtcCode}(${detail.noOfOccurrences})`
      );
      return { name: item.name, totalCount: item.totalCount, details: details };
    });

    const secondGraphData = geoMapData?.filter(
      (item) => item.color === "black"
    );
    const Population2 = secondGraphData.flatMap((item) => item.state);
    const filterDataPopulation2 = Population2.map((item) => {
      const details = item.details.map(
        (detail) => `${detail.dtcCode}(${detail.noOfOccurrences})`
      );
      return { name: item.name, totalCount: item.totalCount, details: details };
    });
    setFirstGeoMapData(filterDataPopulation1);
    setSecondGeoMapData(filterDataPopulation2);
  };

  const getPageData = (val) => {
    setNoDataFound(false);
    setShowChartLoader(true);
    const url = `${VehiclePopulationDashBoard.compare_with_vin}/${diagnosesState}?page=${pageSize}&size=10`;
    let params = {};
    if (showRulMetric) {
      switch (defaultInterval) {
        case "Miles": {
          params = {
            fromRulMiles: parseInt(lowerLimitData),
            toRulMiles: parseInt(upperLimitData),
            rul: "by_miles",
          };
          break;
        }

        case "Engine Run Time": {
          params = {
            fromRulEngineRunTime: parseInt(lowerLimitData),
            toRulEngineRunTime: parseInt(upperLimitData),
            rul: "by_engine_runtime",
          };
          break;
        }

        case "Hours": {
          params = {
            fromRulHours: parseInt(lowerLimitData),
            toRulHours: parseInt(upperLimitData),
            rul: "by_hours",
          };
          break;
        }

        case "Key starts": {
          params = {
            fromRulKeyStarts: parseInt(lowerLimitData),
            toRulKeyStarts: parseInt(upperLimitData),
            rul: "by_key_starts",
          };
          break;
        }

        default: {
          params = {
            fromRulMiles: parseInt(lowerLimitData),
            toRulMiles: parseInt(upperLimitData),
            rul: "by_miles",
          };
        }
      }
    } else {
      switch (defaultInterval) {
        case "Miles": {
          params = {
            fromMiles: lowerLimitData,
            toMiles: upperLimitData,
            type: "by_miles",
          };
          break;
        }

        case "Date": {
          params = {
            fromDate: moment(selectedStartDate).format("yyyy-MM-DD"),
            toDate: moment(selectedEndtDate).format("yyyy-MM-DD"),
            type: "by_date",
          };
          break;
        }

        default: {
          params = {
            fromMiles: lowerLimitData,
            toMiles: upperLimitData,
            type: "by_miles",
          };
        }
      }
    }
    let paramData = {
      filterDto: {
        ...firstfilter,
        ...params,
      },
      vinNumberRequestDto: {
        vinNumber: vinfilter,
        make: "Ford",
        organizationId: 1,
      },
    };

    mainAxiosInstance
      .post(url, paramData)
      .then((response) => {
        if (response.status === 200) {
          setData(response.data);
          setBlinkData(response.data);
          setShowChartLoader(false);
          setNoDataFound(false);
        }
        if (response.status === 401) {
          setNoDataFound(true);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setNoDataFound(true);
        } else {
          setNoDataFound(true);
        }
      });
  };

  const fetchMapData = async () => {
    const mapDataRes = await mapDataApi.getWorld();
    setMapData(mapDataRes.data);
  };

  useEffect(() => {
    fetchMapData();
  }, []);

  useEffect(() => {
    if (!upperLimitData) {
      setNoDataFound(true);
    } else {
      if (diagnosesState !== null) getPageData();
    }
  }, [
    diagnosesState,
    defaultInterval,
    upperLimitData,
    lowerLimitData,
    selectedStartDate,
    selectedEndtDate,
    pageSize,
    defaultAllInterval,
  ]);

  useEffect(() => {
    if (data) {
      prepareChartData(data);
    }
  }, [data, showRulMetric, defaultAllInterval, containerName]);

  useEffect(() => {
    setPageSize(0);
  }, [containerName]);

  if (containerName === 4) {
    if (
      chartData === null &&
      noDataFound === false &&
      firstGeoMapData === null &&
      secondGeoMapData === null
    ) {
      return (
        <div className="vehicle-dashboard-middle-container">
          <SingleChartLoader />;
        </div>
      );
    }
  }

  const handleOnStartDateChange = (dates) => {
    const startDate = moment(dates).format("yyyy-MM-DD");
    setSelectedStartDate(startDate);
  };

  const handleOnEndDateChange = (dates) => {
    const endDate = moment(dates).format("yyyy-MM-DD");
    setSelectedEndDate(endDate);
  };

  const handleLowerRulInput = (event) => {
    const { value } = event.target;
    const letters = /^-?\d*\.?\d*$/.test(value);
    if (letters) {
      setLowerLimitData(value);
    }
  };

  const handleUpperRulInput = (event) => {
    const { value } = event.target;
    const letters = /^-?\d*\.?\d*$/.test(value);
    if (letters) {
      setUpperLimitData(value);
    }
  };

  const handleIntervalOptionClick = (value) => {
    setDefaultInterval(value);
    switch (value) {
      case "Miles":
        setShowInput(true);
        setShowDatepicker(false);
        setLowerLimitData(1);
        setUpperLimitData(5000);
        break;

      case "Date":
        setShowInput(false);
        setShowDatepicker(true);
        setSelectedStartDate(new Date());
        setSelectedEndDate(new Date());
        break;

      default:
        setShowInput(true);
        setShowDatepicker(false);
        setLowerLimitData(1);
        setUpperLimitData(5000);
        break;
    }
  };

  const handleAllIntervalOptionClick = (value) => {
    setDefaultAllInterval(value);
  };

  const rulHandleIntervalOptionClick = (val) => {
    setDefaultInterval(val);
    switch (val) {
      case "Miles":
        setShowInput(true);
        setPageSize(0)
        setShowDatepicker(false);
        setLowerLimitData(1);
        setUpperLimitData(5000);
        break;

      case "Hours":
        setShowInput(true);
        setPageSize(0)
        setShowDatepicker(false);
        setLowerLimitData(0);
        setUpperLimitData(1);
        break;

      case "Engine Run Time":
        setShowInput(true);
        setPageSize(0)
        setShowDatepicker(false);
        setLowerLimitData(0);
        setUpperLimitData(1);
        break;

      case "Key starts":
        setShowInput(true);
        setShowDatepicker(false);
        setPageSize(0)
        setLowerLimitData(1);
        setUpperLimitData(5000);
        break;

      default:
        setShowInput(true);
        setShowDatepicker(false);
        setLowerLimitData(1);
        setUpperLimitData(5000);
        break;
    }
  };

  const onPageChanged = (val) => {
    setPageSize(val);
  };

  return (
    <>
      <div className="vehicle-dashboard-middle-container">
        {containerName !== 4 && (
          <>
            <div className="legend-wrapper" style={{ marginTop: "30px" }}>
              <div className="select-container-all-compare">
                <Select
                  options={allInterval}
                  defaultSelected={defaultAllInterval}
                  handleOptionClick={(val) => handleAllIntervalOptionClick(val)}
                  secondaryContainerClass="top-10-input-box tab"
                  containerClass="top-10-dtc"
                />
              </div>
              <div className="color-wrapper">
                <span
                  className="color-box"
                  style={{ backgroundColor: "#0574A6", marginRight: "15px" }}
                ></span>
                <span class="color-legend" style={{ marginRight: "15px" }}>
                  <b>P1</b>
                </span>
                <span
                  className="color-box"
                  style={{ backgroundColor: "#041F3E", marginRight: "15px" }}
                ></span>
                <span class="color-legend">
                  <b>Common in Vin</b>
                </span>
              </div>
            </div>
            {showMetric ? (
              <div className="select-container-metrics-compare">
                {showRulMetric ? (
                  <Select
                    options={rulInterval}
                    defaultSelected={defaultInterval}
                    handleOptionClick={(val) =>
                      rulHandleIntervalOptionClick(val)
                    }
                    secondaryContainerClass="top-10-input-box tab2"
                    containerClass="compare-rul-top-10-dtc"
                  />
                ) : (
                  <Select
                    options={interval}
                    defaultSelected={defaultInterval}
                    handleOptionClick={(val) => handleIntervalOptionClick(val)}
                    secondaryContainerClass="top-10-input-box tab"
                    containerClass="top-10-dtc"
                  />
                )}

                {showInput && (
                  <>
                    <input
                      type="text"
                      className="Dtc-value-input"
                      placeholder={`Enter Lower Limit`}
                      value={lowerLimitData}
                      name="lowerLimitData"
                      onChange={(e) => handleLowerRulInput(e)}
                      maxLength={10}
                    />
                    <input
                      type="text"
                      className="Dtc-value-input"
                      placeholder={`Enter Upper Limit`}
                      value={upperLimitData}
                      name="upperLimitData"
                      onChange={(e) => handleUpperRulInput(e)}
                      maxLength={10}
                    />
                  </>
                )}
                {showDatepicker && (
                  <div
                    style={{
                      display: "flex",
                      position: "relative",
                      bottom: "5px",
                    }}
                  >
                    <Datepicker
                      onChange={handleOnStartDateChange}
                      classContainer="top-10-dtc-date"
                      secondaryClassContainer="input-value-top-10-dtc"
                    />
                    <Datepicker
                      onChange={handleOnEndDateChange}
                      classContainer="top-10-dtc-date"
                      secondaryClassContainer="input-value-top-10-dtc"
                    />
                  </div>
                )}
              </div>
            ) : (
              <></>
            )}
          </>
        )}

        {(noDataFound && !showChartLoader) || !upperLimitData ? (
          <div>
            <Feedback feedbackClassName="feedback" />
          </div>
        ) : !noDataFound && showChartLoader ? (
          <div>
            <SingleChartLoader />;
          </div>
        ) : (
          <>
            {containerName === 4 ? (
              <>
                <div className="comparison-geomap" style={{ display: "flex" }}>
                  <div style={{ position: "relative", top: "40px" }}>
                    <p>Population 1</p>
                    <MyMap chartData={firstGeoMapData} mapData={mapData} />
                  </div>
                  <div style={{ position: "relative", top: "40px" }}>
                    <p>VIN- {vinfilter}</p>
                    <MyMap chartData={secondGeoMapData} mapData={mapData} />
                  </div>
                </div>
                <div
                  className="comparecard-text"
                  style={{ fontSize: "11px", left: "200px" }}
                >
                  <div style={{ fontWeight: "500" }}>
                    <p>Number of occurrences</p>
                  </div>
                  <div className="geo-map-occurance-container">
                    <p
                      className="geo-map-occurance-color"
                      style={{ backgroundColor: "#92C5DE" }}
                    ></p>
                    <p className="geo-map-occurance-data">0-40</p>
                  </div>
                  <div className="geo-map-occurance-container">
                    <p
                      className="geo-map-occurance-color"
                      style={{ backgroundColor: "#4393C3" }}
                    ></p>
                    <p className="geo-map-occurance-data">41-70</p>
                  </div>
                  <div className="geo-map-occurance-container">
                    <p
                      className="geo-map-occurance-color"
                      style={{ backgroundColor: "#0575A8" }}
                    ></p>
                    <p className="geo-map-occurance-data">71-90</p>
                  </div>
                </div>
              </>
            ) : (
              <>
                {!showChartLoader && !noDataFound && chartData !== null ? (
                  <BarGraph chartdata={chartData} pageSize={pageSize} />
                ) : (
                  // <SingleChartLoader />
                  <Feedback feedbackClassName="feedback" />
                )}
              </>
            )}
          </>
        )}
      </div>

      {!noDataFound && containerName !== 4 && (
        <>
          <div className="graph-name">{graphName} </div>
          {pagination?.totalPages > 1 && (
            <Pagination
              pageCount={pagination?.totalPages}
              pageRange={pagination?.size}
              onPageChanged={onPageChanged}
              forcePage={pageSize}
            />
          )}
        </>
      )}
      {!noDataFound && containerName === 4 && (
        <div className="graph-name">Geo-Spatial mapping </div>
      )}
    </>
  );
};

export default CompareWithVin;
