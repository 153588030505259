import { RightVehiclePagesCompareWithCardDiagnostic } from "../../components/VehicleCard";
import { RightVehiclePagesCompareWithCardIcon } from "../../components/VehicleCard";
import "../../VehiclePopulation.scss";
import Altitude from "../../assets/Altitude-image.svg";
import Make from "../../assets/make-image.svg";
import Model from "../../assets/model-image.svg";
import ModelYear from "../../assets/model-year-image.svg";
import Manufact from "../../assets/manufact-image.svg";
import Build_date from "../../assets/build-dates-image.svg";
import Powerpack from "../../assets/power-pack-image.svg";
import Subsystem from "../../assets/subsystem-image.svg";
import Software from "../../assets/software-image.svg";
import Calibration from "../../assets/calibration-image.svg";
import Dtc from "../../assets/dtc-image.svg";
import Temperature from "../../assets/Temperature-image.svg";
import System_st from "../../assets/system-st-image.svg";
import Operating from "../../assets/operating-image.svg";
import Customer from "../../assets/customer-image.svg";
import Rul from "../../assets/Rul.svg";
import { useState, useEffect } from "react";
import { useLocation } from "react-router";

const RightVPCompareCards = ({
  pathName,
  handleClick,
  selectedNumber,
  dashboardType,
}) => {
  const smallRightMenuContainer = localStorage.getItem("VPRightMenuContainer");
  const location = useLocation();
  const [rightMenuContainerSmall, setrightMenuConatinerSmall] = useState("");

  const Data = [
    {
      img: Make,
      text: "Make",
      FlagName: "makeCountComparisonDto",
      containerId: 5,
      containerName: "make",
    },
    {
      img: Model,
      text: "Model",
      containerId: 6,
      containerName: "model",
      FlagName: "modelCountComparisonDto",
    },
    {
      img: ModelYear,
      text: "Model Year",
      containerId: 7,
      containerName: "modelYear",
      FlagName: "modelYearComparisonDto",
    },
    {
      img: Manufact,
      text: "Manufact...",
      containerId: 8,
      containerName: "manufacture",
      FlagName: "manufacturingPlantComparisonDto",
    },
    {
      img: Build_date,
      text: "Build dates",
      containerId: 9,
      containerName: "buildDates",
      FlagName: "buildDateComparisonDto",
    },
    {
      img: Powerpack,
      text: "Powerpack",
      containerId: 10,
      containerName: "powerpack",
      FlagName: "powerPackComparisonDto",
    },
    {
      img: Subsystem,
      text: "Subsystem",
      containerId: 11,
      containerName: "subsystem",
      FlagName: "subSystemComparisonDto",
    },
    {
      img: Software,
      text: "Software...",
      containerId: 12,
      containerName: "software",
      FlagName: "softwareVersionComparisonDto",
    },
    {
      img: Calibration,
      text: "Calibration...",
      containerId: 13,
      containerName: "calibration",
      FlagName: "calibrationVersionComparisonDto",
    },
    {
      img: Dtc,
      text: "DTC",
      containerId: 14,
      containerName: "dtc",
      FlagName: "dtcCountComparisonDto",
    },
    {
      img: Temperature,
      text: "Temperature",
      containerId: 15,
      containerName: "temperature",
      FlagName: "temperatureComparisonDto",
    },
    {
      img: Altitude,
      text: "Altitude",
      containerId: 16,
      containerName: "altitude",
      FlagName: "altitudeComparisonDto",
    },
    {
      img: System_st,
      text: "System St...",
      containerId: 17,
      containerName: "systemState",
      FlagName: "systemStateComparisonDto",
    },
    {
      img: Operating,
      text: "Operating...",
      containerId: 18,
      containerName: "operating",
      FlagName: "operatingStateComparisonDto",
    },
    {
      img: Customer,
      text: "Customer...",
      containerId: 19,
      containerName: "customer",
      FlagName: "customerComplaintComparisonDto",
    },
  ];

  if (
    location.pathname ===
      "/vp-saved-filter/comparision/GeneratedDashboard/prognostics/cwv" ||
    location.pathname ===
      "/vp-saved-filter/comparision/GeneratedDashboard/prognostics/cwf"
  ) {
    Data.push({
      img: Rul,
      text: "RUL",
      // handleClick: "/vehicle-population/prognostic/rul",
      containerId: 20,
      containerName: "rul",
      FlagName: "rulComparisonDto",
    });
  }

  useEffect(() => {
    setrightMenuConatinerSmall(smallRightMenuContainer);
  }, [smallRightMenuContainer]);

  return (
    <>
      {selectedNumber === 4 ? (
        <div className="right-vehicle-container-icon">
          {Data.map((Value) => (
            <RightVehiclePagesCompareWithCardIcon
              key={Value.containerId}
              img={Value.img}
              containerId={Value.containerId}
              isSelected={selectedNumber === Value.containerId}
              handleClick={handleClick}
              pathName={pathName}
              dashboardType={dashboardType}
            />
          ))}
        </div>
      ) : (
        <div className="right-vehicle-container">
          {Data.map((Value) => (
            <RightVehiclePagesCompareWithCardDiagnostic
              key={Value.containerId}
              img={Value.img}
              text={Value.text}
              containerId={Value.containerId}
              isSelected={selectedNumber === Value.containerId}
              handleClick={handleClick}
              pathName={pathName}
              dashboardType={dashboardType}
              FlagName={Value.FlagName}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default RightVPCompareCards;
