import { mainAxiosInstance } from "../../../../services/service";
import { ServiceConfig } from "../../../../services/service-constants";

export const getActions = async (payload) => {
  const url = `${ServiceConfig.mod_prescribed_actions}`;
  const reqBody = {
    vin_number: payload.vinNumber,
    organization_id: payload.organization,
    stats_date: payload.statsdate,
    dtc_code: payload.dtcCode,
  };
  return mainAxiosInstance
    .get(url, {
      params: reqBody,
    })
    .then((response) => {
      if (response.status === 200) {
        return {
          code: response.status,
          data: response.data,
        };
      }
      if (response.status === 401) {
        return {
          code: response.status,
          data: response.data.detail || "You are not authorized",
        };
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        return {
          code: err.response.status,
          data: err.response.data.detail || "You are not authorized",
        };
      } else {
        return {
          code: "error",
          data: err.response.data.detail || "Something went wrong",
        };
      }
    });
};
