export const Strings = {
  notificationTableColumnHead: ["Dtc", "Description", "Date", "View Details"],
};

export const colorMap = {
  yellow: "#EEC85C",
  green: "#93B452",
  red: "#E45060",
  blue: "",
  pink: "#DD359A",
  cyan: "#09A1A1",
};

export const gaugeSelectStyles = {
  container: {
    minWidth: "118px",
  },
  headingContainer: {
    background: "none",
    boxShadow: "none",
    border: "none",
  },
  selectedKey: {
    fonttSize: "14px",
  },
  selected: {
    fonttSize: "12px",
  },
  selectOptionContainer: {
    width: "135px",
    boxShadow:
      "0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08)",
  },
};

const getLabelDistane = (height, width) => {
  if (height < 252) {
    return -40;
  }
  if (width < 270 && height < 252) {
    return -40;
  } else if (width < 540 && height < 252) {
    return -40;
  } else if (width < 270 && height < 502) {
    return -40;
  } else if (width < 540 && height < 502) {
    return -80;
  } else if (width < 808 && height < 502) {
    return -100;
  } else if (width < 1077 && height < 502) {
    return -120;
  } else if (width > 1077 && height > 752) {
    return -120;
  }
};

// Chart options
export const getChartOptions = ({
  height,
  width,
  minValue,
  maxValue,
  tickRangeArray,
  plotBandsArray,
  currentValue,
  unit,
  chartText,
}) => {
  return {
    chart: {
      type: "gauge",
      height: height || "250px",
      width: width || "100%",
      plotBackgroundColor: null,
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false,
      backgroundColor: null,
    },
    colors: ["#95c792", "green"],
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    pane: {
      center: ["50%", "65%"],
      size: width > 300 && height < 300 ? 268 / 1.3 : width / 1.3,
      startAngle: -95,
      endAngle: 95,
      background: [
        {
          backgroundColor: "#EAEEF2",
          innerRadius: "90%",
          outerRadius: "100%",
          shape: "arc",
        },
      ],
    },
    // tooltip: {
    //   backgroundColor: "#000",
    //   style: {
    //     color: "#FFFFFF",
    //     font: "12px Open Sans",
    //     enabled: false,
    //   },
    // },
    tooltip: {
      enabled: false
    },
    yAxis: {
      min: minValue,
      max: maxValue,
      tickPositions: tickRangeArray,
      lineColor: null,
      minorTickWidth: 0,
      tickWidth: 0,
      tickLength: 0,
      labels: {
        step: 1,
        rotation: "x",
        distance: getLabelDistane("10px", width),
        useHTML: true,
        // y: 10,
      },
      title: { text: null },
      plotBands: plotBandsArray,
    },
    plotOptions: {
      gauge: {
        dial: {
          radius: "40%",
          backgroundColor: "#000000",
          baseWidth: 14,
          topWidth: 0,
          baseLength: "3%", // of radius
          rearLength: "0%",
        },
        pivot: {
          radius: 7,
          backgroundColor: "#000000",
        },
      },
    },
    series: [
      {
        data: [currentValue ? currentValue : 0],
        dataLabels: {
          borderWidth: 0,
          allowOverlap: true,
          useHTML: true,
          borderRadius: 20,
          format: `<div style="position:relative;top:25px;right:10px"> <p style="font-family: sans-serif; font-size: 12px;color: rgb(53, 53, 54);font-weight: 500;display: flex;align-items: center;justify-content: center;width: 194px;">${chartText} (${unit})</p></div>`,
        },
      },
    ],
  };
};

export const layoutObj = [
  {
    w: 3,
    h: 1,
    x: 0,
    y: 0,
    i: "0",
  },
  {
    w: 3,
    h: 1,
    x: 3,
    y: 0,
    i: "1",
  },
  {
    w: 6,
    h: 1,
    x: 6,
    y: 0,
    i: "2",
  },
  {
    w: 3,
    h: 1,
    x: 0,
    y: 1,
    i: "3",
  },
  {
    w: 3,
    h: 1,
    x: 3,
    y: 1,
    i: "4",
  },
  {
    w: 6,
    h: 1,
    x: 6,
    y: 1,
    i: "9",
  },
  {
    w: 3,
    h: 1,
    x: 0,
    y: 2,
    i: "5",
  },
  {
    w: 3,
    h: 1,
    x: 3,
    y: 1,
    i: "6",
  },
  {
    w: 3,
    h: 1,
    x: 0,
    y: 1,
    i: "7",
  },
  {
    w: 3,
    h: 1,
    x: 3,
    y: 1,
    i: "8",
  },
];

export const filterOptions = [
  {
    displayname: "Fuel Level",
    id: "fuel_level",
    isChecked: true,
    key: "fuelLevelData",
    i: "0",
  },
  {
    displayname: "Engine Speed",
    id: "engine_speed",
    isChecked: true,
    key: "engineSpeedData",
    i: "1",
  },
  {
    displayname: "Gas Pedal Position",
    id: "gas_pedal_position",
    isChecked: true,
    key: "gasPedalData",
    i: "2",
  },
  {
    displayname: "Manifold Air Pressure",
    id: "manifold_air_pressure",
    isChecked: true,
    key: "airPressureData",
    i: "3",
  },
  {
    displayname: "Transmission Oil Pressure",
    id: "transmission_oil_pressure",
    isChecked: true,
    key: "oilPressureData",
    i: "4",
  },
  {
    displayname: "Brake Pedal Position ",
    id: "brake_pedal_position",
    isChecked: true,
    key: "brakePedalData",
    i: "5",
  },
  {
    displayname: "Engine Load",
    id: "engine_load",
    isChecked: true,
    key: "engineLoadData",
    i: "6",
  },
  {
    displayname: "Engine Oil Pressure",
    id: "engine_oil_pressure",
    isChecked: true,
    key: "engineOilData",
    i: "7",
  },
  {
    displayname: "Engine Coolant Temperature",
    id: "engine_coolant_temperature",
    isChecked: true,
    key: "engineCoolantData",
    i: "8",
  },
  {
    displayname: "Tire Pressure ",
    id: "tire_pressure",
    isChecked: true,
    key: "tirePressureData",
    i: "9",
  },
];

// const fuelLevelObj = {
//   id: "fuel_level",
//   component: (
//     <FuelLevelWidget
//       height={getSize(0, "h")}
//       width={getSize(0, "w")}
//       fuelLevelData={allChartData?.fuelLevelAggregatorDto}
//     />
//   ),
// };

// const engineSpeedObj = {
//   id: "engine_speed",
//   component: (
//     <EngineSpeedWidget
//       height={getSize(1, "h")}
//       width={getSize(1, "w")}
//       engineSpeedData={allChartData?.engineSpeedRpmAggregatorDto}
//     />
//   ),
// };

// const gasPedalPositionObj = {
//   id: "gas_pedal_position",
//   component: (
//     <GasPedalPositionWidget
//       height={getSize(2, "h")}
//       width={getSize(2, "w")}
//       gasPedalData={allChartData?.gasPedalPositionSensorDtos}
//     />
//   ),
// };

// const manifoldAirPressureObj = {
//   id: "manifold_air_pressure",
//   component: (
//     <ManifoldAirPressureWidget
//       height={getSize(3, "h")}
//       width={getSize(3, "w")}
//       airPressureData={allChartData?.manifoldAirPressureAggregatorDto}
//     />
//   ),
// };

// const transmissionOilPressureObj = {
//   id: "transmission_oil_pressure",
//   component: (
//     <TransmissionOilPressure
//       height={getSize(4, "h")}
//       width={getSize(4, "w")}
//       oilPressureData={allChartData?.transmissionOilPressureAggregatorDto}
//     />
//   ),
// };

// const engineLoadObj = {
//   id: "engine_load",
//   component: (
//     <EngineLoadWidget
//       height={getSize(5, "h")}
//       width={getSize(5, "w")}
//       engineLoadData={allChartData?.engineLoadAggregatorDto}
//     />
//   ),
// };

// const engineOilPressureObj = {
//   id: "engine_oil_pressure",
//   component: (
//     <EngineOilPressureWidget
//       height={getSize(6, "h")}
//       width={getSize(6, "w")}
//       engineOilData={allChartData?.engineOilPressurePsiAggregatorDto}
//     />
//   ),
// };

// const engineCoolantTemperatureObj = {
//   id: "engine_coolant_temperature",
//   component: (
//     <EngineCoolantTemperatureWidget
//       height={getSize(7, "h")}
//       width={getSize(7, "w")}
//       engineCoolantData={allChartData?.engineCoolantTemperatureAggregatorDto}
//     />
//   ),
// };

// const tirePressureObj = {
//   id: "tire_pressure",
//   component: (
//     <TirePressureWidget
//       height={getSize(8, "h")}
//       width={getSize(8, "w")}
//       tirePressureData={allChartData?.tirePressurePsiAggregatorDto}
//     />
//   ),
// };

// const brakePedalPositionObj = {
//   id: "Brake Pedal Position",
//   component: (
//     <BrakePedalPositionChart
//       height={getSize(9, "h")}
//       width={getSize(9, "w")}
//       brakePedalData={allChartData?.brakePedalPositionDtos}
//     />
//   ),
// };
