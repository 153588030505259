import React, { useState, useEffect, useLayoutEffect } from "react";
//import mapDataApi from "./MapApi";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "../../../pages/VehiclePopulation/VehiclePopulation.scss";

require("highcharts/modules/map")(Highcharts);

const MyMap = ({ chartData, mapData }) => {
  const [mapOptions, setMapOptions] = useState(null);
  const [formApi, setformApi] = useState(chartData);

  const fetchData = () => {
    const options = {
      title: {
        text: "",
        style: {
          color: "#fff",
        },
      },
      chart: {
        backgroundColor: "transparent",
        type: "map",
        map: null,
        width: 400,
        height: 400,
      },
      mapNavigation: {
        enabled: true,
        enableButtons: false,
      },
      credits: {
        enabled: false,
      },
      colorAxis: {
        dataClasses: [
          {
            from: 0,
            to: 40,
            color: "#92C5DE",
          },
          {
            from: 41,
            to: 70,
            color: "#038CCA",
          },
          {
            from: 71,
            to: 90,
            color: "#055377",
          },
        ],
      },
      tooltip: {
        pointFormatter: function () {
          var code = this.name;
          var match = chartData?.find(function (d) {
            return d.name === code;
          });
          var details = match ? match.details : "";
          var detailsHtml = "";
          for (var i = 0; i < details.length; i++) {
            if (i > 0 && i % 6 === 0) {
              detailsHtml += "<br>";
            }
            detailsHtml += details[i];
          }
          return detailsHtml + "</div><br>" + this.name;
        },
        split: true,
      },
      legend: false,
      series: [
        {
          name: "",
          dataLabels: {
            enabled: true,
            color: "#000000",
            format: "{point.postal-code}",
            style: {
              textTransform: "uppercase",
              fontWeight: "700",
            },
          },
          cursor: "pointer",
          joinBy: "postal-code",
          data: [],
          point: {
            events: {
              click: function (r) {
                console.log(r);
              },
            },
          },
        },
      ],
    };

    options.series[0].data = [];
    options.chart.map = mapData;

    for (let i in mapData.features) {
      let mapInfo = mapData.features[i];

      if (mapInfo.id) {
        var postalCode = mapInfo.properties["postal-code"];
        var name = mapInfo.properties["name"];
        var value =
          chartData && Array.isArray(chartData)
            ? chartData
                .filter((state) => state.name === name)
                .map((val) => val.totalCount)
            : [];
        var type = value;
        var row = i;
        options.series[0].data.push({
          value: value,
          name: name,
          "postal-code": postalCode,
          row: row,
          type: type,
        });
      }
    }

    setMapOptions(options);
  };

  useEffect(() => {
    if (mapData !== null) {
      fetchData();
    }
  }, [chartData, mapData]);

  return (
    <>
      <div style={{ position: "relative", bottom: "30px" }}>
        {true ? (
          <HighchartsReact
            highcharts={Highcharts}
            constructorType={"mapChart"}
            options={mapOptions}
          />
        ) : (
          ""
        )}
      </div>
      <div></div>
    </>
  );
};

export default MyMap;
