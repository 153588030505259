import { useState, useEffect } from "react";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import "../../scss/_common.scss";
import Datepicker from "./datepicker";

const Daterangepicker = (props) => {
  const { customInput, startDate, handleOnChange, endDate } = props;
  const [fromDate, setFromDate] = useState(new Date());
  const [fromInputDate, setFromInputDate] = useState("");
  const [toInputDate, setToInputDate] = useState("");
  const [toDate, setToDate] = useState(new Date());
  const today = new Date();

  useEffect(() => {
    setFromDate(startDate || new Date());
    setFromInputDate(startDate || new Date());
    setToInputDate(endDate || new Date());
    setToDate(endDate || new Date());
  }, []);

  useEffect(() => {
    const returnData = [fromDate, toDate];
    handleOnChange(returnData);
  }, [fromDate, toDate]);

  return (
    <div className="date-picker">
      <Datepicker
        selected={fromDate}
        onChange={(fromDate) => setFromDate(fromDate)}
        maxDate={today}
        placeholderText={"mm/dd/yyyy"}
      />
      <p style={{ paddingLeft: "10px", paddingRight: "10px" }}>to</p>
      <Datepicker
        selected={toDate}
        onChange={(toDate) => setToDate(toDate)}
        maxDate={today}
        placeholderText={"mm/dd/yyyy"}
      />
    </div>
  );
};

export default Daterangepicker;
