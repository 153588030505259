import React, { useEffect } from "react";
import { NavLink, Outlet, useNavigate, useLocation } from "react-router-dom";
import TabLayout from "../../../components/Layout/TabLayout";
import "./index.scss";

const RootCauseData = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname.includes("/root-cause-data") &&
      !location.pathname.includes("/root-cause-data/")
    ) {
      navigate("./data-visualization", { replace: true });
    }
  }, [location.pathname]);

  const navLink = [
    { label: "Data Visualization", to: "./data-visualization" },
    { label: "Is - Is Not Analysis", to: "./is-isnot-analysis" },
    { label: " Ishikawa Diagram", to: "./ishikawa-diagram" },
  ];
  
  return (
    <div className="full-width diagnostics-container">
      {/* <div className="tabs-container">
        <NavLink
          to="./data-visualization"
          className={({ isActive }) =>
            isActive ? "btn-primary tabs" : "btn-secondary tabs"
          }
        >
          Data Visualization
        </NavLink>
        <NavLink
          to="./is-isnot-analysis"
          className={({ isActive }) =>
            isActive ? "btn-primary tabs" : "btn-secondary tabs"
          }
        >
          Is - Is Not Analysis
        </NavLink>
        <NavLink
          to="./ishikawa-diagram"
          className={({ isActive }) =>
            isActive ? "btn-primary tabs" : "btn-secondary tabs"
          }
        >
          Ishikawa Diagram
        </NavLink>
      </div>
      <Outlet /> */}
      <TabLayout tabLink={navLink} />
    </div>
  );
};

export default RootCauseData;
