import "./selfTest.scss";
import Select from "../../common/Select/Select";
import { useEffect, useState } from "react";
import {
  selfTestEndPoints,
  ServiceConfig,
} from "../../../services/service-constants";
import { mainAxiosInstance } from "../../../services/service";
import selfTestIcon from "../../../assets/self-test.svg";
import { getSelectedFilter_SelfTest } from "../../../store/actions/fetchActions";
import Modal from "../../common/Modal/Modal";
import DTCDetails from "../Diagnostics/DTCDetails/index";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Feedback from "../../../components/common/ErrorComponents/Feedback";
import NotAuthorized from "../../../components/common/ErrorComponents/NotAuthorized";
import LottieLoad from "../../../components/common/ErrorComponents/LottieLoad";

const SelfTest = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const filterOptions = [
    "Key-On-Engine-Off(KOEO)",
    "Key-On-Engine-Running(KOER)",
  ];
  const vin_Number = useSelector((state) => state.mainReducer.vin_Number);
  const FilterSelector = useSelector((state) => state.mainReducer.selected_Filter_SelfTest);
  const [selectedFilter, setSelectedFilter] = useState(FilterSelector);
  const [preTestResult, setPreTestResult] = useState([]);
  const [postTestResult, setPostTestResult] = useState([]);
  const [mainResult, setMainResult] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedDTC, setSelectedDTC] = useState("");
  const [selectedStatsDate, setSelectedStatsDate] = useState("");
  const [noDataFound, setNoDataFound] = useState(true);
  const [errorMsg, setErrorMsg] = useState(true);
  const [authenticationMsg, setAuthenticationMsg] = useState(false);
  const changeFilter = (val) => {
    if (val === "Key-On-Engine-Off(KOEO)") {
      setSelectedFilter("KOEO");
    } else if (val === "Key-On-Engine-Running(KOER)") {
      setSelectedFilter("KOER");
    } else {
      setSelectedFilter("KOEO");
    }
  };

  const getData = (dataSet, type) => {
    let result = [];
    let preResult = [];
    let postResult = [];
    if (selectedFilter === "KOEO") {
      result = dataSet.koeos.reduce((acc, data) => {
        const temp = {
          code: data.dtc || data.dtcCode,
          date: data.statsDate,
        };
        acc.push(temp);
        return acc;
      }, []);

      preResult = dataSet.koeoPreTestResults.reduce((acc, data) => {
        const temp = {
          code: data.dtc || data.dtcCode,
          date: data.statsDate,
        };
        acc.push(temp);
        return acc;
      }, []);

      postResult = dataSet.koeoPostTestResults.reduce((acc, data) => {
        const temp = {
          code: data.dtc || data.dtcCode,
          date: data.statsDate,
        };
        acc.push(temp);
        return acc;
      }, []);
    } else {
      result = dataSet.koer.reduce((acc, data) => {
        const temp = {
          code: data.dtc || data.dtcCode,
          date: data.statsDate,
        };
        acc.push(temp);
        return acc;
      }, []);

      preResult = dataSet.koerPreTestResults.reduce((acc, data) => {
        const temp = {
          code: data.dtc || data.dtcCode,
          date: data.statsDate,
        };
        acc.push(temp);
        return acc;
      }, []);

      postResult = dataSet.koerPostTestResults.reduce((acc, data) => {
        const temp = {
          code: data.dtc || data.dtcCode,
          date: data.statsDate,
        };
        acc.push(temp);
        return acc;
      }, []);
    }
    setPreTestResult(preResult);
    setPostTestResult(postResult);
    setMainResult(result);
  };

  const getResults = (val) => {
    let url = "";
    dispatch(getSelectedFilter_SelfTest(selectedFilter));
    if (selectedFilter === "KOEO") {
      url = `${ServiceConfig.self_test}${selfTestEndPoints.koeo_result}`;
    } else {
      url = `${ServiceConfig.self_test}${selfTestEndPoints.koer_result}`;
    }
    const params = {
      vin_number: val.vin_Number,
      organization_id: 1,
    };


    mainAxiosInstance
      .get(url, { params: params })
      .then((response) => {

        if (response.status === 200) {
          setNoDataFound(false);
          getData(response.data.response);
        }
        if (response.status === 401) {
          setErrorMsg(response.data?.details || "You are not authorized.");
          setAuthenticationMsg(true)

        }

      })
      .catch((err) => {
        if (err.response.status === 401) {
          setAuthenticationMsg(true)
          setErrorMsg(err.response.data?.details || "You are not authorized.");

        }
        else {
          setNoDataFound(true);
          setErrorMsg(false);
        };
      });
  };

  useEffect(() => {
    getResults({
      vin_Number: vin_Number,
    });
  }, [selectedFilter]);

  const getDefaultSelected = () => {
    let selected = "";
    if (selectedFilter === "KOEO") {
      selected = filterOptions[0];
    } else {
      selected = filterOptions[1];
    }
    return selected;
  };

  const handleClick = (dtc) => {
    setSelectedDTC(dtc.code);
    setSelectedStatsDate(dtc.date);
    setShowModal(true);
  };

  const goToComponentSubSystemHistory = (data) => {
    navigate("./component-system-history/diagnostics", {
      relative: "route",
      state: { subSystem: data.subSystem, date: data.date },
    });
  };

  return (
    <>
      {!noDataFound && (
        <div className="page-wrapper self-test-container">
          <div className="select-container-holder" >
            <Select
              options={filterOptions}
              defaultSelected={getDefaultSelected}
              handleOptionClick={changeFilter}
              // preIcon={selfTestIcon}
            ></Select>
          </div>
          <div className="flex sp-bw">
            <div className="self-test-card-container">
              <div className="self-test-heading">Pre Test Results</div>
              <div className="self-test-card-box">
                <div className="self-test-card-wrapper">
                  {preTestResult.map(
                    (val) =>
                      val && (
                        <div
                          className="self-test-card"
                          onClick={() => handleClick(val)}
                        >
                          {val.code}
                        </div>
                      )
                  )}
                </div>
              </div>
            </div>
            <div className="self-test-card-container">
              <div className="self-test-heading">{selectedFilter}</div>
              <div className="self-test-card-box">
                <div className="self-test-card-wrapper">
                  {mainResult.map(
                    (val) =>
                      val && (
                        <div
                          className="self-test-card"
                          onClick={() => handleClick(val)}
                        >
                          {val.code}
                        </div>
                      )
                  )}
                </div>
              </div>
            </div>
            <div className="self-test-card-container">
              <div className="self-test-heading">Post Test Results</div>
              <div className="self-test-card-box">
                <div className="self-test-card-wrapper">
                  {postTestResult.map(
                    (val) =>
                      val && (
                        <div
                          className="self-test-card"
                          onClick={() => handleClick(val)}
                        >
                          {val.code}
                        </div>
                      )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showModal && (
        <Modal
          type={"large"}
          title={"DTC Details"}
          wrapperClass="main-diagnostics-modal"
          titlePosition={"center"}
          isError={false}
          handleClose={() => setShowModal(false)}
        >
          <DTCDetails
            dtcValue={selectedDTC}
            statsDate={selectedStatsDate}
            handleClose={() => setShowModal(false)}
            handleClick={goToComponentSubSystemHistory}
          />
        </Modal>
      )}
      <>
        {noDataFound && (!errorMsg) && (
          <div>
            <Feedback />
          </div>
        )}
        {authenticationMsg && (
          <div>
            < NotAuthorized title={errorMsg} />
          </div>
        )}
        {noDataFound && (!authenticationMsg) && (errorMsg) && (
          <div>
            <LottieLoad />

          </div>
        )}
      </>
    </>
  );
};

export default SelfTest;
