import { useEffect } from "react";
import Highcharts from "highcharts";
import "./RangeChart.scss";
import { useSelector } from "react-redux";

// import { engineSpeedMile, engineSpeedLastTrip } from "../../../_mock/vehicle-usage-profile/engineSpeed";

export const BarChart = ({
  id,
  data,
  title,
  yLabel,
  onClickHandler,
  fromVpUp = false,
}) => {
  const isMenuExpanded = useSelector(
    (state) => state.mainReducer.isSidemenuExpanded
  );

  useEffect(() => {
    renderChart(id, data, title, yLabel);
  }, [data, isMenuExpanded]);

  const getyAxisTitle = (chartData, yLabel) => {
    return chartData?.[0]?.data?.length < 1
      ? {
          text: null,
        }
      : {
          text: `<div class="y-axis-legend"><div>${yLabel}</div><div class="y-axis-arrow"></div></div>`,
          style: {
            color: "#353536",
            lineHeight: 1,
            textAlign: "right",
            font: "10px Open Sans",
            letterSpacing: "1px",
            fontWeight: 600,
          },
          align: "high",
          useHTML: true,
          margin: 20,
        };
  };

  const getxAxisTitle = (chartData, title) => {
    return chartData?.[0]?.data?.length < 1
      ? {
          text: null,
        }
      : {
          text: title,
          style: {
            color: "#000000",
            lineHeight: 1,
            textAlign: "right",
            font: "12px Open Sans",
            fontWeight: 600,
          },
          useHTML: true,
          margin: 20,
        };
  };

  const renderChart = (id, chartData, title, yLabel) => {
    Highcharts.chart(
      id,
      {
        chart: {
          backgroundColor: "#FFFFFF",
          type: "column",
          height: 300,
          borderRadius: 10,
          spacing: [20, 20, 20, 20],
          // width: isMenuExpanded ? 410 : 510,
          // borderColor: "#CECECE",
          // borderWidth: 1,
          borderRadius: 20,
        },
        title: {
          text: null,
        },
        yAxis: {
          title: getyAxisTitle(chartData, yLabel),
          margin: 60,
          gridLineColor: "#F2F2F2",
          tickAmount: 8,
          labels: {
            enabled: false,
          },
        },
        xAxis: {
          categories: data?.[0]?.categories,
          title: getxAxisTitle(chartData, title),
          margin: 60,
          labels: {
            enabled: false,
          },
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          backgroundColor: "#000",
          style: {
            color: "#FFFFFF",
            font: "12px Open Sans",
          },

          // formatter: function () {
          //   if (fromVpUp) {
          //     let points = this.points.map(function (point) {
          //       if (point.series.index === 0) {
          //         return point.series.name + ": " + point.y;
          //       } else {
          //         return point.y;
          //       }
          //     });
          //     return (
          //       "<b>" +
          //       this.x +
          //       "</b><br/>" +
          //       points[0] +
          //       "(" +
          //       points[2] +
          //       "," +
          //       points[1] +
          //       ")" +
          //       "</b><br/>" +
          //       "Iqr : " +
          //       this.points[0].point.options.iqr +
          //       "</b><br/>" +
          //       "Standard Deviation : " +
          //       this.points[0].point.options.standardDeviation +
          //       "</b><br/>" +
          //       "Range : " +
          //       this.points[0].point.options.range +
          //       "</b><br/>" +
          //       "Variance : " +
          //       this.points[0].point.options.variance
          //     );
          //   } else {
          //     return this.series.name + ": " + this.y;
          //   }
          // },
          // shared: fromVpUp,

          formatter: function () {
            if (fromVpUp) {
              if (Object.keys(this.point.options).length > 1) {
                return (
                  "<b>" +
                  this.x +
                  "</b><br/>" +
                  this.series.name +
                  " : " +
                  this.y +
                  " (" +
                  this.point.options.minValue +
                  "," +
                  this.point.options.maxValue +
                  ")" +
                  "</b><br/>" +
                  "Iqr : " +
                  this.point.options.iqr +
                  "</b><br/>" +
                  "Standard Deviation : " +
                  this.point.options.standardDeviation +
                  "</b><br/>" +
                  "Range : " +
                  this.point.options.range +
                  "</b><br/>" +
                  "Variance : " +
                  this.point.options.variance
                );
              } else {
                return (
                  "<b>" +
                  this.x +
                  "</b><br/>" +
                  this.series.name +
                  " : " +
                  this.y
                );
              }
            } else {
              return this.series.name + ": " + Math.ceil(this.y);
            }
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
            pointWidth: 10,
          },
        },
        series: fromVpUp ? chartData?.[0]?.data : chartData,
      },
      function (chart) {
        // on complete
        const dataLen = chart.series?.[0]?.data?.length || 0;

        if (dataLen < 1) {
          chart.renderer
            .text("No Data Available", 140, 140)
            .css({
              color: "#000000",
              fontSize: "16px",
            })
            .add();
          chart.renderer
            .text( 150, 270)
            .css({
              color: "#000000",
              lineHeight: 1,
              textAlign: "right",
              font: "12px Open Sans",
              fontWeight: 600,
            })
            .add();
        }
      }
    );
  };

  return (
    <figure className="highcharts-figure" onClick={() => onClickHandler()}>
      <div id={id}></div>
    </figure>
  );
};
