import React, { useEffect, useState } from "react";
import { FishboneChart } from "react-fishbone-chart";
import { useDispatch, useSelector } from "react-redux";
import ValueChip from "../../../../components/common/ValueChip/ValueChip";
import { mainAxiosInstance } from "../../../../services/service";
import { ServiceConfig } from "../../../../services/service-constants";
import { addRootCausePayload } from "../../../../store/rootCauseAnalysis/actions";
import "./index.scss";
import { getPotentialRootCauseData, prepareIshikawaData } from "./utils";
import Feedback from "../../../../components/common/ErrorComponents/Feedback";
import NotAuthorized from "../../../../components/common/ErrorComponents/NotAuthorized";
import ChartLoader from "../../../../components/common/ErrorComponents/ChartLoader";
const IshikawaDiagram = () => {
  const dispatch = useDispatch();
  const vinNumber = useSelector((state) => state.mainReducer.vin_Number);
  let rcaPayload = useSelector(
    (state) => state.rootCauseReducer.rootCauseAnalysisPayload
  );

  if (!rcaPayload || rcaPayload === {}) {
    rcaPayload = JSON.parse(
      window.localStorage.getItem("root-cause-payload") || {}
    );
    dispatch(addRootCausePayload({ ...rcaPayload }));
  }
  const [data, setData] = useState(null);
  const [noDataFound, setNoDataFound] = useState(true);
  const [possibleCauses, setPossibleCauses] = useState([]);
  const [notAuthorized, setNotAuthorized] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const prepareData = (response) => {
    const data = prepareIshikawaData(response.data);
    setData({ ...data });
    setPossibleCauses(getPotentialRootCauseData(response.data));
  };

  const getData = (vin, oId, dcode, stats) => {
    let url = `${ServiceConfig.mod_ishikawa_diagram}`;
    let params = {
      vin_number: vinNumber,
      organization_id: oId,
      stats_date: stats,
      dtc_code: dcode,
    };
    mainAxiosInstance
      .get(url, { params: params })
      .then((response) => {
        if (response.status === 200) {
          prepareData(response);
          setNoDataFound(false);
        }
        if (response.status === 401) {
          setErrorMsg(response.data.detail || "You are not authorized");
          setNotAuthorized(true);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setNotAuthorized(true);
          setErrorMsg(err.response.data?.details || "You are not authorized.");
        } else {
          setApiError(true);
          setErrorMsg(err.response.data.details || "No records Found");
          setNoDataFound(true);
        }
      });
  };
  useEffect(() => {
    if (
      rcaPayload.vin &&
      rcaPayload.oId &&
      rcaPayload.dcode &&
      rcaPayload.stats
    ) {
      getData(
        rcaPayload.vin,
        rcaPayload.oId,
        rcaPayload.dcode,
        rcaPayload.stats
      );
    }
  }, []);
  if (notAuthorized) {
    return <NotAuthorized title={errorMsg} />;
  }
  if (apiError) {
    return <Feedback />;
  }

  return (
    <>
      {!noDataFound && possibleCauses.length > 0 && (
        <>
          <div className="possible-cause-wrapper">
            <div className="problem">Potential Causes</div>
            <ul className="problem-list-ul">
              {possibleCauses.map((elem) => {
                let cause = `${elem.primaryCause} -> ${elem.secondaryCause}`;
                if (elem.tertiaryCause) {
                  cause += ` -> ${elem.tertiaryCause}`;
                }
                return (
                  <li className="problem-list">
                    <ValueChip
                      text={cause}
                      showClose={false}
                      handleRemove={() => {}}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </>
      )}
      {!noDataFound && (
        <div className="ishikawa-wrapper">
          <div className="ishikawa-diagram">
            {data && <FishboneChart data={{ ...data }} />}
          </div>
        </div>
      )}
      {noDataFound && (
        <div>
          <ChartLoader />
        </div>
      )}
    </>
  );
};

export default IshikawaDiagram;
