import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
//import "./WarrantyManagement.scss";
import "../../../WarrantyManagement/WarrantyManagement.scss";

const VPWarrantyManagementPrognostic = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/vehicle-population/vehicle-population-warranty-management/prognostics") {
      navigate("/vehicle-population/vehicle-population-warranty-management/prognostics/top-10-DTC-complaints", {
        replace: true,
      });
    }
  }, [location.pathname]);

  return (
    <div className="full-width diagnostics-container">
    <div className="sec-tabs-container">
      <NavLink
        to="/vehicle-population/vehicle-population-warranty-management/prognostics/top-10-DTC-complaints"
        className={({ isActive }) =>
          isActive ? "btn-primary-sec-tabs" : "btn-secondary-sec-tabs"
        }
      >
        Top 10 DTC Complaints
      </NavLink>
      <NavLink
        to="/vehicle-population/vehicle-population-warranty-management/prognostics/top-10-non-dtc-complaints"
        className={({ isActive }) =>
          isActive ? "btn-primary-sec-tabs" : "btn-secondary-sec-tabs"
        }
      >
        Top 10 Non-DTC Complaints
      </NavLink>
    </div>
    <Outlet />
  </div>
  );
};

export default VPWarrantyManagementPrognostic;
