import { useEffect } from "react";
import Highcharts from "highcharts";
import "../../../../common/LineGraph/index.scss";
import arrowYAxis from "../../../../../assets/arrow-yaxis.svg";
import arrowXAxis from "../../../../../assets/arrow-xaxis.svg";
import { useSelector } from "react-redux";

/** Extension component of Line graph
 *  Style dependent on line Graph
 *
 */

export const RemoteTestingGraph = ({
  id,
  data,
  title,
  xLabel,
  onClickHandler,
  plotXLines,
  plotYLines,
  xAxisData,
  yAxisLabel,
  showLegend = false,
}) => {
  const isSidemenuExpanded = useSelector(
    (state) => state.mainReducer.isSidemenuExpanded
  );

  useEffect(() => {
    renderChart(data, title, xLabel, plotXLines, plotYLines, yAxisLabel);
  }, [data, isSidemenuExpanded]);

  const getyAxisTitle = (chartData, yAxisLabel) => {
    const yLabel = yAxisLabel ?? chartData?.[0]?.name;
    return chartData?.length < 1
      ? {
          text: null,
        }
      : {
          text: `<div class="y-axis-legend"><div>${yLabel}</div><img src='${arrowYAxis}'/></div>`,
          style: {
            color: "#353536",
            lineHeight: 1,
            textAlign: "right",
            font: "10px Open Sans",
            letterSpacing: "1px",
            width: "300px",
          },
          align: "high",
          useHTML: true,
          margin: 20,
        };
  };

  const getxAxisTitle = (chartData, xLabel) => {
    return chartData?.length < 1
      ? {
          text: null,
        }
      : {
          text: `<div class="x-axis-legend"><div>${xLabel}</div><img src='${arrowXAxis}'/></div>`,
          style: {
            color: "#353536",
            lineHeight: 1,
            textAlign: "right",
            font: "10px Open Sans",
            letterSpacing: "1px",
          },
          align: "high",
          useHTML: true,
          margin: 20,
        };
  };

  const renderChart = (
    chartData,
    title,
    xLabel,
    plotXLines,
    plotYLines,
    yAxisLabel
  ) => {
    if (chartData && Array.isArray(chartData) && chartData.length) {
      Highcharts.chart(
        id,
        {
          chart: {
            type: "spline",
            backgroundColor: "#FFFFFF",
            spacing: [40, 30, 10, 5],
            height: 350,
            borderColor: "#CECECE",
            borderWidth: 1,
            borderRadius: 20,
          },
          title: {
            text: title,
            verticalAlign: "bottom",
            style: {
              fontSize: "12px",
              fontWeight: "600",
              fill: "#000",
            },
          },

          yAxis: {
            title: getyAxisTitle(chartData, yAxisLabel),
            labels: {
              style: {
                color: "#353536",
                lineHeight: 1,
                font: "10px Open Sans",
                letterSpacing: "1px",
              },
            },
            gridLineColor: "#CECECE",
            plotLines: plotYLines
              ? [
                  {
                    color: chartData?.[1]?.color,
                    value: chartData?.[1]?.data?.[1]?.y,
                    width: 2,
                    dashStyle: chartData?.[1]?.dashStyle,
                  },
                ]
              : [],
          },
          xAxis: {
            type: "category",
            title: getxAxisTitle(xAxisData ? xAxisData : chartData, xLabel),
            labels: {
              style: {
                color: "#353536",
                lineHeight: 1,
                font: "10px Open Sans",
                letterSpacing: "1px",
              },
            },
            plotLines: plotXLines
              ? [
                  {
                    color: chartData?.[0]?.color,
                    value: chartData?.[1]?.data?.[0]?.x,
                    width: 2,
                    dashStyle: chartData?.[1]?.dashStyle,
                  },
                  {
                    color: chartData?.[0]?.color,
                    value: chartData?.[1]?.data?.[1]?.x,
                    width: 2,
                    dashStyle: chartData?.[1]?.dashStyle,
                  },
                ]
              : [],
          },
          legend: {
            enabled: showLegend,
            itemWidth: 150,
            itemStyle: {
              color: "#6b6b6b",
              fontSize: "10px",
            },
          },
          credits: {
            enabled: false,
          },
          tooltip: {
            backgroundColor: "#000",
            style: {
              color: "#FFFFFF",
              font: "12px Open Sans",
            },
          },
          plotOptions: {
            series: {
              marker: {
                enabled: false,
                states: {
                  hover: {
                    enabled: true,
                  },
                },
              },
              line: {
                label: {
                  enabled: true,
                  formatter: `Hello`,
                },
              },
            },
          },
          series: plotXLines || plotYLines ? [chartData[0]] : chartData,
        },
        function (chart) {
          // on complete
          const dataLen = chart.series?.[0]?.data?.length || 0;
          if (dataLen < 1) {
            // check series is empty
            chart.renderer
              .text("No Data Available", 50, 125)
              .css({
                color: "#000000",
                fontSize: "16px",
              })
              .add();
          }
        }
      );
    }
  };

  return (
    <figure className="highcharts-figure" onClick={() => onClickHandler()}>
      <div id={id}></div>
      {/* <div className="remote-graph-name">{title}</div> */}
    </figure>
  );
};
