import React, { useEffect } from "react";
import { NavLink, Outlet, useNavigate, useLocation } from "react-router-dom";
import TabLayout from "../../components/Layout/TabLayout";

import "./index.scss";

const OperationalAnalytics = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/operational-analytics") {
      navigate("/operational-analytics/driver-behaviour", { replace: true });
    }
  }, [location.pathname]);
  const navLink = [
    {
      label: "Driver Behaviour",
      to: "/operational-analytics/driver-behaviour",
    },
    { label: "Fuel Economy", to: "/operational-analytics/fuel-economy" },
  ];
  return (
    <div className=" maintenance-log-summary-wrapper tab-wrap">
      <TabLayout tabLink={navLink} />
    </div>
  );
};

export default OperationalAnalytics;
