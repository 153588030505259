import React, { useCallback, useEffect } from "react";
import "./index.scss";
import openEyeIcon from "../../../assets/eye-open.svg";
import closeEyeIcon from "../../../assets/eye-close.svg";

const Input = ({
  type,
  value,
  placeholder,
  handleOnChange,
  handleOnKeyPress,
  onBlur,
  onFocus,
  htmlForId,
  error,
  onKeyDown,
  size,
  maxLength = 40,
}) => {
  useEffect(() => {
    if (document.getElementById(`${htmlForId}`).type === "password") {
      document
        .getElementById(`hideicon__${htmlForId}`)
        .setAttribute("style", "display:block");
      document
        .getElementById(`showicon__${htmlForId}`)
        .setAttribute("style", "display:none");
    }
  }, []);

  const clicked = useCallback((event) => {
    if (document.getElementById(`${htmlForId}`).type === "password") {
      document.getElementById(`${htmlForId}`).setAttribute("type", "text");
      document
        .getElementById(`hideicon__${htmlForId}`)
        .setAttribute("style", "display:none");
      document
        .getElementById(`showicon__${htmlForId}`)
        .setAttribute("style", "display:block");
    } else {
      document.getElementById(`${htmlForId}`).setAttribute("type", "password");
      document
        .getElementById(`hideicon__${htmlForId}`)
        .setAttribute("style", "display:block");
      document
        .getElementById(`showicon__${htmlForId}`)
        .setAttribute("style", "display:none");
    }
    document.getElementById(`${htmlForId}`).focus();
  }, []);

  return (
    <>
      <div className="input__container">
        <div className="input__field">
          <input
            value={value}
            placeholder={placeholder}
            onChange={handleOnChange}
            onKeyPress={handleOnKeyPress}
            onBlur={onBlur}
            onFocus={onFocus}
            type={type}
            id={htmlForId}
            name={htmlForId}
            onKeyDown={onKeyDown}
            size={size}
            maxLength={maxLength}
          />
          {type === "password" ? (
            <>
              <div className="eye-icon">
                <img
                  id={`hideicon__${htmlForId}`}
                  onClick={clicked}
                  src={closeEyeIcon}
                  alt="close eye icon"
                />
                <img
                  id={`showicon__${htmlForId}`}
                  onClick={clicked}
                  src={openEyeIcon}
                  alt="close eye icon"
                  className="open-eye-icon"
                />
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      {error && (
        <div className="input__container-error-msg">
          <span>{error}</span>
        </div>
      )}
    </>
  );
};

export default Input;
