import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import TabLayout from "../../components/Layout/TabLayout";
import "./WarrantyManagement.scss";

const WarrantyManagement = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/warranty-management") {
      navigate("/warranty-management/diagnostics", {
        replace: true,
      });
    }
  }, [location.pathname]);
  const navLink = [
    {
      label: "Diagnostics",
      to: "/warranty-management/diagnostics",
    },
    { label: "Prognostics", to: "/warranty-management/prognostics" },
    { label: "Warranty Rate", to: "/warranty-management/warranty-rate" },
  ];
  return (
    <div className="full-width warranty-management-container">
      <TabLayout tabLink={navLink} />
    </div>
  );
};

export default WarrantyManagement;
