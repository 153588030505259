import React, { useEffect, useState, useContext } from "react";
import { NavLink, Outlet, useNavigate, useLocation } from "react-router-dom";
import "../../VehiclePopulation.scss";
//import geoMapping from "../../assets/geo-spatial-mapping-green.svg";

import RightVPCompareCards from "../components/RightVPCompareCard";
import { GeneratedDashboardCard } from "../components/GeneratedDashboardCard";
import DataContext from "../../../../utils/dataContext";
import { repeat } from "lodash";
import { useSelector } from "react-redux";
import { Button } from "../../../../components/common/Button/Button";
import geoMapping from "../../assets/geo-spatial-mapping-blue.svg";

const GeneratedDashboard = () => {
  const [blinkData, setBlinkData] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const smallRightMenuContainer = localStorage.getItem("VPRightMenuContainer");
  const firstfilter = useSelector((state) => state.mainReducer.first_filter);
  const secondfilter = useSelector((state) => state.mainReducer.second_filter);
  const vinfilter = useSelector((state) => state.mainReducer.vin_filter);
  const displayCard = location.search.includes("?key=3");
  const [showDetails, setShowDetails] = useState(false);

  const url = window.location.href;
  const urlParams = new URLSearchParams(url.split("?")[1]);
  const [containerName, setContainerName] = useState(
    urlParams.get("containerId")
  );

  const [dashboardType, setDashboardType] = useState(null); //cwf
  const [selectedNumber, setSelectedNumber] = useState(0);
  const pathName = location.pathname.includes("diagnostics")
    ? "diagnostics"
    : "prognostics";
  const [pathKey, setPathKey] = useState("");

  useEffect(() => {
    if (firstfilter === null) {
      navigate(`/vehicle-population`, {
        replace: true,
      });
    }
  }, [firstfilter]);

  const handleCardClick = (containerId) => {
    if (selectedNumber !== containerId) {
      setSelectedNumber(containerId);
    }
  };

  useEffect(() => {
    setSelectedNumber(parseInt(location.search.slice(5)));
  }, [location.search]);

  useEffect(() => {
    if (
      location.pathname ===
      `/vp-saved-filter/comparision/GeneratedDashboard/prognostics`
    ) {
      setDashboardType(vinfilter !== null ? "cwv" : "cwf");
      setSelectedNumber(0);
      setPathKey(location.key);
    } else if (
      location.pathname ===
      `/vp-saved-filter/comparision/GeneratedDashboard/diagnostics`
    ) {
      setDashboardType(vinfilter !== null ? "cwv" : "cwf");
      setSelectedNumber(0);
      setPathKey(location.key);
    }
  }, [location.pathname, pathName, vinfilter]);

  useEffect(() => {
    if (dashboardType !== null && pathName !== null) {
      navigate(
        `/vp-saved-filter/comparision/GeneratedDashboard/${pathName}/${dashboardType}?key=0`,
        {
          replace: true,
          state: {
            diagnosesState: pathName === "diagnostics" ? 0 : 1,
          },
        }
      );
    }
  }, [pathKey]);
  useEffect(() => {
    if (vinfilter !== null) {
      setDashboardType("cwv");
    } else {
      setDashboardType("cwf");
    }
  }, [vinfilter]);

  const filteredFilterData = (key) => {
    if (location.pathname.includes("diagnostics")) {
      return (
        key !== "id" &&
        key !== "userRole" &&
        key !== "organizationId" &&
        key !== "filterName" &&
        key !== "filterType" &&
        key !== "username" &&
        key !== "lastUpdated" &&
        key !== "rul" &&
        key !== "type" &&
        key !== "fromMiles" &&
        key !== "fromRulEngineRunTime" &&
        key !== "fromRulHours" &&
        key !== "fromRulKeyStarts" &&
        key !== "fromRulMiles" &&
        key !== "toMiles" &&
        key !== "toRulEngineRunTime" &&
        key !== "toRulHours" &&
        key !== "toRulKeyStarts" &&
        key !== "toRulMiles" &&
        key !== "module" &&
        key !== "fromRulHours" &&
        key !== "fromRulEngineRunTime" &&
        key !== "toRulEngineRunTime" &&
        key !== "toRulHours" &&
        key !== "toRulKeyStarts" &&
        key !== "fromRulKeyStarts"
      );
    } else {
      return (
        key !== "id" &&
        key !== "userRole" &&
        key !== "organizationId" &&
        key !== "filterName" &&
        key !== "filterType" &&
        key !== "username" &&
        key !== "lastUpdated" &&
        key !== "rul" &&
        key !== "fromMiles" &&
        key !== "toMiles" &&
        key !== "type" &&
        key !== "module"
      );
    }
  };

  return (
    <DataContext.Provider value={{ myData: blinkData, setBlinkData }}>
      <div className="vehicle-population-container">
        <div className="top-button-container-comparing">
          {firstfilter && Object.keys(firstfilter).length > 0 && (
            <div className="inp">
              <div
                className="display-selected-data"
                title={Object.keys(firstfilter)
                  .filter(
                    (key) =>
                      filteredFilterData(key) && firstfilter[key] !== null
                  )
                  .map((key) => key.charAt(0).toUpperCase() + key.slice(1))
                  .join("_")}
              >
                {Object.keys(firstfilter)
                  .filter(
                    (key) =>
                      filteredFilterData(key) && firstfilter[key] !== null
                  )
                  .map((key) => key.charAt(0).toUpperCase() + key.slice(1))
                  .join("_")}
              </div>
              <div className="display-selected-data-title">Population 1</div>
            </div>
          )}

          {secondfilter && Object.keys(secondfilter).length > 0 && (
            <div className="inp">
              <div
                className="display-selected-data grey"
                title={Object.keys(secondfilter)
                  .filter(
                    (key) =>
                      filteredFilterData(key) && secondfilter[key] !== null
                  )
                  .map((key) => key.charAt(0).toUpperCase() + key.slice(1))
                  .join("_")}
              >
                {Object.keys(secondfilter)
                  .filter(
                    (key) =>
                      filteredFilterData(key) && secondfilter[key] !== null
                  )
                  .map((key) => key.charAt(0).toUpperCase() + key.slice(1))
                  .join("_")}
              </div>
              <div className="display-selected-data-title">Population 2</div>
            </div>
          )}
          {vinfilter && (
            <div className="inp">
              <div className="display-selected-data grey">{vinfilter}</div>
              <div className="display-selected-data-title">Vin Number</div>
            </div>
          )}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              //  width: "75%",
              width: selectedNumber === 4 ? "95%" : "75%",
            }}
          >
            <div className="Top-container">
              <GeneratedDashboardCard
                backgroundColor="#E3F5E3"
                text="Top 10 DTC  "
                graphContainer="Up-down"
                containerId={0}
                handleClick={handleCardClick}
                isSelected={selectedNumber === 0}
                pathName={pathName}
                dashboardType={dashboardType}
              />

              <GeneratedDashboardCard
                backgroundColor="#E3F5E3"
                text="Top 10 Non-DTC"
                secondText=""
                graphContainer="Up-down"
                containerId={1}
                handleClick={handleCardClick}
                isSelected={selectedNumber === 1}
                pathName={pathName}
                dashboardType={dashboardType}
              />
              <GeneratedDashboardCard
                backgroundColor="#E3F5E3"
                text="% of population "
                secondText="setting DTC"
                thirdText="(By Mileage)"
                handleClick={handleCardClick}
                graphContainer="Down-up"
                containerId={2}
                isSelected={selectedNumber === 2}
                pathName={pathName}
                dashboardType={dashboardType}
              />

              <GeneratedDashboardCard
                backgroundColor="#E3F5E3"
                text="DTC Warranty "
                secondText="Scorecard"
                graphContainer="Up-down"
                containerId={3}
                handleClick={handleCardClick}
                isSelected={selectedNumber === 3}
                pathName={pathName}
                dashboardType={dashboardType}
              />

              <GeneratedDashboardCard
                img={geoMapping}
                backgroundColor="#E3F5E3"
                text="Geo-Spatial"
                secondText="mapping"
                containerId={4}
                handleClick={handleCardClick}
                isSelected={selectedNumber === 4}
                pathName={pathName}
                dashboardType={dashboardType}
              />
            </div>
            <div>
              <Outlet />
            </div>
          </div>
          <RightVPCompareCards
            handleClick={handleCardClick}
            pathName={pathName}
            dashboardType={dashboardType}
            selectedNumber={selectedNumber}
          />
        </div>
        <div
          id="for-warrantyscorecard"
          style={{ display: `${displayCard ? "" : "none"}` }}
        >
          <Button
            title={showDetails ? "Hide Details" : "Show Details"}
            handleClick={() => setShowDetails(!showDetails)}
            styleClasses="showdetails"
          />
          {showDetails && (
            <>
              <div className="mainTable-container">
                <div className="firstTable">
                  <table>
                    <thead>
                      <tr>
                        <th colspan="2">DTC Warranty Scorecard Factors</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Failure Reports:</td>
                        <td>20%</td>
                      </tr>
                      <tr>
                        <td>Failure Rate:</td>
                        <td>20%</td>
                      </tr>
                      <tr>
                        <td>Repeat Failures</td>
                        <td>20%</td>
                      </tr>
                      <tr>
                        <td>Cost</td>
                        <td>5%</td>
                      </tr>
                      <tr>
                        <td>Severity</td>
                        <td>20%</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="bmt-text">
                    <p>Total</p>
                    <p>85%</p>
                  </div>
                </div>

                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ display: "flex" }}>
                    <div className="secondTable" style={{ marginLeft: "5px" }}>
                      <table>
                        <thead>
                          <tr>
                            <th colspan="2">Failure reports</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>0</td>
                            <td>0</td>
                          </tr>
                          <tr>
                            <td>1-10</td>
                            <td>2</td>
                          </tr>
                          <tr>
                            <td>11-15</td>
                            <td>4</td>
                          </tr>
                          <tr>
                            <td>16-20</td>
                            <td>6</td>
                          </tr>
                          <tr>
                            <td>21-30</td>
                            <td>8</td>
                          </tr>
                          <tr>
                            <td>&gt;31</td>
                            <td>10</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="thirdTable" style={{ marginLeft: "5px" }}>
                      <table>
                        <thead>
                          <tr>
                            <th colspan="2">Cost</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>0</td>
                            <td>0</td>
                          </tr>
                          <tr>
                            <td>1-10</td>
                            <td>2</td>
                          </tr>
                          <tr>
                            <td>11-15</td>
                            <td>4</td>
                          </tr>
                          <tr>
                            <td>16-20</td>
                            <td>6</td>
                          </tr>
                          <tr>
                            <td>21-30</td>
                            <td>8</td>
                          </tr>
                          <tr>
                            <td>&gt;31</td>
                            <td>10</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="fourthTable" style={{ marginLeft: "5px" }}>
                      <table>
                        <thead>
                          <tr>
                            <th colspan="2">Severity</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Poor Performance</td>
                            <td>2</td>
                          </tr>
                          <tr>
                            <td>Intermittent</td>
                            <td>4</td>
                          </tr>
                          <tr>
                            <td>Limp home mode</td>
                            <td>6</td>
                          </tr>
                          <tr>
                            <td>Customer stranded</td>
                            <td>8</td>
                          </tr>
                          <tr>
                            <td>Safety/Emissions</td>
                            <td>10</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div>
                    <div className="btmText">
                      <p>Repeat Failure</p>
                      <p className="equalsTo1">=</p>
                      <p className="formula1">
                        Failure Reports /Vehicles affected
                      </p>
                    </div>
                    <div className="btmText">
                      <p>Failure Rate</p>
                      <p>=</p>
                      <p className="formula2">Vehicles affected/Population </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </DataContext.Provider>
  );
};

export default GeneratedDashboard;
