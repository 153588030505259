import React from "react";

const TopBarheaderCard = ({
  icon,
  text,
  secondtext,
  handleOnClick,
  status,
}) => {
  var active = localStorage.getItem("NavigationMenType");
  return (
    <div
      className={`${active === status ? `header-Card-active` : "header-Card"} `}
      onClick={handleOnClick}
    >
      <img src={icon} alt="hc" />
      <h6>
        {text}
        <br />
        {secondtext}
      </h6>
    </div>
  );
};

export default TopBarheaderCard;
