import Details from "../components/Details";
import VehicleHealthCheck from "../components/VehicleHealthCheck/index";
import VehicleUsageProfile from "../pages/VehicleUsageProfile/VehicleUsageProfile";
import Diagnostics from "../components/VehicleHealthCheck/Diagnostics/Diagnostics";
import Home from "../components/Home";
import SelfTest from "../components/VehicleHealthCheck/SelfTest/SelfTest";
import DigitalTwin from "../components/VehicleHealthCheck/DigitalTwin/digitalTwin";
import PerformanceDigitalTwin from "../components/VehicleHealthCheck/DigitalTwin/PerformanceDigitalTwin/index";
import ComponentSubSystem from "../components/VehicleHealthCheck/DigitalTwin/ComponentSubSystem";
import OnBoard from "../components/VehicleHealthCheck/Diagnostics/OnBoard/OnBoard";
import MaintenanceLogs from "../pages/MaintenanceLogs";
import FaultTrendAnalytics from "../pages/FaultTrendAnalytics/FaultTrendAnalytics";
import FaultAnalytics from "../pages/FaultTrendAnalytics/index";
import MaintenanceOnDemand from "../pages/MaintenanceOnDemand/index";
import RoutineMaintenance from "../pages/MaintenanceOnDemand/RoutineMaintenance/RoutineMaintenance";
import MaintenanceDiagnostics from "../pages/MaintenanceOnDemand/MaintenanceDiagnostics/index";
import MaintenancePrognostics from "../pages/MaintenanceOnDemand/MaintenancePrognostics";
import VehicleOverview from "../pages/VehicleOverview";
import VehicleInformation from "../pages/VehicleOverview/VehicleInformation/VehicleInformation";
import VehicleNotification from "../pages/VehicleOverview/VehicleNotfication/VehicleNotification";
import TimeDrivenAction from "../pages/MaintenanceLogs/MaintenanceLogsSummary/Time Driven Action";
import MaintenanceOnDemandLogs from "../pages/MaintenanceLogs/MaintenanceLogsSummary/Maintenance On-Demand";
import Quality from "../pages/MaintenanceLogs/MaintenanceLogsSummary/Quality";
import Location from "../pages/MaintenanceLogs/MaintenanceLogsSummary/Location";
import OperationalAnalytics from "../pages/Operational Analytics";
import DriverBehaviour from "../pages/Operational Analytics/Driver Behaviour";
import WarrantyDiagnostics from "../pages/WarrantyManagement/Diagnostics/Diagnostics";
import WarrantyPrognostics from "../pages/WarrantyManagement/Prognostics/Prognostics";
import WarrantyManagement from "../pages/WarrantyManagement";
import TopDiagonasticDtcComplaints from "../pages/WarrantyManagement/Diagnostics/TopDtcComplaints/TopDtcComplaints";
import WMReports from "../pages/WarrantyManagement/Diagnostics/TopDtcComplaints/WMReports";
import WMCost from "../pages/WarrantyManagement/Diagnostics/TopDtcComplaints/WMCost";
import WMSeverity from "../pages/WarrantyManagement/Diagnostics/TopDtcComplaints/WMSeverity";
import WMRiskAnalysis from "../pages/WarrantyManagement/Diagnostics/TopDtcComplaints/WMRiskAnalysis";
import WMScoreCard from "../pages/WarrantyManagement/Diagnostics/TopDtcComplaints/WMScoreCard";
import WMScoreCardNonDtc from "../pages/WarrantyManagement/Diagnostics/TopNonDtcComplaints/WMScoreCard";
import WMReportsNonDtc from "../pages/WarrantyManagement/Diagnostics/TopNonDtcComplaints/WMReports";
import WMCostNonDtc from "../pages/WarrantyManagement/Diagnostics/TopNonDtcComplaints/WMCost";
import WMSeverityNonDtc from "../pages/WarrantyManagement/Diagnostics/TopNonDtcComplaints/WMSeverity";
import WMRiskAnalysisNonDtc from "../pages/WarrantyManagement/Diagnostics/TopNonDtcComplaints/WMRiskAnalysis";
import WMPrognosticReports from "../pages/WarrantyManagement/Prognostics/TopDtcComplaints/WMPrognosticReport";
import WMPrognosticCost from "../pages/WarrantyManagement/Prognostics/TopDtcComplaints/WMPrognosticCost";
import WMPrognosticSeverity from "../pages/WarrantyManagement/Prognostics/TopDtcComplaints/WMPrognosticSeverity";
import WMPrognosticRiskAnalysis from "../pages/WarrantyManagement/Prognostics/TopDtcComplaints/WMPrognosticRiskAnalysis";
import WMPrognosticScoreCard from "../pages/WarrantyManagement/Prognostics/TopDtcComplaints/WMPrognosticScoreCard";
import WMPrognosticNonReports from "../pages/WarrantyManagement/Prognostics/TopNonDtcComplaints/WMPrognosticReport";
import WMPrognosticNonCost from "../pages/WarrantyManagement/Prognostics/TopNonDtcComplaints/WMPrognosticCost";
import WMPrognosticNonSeverity from "../pages/WarrantyManagement/Prognostics/TopNonDtcComplaints/WMPrognosticSeverity";
import WMPrognosticNonRiskAnalysis from "../pages/WarrantyManagement/Prognostics/TopNonDtcComplaints/WMPrognosticRiskAnalysis";
import WMPrognosticNonScoreCard from "../pages/WarrantyManagement/Prognostics/TopNonDtcComplaints/WMPrognosticScoreCard";
import TopDiagonasticDtcComplaintsLayout from "../pages/WarrantyManagement/Diagnostics/TopDtcComplaints";
import TopDiagonasticNonDtcComplaints from "../pages/WarrantyManagement/Diagnostics/TopNonDtcComplaints/TopNonDtcComplaints";
import TopPrognosticDtcComplaints from "../pages/WarrantyManagement/Prognostics/TopDtcComplaints/TopDtcComplaints";
import TopPrognosticNonDtcComplaints from "../pages/WarrantyManagement/Prognostics/TopNonDtcComplaints/TopNonDtcComplaints";
import WarrantyRate from "../pages/WarrantyManagement/WarrantyRate/WarrantyRate";
import RootCauseAnalysis from "../pages/RootCauseAnalysis";
import RemoteTesting from "../components/VehicleHealthCheck/Diagnostics/RemoteTesting/RemoteTesting";
import VehicleRealTimeRefactor from "../pages/VehicleOverview/VehicleRealTimeData/VehicleRealTimeRefactor";
import VibrationalAnalytics from "../components/VehicleHealthCheck/Diagnostics/VibrationalAnalytics/VibrationalAnalytics";
import RemotePerformanceProfile from "../components/VehicleHealthCheck/Diagnostics/RemoteTesting/RemotePerformanceProfile";
import RemoteAbsDiagram from "../components/VehicleHealthCheck/Diagnostics/RemoteTesting/RemoteAbsDiagram";
import DashBoard from "../pages/Dashboard";
import ArchitectureServiceGuide from "../pages/MaintenanceOnDemand/ServiceGuide/Diagnostics/Architecture";
import UserManualServiceGuide from "../pages/MaintenanceOnDemand/ServiceGuide/Diagnostics/UserManual";
import AugmentedRealityServiceGuide from "../pages/MaintenanceOnDemand/ServiceGuide/Diagnostics/AugmentedReality";
import YoutubeVideosServiceGuide from "../pages/MaintenanceOnDemand/ServiceGuide/Diagnostics/YoutubeVideos";
import SocialMediaServiceGuide from "../pages/MaintenanceOnDemand/ServiceGuide/Diagnostics/SocialMedia";
import PictorialRepresentation from "../pages/Operational Analytics/PictorialRepresentation";
import ComponentSystemHistoryDiagnostics from "../components/ComponentSystemHistory/Diagnostics";
import ComponentSystemHistoryPrognostics from "../components/ComponentSystemHistory/Prognostics";
import ComponentSystemHistoryMaintenanceProfile from "../components/ComponentSystemHistory/MaintenanceProfile";
import ComponentSystemHistoryMaintenanceHistory from "../components/ComponentSystemHistory/MaintenanceHistory";
import ComponentSystemHistoryUsageProfile from "../components/ComponentSystemHistory/UsageProfile";
import ComponentSystemHistoryServiceConditions from "../components/ComponentSystemHistory/ServiceConditions";
import FuelEconomy from "../pages/Operational Analytics/FuelEconomy";
import IshikawaDiagram from "../pages/RootCauseAnalysis/RootCauseData/IshikawaDiagram";
import IsIsNotAnalysis from "../pages/RootCauseAnalysis/RootCauseData/IsIsNotAnalysis";
import DataVisualization from "../pages/RootCauseAnalysis/RootCauseData/DataVisualization";
import VehiclePopulation from "../pages/VehiclePopulation";
import ReportForDiagnostics from "../pages/VehiclePopulation/ReportForDiagnostic/ReportForDiagnostic";
import ReportForPrognostic from "../pages/VehiclePopulation/ReportForPrognostic/ReportForPrognostic";
import DiagnosticsReportsForTop10Dtc from "../pages/VehiclePopulation/ReportForDiagnostic/Top10Dtc/Top10Dtc";
import DiagnosticsReportsForTop10NonDtc from "../pages/VehiclePopulation/ReportForDiagnostic/Top10NonDtc/Top10NonDtc";
import DiagnosticsReportsForPopulationSettingDtc from "../pages/VehiclePopulation/ReportForDiagnostic/PopulationSettingDtc/PopulationSettingDtc";
import DiagnosticsReportsForWarrentyScoreCared from "../pages/VehiclePopulation/ReportForDiagnostic/WarrantyScoreCard/WarrantyScoreCard";
import DiagnosticsReportsForGeoSpatialMapping from "../pages/VehiclePopulation/ReportForDiagnostic/GeoSpatial/GeoSpatial";
import VPRightContainerPagesComponent from "../pages/VehiclePopulation/components/VPRightContainerPagesComponent";
import PrognosticsReportsForTop10Dtc from "../pages/VehiclePopulation/ReportForPrognostic/Top10Dtc/Top10Dtc";
import PrognosticsReportsForTop10NonDtc from "../pages/VehiclePopulation/ReportForPrognostic/Top10NonDtc/Top10NonDtc";
import PrognosticsReportsForPopulationSettingDtc from "../pages/VehiclePopulation/ReportForPrognostic/PopulationSettingDtc/PopulationSettingDtc";
import PrognosticsReportsForWarrentyScoreCared from "../pages/VehiclePopulation/ReportForPrognostic/WarrantyScoreCard/WarrantyScoreCard";
import PrognosticsReportsForGeoSpatialMapping from "../pages/VehiclePopulation/ReportForPrognostic/GeoSpatial/GeoSpatial";
import RUL from "../pages/VehiclePopulation/ReportForPrognostic/Rul/Rul";
import ErrorPage from "../components/common/ErrorComponents/ErrorPage";
import FormLayout from "../pages/VehiclePopulation/components/FormLayout";
import SavedFilters from "../pages/VehiclePopulation/SavedFilters";
import SavedFiltersDashboard from "../pages/VehiclePopulation/SavedFilters/SavedFiltersDashboard";
import Comparision from "../pages/VehiclePopulation/Comparision";
import GeneratedDashboardComp from "../pages/VehiclePopulation/Comparision/GeneratedDashboard";
import CompareWithVin from "../pages/VehiclePopulation/Comparision/GeneratedDashboard/CompareWithVin";
import CompareWithFilter from "../pages/VehiclePopulation/Comparision/GeneratedDashboard/CompareWithFilter";
import ConfigureFeature from "../pages/AdminDashboard/ConfigureFeatures/index";
import VPUsageProfile from "../pages/VehiclePopulation/UsageProfile";
import VPUPReportForDiagnostic from "../pages/VehiclePopulation/UsageProfile/ReportForDiagnostic";
import VPUPReportForPrognostic from "../pages/VehiclePopulation/UsageProfile/ReportForPrognostic";
import PocDigitalTwin from "../components/VehicleHealthCheck/PocDigitalTwin/PocDigitalTwin";
import VPUsageProfileCompare from "../pages/VehiclePopulation/UsageProfileComparision";
import UPCompareWithVin from "../pages/VehiclePopulation/UsageProfileComparision/CompareWithVin";
import UPCompareWithFilter from "../pages/VehiclePopulation/UsageProfileComparision/CompareWithFilter";
import VPWarrantyManagement from "../pages/VehiclePopulation/WarrantyManagement";
import WMDiagnostic from "../pages/VehiclePopulation/WarrantyManagement/Diagonstic/Diagnostic";
import VpTopDtcNonComplaints from "../pages/VehiclePopulation/WarrantyManagement/Diagonstic/Top10NonDtcComplaints/Top10NonDtcComplaints";
import VPTopDtcComplaints from "../pages/VehiclePopulation/WarrantyManagement/Diagonstic/Top10DtcComplaints/Top10DtcComplaints";
import WMPrognostic from "../pages/VehiclePopulation/WarrantyManagement/Prognostic/Prognostic";
import VPTopDtcComplaintsPrognostic from "../pages/VehiclePopulation/WarrantyManagement/Prognostic/Top10DtcComplaints/Top10DtcComplaints";
import VpTopDtcNonComplaintsPrognostic from "../pages/VehiclePopulation/WarrantyManagement/Prognostic/Top10NonDtcComplaints/Top10NonDtc";
import VPWMCost from "../pages/VehiclePopulation/WarrantyManagement/Diagonstic/Top10DtcComplaints/VPWMCost";
import VPWMReports from "../pages/VehiclePopulation/WarrantyManagement/Diagonstic/Top10DtcComplaints/VPWMReports";
import VPWMRiskAnalysis from "../pages/VehiclePopulation/WarrantyManagement/Diagonstic/Top10DtcComplaints/VPWMAnalysis";
import VPWMSeverity from "../pages/VehiclePopulation/WarrantyManagement/Diagonstic/Top10DtcComplaints/VPWMSeverity";
import VPWMScoreCard from "../pages/VehiclePopulation/WarrantyManagement/Diagonstic/Top10DtcComplaints/VPWMScoreCard";
import VPWMCustomerAffected from "../pages/VehiclePopulation/WarrantyManagement/Diagonstic/Top10DtcComplaints/VPWMCustomerAffected";
import VPWMCostNonDtc from "../pages/VehiclePopulation/WarrantyManagement/Diagonstic/Top10NonDtcComplaints/VPWMCost";
import VPWMCustomerAffectedNonDtc from "../pages/VehiclePopulation/WarrantyManagement/Diagonstic/Top10NonDtcComplaints/VPWMCustomerAffected";
import VPWMReportsNonDtc from "../pages/VehiclePopulation/WarrantyManagement/Diagonstic/Top10NonDtcComplaints/VPWMReports";
import VPWMSeverityNonDtc from "../pages/VehiclePopulation/WarrantyManagement/Diagonstic/Top10NonDtcComplaints/VPWMSeverity";
import VPWMScoreCardNonDtc from "../pages/VehiclePopulation/WarrantyManagement/Diagonstic/Top10NonDtcComplaints/VPWMScoreCard";
import VPWMRiskAnalysisNonDtc from "../pages/VehiclePopulation/WarrantyManagement/Diagonstic/Top10NonDtcComplaints/VPWMRiskAnalysis";
import VPWMPrognosticCost from "../pages/VehiclePopulation/WarrantyManagement/Prognostic/Top10DtcComplaints/VPWMPrognosticCost";
import VPWMPrognosticCustomerAffected from "../pages/VehiclePopulation/WarrantyManagement/Prognostic/Top10DtcComplaints/VPWMPrognosticCustomerAffected";
import VPWMPrognosticReports from "../pages/VehiclePopulation/WarrantyManagement/Prognostic/Top10DtcComplaints/VPWMPrognosticReports";
import VPWMPrognosticRiskAnalysis from "../pages/VehiclePopulation/WarrantyManagement/Prognostic/Top10DtcComplaints/VPWMPrognosticRiskAnalysis";
import VPWMPrognosticScoreCard from "../pages/VehiclePopulation/WarrantyManagement/Prognostic/Top10DtcComplaints/VPWMPrognosticScoreCard";
import VPWMPrognosticSeverity from "../pages/VehiclePopulation/WarrantyManagement/Prognostic/Top10DtcComplaints/VPWMPrognosticSeverity";
import VPWMPrognosticNonDtcRiskAnalysis from "../pages/VehiclePopulation/WarrantyManagement/Prognostic/Top10NonDtcComplaints/VPWMPognosticRickAnalysis";
import VPWMPrognosticNonDtcCost from "../pages/VehiclePopulation/WarrantyManagement/Prognostic/Top10NonDtcComplaints/VPWMPrognosticCost";
import VPWMPrognosticNonDtcCustomerAffected from "../pages/VehiclePopulation/WarrantyManagement/Prognostic/Top10NonDtcComplaints/VPWMPrognosticCustomerAffected";
import VPWMPrognosticNonDtcReports from "../pages/VehiclePopulation/WarrantyManagement/Prognostic/Top10NonDtcComplaints/VPWMPrognosticReports";
import VPWMPrognosticNonDtcScoreCard from "../pages/VehiclePopulation/WarrantyManagement/Prognostic/Top10NonDtcComplaints/VPWMPrognosticScoreCard";
import VPWMPrognosticNonDtcSeverity from "../pages/VehiclePopulation/WarrantyManagement/Prognostic/Top10NonDtcComplaints/VPWMPrognosticSeverity";
import VpHealthCheck from "../pages/VehiclePopulation/HealthCheck/index";
import VPHCReportForDiagnostic from "../pages/VehiclePopulation/HealthCheck/ReportForDiagnostic";
import VPHCReportForPrognostic from "../pages/VehiclePopulation/HealthCheck/ReportForPrognostic";
import VATables from "../components/VehicleHealthCheck/Diagnostics/VibrationalAnalytics/VATables";
import VibrationalAnalyticsIndex from "../components/VehicleHealthCheck/Diagnostics/VibrationalAnalytics";
import VPHealthCheckComparision from "../pages/VehiclePopulation/HealthCheck/HealthCheckComparision";
import VPCompareWithFilter from "../pages/VehiclePopulation/HealthCheck/HealthCheckComparision/CompareWithFilter";
import VPCompareWithVin from "../pages/VehiclePopulation/HealthCheck/HealthCheckComparision/CompareWithVin";

const RemoteTestingRoutes = [
  {
    breadcrumb: "Performance Profile",
    title: "Performance Profile",
    iconName: "",
    component: <RemotePerformanceProfile />,
    path: "/vehicle-health/diagnostics/remote-testing/performance-profile",
  },
  {
    breadcrumb: "ABS Diagram",
    title: "ABS Diagram",
    iconName: "",
    component: <RemoteAbsDiagram />,
    path: "/vehicle-health/diagnostics/remote-testing/abs-diagram",
  },
];

export const ReportForDiagnosticRoutes = [
  {
    component: <DiagnosticsReportsForTop10Dtc />,
    path: "/vehicle-population/diagnostic/top-10-dtc",
  },
  {
    component: <DiagnosticsReportsForTop10NonDtc />,
    path: "/vehicle-population/diagnostic/top-10-Non-dtc",
  },
  {
    component: <DiagnosticsReportsForPopulationSettingDtc />,
    path: "/vehicle-population/diagnostic/population-setting-dtc",
  },
  {
    component: <DiagnosticsReportsForWarrentyScoreCared />,
    path: "/vehicle-population/diagnostic/warranty-scorecard",
  },
  {
    component: <DiagnosticsReportsForGeoSpatialMapping />,
    path: "/vehicle-population/diagnostic/geo-spatial-mapping",
  },
  {
    component: <VPRightContainerPagesComponent />,
    path: "/vehicle-population/diagnostic/pages",
  },
];

export const ReportForPrognosticRoutes = [
  {
    component: <PrognosticsReportsForTop10Dtc />,
    path: "/vehicle-population/prognostic/top-10-dtc",
  },
  {
    component: <PrognosticsReportsForTop10NonDtc />,
    path: "/vehicle-population/prognostic/top-10-Non-dtc",
  },
  {
    component: <PrognosticsReportsForPopulationSettingDtc />,
    path: "/vehicle-population/prognostic/population-setting-dtc",
  },
  {
    component: <PrognosticsReportsForWarrentyScoreCared />,
    path: "/vehicle-population/prognostic/warranty-scorecard",
  },
  {
    component: <PrognosticsReportsForGeoSpatialMapping />,
    path: "/vehicle-population/prognostic/geo-spatial-mapping",
  },

  {
    component: <VPRightContainerPagesComponent />,
    path: "/vehicle-population/prognostic/pages",
  },

  {
    component: <RUL />,
    path: "/vehicle-population/prognostic/rul",
  },
];

const VehicleDiagnosticsRoutes = [
  {
    breadcrumb: "On Board",
    title: "On Board",
    iconName: "",
    component: <OnBoard />,
    path: "/vehicle-health/diagnostics/on-board",
  },
  {
    breadcrumb: "Remote Testing",
    title: "Remote Testing",
    iconName: "",
    component: <RemoteTesting />,
    path: "/vehicle-health/diagnostics/remote-testing",
    nestedRoutes: RemoteTestingRoutes,
  },
  {
    breadcrumb: "Vibrational Analytics",
    title: "Vibrational Analytics",
    iconName: "",
    component: <VibrationalAnalyticsIndex />,
    path: "/vehicle-health/diagnostics/vibrational-analytic",
  },
  {
    breadcrumb: "Vibrational Analytics",
    title: "Vibrational Analytics",
    iconName: "",
    component: <VibrationalAnalytics />,
    path: "/vehicle-health/diagnostics/vibrational-analytics",
  },
  {
    breadcrumb: "Vibrational Analytics",
    title: "Vibrational Analytics",
    iconName: "",
    component: <VibrationalAnalytics />,
    path: "/vehicle-health/diagnostics/vibrational-analytics/engine",
  },
  {
    breadcrumb: "Vibrational Analytics",
    title: "Vibrational Analytics",
    iconName: "",
    component: <VATables />,
    path: "/vehicle-health/diagnostics/vibrational-analytics/engine/combustion",
  },
];

const DigitalTwinRoutes = [
  {
    breadcrumb: "Performance Digital Twin",
    iconName: "",
    component: <PerformanceDigitalTwin />,
    path: "/vehicle-health/digital-twin/performance-digital-twin",
  },
  {
    breadcrumb: "Component Sub System",
    iconName: "",
    component: <ComponentSubSystem />,
    path: "/vehicle-health/digital-twin/component-sub-system",
  },
];

export const VehicleHealthCheckRoutes = [
  {
    breadcrumb: "Diagnostics",
    iconName: "",
    component: <Diagnostics />,
    path: "/vehicle-health/diagnostics",
    nestedRoutes: VehicleDiagnosticsRoutes,
  },
  {
    breadcrumb: "Self Test",
    iconName: "",
    component: <SelfTest />,
    path: "/vehicle-health/self-test",
  },
  {
    breadcrumb: "Digital Twin",
    iconName: "",
    component: <DigitalTwin />,
    path: "/vehicle-health/digital-twin",
    nestedRoutes: DigitalTwinRoutes,
  },
  {
    breadcrumb: " Poc Digital Twin",
    iconName: "",
    component: <PocDigitalTwin />,
    path: "/vehicle-health/poc-digital-twin",
    // nestedRoutes: DigitalTwinRoutes,
  },
];

export const WarrantyDiagnosticsRoutes = [
  {
    breadcrumb: "Top 10 DTC Complaints",
    title: "Top 10 DTC Complaints",
    iconName: "",
    component: <TopDiagonasticDtcComplaints />,
    path: "/warranty-management/diagnostics/top-10-DTC-complaints",
  },
  {
    breadcrumb: "Top 10 Non-DTC Complaints",
    title: "Top 10 Non-DTC Complaints",
    iconName: "",
    component: <TopDiagonasticNonDtcComplaints />,
    path: "/warranty-management/diagnostics/top-10-non-DTC-complaints",
  },
  {
    breadcrumb: "Top 10 DTC Complaints",
    title: "Reports",
    iconName: "",
    component: <WMReports />,
    path: "/warranty-management/diagnostics/top-10-DTC-complaints/reports",
  },
  {
    breadcrumb: "Top 10 DTC Complaints",
    title: "Cost",
    iconName: "",
    component: <WMCost />,
    path: "/warranty-management/diagnostics/top-10-DTC-complaints/cost",
  },
  {
    breadcrumb: "Top 10 DTC Complaints",
    title: "Severity",
    iconName: "",
    component: <WMSeverity />,
    path: "/warranty-management/diagnostics/top-10-DTC-complaints/severity",
  },
  {
    breadcrumb: "Top 10 DTC Complaints",
    title: "Risk Analysis and Action Plan",
    iconName: "",
    component: <WMRiskAnalysis />,
    path: "/warranty-management/diagnostics/top-10-DTC-complaints/risk-analysis",
  },
  {
    breadcrumb: "Top 10 DTC Complaints",
    title: "DTC Warranty Scorecard",
    iconName: "",
    component: <WMScoreCard />,
    path: "/warranty-management/diagnostics/top-10-DTC-complaints/score-card",
  },
  {
    breadcrumb: "Top 10 Non-DTC Complaints",
    title: "Reports",
    iconName: "",
    component: <WMReportsNonDtc />,
    path: "/warranty-management/diagnostics/top-10-non-DTC-complaints/reports",
  },
  {
    breadcrumb: "Top 10 Non-DTC Complaints",
    title: "Cost",
    iconName: "",
    component: <WMCostNonDtc />,
    path: "/warranty-management/diagnostics/top-10-non-DTC-complaints/cost",
  },
  {
    breadcrumb: "Top 10 Non-DTC Complaints",
    title: "Severity",
    iconName: "",
    component: <WMSeverityNonDtc />,
    path: "/warranty-management/diagnostics/top-10-non-DTC-complaints/severity",
  },
  {
    breadcrumb: "Top 10 Non-DTC Complaints",
    title: "Risk Analysis and Action Plan",
    iconName: "",
    component: <WMRiskAnalysisNonDtc />,
    path: "/warranty-management/diagnostics/top-10-non-DTC-complaints/risk-analysis",
  },
  {
    breadcrumb: "Top 10 Non-DTC Complaints",
    title: "DTC Warranty Scorecard",
    iconName: "",
    component: <WMScoreCardNonDtc />,
    path: "/warranty-management/diagnostics/top-10-non-DTC-complaints/score-card",
  },
];

export const DashBoardRoutes = [
  {
    breadcrumb: "",
    title: "",
    iconName: "",
    component: <DashBoard />,
    path: "/dashBoard",
  },
];

export const WarrantyPrognosticsRoutes = [
  {
    breadcrumb: "Top 10 DTC Complaints",
    title: "Top 10 DTC Complaints",
    iconName: "",
    component: <TopPrognosticDtcComplaints />,
    path: "/warranty-management/prognostics/top-10-DTC-complaints",
  },
  {
    breadcrumb: "Top 10 DTC Complaints",
    title: "Reports",
    iconName: "",
    component: <WMPrognosticReports />,
    path: "/warranty-management/prognostics/top-10-DTC-complaints/reports",
  },
  {
    breadcrumb: "Top 10 DTC Complaints",
    title: "Cost",
    iconName: "",
    component: <WMPrognosticCost />,
    path: "/warranty-management/prognostics/top-10-DTC-complaints/cost",
  },
  {
    breadcrumb: "Top 10 DTC Complaints",
    title: "Severity",
    iconName: "",
    component: <WMPrognosticSeverity />,
    path: "/warranty-management/prognostics/top-10-DTC-complaints/severity",
  },
  {
    breadcrumb: "Top 10 DTC Complaints",
    title: "Risk Analysis",
    iconName: "",
    component: <WMPrognosticRiskAnalysis />,
    path: "/warranty-management/prognostics/top-10-DTC-complaints/risk-analysis",
  },
  {
    breadcrumb: "Top 10 DTC Complaints",
    title: "DTC Warranty Scorecard",
    iconName: "",
    component: <WMPrognosticScoreCard />,
    path: "/warranty-management/prognostics/top-10-DTC-complaints/score-card",
  },
  {
    breadcrumb: "Top 10 Non-DTC Complaints",
    title: "Top 10 Non-DTC Complaints",
    iconName: "",
    component: <TopPrognosticNonDtcComplaints />,
    path: "/warranty-management/prognostics/top-10-non-DTC-complaints",
  },

  {
    breadcrumb: "Top 10 DTC Complaints",
    title: "Reports",
    iconName: "",
    component: <WMPrognosticNonReports />,
    path: "/warranty-management/prognostics/top-10-non-DTC-complaints/reports",
  },
  {
    breadcrumb: "Top 10 DTC Complaints",
    title: "Cost",
    iconName: "",
    component: <WMPrognosticNonCost />,
    path: "/warranty-management/prognostics/top-10-non-DTC-complaints/cost",
  },
  {
    breadcrumb: "Top 10 DTC Complaints",
    title: "Severity",
    iconName: "",
    component: <WMPrognosticNonSeverity />,
    path: "/warranty-management/prognostics/top-10-non-DTC-complaints/severity",
  },
  {
    breadcrumb: "Top 10 DTC Complaints",
    title: "Risk Analysis",
    iconName: "",
    component: <WMPrognosticNonRiskAnalysis />,
    path: "/warranty-management/prognostics/top-10-non-DTC-complaints/risk-analysis",
  },
  {
    breadcrumb: "Top 10 DTC Complaints",
    title: "Risk Analysis",
    iconName: "",
    component: <WMPrognosticNonScoreCard />,
    path: "/warranty-management/prognostics/top-10-non-DTC-complaints/score-card",
  },
];

export const VehiclePopulataionWarrantyPrognosticsRoutes = [
  {
    breadcrumb: "Top 10 DTC Complaints",
    title: "Top 10 DTC Complaints",
    iconName: "",
    component: <VPTopDtcComplaintsPrognostic />,
    path: "/vehicle-population/vehicle-population-warranty-management/prognostics/top-10-DTC-complaints",
  },
  {
    breadcrumb: "Top 10 Non-DTC Complaints",
    title: "Top 10 Non-DTC Complaints",
    iconName: "",
    component: <VpTopDtcNonComplaintsPrognostic />,
    path: "/vehicle-population/vehicle-population-warranty-management/prognostics/top-10-non-dtc-complaints",
  },
  {
    breadcrumb: "Top 10 DTC Complaints",
    title: "Reports",
    iconName: "",
    component: <VPWMPrognosticReports />,
    path: "/vehicle-population/vehicle-population-warranty-management/prognostics/top-10-DTC-complaints/reports",
  },
  {
    breadcrumb: "Top 10 DTC Complaints",
    title: "Cost",
    iconName: "",
    component: <VPWMPrognosticCost />,
    path: "/vehicle-population/vehicle-population-warranty-management/prognostics/top-10-DTC-complaints/cost",
  },
  {
    breadcrumb: "Top 10 DTC Complaints",
    title: "Customer Affected",
    iconName: "",
    component: <VPWMPrognosticCustomerAffected />,
    path: "/vehicle-population/vehicle-population-warranty-management/prognostics/top-10-DTC-complaints/customer-affected",
  },
  {
    breadcrumb: "Top 10 DTC Complaints",
    title: "Severity",
    iconName: "",
    component: <VPWMPrognosticSeverity />,
    path: "/vehicle-population/vehicle-population-warranty-management/prognostics/top-10-DTC-complaints/severity",
  },
  {
    breadcrumb: "Top 10 DTC Complaints",
    title: "Risk Analysis and Action Plan",
    iconName: "",
    component: <VPWMPrognosticRiskAnalysis />,
    path: "/vehicle-population/vehicle-population-warranty-management/prognostics/top-10-DTC-complaints/risk-analysis",
  },
  {
    breadcrumb: "Top 10 DTC Complaints",
    title: "DTC Warranty Scorecard",
    iconName: "",
    component: <VPWMPrognosticScoreCard />,
    path: "/vehicle-population/vehicle-population-warranty-management/prognostics/top-10-DTC-complaints/score-card",
  },
  {
    breadcrumb: "Top 10 Non-DTC Complaints",
    title: "Reports",
    iconName: "",
    component: <VPWMPrognosticNonDtcReports />,
    path: "/vehicle-population/vehicle-population-warranty-management/prognostics/top-10-non-dtc-complaints/reports",
  },
  {
    breadcrumb: "Top 10 non-DTCs Complaints",
    title: "Customer Affected",
    iconName: "",
    component: <VPWMPrognosticNonDtcCustomerAffected />,
    path: "/vehicle-population/vehicle-population-warranty-management/prognostics/top-10-non-dtc-complaints/customer-affected",
  },
  {
    breadcrumb: "Top 10 Non-DTC Complaints",
    title: "Cost",
    iconName: "",
    component: <VPWMPrognosticNonDtcCost />,
    path: "/vehicle-population/vehicle-population-warranty-management/prognostics/top-10-non-dtc-complaints/cost",
  },
  {
    breadcrumb: "Top 10 Non-DTC Complaints",
    title: "Severity",
    iconName: "",
    component: <VPWMPrognosticNonDtcSeverity />,
    path: "/vehicle-population/vehicle-population-warranty-management/prognostics/top-10-non-dtc-complaints/severity",
  },
  {
    breadcrumb: "Top 10 Non-DTC Complaints",
    title: "Risk Analysis and Action Plan",
    iconName: "",
    component: <VPWMPrognosticNonDtcRiskAnalysis />,
    path: "/vehicle-population/vehicle-population-warranty-management/prognostics/top-10-non-dtc-complaints/risk-analysis",
  },
  {
    breadcrumb: "Top 10 Non-DTC Complaints",
    title: "DTC Warranty Scorecard",
    iconName: "",
    component: <VPWMPrognosticNonDtcScoreCard />,
    path: "/vehicle-population/vehicle-population-warranty-management/prognostics/top-10-non-dtc-complaints/score-card",
  },
];
export const VehiclePopulataionWarrantyDiagnosticsRoutes = [
  {
    breadcrumb: "Top 10 DTC Complaints",
    title: "Top 10 DTC Complaints",
    iconName: "",
    component: <VPTopDtcComplaints />,
    path: "/vehicle-population/vehicle-population-warranty-management/diagnostics/top-10-DTC-complaints",
  },
  {
    breadcrumb: "Top 10 Non-DTC Complaints",
    title: "Top 10 Non-DTC Complaints",
    iconName: "",
    component: <VpTopDtcNonComplaints />,
    path: "/vehicle-population/vehicle-population-warranty-management/diagnostics/top-10-non-dtc-complaints",
  },
  {
    breadcrumb: "Top 10 DTC Complaints",
    title: "Reports",
    iconName: "",
    component: <VPWMReports />,
    path: "/vehicle-population/vehicle-population-warranty-management/diagnostics/top-10-DTC-complaints/reports",
  },
  {
    breadcrumb: "Top 10 DTC Complaints",
    title: "Customer Affected",
    iconName: "",
    component: <VPWMCustomerAffected />,
    path: "/vehicle-population/vehicle-population-warranty-management/diagnostics/top-10-DTC-complaints/customer-affected",
  },
  {
    breadcrumb: "Top 10 DTC Complaints",
    title: "Cost",
    iconName: "",
    component: <VPWMCost />,
    path: "/vehicle-population/vehicle-population-warranty-management/diagnostics/top-10-DTC-complaints/cost",
  },
  {
    breadcrumb: "Top 10 DTC Complaints",
    title: "Severity",
    iconName: "",
    component: <VPWMSeverity />,
    path: "/vehicle-population/vehicle-population-warranty-management/diagnostics/top-10-DTC-complaints/severity",
  },
  {
    breadcrumb: "Top 10 DTC Complaints",
    title: "Risk Analysis and Action Plan",
    iconName: "",
    component: <VPWMRiskAnalysis />,
    path: "/vehicle-population/vehicle-population-warranty-management/diagnostics/top-10-DTC-complaints/risk-analysis",
  },
  {
    breadcrumb: "Top 10 DTC Complaints",
    title: "DTC Warranty Scorecard",
    iconName: "",
    component: <VPWMScoreCard />,
    path: "/vehicle-population/vehicle-population-warranty-management/diagnostics/top-10-DTC-complaints/score-card",
  },
  {
    breadcrumb: "Top 10 Non-DTC Complaints",
    title: "Reports",
    iconName: "",
    component: <VPWMReportsNonDtc />,
    path: "/vehicle-population/vehicle-population-warranty-management/diagnostics/top-10-non-dtc-complaints/reports",
  },
  {
    breadcrumb: "Top 10 Non-DTC Complaints",
    title: "Cost",
    iconName: "",
    component: <VPWMCostNonDtc />,
    path: "/vehicle-population/vehicle-population-warranty-management/diagnostics/top-10-non-dtc-complaints/cost",
  },
  {
    breadcrumb: "Top 10 Non-DTC Complaints",
    title: "Severity",
    iconName: "",
    component: <VPWMSeverityNonDtc />,
    path: "/vehicle-population/vehicle-population-warranty-management/diagnostics/top-10-non-dtc-complaints/severity",
  },
  {
    breadcrumb: "Top 10 DTC Complaints",
    title: "Customer Affected",
    iconName: "",
    component: <VPWMCustomerAffectedNonDtc />,
    path: "/vehicle-population/vehicle-population-warranty-management/diagnostics/top-10-non-dtc-complaints/customer-affected",
  },
  {
    breadcrumb: "Top 10 Non-DTC Complaints",
    title: "Risk Analysis and Action Plan",
    iconName: "",
    component: <VPWMRiskAnalysisNonDtc />,
    path: "/vehicle-population/vehicle-population-warranty-management/diagnostics/top-10-non-dtc-complaints/risk-analysis",
  },
  {
    breadcrumb: "Top 10 Non-DTC Complaints",
    title: "DTC Warranty Scorecard",
    iconName: "",
    component: <VPWMScoreCardNonDtc />,
    path: "/vehicle-population/vehicle-population-warranty-management/diagnostics/top-10-non-dtc-complaints/score-card",
  },
];
export const WarrantyManagementRoutes = [
  {
    breadcrumb: "Diagnostics",
    iconName: "",
    component: <WarrantyDiagnostics />,
    path: "/warranty-management/diagnostics",
    nestedRoutes: WarrantyDiagnosticsRoutes,
  },
  {
    breadcrumb: "Prognostics",
    iconName: "",
    component: <WarrantyPrognostics />,
    path: "/warranty-management/prognostics",
    nestedRoutes: WarrantyPrognosticsRoutes,
  },
  {
    breadcrumb: "Warranty rate",
    iconName: "",
    component: <WarrantyRate />,
    path: "/warranty-management/warranty-rate",
  },
];

export const VehiclePopulationWarrantyManagmentRoutes = [
  {
    breadcrumb: "Diagnostics",
    iconName: "",
    component: <WMDiagnostic />,
    path: "/vehicle-population/vehicle-population-warranty-management/diagnostics",
    nestedRoutes: VehiclePopulataionWarrantyDiagnosticsRoutes,
  },
  {
    breadcrumb: "Prognostics",
    iconName: "",
    component: <WMPrognostic />,
    path: "/vehicle-population/vehicle-population-warranty-management/prognostics",
    nestedRoutes: VehiclePopulataionWarrantyPrognosticsRoutes,
  },
  {
    breadcrumb: "Warranty rate",
    iconName: "",
    component: <WarrantyRate />,
    path: "/vehicle-population/vehicle-population-warranty-management/warranty-rate",
  },
];

export const MODServiceGuideDetailsRoutes = [
  {
    breadcrumb: "Architecture",
    title: "Architecture",
    iconName: "",
    component: <ArchitectureServiceGuide />,
    path: "/maintenance-overview/diagnostics/service-guide/architecture",
  },
  {
    breadcrumb: "User Manual",
    title: "User Manual",
    iconName: "",
    component: <UserManualServiceGuide />,
    path: "/maintenance-overview/diagnostics/service-guide/user-manual",
  },
  {
    breadcrumb: "Augmented Reality",
    title: "Augmented Reality",
    iconName: "",
    component: <AugmentedRealityServiceGuide />,
    path: "/maintenance-overview/diagnostics/service-guide/augmented-reality",
  },
  {
    breadcrumb: "Youtube Videos",
    title: "Youtube Videos",
    iconName: "",
    component: <YoutubeVideosServiceGuide />,
    path: "/maintenance-overview/diagnostics/service-guide/youtube-videos",
  },
  {
    breadcrumb: "Internet Contents",
    title: "Internet Contents",
    iconName: "",
    component: <SocialMediaServiceGuide />,
    path: "/maintenance-overview/diagnostics/service-guide/internet-contents",
  },
];
export const PrognosticsServiceGuideDetailsRoutes = [
  {
    breadcrumb: "Architecture",
    title: "Architecture",
    iconName: "",
    component: <ArchitectureServiceGuide />,
    path: "/maintenance-overview/prognostics/service-guide/architecture",
  },
  {
    breadcrumb: "User Manual",
    title: "User Manual",
    iconName: "",
    component: <UserManualServiceGuide />,
    path: "/maintenance-overview/prognostics/service-guide/user-manual",
  },
  {
    breadcrumb: "Augmented Reality",
    title: "Augmented Reality",
    iconName: "",
    component: <AugmentedRealityServiceGuide />,
    path: "/maintenance-overview/prognostics/service-guide/augmented-reality",
  },
  {
    breadcrumb: "Youtube Videos",
    title: "Youtube Videos",
    iconName: "",
    component: <YoutubeVideosServiceGuide />,
    path: "/maintenance-overview/prognostics/service-guide/youtube-videos",
  },
  {
    breadcrumb: "Internet Contents",
    title: "Internet Contents",
    iconName: "",
    component: <SocialMediaServiceGuide />,
    path: "/maintenance-overview/prognostics/service-guide/internet-contents",
  },
];

export const RootCauseDataRoutes = [
  {
    title: "Data Visualization",
    breadcrumb: "Data Visualization",
    iconName: "",
    component: <Details />,
    path: "/data-visualization",
  },
  {
    title: "Is - Is Not Analysis",
    breadcrumb: "Is - Is Not Analysis",
    iconName: "",
    component: <Details />,
    path: "/is-isnot-analysis",
  },
  {
    title: "Ishikawa Diagram",
    breadcrumb: "Ishikawa Diagram",
    iconName: "",
    component: <IshikawaDiagram />,
    path: "/ishikawa-diagram",
  },
];

export const FaultAnalyticsRoute = [
  {
    title: "Fault Trend Analytics",
    breadcrumb: "Fault Trend Analytics > Predicted DTC’s",
    iconName: "",
    component: <FaultTrendAnalytics />,
    path: "/fault-analytics",
  },
  {
    title: "Root Cause Analysis",
    breadcrumb: "Root Cause Analysis",
    iconName: "",
    component: <RootCauseAnalysis />,
    path: "/fault-analytics/root-cause-analysis",
  },
];
export const MaintenanceOnDemandRoutes = [
  {
    title: "Routine Maintenance",
    breadcrumb: "Routine Maintenance",
    iconName: "",
    component: <RoutineMaintenance />,
    path: "/maintenance-overview/routine-maintenance",
  },
  {
    title: "Diagnostics",
    breadcrumb: "Diagnostics ",
    iconName: "",
    element: <MaintenanceDiagnostics />,
    component: <MaintenanceDiagnostics />,
    path: "/maintenance-overview/diagnostics",
  },
  {
    title: "Prognostics",
    breadcrumb: "Prognostics >  Predicted DTC’s",
    iconName: "",
    component: <MaintenancePrognostics />,
    path: "/maintenance-overview/prognostics",
  },
  {
    title: "Root Cause Analysis",
    breadcrumb: "Root Cause Analysis",
    iconName: "",
    component: <RootCauseAnalysis />,
    path: "/maintenance-overview/diagnostics/root-cause-analysis",
  },
  {
    title: "Root Cause Analysis",
    breadcrumb: "Root Cause Analysis",
    iconName: "",
    component: <RootCauseAnalysis />,
    path: "/maintenance-overview/prognostics/root-cause-analysis",
  },
];

export const VehicleOverviewRoutes = [
  {
    title: "Vehicle Information",
    breadcrumb: "Vehicle Information",
    iconName: "",
    component: <VehicleInformation />,
    path: "/vehicle-overview/information",
  },
  {
    title: "Vehicle Notification",
    breadcrumb: "Vehicle Notification",
    iconName: "",
    element: <VehicleNotification />,
    component: <VehicleNotification />,
    path: "/vehicle-overview/notification",
  },
  {
    title: "Real Time Data",
    breadcrumb: "Real Time Data",
    // title: "vehicle Real-Time data",
    // breadcrumb: "vehicle Real-Time data",
    iconName: "",
    component: <VehicleRealTimeRefactor />,
    path: "/vehicle-overview/realtime-data",
  },
];

export const OperationalAnalyticsRoute = [
  {
    title: "Driver Behaviour",
    breadcrumb: "Driver Behaviour",
    iconName: "",
    component: <DriverBehaviour />,
    path: "/operational-analytics/driver-behaviour",
  },
  {
    title: "Fuel Economy",
    breadcrumb: "Fuel Economy",
    iconName: "",
    component: <FuelEconomy />,
    path: "/operational-analytics/fuel-economy",
  },
];

export const MaintenanceLogsRoutes = [
  {
    title: "time driven actions",
    breadcrumb: "time driven actions",
    iconName: "",
    component: <TimeDrivenAction />,
    path: "/maintenance-log/summary/time-driven-actions",
  },
  {
    title: "Maintenance On-Demand",
    breadcrumb: "Maintenance On-Demand",
    iconName: "",
    component: <MaintenanceOnDemandLogs />,
    path: "/maintenance-log/summary/maintenance-on-demand",
  },
  {
    title: "Quality",
    breadcrumb: "Quality",
    iconName: "",
    component: <Quality />,
    path: "/maintenance-log/summary/quality",
  },
  {
    title: "Location",
    breadcrumb: "Location",
    iconName: "",
    component: <Location />,
    path: "/maintenance-log/summary/location",
  },
];

export const VehiclePopulationUsageProfileRoutes = [
  {
    title: "Reports For Diagnostics",
    breadcrumb: "Reports For Diagnostics",
    iconName: "",
    component: <VPUPReportForDiagnostic />,
    path: "/vehicle-population/usageprofile/diagnostic",
  },
  {
    title: "Reports For Prognostic",
    breadcrumb: "Reports For Prognostic",
    iconName: "",
    component: <VPUPReportForPrognostic />,
    path: "/vehicle-population/usageprofile/prognostic",
  },
];

export const VehiclePopulationHealthCheckRoutes = [
  {
    title: "Reports For Diagnostics",
    breadcrumb: "Reports For Diagnostics",
    iconName: "",
    component: <VPHCReportForDiagnostic />,
    path: "/vehicle-population/health-check/diagnostic",
  },
  {
    title: "Reports For Prognostic",
    breadcrumb: "Reports For Prognostic",
    iconName: "",
    component: <VPHCReportForPrognostic />,
    path: "/vehicle-population/health-check/prognostic",
  },
];

export const VehiclePopulationDashBoardRoutes = [
  {
    title: "Reports For Diagnostics",
    breadcrumb: "Reports For Diagnostics",
    iconName: "",
    component: <ReportForDiagnostics />,
    path: "/vehicle-population/diagnostic",
    nestedRoutes: ReportForDiagnosticRoutes,
  },
  {
    title: "Reports For Prognostic",
    breadcrumb: "Reports For Prognostic",
    iconName: "",
    component: <ReportForPrognostic />,
    path: "/vehicle-population/prognostic",
    nestedRoutes: ReportForPrognosticRoutes,
  },
  // {
  //   breadcrumb: "Usage Profile",
  //   title: "Usage Profile",
  //   iconName: "usage-profile",
  //   component: <VPUsageProfile />,
  //   path: "/vehicle-population/usageprofile",
  //   nestedRoutes: VehiclePopulationUsageProfileRoutes,
  // },
];

export const NavigationDashBoardMenuLish = [
  {
    breadcrumb: "DashBoard",
    title: "DashBoard",
    iconName: "",
    component: <DashBoard />,
    path: "/dashboard",
  },
];

export const AdminNavigationMenuList = [
  {
    breadcrumb: "Dashboard-tuhin",
    title: "Dashboard",
    iconName: "hamburger-icon",
    path: "/admin-dashboard",
  },
  {
    breadcrumb: "Configure Features",
    title: "Configure Features",
    iconName: "configure-features",
    component: <ConfigureFeature />,
    path: "/configure-features",
  },
];

export const NavigationMenuList = [
  {
    breadcrumb: "Vehicle Overview",
    title: "Vehicle Overview",
    iconName: "over-view",
    component: <VehicleOverview />,
    path: "/vehicle-overview",
    nestedRoutes: VehicleOverviewRoutes,
  },

  {
    breadcrumb: "Vehicle Usage Profile",
    title: "Vehicle Usage Profile",
    iconName: "usage-profile",
    component: <VehicleUsageProfile />,
    path: "/vehicle-usage",
  },
  {
    breadcrumb: "Vehicle Health Check",
    title: "Vehicle Health Check",
    iconName: "health-check",
    component: <VehicleHealthCheck />,
    path: "/vehicle-health",
    nestedRoutes: VehicleHealthCheckRoutes,
  },
  {
    breadcrumb: "Remote Monitoring",
    title: "Operational Analytics",
    iconName: "remote-monitoring",
    component: <OperationalAnalytics />,
    path: "/operational-analytics",
    nestedRoutes: OperationalAnalyticsRoute,
  },
  {
    breadcrumb: "Fault Trend Analytics",
    title: "Fault Trend Analytics",
    iconName: "fault-trend",
    component: <FaultAnalytics />,
    path: "/fault-analytics",
    nestedRoutes: FaultAnalyticsRoute,
  },
  {
    breadcrumb: "Prescriptive Analytics",
    title: "Prescriptive Analytics",
    iconName: "perspective-analytics",
    component: <Details />,
    path: "/prescriptive-analytics",
  },
  {
    breadcrumb: "Maintenance On-Demand",
    title: "Maintenance On-Demand",
    iconName: "maintainance-overview",
    component: <MaintenanceOnDemand />,
    path: "/maintenance-overview",
    nestedRoutes: MaintenanceOnDemandRoutes,
  },
  {
    breadcrumb: "Maintenance Log",
    title: "Maintenance Log",
    iconName: "maintainance-logs",
    component: <MaintenanceLogs />,
    path: "/maintenance-log",
  },
  {
    breadcrumb: "Warranty Management",
    title: "Warranty Management",
    iconName: "warranty-management",
    component: <WarrantyManagement />,
    path: "/warranty-management",
    nestedRoutes: WarrantyManagementRoutes,
  },
];

export const VehiclePopulationNavigationMenuList = [
  {
    breadcrumb: "Vehicle Overview",
    title: "Dashboard",
    iconName: "vehcile-population-dashboard",
    component: <VehiclePopulation />,
    path: "/vehicle-population",
    nestedRoutes: VehiclePopulationDashBoardRoutes,
    children: [
      {
        breadcrumb: "Usage Profile",
        title: "Usage Profile",
        iconName: "usage-profile",
        component: <VPUsageProfile />,
        path: "/vehicle-population/usageprofile",
        nestedRoutes: VehiclePopulationUsageProfileRoutes,
      },
      {
        breadcrumb: "health Check",
        title: "Health Check",
        iconName: "health-check",
        component: <VpHealthCheck />,
        path: "/vehicle-population/health-check",
        nestedRoutes: VehiclePopulationHealthCheckRoutes,
      },
      {
        breadcrumb: "Operational Analytics",
        title: "Operational Analytics",
        iconName: "remote-monitoring",
        component: <Details />,
        path: "/vehicle-population/vehicle-population-operational-analytics",
      },
      {
        breadcrumb: "Fault Trend Analytics",
        title: "Fault Trend Analytics",
        iconName: "fault-trend",
        component: <Details />,
        path: "/vehicle-population/vehicle-population-fault-trend-analytics",
      },
      {
        breadcrumb: "Maintenance On-Demand",
        title: "Maintenance On-Demand",
        iconName: "maintainance-overview",
        component: <Details />,
        path: "/vehicle-population/vehicle-population-maintenance-on-demand",
      },
      {
        breadcrumb: "Warranty Management",
        title: "Warranty Management",
        iconName: "warranty-management",
        component: <VPWarrantyManagement />,
        nav: true,
        path: "/vehicle-population/vehicle-population-warranty-management",
        nestedRoutes: VehiclePopulationWarrantyManagmentRoutes,
      },
    ],
  },

  {
    breadcrumb: "Saved filters",
    title: "Saved Filters",
    iconName: "save-filter",
    component: <SavedFilters />,
    path: "/vp-saved-filter",
    nestedRoutes: [
      {
        breadcrumb: "Saved filters",
        title: "Saved Filters",
        iconName: "save-filter",
        component: <SavedFiltersDashboard />,
        path: "/vp-saved-filter/savedfilter",
      },
    ],
    children: [
      {
        breadcrumb: "Generated dashboard",
        title: "Generated dashboard",
        iconName: "generated-dashboard",
        component: <Comparision />,
        path: "/vp-saved-filter/comparision",
        nestedRoutes: [
          // {
          //   title: "Generated Dashboard",
          //   breadcrumb: "Generated Dashboard",
          //   iconName: "",
          //   component: <GeneratedDashboardComp />,
          //   path: "/vp-saved-filter/GeneratedDashboard",
          // },
          {
            title: "Reports For Diagnostics",
            breadcrumb: "Reports For Diagnostics",
            iconName: "",
            component: <GeneratedDashboardComp />,
            path: "/vp-saved-filter/comparision/GeneratedDashboard/diagnostics",
            nestedRoutes: [
              {
                title: "Reports For Diagnostics",
                breadcrumb: "Reports For Diagnostics",
                iconName: "",
                component: <CompareWithFilter />,
                path: "/vp-saved-filter/comparision/GeneratedDashboard/diagnostics/cwf",
              },
              {
                title: "Reports For Diagnostics",
                breadcrumb: "Reports For Diagnostics",
                iconName: "",
                component: <CompareWithVin />,
                path: "/vp-saved-filter/comparision/GeneratedDashboard/diagnostics/cwv",
              },
            ],
          },
          {
            title: "Reports For Prognostics",
            breadcrumb: "Reports For Prognostics",
            iconName: "",
            component: <GeneratedDashboardComp />,
            path: "/vp-saved-filter/comparision/GeneratedDashboard/prognostics",
            nestedRoutes: [
              {
                title: "Reports For Diagnostics",
                breadcrumb: "Reports For Diagnostics",
                iconName: "",
                component: <CompareWithFilter />,
                path: "/vp-saved-filter/comparision/GeneratedDashboard/prognostics/cwf",
              },
              {
                title: "Reports For Diagnostics",
                breadcrumb: "Reports For Diagnostics",
                iconName: "",
                component: <CompareWithVin />,
                path: "/vp-saved-filter/comparision/GeneratedDashboard/prognostics/cwv",
              },
            ],
          },
        ],
      },
      {
        breadcrumb: "Usage Profile",
        title: "Usage Profile",
        iconName: "usage-profile",
        component: <VPUsageProfileCompare />,
        path: "/vp-saved-filter/usageprofile/comparision",
        nestedRoutes: [
          {
            title: "Reports For Diagnostics",
            breadcrumb: "Reports For Diagnostics",
            iconName: "",
            component: <VPUsageProfileCompare />,
            path: "/vp-saved-filter/usageprofile/comparision/diagnostics",
          },
          {
            title: "Reports For Diagnostics",
            breadcrumb: "Reports For Diagnostics",
            iconName: "",
            component: <UPCompareWithFilter />,
            path: "/vp-saved-filter/usageprofile/comparision/diagnostics/cwf",
            //path: "/vp-saved-filter/usageprofile/comparision/diagnostics",
          },
          {
            title: "Reports For Diagnostics",
            breadcrumb: "Reports For Diagnostics",
            iconName: "",
            component: <UPCompareWithVin />,
            path: "/vp-saved-filter/usageprofile/comparision/diagnostics/cwv",
          },
          {
            title: "Reports For Diagnostics",
            breadcrumb: "Reports For Diagnostics",
            iconName: "",
            component: <VPUsageProfileCompare />,
            path: "/vp-saved-filter/usageprofile/comparision/prognostics",
          },
          {
            title: "Reports For Diagnostics",
            breadcrumb: "Reports For Diagnostics",
            iconName: "",
            component: <UPCompareWithFilter />,
            path: "/vp-saved-filter/usageprofile/comparision/prognostics/cwf",
          },
          {
            title: "Reports For Diagnostics",
            breadcrumb: "Reports For Diagnostics",
            iconName: "",
            component: <UPCompareWithVin />,
            path: "/vp-saved-filter/usageprofile/comparision/prognostics/cwv",
          },
        ],
      },
      {
        breadcrumb: "health Check",
        title: "Health Check",
        iconName: "health-check",
        component: <VPHealthCheckComparision />,
        path: "/vp-saved-filter/health-check/comparision",
        nestedRoutes: [
          {
            title: "Reports For Diagnostics",
            breadcrumb: "Reports For Diagnostics",
            iconName: "",
            component: <VPHealthCheckComparision />,
            path: "/vp-saved-filter/health-check/comparision/diagnostics",
          },
          {
            title: "Reports For Diagnostics",
            breadcrumb: "Reports For Diagnostics",
            iconName: "",
            component: <VPCompareWithFilter />,
            path: "/vp-saved-filter/health-check/comparision/diagnostics/cwf",
            //path: "/vp-saved-filter/usageprofile/comparision/diagnostics",
          },
          {
            title: "Reports For Diagnostics",
            breadcrumb: "Reports For Diagnostics",
            iconName: "",
            component: <Details />,
            path: "/vp-saved-filter/health-check/comparision/diagnostics/cwv",
          },
          {
            title: "Reports For Diagnostics",
            breadcrumb: "Reports For Diagnostics",
            iconName: "",
            component: <VPHealthCheckComparision />,
            path: "/vp-saved-filter/health-check/comparision/prognostics",
          },
          {
            title: "Reports For Diagnostics",
            breadcrumb: "Reports For Diagnostics",
            iconName: "",
            component: <VPCompareWithFilter />,
            path: "/vp-saved-filter/health-check/comparision/prognostics/cwf",
          },
          {
            title: "Reports For Diagnostics",
            breadcrumb: "Reports For Diagnostics",
            iconName: "",
            component: <Details />,
            path: "/vp-saved-filter/health-check/comparision/prognostics/cwv",
          },
        ],
      },
      {
        breadcrumb: "Operational Analytics",
        title: "Operational Analytics",
        iconName: "remote-monitoring",
        component: <Details />,
        path: "/vp-saved-filter/vehicle-population-operational-analytics/comparision",
      },
      {
        breadcrumb: "Fault Trend Analytics",
        title: "Fault Trend Analytics",
        iconName: "fault-trend",
        component: <Details />,
        path: "/vp-saved-filter/vehicle-population-fault-trend-analytics/comparision",
      },
      {
        breadcrumb: "Maintenance On-Demand",
        title: "Maintenance On-Demand",
        iconName: "maintainance-overview",
        component: <Details />,
        path: "/vp-saved-filter/vehicle-population-maintenance-on-demand/comparision",
      },
      {
        breadcrumb: "Warranty Management",
        title: "Warranty Management",
        iconName: "warranty-management",
        component: <Details />,
        path: "/vp-saved-filter/vehicle-population-warranty-management/comparision",
      },
    ],
  },

  // {
  //   breadcrumb: "Generated dashboard",
  //   title: "Generated dashboard",
  //   iconName: "generated-dashboard",
  //   component: <Comparision />,
  //   path: "/vehicle-population-comparision",
  //   nestedRoutes: [
  //     // {
  //     //   title: "Generated Dashboard",
  //     //   breadcrumb: "Generated Dashboard",
  //     //   iconName: "",
  //     //   component: <GeneratedDashboardComp />,
  //     //   path: "/vehicle-population-comparision/GeneratedDashboard",
  //     // },
  //     {
  //       title: "Reports For Diagnostics",
  //       breadcrumb: "Reports For Diagnostics",
  //       iconName: "",
  //       component: <GeneratedDashboardComp />,
  //       path: "/vehicle-population-comparision/GeneratedDashboard/diagnostics",
  //       nestedRoutes: [
  //         {
  //           title: "Reports For Diagnostics",
  //           breadcrumb: "Reports For Diagnostics",
  //           iconName: "",
  //           component: <CompareWithFilter />,
  //           path: "/vehicle-population-comparision/GeneratedDashboard/diagnostics/cwf",
  //         },
  //         {
  //           title: "Reports For Diagnostics",
  //           breadcrumb: "Reports For Diagnostics",
  //           iconName: "",
  //           component: <CompareWithVin />,
  //           path: "/vehicle-population-comparision/GeneratedDashboard/diagnostics/cwv",
  //         },
  //       ],
  //     },
  //     {
  //       title: "Reports For Prognostics",
  //       breadcrumb: "Reports For Prognostics",
  //       iconName: "",
  //       component: <GeneratedDashboardComp />,
  //       path: "/vehicle-population-comparision/GeneratedDashboard/prognostics",
  //       nestedRoutes: [
  //         {
  //           title: "Reports For Diagnostics",
  //           breadcrumb: "Reports For Diagnostics",
  //           iconName: "",
  //           component: <CompareWithFilter />,
  //           path: "/vehicle-population-comparision/GeneratedDashboard/prognostics/cwf",
  //         },
  //         {
  //           title: "Reports For Diagnostics",
  //           breadcrumb: "Reports For Diagnostics",
  //           iconName: "",
  //           component: <CompareWithVin />,
  //           path: "/vehicle-population-comparision/GeneratedDashboard/prognostics/cwv",
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export const ComponentSystemHistoryRoutes = [
  {
    breadcrumb: "Diagnostics",
    title: "Diagnostics",
    iconName: "",
    component: <ComponentSystemHistoryDiagnostics />,
    path: "/diagnostics",
  },
  {
    breadcrumb: "Prognostics",
    title: "Prognostics",
    iconName: "",
    component: <ComponentSystemHistoryPrognostics />,
    path: "/prognostics",
  },
  {
    breadcrumb: "Maintenance Profile",
    title: "Maintenance Profile",
    iconName: "",
    component: <ComponentSystemHistoryMaintenanceProfile />,
    path: "/maintenance-profile",
  },
  {
    breadcrumb: "Maintenance History",
    title: "Maintenance History",
    iconName: "",
    component: <ComponentSystemHistoryMaintenanceHistory />,
    path: "/maintenance-history",
  },
  {
    breadcrumb: "Usage Profile",
    title: "Usage Profile",
    iconName: "",
    component: <ComponentSystemHistoryUsageProfile />,
    path: "/usage-profile",
  },
  {
    breadcrumb: "Service Conditions",
    title: "Service Conditions",
    iconName: "",
    component: <ComponentSystemHistoryServiceConditions />,
    path: "/service-conditions",
  },
];

export const routes = [
  {
    path: "/",
    breadcrumb: "Home",
    element: <Home />,
    nav: true,
    children: [
      {
        path: "/maintenance-overview",
        breadcrumb: "Maintenance On Demand",
        element: <MaintenanceOnDemand />,
        children: MaintenanceOnDemandRoutes,
      },
      {
        path: "/vehicle-population/diagnostic/top-10-dtc",
        breadcrumb: "Top 10 DTC",
        component: <DiagnosticsReportsForTop10Dtc />,
      },
      {
        path: "/vehicle-population/diagnostic/top-10-Non-dtc",
        breadcrumb: "Top 10 Non DTC",
        component: <DiagnosticsReportsForTop10NonDtc />,
      },
      {
        path: "/vehicle-population/diagnostic/population-setting-dtc",
        breadcrumb: "Population Setting DTC",
        component: <DiagnosticsReportsForPopulationSettingDtc />,
      },
      {
        path: "/vehicle-population/prognostic/top-10-dtc",
        breadcrumb: "Top 10 DTC",
        component: <PrognosticsReportsForTop10Dtc />,
      },
      {
        path: "/vehicle-population/prognostic/top-10-Non-dtc",
        breadcrumb: "Top 10 Non DTC",
        component: <PrognosticsReportsForTop10NonDtc />,
      },
      {
        path: "/vehicle-population/prognostic/population-setting-dtc",
        breadcrumb: "Population Setting DTC",
        component: <PrognosticsReportsForPopulationSettingDtc />,
      },
      {
        path: "/vehicle-overview",
        breadcrumb: "Vehicle Overview",
        element: <VehicleOverview />,
        children: VehicleOverviewRoutes,
      },
      {
        path: "vehicle-usage",
        element: <VehicleUsageProfile />,
        breadcrumb: "Vehicle Usage Profile",
      },
      {
        path: "vehicle-health",
        element: <VehicleHealthCheck />,
        breadcrumb: "Vehicle Health Check",
        //  nestedRoutes: VehicleHealthCheckRoutes,
        nav: true,
        children: [
          {
            path: "vehicle-health/diagnostics",
            breadcrumb: "Diagnostics",
            element: <Diagnostics />,
            children: [
              {
                path: "vehicle-health/diagnostics/on-board",
                breadcrumb: "On Board",
                element: <OnBoard />,
              },
              {
                path: "vehicle-health/diagnostics/remote-testing",
                breadcrumb: "Remote Testing",
                element: <Details />,
              },
            ],
          },
          {
            path: "vehicle-health/self-test",
            breadcrumb: "Self Test",
            element: <SelfTest />,
          },
          {
            path: "/vehicle-health/digital-twin",
            breadcrumb: "Digital Twin",
            element: <DigitalTwin />,
            children: [
              {
                path: "vehicle-health/digital-twin/performance-digital-twin",
                breadcrumb: "Performance Digital Twin",
                element: <PerformanceDigitalTwin />,
              },
              {
                path: "vehicle-health/digital-twin/component-sub-system",
                breadcrumb: "Component Sub System",
                element: <ComponentSubSystem />,
              },
            ],
          },
        ],
      },
      {
        path: "operational-analytics",
        breadcrumb: "Operational Analytics",
        element: <Details />,
      },
      {
        path: "/operational-analytics/driver-behaviour/summary/pictorial-representation",
        breadcrumb: "Pictorial Representation",
        element: <PictorialRepresentation />,
      },
      {
        path: "/fault-analytics",
        breadcrumb: "Fault Trend Analytics",
        element: <FaultAnalytics />,
        children: FaultAnalyticsRoute,
      },
      {
        path: "/prescriptive-analytics",
        breadcrumb: "Prescriptive Analytics",
        element: <Details />,
      },

      {
        path: "/maintenance-log",
        breadcrumb: "Maintenance Log",
        element: <Details />,
      },
      {
        path: "/warranty-management",
        breadcrumb: "Warranty Management",
        element: <WarrantyManagement />,
        nav: true,
        children: [
          {
            path: "warranty-management/diagnostics",
            breadcrumb: "Diagnostics",
            element: <WarrantyDiagnostics />,
            children: [
              {
                path: "warranty-management/diagnostics/top-10-DTC-complaints",
                breadcrumb: "Top 10 DTC Complaints",
                element: <TopDiagonasticDtcComplaintsLayout />,
              },
              {
                path: "warranty-management/diagnostics/top-10-non-dtc-complaints",
                breadcrumb: "Top 10 Non-DTC Complaints",
                element: <TopDiagonasticNonDtcComplaints />,
              },
            ],
          },
          {
            path: "warranty-management/prognostics",
            breadcrumb: "Prognostics",
            element: <WarrantyPrognostics />,
            children: [
              {
                path: "warranty-management/prognostics/top-10-DTC-complaints",
                breadcrumb: "Top 10 DTC Complaints",
                element: <TopPrognosticDtcComplaints />,
              },
              {
                path: "warranty-management/prognostics/top-10-non-dtc-complaints",
                breadcrumb: "Top 10 Non-DTC Complaints",
                element: <TopPrognosticNonDtcComplaints />,
              },
            ],
          },
          {
            path: "/warranty-management/warranty-rate",
            breadcrumb: "Warranty Rate",
            element: <WarrantyRate />,
          },
        ],
      },

      { path: "*", element: <Details /> }, // TODO: Create a Not found component and update this with new component
    ],
  },
  {
    path: "/",
    breadcrumb: "Home",
    element: <Home />, // TODO: Replace this with login component
    children: [
      { path: "404", element: <Details /> }, // TODO: Create a Bad request component and update this with new component
      { path: "*", element: <Details /> }, // TODO: Create a Not found component and update this with new component
    ],
  },
  {
    path: "/",
    element: <Home />,
    breadcrumb: "Home",
    children: [
      { path: "health", element: <Details /> }, // Dummy route, DO NOT REMOVE
    ],
  },
];

export default routes;
