import React from 'react'
import {CssBaseline,Container,Paper,Box} from "@mui/material"
import FilterStepper from './FilterStepper'
import { styled } from "@mui/material/styles";
const FormLayout = () => {

  return (
    <div style={{padding:"0 !important"}}>
    
     <Container component={Box}  style={{padding:"0px"}}>
     <Paper component={Box} p={3} style={{borderRadius:"30px"}}>
     <FilterStepper/>
     </Paper>
     </Container>
    
    </div>
  )
}

export default FormLayout;