import { BarGraph } from "../../../../components/common/blackbarGraph/Graph";
import {
  ServiceConfig,
  VehiclePopulationDashBoard,
} from "../../../../services/service-constants";
import { mainAxiosInstance } from "../../../../services/service";
import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../VehiclePopulation.scss";
// import { useSelector } from "react-redux";
import Feedback from "../../../../components/common/ErrorComponents/Feedback";
import SingleChartLoader from "../../../../components/common/ErrorComponents/SingleChartLoader";
import { getWarrantydata } from "../../Apis/Apis";
import Pagination from "../../../../components/common/Pagination";

const WarrantyScoreCard = () => {
  const [noDataFound, setNoDataFound] = useState(false);
  const [showChartLoader, setShowChartLoader] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [chartData, setChartData] = useState();
  const [graphName, setGraphName] = useState("");
  const [pagination, setPagination] = useState({});

  const getChartData = async (val) => {
    setShowChartLoader(true);
    var pageSize = val === undefined ? 0 : val;
    const { code, data, detailsData, PaginationDetails } =
      await getWarrantydata(pageSize);
    setPagination(PaginationDetails);
    if (code == 200) {
      setNoDataFound(false);
      setShowChartLoader(false);
      const milesData = [];
      let xAxisLabel = data.informationDto.xAxisName;
      let yAxisLabel = data.informationDto.yAxisName;
      setGraphName(data.informationDto.graphName);
      milesData.push({
        data: detailsData,
        xLabel: xAxisLabel,
        yLabel: yAxisLabel,
        id: graphName,
      });
      setChartData(milesData);
    }
    if (code === "error") {
      setApiError(data);
      setNoDataFound(true);
      setShowChartLoader(false);
    }
  };

  useEffect(() => {
    getChartData();
  }, []);

  const onPageChanged = (val) => {
    getChartData(val);
  };

  if (!noDataFound && showChartLoader) {
    return (
      <div className="vehicle-dashboard-middle-container">
        <SingleChartLoader />;
      </div>
    );
  }

  return (
    <>
      <div className="vehicle-dashboard-middle-container">
        {!noDataFound && chartData !== undefined && !showChartLoader ? (
          <>
            <BarGraph chartdata={chartData} />
          </>
        ) : (
          <div>
            <Feedback feedbackClassName="feedback" />
          </div>
        )}
      </div>
      {!noDataFound ? (
        <>
          <div className="graph-name ">{graphName} </div>
          {pagination.totalpages > 1 && (
            <div>
              <Pagination
                pageCount={pagination?.totalPages}
                pageRange={pagination?.size}
                onPageChanged={onPageChanged}
              />
            </div>
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default WarrantyScoreCard;
