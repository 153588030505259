import React, { useEffect, useState } from "react";
import { mainAxiosInstance } from "../../../services/service";
import { GetDriverBehaviourSummaryApi } from "../../../services/service-constants";
import TrafficImg from "../../../assets/traffic-img.svg";
import TravelImg from "../../../assets/travel-img.svg";
import DriveImg from "../../../assets/drive-img.svg";
import { useNavigate } from "react-router";
import LottieLoad from "../../../components/common/ErrorComponents/LottieLoad";
import Feedback from "../../../components/common/ErrorComponents/Feedback";
import NotAuthorized from "../../../components/common/ErrorComponents/NotAuthorized";
import "./index.scss";
import { Button } from "../../../components/common/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../../components/common/Breadcrumb/Breadcrumbs";
import routes from "../../../utils/constants";

const GetDriverBehaviourSummary = () => {
  const navigate = useNavigate();
  const vin_Number = useSelector((state) => state.mainReducer.vin_Number);
  const [driverBehaviourSummaryData, setDriverBehaviourSummaryData] = useState(
    []
  );
  const [isExpanded, setExpanded] = useState(true);
  const [noDataFound, setNoDataFound] = useState(true);
  const [notAuthorized, setNotAuthorized] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("")

  const colorMap = {
    yellow: "#FDE6A1",
    green: "#CFFFD2",
    red: "#FDDBD4",
    darkpink: "#FBBEE3",
    lightblue: "#CBECFB",
    brightblue: "#9AF8F9"
  };

  const getPageData = (val) => {
    let url = "";
    let params = {};
    url = `${GetDriverBehaviourSummaryApi}`;
    params = {
      vin_number: val.vin_Number,
      organization_id: 1,
    };

    mainAxiosInstance
      .get(url, { params: params })
      .then((response) => {
        if (response.status === 200) {
          setDriverBehaviourSummaryData(response?.data);
          setNoDataFound(false);
        }
        if (response.status === 401) {
          setErrorMsg(response.data.detail || "You are not authorized");
          setNotAuthorized(true)
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setNotAuthorized(true)
          setErrorMsg(err.response.data?.details || "You are not authorized.");
        }
        else {
          setApiError(true);
          setErrorMsg(err.response.data.details || "No records Found")
          setNoDataFound(true);
        }

      });
  };

  useEffect(() => {
    getPageData({
      vin_Number: vin_Number,
    });
  }, []);

  if (notAuthorized) {
    return <NotAuthorized title={errorMsg} />;
  }
  if (apiError) {
    return <Feedback />;
  }


  return (
    <div className="driver-behaviour-summary-wrapper">
      <div className={` ${isExpanded ? "" : "expand"}`} >
        <Breadcrumbs routes={routes} />
      </div>
      <div className="summary-driver-behaviour">
        Driver Behaviour score is {driverBehaviourSummaryData?.driverScore}%
        (Good)
      </div>
      <div className="flex page-content-header"
        style={
          { "paddingTop": "0px", "marginTop": "-10px", "marginBottom": "10px" }
        }>
        <div className="page-options-container"></div>
        <div className="service-help-wrapper">
          <div className="service-help">
            <Button
              title="Pictorial Representation View"
              handleClick={() =>
                navigate(
                  "/operational-analytics/driver-behaviour/summary/pictorial-representation",
                  {
                    replace: true,
                  }
                )
              }
              styleClasses="btn btn-secondary-blue pictorialBtn"

            />
          </div>
        </div>
      </div>
      <div className="page-chart-container vehicle-usage-charts">
        {!noDataFound &&
          driverBehaviourSummaryData?.behaviourSummary?.map((el) => {
            const imgName =
              el.message.includes("drive") || el.message.includes("driving")
                ? DriveImg
                : el.message.toLowerCase().includes("sign")
                  ? TrafficImg
                  : TravelImg;
            return (
              <div key={el.id} className="driver-summary-wrapper">
                <div
                  className="driver-summary-card"
                  style={{ backgroundColor: el.color }}
                >
                  <img src={imgName} alt="img" />
                  <div>&nbsp;{el.paramValue}%</div>
                  <div className="driver-summary-tooltip">
                    <div>Elementary score: {el.elementaryScore}</div>
                    <div>Weight of element: {el.weightOfElement}</div>
                    <div>{el.message}</div>
                  </div>
                </div>
                <div className="driver-summary-text">{el.paramName}</div>
              </div>
            );
          })}


      </div>
      {noDataFound && (
        <div>
          <LottieLoad />
        </div>
      )}
    </div>
  );
};

export default GetDriverBehaviourSummary;
