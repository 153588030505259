import "./Select.scss";
import { useEffect, useState } from "react";
import upArrow from "../../../assets/arrow-drop-down.svg";
import downArrow from "../../../assets/arrow-drop-down.svg";
import tickIcon from "../../../assets/check.svg";
import useCloseOnClickOutside from "../../../hooks/useCloseOnClickOutside";

const Select = (props) => {
  const {
    heading,
    headingIcon,
    preIcon,
    options = [],
    handleOptionClick,
    defaultSelected,
    defaultSelectedObj,
    optionsWithUniqueIdentifier = false,
    styles,
    containerClass,
    secondaryContainerClass,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(defaultSelected || heading);
  const [selectedKey, setSelectedKey] = useState(
    defaultSelectedObj?.label || ""
  );

  useEffect(() => {
    setSelectedKey(defaultSelectedObj?.label);
  }, []);

  useEffect(() => {
    setSelected(defaultSelected);
  }, [defaultSelected]);

  const toggleSelect = () => {
    setIsOpen(!isOpen);
  };

  const closeSelect = () => {
    setIsOpen(false);
  };
  let selectRef = useCloseOnClickOutside(() => {
    setIsOpen(false);
  });

  const handleClick = (val) => {
    setSelected(val);
    handleOptionClick(val);
    setIsOpen(!isOpen);
  };

  const handleObjectClick = (option) => {
    setSelectedKey(option.label);
    handleOptionClick(option);
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={`select-container ${containerClass}`}
      ref={selectRef}
      style={{ ...styles?.container }}
    >
      {heading && (
        <div className="heading-label">
          {headingIcon && (
            <img
              className="heading-icon-img"
              src={headingIcon}
              alt="pre-icon"
            />
          )}
          {heading}
        </div>
      )}
      <div
        className={`heading-container ${secondaryContainerClass}`}
        onClick={toggleSelect}
        style={{ ...styles?.headingContainer }}
      >
        <div className="flex">
          {preIcon && (
            <img className="pre-icon-img" src={preIcon} alt="pre-icon" />
          )}
          {optionsWithUniqueIdentifier ? (
            <>
              <span className="heading" style={{ ...styles?.selectedKey }}>
                {selectedKey}
              </span>
            </>
          ) : (
            <>
              <span className="heading" style={{ ...styles?.selected }}>
                {selected}
              </span>
            </>
          )}
        </div>
        {isOpen ? (
          <img className="arrow-icon" src={upArrow} alt="down-arrow" />
        ) : (
          <img className="arrow-icon" src={downArrow} alt="down-arrow" />
        )}
      </div>
      <div className="select-options-container">
        {isOpen && (
          <div
            className="select-option"
            style={{ ...styles?.selectOptionContainer }}
          >
            {!optionsWithUniqueIdentifier ? (
              <>
                {options.map((el, index) => (
                  <div
                    className={`option-value option-box${index}`}
                    onClick={() => handleClick(el)}
                  >
                    <span>{el}</span>
                    <img
                      className={
                        `tick-icon ` + (el === selected ? "selected" : "")
                      }
                      alt="tick-icon"
                      src={tickIcon}
                    ></img>
                  </div>
                ))}
              </>
            ) : (
              <>
                {options.map((option, index) => (
                  <div
                    key={option.key}
                    className={`option-value option-box${index}`}
                    onClick={() => handleObjectClick(option)}
                  >
                    <span>{option.label}</span>
                    <img
                      className={
                        `tick-icon ` +
                        (option.value === selected ? "selected" : "")
                      }
                      alt="tick-icon"
                      src={tickIcon}
                    ></img>
                  </div>
                ))}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Select;
