import { RightVehiclePagesContainerCardPrognostic } from "../components/VehicleCard";
import "../VehiclePopulation.scss";
import Altitude from "../assets/Altitude-image.svg";
import Make from "../assets/make-image.svg";
import Model from "../assets/model-image.svg";
import ModelYear from "../assets/model-year-image.svg";
import Manufact from "../assets/manufact-image.svg";
import Build_date from "../assets/build-dates-image.svg";
import Powerpack from "../assets/power-pack-image.svg";
import Subsystem from "../assets/subsystem-image.svg";
import Software from "../assets/software-image.svg";
import Calibration from "../assets/calibration-image.svg";
import Dtc from "../assets/dtc-image.svg";
import Temperature from "../assets/Temperature-image.svg";
import System_st from "../assets/system-st-image.svg";
import Operating from "../assets/operating-image.svg";
import Customer from "../assets/customer-image.svg";
import Rul from "../assets/Rul.svg";
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';


const RightVehiclePagesContainerPrognostic = () => {
    const location = useLocation();

    const [selectedNumber, setSelectedNumber] = useState(null);
  
     const handleCardClick = (containerId) => {
    if (selectedNumber !== containerId) {
      setSelectedNumber(containerId);
    }
  };

    const Data = [
        {
            img: Make,
            text: "Make",
            handleClick: "/vehicle-population/prognostic/make",
            containerId: 0,
            containerName:"make"
        },
        {
            img: Model,
            text: "Model",
            handleClick: "/vehicle-population/prognostic/model",
            containerId: 1,
            containerName:"model"
        },
        {
            img: ModelYear,
            text: "Model Year",
            handleClick: "/vehicle-population/prognostic/model-year",
            containerId: 2,
            containerName:"modelYear"
        },
        {
            img: Manufact,
            text: "Manufact...",
            handleClick: "/vehicle-population/prognostic/manufacture",
            containerId: 3,
            containerName:"manufacture"
        },
        {
            img: Build_date,
            text: "Build dates",
            handleClick: "/vehicle-population/prognostic/build-dates",
            containerId: 4,
            containerName:"buildDates"
        },
        {
            img: Powerpack,
            text: "Powerpack",
            handleClick: "/vehicle-population/prognostic/powerpack",
            containerId: 5,
            containerName: "powerpack",
        },
        {
            img: Subsystem,
            text: "Subsystem",
            handleClick: "/vehicle-population/prognostic/subsystem",
            containerId: 6,
            containerName: "subsystem",
        },
        {
            img: Software,
            text: "Software...",
            handleClick: "/vehicle-population/prognostic/software",
            containerId: 7,
            containerName: "software",
        },
        {
            img: Calibration,
            text: "Calibration...",
            handleClick: "/vehicle-population/prognostic/calibration",
            containerId: 8,
            containerName: "calibration",
        },
        {
            img: Dtc,
            text: "DTC",
            handleClick: "/vehicle-population/prognostic/dtc",
            containerId: 9,
            containerName: 'dtc',
        },
        {
            img: Temperature,
            text: "Temperature",
            handleClick: "/vehicle-population/prognostic/temperature",
            containerId: 10,
            containerName: "temperature",
        },
        {
            img: Altitude,
            text: "Altitude",
            handleClick: "/vehicle-population/prognostic/altitude",
            containerId: 11,
            containerName: "altitude",
        },
        {
            img: System_st,
            text: "System St...",
            handleClick: "/vehicle-population/prognostic/system-state",
            containerId: 12,
            containerName: "systemState",
        },
        {
            img: Operating,
            text: "Operating...",
            handleClick: "/vehicle-population/prognostic/operating",
            containerId: 13,
            containerName: "operating",
        },
        {
            img: Customer,
            text: "Customer...",
            handleClick: "/vehicle-population/prognostic/customer",
            containerId: 14,
            containerName: "customer",
        },
        {
            img: Rul,
            text: "RUL",
            handleClick: "/vehicle-population/prognostic/rul",
            containerId: 15,
            containerName: "rul",
        },

    ]

    useEffect(() => {
        // check if the current location matches the target URL
        if (location.pathname === "/vehicle-population/prognostic/pages") {
          // set the classname of the selected component to "box2"
          setSelectedNumber(parseInt(location.search.replace("?key=", ""), 10));
        } else {
          setSelectedNumber(null);
        }
      }, [location.pathname, location.search]);
    return (
        <div className="right-vehicle-container">
            {Data.map((Value) => (
                <RightVehiclePagesContainerCardPrognostic
                key={Value.containerId}
                img={Value.img}
                text={Value.text}
                containerId={Value.containerId}
                isSelected={selectedNumber === Value.containerId}
                handleClick={handleCardClick}
                />

            ))}
        </div>
    )

}

export default RightVehiclePagesContainerPrognostic