import React from "react";
import { useNavigate } from "react-router";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import "../../VehiclePopulation.scss";
import { useLocation } from "react-router";

export const GeneratedDashboardCard = ({
  img,
  text,
  secondText,
  thirdText,
  graphContainer,
  containerId,
  handleClick,
  isSelected,
  pathName,
  dashboardType,
}) => {
  const location = useLocation();
  const keyValue = parseInt(location.search.slice(5));
  const className =
    keyValue === containerId ? "white-container-tab" : "orange-container-tab";

  return (
    <>
      <NavLink
        to={{
          pathname: `/vp-saved-filter/comparision/GeneratedDashboard/${pathName}/${dashboardType}`,
          search: containerId !== null ? `?key=${containerId}` : "",
        }}
        className={className}
        onClick={() => handleClick(containerId)}
      >
        <div>
          <p
            style={{
              position: "relative",
              bottom: "6px",
              left: "4px",
              fontSize: "13px",
              fontWeight: "500",
              marginBottom: "2px",
              width: "90%",
            }}
          >
            {text}
            <br />
            {secondText}
            <br />
            <span style={{ fontWeight: "400" }}>{thirdText}</span>
            <br />
          </p>
        </div>

        {dashboardType === "cwv" ? (
          graphContainer === "Up-down" ? (
            <div
              className={`box-container ${
                containerId === 0
                  ? "b-c--forcwv1"
                  : containerId === 1
                  ? "b-c--forcwv2"
                  : containerId === 3
                  ? "b-c--forcwv3"
                  : ""
              }`}
            >
              <div className="grid-item tab1"></div>
              <div className="grid-item tab2"></div>
              <div className="grid-item tab3"></div>
              <div className="grid-item tab4"></div>
              <div className="grid-item tab5"></div>
              <div className="grid-item tab6"></div>
            </div>
          ) : graphContainer === "Down-up" ? (
            <div className="box-container b-c--forcwv">
              <div className="grid-item tab6"></div>
              <div className="grid-item tab5"></div>
              <div className="grid-item tab4"></div>
              <div className="grid-item tab3 "></div>
              <div className="grid-item tab2"></div>
              <div className="grid-item tab1"></div>
            </div>
          ) : (
            <>
              <img className="img-icon" src={img} alt="wa" />
            </>
          )
        ) : dashboardType === "cwf" ? (
          graphContainer === "Up-down" ? (
            <div
              className={`box-container-cwf ${
                containerId === 0
                  ? "b-c--forcwf1"
                  : containerId === 1
                  ? "b-c--forcwf2"
                  : containerId === 2
                  ? "b-c--forcwf3"
                  : containerId === 3
                  ? "b-c--forcwf4"
                  : ""
              }`}
            >
              {containerId === 0 ? (
                <>
                  <div className="grid-item tab1"></div>
                  <div className="grid-item tab2"></div>
                  <div className="grid-item tab3"></div>
                  <div className="grid-item tab4"></div>
                  <div className="grid-item tab11"></div>
                  <div className="grid-item tab5"></div>
                  <div className="grid-item tab6"></div>
                  <div className="grid-item tab7"></div>
                  <div className="grid-item tab11"></div>
                  <div className="grid-item tab8"></div>
                  <div className="grid-item tab9"></div>
                  <div className="grid-item tab10"></div>
                </>
              ) : containerId === 1 ? (
                <>
                  <div className="grid-item tab1"></div>
                  <div className="grid-item tab2"></div>
                  <div className="grid-item tab3"></div>
                  <div className="grid-item tab4"></div>
                  <div className="grid-item tab11"></div>
                  <div className="grid-item tab5"></div>
                  <div className="grid-item tab11"></div>
                  <div className="grid-item tab6"></div>
                  <div className="grid-item tab11"></div>
                  <div className="grid-item tab7"></div>
                  <div className="grid-item tab11"></div>
                  <div className="grid-item tab8"></div>
                  <div className="grid-item tab11"></div>
                  <div className="grid-item tab9"></div>
                </>
              ) : containerId === 3 ? (
                <>
                  <div className="grid-item tab1"></div>
                  <div className="grid-item tab2"></div>
                  <div className="grid-item tab11"></div>
                  <div className="grid-item tab3"></div>
                  <div className="grid-item tab4"></div>
                  <div className="grid-item tab11"></div>
                  <div className="grid-item tab5"></div>
                  <div className="grid-item tab11"></div>
                  <div className="grid-item tab6"></div>
                  <div className="grid-item tab11"></div>
                  <div className="grid-item tab7"></div>
                  <div className="grid-item tab11"></div>
                  <div className="grid-item tab8"></div>
                </>
              ) : (
                <></>
              )}
            </div>
          ) : graphContainer === "Down-up" ? (
            <div className="box-container-cwf b-c--forcwf5">
              <div className="grid-item tab10"></div>
              <div className="grid-item tab9"></div>
              <div className="grid-item tab11"></div>
              <div className="grid-item tab8"></div>
              <div className="grid-item tab7"></div>
              <div className="grid-item tab11"></div>
              <div className="grid-item tab6"></div>
              <div className="grid-item tab5"></div>
              <div className="grid-item tab11"></div>
              <div className="grid-item tab4"></div>
              <div className="grid-item tab3 "></div>
              <div className="grid-item tab11"></div>
              <div className="grid-item tab2"></div>
              <div className="grid-item tab1"></div>
            </div>
          ) : (
            <>
              <img className="img-icon" src={img} alt="wa" />
            </>
          )
        ) : graphContainer === "Up-down" ? (
          <div className="box-container">
            <div className="grid-item tab1"></div>
            <div className="grid-item tab2"></div>
            <div className="grid-item tab3"></div>
            <div className="grid-item tab4"></div>
            <div className="grid-item tab5"></div>
            <div className="grid-item tab6"></div>
          </div>
        ) : graphContainer === "Down-up" ? (
          <div className="box-container">
            <div className="grid-item tab6"></div>
            <div className="grid-item tab5"></div>
            <div className="grid-item tab4"></div>
            <div className="grid-item tab3"></div>
            <div className="grid-item tab2"></div>
            <div className="grid-item tab1"></div>
          </div>
        ) : (
          <>
            <img className="img-icon" src={img} alt="wa" />
          </>
        )}
      </NavLink>
    </>
  );
};
