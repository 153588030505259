import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { handlePerProfile } from "./RemoteTesting";
import { LineChart } from "../../../common/LineGraph";
import { useSelector } from "react-redux";
import { RemoteTestingGraph } from "./Graph/RemoteTestingGraph";
import VehicleHealthCheck from "../../index";
import Diagnostics from "../Diagnostics";
import ChartLoader from "../../../common/ErrorComponents/ChartLoader";

const RemotePerformanceProfile = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const urlEnd = searchParams.get("urlEnd");
  const imgUrlEnd = searchParams.get("imgUrlEnd");
  const graphUrl = searchParams.get("graphUrl");
  const [chartData, setChartData] = useState([]);
  const [timeAxis, setTimeAxis] = useState("");
  const [dataWithImg, setDataWithImg] = useState([]);
  const [data, setData] = useState("");
  const [testExecutionData, setTestExecutionData] = useState([]);
  const vin_Number = useSelector((state) => state.mainReducer.vin_Number);

  useEffect(() => {
    if (chartData?.length) {
      setData(chartData);
    }
  }, [JSON.stringify(chartData)]);

  useEffect(() => {
    handlePerProfile({
      urlEnd,
      imgUrlEnd,
      saveData: setChartData,
      saveImgData: setDataWithImg,
      xAxisSaveData: setTimeAxis,
      graphUrl,
      vin_Number,
    });
  }, []);

  useEffect(() => {
    var str = dataWithImg?.[0]?.testExecution;
    var array = str?.split(".");
    setTestExecutionData(array);
  }, [dataWithImg]);

  const handleViewDiagram = ({ routePath, urlEnd, imgUrlEnd, graphUrl }) => {
    navigate(
      "/vehicle-health/diagnostics/remote-testing/" +
        routePath +
        "?" +
        "urlEnd=" +
        urlEnd +
        "&imgUrlEnd=" +
        imgUrlEnd +
        "&graphUrl=" +
        graphUrl,

      {
        replace: true,
      }
    );
  };

  if (chartData == 0) {
    return <ChartLoader />
  }
  return (
    <div className="page-wrapper" style={{ marginTop: "20px" ,backgroundColor:"#EEEEEE" }}>
      <div className="full-width vehicle-health-check" style={{ marginTop: "20px" }}>
        <VehicleHealthCheck />
      </div>
      <Diagnostics />
      <div className="rem-test-abs-diagram-card" style={{ marginTop: "20px" }}>
        <div className="re-test-abs-wrapper">
          <div className="rem-test-diagrams">
            <div className="rem-test-graph">
              {chartData?.length > 0 && dataWithImg?.length > 0 && (
                <div
                  onClick={() =>
                    handleViewDiagram({
                      routePath: "performance-profile",
                      urlEnd: urlEnd,
                      imgUrlEnd: imgUrlEnd,
                      graphUrl: graphUrl,
                    })
                  }
                >
                  <RemoteTestingGraph
                    id={dataWithImg?.[0]?.id}
                    data={data}
                    onClickHandler={() => {}}
                    title={dataWithImg?.[0]?.title
                      ?.split("_")
                      .join(" ")
                      ?.toUpperCase()}
                    xLabel={dataWithImg?.[0]?.xAxisName}
                    xAxisData={timeAxis}
                    yAxisLabel={dataWithImg?.[0]?.yAxisName}
                    showLegend={true}
                  />
                </div>
              )}
            </div>
            <div className="rem-test-abs-diagram">
              <img
                className="rem-test-abs-diagram-image"
                src={dataWithImg?.[0]?.imageURL}
                alt={dataWithImg?.[0]?.graphName}
                height={350}
              />
              <div className="rem-img-text">ABS Diagram</div>
            </div>
          </div>
          <div className="rem-test-diag-text">
            <div className="rem-test-diag-text-heading">
              Purpose:
            </div>
            <div className="rem-test-diag-text-description">
              {dataWithImg?.[0]?.purpose}
            </div>
            <div className="rem-test-diag-text-heading" >
              Test Prerequisite:
            </div>
            <div className="rem-test-diag-text-description" >
              {dataWithImg?.[0]?.testPrerequisite}
            </div>
            <div className="rem-test-diag-text-heading">
              Test Execution:
            </div>
            <ul style={{"paddingBottom":"20px"}}>
              {
                testExecutionData?.map((item, i) => <li key={i}>{item}</li>)
              }
              {/* <li>
              {dataWithImg?.[0]?.testExecution} 
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemotePerformanceProfile;
