import React from "react";
import "./index.scss";
import login_left_car from "../../assets/login_left_car.svg";


const LoginLayout = () => {
  return (
    <div className="layout__container">
      <div className="layout__container--content">
        <div className="layout__container--image">
          <img src={login_left_car} width="100%" height="100%" alt="car" />
        </div>
      </div>
    </div>
  );
};

export default LoginLayout;
