import moment from "moment";
import React from "react";
import { useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import RightArrowLongIcon from "../assets/right-arrow-long.svg";
import LengendContainer from "./LengendContainer";
import { useSelector } from "react-redux";
import { colorMap, getTickIntervals, graphType } from "../utils/constants";
import NoDataFound from "./NoDataFound";

const TimeVehicleInMotion = ({ data, filterType }) => {
  const isSidemenuExpanded = useSelector(
    (state) => state.mainReducer.isSidemenuExpanded
  );

  const [odometerTick, setOdometerTick] = useState([]);

  const [chartData, setChartData] = useState(null);
  const [timeVehicleInMotionArray, setTimeVehicleInMotionArray] =
    useState(null);
  const [chartInfo, setChartInfo] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);

  useEffect(() => {
    setChartData(data?.timeVehicleInMotion);
    if (data && data?.timeVehicleInMotion.length > 0) {
      let timeVehicleInMotionArray = [];
      switch (filterType) {
        case graphType.DATE: {
          timeVehicleInMotionArray = data.timeVehicleInMotion.map((item) => [
            moment(item.statsDate).format("DD"),
            item.timeVehicleInMotionWrtTotalEngineRuntime,
          ]);
          break;
        }

        case graphType.MILES: {
          timeVehicleInMotionArray = data.timeVehicleInMotion.map((item) => [
            item.odometer,
            item.timeVehicleInMotionWrtTotalEngineRuntime,
          ]);
          if (timeVehicleInMotionArray.length > 1) {
            const tickArray = data.timeVehicleInMotion
              .map((item) => item.odometer)
              .sort((a, b) => a - b);
            const tArray = getTickIntervals(tickArray);
            setOdometerTick(tArray);
          }
          break;
        }
        case graphType.TRIP: {
          timeVehicleInMotionArray = data.timeVehicleInMotion.map((item) => [
            item.trip,
            item.timeVehicleInMotionWrtTotalEngineRuntime,
          ]);
          break;
        }
        default: {
          timeVehicleInMotionArray = data.timeVehicleInMotion.map((item) => [
            moment(item.statsDate).format("DD"),
            item.timeVehicleInMotionWrtTotalEngineRuntime,
          ]);
        }
      }

      setChartInfo(data?.information);
      setTimeVehicleInMotionArray(timeVehicleInMotionArray);
      const month = moment(data?.timeVehicleInMotion[0].statsDate).format(
        "MMMM"
      );
      const year = moment(data?.timeVehicleInMotion[0].statsDate).format(
        "YYYY"
      );
      setMonth(month);
      setYear(year);
    } else {
      setTimeVehicleInMotionArray(null);
    }
  }, [data]);

  const options = {
    chart: {
      backgroundColor: "#FFFFFF",
      spacing: [50, 50, 30, 20],
      height: 350,
      type: "scatter",
      // borderColor: "#CECECE",
      borderRadius: 20,
      // borderWidth: 1,
      events: {
        load: function () {
          this.series[1]?.update({
            enableMouseTracking: false,
          });
        },
      },
    },
    title: {
      text: null,
    },
    yAxis: {
      title: {
        useHTML: true,
        text: `
        <div style="font-size: 11px; left:-150px;top:4px;position:absolute;">
         Total Engine Runtime (%)
          <img src='${RightArrowLongIcon}'/>
        </div>
        `,
        align: "high",
      },
      min: 0,
      labels: {
        style: {
          color: "#353536",
          lineHeight: 1,
          font: "10px Open Sans",
          letterSpacing: "1px",
        },
      },
      gridLineColor: "#F2F2F2",
    },
    xAxis: {
      type: "category",
      tickPositions: filterType === graphType.MILES ? odometerTick : null,
      title: {
        useHTML: true,
        text: `
         <div style="font-size: 11px; left:-60px;top:4px;position:absolute;">
           ${
             filterType === graphType.DATE
               ? `${month},${year}`
               : filterType === graphType.MILES
               ? `Miles`
               : filterType === graphType.TRIP
               ? `Trips`
               : `${month},${year}`
           }
          <img src='${RightArrowLongIcon}'/>
        </div>
        `,
        align: "high",
      },
      labels: {
        style: {
          color: "#353536",
          lineHeight: 1,
          font: "10px Open Sans",
          letterSpacing: "1px",
        },
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: true,
        },
      },
      line: {
        dashStyle: "Dash",
      },
    },
    tooltip: {
      backgroundColor:"#000",
      style: {
        color: "#FFFFFF",
        font: "12px Open Sans",
      },
    },
    series: [
      {
        data: timeVehicleInMotionArray,
        showInLegend: false,
        name: "Alternator Efficiency (%)",
        regression: timeVehicleInMotionArray?.length > 1 ? true : false,
        regressionSettings: {
          type: "polynomial",
          order: "2",
          color: chartInfo && colorMap[chartInfo[0]?.color],
          dashStyle: "dot",
        },
        color: chartInfo && colorMap[chartInfo[0]?.color],
      },
    ],
  };
  return (
    <div key={timeVehicleInMotionArray}>
      <div key={isSidemenuExpanded}>
        {Array.isArray(timeVehicleInMotionArray) &&
        timeVehicleInMotionArray.length > 0 ? (
          <>
            <LengendContainer chartInfo={chartInfo} />
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              updateArgs={[true, true, true]}
            />
            <div
              style={{ width: "100%", textAlign: "center", paddingTop: "10px" }}
            >
              <p style={{ fontSize: "14px", fontWeight: "600" }}>
                Time Vehicle in Motion / Total Engine Runtime (%)
              </p>
            </div>
          </>
        ) : (
          <>
            <NoDataFound chartName="Time Vehicle in Motion / Total Engine Runtime (%)" />
          </>
        )}
      </div>
    </div>
  );
};

export default TimeVehicleInMotion;
