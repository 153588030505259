import React, { useState, useEffect } from "react";
import "./index.scss";
import { Button } from "../../components/common/Button/Button";
import Input from "../../components/common/Input";
import Forgotpassword from "./Forgotpassword";
import Resetpassword from "./Resetpassword";
import { Link, useNavigate, useLocation, useMatch } from "react-router-dom";
import * as message from "../../utils/messages";
import * as regex from "../../utils/regex";
import LoginLayout from "../../components/Layout/LoginLayout";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../store/actions/authActions";
import logo from "../../assets/logo_blue.png";
import * as AuthService from "../../services/auth-service";
import Modal from "../../components/common/Modal/Modal";
import rightArrow from "../../assets/right-arrow-mark.svg";

const Login = () => {
  const location = useLocation();
  const matchresetPwd = useMatch({
    path: "/reset-password/:emailId",
    caseSensitive: true,
    end: true,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.authReducer);
  const [successMsg, setSuccessMsg] = useState(null);
  const [failedLoginAttempts, setFailedLoginAttempts] = useState(0);
  const [errorMsg, setErrorMsg] = useState({
    login: "",
    forgotpwd: "",
    setpwd: "",
  });
  const [showModal, setShowModal] = useState({
    showForgotPwdModal: false,
    showSetPwdModal: false,
    showLimitExceedModal: false,
  });
  const [formInputs, setForminputs] = useState({
    email: "",
    password: "",
    FPemail: "",
    SPverifycode: "",
    SPpassword: "",
    SPcpassword: "",
  });
  const [error, setError] = useState({
    email: "",
    password: "",
    FPemail: "",
    SPverifycode: "",
    SPpassword: "",
    SPcpassword: "",
  });
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit(e);
    }
  };
  const handleKeyDownFP = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleForgotPwdSubmit(e);
    }
  };
  const handleKeyDownSP = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSetPwdSubmit(e);
    }
  };
  const handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setForminputs({ ...formInputs, [name]: value });
    validate(name, value);
    setErrorMsg({
      login: "",
      forgotpwd: "",
      setpwd: "",
    });
    setSuccessMsg(null);
  };

  const handleSetPwdModalShowhide = () => {
    setShowModal({ ...showModal, showSetPwdModal: !showModal.showSetPwdModal });

    setForminputs({
      ...formInputs,
      SPverifycode: "",
      SPpassword: "",
      SPcpassword: "",
    });
    setError({
      email: "",
      password: "",
      FPemail: "",
      SPverifycode: "",
      SPpassword: "",
      SPcpassword: "",
    });
    setErrorMsg({ ...errorMsg, setpwd: "" });
  };
  const handleForgotPwdModalShowhide = () => {
    setShowModal({
      ...showModal,
      showForgotPwdModal: !showModal.showForgotPwdModal,
      showLimitExceedModal: false,
    });

    setForminputs({ ...formInputs, FPemail: "" });
    setError({
      email: "",
      password: "",
      FPemail: "",
      SPverifycode: "",
      SPpassword: "",
      SPcpassword: "",
    });
    setErrorMsg({ ...errorMsg, forgotpwd: "" });
  };
  const handleSetPwdSubmit = (e) => {
    e.preventDefault();
    validate("SPverifycode", formInputs.SPverifycode);
    validate("SPpassword", formInputs.SPpassword);
    validate("SPcpassword", formInputs.SPcpassword);
    if (validateForm(error)) {
      AuthService.resetPassword({
        emailId: formInputs.FPemail,
        password: formInputs.SPpassword,
        confirmPassword: formInputs.SPcpassword,
        confirmationCode: formInputs.SPverifycode,
      })
        .then((res) => {
          if (res.status === 200) {
            setShowModal({
              ...showModal,
              showSetPwdModal: false,
            });
          }
        })
        .catch((err) => {
          if (err && err.response && err.response.status) {
            let errdata = err.response.data.detail;
            if (errdata.includes("Could not find user in our system")) {
              setErrorMsg({ ...errorMsg, setpwd: message.EMAIL_NOT_FOUND });
            } else {
              setErrorMsg({ ...errorMsg, setpwd: errdata });
            }
          } else {
            setErrorMsg({
              ...errorMsg,
              setpwd: message.SOMETHING_WENT_WRONG,
            });
          }
        });
    }
  };

  const handleForgotPwdSubmit = (e) => {
    e.preventDefault();
    validate("FPemail", formInputs.FPemail);
    if (validateForm(error)) {
      AuthService.forgotPassword({ emailId: formInputs.FPemail })
        .then((res) => {
          if (res.status === 200) {
            setShowModal({
              ...showModal,
              showForgotPwdModal: false,
              showSetPwdModal: true,
            });
          }
        })
        .catch((err) => {
          if (err && err.response && err.response.status) {
            let errdata = err.response.data.detail;
            if (errdata.includes("application is restricted by the admin")) {
              setErrorMsg({ ...errorMsg, forgotpwd: message.CONTACT_ADMIN });
            } else {
              setErrorMsg({ ...errorMsg, forgotpwd: message.EMAIL_NOT_FOUND });
            }
          } else {
            setErrorMsg({
              ...errorMsg,
              forgotpwd: message.SOMETHING_WENT_WRONG,
            });
          }
        });
    }
  };
  const validate = (name, value) => {
    let errors = error;
    switch (name) {
      case "email":
        errors.email =
          value.length === 0
            ? message.EMAIL_REQUIRED
            : !regex.EMAIL_REGEX.test(value)
            ? message.EMAIL_VAILD
            : "";
        break;
      case "password":
        errors.password =
          value.length === 0
            ? message.PASSWORD_REQUIRED
            : !regex.PASSWORD_VALUE_REGEX.test(value)
            ? message.PASSWORD_VALID
            : value.length < 8 || value.length > 20
            ? message.PASSWORD_MINMAX_REQUIRED
            : "";
        break;
      case "FPemail":
        errors.FPemail =
          value.length === 0
            ? message.EMAIL_RESETPWD_REQUIRED
            : !regex.EMAIL_REGEX.test(value)
            ? message.EMAIL_RESETPWD_VAILD
            : "";
        break;
      case "SPverifycode":
        errors.SPverifycode =
          value.length === 0
            ? message.CODE_REQUIRED
            : !regex.NUMBER_REGEX.test(value)
            ? message.CODE_INVALID
            : "";
        break;
      case "SPpassword":
        errors.SPpassword =
          value.length === 0
            ? message.UPDATE_PASSWORD_REQUIRED
            : !regex.PASSWORD_VALUE_REGEX.test(value)
            ? message.PASSWORD_VALID
            : value.length < 8 || value.length > 20
            ? message.PASSWORD_MINMAX_REQUIRED
            : "";
        break;
      case "SPcpassword":
        errors.SPcpassword =
          value.length === 0
            ? message.CONFIRM_PASSWORD_REQUIRED
            : !(value.length > 0 && formInputs.SPpassword === value)
            ? message.PASSWORD_MISMATCH
            : "";
        break;
      default:
        break;
    }
    setError({ ...error, errors });
  };
  const validateForm = (err) => {
    let valid = true;
    Object.values(err).forEach((val) => val.length > 0 && (valid = false));
    return valid;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    validate("email", formInputs.email);
    validate("password", formInputs.password);
    if (validateForm(error)) {
      const data = {
        email: formInputs.email,
        password: formInputs.password,
      };
      dispatch(login(data))
        .then(() => {
          navigate("/dashboard", {
            replace: true,
          });
          window.location.reload();
        })
        .catch((error) => {
          if (error?.response.data?.status) {
            if (
              error?.response.data?.status === 401 ||
              error?.response.data?.status === 404
            ) {
              setErrorMsg({ ...errorMsg, login: message.INVALID_lOGIN });
              setFailedLoginAttempts(failedLoginAttempts + 1);
            } else {
              setErrorMsg({ ...errorMsg, login: error?.response.data?.detail });
            }
          } else {
            setErrorMsg({ ...errorMsg, login: message.INVALID_lOGIN });
          }
        });
    }
  };
  useEffect(() => {
    if (isLoggedIn) {
      navigate("/dashboard", {
        replace: true,
      });
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (location?.state?.msg) {
      setSuccessMsg(location?.state?.msg);
    }
    if (matchresetPwd !== null) {
      validate("FPemail", matchresetPwd.params.emailId);
      if (validateForm(error)) {
        setForminputs({ ...formInputs, FPemail: matchresetPwd.params.emailId });
        setShowModal({ ...showModal, showSetPwdModal: true });
      } else {
        setError({ ...error, FPemail: "" });
        setShowModal({ ...showModal, showSetPwdModal: false });
      }
    } else {
      setShowModal({ ...showModal, showSetPwdModal: false });
    }
  }, [location]);
  useEffect(() => {
    if (failedLoginAttempts >= 4) {
      setShowModal({
        ...showModal,
        showLimitExceedModal: true,
      });
    }
  }, [failedLoginAttempts]);

  return (
    <>
      <div className="login">
        <LoginLayout />
        <div className="login__Form">
          <div className="login__rightside__container">
            <div className="login--logo">
              <img
                src={logo}
                width="100%"
                height="100%"
                alt="autonostix_logo"
              ></img>
            </div>
            <div className="login__Form--title">
              <p>Welcome back!</p>
            </div>
            <span className="Form--error">{errorMsg.login}</span>
            <span className="Form--success">{successMsg}</span>
            <div className="login__Form--inputs">
              <Input
                htmlForId="email"
                type="text"
                placeholder="Enter your email ID"
                value={formInputs.email}
                handleOnChange={handleInputChange}
                error={error.email}
                onKeyDown={handleKeyDown}
                onBlur={handleInputChange}
              />
              <Input
                htmlForId="password"
                type="password"
                placeholder="Password"
                value={formInputs.password}
                handleOnChange={handleInputChange}
                error={error.password}
                onKeyDown={handleKeyDown}
                onBlur={handleInputChange}
              />
            </div>
            <div className="login__Form--forgettext">
              <span onClick={handleForgotPwdModalShowhide}>
                Forgot password?
              </span>
            </div>
            <div className="login-form-footer">
              <div className="login__Form--footer">
                <span>Don’t have an account yet?</span>
                <span>
                  <Link to="/register">Create an account</Link>
                </span>
              </div>
              <div className="login__Form--btn">
                <div onClick={handleSubmit} className="btn-primary-blue">
                  Get Started
                  <div style={{ marginTop: "5px" }}>
                    <img src={rightArrow} alt="rightArrow" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal.showForgotPwdModal && (
        <Forgotpassword
          handleFPInputChange={handleInputChange}
          FPemail={formInputs.FPemail}
          error={error}
          errorFPMsg={errorMsg.forgotpwd}
          handleClose={handleForgotPwdModalShowhide}
          handleClick={handleForgotPwdSubmit}
          handleKeyDown={handleKeyDownFP}
        />
      )}
      {showModal.showSetPwdModal && (
        <Resetpassword
          handleSPInputChange={handleInputChange}
          SPverifycode={formInputs.SPverifycode}
          SPpassword={formInputs.SPpassword}
          SPcpassword={formInputs.SPcpassword}
          error={error}
          errorSPMsg={errorMsg.setpwd}
          handleClose={handleSetPwdModalShowhide}
          handleClick={handleSetPwdSubmit}
          handleKeyDown={handleKeyDownSP}
        />
      )}
      {showModal.showLimitExceedModal && (
        <Modal
          type={"medium"}
          title={""}
          titlePosition={"center"}
          isError={false}
          displayCloseIcon={false}
        >
          <div className="adminverify__pop">
            <div className="adminverify__pop--title">
              <p>Limit Exceeded</p>
            </div>
            <div className="adminverify__pop--inputs">
              <p>
                you have already tried 4 number of times. Please try to reset
                your password to login
              </p>
              <Button
                title="Forgot Password"
                handleClick={handleForgotPwdModalShowhide}
                styleClasses="btn-primary-blue"
              />
            </div>
            <div className="limitexceed--footer">
              <p>
                <span
                  onClick={() => {
                    setShowModal({
                      ...showModal,
                      showLimitExceedModal: !showModal.showLimitExceedModal,
                    });
                  }}
                >
                  Close
                </span>
              </p>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Login;
