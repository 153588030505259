import "./scss/App.scss";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  // useRoutes,
} from "react-router-dom";
import store from "./store";
import Details from "./components/Details";
import DashBoard from "./pages/Dashboard";
import VehicleUsageProfile from "./pages/VehicleUsageProfile/VehicleUsageProfile";
import routes, {
  MaintenanceLogsRoutes,
  MODServiceGuideDetailsRoutes,
  NavigationMenuList,
  NavigationDashBoardMenuLish,
  VehiclePopulationNavigationMenuList,
  PrognosticsServiceGuideDetailsRoutes,
  AdminNavigationMenuList,
  ComponentSystemHistoryRoutes,
  RootCauseAnalysisRoutes,
  RootCauseDataRoutes,
} from "./utils/constants";
import ServiceGuide from "./pages/MaintenanceOnDemand/ServiceGuide";
import MaintenanceLogsSummary from "./pages/MaintenanceLogs/MaintenanceLogsSummary";
import GetDriverBehaviourSummary from "./pages/Operational Analytics/GetDriverBehaviourSummary.js";
import PictorialRepresentation from "./pages/Operational Analytics/PictorialRepresentation";
import RemotePerformanceProfile from "./components/VehicleHealthCheck/Diagnostics/RemoteTesting/RemotePerformanceProfile";
import RemoteAbsDiagram from "./components/VehicleHealthCheck/Diagnostics/RemoteTesting/RemoteAbsDiagram";
import RemoteTesting from "./components/VehicleHealthCheck/Diagnostics/RemoteTesting/RemoteTesting";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import PrivateRoute from "./utils/PrivateRoute";
import IdealBehaviourPieChart from "./pages/Operational Analytics/PictorialRepresentation/IdealBehaviourPieChart";
import PrognosticsServiceGuide from "./pages/MaintenanceOnDemand/ServiceGuide/prognostics";
import ComponentSystemHistory from "./components/ComponentSystemHistory";
import RootCauseAnalysis from "./pages/RootCauseAnalysis";
import Home from "./components/Home";
import RootCauseData from "./pages/RootCauseAnalysis/RootCauseData";
import AdminDahboardLayout from "./utils/layouts/AdminDahboardLayout";
import AdminDashboard from "./pages/AdminDashboard";
import PrescribedActions from "./pages/RootCauseAnalysis/PrescribedActions";
import MainLayout from "./components/Layout/MainLayout";
import VehiclePopulation from "./pages/VehiclePopulation/index";
import React, { useState, useEffect } from "react";
import IdleTimer from "react-idle-timer";
import { useNavigate } from "react-router";
import { logout } from "../src/store/actions/authActions";
import { useDispatch } from "react-redux";
import { loginAxiosInstance } from "../src/services/service";
import ErrorPage from "./components/common/ErrorComponents/ErrorPage";

import Generalform from "../src/pages/VehiclePopulation/components/Generalform";
import Diagonisticsform from "../src/pages/VehiclePopulation/components/Diagonisticsform";
import Prognasticsform from "../src/pages/VehiclePopulation/components/Prognasticsform";
import FilterStepper from "../src/pages/VehiclePopulation/components/FilterStepper";
import FormLayout from "../src/pages/VehiclePopulation/components/FormLayout";
import { refreshLogout } from "./services/auth-service";

function App() {
  const [showModal, setShowModal] = useState(false);
  const showPopup = localStorage.getItem("token");
  let token = JSON.parse(localStorage.getItem("token"));
  let accessToken = token?.accessToken;
  let refreshToken = token?.refreshToken;
  let onActionToken = false;

  const [tab2, settab2] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleCloseModal = () => {
    setShowModal(false);
    dispatch(logout());
    navigate("/login");
  };
  const handleOnIdle = () => {
    setShowModal(true);
  };
  const handleOnAction = () => {
    if (accessToken) {
      let newDateTime = new Date().getTime() / 1000;
      const newTime = Math.trunc(newDateTime);
      const splitToken = accessToken.split(".");
      const decodeToken = atob(splitToken[1]);
      const tokenObj = JSON.parse(decodeToken);
      const newTimeMin = newTime + 5 * 60; //adding 5min faster from current time
      if (newTimeMin < tokenObj.exp) {
        onActionToken = false;
      }
      if (newTimeMin > tokenObj.exp && !onActionToken) {
        onActionToken = true;
        tokenRefresh();
      }
    }
  };

  useEffect(() => {
    checkDuplicateTab();
  }, []);

  const checkDuplicateTab = () => {
    let sessionGuid = sessionStorage.getItem("tab-uid") || createUID();
    let tabObj = JSON.parse(localStorage.getItem("browser-tab")) || null;
    let isFirstTime = true;
    sessionStorage.setItem("tab-uid", sessionGuid);
    if (
      (isFirstTime && tabObj === null) ||
      tabObj.timestamp < new Date().getTime() - 15000 ||
      tabObj?.guid === sessionGuid
    ) {
      TabObj(isFirstTime);
      setInterval(() => TabObj(isFirstTime), 1000);
      isFirstTime = false;
      return true;
    } else {
      if (isFirstTime & (tabObj?.guid !== sessionGuid)) {
        TabObj(isFirstTime);
        setInterval(() => TabObj(isFirstTime), 1000);
        isFirstTime = false;
        return true;
      }
      isFirstTime = false;
      settab2(false);
      return false;
    }
  };

  const createUID = () => {
    let guid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      let r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
    return guid;
  };

  const TabObj = (isFirstTime) => {
    let sessionGuid = sessionStorage.getItem("tab-uid") || createUID();
    let newTabObj = {
      guid: sessionGuid,
      timestamp: new Date().getTime(),
    };
    let latesttabObj = JSON.parse(localStorage.getItem("browser-tab")) || null;
    if ((isFirstTime && !latesttabObj) || sessionGuid === latesttabObj.guid) {
      localStorage.setItem("browser-tab", JSON.stringify(newTabObj));
      settab2(true);
    } else if (
      isFirstTime &&
      tab2 &&
      latesttabObj &&
      sessionGuid !== latesttabObj.guid
    ) {
      localStorage.setItem("browser-tab", JSON.stringify(newTabObj));
      settab2(true);
    } else {
      settab2(false);
    }
  };

  const tokenRefresh = () => {
    let url = `auth/refresh_token`;
    const data = { refreshToken: refreshToken };
    loginAxiosInstance
      .post(url, data)
      .then((response) => {
        if (response.status === 200) {
          let updatedtoken = JSON.parse(localStorage.getItem("token"));
          updatedtoken.accessToken = response.data.accessToken;

          localStorage.setItem("token", JSON.stringify(updatedtoken));
        } else {
          localStorage.removeItem("token");
          window.location.reload();
        }
      })
      .catch((er) => {
        throw er;
      });
  };

  const modalStyle = {
    position: "fixed",
    // top: "50%",
    // left: "50%",
    // transform: "translate(-50%, -50%)",
    background: "#000000aa",
    // padding: "20px",
    // borderRadius: "10px",
    // boxShadow: "0px 0px 10px #ccc",
    // display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1111,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  };

  const modalContentStyle = {
    textAlign: "center",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "white",
    padding: "20px",
    borderRadius: "10px",
    //boxShadow: "0px 0px 10px #ccc",
    display: "",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 11111,
  };

  if (!tab2) {
    return (
      <div className="details">
        <p>Access denied</p>
      </div>
    );
  }

  return (
    <>
      <div className="App">
        <IdleTimer
          element={window}
          onIdle={handleOnIdle}
          onAction={() => handleOnAction()}
          debounce={250}
          timeout={3600000}
        />
        {showModal &&
        showPopup &&
        setTimeout(() => {
          refreshLogout();
        }, 2000) ? (
          <div style={modalStyle}>
            <div style={modalContentStyle}>
              <p>Your Sesion Expired </p>
              <button
                onClick={handleCloseModal}
                style={{ backgroundColor: "#e58817", color: "#fff" }}
              >
                OK
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/reset-password/:emailId" element={<Login />} />
          <Route exact path="/register" element={<Signup />} />
          <Route exact path="/set-password/:emailId" element={<Signup />} />
          <Route path="*" element={<Navigate to="/" />} />

          <Route path="" element={<PrivateRoute />}>
            <Route exact path="/dashboard" element={<DashBoard />} />
            <Route exact path="/error-page" element={<ErrorPage />} />

            <Route exact path="/generalform" element={<Generalform />} />
            <Route
              exact
              path="/diagonisticsform"
              element={<Diagonisticsform />}
            />
            <Route
              exact
              path="/prognasticsform"
              element={<Prognasticsform />}
            />
            <Route exact path="/FilterStepper" element={<FilterStepper />} />
            {/* <Route exact path="/FormLayout" element={<FormLayout />} /> */}
            <Route
              exact
              path="/vehicle-population/FormLayout"
              element={<FormLayout />}
            />

            <Route exact path="/details" element={<Details />} />
            <Route
              exact
              path="/health"
              element={<h3>Hey There!!! The App is Healthy</h3>}
            />
            <Route
              exact
              path="/vehicle-usage"
              element={<VehicleUsageProfile />}
            />
            <Route
              exact
              path="/vehicle-population"
              element={<VehiclePopulation />}
            />

            <Route exact path="/admin-dashboard" element={<AdminDashboard />} />
            {/* <Route exact path="/configure-features" element={<Details />} /> */}

            {NavigationDashBoardMenuLish?.length &&
              NavigationDashBoardMenuLish.map((item) => (
                <Route
                  key={item.path}
                  exact
                  path={item.path}
                  element={item.component}
                />
              ))}

            {NavigationMenuList?.length &&
              NavigationMenuList.map((item) => (
                <Route
                  key={item.path}
                  exact
                  path={item.path}
                  element={item.component}
                >
                  {item?.nestedRoutes?.map((nestedItem) => (
                    <Route
                      key={item.path}
                      exact
                      path={nestedItem.path}
                      element={nestedItem.component}
                    >
                      {nestedItem?.nestedRoutes?.map((childItem) => (
                        <Route
                          key={item.path}
                          exact
                          path={childItem.path}
                          element={childItem.component}
                        />
                      ))}
                    </Route>
                  ))}
                </Route>
              ))}

            {AdminNavigationMenuList?.length &&
              AdminNavigationMenuList.map((item) => (
                <Route
                  key={item.path}
                  exact
                  path={item.path}
                  element={item.component}
                />
              ))}
            {/* 
            {VehiclePopulationNavigationMenuList?.length &&
              VehiclePopulationNavigationMenuList.map((item) => (
                <Route
                  key={item.path}
                  exact
                  path={item.path}
                  element={item.component}
                />
              ))} */}
            {VehiclePopulationNavigationMenuList?.length &&
              VehiclePopulationNavigationMenuList.map((item) => (
                <Route
                  key={item.path}
                  exact
                  path={item.path}
                  element={item.component}
                >
                  {item?.nestedRoutes?.map((nestedItem) => (
                    <Route
                      key={item.path}
                      exact
                      path={nestedItem.path}
                      element={nestedItem.component}
                    >
                      {nestedItem?.nestedRoutes?.map((childItem) => (
                        <Route
                          key={item.path}
                          exact
                          path={childItem.path}
                          element={childItem.component}
                        />
                      ))}
                    </Route>
                  ))}
                  {item?.children?.map((childItem) => (
                    <Route
                      key={childItem.path}
                      exact
                      path={childItem.path}
                      element={childItem.component}
                    >
                      {childItem?.nestedRoutes?.map((nestedItem) => (
                        <Route
                          key={item.path}
                          exact
                          path={nestedItem.path}
                          element={nestedItem.component}
                        >
                          {nestedItem?.nestedRoutes?.map((childItem) => (
                            <Route
                              key={item.path}
                              exact
                              path={childItem.path}
                              element={childItem.component}
                            />
                          ))}
                        </Route>
                      ))}
                    </Route>
                  ))}
                </Route>
              ))}
            <Route
              key="Service Guide"
              exact
              path="/maintenance-overview/diagnostics/service-guide"
              element={<ServiceGuide />}
            >
              {MODServiceGuideDetailsRoutes.map((item) => (
                <Route
                  key={item.title}
                  exact
                  path={item.path}
                  element={item.component}
                />
              ))}
            </Route>
            <Route
              key="Service Guide Prognostics"
              exact
              path="/maintenance-overview/prognostics/service-guide"
              element={<PrognosticsServiceGuide />}
            >
              {PrognosticsServiceGuideDetailsRoutes.map((item) => (
                <Route
                  key={item.title}
                  exact
                  path={item.path}
                  element={item.component}
                />
              ))}
            </Route>
            <Route
              key="Maintenance Logs Summary"
              exact
              path="/maintenance-log/summary"
              element={<MaintenanceLogsSummary />}
            >
              {MaintenanceLogsRoutes.map((item) => (
                <Route
                  key={item.title}
                  exact
                  path={item.path}
                  element={item.component}
                />
              ))}
            </Route>
            <Route
              key="Get Summary"
              exact
              path="/operational-analytics/driver-behaviour/summary"
              element={<GetDriverBehaviourSummary />}
            />
            <Route
              key="Pictorial Representation"
              exact
              path="/operational-analytics/driver-behaviour/summary/pictorial-representation"
              element={<PictorialRepresentation />}
            />

            <Route
              key="Root cause Data Fault Trend"
              exact
              path="/fault-analytics/root-cause-analysis/root-cause-data"
              element={<RootCauseData />}
            >
              {RootCauseDataRoutes.map((item) => (
                <Route
                  key={`${item.path} Fault Trend`}
                  exact
                  path={`/fault-analytics/root-cause-analysis/root-cause-data${item.path}`}
                  element={item.component}
                />
              ))}
            </Route>
            <Route
              key="Falut Trend Prescribed Actions"
              exact
              path="/fault-analytics/root-cause-analysis/prescribed-actions"
              element={<PrescribedActions />}
            ></Route>
            <Route
              key="Root cause Analysis Fault Trend"
              exact
              path="/maintenance-overview/diagnostics/root-cause-analysis/root-cause-data"
              element={<RootCauseData />}
            >
              {RootCauseDataRoutes.map((item) => (
                <Route
                  key={`${item.path} MOD Diagnostcs`}
                  exact
                  path={`/maintenance-overview/diagnostics/root-cause-analysis/root-cause-data${item.path}`}
                  element={item.component}
                />
              ))}
            </Route>
            <Route
              key="Falut Trend Prescribed Actions"
              exact
              path="/maintenance-overview/diagnostics/root-cause-analysis/prescribed-actions"
              element={<PrescribedActions />}
            ></Route>
            <Route
              key="Falut Trend Prescribed Actions"
              exact
              path="/maintenance-overview/prognostics/root-cause-analysis/prescribed-actions"
              element={<PrescribedActions />}
            ></Route>
            <Route
              key="Root cause Analysis Fault Trend"
              exact
              path="/maintenance-overview/prognostics/root-cause-analysis/root-cause-data"
              element={<RootCauseData />}
            >
              {RootCauseDataRoutes.map((item) => (
                <Route
                  key={`${item.path} MOD Prognostics`}
                  exact
                  path={`/maintenance-overview/prognostics/root-cause-analysis/root-cause-data${item.path}`}
                  element={item.component}
                />
              ))}
            </Route>
            <Route
              key="Component System History Vehicle Overview"
              exact
              path="/vehicle-overview/notification/component-system-history"
              element={<ComponentSystemHistory />}
            >
              {ComponentSystemHistoryRoutes.map((item) => (
                <Route
                  key={`${item.title} Vehicle Overview`}
                  exact
                  path={`/vehicle-overview/notification/component-system-history${item.path}`}
                  element={item.component}
                />
              ))}
            </Route>

            <Route
              key="Component System History Vehicle Health OnBoard"
              exact
              path="/vehicle-health/diagnostics/on-board/component-system-history"
              element={<ComponentSystemHistory />}
            >
              {ComponentSystemHistoryRoutes.map((item) => (
                <Route
                  key={`${item.title} On Board`}
                  exact
                  path={`/vehicle-health/diagnostics/on-board/component-system-history${item.path}`}
                  element={item.component}
                />
              ))}
            </Route>

            <Route
              key="Component System History Vehicle Health Self Test"
              exact
              path="/vehicle-health/self-test/component-system-history"
              element={<ComponentSystemHistory />}
            >
              {ComponentSystemHistoryRoutes.map((item) => (
                <Route
                  key={`${item.title} Self Test`}
                  exact
                  path={`/vehicle-health/self-test/component-system-history${item.path}`}
                  element={item.component}
                />
              ))}
            </Route>

            <Route
              key="Maintenance Logs Summary"
              exact
              path="/maintenance-log/summary"
              element={<MaintenanceLogsSummary />}
            >
              {MaintenanceLogsRoutes.map((item) => (
                <Route
                  key={item.title}
                  exact
                  path={item.path}
                  element={item.component}
                />
              ))}
            </Route>
            <Route
              key="Get Summary"
              exact
              path="/operational-analytics/driver-behaviour/summary"
              element={<GetDriverBehaviourSummary />}
            />
            <Route
              key="Pictorial Representation"
              exact
              path="/operational-analytics/driver-behaviour/summary/pictorial-representation"
              element={<PictorialRepresentation />}
            />
            <Route
              key="Ideal Behaviour"
              exact
              path="/operational-analytics/driver-behaviour/summary/pictorial-representation/ideal-behaviour"
              element={<IdealBehaviourPieChart />}
            />
            <Route
              key="Performance Profile"
              exact
              path="/vehicle-health/diagnostics/remote-testing/performance-profile"
              element={<RemoteAbsDiagram />}
            />
            <Route
              key="ABS Diagram"
              exact
              path="/vehicle-health/diagnostics/remote-testing/abs-diagram"
              element={<RemotePerformanceProfile />}
            />
          </Route>
        </Routes>

        {/* <RoutingComp /> */}
      </div>
    </>
  );
}

export default App;
