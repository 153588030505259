import moment from "moment";
import React, { useState, useEffect, forwardRef, useRef } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useLocation } from "react-router";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { Button } from "../../../components/common/Button/Button";
import { mainAxiosInstance } from "../../../services/service";
import { maintenanceLogEndPoints } from "../../../services/service-constants";
import { useDispatch, useSelector } from "react-redux";
import Feedback from "../../../components/common/ErrorComponents/Feedback";
import NotAuthorized from "../../../components/common/ErrorComponents/NotAuthorized";
import LottieLoad from "../../../components/common/ErrorComponents/LottieLoad";
import Breadcrumbs from "../../../components/common/Breadcrumb/Breadcrumbs";
import routes from "../../../utils/constants";

import "./index.scss";

const MaintenanceLogsSummary = () => {
  const navigate = useNavigate();
  const urlPathObj = useLocation();
  const [noDataFound, setNoDataFound] = useState(true);
  const [scoreDetails, setScoreDetails] = useState({});
  const [detailsClicked, setDetailsClicked] = useState(false);
  const [notAuthorized, setNotAuthorized] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isExpanded, setExpanded] = useState(true);

  const vin_Number = useSelector((state) => state.mainReducer.vin_Number);

  const getScoreCard = (payload) => {
    const url = `${maintenanceLogEndPoints.score_card}`;
    const { vinNumber } = payload;
    const params = {
      vin_number: vinNumber,
      organization_id: 1,
    };

    mainAxiosInstance
      .get(url, { params: params })
      .then((response) => {
        if (response.status === 200) {
          setNoDataFound(false);
          setScoreDetails(response.data);
          setNotAuthorized(false);
          setApiError(false);
        }
        if (response.status === 401) {
          setErrorMsg(response.data.detail || "You are not authorized");
          setNotAuthorized(true);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setNotAuthorized(true);
          setErrorMsg(err.response.data?.details || "You are not authorized.");
        } else {
          setApiError(true);
          setErrorMsg(err.response.data.details || "No records Found");
          setNoDataFound(true);
        }
      });
  };

  useEffect(() => {
    getScoreCard({
      vinNumber: vin_Number,
    });

    if (urlPathObj.pathname.includes("/maintenance-log/summary/")) {
      setDetailsClicked(true);
    } else {
      setDetailsClicked(false);
    }
  }, [urlPathObj.pathname]);

  const handleDetailsClick = () => {
    setDetailsClicked(true);
    navigate("/maintenance-log/summary/time-driven-actions", {
      replace: true,
    });
  };

  if (notAuthorized) {
    return <NotAuthorized title={errorMsg} />;
  }
  if (apiError) {
    return <Feedback />;
  }

  return (
    <>
      {!noDataFound ? (
        <>
          <div
            className="page-wrapper maintenance-log-summary-wrapper"
            style={{ padding: "30px 10px" }}
          >
            <div
              className={` ${isExpanded ? "" : "expand"}`}
              style={{ marginBottom: "10px" }}
            >
              <Breadcrumbs routes={routes} />
            </div>
            <div className="maintenance-score-container">
              <div className="score-graph-container">
                <div className="score-graph">
                  <CircularProgressbar
                    value={scoreDetails.score}
                    text={`${scoreDetails.score}%`}
                    counterClockwise={true}
                    strokeWidth="15"
                    styles={buildStyles({
                      pathColor: "#446EB8",
                      textColor: "#446EB8",
                    })}
                  />
                </div>
                <div className="score-details-button">
                  <Button
                    title="View details"
                    handleClick={() => handleDetailsClick()}
                    styleClasses={`btn ${
                      detailsClicked ? "btn-disabled" : "btn-primary-blue"
                    }`}
                  />
                </div>
              </div>

              <div className="score-details-container">
                <div className="score-text">Maintenance score</div>
                <ul className="score-details-ul">
                  <li className="score-details-li">
                    <div className="score-param">VIN</div>
                    <div className="score-seperator">:</div>
                    <div className="score-value">{scoreDetails.vinNumber}</div>
                  </li>
                  <li className="score-details-li">
                    <div className="score-param">Last Update Date</div>
                    <div className="score-seperator">:</div>
                    <div className="score-value">
                      {moment(scoreDetails.lastUpdated).format("DD/MM/YYYY")}
                    </div>
                  </li>
                  <li className="score-details-li">
                    <div className="score-param">Odometer Reading</div>
                    <div className="score-seperator">:</div>
                    <div className="score-value">{scoreDetails.odometer}</div>
                  </li>
                  <li className="score-details-li">
                    <div className="score-param">Maintenance Status</div>
                    <div className="score-seperator">:</div>
                    <div className="score-value">
                      {scoreDetails.maintenanceStatus}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            {detailsClicked && (
              <div className="tabs-container">
                <NavLink
                  to="/maintenance-log/summary/time-driven-actions"
                  className={({ isActive }) =>
                    isActive ? "btn-primary-blue tabs" : "btn-secondary tabs"
                  }
                >
                  Time Driven Actions
                </NavLink>
                <NavLink
                  to="/maintenance-log/summary/maintenance-on-demand"
                  className={({ isActive }) =>
                    isActive ? "btn-primary-blue tabs" : "btn-secondary tabs"
                  }
                >
                  Maintenance on Demand
                </NavLink>
                <NavLink
                  to="/maintenance-log/summary/quality"
                  className={({ isActive }) =>
                    isActive ? "btn-primary-blue tabs" : "btn-secondary tabs"
                  }
                >
                  Quality
                </NavLink>
                <NavLink
                  to="/maintenance-log/summary/location"
                  className={({ isActive }) =>
                    isActive ? "btn-primary-blue tabs" : "btn-secondary tabs"
                  }
                >
                  Location
                </NavLink>
              </div>
            )}
            <Outlet />
          </div>
        </>
      ) : (
        <>
          <div className="padding-30">
            <LottieLoad />
          </div>
        </>
      )}
    </>
  );
};

export default MaintenanceLogsSummary;
