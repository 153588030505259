import React, { useEffect } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";

const Prognostics = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/warranty-management/prognostics") {
      navigate("/warranty-management/prognostics/top-10-DTC-complaints", {
        replace: true,
      });
    }
  }, [location.pathname]);

  return (
    <div className="full-width diagnostics-container">
      <div className="sec-tabs-container">
        <NavLink
          to="/warranty-management/prognostics/top-10-DTC-complaints"
          className={({ isActive }) =>
            isActive ? "btn-primary-sec-tabs" : "btn-secondary-sec-tabs"
          }
        >
          Top 10 DTC Complaints
        </NavLink>
        <NavLink
          to="/warranty-management/prognostics/top-10-non-DTC-complaints"
          className={({ isActive }) =>
            isActive ? "btn-primary-sec-tabs" : "btn-secondary-sec-tabs"
          }
        >
          Top 10 Non-DTC Complaints
        </NavLink>
      </div>
      <Outlet />
    </div>
  );
};

export default Prognostics;
