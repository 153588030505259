import { mainAxiosInstance } from "../../../../../services/service";
import { vehicleHealthCheckEndpoints } from "../../../../../services/service-constants";

export const getVibrationalPerformanceData = async (payload) => {
  const url = `${vehicleHealthCheckEndpoints.get_performance}`;

  //   TODO : dynamic vin_number and org_id required
  const requestBody = {
    vinNumber: payload.vinNumber,
    organizationId: 1,
    profile: "Engine",
    parts: ["Combustion", "Crankshaft"],
  };
  return mainAxiosInstance
    .post(url, requestBody)
    .then((response) => {
      if (response.status === 200) {
        return {
          code: response.status,
          data: response.data,
        };
      }
      if (response.status === 401) {
        return {
          code: response.status,
          data: "You are not authorized ",
        };
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        return {
          code: err.response.status,
          data: "You are not authorized",
        };
      } else {
        return {
          code: err.response.status,
          data: "No records are Found",
        };
      }
    });
};
