import React from "react";
import ReactPaginate from "react-paginate";
import { BiChevronRight, BiChevronLeft } from "react-icons/bi";
import "./index.scss";

const index = ({
  pageCount = 0,
  pageRange = 0,
  onPageChanged,
  forcePage ,
}) => {
  return (
    <>
      <div className="container__pagination">
        <ReactPaginate
          containerClassName="pagination"
          breakLabel="..."
          nextLabel={<BiChevronRight />}
          pageRangeDisplayed={pageRange}
          pageCount={pageCount}
          previousLabel={<BiChevronLeft />}
          previousClassName="pagination--previous"
          nextClassName="pagination--next"
          activeClassName="pagination--active"
          renderOnZeroPageCount={null}
          selectedPageRel={null}
          onPageChange={(e) => onPageChanged(e.selected)}
          forcePage={forcePage}
        />
      </div>
    </>
  );
};

export default index;
