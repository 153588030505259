import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const UPComparision = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const firstfilter = useSelector((state) => state.mainReducer.first_filter);
  const secondfilter = useSelector((state) => state.mainReducer.second_filter);
  const vinfilter = useSelector((state) => state.mainReducer.vin_filter);
  const [dashboardType, setDashboardType] = useState(null);
  const [pathName, setPathName] = useState("diagnostics");
  const [progFilter, setProgFilter] = useState({});

  const [pathKey, setPathKey] = useState("");
  useEffect(() => {
    if (firstfilter === null) {
      navigate(`/vehicle-population/usageprofile`, {
        replace: true,
      });
    }
  }, [firstfilter]);

  useEffect(() => {
    const selectedData = JSON.parse(localStorage.getItem("selectedData"));
    const selectedDiagonsticsData = JSON.parse(
      localStorage.getItem("selectedDiagonosticsData")
    );

    const selectedRulData = localStorage.getItem("rul");
    const RULlowerLimitData = localStorage.getItem("lowerLimitData");
    const RULupperLimitData = localStorage.getItem("upperLimitData");
    let params = {};
    switch (selectedRulData) {
      case "Miles": {
        params = {
          fromRulMiles: parseInt(RULlowerLimitData),
          toRulMiles: parseInt(RULupperLimitData),
          rul: "by_miles",
        };
        break;
      }

      case "Engine Run Time": {
        params = {
          fromRulEngineRunTime: parseInt(RULlowerLimitData),
          toRulEngineRunTime: parseInt(RULupperLimitData),
          rul: "by_engine_runtime",
        };
        break;
      }

      case "Hours": {
        params = {
          fromRulHours: parseInt(RULlowerLimitData),
          toRulHours: parseInt(RULupperLimitData),
          rul: "by_hours",
        };
        break;
      }

      case "Key starts": {
        params = {
          fromRulKeyStarts: parseInt(RULlowerLimitData),
          toRulKeyStarts: parseInt(RULupperLimitData),
          rul: "by_key_starts",
        };
        break;
      }

      default: {
        params = {
          // fromRulMiles: parseInt(RULlowerLimitData),
          // toRulMiles: parseInt(RULupperLimitData),
          // rul: "by_miles",
        };
      }
    }

    const ProgFilterSelectedValues = {
      // ...selectedData,
      // ...selectedDiagonsticsData,
      ...firstfilter,
      ...params,
    };
    setProgFilter(ProgFilterSelectedValues);
  }, []);

  useEffect(() => {
    if (
      location.pathname ===
      `/vp-saved-filter/usageprofile/comparision/prognostics`
    ) {
      setPathName("prognostics");
      setDashboardType(vinfilter !== null ? "cwv" : "cwf");
      setPathKey(location.key);
    } else if (
      location.pathname ===
      `/vp-saved-filter/usageprofile/comparision/diagnostics`
    ) {
      setPathName("diagnostics");
      setDashboardType(vinfilter !== null ? "cwv" : "cwf");
      setPathKey(location.key);
    }
  }, [location.pathname, pathName, vinfilter]);
  useEffect(() => {
    if (location.pathname === "/vp-saved-filter/usageprofile/comparision") {
      navigate("/vp-saved-filter/usageprofile/comparision/diagnostics", {
        replace: true,
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    if (dashboardType !== null && pathName !== null) {
      navigate(
        `/vp-saved-filter/usageprofile/comparision/${pathName}/${dashboardType}`,
        {
          replace: true,
          state: {
            diagnosesState: pathName === "diagnostics" ? 0 : 1,
          },
        }
      );
    }
  }, [pathKey]);

  const filteredFilterData = (key) => {
    if (location.pathname.includes("diagnostics")) {
      return (
        key !== "id" &&
        key !== "userRole" &&
        key !== "organizationId" &&
        key !== "filterName" &&
        key !== "filterType" &&
        key !== "username" &&
        key !== "lastUpdated" &&
        key !== "rul" &&
        key !== "type" &&
        key !== "fromMiles" &&
        key !== "toMiles" &&
        key !== "fromRulMiles" &&
        key !== "toRulMiles" &&
        key !== "module" &&
        key !== "fromRulHours" &&
        key !== "fromRulEngineRunTime" &&
        key !== "toRulEngineRunTime" &&
        key !== "toRulHours" &&
        key !== "toRulKeyStarts" &&
        key !== "fromRulKeyStarts"
      );
    } else if ((progFilter.toRulMiles === null) && (progFilter.fromRulKeyStarts != null) ) {
      return (
        key !== "id" &&
        key !== "userRole" &&
        key !== "organizationId" &&
        key !== "filterName" &&
        key !== "filterType" &&
        key !== "username" &&
        key !== "lastUpdated" &&
        key !== "rul" &&
        key !== "fromMiles" &&
        key !== "toMiles" &&
        key !== "type" &&
        key !== "module"
      );
    } else {
      return (
        key !== "id" &&
        key !== "userRole" &&
        key !== "organizationId" &&
        key !== "filterName" &&
        key !== "filterType" &&
        key !== "username" &&
        key !== "lastUpdated" &&
        key !== "rul" &&
        key !== "fromMiles" &&
        key !== "toMiles" &&
        key !== "type" &&
        key !== "module" &&
        key !== "fromRulEngineRunTime" &&
        key !== "toRulEngineRunTime"
      );
    }
  };

  return (
    <div className="vehicle-population-container">
      <div className="top-button-container-comparing">
        {firstfilter && Object.keys(firstfilter).length > 0 && (
          <div className="inp">
            {location.pathname ===
            "/vp-saved-filter/usageprofile/comparision/diagnostics/cwv" ? (
              <>
                <div
                  className="display-selected-data"
                  title={Object.keys(firstfilter)
                    .filter(
                      (key) =>
                        filteredFilterData(key) && firstfilter[key] !== null
                    )
                    .map((key) => key.charAt(0).toUpperCase() + key.slice(1))
                    .join("_")}
                >
                  {Object.keys(firstfilter)
                    .filter(
                      (key) =>
                        filteredFilterData(key) && firstfilter[key] !== null
                    )
                    .map((key) => key.charAt(0).toUpperCase() + key.slice(1))
                    .join("_")}
                </div>
                <div className="display-selected-data-title">Population 1</div>
              </>
            ) : (
              <>
                <div
                  className="display-selected-data"
                  title={Object.keys(progFilter)
                    .filter(
                      (key) =>
                        filteredFilterData(key) && progFilter[key] !== null
                    )
                    .map((key) => key.charAt(0).toUpperCase() + key.slice(1))
                    .join("_")}
                >
                  {Object.keys(progFilter)
                    .filter(
                      (key) =>
                        filteredFilterData(key) && progFilter[key] !== null
                    )
                    .map((key) => key.charAt(0).toUpperCase() + key.slice(1))
                    .join("_")}
                </div>
                <div className="display-selected-data-title">Population 1</div>
              </>
            )}
          </div>
        )}

        {secondfilter && Object.keys(secondfilter).length > 0 && (
          <div className="inp">
            <div
              className="display-selected-data grey"
              title={Object.keys(secondfilter)
                .filter(
                  (key) => filteredFilterData(key) && secondfilter[key] !== null
                )
                .map((key) => key.charAt(0).toUpperCase() + key.slice(1))
                .join("_")}
            >
              {Object.keys(secondfilter)
                .filter(
                  (key) => filteredFilterData(key) && secondfilter[key] !== null
                )
                .map((key) => key.charAt(0).toUpperCase() + key.slice(1))
                .join("_")}
            </div>
            <div className="display-selected-data-title">Population 2</div>
          </div>
        )}
        {vinfilter && (
          <div className="inp">
            <div className="display-selected-data grey">{vinfilter}</div>
            <div className="display-selected-data-title">Vin Number</div>
          </div>
        )}
      </div>
      <div className="full-width ">
        <Outlet />
      </div>
    </div>
  );
};

export default UPComparision;
