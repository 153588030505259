import { useEffect } from "react";
import { useSelector } from "react-redux";
import Highcharts from "highcharts";
import arrowXAxis from "../../../assets/arrow-xaxis.svg";
import "./index.scss";
// import { engineSpeedMile, engineSpeedLastTrip } from "../../../_mock/vehicle-usage-profile/engineSpeed";

export const SingleLine = (data) => {
  const { id, chartdata, title, xLabel, yLabel, chartDetails } = data.data;
  const onClickHandler = data.onClickHandler;
  const isSidemenuExpanded = useSelector(
    (state) => state.mainReducer.isSidemenuExpanded
  );

  useEffect(() => {
    renderChart(id, chartdata, title, xLabel, yLabel);
  }, [chartdata, isSidemenuExpanded]);

  const getyAxisTitle = (chartData, yLabel) => {
    return chartData?.length < 1
      ? {
          text: null,
        }
      : {
          text: `<div class="y-axis-legend"><div>${yLabel}</div><div class="y-axis-arrow"></div></div>`,
          style: {
            color: "#353536",
            lineHeight: 1,
            textAlign: "right",
            font: "10px Open Sans",
            letterSpacing: "1px",
          },
          align: "high",
          useHTML: true,
          margin: 20,
        };
  };

  const getxAxisTitle = (chartData, xLabel) => {
    return chartData?.length < 1
      ? {
          text: null,
        }
      : {
          text: `<div class="x-axis-legend"><div>${xLabel}</div><img src='${arrowXAxis}'/></div>`,
          style: {
            color: "#353536",
            lineHeight: 1,
            textAlign: "right",
            font: "10px Open Sans",
            letterSpacing: "1px",
          },
          align: "high",
          useHTML: true,
          margin: 20,
        };
  };

  const renderChart = (id, chartdata, title, xLabel, yLabel) => {
    Highcharts.chart(
      id,
      {
        chart: {
          backgroundColor: "#FFFFFF",
          spacing: [50, 30, 10, 5],
          height: 300,
          // borderColor: "#CECECE",
          borderRadius: 20,
          // borderWidth: 1,
        },
        title: {
          text: null,
        },

        yAxis: {
          title: getyAxisTitle(chartdata, yLabel),
          labels: {
            style: {
              color: "#353536",
              lineHeight: 1,
              font: "10px Open Sans",
              letterSpacing: "1px",
            },
          },
          gridLineColor: "#F2F2F2",
        },
        xAxis: {
          type: "category",
          title: getxAxisTitle(chartdata, xLabel),
          labels: {
            style: {
              color: "#353536",
              lineHeight: 1,
              font: "10px Open Sans",
              letterSpacing: "1px",
            },
          },
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          series: {
            color: "#0574A6",
            label: {
              connectorAllowed: true,
            },
          },
        },
        tooltip: {
          backgroundColor: "#000",
          style: {
            color: "#FFFFFF",
            font: "12px Open Sans",
          },
        },

        series: [
          {
            name: yLabel,
            data: chartdata,
          },
        ],
      },
      function (chart) {
        // on complete
        const dataLen = chart.series?.[0]?.data?.length || 0;
        if (dataLen < 1) {
          // check series is empty
          chart.renderer
            .text("No Data Available", 50, 125)
            .css({
              color: "#000000",
              fontSize: "16px",
            })
            .add();
        }
      }
    );
  };

  return (
    <figure className="highcharts-figure" onClick={() => onClickHandler()}>
      <div className="chart-details-wrapper">
        {chartDetails?.map(
          (el) =>
            !el.value &&
            el.color && (
              <>
                <div className="legend-wrapper">
                  <div className={`${el.color.toLowerCase()}-legend`}>
                    <span
                      className={`color-box ${el.color.toLowerCase()}`}
                    ></span>
                    <span className="color-legend">{el.label}</span>
                  </div>
                </div>
              </>
            )
        )}
        {chartDetails?.map(
          (el) =>
            ((el.value && el.color) || (!el.value && !el.color)) && (
              <div className="details-wrapper">
                <div className={`chart-details`}>
                  <span className={`value ${el.color.toLowerCase()}`}>
                    {el.value}
                  </span>
                  <span className="color-legend">{el.label}</span>
                </div>
              </div>
            )
        )}
      </div>
      <div id={id}></div>
      <div className="graph-name">{title}</div>
    </figure>
  );
};
