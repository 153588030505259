import {
  ServiceConfig,
  VehiclePopulationDashBoard,
} from "../../../../services/service-constants";
import { mainAxiosInstance } from "../../../../services/service";
import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../VehiclePopulation.scss";
import Feedback from "../../../../components/common/ErrorComponents/Feedback";
import SingleChartLoader from "../../../../components/common/ErrorComponents/SingleChartLoader";
import MyMap from "../../../../components/common/MapGraph/MyMap";
import mapDataApi from "../../../../components/common/MapGraph/MapApi";

const PrognosticGeoSpatialMapping = () => {
  const [noDataFound, setNoDataFound] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [topDtc, setTopDtc] = useState([]);
  const [mapData, setMapData] = useState(null);
  const [paramData, setParamData] = useState(null);
  useEffect(() => {
    let url = `${VehiclePopulationDashBoard.defaultApi}`;
    mainAxiosInstance
      .get(url)
      .then((response) => {
        setParamData(response.data);
      })
      .catch((er) => {
        throw er;
      });
  }, []);
  const fetchMapData = async () => {
    const mapDataRes = await mapDataApi.getWorld();
    setMapData(mapDataRes.data);
  };

  const prepareChartData = (dataSet) => {
    const formData = dataSet.geospatialMappingDtoList.map((mapping) => ({
      name: mapping.state[0].name,
      totalCount: mapping.state[0].totalCount,
      details: mapping.state[0].details.map(
        (detail) => `${detail.dtcCode}(${detail.noOfOccurrences})`
      ),
    }));

    const data = [
      {
        firstDTcnumber: dataSet?.topThreeDtcForGeospatialMappingDto[0]?.dtcCode,
      },
      {
        secondDTcnumber:
          dataSet?.topThreeDtcForGeospatialMappingDto[1]?.dtcCode,
      },
      {
        ThirdDTcnumber: dataSet?.topThreeDtcForGeospatialMappingDto[2]?.dtcCode,
      },
      { GraphName: dataSet?.informationDto.graphName },
    ];

    setChartData(formData);
    setTopDtc(data);
  };

  const getPageData = () => {
    setNoDataFound(false);
    const url = `${VehiclePopulationDashBoard.GeoMapping}/1`;
    const selectedData = JSON.parse(localStorage.getItem("selectedData"));
    const selectedDiagonsticsData = JSON.parse(
      localStorage.getItem("selectedDiagonosticsData")
    );
    const FilterSelectedValues = {
      ...selectedData,
      ...selectedDiagonsticsData,
    };
    let filteredValues = {};
    if (Object.keys(FilterSelectedValues).length === 0) {
      filteredValues = paramData;
    } else {
      filteredValues = FilterSelectedValues;
    }

    let data = Object.assign({}, filteredValues);
    mainAxiosInstance
      .post(url, data)
      .then((response) => {
        if (response.status === 200) {
          setNoDataFound(false);
          prepareChartData(response.data);
        }
        if (response.status === 401) {
          setApiError(response.data?.details || "You are not authorized.");
          setNoDataFound(true);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setNoDataFound(true);
        } else {
          setNoDataFound(true);
        }
      });
  };
  useEffect(() => {
    // getPageData();
    fetchMapData();
  }, []);
  useEffect(() => {
    if (paramData !== null) getPageData();
  }, [paramData]);
  if (chartData === null && noDataFound === false) {

    return (
      <div className="vehicle-dashboard-middle-container">
        <SingleChartLoader />;
      </div>
    );
  }

  return (
    <div className="vehicle-dashboard-middle-container">
      <>
        {!noDataFound ? (
          <>
            <div style={{ display: "flex" }}>
              <div>
                <MyMap chartData={chartData} mapData={mapData} />
              </div>
              <div className="mapping">
                <div>
                  <div style={{ fontWeight: "500" }}>
                    <p>Number of occurance</p>
                  </div>
                  <div className="geo-map-occurance-container">
                    <p
                      className="geo-map-occurance-color"
                      style={{ backgroundColor: "#92C5DE" }}
                    ></p>
                    <p className="geo-map-occurance-data">0-40</p>
                  </div>
                  <div className="geo-map-occurance-container">
                    <p
                      className="geo-map-occurance-color"
                      style={{ backgroundColor: "#4393C3" }}
                    ></p>
                    <p className="geo-map-occurance-data">41-70</p>
                  </div>
                  <div className="geo-map-occurance-container">
                    <p
                      className="geo-map-occurance-color"
                      style={{ backgroundColor: "#0575A8" }}
                    ></p>
                    <p className="geo-map-occurance-data">71-90</p>
                  </div>
                </div>
                <div style={{ marginTop: "60px" }}>
                  <div style={{ fontWeight: "500" }}>
                    <p>Top Three DTC's </p>
                  </div>
                  <p className="geo-map-occurance-dtc-code">
                    {topDtc[0]?.firstDTcnumber}
                  </p>
                  <p className="geo-map-occurance-dtc-code">
                    {topDtc[1]?.secondDTcnumber}
                  </p>
                  <p className="geo-map-occurance-dtc-code">
                    {topDtc[2]?.ThirdDTcnumber}
                  </p>
                </div>
              </div>
            </div>

            <div className="graph-name " style={{ top: "40px" }}>
              {topDtc[3]?.GraphName}{" "}
            </div>
          </>
        ) : (
          <div>
            <Feedback feedbackClassName="feedback" />
          </div>
        )}
      </>
    </div>
  );
};

export default PrognosticGeoSpatialMapping;
