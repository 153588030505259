import { BarGraph } from "../../../../components/common/blackbarGraph/Graph";
import {
  ServiceConfig,
  VehiclePopulationDashBoard,
} from "../../../../services/service-constants";
import { mainAxiosInstance } from "../../../../services/service";
import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../VehiclePopulation.scss";
// import { useSelector } from "react-redux";
import Feedback from "../../../../components/common/ErrorComponents/Feedback";
import ChartLoader from "../../../../components/common/ErrorComponents/ChartLoader";
import { getWarrantydata } from "../../Apis/Apis";
import SingleChartLoader from "../../../../components/common/ErrorComponents/SingleChartLoader";
import Pagination from "../../../../components/common/Pagination";

const PrognosticWarrantyScordCard = () => {
  const [noDataFound, setNoDataFound] = useState(false);
  const [apiError, setApiError] = useState(false);
  const vinNumber = useSelector((state) => state.mainReducer.vin_Number);
  const [chartData, setChartData] = useState(null);
  const [graphName, setGraphName] = useState("");
  const [pagination, setPagination] =useState({});

  const getChartData = async (val) => {
    var pageSize = val === undefined ? 0 : val;
    const { code, data, detailsData ,PaginationDetails } = await getWarrantydata(pageSize);
    setPagination(PaginationDetails)
    if (code == 200) {
      setNoDataFound(false);
      const milesData = [];
      let xAxisLabel = data.informationDto.xAxisName;
      let yAxisLabel = data.informationDto.yAxisName;
      setGraphName(data.informationDto.graphName);
      milesData.push({
        data: detailsData,
        xLabel: xAxisLabel,
        yLabel: yAxisLabel,
        id: graphName,
      });
      setChartData(milesData);
    }
    if (code === "error") {
      setApiError(data);
      setNoDataFound(true);
    }
  };

  useEffect(() => {
    getChartData();
  }, []);

  const onPageChanged = (val) => {
    getChartData(val);
  }

  if (chartData === null && noDataFound === false) {
    return (
      <div className="vehicle-dashboard-middle-container">
        <SingleChartLoader />;
      </div>
    );
  }

  return (
    <>
      <div className="vehicle-dashboard-middle-container">
        {!noDataFound ? (
          <>
            <BarGraph chartdata={chartData} />
            <div className="warrenty "></div>
          </>
        ) : (
          <div>
            <Feedback feedbackClassName="feedback" />
          </div>
        )}
      </div>
      {noDataFound === false ? (
        <>
        <div className="graph-name ">{graphName} </div>
        {pagination.totalpages > 1 && (
          <div>
          <Pagination
            pageCount={pagination?.totalPages}
            pageRange={pagination?.size}
            onPageChanged={onPageChanged}
          />
        
        </div>
          )}
          </>
      ) : (
        ""
      )}
    </>
  );
};

export default PrognosticWarrantyScordCard;
