import moment from 'moment';
import { mainAxiosInstance } from '../../../services/service';
import { ServiceConfig } from '../../../services/service-constants';

export const getComponentSystemHistoryTableData = async (payload) => {
  const { page, url, organizationId, vinNumber, subSystem, graphName = null, statsDate = null, type = null } = payload;
  const params = {
    organization_id: organizationId || 1,
    vin_number: vinNumber,
    sub_system: subSystem,
    page: page,
    size: 10,
  };

  if(graphName){
    params.graph_name = graphName;
  }

  if(statsDate) {
    params.stats_date = statsDate;
  }

  if(type) {
    params.type = type;
  }

  return mainAxiosInstance
    .get(url, {
      params: params,
    })
    .then((response) => {
      if (response.status === 200 || response.status === 301) {
        return {
          code: response?.status,
          data: response?.data,
        };
      }
      if (response.status === 401) {
        return {
          code: response.status,
          data: "You are not authorized",
        };
      }
    
    })
    .catch((err) => {
      if (err.response.status === 401) {
        return {
          code: err.response.status,
          data: err.msj || "You are not authorized.",
        };
      } else {
        return {
          code: "error",
          data: err.msj || "Something went wrong",
        };
      }
    });
};
