import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../VehiclePopulation.scss";
import { useLocation } from "react-router-dom";
import SingleChartLoader from "../../../../components/common/ErrorComponents/SingleChartLoader";
import { VehiclePopulationDashBoard } from "../../../../services/service-constants";
import "../../VehiclePopulation.scss";
import { mainAxiosInstance } from "../../../../services/service";
import { Checkbox } from "../../../../components/common/Checkbox/Checkbox";
import Filter from "../../../../components/Filter/Filter";
import DualColumnLineGraph from "../../../../components/common/DualColumnLineGraph";
import DualColumnLineGraphLarge from "../../../../components/common/DualColumnLineGraph/DualColumnLineGraphLarge";
import ChartLoader from "../../../../components/common/ErrorComponents/ChartLoader";
import NotAuthorized from "../../../../components/common/ErrorComponents/NotAuthorized";
import Feedback from "../../../../components/common/ErrorComponents/Feedback";
import navLeft from "../../../../assets/left-arrow-square.svg";
import navRight from "../../../../assets/right-arrow-square.svg";
import Pagination from "../../../../components/common/Pagination";

const UPCompareWithFilter = () => {
  const valueMap = {
    engineCoolantTemperatureResponsePage: "temperatureInFahrenheit",
    transmissionGearResponsePage: "transmissionGear",
    engineOilTemperatureResponsePage: "oilTemperatureFahrenheit",
    transmissionOilTemperatureResponsePage:
      "transmissionOilTemperatureFahrenheit",
    engineTorqueResponsePage: "engineTorque",
    tirePressureResponsePage: "tirePressure",
    engineOilLevelResponsePage: "engineOilLevel",
    engineSpeedResponsePage: "engineSpeed",
  };
  const dataStore = [];

  const location = useLocation();
  const [noDataFound, setNoDataFound] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [data, setData] = useState(null);
  const [graphName, setGraphName] = useState("");
  const [showFilter, setShowFilter] = useState(true);
  const [selectAll, setSelectAll] = useState(true);
  const [filterData, setFilterData] = useState([]);
  const [diagnosesState, setDiagnosesState] = useState(null);
  const firstfilter = useSelector((state) => state.mainReducer.first_filter);
  const secondfilter = useSelector((state) => state.mainReducer.second_filter);
  const [showChartLoader, setShowChartLoader] = useState(false);
  const [authenticationMsg, setAuthenticationMsg] = useState(false);
  const [selectedAll, setSelectedAll] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedChartData, setSelectedChartData] = useState(null);
  const [pageSize, setPageSize] = useState(0);
  const [pagination, setPagination] = useState(0);
  const [yAxisLabel, setYAxisLabel] = useState("Total number of miles");

  useEffect(() => {
    if (location?.state) {
      setDiagnosesState(location?.state?.diagnosesState);
    }
  }, [location]);

  useEffect(() => {
    if (diagnosesState !== null) getPageData();
  }, [diagnosesState, pageSize]);

  const getPageData = (val) => {
    setShowChartLoader(true);
    const url = `${VehiclePopulationDashBoard.vp_up_compare_with_card}/${diagnosesState}?page=${pageSize}&size=10`;
    let params = {};

    // if (!firstfilter.hasOwnProperty('fromRulMiles') ||(!firstfilter.hasOwnProperty('toRulMiles'))) {
    //   firstfilter.fromRulMiles = 1;
    //   firstfilter.toRulMiles = 200000; // Replace defaultValue with the value you want to assign
    // }

    // if (!secondfilter.hasOwnProperty('fromRulMiles') ||(!secondfilter.hasOwnProperty('toRulMiles'))) {
    //   secondfilter.fromRulMiles = 1;
    //   secondfilter.toRulMiles = 200000; // Replace defaultValue with the value you want to assign
    // }
    // if (firstfilter.fromRulMiles === null || firstfilter.toRulMiles === null) {
    //   firstfilter.fromRulMiles = 1;
    //   firstfilter.toRulMiles = 200000;
    // }

    // if (secondfilter.fromRulMiles === null || secondfilter.toRulMiles === null) {
    //   secondfilter.fromRulMiles = 1;
    //   secondfilter.toRulMiles = 200000;
    // }

    // if (secondfilter.fromRulEngineRunTime === null || secondfilter.toRulEngineRunTime === null) {
    //   secondfilter.fromRulEngineRunTime = 0;
    //   secondfilter.toRulEngineRunTime = 1;
    // }

    let firstParam = { ...firstfilter, ...params };
    let secondParam = { ...secondfilter, ...params };
    let paramData = [firstParam, secondParam];

    mainAxiosInstance
      .post(url, paramData)
      .then((response) => {
        if (response.status === 200) {
          setNoDataFound(false);
          setData(response.data);
          setShowChartLoader(false);
        }
        if (response.status === 401) {
          setErrorMsg(response.data?.details || "You are not authorized.");
          setAuthenticationMsg(true);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setAuthenticationMsg(true);
          setErrorMsg(err.response.data?.details || "You are not authorized.");
        } else {
          setNoDataFound(true);
        }
      });
  };

  const setSelectAllvalue = (data) => {
    const checkAllSelected = data.filter((el) => el.isChecked === false);
    if (checkAllSelected.length > 0) {
      setSelectAll(false);
    } else {
      setSelectAll(true);
    }
  };
  const handleFilterClick = (val) => {
    const newFilterData = filterData.map((el) => {
      if (el.displayname === val.displayname) {
        el.isChecked = !el.isChecked;
      }
      return el;
    });

    const isAnyModuleSelected = newFilterData.some((el) => el.isChecked);
    setFilterData([...newFilterData]);
    setSelectAllvalue(newFilterData);
  };
  const AllSelect = (val) => {
    const getUpdatedfilterData = filterData.map((el) => {
      return { ...el, isChecked: val };
    });
    setFilterData(...[getUpdatedfilterData]);
    setSelectAll(val);
  };

  const getDataForBarChart = (element) => {
    const barChartData = chartData?.filter((el) => el.name === element.id);
    dataStore.push(barChartData?.[0]?.data);
    return barChartData?.[0]?.data;
  };

  const getChartData = async (response) => {
    let mainData = [];
    var filterKey = [];
    Object.keys(response).map((key) => {
      if (response[key] !== null) {
        const colorMap = {
          yellow: "#09A1A1",
          green: "#0574A6",
          red: "#DD359A",
          black: "#000000",
          darkgreen: "#045E5E",
          maroon: "#700746",
        };
        let resKey = valueMap[key];
        let firstMeanData = [];
        let firstMaxData = [];
        let firstMinData = [];
        let firstResData = [];
        let secondMeanData = [];
        let secondMaxData = [];
        let secondMinData = [];
        let secondResData = [];
        let paginationInfo = response[key]?.totalPages;
        var temp = response[key]?.content[0];
        var filterKeyValues = Object.values(temp)[0][0]?.graphLabelsDto;

        filterKey.push({
          displayname: filterKeyValues?.graphLabel,
          id: filterKeyValues?.graphLabel,
          isChecked: true,
        });

        response[key].content?.map((data) => {
          Object.values(data).map((item) => {
            const aObject = item[0]?.usageProfilePopulationDTO[0];
            const bObject = item[1]?.usageProfilePopulationDTO[0];
            firstMeanData.push({
              y:
                aObject?.meanValue == null || aObject?.meanValue == undefined
                  ? 0
                  : aObject?.meanValue,
              color: colorMap[aObject?.threshold],
              maxValue: aObject?.maxValue,
              minValue: aObject?.minValue,
              iqr: aObject?.iqr,
              standardDeviation: aObject?.standardDeviation,
              variance: aObject?.variance,
              range: aObject?.range,
            });
            firstMaxData.push(
              aObject?.maxValue == null || aObject?.maxValue == undefined
                ? 0
                : aObject?.maxValue
            );
            firstMinData.push(
              aObject?.minValue == null || aObject?.maxValue == undefined
                ? 0
                : aObject?.minValue
            );
            firstResData.push(Object.keys(data)[0]);
            secondMeanData.push({
              y:
                bObject?.meanValue == null || bObject?.meanValue == undefined
                  ? 0
                  : bObject?.meanValue,
              color:
                aObject?.threshold === "green"
                  ? colorMap["black"]
                  : aObject?.threshold === "yellow"
                  ? colorMap["darkgreen"]
                  : aObject?.threshold === "red"
                  ? colorMap["maroon"]
                  : colorMap[bObject?.threshold],
              maxValue: bObject?.maxValue,
              minValue: bObject?.minValue,
              iqr: bObject?.iqr,
              standardDeviation: bObject?.standardDeviation,
              variance: bObject?.variance,
              range: bObject?.range,
            });
            secondMaxData.push(
              bObject?.maxValue == null || bObject?.maxValue == undefined
                ? 0
                : bObject?.maxValue
            );
            secondMinData.push(
              bObject?.minValue == null || bObject?.maxValue == undefined
                ? 0
                : bObject?.minValue
            );
            secondResData.push(
              bObject?.[resKey] == null || bObject?.maxValue == undefined
                ? bObject?.[resKey]
                : bObject?.[resKey]
            );
          });
        });
        mainData.push({
          name: filterKeyValues?.graphLabel,
          PaginationInfo: paginationInfo,
          data: [
            {
              data: [
                {
                  type: "column",
                  data: firstMeanData,
                  color: "#0574A6",
                  pointWidth: 15,
                  name: "Miles",
                },

                {
                  type: "column",
                  data: secondMeanData,
                  color: "#041F3E",
                  pointWidth: 15,
                  name: "Miles",
                },
                {
                  type: "line",
                  data: firstMaxData,

                  marker: {
                    radius: 3,
                    symbol: "circle",
                    fillColor: {
                      radialGradient: {
                        cx: 0.5,
                        cy: 0.3,
                        r: 0.5,
                      },
                      stops: [
                        [0, "#FFFFFF"],
                        [1, "#DD359A"],
                      ],
                    },
                  },
                  lineColor: "#DD359A",
                  lineWidth: 1,
                  name: "Max",
                },
                {
                  type: "line",
                  data: secondMaxData,

                  marker: {
                    radius: 3,
                    symbol: "circle",
                    fillColor: {
                      radialGradient: {
                        cx: 0.5,
                        cy: 0.3,
                        r: 0.5,
                      },
                      stops: [
                        [0, "#FFFFFF"],
                        [1, "#700746"],
                      ],
                    },
                  },
                  lineColor: "#700746",
                  lineWidth: 1,
                  name: "Max",
                },
                {
                  type: "line",
                  data: firstMinData,
                  marker: {
                    radius: 3,
                    symbol: "circle",
                    fillColor: {
                      radialGradient: {
                        cx: 0.5,
                        cy: 0.3,
                        r: 0.5,
                      },
                      stops: [
                        [0, "#FFFFFF"],
                        [1, "#0574A6"],
                      ],
                    },
                  },
                  lineColor: "#0574A6",
                  lineWidth: 1,
                  name: "Min",
                },
                {
                  type: "line",
                  data: secondMinData,
                  marker: {
                    radius: 3,
                    symbol: "circle",
                    fillColor: {
                      radialGradient: {
                        cx: 0.5,
                        cy: 0.3,
                        r: 0.5,
                      },
                      stops: [
                        [0, "#FFFFFF"],
                        [1, "#041F3E"],
                      ],
                    },
                  },
                  lineColor: "#041F3E",
                  lineWidth: 1,
                  name: "Min",
                },
              ],
              //  categories: (firstResData === null || firstResData === undefined) ? secondResData : firstResData,
              categories: firstResData,
            },
          ],
        });
      }
    });

    setChartData(mainData);
    setFilterData(filterKey);
  };

  useEffect(() => {
    if (data) getChartData(data);
  }, [data]);

  useEffect(() => {
    setPagination(0);
  }, []);

  useEffect(() => {
    const selectedAll = filterData.map((key) => key.isChecked === true);
    const values = selectedAll.filter((key) => key === false);
    setSelectedAll(values);
  }, [filterData]);

  if (chartData === null && noDataFound === false) {
    return (
      <div className="vehicle-dashboard-middle-container">
        <SingleChartLoader />;
      </div>
    );
  }

  const handleChartClick = (el) => {
    setShowModal(true);
    setSelectedChartData(el);
    setShowFilter(false);
    getTotalPages(el);

    const barChartData = chartData.filter((elemet) => elemet.name === el.id);
    const totalPages = barChartData[0]?.PaginationInfo;
    setPagination(totalPages);
  };

  const showPrev = (el) => {
    const data = filterData.filter((el) => el.isChecked);
    if (data.length > 0) {
      const index = data.findIndex((elem) => elem.id === el.id);
      let selectElement = el;
      if (index !== 0) {
        selectElement = data[index - 1];
      } else if (index === 0) {
        selectElement = data[data.length - 1];
      }
      setNoDataFound(false);
      setSelectedChartData(selectElement);
      getTotalPages(selectElement);
      setPageSize(0);
    } else {
      setNoDataFound(true);
    }
  };

  const clickOnClose = (e) => {
    setShowModal(false);
    setShowFilter(true);
    setPageSize(0);
  };
  const showNext = (el) => {
    const data = filterData.filter((el) => el.isChecked);

    if (data.length > 0) {
      const index = data.findIndex((elem) => elem.id === el.id);
      let selectElement = el;
      if (index !== data.length - 1) {
        selectElement = data[index + 1];
      } else if (index === data.length - 1) {
        selectElement = data[0];
      }
      setNoDataFound(false);
      setSelectedChartData(selectElement);
      getTotalPages(selectElement);
      setPageSize(0);
    } else {
      setNoDataFound(true);
    }
  };

  const getTotalPages = (element) => {
    const barChartData = chartData.filter((el) => el.name === element.id);
    const totalPages = barChartData[0]?.PaginationInfo;
    setPagination(totalPages);
  };

  const onPageChanged = (val) => {
    setPageSize(val);
  };

  return (
    <div
      className="page-wrapper vehicle-usage"
      style={{ backgroundColor: "#EEEEEE", padding: "0px 20px" }}
    >
      <div className="flex page-content-header--filter">
        {showFilter === true && (
          <Filter data={AllSelect} selectAll={selectAll}>
            {filterData.map((el) => (
              <Checkbox
                title={el.displayname}
                handleInputClick={() => handleFilterClick(el)}
                alignProp={{
                  borderTop: "0.5px solid #C4C4C4",
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "10px",
                  alignItems: "baseline",
                  flexDirection: "row-reverse",
                }}
                checkboxStyle={{ marginRight: "10px" }}
                isChecked={el.isChecked}
              />
            ))}
          </Filter>
        )}
      </div>

      {!noDataFound && (
        <div className="legend-wrapper" style={{ marginBottom: "20px" }}>
          <div className="legend">
            <div>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <div className="green-legend">
                  <span className="line-box blue"></span>
                  <span className="color-legend">P1 Min Values</span>
                </div>
                <div className="green-legend">
                  <span className="line-box black"></span>
                  <span className="color-legend">P2 Min Values</span>
                </div>
                <div className="green-legend">
                  <span className="line-box red"></span>
                  <span className="color-legend">P1 Max Values</span>
                </div>
                <div className="green-legend">
                  <span className="line-box maroon"></span>
                  <span className="color-legend">P2 Max Values</span>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="green-legend">
                  <span className="color-box green"></span>
                  <span className="color-box black"></span>
                  <span className="color-legend">
                    P1,P2 Within the Expected range
                  </span>
                </div>
                <div className="yellow-legend">
                  <span className="color-box yellow"></span>{" "}
                  <span className="color-box darkgreen"></span>
                  <span className="color-legend">
                    P1,P2 Within Expected range but too high/low
                  </span>
                </div>
                <div className="red-legend">
                  <span className="color-box pink"></span>
                  <span className="color-box maroon"></span>
                  <span className="color-legend">
                    P1,P2 Outside Expected range
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div>
        {noDataFound ||
          (selectedAll?.length === 8 && (
            <div>
              <Feedback />
            </div>
          ))}
        {authenticationMsg && (
          <div>
            <NotAuthorized title={errorMsg} />
          </div>
        )}
        {noDataFound && !authenticationMsg && (
          <div>
            <ChartLoader />
          </div>
        )}
      </div>

      {!noDataFound && (
        <div className="page-chart-container vehicle-usage-charts">
          {!noDataFound &&
            filterData.map(
              (el, i) =>
                el.isChecked && (
                  <>
                    {/* <span>{el.displayname}</span> */}
                    {!showModal && (
                      <DualColumnLineGraph
                        id={el.id}
                        data={getDataForBarChart(el)}
                        title={el.displayname}
                        graphName={el.displayname}
                        onClickHandler={() => handleChartClick(el)}
                        fromVpUp={true}
                      />
                    )}
                  </>
                )
            )}

          {showModal && !noDataFound && (
            <>
              <div
                className="nav-icons"
                onClick={() => showPrev(selectedChartData)}
              >
                <img src={navLeft} alt="previous" />
              </div>
              <DualColumnLineGraphLarge
                id={selectedChartData.displayname}
                data={getDataForBarChart(selectedChartData)}
                title={selectedChartData.displayname}
                yLabel={yAxisLabel}
                clickOnClose={clickOnClose}
                fromVpUp={true}
              />
              <div
                className="nav-icons"
                onClick={() => showNext(selectedChartData)}
              >
                <img src={navRight} alt="previous" />
              </div>
            </>
          )}
          {dataStore?.length == 0 && (
            <div style={{ width: "900px" }}>
              <Feedback />
            </div>
          )}
        </div>
      )}
      {showModal && pagination > 1 && (
        <Pagination
          pageCount={pagination}
          pageRange={10}
          onPageChanged={onPageChanged}
          forcePage={pageSize}
        />
      )}
    </div>
  );
};

export default UPCompareWithFilter;
