import React from "react";
import "./WarrantyCard.scss";

const WarrantyCard = ({
  img,
  backgroundColor,
  text,
  containerStyles,
  secondText,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "250px",
        height: "100px",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #C4C4C4",
        boxShadow:
          " 0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08) ",
        backgroundColor: backgroundColor,
        borderRadius: "10px",
        textAlign: "center",
        padding: "8px",

        ...containerStyles,
      }}
    >
      <img src={img} alt="wa" />
      <h3 style={{ fontWeight: 400, fontSize: "14px" }}>
        {text}
        <br />
        {secondText}
      </h3>
    </div>
  );
};

export default WarrantyCard;
