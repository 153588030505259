import React, { useEffect, useState } from "react";
import Feedback from "../../../../../components/common/ErrorComponents/Feedback";
import { BarGraph } from "../../../../../components/common/blackbarGraph/Graph";
import { mainAxiosInstance } from "../../../../../services/service";
import { WarrantymanagementDashBoard } from "../../../../../services/service-constants";
import { useSelector } from "react-redux";
import SingleChartLoader from "../../../../../components/common/ErrorComponents/SingleChartLoader";
import { DualColumn } from "../../../../../components/common/DualColumnGraph/DualColumnGraphTwoYaxis";

const VPWMSeverity = () => {
    const vinNumber = useSelector((state) => state.mainReducer.vin_Number);
    const [data, setChartData] = useState(null);
    const [noDataFound, setNoDataFound] = useState(false);
    const [graphID, setGraphID] = useState(null)

    const prepareChartData = (dataSet) => {
        getOutputData(dataSet);
    };

    const getOutputData = (data) => {
        const milesData = [];
        const dataAxis1 = [];
        const dataAxis2 = [];
        const xAxisValues = []
        let xAxisLabel = data.informationDto.xAxisName;
        let yAxisLabel = data.informationDto.yAxisName;
        let graphName = data.informationDto.graphName;
        let yAxisName2 = data.informationDto.yAxisName2
        setGraphID(graphName);
        data.severityDtos.forEach((val) => {
            dataAxis1.push({
                // x: val.dtcCode,
                y: val.severityScore,
                color: "#4472c4",
            });
            dataAxis2.push({
                // x: val.dtcCode,
                y: val.noOfCustomerAffected,
                color: "#ffc000",
            });
            xAxisValues.push(
                val.dtcCode
            )
        });
        milesData.push({
            dataAxis1: dataAxis1,
            dataAxis2: dataAxis2,
            id: graphName,
            title: graphName,
            xLabel: xAxisLabel,
            yLabel: yAxisLabel,
            yLabel2: yAxisName2,
            xAxisValues: xAxisValues,
            labelfirstText:"Population Severity Score",
            labelSecondText:"Population Number of Customers Affected",

        });
        setChartData(milesData);
    };

    const getPageData = (val) => {
        setNoDataFound(false);
        const url = `${WarrantymanagementDashBoard.warranty_management}/0/dtc/severity`;
        let params = {
            make: "Ford",
            organizationId: 1,
        };

        mainAxiosInstance
            .post(url, params)
            .then((response) => {
                if (response.status === 200) {
                    setNoDataFound(false);
                    prepareChartData(response.data);
                }
            })
            .catch((err) => {
                setNoDataFound(true);
            });
    };

    useEffect(() => {
        getPageData();
    }, []);


    if (data === null && noDataFound === false) {
        return (
            <div className="wm-dashboard-middle-container">
                <SingleChartLoader />;
            </div>
        );
    }
    return (
        <>
            <div className="wm-dashboard-middle-container">
                {noDataFound ? (
                    <div>
                        <Feedback feedbackClassName="feedback" />
                    </div>
                ) : (
                    <>
                        <DualColumn data={data} />
                    </>
                )}
            </div>
            {noDataFound === false ? <div className="graph-name">{graphID}</div> : ""}
        </>

    );


}
export default VPWMSeverity;