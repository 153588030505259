import axios from "axios";

const baseUrl = "https://code.highcharts.com/mapdata/";

/* Helper Function
 * To get the countries of a specific region
 * */
const getWorld = async () => {
  return await axios.get(baseUrl + "countries/us/us-all.geo.json");
};

const mapData = {
  getWorld
};

export default mapData;